import React from 'react';
import ReactDOM from 'react-dom';


// export default function Translate(lang, words) {
    
export default function XTranslate() {
    // const lookup = {

    this.lookup = {'dumb': 'dumb'};

    this.x = function (lang, words) {
        if (lang === 'FRANCAIS') {
            return (this.lookup[words]);
        } else {
            return words;
        };

    };

    this.addLookup = function(orderList) {
        if (orderList !== null) {
            orderList.map((value) => {
                this.lookup[value.NameDatabase] = value.NameFrench;
            });
        };
    };

    this.addLookupNew = function(orderList) {
        if (orderList !== null) {
            orderList.map((value) => {
                this.lookup[value.name] = value.name_french;
            });
        };
    };
        
}

