import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import Skeleton from '@mui/material/Skeleton';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "90%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.green': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.red': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function YPCWeeklyBillings(props) {

  const classes = useStyles();
  // const theme = useTheme();
  

  const [starterOrderArrayList, setStarterOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [dessertsOrderArrayList, setDessertsOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [fruitsOrderArrayList, setFruitsOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [drinksOrderArrayList, setDrinksOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [mainsOrderArrayList, setMainsOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [lunchDeliveryTime, setLunchDeliveryTime] = React.useState(['','','','','','','']);
  const [dinnerDeliveryTime, setDinnerDeliveryTime] = React.useState(['','','','','','','']);

  const [subscriptionTypeArrayList, setSubscriptionTypeArrayList] = React.useState(['None','None','None','None','None','None','None'])

  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);


  const [summaryList, setsummaryList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);
  const [summaryArrayList, setsummaryArrayList] = React.useState([
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]
                                        ]);

  const [summaryEverythingList, setsummaryEverythingList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);

  const [combinedSummaryList, setCombinedSummaryList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [datesArray, setDatesArray] = React.useState([moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD"),
              moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD"),
              moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD")])

  const [beginDate, setBeginDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);


  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);
  // const [noneSubsriberCost, setNoneSubscriberCost] = React.useState(5.0);
  const noneSubscriberCostDict = {'None': 9.00, 'None5': 5.00};

  const [subscriptionType, setSubscriptionType] = React.useState('None');

  const [locale, setLocale] = React.useState()

  const [username, setUsername] = React.useState("");
  const [usernameList, setUsernameList] = React.useState([]);
  const [enableSummaryAll, setEnableSummaryAll] = React.useState(false);

  const [duesRows, setDuesRows] = React.useState([]);

  const [dailyTotalRows, setDailyTotalRows] = React.useState([]);
  const [dateDisplay, setDateDisplay] = React.useState(['','','','','','','']);


  const bi_monthly_subscription_cost = 45.0;

  const subscriptionCostLookup = {'Individual': 30.0, 'Family': 50.0, 'Individual 4 Weeks': 100.0,  'Family 4 Weeks':170.0, 'None': 0.0, 'None5': 0.0, 'Free':0.0, 'CmpPaysAll':0.0, 'CmpPaysSub': 0.0, 
        'MngPaysAll': 0.0, 'MngPaysSub' : 0.0, 'Free Subscription' : 0.0, 
        'Company Subscription': 30.0 };
  

  const [subscribed, setSubscribed] = React.useState(false);
  const [subsType, setSubsType] = React.useState(null);
  const [compType, setCompType] = React.useState('None');

  const [hideSummary, setHideSummary] = React.useState(true);
  const [hideSingleUser, setHideSingleUser] = React.useState(false);

  const [showPayEditDialog, setShowPayEditDialog] = React.useState(null);
 
  const [showPayAllDialog, setShowPayAllDialog] = React.useState(false);
  

  React.useEffect(() => {
    calculatesummaryTable();
  }, [subscriptionTypeArrayList, mainsOrderArrayList, starterOrderArrayList, dessertsOrderArrayList, drinksOrderArrayList, fruitsOrderArrayList, props.language, dinnerDeliveryTime, lunchDeliveryTime]);

  React.useEffect(() => {
    getUserNames();
    getMenu();
    getWeekTotalAll()

  }, [selectedDate]);

  React.useEffect(() => {
    getUserNames();
    getMenu();
  }, [username]);


  async function getWeekTotalAll() {

    setHideSummary(true);

    let xsubDate = selectedDate.toString().split(" ")
    let xdateString = xsubDate[1] + xsubDate[2] + xsubDate[0] + xsubDate[3];
    let allSubsTypeCompHistories = null;
    allSubsTypeCompHistories = await YPCGetAxiosAlt(xget, setXGet, 'getAllDateSubscriptionTypeURL', xdateString);
    console.log('FIFIFIFIFFI', allSubsTypeCompHistories);

    // let monthly_sub_reqd_dict = null;
    // let newDate = new moment(selectedDate).startOf('week').format("YYYY-MM-DD")

    // let allSubsTypeCompHistories = null;
    // allSubsTypeCompHistories = await YPCGetAxiosAlt(xget, setXGet, 'getAllSubsTypeCompHistoriesURL', newDate);
    // console.log('PIPIPIPIPIPIPIP', allSubsTypeCompHistories);

    // monthly_sub_reqd_dict = allSubsTypeCompHistories.monthly_sub_reqd_dict;

    let pay_subscription_dict = allSubsTypeCompHistories.pay_subscription_dict;

    let dateList = [];
    let altDateList = [];
    let subTotal = 0.0;
    let noSubscribeTotal = 0.0;
    for (let count=0; count<7; count ++) {

      let sDate = new moment(selectedDate).startOf('week').add(count + 1,'days');

      dateList.push(sDate.format("YYYY-MM-DD"));
      altDateList.push(sDate.format("ddd MMM D"));
      
      let subDate = sDate.toString().split(" ");

      let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

      var order_structure_list =  await YPCGetAxiosAlt(xget, setXGet, 'getOrdersAllURL', dateString);




      order_structure_list.map((orderEntry) => {

        // console.log('AJAJAJAJAJAJ', count, JSON.parse(orderEntry.order));

        // console.log('APAPAPAPAPAP', orderEntry);

        let order = JSON.parse(orderEntry.order).fullList;
        
        // console.log('AMAMAMAMAMAM', order);

        let orderPresent = false;
        order.map((record) => {
          if (record.dinnercount !== null || record.lunchcount !== null || record.lunchboxcount !== null) {
            orderPresent = true;
          };
        });

        if (parseInt(selectedDate.format('YY')) > 21 && orderPresent) {

          // big change
          // let topOrder = JSON.parse(orderEntry.order);

          // if (topOrder.hasOwnProperty('subscriptionType')) {
          //   if (topOrder.subscriptionType === "None") {
          //       // subTotal = subTotal + noneSubsriberCost;
          //       noSubscribeTotal = noSubscribeTotal + noneSubsriberCost;
          //   }
          // } else {
          //   if (orderEntry.subscription_type === 'None') {
          //     // subTotal = subTotal + noneSubsriberCost;
          //     noSubscribeTotal = noSubscribeTotal + noneSubsriberCost;
          //   };
          // };

          // console.log('IIIIIIIIIIIIIIIIIIIIIIII', orderEntry['username'], allSubsTypeCompHistories.subs_type_dict[orderEntry['username']]);
          if (allSubsTypeCompHistories.subs_type_dict[orderEntry['username']] === 'None' || allSubsTypeCompHistories.subs_type_dict[orderEntry['username']] === 'None5') {
            noSubscribeTotal = noSubscribeTotal + noneSubscriberCostDict[allSubsTypeCompHistories.subs_type_dict[orderEntry['username']]];            
            // noSubscribeTotal = noSubscribeTotal + noneSubsriberCost;
          };

        };


        order.map((record) => {
          if (record.dinnercount !== null) {
            subTotal = subTotal + record.dinnercount * record.price;
          };
          if (record.lunchcount !== null) {
            subTotal = subTotal + record.lunchcount * record.price;
          };
          if (record.lunchboxcount !== null) {
            subTotal = subTotal + record.lunchboxcount * record.price;
          }
        });
      });

      // console.log('JWJWJWJWJWJWJWJWJWJWJWJWJ', count, subTotal);

    }  // end of for loop

    // console.log('JAJAJAJAJAJAJAJAJAJ', subTotal);

    let totalSubscriptionCost = 0.0
    // Object.keys(monthly_sub_reqd_dict).map((user) => {
    Object.keys(pay_subscription_dict).map((user) => {
      if (pay_subscription_dict[user]) {
        // totalSubscriptionCost = totalSubscriptionCost + bi_monthly_subscription_cost;
        totalSubscriptionCost = totalSubscriptionCost + subscriptionCostLookup[allSubsTypeCompHistories.subs_type_dict[user]];
      }
    });

    let tempList = []
      tempList.push({
        id: 0,
        price: translate(props.language,'Subscription Total'),
        total: totalSubscriptionCost
      });
      tempList.push({
        id: 1,
        price: translate(props.language,'Non Membership Total'),
        total: noSubscribeTotal
      });
      tempList.push({
        id: 2,
        price: translate(props.language,'Food Total'),
        total: subTotal
      });
      tempList.push({
          id: 3,
          // price: 'GST (5%)',
          price: translate(props.language,'GST') + ' (5%)',
          total: gst * (subTotal + noSubscribeTotal + totalSubscriptionCost)
      });
      tempList.push({
          id: 4,
          price: translate(props.language,'PST') + ' (9.975%)',
          total: pst * (subTotal + noSubscribeTotal + totalSubscriptionCost)
      });
      tempList.push({
          id: 5,
          price: translate(props.language,'Total'),        
          total: (subTotal + noSubscribeTotal + totalSubscriptionCost) + gst * (subTotal + noSubscribeTotal + totalSubscriptionCost) + pst * (subTotal + noSubscribeTotal + totalSubscriptionCost)
      });

      // console.log('KJKJKJKJKJK', tempList);

      // setsummaryEverythingList(tempList);

      setDateDisplay(altDateList);

      // get all unpaid orders

      let sDate = new moment(selectedDate).startOf('week').add(1,'days');
      let subDate = sDate.toString().split(" ");
      let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
      var order_structure_list =  await YPCGetAxiosAlt(xget, setXGet, 'getWeeklyUnpaidOrdersURL', dateString);

      tempList = []
      let accountedUsers = []
      order_structure_list.map((value) => {
        let temp = value;

        // if (monthly_sub_reqd_dict[temp.username]) {
        if (pay_subscription_dict[temp.username]) {          
          // temp.subscription = bi_monthly_subscription_cost;
          temp.subscription = subscriptionCostLookup[allSubsTypeCompHistories.subs_type_dict[temp.username]];
          // temp.total = temp.total + bi_monthly_subscription_cost * (0.05 + 0.09975 + 1.0);
          // temp.total = temp.total + subscriptionCostLookup[allSubsTypeCompHistories.subs_type_dict[temp.username]] * (0.05 + 0.09975 + 1.0);
        } else {
          temp.subscription = 0.0;
        };
        // temp['subscription'] =   monthly_sub_reqd_dict[temp['username']] ? bi_monthly_subscription_cost : 0.0;
        // temp['total'] = temp['total'] + temp['subscription']

        temp.subs_type = allSubsTypeCompHistories['subs_type_dict'][temp.username]
        temp.company_name = allSubsTypeCompHistories['comp_type_dict'][temp.username]

        if (allSubsTypeCompHistories['subs_type_dict'][temp.username] === 'None' || allSubsTypeCompHistories['subs_type_dict'][temp.username] === 'None5') {
          let noneTempSubscriberCost = noneSubscriberCostDict[allSubsTypeCompHistories['subs_type_dict'][temp.username]];
          temp.no_subscription_total = (temp.Mon > 0.10 ? noneTempSubscriberCost : 0.0) + (temp.Tue > 0.10  ? noneTempSubscriberCost : 0.0) + (temp.Wed > 0.10  ? noneTempSubscriberCost : 0.0) + 
                    (temp.Thu > 0.10  ? noneTempSubscriberCost : 0.0) + (temp.Fri > 0.10 ? noneTempSubscriberCost : 0.0) + (temp.Sat > 0.10 ? noneTempSubscriberCost : 0.0) + (temp.Sun > 0.10  ? noneTempSubscriberCost : 0.0);
          // temp.no_subscription_total = (temp.Mon > 0.10 ? noneSubsriberCost : 0.0) + (temp.Tue > 0.10  ? noneSubsriberCost : 0.0) + (temp.Wed > 0.10  ? noneSubsriberCost : 0.0) + 
          //           (temp.Thu > 0.10  ? noneSubsriberCost : 0.0) + (temp.Fri > 0.10 ? noneSubsriberCost : 0.0) + (temp.Sat > 0.10 ? noneSubsriberCost : 0.0) + (temp.Sun > 0.10  ? noneSubsriberCost : 0.0);
        } else {
          temp.no_subscription_total = 0.0
        };

        if (allSubsTypeCompHistories['subs_type_dict'][temp.username] === 'Free' || allSubsTypeCompHistories['subs_type_dict'][temp.username] === 'CmpPaysAll') {
          temp.Mon = 0.0;
          temp.Tue = 0.0;
          temp.Wed = 0.0;
          temp.Thu = 0.0;
          temp.Fri = 0.0;
          temp.Sat = 0.0;
          temp.Sun = 0.0;
        }

        temp.total = (temp.Mon + temp.Tue + temp.Wed + temp.Thu + temp.Fri + temp.Sat + temp.Sun + temp.no_subscription_total + temp.subscription) * (0.05 + 0.09975 + 1.0);

        // if (temp.total === 0.0 && temp.subs_type === 'None') {
        //   // pass
        // } else {
          tempList.push(temp);
        // }

      });

      // company stuff
      const companyAllSet = new Set(); 
      tempList.map((value) => {
        if (value.subs_type === 'CmpPaysAll' || value.subs_type === 'CmpPaysSub' || value.subs_type === 'MngPaysAll' || value.subs_type === 'MngPaysSub') {
          companyAllSet.add(value.company_name);
        };
      });


      let compDict = {};
      companyAllSet.forEach((company) => {
          let CPAcnt = 0;
          let CPScnt = 0;
          let MPAcnt = 0;
          let MPScnt = 0;
          let tempMon = 0.0;
          let tempTue = 0.0;
          let tempWed = 0.0;
          let tempThu = 0.0;
          let tempFri = 0.0;
          let tempSat = 0.0;
          let tempSun = 0.0;

          tempList.map((value) => {
            if ((value.subs_type === 'CmpPaysAll' || value.subs_type === 'CmpPaysSub' || value.subs_type === 'MngPaysAll' || value.subs_type === 'MngPaysSub') && value.company_name === company) {
              if (value.subs_type === 'CmpPaysAll') {
                CPAcnt += 1;
              } else if (value.subs_type === 'CmpPaysSub') {
                CPScnt += 1;
              } else if (value.subs_type === 'MngPaysAll') {
                MPAcnt += 1;
              } else if (value.subs_type === 'MngPaysSub') {
                MPScnt += 1;
              };
              tempMon = tempMon + value.Mon;
              tempTue = tempTue + value.Tue;
              tempWed = tempWed + value.Wed;
              tempThu = tempThu + value.Thu;
              tempFri = tempFri + value.Fri;
              tempSat = tempSat + value.Sat;
              tempSun = tempSun + value.Sun;
            };
          });

          let cmpType = 'PaysSub';
          if (CPAcnt + MPAcnt > CPScnt + MPScnt) {
            cmpType = 'PaysAll';
          };

          if (cmpType === 'PaysSub') {
            compDict[company] = {CPAcnt:CPAcnt, CPScnt:CPScnt, MPAcnt:MPAcnt, MPScnt:MPScnt, Mon: 0, Tue:0, Wed:0, Thu:0, Fri:0, Sat:0, Sun:0, 
                                subscription: (CPScnt + MPScnt) * subscriptionCostLookup['Company Subscription'],
                                total: (CPScnt + MPScnt) * subscriptionCostLookup['Company Subscription'] * (0.05 + 0.09975 + 1.0)};
          } else {   // PaysAll
            compDict[company] = {CPAcnt:CPAcnt, CPScnt:CPScnt, MPAcnt:MPAcnt, MPScnt:MPScnt, Mon: tempMon, Tue:tempTue, Wed:tempWed, Thu:tempThu, Fri:tempFri, Sat:tempSat, Sun:tempSun,
              subscription: (CPAcnt + MPAcnt) * subscriptionCostLookup['Company Subscription'],
              total: ((tempMon + tempTue + tempWed + tempThu + tempFri + tempSat + tempSun) +  (CPScnt + MPScnt) * subscriptionCostLookup['Company Subscription']) * (0.05 + 0.09975 + 1.0)};
          }
      });

      let newTempList = [];
      tempList.map((value) => {
        let temp = value
        if ((temp.subs_type === 'CmpPaysAll' || temp.subs_type === 'CmpPaysSub' || temp.subs_type === 'MngPaysAll' || temp.subs_type === 'MngPaysSub')) {
          if (temp.subs_type === 'CmpPaysSub') {

          } else if (temp.subs_type === 'MngPaysSub') {
            // temp.Mon = compDict[temp.company_name]['Mon'];
            // temp.Tue = compDict[temp.company_name]['Tue'];
            // temp.Wed = compDict[temp.company_name]['Wed'];
            // temp.Thu = compDict[temp.company_name]['Thu'];
            // temp.Fri = compDict[temp.company_name]['Fri'];
            // temp.Sat = compDict[temp.company_name]['Sat'];
            // temp.Sun = compDict[temp.company_name]['Sun'];
            // temp.customer_ate = true;
            temp.subscription = compDict[temp.company_name]['subscription'];
            temp.total = compDict[temp.company_name]['total'];
          } else if (temp.subs_type === 'CmpPaysAll') {
            temp.Mon = 0;
            temp.Tue = 0;
            temp.Wed = 0;
            temp.Thu = 0;
            temp.Fri = 0;
            temp.Sat = 0;
            temp.Sun = 0;
            temp.total = 0;
          } else if (temp.subs_type === 'MngPaysAll') {
            temp.Mon = compDict[temp.company_name]['Mon'];
            temp.Tue = compDict[temp.company_name]['Tue'];
            temp.Wed = compDict[temp.company_name]['Wed'];
            temp.Thu = compDict[temp.company_name]['Thu'];
            temp.Fri = compDict[temp.company_name]['Fri'];
            temp.Sat = compDict[temp.company_name]['Sat'];
            temp.Sun = compDict[temp.company_name]['Sun'];
            // temp.customer_ate = true;
            temp.subscription = compDict[temp.company_name]['subscription'];
            temp.total = compDict[temp.company_name]['total'];
          }
        };
        
        newTempList.push(temp);
        

      });



      console.log('APAPAPAPAP', compDict);

      // end of company stuff

      // setDuesRows(tempList);
      setDuesRows(newTempList);

      // console.log('BPBPBPBPBP', order_structure_list);
      console.log('BPBPBPBPBP', tempList);


      let tempMon = 0.0;
      let tempTue = 0.0;
      let tempWed = 0.0;
      let tempThu = 0.0;
      let tempFri = 0.0;
      let tempSat = 0.0;
      let tempSun = 0.0;
      let tempSub = 0.0;
      let tempNoSub = 0.0;
      tempList.map((value) => {
        tempMon = tempMon + value.Mon;
        tempTue = tempTue + value.Tue;
        tempWed = tempWed + value.Wed;
        tempThu = tempThu + value.Thu;
        tempFri = tempFri + value.Fri;
        tempSat = tempSat + value.Sat;
        tempSun = tempSun + value.Sun;
        tempSub = tempSub + value.subscription;
        tempNoSub = tempNoSub + value.no_subscription_total;
      });


      setDailyTotalRows([{'id' : 1, 'subscription': tempSub, 'Mon': tempMon, 'Tue': tempTue,  'Wed': tempWed, 'Thu': tempThu, 'Fri': tempFri,  'Sat': tempSat,  'Sun': tempSun, 'no_subscription_total': tempNoSub }])

      tempList = []
      tempList.push({
        id: 0,
        price: translate(props.language,'Subscription Total'),
        total: tempSub
      });
      tempList.push({
        id: 1,
        price: translate(props.language,'Non Membership Total'),
        total: tempNoSub
      });
      tempList.push({
        id: 2,
        price: translate(props.language,'Food Total'),
        total: tempMon + tempTue + tempWed + tempThu + tempFri + tempSat + tempSun
      });
      tempList.push({
          id: 3,
          // price: 'GST (5%)',
          price: translate(props.language,'GST') + ' (5%)',
          // total: gst * (subTotal + noSubscribeTotal + totalSubscriptionCost)
          total: gst * (tempSub + tempNoSub + tempMon + tempTue + tempWed + tempThu + tempFri + tempSat + tempSun)
      });
      tempList.push({
          id: 4,
          price: translate(props.language,'PST') + ' (9.975%)',
          // total: pst * (subTotal + noSubscribeTotal + totalSubscriptionCost)
          total: pst * (tempSub + tempNoSub + tempMon + tempTue + tempWed + tempThu + tempFri + tempSat + tempSun)
      });
      tempList.push({
          id: 5,
          price: translate(props.language,'Total'),        
          // total: (subTotal + noSubscribeTotal + totalSubscriptionCost) + gst * (subTotal + noSubscribeTotal + totalSubscriptionCost) + pst * (subTotal + noSubscribeTotal + totalSubscriptionCost)
          total: (tempSub + tempNoSub + tempMon + tempTue + tempWed + tempThu + tempFri + tempSat + tempSun) * (1.0 + gst + pst)
      });

      setsummaryEverythingList(tempList);

      setHideSummary(false);


  }


  async function getUserNames() {
    // irv moded
    // let unames = await YPCGetAxios(xget, setXGet, 'getUsernamesURL', '');
    let unames = await YPCGetAxiosAlt(xget, setXGet, 'getUsernamesURL', '');
    setUsernameList(unames.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }));
    // console.log('IQIQIQIQIQI', test);
  }

    async function getMenu() {

        if (username === null || username === "") {
          return;
        };


        setHideSingleUser(true)

        // big change all below replaced by **A
        // get subscription type
        // let subType = 'None';
        // if (parseInt(selectedDate.format('YY')) > 21) {
        //   subType = await YPCGetAxiosAlt(xget, setXGet, 'getSubsciptionTypeURL', username);
        // };
        // setSubscriptionType(subType);


        // let subsTypeCompanyHistory = [];
        // let newDate = new moment(selectedDate).startOf('week').format("YYYY-MM-DD")
        // subsTypeCompanyHistory = await YPCGetAxiosAlt(xget, setXGet, 'getSubsTypeCompHistoryURL', [newDate, username]);
        // console.log('HIHIHIHIHIHIHI', subsTypeCompanyHistory);
        // setSubscribed(subsTypeCompanyHistory['monthly_sub_reqd']);
        // setSubsType(subsTypeCompanyHistory['subs_type']);
        // setCompType(subsTypeCompanyHistory['comp_type']);


        // ** A
        let subDate = selectedDate.toString().split(" ")
        let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];        

        let subType = 'None';
        let subsTypeCompanyHistory = [];
        subsTypeCompanyHistory = await YPCGetAxiosAlt(xget, setXGet, 'getDateSubscriptionTypeURL', [dateString, username]);
        setSubscribed(subsTypeCompanyHistory['pay_subscription']);
        setSubsType(subsTypeCompanyHistory['subs_type']);
        setCompType(subsTypeCompanyHistory['comp_type']);

        subType = subsTypeCompanyHistory['subs_type']
        setSubscriptionType(subType);


        let mainsOList = [];
        let starterOList = [];
        let dessertsOList = [];
        let fruitsOList = [];
        let drinksOList = [];

        let dateList = [];

        let lunchODelivery = [];
        let dinnerODelivery = [];

        let subscriptionTypeOList = [];

        for (let count=0; count<7; count ++) {

                let sDate = new moment(selectedDate).startOf('week').add(count + 1,'days');

                dateList.push(sDate.format("YYYY-MM-DD"));
                
                let subDate = sDate.toString().split(" ");

                // let subDate = selectedDate.toString().split(" ")
                let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
                // var menu_structure = await YPCGetAxios(xget, setXGet, 'getMenuURL', dateString);
                var menu_structure = await YPCGetAxiosAlt(xget, setXGet, 'getMenuURL', dateString);
                let result = [];
                if (menu_structure.length !== 0) {
                console.log('ZMZMZMZMZMZM', JSON.parse(menu_structure[0].menu));
                result = JSON.parse(menu_structure[0].menu);
                } else {
                console.log('ZMZMZMZMZMZM', menu_structure);
                }


                let orderResult = [];
                let order = {};
                let lunchDelivery = '';
                let dinnerDelivery = '';                

                let subscriptionTypeValue = 'None';

                // var order_structure =  await YPCGetAxios(xget, setXGet, 'getOrderURL', [dateString, username]);
                var order_structure =  await YPCGetAxiosAlt(xget, setXGet, 'getOrderURL', [dateString, username]);                

                if (order_structure.length !== 0) {
                    // console.log('ZOZOZOZOZOZ', JSON.parse(order_structure[0].order));
                    orderResult = JSON.parse(order_structure[0].order);
                    order = orderResult.minimalList;

                    dinnerDelivery = String(orderResult.dinnerTimeHour) + ':' + String(orderResult.dinnerTimeMinute)
                    lunchDelivery = String(orderResult.lunchTimeHour) + ':' + String(orderResult.lunchTimeMinute)                    

                    if (orderResult.hasOwnProperty('subscriptionType')) {
                      subscriptionTypeValue = orderResult.subscriptionType;
                    } else {
                      subscriptionTypeValue = subType;
                    }

                } else {
                  // console.log('ZOZOZOZOZO', order_structure);
                  order = {crap: {tests: 1}};
                  dinnerDelivery = '';
                  lunchDelivery = ''

                  subscriptionTypeValue = subType;
                }


                let translateList = [];

                if (result.length !== 0) {
                    let mainsList = [];
                    let starterList = [];
                    let dessertsList = [];
                    let fruitsList = [];
                    let drinksList = [];
                    let category = null;
                    let tempValue = null;
                    let tempLunch = {};
                    let tempDinner = {};
                    result.map((value) => {

                          translateList.push(value);

                          tempValue = {
                              category: value.category,
                              dish: value.dish,
                              id: value.id,
                              name: value.name,
                              price: value.price,
                              side: value.side,
                              uuid: value.uuid,
                              lunchcount: value.uuid in order ? order[value.uuid].lunchcount : null,
                              dinnercount:  value.uuid in order ? order[value.uuid].dinnercount : null,
                              lunchboxcount:  value.uuid in order ? order[value.uuid].lunchboxcount : null,
                              spicelevel: value.spicelevel,
                              vegetarian: value.vegetarian,
                              gluten_free: value.gluten_free,
                              peanut_free: value.peanut_free,
                              baby_friendly: value.baby_friendly,
                          };
                          if (value.lunchbox) {
                              tempLunch[value.uuid] = true;
                          };
                          if (value.dinneronly) {
                              tempDinner[value.uuid] = true;
                          };
                          if (!value.side) {
                              category = value.category.slice();
                          };
                          if (category === 'Starter') {
                              starterList.push(tempValue);
                          } else if (category === 'Main') {
                              mainsList.push(tempValue);
                          } else if (category === 'Dessert') {
                              dessertsList.push(tempValue);
                          } else if (category === 'Drink') {
                              drinksList.push(tempValue);
                          } else if (category === 'Fruit') {
                              fruitsList.push(tempValue)
                          };

                    });

                    xtranslate.addLookupNew(translateList);

                    mainsOList.push(mainsList);
                    starterOList.push(starterList);
                    dessertsOList.push(dessertsList);
                    fruitsOList.push(fruitsList);
                    drinksOList.push(drinksList);

                    dinnerODelivery.push(dinnerDelivery);
                    lunchODelivery.push(lunchDelivery);

                    // following is modified on major change
                    // subscriptionTypeOList.push(subscriptionTypeValue);
                    subscriptionTypeOList.push(subType);

                } else {

                    mainsOList.push([]);
                    starterOList.push([]);
                    dessertsOList.push([]);
                    fruitsOList.push([]);
                    drinksOList.push([]);

                    dinnerODelivery.push('');
                    lunchODelivery.push('');

                    subscriptionTypeOList.push(subType);

                };

        }; // for loop end
    
        setMainsOrderArrayList(mainsOList);
        setStarterOrderArrayList(starterOList);
        setDessertsOrderArrayList(dessertsOList);
        setFruitsOrderArrayList(fruitsOList);
        setDrinksOrderArrayList(drinksOList);

        setDatesArray(dateList);

        

        setLunchDeliveryTime(lunchODelivery);
        setDinnerDeliveryTime(dinnerODelivery);

        setSubscriptionTypeArrayList(subscriptionTypeOList)

        setHideSingleUser(false);

    };


  const handleDateChange = (date) => {
    console.log('GAGAGA', moment(date).format("YYYY-MM-DDTHH:mm"));
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };





  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  const summaryColumns = [
    {
      field: 'item',
      headerName: translate(props.language, 'Item'),
      // headerName: 'Item',
      width: 300,
      sortable: false,
      valueFormatter: (params) => {
        return xtranslate.x(props.language, params.value);
      },
    },
    {
        field: 'itemtype',
        headerName: translate(props.language, 'Time/Type'),
        // headerName: 'Type',
        width: 200,
        sortable: false,
      },
      {
        field: 'quantity',
        headerName: translate(props.language, 'Quantity'),
        // headerName: 'Quantity',
        type: 'number',
        width: 140,
        sortable: false
      },      
    {
      field: 'price',
      headerName: translate(props.language, 'Price'),
      // headerName: 'Price',
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
        field: 'total',
        headerName: translate(props.language, 'Total'),
        // headerName: 'Total',
        type: 'number',
        width: 140,
        sortable: false,
        valueFormatter: (params) => {
            if (typeof params.value === 'string' || params.value instanceof String) {
                return params.value;
            } else {
                const valueFormatted = currencyFormatter.format(Number(params.value));
                return `${valueFormatted}`;
            }
          },
      },
  ];


  const dailyTotalColumns = [
    {
      field: 'subscription',
      headerName: 'Subscription',
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'no_subscription_total',
      headerName: 'Non Membership',
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Mon',
      headerName: dateDisplay[0],
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Tue',
      headerName: dateDisplay[1],
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Wed',
      headerName: dateDisplay[2],
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Thu',
      headerName: dateDisplay[3],
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Fri',
      headerName: dateDisplay[4],
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Sat',
      headerName: dateDisplay[5],
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Sun',
      headerName: dateDisplay[6],
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },



  ];  


  const duesColumns = [
    {
      field: 'username',
      headerName: 'User',
      width: 300,
      sortable: true,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
    },
    {
      field: 'subscription',
      headerName: 'Subscription',
      width: 140,
      type: 'number',
      sortable: true,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
        green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
        red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },    
    {
      field: 'no_subscription_total',
      headerName: 'Non Membership',
      width: 140,
      type: 'number',
      sortable: true,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
        green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
        red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },

    {
      field: 'Mon',
      headerName: dateDisplay[0],
      width: 140,
      type: 'number',
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
        green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
        red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Tue',
      headerName: dateDisplay[1],
      width: 140,
      type: 'number',
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Wed',
      headerName: dateDisplay[2],
      width: 140,
      type: 'number',
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Thu',
      headerName: dateDisplay[3],
      width: 140,
      type: 'number',
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Fri',
      headerName: dateDisplay[4],
      width: 140,
      type: 'number',
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Sat',
      headerName: dateDisplay[5],
      width: 140,
      type: 'number',
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Sun',
      headerName: dateDisplay[6],
      width: 140,
      type: 'number',
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },    

    {
      field: 'total',
      headerName: 'Total (+taxes)',
      width: 140,
      type: 'number',
      sortable: true,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'user_paid',
      headerName: 'Paid',
      width: 140,
      // type: 'boolean',
      sortable: true,
      valueFormatter: (params) => {
        if (params.row.user_paid || params.row.total === 0.0) {
          return 'Yes'
        } else if (!params.row.user_paid && params.row.total !== 0.0) {
          return 'No'
        }
      },
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
    },
    {
      field: 'subs_type',
      headerName: 'Subcription Type',
      width: 140,
      sortable: true,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
    },
    {
      field: 'company_name',
      headerName: 'Company',
      width: 140,
      sortable: true,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      // valueFormatter: (params) => {
      //   if (params.row.subs_type === 'None' || params.row.subs_type === 'None5' ||  params.row.subs_type === 'Family' || params.row.subs_type === 'Individual'  || params.row.subs_type === 'Free Subscription') {
      //     return '';
      //   } else {
      //     return params.value;
      //   }
      // },
    },
    {
      field: 'customer_ate',
      headerName: 'Ate',
      width: 140,
      // type: 'boolean',
      sortable: true,
      valueFormatter: (params) => {
        if (params.row.customer_ate) {
          return 'Yes'
        } else if (!params.row.customer_ate) {
          return 'No'
        }
      },
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          green: (params.row.user_paid || params.row.total === 0.0) ? true: false,
          red: (!params.row.user_paid && params.row.total !== 0.0) ? true: false,
      }),
      renderCell: (params) => {
        // if (params.row.user_paid || params.row.total === 0.0) {
        //   return (
        //       <Button variant="outlined" size="small" startIcon={<EditIcon />}>
        //         UnPay
        //       </Button>
        //   )
        // } else 
        if (params.row.total !== 0.0) {
          return (
            <Button variant="outlined" size="small" startIcon={<EditIcon />}
              onClick={() => {
                payEdit(params.row);
              }}
            >
              Edit
          </Button>
          )
        }
      },
    },


  ];


  function payEdit(row) {
    setShowPayEditDialog(row);

  }


  function showPayEdit() {
    return (
      <div>
        <Dialog open={showPayEditDialog !== null} >
          <DialogTitle>
              Are you sure you want to change user <br></br>{"< " + showPayEditDialog.username + " >'s"} <br></br> payment status to 
              {showPayEditDialog.user_paid ? " Unpaid?" : " Paid?" }
          </DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePayEditCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handlePayEditSave} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }


  const handlePayEditCancel = () => {
    setShowPayEditDialog(null);
  }

  async function handlePayEditSave() {

    let sDate = new moment(selectedDate).startOf('week').add(1,'days');

    let xsubDate = sDate.toString().split(" ")
    let xdateString = xsubDate[1] + xsubDate[2] + xsubDate[0] + xsubDate[3]

    console.log('BABABAABABAB', xdateString);

    let edit_type = 'create';
    if (showPayEditDialog.user_paid) {
      edit_type = 'delete';
    };

    let edit_data = {
        data: {
          username: showPayEditDialog.username,
          datestring : xdateString,
          transaction_type : 'weekly',
          details : 'manual change'
        },
        edit_type : edit_type

    };


    let result = ''
    result = await YPCPutAxios2Alt(setXPost, 'PayEditURL', edit_data, '');

    setDuesRows(prevValue =>
      [...prevValue].map(el =>
          el.username === showPayEditDialog.username ? ({...el, user_paid: !showPayEditDialog.user_paid}) : el
        )
      );


    setShowPayEditDialog(null);

  }


// set all to paid


function payAll() {
  setShowPayAllDialog(true);
}


function showPayAll() {
  return (
    <div>
      <Dialog open={showPayAllDialog === true} >
        <DialogTitle>
            Are you sure you want to set ALL to paid?  You will not be able to revert back to original settings.
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePayAllCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePayAllSave} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const handlePayAllCancel = () => {
  setShowPayAllDialog(false);
}

async function handlePayAllSave() {

  let sDate = new moment(selectedDate).startOf('week').add(1,'days');

  let xsubDate = sDate.toString().split(" ")
  let xdateString = xsubDate[1] + xsubDate[2] + xsubDate[0] + xsubDate[3]

  // console.log('BABABAABABAB', xdateString);

  let save_data = {
      data: duesRows,
      datestring : xdateString,
      details : 'manual change',
      transaction_type : 'weekly'
  };


  let result = ''
  result = await YPCPutAxios2Alt(setXPost, 'setPayAllURL', save_data, '');

  console.log('CACACACACAC', result);

  setShowPayAllDialog(false);

  // reload it all here
  getWeekTotalAll()

}


// end of set all to paid



  const finalColumns = [
    {
      field: 'price',
      headerName: translate(props.language, 'Price'),
      type: 'number',
      width: 300,
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
        field: 'total',
        headerName: translate(props.language, 'Total'),
        type: 'number',
        width: 140,
        sortable: false,
        valueFormatter: (params) => {
            if (typeof params.value === 'string' || params.value instanceof String) {
                return params.value;
            } else {
                const valueFormatted = currencyFormatter.format(Number(params.value));
                return `${valueFormatted}`;
            }
          },
      },
  ];



  function calculatesummaryTable() {
    let combinedGiantOrderList = [];
    let giantOrderList = [];
    let weeklyTotal = 0.0;
    let weeklyNoSubscribeTotal = 0.0;
    let itemId = 0;
    let dayList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday','Saturday','Sunday'];
    for (let count=0; count<7; count ++) {

            let globalOrderList = [];
            globalOrderList.push(...starterOrderArrayList[count], ...mainsOrderArrayList[count], ...dessertsOrderArrayList[count],
                   ...drinksOrderArrayList[count], ...fruitsOrderArrayList[count]);
            let tempList = [];
            // let itemId = 0;
            let item = null;
            globalOrderList.map((value) => {
                if (!value.side) {
                    if (value.lunchcount !== null || value.dinnercount !== null || value.lunchboxcount !== null) {
                        item = value.dish;
                        if (value.lunchcount !== null) {
                            tempList.push({
                                id: itemId,
                                item: value.dish,
                                // itemtype: value.category + " (Lunch)",
                                itemtype: lunchDeliveryTime[count]  + " (" + translate(props.language, 'Lunch') + ") " + translate(props.language, value.category),
                                quantity: value.lunchcount,
                                price: value.price,
                                total: value.lunchcount * value.price
                            });
                            itemId = itemId + 1;
                        };
                        if (value.dinnercount !== null) {
                            tempList.push({
                                id: itemId,
                                item: value.dish,
                                // itemtype: value.category + " (Dinner)",
                                itemtype: dinnerDeliveryTime[count] + " (" + translate(props.language, 'Dinner') + ") " + translate(props.language, value.category),
                                quantity: value.dinnercount,
                                price: value.price,
                                total: value.dinnercount * value.price
                            });
                            itemId = itemId + 1;
                        };
                        if (value.lunchboxcount !== null) {
                            tempList.push({
                                id: itemId,
                                item: value.dish,
                                // itemtype: value.category + " (Lunchbox)",
                                itemtype: dinnerDeliveryTime[count]  + " (" + translate(props.language, 'Lunchbox') + ") " + translate(props.language, value.category),
                                quantity: value.lunchboxcount,
                                price: value.price,
                                total: value.lunchboxcount * value.price
                            });
                            itemId = itemId + 1;
                        };
                    }
                }
            });

            let subTotal = 0;
            tempList.map((value) => {
                subTotal = subTotal + value.total;
            });


            // check on subscription
            // if (subscriptionType === 'None' && tempList.length !== 0) {
            let subSub = 0.0
            // big change
            if ( (subscriptionTypeArrayList[count] === 'None' || subscriptionTypeArrayList[count] === 'None5') && tempList.length !== 0) {
              // subSub = noneSubsriberCost;
              subSub = noneSubscriberCostDict[subscriptionTypeArrayList[count]];
              // subTotal = subTotal + noneSubsriberCost;
              // weeklyNoSubscribeTotal = weeklyNoSubscribeTotal + noneSubsriberCost;
              weeklyNoSubscribeTotal = weeklyNoSubscribeTotal + noneSubscriberCostDict[subscriptionTypeArrayList[count]];
              tempList.push({
                id: itemId,
                item: translate(props.language, 'Non-member fee (cooking & dishwashing)'),
                itemtype: null,
                quantity: null,
                // price: noneSubsriberCost,
                // total: noneSubsriberCost
                price: noneSubscriberCostDict[subscriptionTypeArrayList[count]],
                total: noneSubscriberCostDict[subscriptionTypeArrayList[count]]
              });
              itemId = itemId + 1;
            };

            weeklyTotal = weeklyTotal + subTotal;

            let totalGST = gst * (subTotal + subSub);
            let totalPST = pst * (subTotal + subSub);
            let grandTotal = subTotal + subSub + totalGST + totalPST;

            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                price: " ",
                total: " "
            });
            itemId = itemId + 1;

            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                // price: 'SubTotal',
                price: translate(props.language,'SubTotal'),
                total: subTotal + subSub
            });
            itemId = itemId + 1;
            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                // price: 'GST (5%)',
                price: translate(props.language,'GST') + ' (5%)',
                total: totalGST
            });
            itemId = itemId + 1;
            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                // price: 'PST (9.975%)',
                price: translate(props.language,'PST') + ' (9.975%)',
                total: totalPST
            });
            itemId = itemId + 1;
            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                // price: 'Total',
                price: translate(props.language,'Total'),        
                total: grandTotal
            });

            giantOrderList.push(tempList);

      }; // end of for loop

      let tempList = []
    //   let itemId = 0;
      let item = null;

      tempList.push({
        id: itemId,
        price: translate(props.language,'Subscription Total'),
        // total: subscribed ? bi_monthly_subscription_cost: 0.0
        total: subscribed ? subscriptionCostLookup[subsType]: 0.0
      });
      itemId = itemId + 1;    

      tempList.push({
        id: itemId,
        price: 'Non Member Total',
        total: weeklyNoSubscribeTotal
      });
      itemId = itemId + 1;      
      tempList.push({
        id: itemId,
        price: translate(props.language,'Food Total'),
        total: weeklyTotal
      });

      // tempList.push({
      //   id: itemId,
      //   price: translate(props.language,'SubTotal'),
      //   total: weeklyTotal
      // });
      itemId = itemId + 1;
      tempList.push({
          id: itemId,
          // price: 'GST (5%)',
          price: translate(props.language,'GST') + ' (5%)',
          // total: gst * (weeklyTotal + weeklyNoSubscribeTotal + (subscribed ? bi_monthly_subscription_cost: 0.0))
          total: gst * (weeklyTotal + weeklyNoSubscribeTotal + (subscribed ? subscriptionCostLookup[subsType]: 0.0))
      });
      itemId = itemId + 1;
      tempList.push({
          id: itemId,
          price: translate(props.language,'PST') + ' (9.975%)',
          // total: pst * (weeklyTotal +  weeklyNoSubscribeTotal + (subscribed ? bi_monthly_subscription_cost: 0.0))
          total: pst * (weeklyTotal +  weeklyNoSubscribeTotal + (subscribed ? subscriptionCostLookup[subsType]: 0.0))
      });
      itemId = itemId + 1;
      tempList.push({
          id: itemId,
          price: translate(props.language,'Total'),        
          // total: (weeklyTotal +  weeklyNoSubscribeTotal + (subscribed ? bi_monthly_subscription_cost: 0.0)) + gst * (weeklyTotal +  weeklyNoSubscribeTotal + (subscribed ? bi_monthly_subscription_cost: 0.0)) + pst * (weeklyTotal +  weeklyNoSubscribeTotal + (subscribed ? bi_monthly_subscription_cost: 0.0))
          total: (weeklyTotal +  weeklyNoSubscribeTotal + (subscribed ? subscriptionCostLookup[subsType]: 0.0)) + gst * (weeklyTotal +  weeklyNoSubscribeTotal + (subscribed ? subscriptionCostLookup[subsType]: 0.0)) + pst * (weeklyTotal +  weeklyNoSubscribeTotal + (subscribed ? subscriptionCostLookup[subsType]: 0.0))
      });

      giantOrderList.push(tempList);
    //   combinedGiantOrderList.push(...tempList);

    setsummaryArrayList(giantOrderList);
    // setCombinedSummaryList(combinedGiantOrderList)

  };



  function summaryTable(index) {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          // rows={summaryList}
          rows={summaryArrayList[index]}
          columns={summaryColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  function finalEverythingTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          // rows={summaryList}
          rows={summaryEverythingList}
          columns={finalColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };


  function finalTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          // rows={summaryList}
          rows={summaryArrayList[7]}
          columns={finalColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };


  function combinedSummaryTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          // rows={summaryList}
          rows={combinedSummaryList}
          columns={summaryColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };


  const handleEnableSummary = (event) => {
      setEnableSummaryAll(event.target.checked);
    };

    const handleUserSelect = (params) => {
      // console.log('TITITITITITITI', params);
      setUsername(params.row.username);
    };
    

    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
      <MuiThemeProvider theme={theme}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>

                  {showPayEditDialog === null ? null : showPayEdit()}
                  {showPayAllDialog === null ? null : showPayAll()}
                  <Typography component="h4" variant="h4">
                      Master Weekly Billings Viewer
                      {/* {translate(props.language,'Weekly Billings Viewer')} */}
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>

          <Grid item xs={12}>

                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              {/* <KeyboardDatePicker */}
                              <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date picker dialog"
                                  // format="MM/dd/yyyy"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>
                          <p><small>{translate(props.language,"Choose any day in the week to get full week's order billings")}</small></p>
          </Grid>


          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Summary for Week of')} {datesArray[0]} {translate(props.language,'to')} {datesArray[6]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>

          {/* Summary */}
                <Grid item xs={12}>
                    <FormControlLabel control={
                      <Checkbox
                          value="checkedA"
                          checked={enableSummaryAll}
                          onChange={handleEnableSummary}
                          // inputProps={{
                          //   'aria-label': 'Checkbox A',
                          // }}
                        />}
                      label="Single User" />
                </Grid>                      

            {!enableSummaryAll ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                                  <br />
                                  <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Summary All Users')} &#8198;</div>
                    </Grid>

                    <Grid item xs={12}>
                        { !hideSummary ?     
                              <Grid item xs={12} >
                                  {finalEverythingTable()}

                              </Grid>
                        : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
                    </Grid>

                    <Grid item xs={12}>
                                  <br />
                                  <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Daily Totals All Users (no taxes)'} &#8198;</div>
                    </Grid>


                    <Grid item xs={12}>
                        { !hideSummary ?     
                              <Grid item xs={12} >

                                  <div style={{ width: '100%' }} >
                                    <DataGrid
                                    //   isCellEditable={handleEditable}
                                      className={classes.gridcell}
                                      hideFooter={true}
                                      hideFooterPagination={true}
                                      density="compact"
                                    //   rows={inventoryList}
                                      rows={dailyTotalRows}
                                      columns={dailyTotalColumns}
                                      // onCellEditCommit={handleCellEditCommit}
                                      disableColumnSelector={true}
                                      disableColumnFilter={true}
                                    //   onRowClick={handleStarterSelect}
                                      autoHeight={true}
                                    />
                                  </div>

                              </Grid>
                        : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
                    </Grid>


                    <Grid item xs={12}>
                                  <br />
                                  <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Outstanding Paid/Unpaid Users'} &#8198;</div>
                    </Grid>

                    <Grid item xs={12}>
                        { !hideSummary ?     
                              <Grid item xs={12} >

                                  <div style={{ width: '100%' }} >
                                    <DataGrid
                                    //   isCellEditable={handleEditable}
                                      className={classes.gridcell}
                                      // hideFooter={true}
                                      // hideFooterPagination={true}
                                      pageSize={25}
                                      density="compact"
                                    //   rows={inventoryList}
                                      rows={duesRows}
                                      columns={duesColumns}
                                      // onCellEditCommit={handleCellEditCommit}
                                      disableColumnSelector={true}
                                      disableColumnFilter={true}
                                      onRowClick={handleUserSelect}
                                      autoHeight={true}
                                      components={{
                                        NoRowsOverlay: () => (
                                          <div>
                                          </div>
                                        ),
                                        Toolbar:CustomToolbar
                                      }}
                                    />
                                  </div>

                              </Grid>
                        : <Skeleton variant="rectangular" width={"100%"} height={400} /> }
                    </Grid>

                    <Grid item xs={12}>
                      {  !hideSummary ?  
                        <Grid item xs={12}>
                            <Grid item xs={2} >
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    size="small"
                                    className={classes.button}
                                    onClick={payAll}
                                    disabled={false}
                                    aria-label="Set All Paid"
                                    style={{backgroundColor: '#78cbff', color:"white"}}
                                >
                                    Set All Paid
                                </Button>   
                            </Grid>
                            <Grid item xs={10} >
                            </Grid>
                        </Grid>
                        :
                        null
                      }
                    </Grid>




                </Grid>





              : null}

          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'User Name')} &#8198;</div>
          </Grid>

            <Grid container spacing={2} item xs={12} direction='row' justifyContent='left'>
              <Grid item xs={3}>
                  <Autocomplete
                      onChange={(event, value) => setUsername(value)}
                      id="combo-box-username"
                      value={username}
                      options={usernameList}
                      // getOptionLabel={(option) => option.name}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label={translate(props.language, 'User Name')} InputLabelProps={{
                          shrink: true,
                          style: {color: '#78cbff'},
                      }}
                      // variant="outlined"
                      />} 
                  />
                </Grid>

                <Grid item xs={3}>
                  <p></p>
                  <Typography >
                      Subscription Type - {subsType}
                  </Typography>
                </Grid>

                { subsType === 'CmpPaysAll' || subsType === 'CmpPaysSub' || subsType === 'MngPaysAll' || subsType === 'MngPaysSub' ?  
                    <Grid item xs={3}>
                        <p></p>
                        <Typography >
                          Company Name - {compType}
                        </Typography>
                    </Grid>
                  : null
                }

            </Grid>


          {/* Start of Check Balances */}

          {/* User Summary */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'User Summary')} &#8198;</div>
          </Grid>

          <Grid item xs={12}>
              { !hideSingleUser ?     
                    <Grid item xs={12} >
                        {finalTable()}

                    </Grid>
              : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
          </Grid>


          {/* Monday */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Daily')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Monday')} {datesArray[0]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { !hideSingleUser ?     
                    <Grid item xs={12} >
                        {summaryTable(0)}
                    </Grid>
              : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
          </Grid>

          {/* Tuesday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Tuesday')} {datesArray[1]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { !hideSingleUser ?     
                    <Grid item xs={12} >
                        {summaryTable(1)}

                    </Grid>
              : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
          </Grid>

          {/* Wednesday */}
           <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Wednesday')} {datesArray[2]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { !hideSingleUser ?     
                    <Grid item xs={12} >
                        {summaryTable(2)}

                    </Grid>
              : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
          </Grid>

          {/* Thursday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Thursday')} {datesArray[3]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { !hideSingleUser ?     
                    <Grid item xs={12} >
                        {summaryTable(3)}

                    </Grid>
              : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
          </Grid>

          {/* Friday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Friday')} {datesArray[4]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { !hideSingleUser ?     
                    <Grid item xs={12} >
                        {summaryTable(4)}

                    </Grid>
              : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
          </Grid>

          {/* Saturday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Saturday')} {datesArray[5]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { !hideSingleUser ?     
                    <Grid item xs={12} >
                        {summaryTable(5)}

                    </Grid>
              : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
          </Grid>

          {/* Sunday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Sunday')} {datesArray[6]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { !hideSingleUser ?     
                    <Grid item xs={12} >
                        {summaryTable(6)}

                    </Grid>
              : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
          </Grid>


          {/* End of Check Balances */}

          {/* Test Section */}

          {/* <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Test')}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={8} >
                        {combinedSummaryTable()}
                    </Grid>
              : null }
          </Grid> */}

          {/* End of Test Section */}

          <div><p></p></div>

          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}

        </Grid>
        </MuiThemeProvider>
      </Container>

      </ React.Fragment>



    );
}



