import axios from "axios";
import React from "react";
import config from './YPCconfig.js';


export default function YPCFilePutAxios2Alt(setXPost, url, data, id) {



    let baseURL = '';

    if (url === 'conciergeSaveURL') {
        baseURL = config.conciergeSaveURL + id.toString();
    };
    if (url === 'conciergeSaveOrderURL') {
        baseURL = config.conciergeSaveOrderURL + id.toString();
    };
    if (url === 'putProxysaveRecipeURL') {
        baseURL = config.putProxysaveRecipeURL;
    };
    if (url === 'saveRecipeURL') {
        baseURL = config.saveRecipeURL;
    };
    if (url === 'saveIngredientURL') {
        baseURL = config.saveIngredientURL;
    };
    if (url === 'putProxysaveIngredientURL') {
        baseURL = config.putProxysaveIngredientURL;
    };
    if (url === 'saveMenuURL') {
        baseURL = config.saveMenuURL;
    };
    if (url === 'testMenuURL') {
        baseURL = config.testMenuURL;
    };
    if (url === 'saveOrderURL') {
        baseURL = config.saveOrderURL;
    };
    if (url === 'saveXOrderURL') {
        baseURL = config.saveXOrderURL;
    };
    if (url === 'putProxyBatchOverwriteIngredientStockByNameURL') {
        baseURL = config.putProxyBatchOverwriteIngredientStockByNameURL;
    };
    if (url === 'fileUploadURL') {
        baseURL = config.fileUploadURL;
    };


    return new Promise(function (resolve, reject) {

        let token = localStorage.getItem('token');
        let userName = localStorage.getItem('userName')

        data.append('username', userName);
        data.append('token', token);

        // axios
        // .put(baseURL, 
        //     // {'content' : content} 

        //     {'data': data},
        //     // {'token': token, 'username': userName}


        axios({
            url: baseURL,
            method: "PUT",
            data: data,
            // content: {'token': token, 
            // 'username': userName}
        }            

        )
        .then((response) => {
            console.log('PPPPutOrderAxios', response.data);
                if (response.status == 200) {
                    setXPost('Saved to DB');
                    // setXPost(response.data.data);
                }
                else {
                    setXPost('Error saving to DB!');
                    // setXPost('Error');
                }
            var result = response.data;
            resolve(result);

        }).catch(error => {
            console.log('EEEEEEEEE',error);
            if ('response' in error) {
                if (error.response === undefined) {
                    setXPost('Error.  Contact YPC Help Line\n');
                } else if ('data' in error.response) {
                    if ('detail' in error.response.data) {
                        setXPost('Error saving to DB!\n ' + error.response.data.detail);
                    } else {
                        setXPost('Error saving to DB!');
                    }
                } else {
                    setXPost('Error saving to DB!');
                }
            } else {
                setXPost('Error saving to DB!');
            };
            // setXPost('Error');
            reject(error);
        });

    });
}

