import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();

const initSelectedDinnerTime = () => {
  let test = moment((new Date()));
  test.set('minute', 0);
  test.set('hour', 18);
  return test;
};

const initSelectedLunchTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 12);
  return test2;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


export default function YPCCustomerReturns(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [pickupsReturnsList, setPickupsReturnsList] = React.useState([]);

  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);


  const [gotReturns, setGotReturns] = React.useState(false)

  const [locale, setLocale] = React.useState();
  const [selectLine, setSelectLine] = React.useState(null);
  const [checkOpen, setCheckOpen] = React.useState(false);



  React.useEffect(() => {
    if (!gotReturns) {
        getReturns();
        setGotReturns(true);
    };
  });



  async function getReturns() {

    var customer_returns = await YPCGetAxiosAlt(xget, setXGet, 'getAllCustomerReturnsURL', '');

    setPickupsReturnsList(customer_returns['info']);

    // console.log('XXXAXAXAXAXAXAX', customer_returns);

  };


  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  function handleReload() {
    getReturns();

  };


  function handleCheck() {
    return (
      <div>
        <Dialog open={checkOpen !== false} >
          <DialogTitle>Are you sure you want to {checkOpen.message}</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCheckYes} color="primary">
              Yes
            </Button>
            <Button onClick={handleCheckCancel} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleCheckYes = () => {
    checkOpen['function']()
    setCheckOpen(false);
  };

  const handleCheckCancel = () => {
    setCheckOpen(false);
  }


  function preHandleDeleteLine() {
    if (selectLine === null || selectLine.length === 0) {
      return;
    };
    setCheckOpen({'open':true, 'message': 'delect selected entry?', 'function': handleDeleteLine})
  };

  function preHandleDeleteUser() {
    if (selectLine === null || selectLine.length === 0) {
      return;
    };
    setCheckOpen({'open':true, 'message': 'delect selected user all entries?', 'function': handleDeleteUser})
  };

  function preHandleDeleteAll() {
    if (selectLine === null || selectLine.length === 0) {
      return;
    };
    setCheckOpen({'open':true, 'message': 'delect all entries?', 'function': handleDeleteAll})
  };

  async function handleDeleteLine() {
    if (selectLine === null || selectLine.length === 0) {
      return;
    };

    await Promise.all([selectLine].map(async ( record ) => {
      if (record.username === selectLine.username) {
        await YPCPutAxios2Alt(setXPost, 'deleteCustomerReturnsURL', [record], '');
      }
    }));

    // deleteLines([selectLine]);
    setSelectLine(null);
    getReturns();
    setGotReturns(false);

  };

  async function handleDeleteUser() {
    if (selectLine === null || selectLine.length === 0) {
      return;
    };

    await Promise.all(pickupsReturnsList.map(async ( record ) => {
      if (record.username === selectLine.username) {
        await YPCPutAxios2Alt(setXPost, 'deleteCustomerReturnsURL', [record], '');
      }
    }));

    setSelectLine(null);
    getReturns();
    setGotReturns(false);

  };

  async function handleDeleteAll() {

    await Promise.all(pickupsReturnsList.map(async ( record ) => {
        await YPCPutAxios2Alt(setXPost, 'deleteCustomerReturnsURL', [record], '');
    }));

    setSelectLine(null);
    getReturns();
    setGotReturns(false);

  };
  
  const handleSelect = (params) => {
    setSelectLine(params.row);
    // console.log('PZPZPZPZPZPZP', params.row)
  }


  const columns = [
    {
      field: 'username',
      headerName: 'Username',
      type: 'string',
      width: 250,
      sortable: true,
      editable: false,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      type: 'string',
      sortable: true,
      width: 250,
      editable: false,
    },
    {
        field: 'last_name',
        headerName: 'Last Name',
        type: 'string',
        sortable: true,
        width: 250,
        editable: false,
    },
    {
        field: 'category',
        headerName: 'Category',
        type: 'string',
        sortable: true,
        width: 250,
        editable: false,
    },
    {
        field: 'datestring',
        headerName: 'Date/Time',
        type: 'string',
        sortable: true,
        width: 250,
        editable: false,
    },
    
  ];


  // Start Functions Section

  function PickupsReturnsTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >

      <div style={{ width: '100%' }} >
        <MuiThemeProvider theme={theme}>
        <DataGrid
        //   onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          // hideFooter={true}
          // hideFooterPagination={true}
          density="compact"
          rows={pickupsReturnsList}
          columns={columns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
          }}
        />
        </MuiThemeProvider>
      </div>
    );
  };


  // End of Starters Section


    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>

              {checkOpen === false ? null : handleCheck()}
                  <Typography component="h4" variant="h4">
                    Customer Pickups/Returns
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>




          {/*   Orders Section */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Pickups/Returns'} &#8198;</div>
          </Grid>


          <Grid container direction="row" alignItems="stretch" justify="left" spacing={2} xs={12}>
              {/* <Grid item xs={12}> */}
                        <Grid item xs={2}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleReload}
                                // disabled={false}
                                aria-label="reload button"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                Reload
                            </Button>
                        </Grid>

                        <Grid item xs={2}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={preHandleDeleteLine}
                                // disabled={false}
                                aria-label="delete line button"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                Delete Entry
                            </Button>
                        </Grid>

                        <Grid item xs={2}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={preHandleDeleteUser}
                                // disabled={false}
                                aria-label="delete user button"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                Delete User
                            </Button>
                        </Grid>

                        <Grid item xs={2}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={preHandleDeleteAll}
                                // disabled={false}
                                aria-label="delete all button"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                Delete All
                            </Button>
                        </Grid>


                {/* </Grid> */}
          </Grid>


          <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {PickupsReturnsTable()}
                    </Grid>
                  </Grid>
          </Grid>
          {/* Orders Section */}


          <div><p></p></div>


          {/* {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null} */}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


