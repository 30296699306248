import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();

const initSelectedDinnerTime = () => {
  let test = moment((new Date()));
  test.set('minute', 0);
  test.set('hour', 18);
  return test;
};

const initSelectedLunchTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 12);
  return test2;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


export default function YPCViewWeeklyOrders(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [menuLoaded, setMenuLoaded] = React.useState(false);
  const [menuDB, setMenuDB] = React.useState(null);

  const [daysOrderList, setDaysOrderList] = React.useState([]);
  const [altDaysOrderList, setAltDaysOrderList] = React.useState([]);
  const [tallyDaysOrderList, setTallyDaysOrderList] = React.useState([]);
  const [subTallyWeeklyOrdersList, setSubTallyWeeklyOrdersList] = React.useState([],[],[],[],[],[],[]);

  const [tallyWeeksOrderList, setTallyWeeksOrderList] = React.useState([]);

  const [selectStarterDelete, setSelectStarterDelete] = React.useState("");

  const [dessertsOrderList, setDessertsOrderList] = React.useState([]);
  const [selectDessertsDelete, setSelectDessertsDelete] = React.useState("");

  const [fruitsOrderList, setFruitsOrderList] = React.useState([]);
  const [selectFruitsDelete, setSelectFruitsDelete] = React.useState("");

  const [drinksOrderList, setDrinksOrderList] = React.useState([]);
  const [selectDrinksDelete, setSelectDrinksDelete] = React.useState("");

  const [mainsOrderList, setMainsOrderList] = React.useState([]);
  const [selectMainsDelete, setSelectMainsDelete] = React.useState("");
  
  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [lunchBoxChecked, setLunchBoxChecked] = React.useState({'test': 'test2'});
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = React.useState({'test': 'test2'});

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));


  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);

  const [locale, setLocale] = React.useState()

  const [lunchBlocked, setLunchBlocked] = React.useState(false);
  const [dinnerBlocked, setDinnerBlocked] = React.useState(false);

  const [dateDisplay, setDateDisplay] = React.useState(['','','','','','','']);

  const [recipeDict, setRecipeDict] = React.useState({});

  const [loading, setLoading] = React.useState(true);


  // const [value, setValue] = React.useState(new Date("2021-10-26 13:00"));

  // React.useEffect(() => {
  //   if (!menuLoaded) {
  //     handleLoadDB();
  //   }
  // }, [menuLoaded]);


//   React.useEffect(() => {
//     calculatesummaryTable();
//   }, [daysOrderList, props.language]);


  React.useEffect(() => {
    setLoading(true);
    getOrders();
  }, [selectedDate]);

  React.useEffect(() => {
    getTallys();
  }, [altDaysOrderList]);

  React.useEffect(() => {
    getWeeklyTallys();
  }, [subTallyWeeklyOrdersList]);

  // const handleLoadDB = () => {
  //   YPCGetMenuDB(xget, setXGet).then(
  //     function(value) { setMenuDB(value);
  //       xtranslate.addLookup(value);
  //     }
  //   );
  //   setMenuLoaded(true);
  // };




  function getTallys() {

    if (altDaysOrderList.length !== 0) {
      let tempDict = {};
      let tempList = [];

      altDaysOrderList.map((value) => {
        if (value['name'] !== "") {
          tempDict[value['name']] = 0;
          tempList.push(value)
        }
      });


      tempList.map((value) => {
        tempDict[value['name']] = tempDict[value['name']] + value['portions'];
      });

      let finalList = [];
      let id = 0;
      for (const [key, value] of Object.entries(tempDict)) {
        finalList.push({'name': key, 'portions': value, 'id': id })
        id = id + 1;
      }

      // console.log('MAMAMAMAMAM', finalList);

      setTallyDaysOrderList(finalList);
    } else {
      setTallyDaysOrderList([]);
    }

  };


  function getWeeklyTallys () {

    console.log('PZPZPZPZPZP', subTallyWeeklyOrdersList);

    // get list of names
    let namesList = [];
    let categoryList = ['Starter', 'Main', 'Dessert', 'Drink', 'Fruit'];
    categoryList.map((catValue) => {
        subTallyWeeklyOrdersList.map((preValue) => {
          preValue.map((value) => {
            if (value['name'] !== "" && catValue === value['category']) {
              if(!namesList.includes(value['name'])) {
                namesList.push(value["name"])
              }
            };
          });
        });
    });

    let headerLookup = ['portionsMon', 'portionsTue', 'portionsWed', 'portionsThu', 'portionsFri', 'portionsSat', 'portionsSun'];
    let finalList = []
    let id = 0
    namesList.map((valueName) => {
      let record = {'name': valueName, 'portionsMon': 0, 'portionsTue': 0, 'portionsWed': 0, 'portionsThu': 0, 'portionsFri': 0, 'portionsSat': 0, 'portionsSun': 0, 'category':'',  'subcategory':'','total':0, 'id': id};
      subTallyWeeklyOrdersList.map((altValue, index) => {

        altValue.map((daltValue) => {
            if (valueName === daltValue['name']) {
              record[headerLookup[index]] = record[headerLookup[index]] + daltValue['portions'];
              record['category'] = daltValue['category'];
              record['total'] = record['total'] + daltValue['portions'];
              record['subcategory'] = daltValue['subcategory'];
            };
          });
      
        id = id + 1;

      });
      finalList.push(record);
    });

    // console.log('HRHRHRHRHRH', finalList);
    if (finalList.length !== 0) {
      setLoading(false);
    };
    setTallyWeeksOrderList(finalList);

  };

  async function getOrders() {



    let dateList = [];
    let altDateList = [];
    let weeklyOrdersList = [];
    for (let count=0; count<7; count ++) {

            let sDate = new moment(selectedDate).startOf('week').add(count + 1,'days');

            dateList.push(sDate.format("YYYY-MM-DD"));
            altDateList.push(sDate.format("ddd MMM D"));
            
            let subDate = sDate.toString().split(" ");


            // let subDate = selectedDate.toString().split(" ")
            let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
            // var order_structure = await YPCGetAxios(xget, setXGet, 'getDaysOrdersURL', dateString);
            var order_structure = await YPCGetAxiosAlt(xget, setXGet, 'getDaysOrdersURL', dateString);

            if (order_structure.length !== 0) {
                let orderList = [];
                order_structure.map((value) => {
                    let temp = value;
                    let id = uuidv4();
                    temp['id'] = id;
                    orderList.push(temp);
                });

                setDaysOrderList(orderList);

                var fullRecipeDict = await YPCPutAxios2Alt(setXPost, 'getRecipeDictsURL', orderList, '');
                // console.log('PZPZPZPZPPZ', fullRecipeDict)

                let tempRecipeDict = {}
                if (fullRecipeDict['status'] === 'good') {
                  setRecipeDict(fullRecipeDict['recipe_dicts']);
                  tempRecipeDict = fullRecipeDict['recipe_dicts']
                } else {
                  setRecipeDict({});
                };

                // new stuff
                let newOrderList = [];
                let dishName = "";
                let customerName = "";
                let customerFullName = "";
                let portions = 0;
                let mealType = "";
                let lunchBox = false;
                let category = "";
                let servingTime = "2022-12-14T12:00";
                let firstTime = true;
                let subcategory = "";
                // console.log('OOrderStructure', order_structure);
                order_structure.map((value) => {
                  let temp = value;
                  let id = uuidv4();
                  temp['id'] = id;
                  if (firstTime) {
                    if (temp['category'] === "Side") {
                      newOrderList.push({id: 1, name : "Menu Starts With Side!!", category: "", subcategory: '', customer_id: "", customer_fullname: "", portions:0, lunch_box:false, meal_type:"", serving_time_range: ""})
                      newOrderList.push({id: 2, name : "", category: "", customer_id: "",  subcategory: '', customer_fullname: "", portions:0, lunch_box:null, meal_type:"", serving_time_range:""})
                    }
                    dishName = temp['name'];            
                    customerName = temp['customer_id'];
                    customerFullName = temp['user_full_name'];
                    portions = temp['portions'];
                    mealType = temp['meal_type'];
                    category = temp['category'];
                    lunchBox = temp['lunch_box'];
                    servingTime = temp['serving_time_range'];
                    firstTime = false;
                    subcategory = tempRecipeDict[temp['name']]['subcategory'];
                  } else {
                    if (temp['category'] === "Side") {
                      dishName = dishName + ", " + temp['name'];
                    } else {


                      newOrderList.push({id: id, name : dishName, category: category, subcategory: subcategory, customer_id: customerName, customer_fullname: customerFullName, portions: portions, lunch_box:lunchBox, meal_type: mealType, serving_time_range: servingTime});

                      if (servingTime !== temp['serving_time_range'] || customerName !== temp['customer_id']) {
                        id = uuidv4(); 
                        newOrderList.push({id: id, name : "", category: "",  subcategory: '', customer_id: "", customer_fullname: "", portions:null, lunch_box:null, meal_type:"", serving_time_range:""})
                      };

                      dishName = temp['name'];            
                      customerName = temp['customer_id'];
                      customerFullName = temp['user_full_name'];
                      portions = temp['portions'];
                      mealType = temp['meal_type'];
                      category = temp['category'];
                      lunchBox = temp['lunch_box'];
                      servingTime = temp['serving_time_range'];
                      subcategory = tempRecipeDict[temp['name']]['subcategory'];
          
                    };

                  };

                  // save one last one out here!!




                });

                let id = uuidv4();
                newOrderList.push({id: id , name : dishName, category: category,  subcategory: subcategory, customer_id: customerName, customer_fullname: customerFullName, portions: portions, lunch_box:lunchBox, meal_type: mealType, serving_time_range: servingTime});
                setAltDaysOrderList(newOrderList);


                weeklyOrdersList.push(newOrderList);



            } else {
              setDaysOrderList([]);
              setAltDaysOrderList([]);
              weeklyOrdersList.push([]);
            };


      }; // end of for loop

      // console.log('GKGKGKGKGKGKGKGKGK', weeklyOrdersList);
      setDateDisplay(altDateList);
      setSubTallyWeeklyOrdersList(weeklyOrdersList);

  };

  const handleDateChange = (date) => {
    // console.log('GAGAGA', date);
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });



  const tallyColumns = [
    {
      field: 'name',
      headerName: translate(props.language, 'Selection'),
      type: 'string',
      width: 500,
      sortable: true,
      editable: false,
    },
    {
      field: 'portions',
      headerName: translate(props.language, 'Portions'),
      type: 'number',
      sortable: true,
      width: 140,
      editable: false,
    }
  ];

  const tallyWeeklyColumns = [
    {
      field: 'name',
      headerName: translate(props.language, 'Selection'),
      type: 'string',
      width: 500,
      sortable: true,
      editable: false,
    },
    {
      field: 'category',
      headerName: 'Category',
      type: 'string',
      width: 140,
      sortable: true,
      editable: false,
    },
    {
      field: 'subcategory',
      headerName: 'SubCategory',
      type: 'string',
      width: 140,
      sortable: true,
      editable: false,
    },

    {
      field: 'portionsMon',
      headerName: dateDisplay[0],
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === 0) {
            return '';
        }
        else {
            return params.value;
        }
       },
    },
    {
      field: 'portionsTue',
      headerName: dateDisplay[1],
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === 0) {
            return '';
        }
        else {
            return params.value;
        }
       },
    },
    {
      field: 'portionsWed',
      headerName: dateDisplay[2],
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === 0) {
            return '';
        }
        else {
            return params.value;
        }
       },
    },
    {
      field: 'portionsThu',
      headerName:  dateDisplay[3],
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === 0) {
            return '';
        }
        else {
            return params.value;
        }
       },
    },
    {
      field: 'portionsFri',
      headerName:  dateDisplay[4],
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === 0) {
            return '';
        }
        else {
            return params.value;
        }
       },
    },
    {
      field: 'portionsSat',
      headerName:  dateDisplay[5],
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === 0) {
            return '';
        }
        else {
            return params.value;
        }
       },
    },
    {
      field: 'portionsSun',
      headerName:  dateDisplay[6],
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === 0) {
            return '';
        }
        else {
            return params.value;
        }
       },
    },


    {
      field: 'total',
      headerName:  'Total',
      type: 'number',
      sortable: true,
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === 0) {
            return '';
        }
        else {
            return params.value;
        }
       },
    },
  ];


  function TallyWeeklyTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
        //   onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          rows={tallyWeeksOrderList}
          columns={tallyWeeklyColumns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStarterSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };


  function TallyTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
        //   onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          rows={tallyDaysOrderList}
          columns={tallyColumns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStarterSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };




  // End of Starters Section


    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>

                  <Typography component="h4" variant="h4">
                      {translate(props.language,"Weekly Orders")}
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>

          <Grid item xs={12}>

                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              {/* <KeyboardDatePicker */}
                              <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date picker dialog"
                                  // format="MM/dd/yyyy"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>

          </Grid>




          <div><p></p></div>

          {/*   Orders Section */}
          {/* <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Final Tally'} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {TallyTable()}
                    </Grid>
                  </Grid>
              : null }
          </Grid> */}
          {/* Orders Section */}
          {/* <div><p></p></div> */}


          {/*   Orders Section */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Final Tallies'} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                      {loading === true ? <Typography>Loading in process</Typography> : null}
                    </Grid>                  
                    <Grid item xs={12} >
                        {/* {loading ? <Skeleton variant="rectangular" width={"100%"} height={400} /> :  TallyWeeklyTable()} */}

                        {loading === true ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : TallyWeeklyTable()}

                        {/* {TallyWeeklyTable()} */}
                    </Grid>
                  </Grid>
              : null }
          </Grid>
          {/* Orders Section */}


          <div><p></p></div>

          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


