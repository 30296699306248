import axios from "axios";
import React from "react";
import config from './YPCconfig.js';


export default function YPCPutAxios2Alt(setXPost, url, content, id) {



    let baseURL = '';

    if (url === 'conciergeSaveURL') {
        baseURL = config.conciergeSaveURL + id.toString();
    };
    if (url === 'conciergeSaveOrderURL') {
        baseURL = config.conciergeSaveOrderURL + id.toString();
    };
    if (url === 'putProxysaveRecipeURL') {
        baseURL = config.putProxysaveRecipeURL;
    };
    if (url === 'saveRecipeURL') {
        baseURL = config.saveRecipeURL;
    };
    if (url === 'saveIngredientURL') {
        baseURL = config.saveIngredientURL;
    };
    if (url === 'putProxysaveIngredientURL') {
        baseURL = config.putProxysaveIngredientURL;
    };
    if (url === 'saveMenuURL') {
        baseURL = config.saveMenuURL;
    };
    if (url === 'testMenuURL') {
        baseURL = config.testMenuURL;
    };
    if (url === 'saveOrderURL') {
        baseURL = config.saveOrderURL;
    };
    if (url === 'saveXOrderURL') {
        baseURL = config.saveXOrderURL;
    };
    if (url === 'createXPaymentURL') {
        baseURL = config.createXPaymentURL;
    };
    if (url === 'putProxyBatchOverwriteIngredientStockByNameURL') {
        baseURL = config.putProxyBatchOverwriteIngredientStockByNameURL;
    };
    if (url === 'fileUploadURL') {
        baseURL = config.fileUploadURL;
    };
    if (url === 'sendXKitchinEmailURL') {
        baseURL = config.sendXKitchinEmailURL;
    };
    if (url === 'sendKitchinEmailURL') {
        baseURL = config.sendKitchinEmailURL;
    };
    if (url === 'deleteXOrderURL') {
        baseURL = config.deleteXOrderURL;
    };
    if (url === 'deleteOrderURL') {
        baseURL = config.deleteOrderURL;
    };
    if (url === 'deleteFakeOrdersURL') {
        baseURL = config.deleteFakeOrdersURL;
    };
    if (url === 'sendXKitchinEmailCancelURL') {
        baseURL = config.sendXKitchinEmailCancelURL;
    };
    if (url === 'sendKitchinEmailCancelURL') {
        baseURL = config.sendKitchinEmailCancelURL;
    };
    if (url === 'deleteMenuURL') {
        baseURL = config.deleteMenuURL;
    };
    if (url === 'sendXKitchinEmailPaidOrderURL') {
        baseURL = config.sendXKitchinEmailPaidOrderURL;
    };
    if (url === 'savePortraitURL') {
        baseURL = config.savePortraitURL;
    };
    if (url === 'getPortraitURL') {
        baseURL = config.getPortraitURL;
    };
    if (url === 'saveMiscBlockedURL') {
        baseURL = config.saveMiscBlockedURL;
    };
    if (url === 'saveCompanyURL') {
        baseURL = config.saveCompanyURL;
    };
    if (url === 'saveMiscTimeListsURL') {
        baseURL = config.saveMiscTimeListsURL;
    };
    if (url === 'saveMiscCostPriceListsURL') {
        baseURL = config.saveMiscCostPriceListsURL;
    };
    if (url === 'deleteMiscTimeListsURL') {
        baseURL = config.deleteMiscTimeListsURL;
    };
    if (url === 'deleteMiscCostPriceListsURL') {
        baseURL = config.deleteMiscCostPriceListsURL;
    };
    if (url === 'deleteCustomerReturnsURL') {
        baseURL = config.deleteCustomerReturnsURL;
    };
    if (url === 'createUserURL') {
        baseURL = config.createUserURL;
    };
    if (url === 'getUserEmailNamesURL') {
        baseURL = config.getUserEmailNamesURL;
    };
    if (url === 'printContainerTicketURL') {
        baseURL = config.printContainerTicketURL + id;
    };
    if (url === 'sendXRecommendationEmailURL') {
        baseURL = config.sendXRecommendationEmailURL;
    };
    if (url === 'getRecipeDictsURL') {
        baseURL = config.getRecipeDictsURL;
    };
    if (url === 'PayEditURL') {
        baseURL = config.PayEditURL;
    };
    if (url === 'setPayAllURL') {
        baseURL = config.setPayAllURL;
    };
    if (url === 'commsRobotControlURL') {
        baseURL = config.commsRobotControlURL;
    };

    return new Promise(function (resolve, reject) {

        let token = localStorage.getItem('token');
        let userName = localStorage.getItem('userName')

        axios
        .put(baseURL, 
            // {'content' : content} 

            {'content': content, 'token': token, 'username': userName}
        )
        .then((response) => {
            console.log('PPPPutOrderAxios', response.data);
                if (response.status == 200) {
                    setXPost('Updated');
                    // setXPost(response.data.data);
                }
                else {
                    setXPost('Error updating!');
                    // setXPost('Error');
                }
            var result = response.data;
            resolve(result);

        }).catch(error => {
            console.log('EEEEEEEEE',error);
            if ('response' in error) {
                if (error.response === undefined) {
                    setXPost('Error.  Contact YPC Help Line\n');
                } else if ('data' in error.response) {
                    if ('detail' in error.response.data) {
                        setXPost('Error updating!\n ' + error.response.data.detail);
                    } else {
                        setXPost('Error updating!');
                    }
                } else {
                    setXPost('Error updating!');
                }
            } else {
                setXPost('Error updating!');
            };
            // setXPost('Error');
            reject(error);
        });

    });
}

