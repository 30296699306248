import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory, useLocation, Redirect } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import * as Square from '@square/web-sdk';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "75%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();



export default function YPCReferral(props) {

  const classes = useStyles();
  const mediatheme = useTheme();
  const showText = useMediaQuery(mediatheme.breakpoints.up('sm'));
  // const theme = useTheme();
  
  let dateInURL = new URLSearchParams(useLocation().search).get("date");
  let momentDate = null;

  if (dateInURL === null) {
    momentDate = moment(new Date());
  } else { 
    let momentInURL = moment(dateInURL, "DDMMYYYY");
    if (momentInURL._isValid) {
      momentDate = momentInURL;
    } else {
      momentDate = moment(new Date());
    };
  };
  


  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  // const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));
  const [selectedDate, setSelectedDate] = React.useState(momentDate);


  const [locale, setLocale] = React.useState()

  const [xnull, setXNull] = React.useState(null);

  const [createFirst_Name, setCreateFirst_Name] = React.useState(null);
  const [createLast_Name, setCreateLast_Name] = React.useState(null);
  const [createEmail,setCreateEmail] = React.useState(null);
  const [createPhoneNumber,setCreatePhoneNumber] = React.useState(null);    
  const [createBuilding, setCreateBuilding] = React.useState(' ');

  const [sentMessage, setSentMessage] = React.useState(false);

//   const history = useHistory();
//   const goHome = () => history.push('/');


  async function handleSend () {

    let sendData = {
        username: props.userName,
        first_name: createFirst_Name,
        last_name: createLast_Name,
        email: createEmail,
        phone_number: createPhoneNumber,
        building_address: createBuilding
      };

    setSentMessage(true);  
    await YPCPutAxios2Alt(setXNull, 'sendXRecommendationEmailURL', sendData, '');
    setXNull(null);
    // setSentMessage(true);

  };


  function sentMsg() {
    return (
      <div>
        <Dialog open={sentMsg} >
          {/* <DialogTitle>{translate(props.language, 'Lunch and Dinner time values must be set before saving order!')}</DialogTitle> */}
          <DialogTitle></DialogTitle>
          <DialogContent>
                {props.language !== 'FRANCAIS' ? "Thank you for the referral!" : "Merci pour la référence"}
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={goHome} color="primary"> */}
            {/* <Button onClick={handleGeneralMsg} color="primary"> */}
            <Button href="/" color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleGeneralMsg = () => {
    // <Redirect to='/' />
    setSentMessage(false);

  };


    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>


              <div className={classes.sendleft}>
                      {sentMessage ? sentMsg() : null}

                        {props.language !== 'FRANCAIS' ? 
                            <Typography component="h4" variant="h4">
                                Referral
                            </Typography>
                        :
                            <Typography component="h4" variant="h4">
                                Référez
                            </Typography>
                        }



              </div>

              <div style={{clear: "both"}}></div>
          </Grid>

          <Grid item xs={12}>


              <div className={classes.sendleft}>

                        {props.language !== 'FRANCAIS' ? 
                          <React.Fragment>
                              <Typography component="h5" variant="h5">
                                  Refer a neighbour and get one week of free membership and meals for your and their household once they order.
                              </Typography>
                              <p></p>
                              <Typography component="h6" variant="h6">
                                  Jasper reserves the right to modify or refuse the use of the referral program.
                              </Typography>
                          </React.Fragment>
                        :
                          <React.Fragment>
                              <Typography component="h5" variant="h5">
                                  Référez un voisin et obtenez une semaine d'adhésion et de repas gratuits pour votre foyer et le leur, une fois que leur première commande est placée.  Jasper se réserve le droit d'annuler les références ainsi que les récompenses obtenues.
                              </Typography>
                              <p></p>
                              <Typography component="h6" variant="h6">
                                  Jasper se réserve le droit d'annuler les références ainsi que les récompenses obtenues.
                              </Typography>                              
                          </React.Fragment>
                        }



              </div>

              <div style={{clear: "both"}}></div>
          </Grid>


          <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 250}}
                        label={props.language !== 'FRANCAIS'? "First Name" : "Prénom"}
                        value = {createFirst_Name}
                        // helperText="Home Address"
                        onChange={(e) => setCreateFirst_Name(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>

            <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 250}}
                        label={props.language !== 'FRANCAIS'? "Last Name" : "Nom de famille"}
                        // value = {createUser.last_name}
                        // helperText="Home Address"
                        value = {createLast_Name}
                        onChange={(e) => setCreateLast_Name(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>


            <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 250}}
                        label={props.language !== 'FRANCAIS'? "Email" : "Courriel"}
                        value = {createEmail}
                        // helperText="Home Address"
                        onChange={(e) => setCreateEmail(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>            


            <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 250}}
                        label={props.language !== 'FRANCAIS'? "Phone Number" : "Numéro de téléphone"}
                        value = {createPhoneNumber}
                        // helperText="Home Address"
                        onChange={(e) => setCreatePhoneNumber(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>            
            <p></p>

            <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <Autocomplete
                        // onChange={(event, value) => setUsername(value)}
                        onChange={(e, value) => {
                            // if (value === 'None' || value === 'Family' || value === 'Individual') {
                                
                            //     setPortrait(prevPortrait => {
                            //         return { 
                            //         ...prevPortrait, 
                            //         company_name: companyName2Id['None']
                            //         }
                            //     })
                            // }
                            // setPortrait(prevPortrait => {
                            //     return { 
                            //     ...prevPortrait, 
                            //     subscription_type: value 
                            //     }
                            // })
                            setCreateBuilding(value);
                        }}
                        // id="combo-box-username"
                        value={createBuilding}
                        options={[  ' ',
                                    '1 rue De Castelnau',
                                    '75 rue De Castelnau',
                                    '85 rue De Castelnau',
                                    '7400 Boulevard Saint-laurent',
                                    '8 rue Gary-Carter',
                                '88 rue Gary-Carter' ]}
                        style={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label={props.language !== 'FRANCAIS'? 'Building Address': 'Adresse du bâtiment'} InputLabelProps={{
                            shrink: true,
                            style: {color: '#78cbff'},
                        }}


                        // variant="outlined"
                        />} 
                    />
                    <p></p>
                    <p></p>
                </MuiThemeProvider>
            </Grid>

            <Grid item xs={12} >
                <Grid item xs={2} >
                        <p></p>
                        <Button
                            variant="contained"
                            // color="primary"
                            size="small"
                            className={classes.button}
                            onClick={handleSend}
                            disabled={false}
                            aria-label="whatever 1"
                            style={{backgroundColor: '#78cbff', color:"white"}}
                        >
                            {props.language !== 'FRANCAIS' ? 'Send': 'Envoyez'}
                        </Button>        
                </Grid>
                <Grid item xs={10} ></Grid>
            </Grid>
          <div><p></p></div>

          {/* {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null} */}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


