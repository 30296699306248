import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    gridClasses,
    } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCGetMenuDB from './YPCGetMenuDB';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "90%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  tab: {
    color: '#78cbff',
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export default function YPCPortraitMaster(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);
  const [xnull, setXNull] = React.useState(null);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [noneSubsriberCost, setNoneSubscriberCost] = React.useState(5.0);

  const [locale, setLocale] = React.useState()

  const [createUserMsgOpen, setCreateUserMsgOpen] = React.useState(null);

  const [username, setUsername] = React.useState(null);
  const [usernameList, setUsernameList] = React.useState([]);

  const [haveUserNames, setHaveUserNames] = React.useState(false);
  
  const [companyNames, setCompanyNames] = React.useState([]);
  const [id2CompanyName, setId2CompanyName] = React.useState(null);
  const [companyName2Id, setCompanyName2Id] = React.useState(null);
  const [haveCompanyNames, setHaveCompanyNames] = React.useState(false);

  const [savingPortrait, setSavingPortrait] = React.useState(false);

  const [emailNames, setEmailNames] = React.useState({'email':null, 'first_name': null, 'last_name': null})

  const [createUsername, setCreateUsername] = React.useState(null);
  const [createPassword,setCreatePassword] = React.useState(null);
  const [createEmail,setCreateEmail] = React.useState(null);
  const [createFirst_Name,setCreateFirst_Name] = React.useState(null);
  const [createLast_Name,setCreateLast_Name] = React.useState(null);

  const [portrait, setPortrait] = React.useState({
        'username': null,
        'id': null,
        'address': null,
        'apartment': null,
        'country': null,
        'province_state': null,
        'city': null,
        'postal_code': null,
        'phone_number': null,
        'first_name_family_member1': null,
        'last_name_family_member1': null,
        'phone_number1': null,
        'email1': null,
        'first_name_family_member2': null,
        'last_name_family_member2': null,
        'phone_number2': null,
        'email2': null,
        'first_name_family_member3': null,
        'last_name_family_member3': null,
        'phone_number3': null,
        'email3': null,
        'first_name_family_member4': null,
        'last_name_family_member4': null,
        'phone_number4': null,
        'email4': null,
        'building_id' : null,
        'company_name': 1,
        'subscription_count': null,
        'status': null,
        'newsletter_subscribed': null,
        'notes': null,
        'cc_name': null,
        'cc_address': null,
        'cc_apartment': null,
        'cc_country': null,
        'cc_province_state': null,
        'cc_city': null,
        'cc_postal_code': null,
        'cc_credit_card_type': null,
        'credit_card_number': null,
        'cvv': null,
        'expiry_data': null,
        'subscription_history': null,
        'sms_optin_transactional': true,
        'sms_optin_marketing': true,
        'email_optin_transactional': true,
        'email_optin_marketing': true,
  });



  const [usernameToID, setUsernameToID] = React.useState(null);

  const [portraitPresent, setPortraitPresent] = React.useState(false);

  const [tabValue, setTabValue] = React.useState(0);

  const [newCompanyName, setNewCompanyName] = React.useState(null);

  const [allCustomerDataRows, setAllCustomerDataRows] = React.useState([]);

  React.useEffect(() => {
    if (!haveUserNames) {
        getUserNames();
    }
  });

  React.useEffect(() => {
    if (!haveCompanyNames) {
        getCompanyNames();
    }
  });


  React.useEffect(() => {
    if (username !== null && username !== '') {
        getPortrait();
    }
  }, [username]);

  async function getCompanyNames () {
    var companyDict = await YPCGetAxiosAlt(xget, setXGet, 'getCompaniesURL', '');

    setCompanyName2Id(companyDict['company2id'])
    setId2CompanyName(companyDict['id2company'])
    setCompanyNames(companyDict['companies'])

    setHaveCompanyNames(true);
  };


  async function getUserNames() {
    let userDict = await YPCGetAxiosAlt(xget, setXGet, 'getUsernamesIDsURL', '');

    setUsernameToID(userDict);

    let unames = Object.keys(userDict);

    setUsernameList(unames.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }));

    setHaveUserNames(true);
  }


  function clearPortrait() {
    setPortrait(
        {
            'username': null,
            'id': null,
            'address': null,
            'apartment': null,
            'country': null,
            'province_state': null,
            'city': null,
            'postal_code': null,
            'building_id' : null,
            'phone_number': null,
            'first_name_family_member1': null,
            'last_name_family_member1': null,
            'phone_number1': null,
            'email1': null,
            'first_name_family_member2': null,
            'last_name_family_member2': null,
            'phone_number2': null,
            'email2': null,
            'first_name_family_member3': null,
            'last_name_family_member3': null,
            'phone_number3': null,
            'email3': null,
            'first_name_family_member4': null,
            'last_name_family_member4': null,
            'phone_number4': null,
            'email4': null,
            'food_restrictions_allergies': null,
            'preferred_language': null,
            'preferred_payment_method': null,
            'subscription_type': null,
            'pickup_or_delivery' : null,
            'company_name': 1,
            'subscription_count': null,
            'status': null,
            'newsletter_subscribed': null,
            'notes': null,
            'cc_name': null,
            'cc_address': null,
            'cc_apartment': null,
            'cc_country': null,
            'cc_province_state': null,
            'cc_city': null,
            'cc_postal_code': null,
            'cc_credit_card_type': null,
            'credit_card_number': null,
            'cvv': null,
            'expiry_data': null,
            'subscription_history': null,
            'sms_optin_transactional': true,
            'sms_optin_marketing': true,
            'email_optin_transactional': true,
            'email_optin_marketing': true
    }
    )
  };

  async function getPortrait () {

    clearPortrait()

    let sendData = {
        username: username,
    };
    
    let newPortrait = await YPCPutAxios2Alt(setXPost, 'getPortraitURL', sendData, '');

    let tempEmailNames = await YPCPutAxios2Alt(setXPost, 'getUserEmailNamesURL', sendData, '');

    console.log('XPXPXPXPXPXPXPXP', tempEmailNames);

    console.log('XXPXPXPXPXPXPXPXPPX', newPortrait)

    setPortrait(newPortrait);
    setEmailNames(tempEmailNames);


  };


  const handleNewCompanyName = (event) => {
    setNewCompanyName(event.target.value);
};



  async function handleSaveCompany () {

    // console.log('YQYQYQYQYQYQYQYQY', JSON.stringify([{"subscription_type": 'None', "date": 'Jan01Fri2021'   }]) );

    let testit = {
        company_name: newCompanyName
      };

    await YPCPutAxios2Alt(setXPost, 'saveCompanyURL', testit, '');

    setNewCompanyName(null);

    getCompanyNames();

  };


  async function handleLoadAllUserData () {

    var allUserData = await YPCGetAxiosAlt(xget, setXGet, 'getAllUserDataURL', '');
    setAllCustomerDataRows(allUserData);
    console.log('GXGXGXGXGXGXGXG Got here', allUserData);

  };


  const allCustomerDataColumns = [
    {
      field: 'username',
      headerName: 'User Name',
      width: 300,
      sortable: true,
    },
    {
        field: 'first_name',
        headerName: 'First Name',
        width: 200,
        sortable: true,
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        width: 140,
        sortable: true
      },      
    {
      field: 'email',
      headerName: 'Email',
      width: 140,
      sortable: true,
    },
    {
        field: 'phone_number',
        headerName: 'Phone',
        width: 140,
        sortable: true,
    },
    {
        field: 'subscription_type',
        headerName: 'Subscription',
        width: 140,
        sortable: true,
    },
    {
        field: 'pickup_or_delivery',
        headerName: 'Pickup/Delivery',
        width: 140,
        sortable: true,
    },
    {
        field: 'company_name',
        headerName: 'Company',
        width: 140,
        sortable: true,
    },    
    {
        field: 'building_id',
        headerName: 'Building ID',
        width: 140,
        sortable: true,
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 140,
        sortable: true,
    },
    {
        field: 'apartment',
        headerName: 'Apt #',
        width: 140,
        sortable: true,
    },
    {
        field: 'province_state',
        headerName: 'Province',
        width: 140,
        sortable: true,
    },
    {
        field: 'country',
        headerName: 'Country',
        width: 140,
        sortable: true,
    },
    {
        field: 'postal_code',
        headerName: 'Postal Code',
        width: 140,
        sortable: true,
    },
    {
        field: 'sms_optin_transactional',
        headerName: 'SMS Trans',
        width: 140,
        sortable: true,
    },
    {
        field: 'sms_optin_marketing',
        headerName: 'SMS Mrkting',
        width: 140,
        sortable: true,
    },
    {
        field: 'email_optin_transactional',
        headerName: 'Email Trans',
        width: 140,
        sortable: true,
    },
    {
        field: 'email_optin_marketing',
        headerName: 'Email Mrkting',
        width: 140,
        sortable: true,
    },
    {
        field: 'food_restrictions_allergies',
        headerName: 'Food Restrictions/Allergies',
        width: 140,
        sortable: true,
    }

  ];


  async function handleCreateUser () {

    console.log('XPXPXPXPXPXPXP', createUsername, createPassword, createEmail, createFirst_Name, createLast_Name);
    if (createUsername=== null || createEmail === null || createPassword === null || createFirst_Name === null || createLast_Name === null) {
        setCreateUserMsgOpen('All fields must be filled out');
        return;
    };

    // let general = {username : 'Abraman', password: 'asdfghjkl++', email: 'test@whatever.com', first_name: 'Johnny', last_name: 'Campbell'};
    clearPortrait();
    setCreateUserMsgOpen('Creating New Customer');


    let tempCreateUser = {
        'username': createUsername,
        'password': createPassword,
        'email': createEmail,
        'first_name': createFirst_Name,
        'last_name': createLast_Name
    };


    let response = await YPCPutAxios2Alt(setXPost, 'createUserURL', tempCreateUser, '');

    if (!response.success) {
        console.log('Bad news', response)
        setCreateUserMsgOpen(response.message);
    }  else {
        setCreateUserMsgOpen(response.message);
        let tempUsername = createUsername;

        // setCreateUser({username: null,
        //     password: null,
        //     email: null,
        //     first_name: null,
        //     last_name: null
        // });

        // setCreateUsername(null);
        // setCreatePassword(null);
        // setCreateFirst_Name(null);
        // setCreateLast_Name(null);
        // setCreateEmail(null);


        setUsername(tempUsername);
        setHaveCompanyNames(false);
        setHaveUserNames(false);

        console.log('YPYPYPYPYPYPYP Got Here!', createUsername, createPassword, createEmail, createFirst_Name, createLast_Name);

        // setCreateUser({'username': null,
        // 'password': null,
        // 'email': null,
        // 'first_name': null,
        // 'last_name': null
        // });
        // setCreateUserMsgOpen(response.message);
    };

  };


  async function handleSavePortrait () {

    // console.log('YQYQYQYQYQYQYQYQY', JSON.stringify([{"subscription_type": 'None', "date": 'Jan01Fri2021'   }]) );

    if (portrait.username !== null) {

        setSavingPortrait(true);

        let sendData = {
            portrait: portrait,
            username: username,
            jstring: JSON.stringify([{"subscription_type": 'Free', "date": 'Jan01Fri2021'   }])
        };


        let newPortrait = await YPCPutAxios2Alt(setXPost, 'savePortraitURL', sendData, '');

        // console.log('OAOAOAOAOAOAOAOAOA', newPortrait);
        
        setPortrait(newPortrait);

        // setCreateUser({'username': null,
        //     'password': null,
        //     'email': null,
        //     'first_name': null,
        //     'last_name': null
        // });

        // setCreateUsername(null);
        // setCreatePassword(null);
        // setCreateFirst_Name(null);
        // setCreateLast_Name(null);
        // setCreateEmail(null);

    };



  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

function createUserMsg() {
  return (
    <div>
      <Dialog open={createUserMsgOpen} >
        <DialogTitle>Info -</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {createUserMsgOpen}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {   createUserMsgOpen !== "Creating New Customer" ?
                <Button onClick={handleCreateUserMsg} color="primary">
                    {translate(props.language, 'Ok')}
                </Button> :
                null
            }

        </DialogActions>
      </Dialog>
    </div>
  );
}

const handleCreateUserMsg = () => {
  setCreateUserMsgOpen(null);
}

const handleTabChange = (event, newValue) => {
  setTabValue(newValue);
};

// gen dialog

const handleSavedPortraitDialog = () => {
    setSavingPortrait(false);
};

  function portraitSavedDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Portrait Save to Database!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSavedPortraitDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };

    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>
            <div className={classes.sendleft}>
                  {createUserMsgOpen !== null ? createUserMsg() : null}
                  {savingPortrait ? portraitSavedDialog(): null}
                  <Typography component="h4" variant="h4">
                      Portrait Master
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>


          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>Create New Customer &#8198;</div>
          </Grid>


          <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 400}}
                        label="User Name"
                        value = {createUsername}
                        helperText="Letters, digits and @/./+/-/_ only"
                        onChange={(e) => setCreateUsername(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>

            <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 400}}
                        label="Password"
                        value = {createPassword}
                        // helperText="Home Address"
                        onChange={(e) => setCreatePassword(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>

            <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 400}}
                        label="Email"
                        value = {createEmail}
                        // helperText="Home Address"
                        onChange={(e) => setCreateEmail(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>            

            <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 400}}
                        label="First Name"
                        value = {createFirst_Name}
                        // helperText="Home Address"
                        onChange={(e) => setCreateFirst_Name(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>

            <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <TextField
                        style={{width: 400}}
                        label="Last Name"
                        // value = {createUser.last_name}
                        // helperText="Home Address"
                        value = {createLast_Name}
                        onChange={(e) => setCreateLast_Name(e.target.value)}
                    />
                </MuiThemeProvider>
            </Grid>


            <Grid item xs={12} >          
                <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        // color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleCreateUser}
                        disabled={false}
                        aria-label="whatever 35"
                        style={{backgroundColor: '#78cbff', color: "white"}}
                    >
                        Create Customer
                    </Button>        

                </Grid>


                <Grid item xs={9} ></Grid>
            </Grid>


          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'User Name')} &#8198;</div>
          </Grid>


          <Grid item xs={12}>
              <MuiThemeProvider theme={theme}>
                    <Autocomplete
                        onChange={(event, value) => setUsername(value)}
                        id="combo-box-username"
                        value={username}
                        options={usernameList}
                        // getOptionLabel={(option) => option.name}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label={translate(props.language, 'User Name')} InputLabelProps={{
                            shrink: true,
                            style: {color: '#78cbff'},
                        }}
                        // variant="outlined"
                        />} 
                    />
                </MuiThemeProvider>
            </Grid>


            <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>Portrait &#8198;</div>
            </Grid>

                    { portrait.username !== null && username !== null ?
                        <React.Fragment>
                        <Grid item xs={12}>

                            <MuiThemeProvider theme={theme}>
                                <Tabs 
                                  onChange={handleTabChange} aria-label="whatever"
                                  value={tabValue}
                                  TabIndicatorProps={{ style: { background: "#78cbff", color: '#78cbff'} }}
                                >
                                  <Tab label="Main Member"  {...a11yProps(0)} style={{color: '#78cbff'}}/>
                                  <Tab label="Family Member 1"  {...a11yProps(1)}  style={{color: '#78cbff'}}/>
                                  <Tab label="Family Member 2"  {...a11yProps(2)}  style={{color: '#78cbff'}}/>
                                  <Tab label="Family Member 3"  {...a11yProps(3)}  style={{color: '#78cbff'}}/>
                                  <Tab label="Family Member 4"  {...a11yProps(4)}  style={{color: '#78cbff'}}/>
                                  <Tab label="Preferences"  {...a11yProps(5)}  style={{color: '#78cbff'}}/>
                                </Tabs>
                            </MuiThemeProvider>

                            <TabPanel value={tabValue} index={0}>


                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <Grid item xs={1}>
                                            <Typography align="left" style={{color:'#78cbff', fontSize: '13px', paddingTop: '0px' }}>First Name</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="left">{emailNames.first_name}</Typography>
                                        </Grid>
                                        <p></p>
                                    </MuiThemeProvider>
                                </Grid>

                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <Grid item xs={2}>
                                            <Typography align="left" style={{color:'#78cbff', fontSize: '13px', paddingTop: '0px' }}>Last Name</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="left">{emailNames.last_name}</Typography>
                                        </Grid>
                                        <p></p>
                                    </MuiThemeProvider>
                                </Grid>

                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <Grid item xs={2}>
                                            <Typography align="left" style={{color:'#78cbff', fontSize: '13px', paddingTop: '0px' }}>Email</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="left">{emailNames.email}</Typography>
                                        </Grid>
                                        <p></p>
                                    </MuiThemeProvider>
                                </Grid>                                

                                <Grid item xs={12}>
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        label="Address"
                                        value = {portrait.address}
                                        helperText="Home Address"
                                        onChange={(e) => {
                                            setPortrait(prevPortrait => {
                                                return { 
                                                ...prevPortrait, 
                                                address: e.target.value 
                                                }
                                            })
                                        }}
                                    />
                                </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12}>
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        label="Apartment"
                                        value = {portrait.apartment}
                                        helperText="Apartment Number"
                                        onChange={(e) => {
                                            setPortrait(prevPortrait => {
                                                return { 
                                                ...prevPortrait, 
                                                apartment: e.target.value 
                                                }
                                            })
                                        }}
                                    />
                                </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12}>
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        label="Country"
                                        value = {portrait.country}
                                        // helperText="Home Address"
                                        onChange={(e) => {
                                            setPortrait(prevPortrait => {
                                                return { 
                                                ...prevPortrait, 
                                                country: e.target.value 
                                                }
                                            })
                                        }}
                                    />
                                </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12}>
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        label="Province/State"
                                        value = {portrait.province_state}
                                        helperText="Province or State"
                                        onChange={(e) => {
                                            setPortrait(prevPortrait => {
                                                return { 
                                                ...prevPortrait, 
                                                province_state: e.target.value 
                                                }
                                            })
                                        }}
                                    />
                                </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12}>
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        label="City"
                                        value = {portrait.city}
                                        // helperText="Province or State"
                                        onChange={(e) => {
                                            setPortrait(prevPortrait => {
                                                return { 
                                                ...prevPortrait, 
                                                city: e.target.value 
                                                }
                                            })
                                        }}
                                    />
                                </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12}>
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        label="Postal Code"
                                        value = {portrait.postal_code}
                                        // helperText="Province or State"
                                        onChange={(e) => {
                                            setPortrait(prevPortrait => {
                                                return { 
                                                ...prevPortrait, 
                                                postal_code: e.target.value 
                                                }
                                            })
                                        }}
                                    />
                                </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12}>
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        label="Phone Number"
                                        value = {portrait.phone_number}
                                        // helperText="Province or State"
                                        onChange={(e) => {
                                            setPortrait(prevPortrait => {
                                                return { 
                                                ...prevPortrait, 
                                                phone_number: e.target.value 
                                                }
                                            })
                                        }}
                                    />
                                    <p></p>
                                    <p></p>
                                </MuiThemeProvider>
                                </Grid>


                                <Grid item xs={12}>
                                <MuiThemeProvider theme={theme}>
                                        <Autocomplete
                                            // onChange={(event, value) => setUsername(value)}
                                            onChange={(e, value) => {
                                                // if (value === 'None' || value === 'None5' || value === 'Family' || value === 'Individual'  || value === 'Free' || value === 'Free Subscription' || value === 'Individual 4 Weeks' || value === 'Family 4 Weeks') {
                                                //     setPortrait(prevPortrait => {
                                                //         return { 
                                                //         ...prevPortrait, 
                                                //         company_name: companyName2Id['None']
                                                //         }
                                                //     })
                                                // }
                                                setPortrait(prevPortrait => {
                                                    return { 
                                                    ...prevPortrait, 
                                                    subscription_type: value 
                                                    }
                                                })
                                            }}
                                            // id="combo-box-username"
                                            value={portrait.subscription_type}
                                            options={['None', 'None5', 'Free', 'Free Subscription', 'Individual', 'Family', 'Individual 4 Weeks', 'Family 4 Weeks', 'CmpPaysAll', 'CmpPaysSub','MngPaysAll', 'MngPaysSub', ]}
                                            style={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label={translate(props.language, 'Subscription Type')} InputLabelProps={{
                                                shrink: true,
                                                style: {color: '#78cbff'},
                                            }}
                                            // variant="outlined"
                                            />} 
                                        />
                                        <p></p>
                                        <p></p>
                                    </MuiThemeProvider>
                                </Grid>

                                {/* {portrait.subscription_type === 'CmpPaysAll' || portrait.subscription_type === 'CmpPaysSub' || portrait.subscription_type === 'MngPaysAll' || portrait.subscription_type === 'MngPaysSub'? */}
                                        <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                                <Autocomplete
                                                    // onChange={(event, value) => setUsername(value)}
                                                    onChange={(e, value) => {
                                                        setPortrait(prevPortrait => {
                                                            return { 
                                                            ...prevPortrait, 
                                                            company_name: companyName2Id[value]
                                                            }
                                                        })
                                                    }}
                                                    // id="combo-box-username"
                                                    value={id2CompanyName[portrait.company_name]}
                                                    options={companyNames}
                                                    style={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label={translate(props.language, 'Company')} InputLabelProps={{
                                                        shrink: true,
                                                        style: {color: '#78cbff'},
                                                    }}
                                                    // variant="outlined"
                                                    />} 
                                                />
                                                <p></p>
                                                <p></p>
                                            </MuiThemeProvider>
                                        </Grid>                 
                                        {/* : null */}
                                {/* }                */}

                                        <Grid item xs={12}>
                                            <MuiThemeProvider theme={theme}>
                                                <Autocomplete
                                                    onChange={(e, value) => {
                                                        setPortrait(prevPortrait => {
                                                            return { 
                                                            ...prevPortrait, 
                                                            pickup_or_delivery: value
                                                            }
                                                        })
                                                    }}
                                                    // id="combo-box-username"
                                                    value={portrait.pickup_or_delivery}
                                                    options={['Pickup', 'Delivery']}
                                                    style={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label={'Pickup or Delivery'} InputLabelProps={{
                                                        shrink: true,
                                                        style: {color: '#78cbff'},
                                                    }}
                                                    // variant="outlined"
                                                    />} 
                                                />
                                                <p></p>
                                                <p></p>
                                            </MuiThemeProvider>
                                        </Grid>                 

                                        { portrait.pickup_or_delivery === 'Delivery' ?
                                            <Grid item xs={12}>
                                                <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                        label="Building Delivery ID"
                                                        value = {portrait.building_id}
                                                        // helperText="Province or State"
                                                        onChange={(e) => {
                                                            setPortrait(prevPortrait => {
                                                                return { 
                                                                ...prevPortrait, 
                                                                building_id: e.target.value 
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </MuiThemeProvider>
                                            </Grid>
                                        : null
                                        }


                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                // value="checkedA"
                                                checked={portrait.sms_optin_transactional}
                                                // helperText="SMS Opt In Transactional"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        sms_optin_transactional: e.target.checked
                                                        }
                                                    })
                                                }}

                                                // inputProps={{
                                                //   'aria-label': 'Checkbox A',
                                                // }}
                                                />}

                                        label="SMS Opt In Transactional" />
                                        </MuiThemeProvider>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                // value="checkedA"
                                                checked={portrait.sms_optin_marketing}
                                                // helperText="SMS Opt In Transactional"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        sms_optin_marketing: e.target.checked
                                                        }
                                                    })
                                                }}

                                                // inputProps={{
                                                //   'aria-label': 'Checkbox A',
                                                // }}
                                                />}

                                        label="SMS Opt In Marketing" />
                                        </MuiThemeProvider>
                                    </Grid>



                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                // value="checkedA"
                                                checked={portrait.email_optin_transactional}
                                                // helperText="Email Opt In Transactional"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        email_optin_transactional: e.target.checked
                                                        }
                                                    })
                                                }}

                                                // inputProps={{
                                                //   'aria-label': 'Checkbox A',
                                                // }}
                                                />}

                                        label="Email Opt In Transactional" />
                                        </MuiThemeProvider>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                // value="checkedA"
                                                checked={portrait.email_optin_marketing}
                                                // helperText="Email Opt In Transactional"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        email_optin_marketing: e.target.checked
                                                        }
                                                    })
                                                }}

                                                // inputProps={{
                                                //   'aria-label': 'Checkbox A',
                                                // }}
                                                />}

                                        label="Email Opt In Marketing" />
                                        </MuiThemeProvider>
                                    </Grid>



                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <Autocomplete
                                            // onChange={(event, value) => setUsername(value)}
                                            onChange={(e, value) => {
                                                setPortrait(prevPortrait => {
                                                    return { 
                                                    ...prevPortrait, 
                                                    status: value 
                                                    }
                                                })
                                            }}
                                            // id="combo-box-username"
                                            value={portrait.status}
                                            options={['Interested', 'Active', 'Paused', 'Churned']}
                                            style={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label={translate(props.language, 'Status')} InputLabelProps={{
                                                shrink: true,
                                                style: {color: '#78cbff'},
                                            }}
                                            // variant="outlined"
                                            />} 
                                        />
                                        <p></p>
                                        <p></p>
                                    </MuiThemeProvider>
                                </Grid>

                                {portrait.subscription_history !== null ?
                                    <Grid item xs={12}>                                        
                                        <Grid item xs={2}>
                                        <Typography style={{color:'#78cbff', fontSize: '13px', paddingTop: '0px' }}>Subscription History</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {JSON.stringify(JSON.parse(portrait.subscription_history) , null, 2)}
                                        </Grid>
                                    </Grid>
                                : null
                                }


                                {/* <Grid item xs={12} >          
                                    <Grid item xs={3} >
                                        <p></p>
                                        <Button
                                            variant="contained"
                                            // color="primary"
                                            size="small"
                                            className={classes.button}
                                            onClick={handleSavePortrait}
                                            disabled={false}
                                            aria-label="whatever 1"
                                            style={{backgroundColor: '#78cbff', color: "white"}}
                                        >
                                            {translate(props.language, 'Save')}
                                        </Button>        

                                    </Grid>


                                    <Grid item xs={9} ></Grid>
                                </Grid> */}

                            </TabPanel>

                            <TabPanel  value={tabValue} index={1}>
                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            label="First Name 1"
                                            value = {portrait.first_name_family_member1}
                                            helperText="First Name Family Member 1"
                                            onChange={(e) => {
                                                setPortrait(prevPortrait => {
                                                    return { 
                                                    ...prevPortrait, 
                                                    first_name_family_member1: e.target.value 
                                                    }
                                                })
                                            }}
                                        />
                                    </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            label="Last Name 1"
                                            value = {portrait.last_name_family_member1}
                                            helperText="Last Name Family Member 1"
                                            onChange={(e) => {
                                                setPortrait(prevPortrait => {
                                                    return { 
                                                    ...prevPortrait, 
                                                    last_name_family_member1: e.target.value 
                                                    }
                                                })
                                            }}
                                        />
                                    </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            label="Phone Number 1"
                                            value = {portrait.phone_number1}
                                            helperText="Phone Number Family Member 1"
                                            onChange={(e) => {
                                                setPortrait(prevPortrait => {
                                                    return { 
                                                    ...prevPortrait, 
                                                    phone_number1: e.target.value 
                                                    }
                                                })
                                            }}
                                        />
                                    </MuiThemeProvider>
                                </Grid>                            
                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            label="Email 1"
                                            value = {portrait.email1}
                                            helperText="Email Family Member 1"
                                            onChange={(e) => {
                                                setPortrait(prevPortrait => {
                                                    return { 
                                                    ...prevPortrait, 
                                                    email1: e.target.value 
                                                    }
                                                })
                                            }}
                                        />
                                    </MuiThemeProvider>
                                </Grid>                                                        
                            
                            </TabPanel>
                            <TabPanel  value={tabValue} index={2}>
                                <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="First Name 2"
                                                value = {portrait.first_name_family_member2}
                                                helperText="First Name Family Member 2"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        first_name_family_member2: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Last Name 2"
                                                value = {portrait.last_name_family_member2}
                                                helperText="Last Name Family Member 2"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        last_name_family_member2: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Phone Number 2"
                                                value = {portrait.phone_number2}
                                                helperText="Phone Number Family Member 2"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        phone_number2: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>                            
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Email 2"
                                                value = {portrait.email2}
                                                helperText="Email Family Member 2"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        email2: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>     

                            </TabPanel>
                            <TabPanel  value={tabValue} index={3}>
                                <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="First Name 3"
                                                value = {portrait.first_name_family_member3}
                                                helperText="First Name Family Member 3"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        first_name_family_member3: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Last Name 3"
                                                value = {portrait.last_name_family_member3}
                                                helperText="Last Name Family Member 3"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        last_name_family_member3: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Phone Number 3"
                                                value = {portrait.phone_number3}
                                                helperText="Phone Number Family Member 3"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        phone_number3: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>                            
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Email 3"
                                                value = {portrait.email3}
                                                helperText="Email Family Member 3"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        email3: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>     




                            </TabPanel>
                            <TabPanel  value={tabValue} index={4}>
                                <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="First Name 4"
                                                value = {portrait.first_name_family_member4}
                                                helperText="First Name Family Member 4"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        first_name_family_member4: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Last Name 4"
                                                value = {portrait.last_name_family_member4}
                                                helperText="Last Name Family Member 4"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        last_name_family_member4: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Phone Number 4"
                                                value = {portrait.phone_number4}
                                                helperText="Phone Number Family Member 4"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        phone_number4: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>                            
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Email 4"
                                                value = {portrait.email4}
                                                helperText="Email Family Member 4"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        email4: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>     
                            </TabPanel>
                            <TabPanel  value={tabValue} index={5}>

                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                // value="checkedA"
                                                checked={portrait.newsletter_subscribed === null || !portrait.newsletter_subscribed ?  false : true}
                                                helperText="Subscribe to Newsletter"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        newsletter_subscribed: e.target.checked
                                                        }
                                                    })
                                                }}

                                                // inputProps={{
                                                //   'aria-label': 'Checkbox A',
                                                // }}
                                                />}

                                        label="Newsletter Subscribe" />
                                        </MuiThemeProvider>
                                    </Grid>


                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <TextField
                                                label="Preferred Language"
                                                value = {portrait.preferred_language}
                                                helperText="Preferred Language - English or French"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        preferred_language: e.target.value 
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <p></p>
                                            <Typography  style={{color: "#444444"}} >Food Restrictions and/or Allergies</Typography>

                                            <TextareaAutosize
                                                maxRows={10}
                                                minRows={10}
                                                aria-label="maximum height"
                                                placeholder="Food Restrictions and/or Allergies"
                                                // defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                                //     ut labore et dolore magna aliqua."
                                                label="Food Restrictions and/or Allergies"
                                                value = {portrait.food_restrictions_allergies}
                                                helperText="Food Restrictions and/or Allergies"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        food_restrictions_allergies: e.target.value 
                                                        }
                                                    })
                                                }}
                                                style={{ width: 600 }}
                                            />


                                        </MuiThemeProvider>
                                    </Grid>


                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={theme}>
                                            <p></p>
                                            <Typography style={{color: "#444444"}}>Notes</Typography>

                                            <TextareaAutosize
                                                maxRows={10}
                                                minRows={10}
                                                aria-label="maximum height notes"
                                                placeholder="Notes"
                                                // defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                                //     ut labore et dolore magna aliqua."
                                                label="Notes"
                                                value = {portrait.notes}
                                                helperText="Notes"
                                                onChange={(e) => {
                                                    setPortrait(prevPortrait => {
                                                        return { 
                                                        ...prevPortrait, 
                                                        notes: e.target.value 
                                                        }
                                                    })
                                                }}
                                                style={{ width: 600 }}
                                            />


                                        </MuiThemeProvider>
                                    </Grid>



                            </TabPanel>
                        </Grid>

                        <Grid item xs={12} >          
                                    <Grid item xs={3} >
                                        <p></p>
                                        <Button
                                            variant="contained"
                                            // color="primary"
                                            size="small"
                                            className={classes.button}
                                            onClick={handleSavePortrait}
                                            disabled={false}
                                            aria-label="whatever 1"
                                            style={{backgroundColor: '#78cbff', color: "white"}}
                                        >
                                            {translate(props.language, 'Save')}
                                        </Button>        

                                    </Grid>


                                    <Grid item xs={9} ></Grid>
                        </Grid>

                        {/* {portrait.subscription_type === 'CmpPaysAll' || portrait.subscription_type === 'CmpPaysSub' || portrait.subscription_type === 'MngPaysAll' || portrait.subscription_type === 'MngPaysSub'?

                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            label="Company Name"
                                            value = {newCompanyName}
                                            helperText="Add New Company Name"
                                            onChange={handleNewCompanyName}
                                        />
                                    </MuiThemeProvider>
                                </Grid>

                                    <Grid item xs={12} >          
                                        <Grid item xs={3} >
                                            <p></p>
                                            <Button
                                                variant="contained"
                                                // color="primary"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleSaveCompany}
                                                disabled={false}
                                                aria-label="whatever 1"
                                                style={{backgroundColor: '#78cbff', color: "white"}}
                                            >
                                                Save New Company
                                            </Button>        

                                        </Grid>


                                        <Grid item xs={9} ></Grid>
                                    </Grid>
                                </Grid>


                        : null } */}


                        </React.Fragment>
                    : null }

          <div><p></p></div>

                    <Grid item xs={12}>
                                    <br />
                                    <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>Add New Company Name &#8198;</div>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <MuiThemeProvider theme={theme}>
                                <TextField
                                    label="Company Name"
                                    value = {newCompanyName}
                                    helperText="Add New Company Name"
                                    onChange={handleNewCompanyName}
                                />
                            </MuiThemeProvider>
                        </Grid>

                            <Grid item xs={12} >          
                                <Grid item xs={3} >
                                    <p></p>
                                    <Button
                                        variant="contained"
                                        // color="primary"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleSaveCompany}
                                        disabled={false}
                                        aria-label="whatever 1"
                                        style={{backgroundColor: '#78cbff', color: "white"}}
                                    >
                                        Save New Company
                                    </Button>        

                                </Grid>


                                <Grid item xs={9} ></Grid>
                            </Grid>
                    </Grid>


            <div><p></p></div>


                    <Grid item xs={12}>
                                    <br />
                                    <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>Show All Customer Data &#8198;</div>
                    </Grid>

                    <Grid item xs={12} >          
                        <Grid item xs={3} >
                            <p></p>
                            <Button
                                variant="contained"
                                // color="primary"
                                size="small"
                                className={classes.button}
                                onClick={handleLoadAllUserData}
                                disabled={false}
                                aria-label="whatever 35"
                                style={{backgroundColor: '#78cbff', color: "white"}}
                            >
                                Load All Customer Data
                            </Button>        

                        </Grid>


                        <Grid item xs={9} ></Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <MuiThemeProvider theme={theme}>
                                  <div style={{ width: '100%' }} >
                                    <DataGrid
                                    //   isCellEditable={handleEditable}
                                      className={classes.gridcell}
                                      // hideFooter={true}
                                      // hideFooterPagination={true}
                                      pageSize={25}
                                      density="compact"
                                      rows={allCustomerDataRows}
                                      columns={allCustomerDataColumns}
                                      // onCellEditCommit={handleCellEditCommit}
                                      disableColumnSelector={true}
                                      disableColumnFilter={true}
                                    //   onRowClick={handleUserSelect}
                                      autoHeight={true}
                                      components={{
                                        NoRowsOverlay: () => (
                                          <div>
                                          </div>
                                        ),
                                        Toolbar:CustomToolbar
                                      }}
                                    />
                                  </div>
                        </MuiThemeProvider>
                    </Grid>





        </Grid>
      </Container>

      </ React.Fragment>



    );
}



