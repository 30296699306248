import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import * as Square from '@square/web-sdk';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';


import { MuiThemeProvider, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';
import { set } from 'date-fns';


// const theme = createMuiTheme({
const theme = createTheme({
    palette: {
      primary: {
        main: '#78cbff',
      },      
      secondary: {
        main: '#78cbff'
      }
    }
  });
  
  const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: "75%",
        marginTop: "2vh",
        marginBottom: "10vh",
        borderRadius: '0px',
        // backgroundColor: theme.palette.action.disabledBackground,
        backgroundColor: 'white',
        height: 'auto'// 1600,
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2), paddingLeft: theme.spacing(4),
        color: theme.palette.primary.main,
    },
    sliders: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    slidertop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    form: {
        width: '90%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
      },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    submit: {
        // margin: theme.spacing(3, 0, 2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2.7),
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  
      root: {
        display: 'flex',
        marginTop: theme.spacing(1),
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        flex: '1 0 auto',
      },
      cover: {
        display: 'flex',
        width: 151,
        // width: 'auto',
        // height: '100%'
      },
      controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      row: {
        height: '200px',
      },
      tooltip: {
        // backgroundColor: '#f5f5f9',
        // color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 'large',// theme.typography.pxToRem(20),
        // border: '5px solid #ffffff',
      },
      image: {
        // border: '5px solid #ffffff',
        float: 'left',
        // width: "200px",
        // height: "auto",
        width: "auto",
        height: "100px",
        marginLeft: '20px',
        marginTop: '20px',
      },
      sidebyside: {
        float: 'left',
        // marginLeft: '20px'
      },
      Textgreen: {
        color: "#33cc33",
        width: "1%",
        whiteSpace: "nowrap"
      },
      Textred: {
            color: "#e60000",
            width: "1%",
            whiteSpace: "nowrap"
          },
      sendleft: {
        float: "left",
        // margineBottom: '10px'
      },
      gridcell:{
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .super-app.hide': {
          // visibility: 'collapse',
        },
        '& .super-app.show': {
          backgroundColor: 'rgba(157, 255, 118, 0.49)',
        },
        '& .super-app.blocked': {
          backgroundColor: 'rgba(255, 0, 0, 0.49)',
        },
        '& .super-app.indent': {
          // marginLeft: "30px",
          paddingLeft: "30px",
        },
        '& .super-app.noindent': {
        },
  
      },
  }));
  

export default function YPCSurvey(props) {
  const classes = useStyles();
  const [haveOrder, setHaveOrder] =  React.useState(false);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [order, setOrder] = React.useState([]);
  const [datevalue, setDatevalue] = React.useState('');
  const [mealtime, setMealtime] = React.useState('');

  const [answers, setAnswers] = React.useState({'Comment': {'value':null, 'name': 'N/A', 'sides': 'N/A', 'category': 'N/A'}, 'Suggestion': {'value':null, 'name': 'N/A', 'sides': 'N/A', 'category': 'N/A'}});
  
  const [showMessage, setShowMessage] = React.useState(false);

  const [showSubmit, setShowSubmit] = React.useState(false);

    React.useEffect(() => {
        if (!haveOrder) {
           getOrder();
           setHaveOrder(true);
        };
    });

    let idString = new URLSearchParams(useLocation().search).get("id");


    async function  getOrder() {
        // console.log('IDIDIDIDIDIDIDIDIDI', idString);

        if (idString !== null) {
            var temp_dict = await YPCGetAxios(xget, setXGet, 'getSurveyURL', idString);
            if (temp_dict !== {}) {
                setDatevalue(temp_dict['datevalue'])
                setMealtime(temp_dict['mealtime'])
                setOrder(temp_dict['order']);
            } else {
                setDatevalue('')
                setMealtime('')
                setOrder([]);
            }

        } else {
            setDatevalue('')
            setMealtime('')
            setOrder([]);
        };

    };


    async function handleSubmit() {
      // setShowRow(false);
      if (answers.hasOwnProperty('Enjoy') && answers.hasOwnProperty('Recommend')) {
        // do the save here
        await YPCPutAxios2(setXPost, 'saveSurveyURL', answers, '?id=' + idString);
      };
      setShowMessage(true);
      console.log('WAWAWAWAWA', answers);
    };

    function updateQuestions(value, object_key, name='N/A', sides='N/A', category='N/A') {

      let tempAnswers = answers;
      tempAnswers[object_key] = {'value' : value, 'name': name, 'sides': sides, 'category': category};
      setAnswers(tempAnswers);

      // if (tempAnswers.hasOwnProperty('Enjoy') && tempAnswers.hasOwnProperty('Recommend')) {
      //   setShowSubmit(true);
      // };
      console.log('QAQAQAQAQAQAQ', tempAnswers);
    };


    function showDialog() {
      // console.log('XPXPXPXPXPXPXPXP', xpost)
      return (
        <div>
          <Dialog open={true} 
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
          >
          {/* <Dialog> */}
            <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {answers.hasOwnProperty('Enjoy') && answers.hasOwnProperty('Recommend') ? 
                    <div>
                      Évaluation envoyer - Merci!/Survey sent - Thank you!
                    </div>
                  :
                    <div>
                      Questions requis non remplies/Required questions not filled out
                    </div>
                  }

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleShowDialog} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
  
      );
    };


    const handleShowDialog = () => {
      setShowMessage(false);
  };

    function getSurvey() {

        let mainQuestionList = [];
        let mainQuestion = '';
        let sideCollection = {}

        console.log('IQIIIIIIIQI', order);
        order.map((row, index) => {
          if (row.category !== 'Side') {
            mainQuestionList.push(row);
            mainQuestion = row.name;
            sideCollection[mainQuestion] = [];
          } else {
              let tempCollection = sideCollection[mainQuestion];
              tempCollection.push(row)
              sideCollection[mainQuestion] = tempCollection;
          };

        });

        // console.log('UAUAUAUAUAU List', mainQuestionList)
        // console.log('UAUAUAUAUAU List', sideCollection)


        return (
            <React.Fragment>

                <br />
                <Typography component="h5" variant="h5">
                    {datevalue}
                </Typography> 
                <br />

                <Typography style={{color: 'red'}}>
                    * Required/Requis
                </Typography> 
                <br />

                <Typography component="h5" variant="h5">
                    <span style={{color: 'red'}}>*</span>Avez-vous apprécié vos repas/Did you enjoy your meal?
                </Typography> 
                <br />                                
                <FormControl>
                    {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue="Yes"
                      name="radio-buttons-group"
                      onChange={(e) => {
                        // console.log('RARARARARARACCCCC', e.target.value)
                        // setQuestion1(e.target.value)
                        updateQuestions(e.target.value, 'Enjoy')
                      }}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Oui/Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="Non/No" />
                    </RadioGroup>
                </FormControl>                
                <br /><br />
                <Typography component="h5" variant="h5">
                    Pouvez-vous nous donner votre avis sur les plats que vous avez essayés/Can you give us your opinion regarding the dishes you tried?
                </Typography> 
                <br />
                {mainQuestionList.map((row, index) => (

                            <React.Fragment>

                                <Typography component="h6" variant="h6">
                                    {row.french_name}/{row.name}
                                </Typography>

                                { sideCollection[row.name].map((subRow) => (
                                    <Typography component="h6" variant="h6" style={{ marginLeft: "30px" }}>
                                        avec du {subRow.french_name}/with {subRow.name}
                                    </Typography>      
                                  ))
                                }


                                <React.Fragment>
                                    <FormControl>
                                        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                                        <RadioGroup
                                          onChange={(e) => {
                                            // console.log('RARARARARARACCCCC', e.target.value)
                                            // setQuestion2(e.target.value)
                                            updateQuestions(e.target.value, 'Opinion' + index.toString(), row.name, sideCollection[row.name], row.category)
                                          }}
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          // defaultValue="G"
                                          name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="VB" control={<Radio />} label="Très mauvais/Very bad" />
                                            <FormControlLabel value="NG" control={<Radio />} label="Pas assez bon/Not good enough" />
                                            <FormControlLabel value="G" control={<Radio />} label="Bon/Good" />
                                            <FormControlLabel value="VG" control={<Radio />} label="Très bon/Great" />
                                        </RadioGroup>
                                    </FormControl>
                                    <br /><br />
                                </React.Fragment>

                            </React.Fragment>

                ))}


                <Typography component="h5" variant="h5">
                    Avez-vous des commentaires ou des suggestions concernant la saveur des plats/Do you have any comments or suggestions regarding the flavor of the dishes?
                </Typography> 


                <Grid item xs={12}>
                      <MuiThemeProvider theme={theme}>
                          <p></p>
                          {/* <Typography  style={{color: "#444444"}} >Food Restrictions and/or Allergies</Typography> */}

                          <TextareaAutosize
                              maxRows={10}
                              minRows={10}
                              aria-label="maximum height 1"
                              placeholder="Commentaire ou des suggestions/Comments or suggestions"
                              label="Commentaire ou des suggestions/Comments or suggestions"
                              // value = {answers['Comment']}
                              value = {answers.Comment.value}
                              helperText="Commentaire ou des suggestions/Comments or suggestions"
                              onChange={(e) => {
                                  // updateQuestions(e.target.value, 'Comment')
                                setAnswers(prevAnswers => {
                                  return { 
                                  ...prevAnswers, 
                                  Comment: {'value':e.target.value , 'name': 'N/A', 'sides': 'N/A', 'category': 'N/A'}
                                  }
                                })

                              }}
                              style={{ width: 600 }}
                          />

                      </MuiThemeProvider>
                  </Grid>


                <br /><br />
                <Typography component="h5" variant="h5">
                    <span style={{color: 'red'}}>*</span>Recommanderiez-vous Jasper Cooks à un ami/How likely are you to recommend Jasper Cooks to a friend (1 = définitivement pas/defnitely not, 10 = absolument/definitely)?
                </Typography> 
                <br />                                
                <FormControl>
                    {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                    <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="Yes"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      // console.log('RARARARARARACCCCC', e.target.value)
                      // setQuestion4(e.target.value)
                      updateQuestions(e.target.value, 'Recommend')
                    }}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="1" labelPlacement="top"/>
                        <FormControlLabel value="2" control={<Radio />} label="2" labelPlacement="top"/>
                        <FormControlLabel value="3" control={<Radio />} label="3" labelPlacement="top"/>
                        <FormControlLabel value="4" control={<Radio />} label="4" labelPlacement="top"/>
                        <FormControlLabel value="5" control={<Radio />} label="5" labelPlacement="top"/>
                        <FormControlLabel value="6" control={<Radio />} label="6" labelPlacement="top"/>
                        <FormControlLabel value="7" control={<Radio />} label="7" labelPlacement="top"/>
                        <FormControlLabel value="8" control={<Radio />} label="8" labelPlacement="top"/>
                        <FormControlLabel value="9" control={<Radio />} label="9" labelPlacement="top"/>
                        <FormControlLabel value="10" control={<Radio />} label="10" labelPlacement="top"/>

                    </RadioGroup>
                </FormControl>                
                <br /><br />



                <Typography component="h5" variant="h5">
                    Y a-t-il un plat que vous aimeriez voir dans notre carte/Is there a dish you would like to see in our menu?
                </Typography> 


                <Grid item xs={12}>
                      <MuiThemeProvider theme={theme}>
                          <p></p>
                          {/* <Typography  style={{color: "#444444"}} >Food Restrictions and/or Allergies</Typography> */}

                          <TextareaAutosize
                              maxRows={10}
                              minRows={10}
                              aria-label="maximum height 2"
                              placeholder="Suggestion de plat/Dish suggestion"
                              label="Suggestion de plat/Dish suggestion"
                              // value = {answers['Suggestion']}
                              value = {answers.Suggestion.value}
                              helperText="Suggestion de plat/Dish suggestion"
                              onChange={(e) => {
                                  // updateQuestions(e.target.value, 'Suggestion')
                                  setAnswers(prevAnswers => {
                                    return { 
                                    ...prevAnswers, 
                                    Suggestion: {'value':e.target.value , 'name': 'N/A', 'sides': 'N/A', 'category': 'N/A'}
                                    }
                                  })

                              }}
                              style={{ width: 600 }}
                          />


                      </MuiThemeProvider>
                  </Grid>

            </React.Fragment>


        );
    };



  return (

    <React.Fragment>

    <Container className={classes.container}  style={{ marginTop: "2em" }}>
      <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
        <Grid item xs={12}>

            <Grid item xs={12}>
                <div className={classes.sendleft}>
                    {showMessage ? showDialog(): null}
                    <Typography component="h4" variant="h4">
                        Évaluation/Survey
                    </Typography>
                </div>

                <div style={{clear: "both"}}></div>
            </Grid>


            <Grid item xs={12}>
                { order.length !== 0 ?
                    getSurvey()
                    : null
                }
            </Grid>

        </Grid>

        <Grid item xs={12}>
                  {/* { showSubmit ? */}
                        <div>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleSubmit}
                                disabled = {false}
                                // disabled={!(answers.hasOwnProperty('Enjoy') && answers.hasOwnProperty('Recommend'))}
                                aria-label="submit"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                Envoyer/Submit
                            </Button> 
                        </div>
                    {/* : null
                  } */}

        </Grid>      

      </Grid>
    </Container>
    </React.Fragment>




    );
}


