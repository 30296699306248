import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import YPCSurvey from "./components/YPCSurvey";
import PasswordUpdate from "./components/PasswordUpdate";
import YPCCreateMenu from "./components/YPCCreateMenu";
// import LastYPCCreateMenu from "./components/LastYPCCreateMenu";
import YPCCreateRecipe from "./components/YPCCreateRecipe";
import YPCGetAllRecipes from "./components/YPCGetAllRecipes";
import YPCCreateIngredient from "./components/YPCCreateIngredient";
import YPCViewDayOrders from "./components/YPCViewDayOrders";
import YPCCustomerReturns from "./components/YPCCustomerReturns";
import YPCOrderTickets from "./components/YPCOrderTickets";
import YPCViewWeeklyOrders from "./components/YPCViewWeeklyOrders";
import YPCGetAllSurveys from "./components/YPCGetAllSurveys";
import YPCSimOrders from "./components/YPCSimOrders";
import YPCInventory from "./components/YPCInventory";
import YPCLegalDisclaimer from "./components/YPCLegalDisclaimer";
import YPCOrderMeal from "./components/YPCOrderMeal";
import YPCOrderMealTable from "./components/YPCOrderMealTable";
import YPCOrderMealCards from "./components/YPCOrderMealCards";
import YPCOrderMealCust from "./components/YPCOrderMealCust";
import YPCWeeklyBillings from "./components/YPCWeeklyBillings";
import YPCReferral from "./components/YPCReferral";
import YPCMasterBillings from "./components/YPCMasterBillings";
import YPCOrderMaster from "./components/YPCOrderMaster";
import YPCPortraitMaster from "./components/YPCPortraitMaster";
import YPCCook from "./components/YPCCook";
// import YPCOperator from "./components/YPCOperator";
import YPCRobotPrep from "./components/YPCRobotPrep";
import YPCRobotCook from "./components/YPCRobotCook";
import YPCHubMep from "./components/YPCHubMep";
// import LastYPCOrderMeal from "./components/LastYPCOrderMeal";
import YPCHome from "./components/YPCHome";
import YPCNoAccess from "./components/YPCNoAccess";
import YPCTest from "./components/YPCTest";

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
function PrivateRoute({ isAuthenticated, children, ...rest}) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
        isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }
 

  function MasterRoute({ isAuthenticated, universeMaster, children, ...rest}) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
        isAuthenticated && universeMaster ? (
            children
          ) : (

            isAuthenticated ? (
                <Redirect
                to={{
                  pathname: "/noaccess/"
                }}
              />

            )
            : (
            <Redirect
              to={{
                pathname: "/login/",
                state: { from: location }
              }}
            />
            )


          )
        }
      />
    );
  }


function RegularRoute({children, ...rest}) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        children

      }
    />
  );

}

function Urls(props) {

    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/login/"> <Login {...props} language={props.language}/></Route>
                    <Route exact path="/reset_password/"> <ResetPassword {...props} language={props.language}/></Route>
                    <Route exact path="/survey/"> <YPCSurvey {...props} language={props.language}/></Route>
                    <PrivateRoute exact path="/update_password/" isAuthenticated={props.isAuthenticated}><PasswordUpdate {...props} language={props.language} /></PrivateRoute>
                    <MasterRoute exact path="/createmenu" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCCreateMenu {...props}  language={props.language}/></MasterRoute>                    
                    {/* <MasterRoute exact path="/lastcreatemenu" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><LastYPCCreateMenu {...props}  language={props.language}/></MasterRoute>                     */}
                    <MasterRoute exact path="/createrecipe" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCCreateRecipe {...props}  language={props.language}/></MasterRoute>                    
                    <MasterRoute exact path="/getallsurveys" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCGetAllSurveys {...props}  language={props.language}/></MasterRoute>                    
                    <MasterRoute exact path="/getallrecipes" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCGetAllRecipes {...props}  language={props.language}/></MasterRoute>                    
                    <MasterRoute exact path="/createingredient" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCCreateIngredient {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/viewdayorders" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCViewDayOrders {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/customerreturns" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCCustomerReturns {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/ordertickets" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCOrderTickets {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/cook" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCCook {...props}  language={props.language}/></MasterRoute>
                    {/* <MasterRoute exact path="/operator" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCOperator {...props}  language={props.language}/></MasterRoute> */}
                    <MasterRoute exact path="/robotprep" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCRobotPrep {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/robotcook" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCRobotCook {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/hubmep" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCHubMep {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/viewweeklyorders" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCViewWeeklyOrders {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/inventory" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCInventory {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/portraitmaster" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCPortraitMaster {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/masterorder" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCOrderMaster {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/simorder" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCSimOrders {...props}  language={props.language}/></MasterRoute>
                    <MasterRoute exact path="/masterbillings" isAuthenticated={props.isAuthenticated} universeMaster={props.universeMaster}><YPCMasterBillings {...props}  language={props.language}/></MasterRoute>

                    <PrivateRoute exact path="/" isAuthenticated={props.isAuthenticated}><YPCOrderMealCust {...props}  language={props.language}/></PrivateRoute>                    

                    {/* <PrivateRoute exact path="/ordermealcards" isAuthenticated={props.isAuthenticated}><YPCOrderMealCards {...props}  language={props.language}/></PrivateRoute>                     */}

                    <PrivateRoute exact path="/ordermealcustomers" isAuthenticated={props.isAuthenticated}><YPCOrderMealCust {...props}  language={props.language}/></PrivateRoute>                    

                    {/* <PrivateRoute exact path="/ordermealtable" isAuthenticated={props.isAuthenticated}><YPCOrderMealTable {...props}  language={props.language}/></PrivateRoute>                     */}
                    <PrivateRoute exact path="/ordermeal" isAuthenticated={props.isAuthenticated}><YPCOrderMeal {...props}  language={props.language}/></PrivateRoute>                    
                    <PrivateRoute exact path="/disclaimer" isAuthenticated={props.isAuthenticated}><YPCLegalDisclaimer {...props}  language={props.language}/></PrivateRoute>                    
                    <PrivateRoute exact path="/test" isAuthenticated={props.isAuthenticated}><YPCTest {...props}  language={props.language}/></PrivateRoute>                    
                    <PrivateRoute exact path="/weeklybillings" isAuthenticated={props.isAuthenticated}><YPCWeeklyBillings {...props}  language={props.language}/></PrivateRoute>   
                    <PrivateRoute exact path="/referral" isAuthenticated={props.isAuthenticated}><YPCReferral {...props}  language={props.language}/></PrivateRoute>   
                    {/* <PrivateRoute exact path="/lastordermeal" isAuthenticated={props.isAuthenticated}><LastYPCOrderMeal {...props}  language={props.language}/></PrivateRoute>                     */}
                    {/* <RegularRoute exact path="/createmenu" ><YPCCreateMenu {...props}/></RegularRoute>                     */}
                    {/* <RegularRoute exact path="/ordermeal" ><YPCOrderMeal {...props}/></RegularRoute>                     */}
                    {/* <RegularRoute exact path="/" ><YPCHome {...props}/></RegularRoute>  */}
                    {/* <PrivateRoute exact path="/" isAuthenticated={props.isAuthenticated}><YPCHome {...props}  language={props.language}/></PrivateRoute> */}
                    <PrivateRoute exact path="/noaccess" isAuthenticated={props.isAuthenticated}><YPCNoAccess {...props}  language={props.language}/></PrivateRoute>
                    {/* <PrivateRoute exact path="/" isAuthenticated={props.isAuthenticated}><Home {...props}/></PrivateRoute> */}

                </Switch>
            </BrowserRouter>
        </div>
    )
};

export default Urls;