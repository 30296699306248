import 'date-fns';
import React from 'react';

import clsx from 'clsx';
import {createTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {DataGrid, gridClasses, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';

import Rating from '@mui/material/Rating';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Container} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import {v4 as uuidv4} from 'uuid';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCDeleteAxiosAlt from './YPCDeleteAxiosAlt';
import translate from "./YPCTranslate";

import moment from 'moment';

// import { duration } from 'moment';
// import { set } from 'date-fns';
// import { id, te } from 'date-fns/locale';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },
    secondary: {
      main: '#78cbff'
    }
  }
});


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "100%",
    marginTop: "2vh",
    marginBottom: "10vh",
    borderRadius: '0px',
    // backgroundColor: theme.palette.action.disabledBackground,
    backgroundColor: 'white',
    height: 'auto'// 1600,
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2), paddingLeft: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  sliders: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  slidertop: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.7),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  root: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    display: 'flex',
    width: 151,
    // width: 'auto',
    // height: '100%'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  row: {
    height: '200px',
  },
  tooltip: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 'large',// theme.typography.pxToRem(20),
    // border: '5px solid #ffffff',
  },
  image: {
    // border: '5px solid #ffffff',
    float: 'left',
    // width: "200px",
    // height: "auto",
    width: "auto",
    height: "100px",
    marginLeft: '20px',
    marginTop: '20px',
  },
  sidebyside: {
    float: 'left',
    // marginLeft: '20px'
  },
  Textgreen: {
    color: "#33cc33",
    width: "1%",
    whiteSpace: "nowrap"
  },
  Textred: {
    color: "#e60000",
    width: "1%",
    whiteSpace: "nowrap"
  },
  sendleft: {
    float: "left",
    // margineBottom: '10px'
  },
  gridcell: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },

    // *************************************
    // following enables the auto row height, but it doesn't work that great
    // '&.MuiDataGrid-root  .MuiDataGrid-renderingZone': {
    //   maxHeight: 'none !important',
    // },
    // '&.MuiDataGrid-root  .MuiDataGrid-cell': {
    //     lineHeight: 'unset !important',
    //     maxHeight: 'none !important',
    //     whiteSpace: 'normal',
    // },
    // '&.MuiDataGrid-root  .MuiDataGrid-row': {
    //     maxHeight: 'none !important',
    // },
    // ****************************************


    '& .super-app.hide': {
      // visibility: 'collapse',
    },
    '& .super-app.show': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
    },
    '& .super-app.grey': {
      backgroundColor: 'rgba(200, 200, 200, 0.49)',
    },
    '& .super-app.cooking': {
      backgroundColor: 'rgba(255, 0, 0, 0.25)',
    },
    '& .super-app.garnish': {
      backgroundColor: 'rgba(0, 255, 0, 0.25)',
    },
    '& .super-app.oven': {
      backgroundColor: 'rgba(255, 152, 48, 0.25)',
    },
    '& .super-app.indent': {
      // marginLeft: "30px",
      paddingLeft: "30px",
    },
    '& .super-app.noindent': {},

  },
  gridoverlay: {
    flexDirection: 'column'
  },
  label: {
    marginTop: theme.spacing(1),
  },

}));

const categoryList = ['Starter', 'Main', 'Side', 'Fruit', 'Drink', 'Dessert'];

const chefList = ['', 'Serge Blanc', 'Gus Farra', 'Carolina Velendia', 'David Vielfaure', 'Camilo Perez', 'Remy the Rat (Ratatouille)', 'Swedish Chef (Muppets)'];

const fullSubcategoryList = ["Baked", "Beef", "Bread", "Chicken", "Chips", "Cocktail", "Cold", "Dip", "Frozen", "Fruit", "Juice/Smoothie", "Lamb", "Milkshake",
  "Pasta", "Pork", "Potatoes", "Puree", "Rice", "Salad", "Sandwich", "Sauce", "Seafood", "Soup", "Tart/Cake", "Veal", "Vegetarian",
  "Warm", "NA"]

// const subCategoryList = {'Dessert' : ['Cold', 'Frozen', 'Tart/Cake', 'Warm'],
//                     'Drink': ['Cocktail', 'Juice/Smoothie', 'Milkshake'],
//                     'Fruit': ['Fruit'],
//                     'Main' : ['Beef', 'Lamb', 'Chicken', 'Pasta', 'Pork', 'Rice', 'Salad', 'Sandwich', 'Seafood',
// 'Soup', 'Veal', 'Vegetarian'], 'Side' : ['Bread', 'Chips', 'Vegetables', 'Pasta', 'Potatoes', 'Puree', 'Rice',
// 'Salad', 'Sauce'], 'Starter' : ['Baked', 'Dip', 'Salad', 'Seafood', 'Soup', ]}

const subCategoryList = {
  'All': fullSubcategoryList,
  'Dessert': fullSubcategoryList,
  'Drink': fullSubcategoryList,
  'Fruit': fullSubcategoryList,
  'Main': fullSubcategoryList,
  'Side': fullSubcategoryList,
  'Starter': fullSubcategoryList
}


const cuisineList = ['World', 'French', 'American', 'Italian', 'Chinese', 'UK', 'Caribbean', 'Indian',
  'Mexican', 'Korean', 'Asian', 'Japanese', 'Moroccan', 'Thai', ''].sort();

// const ingredientList = ['Flour', 'Olive Oil', 'Milk', 'Salt', 'Cheddar Cheese', 'Pepper'];

// const ingredientUnitSize = {'Flour' : 1, 'Olive Oil': 2, 'Milk': 3, 'Salt': 1, 'Cheddar Cheese':2, 'Pepper' : 2, '':
// 1}; const ingredientUnitPrice = {'Flour' : 0.4, 'Olive Oil': 0.7, 'Milk': 0.8, 'Salt': 0.33, 'Cheddar Cheese':1.21,
// 'Pepper' : 0.10, '': 0.0}; const ingredientId = {'Flour' : 72, 'Olive Oil': 27, 'Milk': 44, 'Salt': 62, 'Cheddar
// Cheese':73, 'Pepper' : 20, '': 85};

const sendToDict = {'None': 0, 'Cooker': 1, 'Oven': 2, 'Scale': 3, 'Robotic Arm': 4, 'Liquid Dispenser': 5, 'Human': 6};
const sendToList = ['None', 'Cooker', 'Oven', 'Scale', 'Robotic Arm', 'Liquid Dispenser', 'Human'];

const spiceLookupToString = ['None', 'Low', 'Med', 'Med', 'High', 'High'];
const spiceLookupToNumber = {'None': 0, 'Low': 1, 'Med': 3, 'High': 5};

export default function YPCCreateRecipe(props) {

  const classes = useStyles();
  // const theme = useTheme();
  const [menuLoaded, setMenuLoaded] = React.useState(false);

  const [menuDB, setMenuDB] = React.useState([]);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [senseList, setSenseList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {
    id: 1,
    costtype: "Abra cadabra2",
    cost: 1.15
  }]);

  // const [markups, setMarkups] = React.useState({'Main':0.70, 'Starter': 0.7, 'Dessert':0.7, 'Drink': 0.5, 'Fruit':
  // 0.0, 'Side': 0.0, '': 0.0 }); following changed August 15, 2022
  const [markups, setMarkups] = React.useState({
    'Main': 1.00,
    'Starter': 0.7,
    'Dessert': 0.7,
    'Drink': 0.5,
    'Fruit': 0.0,
    'Side': 0.0,
    '': 0.0
  });

  const [markupMultiplier, setMarkupMultiplier] = React.useState({
    'Main': 1.5,
    'MainVeg': 2.0,
    'Starter': 2.0,
    'Dessert': 1.5,
    'Drink': 1.2,
    'Fruit': 0.0,
    'Side': 1.5,
    '': 0.0
  });


  const [portion, setPortion] = React.useState(1);
  const [multiplier, setMultiplier] = React.useState(1.0);
  const [recipeNameEng, setRecipeNameEng] = React.useState("");
  const [recipeNameFr, setRecipeNameFr] = React.useState("");
  const [recipeDescriptionEng, setRecipeDescriptionEng] = React.useState("");
  const [recipeDescriptionFr, setRecipeDescriptionFr] = React.useState("");
  const [category, setCategory] = React.useState("Starter");
  const [subCategory, setSubCategory] = React.useState("Soup");
  const [cuisine, setCuisine] = React.useState("World");
  const [spiceLevel, setSpiceLevel] = React.useState(0);
  const [vegetarian, setVegetarian] = React.useState('No');
  const [vegan, setVegan] = React.useState('No');
  const [sugar_free, setSugarfree] = React.useState('No');
  const [lunchbox, setLunchbox] = React.useState('Yes');
  const [totalCost, setTotalCost] = React.useState(0.0);
  const [totalRetailCost, setTotalRetailCost] = React.useState(0.0);
  const [glutenFree, setGlutenFree] = React.useState('No');
  const [percentAutomation, setPercentAutomation] = React.useState(0);
  const [totalCookingTime, setTotalCookingTime] = React.useState(0.0);
  const [totalCalories, setTotalCalories] = React.useState(0.0);
  const [totalGrams, setTotalGrams] = React.useState(0.0);
  const [shelfLife, setShelfLife] = React.useState(0.0);
  const [stepsList, setStepsList] = React.useState([]);
  const [selectStep, setSelectStep] = React.useState("");
  const [selectedSidesValue, setSelectedSidesValue] = React.useState(null);
  const [selectSidesDelete, setSelectSidesDelete] = React.useState("");
  const [sidesOrderList, setSidesOrderList] = React.useState([]);
  const [lastStepType, setLastStepType] = React.useState(null);
  const [babyFriendly, setBabyFriendly] = React.useState('No');
  const [seniorFriendly, setSeniorFriendly] = React.useState('No');
  const [peanutFree, setPeanutFree] = React.useState('No');
  const [robotTested, setRobotTested] = React.useState(false);
  const [humanTested, setHumanTested] = React.useState(false);
  const [supportOffline, setSupportOffline] = React.useState(false);
  const [cookAtHub, setCookAtHub] = React.useState(false);
  const [reviewStars, setReviewStars] = React.useState(0);
  const [selectedEditRecipe, setSelectedEditRecipe] = React.useState("");
  const [doubleCheckOpen, setDoubleCheckOpen] = React.useState(false);
  const [deleteCheckOpen, setDeleteCheckOpen] = React.useState(false);

  const [createdByChef, setCreatedByChef] = React.useState("");

  const [recipeList, setRecipeList] = React.useState([]);
  const [recipeId, setRecipeId] = React.useState('');

  const [ingredientList, setIngredientList] = React.useState([]);
  const [ingredientUnitSize, setIngredientUnitSize] = React.useState({'': 1});
  const [ingredientUnitPrice, setIngredientUnitPrice] = React.useState({'': 0.0});
  const [ingredientRetailPrice, setIngredientRetailPrice] = React.useState({'': 0.0});
  const [ingredientUnitCalories, setIngredientUnitCalories] = React.useState({'': 0.0});
  const [ingredientId, setIngredientId] = React.useState({'': 1000000});
  const [sideList, setSideList] = React.useState([]);

  const [stringed, setStringed] = React.useState(null);

  const [allRobotHumanChoice, setAllRobotHumanChoice] = React.useState('All');
  const [categoryFilterChoice, setCategoryFilterChoice] = React.useState('All');
  const [subcategoryFilterChoice, setSubcategoryFilterChoice] = React.useState('All');
  const [tagString, setTagString] = React.useState('');

  const [printBuffer, setPrintBuffer] = React.useState([]);

  const [createdTime, setCreatedTime] = React.useState("");
  const [updatedTime, setUpdatedTime] = React.useState("")

  const [testValue, setTestValue] = React.useState('');

  React.useEffect(() => {
    if (!menuLoaded) {
      handleLoadDB();
    }
  }, [menuLoaded]);

  React.useEffect(() => {
    calculateStats();
  }, [portion, category, stepsList, sidesOrderList, props.language]);

  React.useEffect(() => {
    calculatePrintTable();
  }, [stepsList]);


  const handleClearAll = () => {
    setPortion(1);
    setRecipeNameEng('');
    setRecipeNameFr("");
    setRecipeDescriptionEng('');
    setRecipeDescriptionFr("");
    setCookAtHub(false);
    setCreatedByChef('');
    setCategory("Starter");
    setSubCategory("Soup");
    setCuisine("World");
    setSpiceLevel(0);
    setVegetarian('No');
    setVegan('No');
    setSugarfree('No');
    setLunchbox('Yes');
    setGlutenFree('No');
    setPercentAutomation(0);
    setShelfLife(0);
    setStepsList([]);
    setSelectStep('');
    setSelectedSidesValue(null);
    setSelectSidesDelete("");
    setSidesOrderList([]);
    setBabyFriendly('No');
    setSeniorFriendly('No');
    setPeanutFree('No');
    setReviewStars(0);
    setCreatedTime('');
    setUpdatedTime('');
  };

  const handleLoadDB = () => {
    setMenuDB([]);
    setMenuLoaded(true);


    getIngredients();
    getRecipes();
    getSideRecipes();

  };

  async function getSideRecipes() {
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesCategoryURL', '');
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesCategoryURL', '');
    result = result.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    setSideList(result);
    // console.log('KKKKKKKKKKKKKKKK', result);

  }

  async function getIngredients() {
    // var result = await YPCGetAxios(xget, setXGet, 'allIngredientsFullURL', '');
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallIngredientsFullURL', '');

    let iList = [];
    let iUnitSize = {'': 1};
    let iUnitPrice = {'': 0.0};
    let iRetailPrice = {'': 0.0};
    let iID = {'': 10000000};
    let iUnitCalories = {'': 0.0};

    result.map((value) => {
      iList.push(value.name);
      iUnitSize[value.name] = value.unit_size;
      iUnitPrice[value.name] = value.unit_price;
      iRetailPrice[value.name] = value.retail_price;
      iID[value.name] = value.id;
      iUnitCalories[value.name] = value.calories;
    });

    iList = iList.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });


    setIngredientList(iList);
    setIngredientUnitSize(iUnitSize);
    setIngredientUnitPrice(iUnitPrice);
    setIngredientRetailPrice(iRetailPrice);
    setIngredientId(iID);
    setIngredientUnitCalories(iUnitCalories);

    // console.log('IIIIIIIIIIIIII', result);
  }

  async function getRecipes() {
    let paramString = '?'
    if (categoryFilterChoice !== 'All') {
      paramString = paramString + "category=" + categoryFilterChoice;
    }
    if (subcategoryFilterChoice !== 'All') {
      paramString = paramString + "&subcategory=" + subcategoryFilterChoice;
    }
    if (allRobotHumanChoice !== 'All') {
      paramString = paramString + "&" + tagString;
    }
    if (paramString === '?') {
      paramString = '';
    }

    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesURL', '');   // not used
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesWithTagsURL', tagString);
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', paramString);
    setRecipeList(result.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }));
    // console.log('JJJJJJJJJJJJJ', result);
  }

  const preSaveRecipe = () => {

    let preRecipeName = recipeNameEng.trim()
    // if (recipeList.includes(recipeNameEng)) {      
    if (recipeList.includes(preRecipeName)) {
      setDoubleCheckOpen(true);
    } else {
      handleSaveRecipe();
    }
  };


  async function handleSaveRecipe() {
    let sides_table = [];
    sidesOrderList.map((value) => {
      sides_table.push({
        side_recipe_name: value.dish,
        composition_num: value.index
      });
    });

    let step_num = 1;
    // let step_group_num = 0;
    let ing_steps = [];
    let cooking_steps = [];
    let oven_steps = [];
    let human_steps = [];
    let child_recipe_steps = [];
    let previousStepType = 'whatever';
    let mep_group_number_list = [];
    let mep_presets = []
    stepsList.map((value) => {
      if (value.step_type === 'Ingredient' || value.step_type === 'Garnish') {
        ing_steps.push(
          {
            "step_num": step_num,
            "step_group_num": value.step_group_num,
            "mep_group_num": value.mep_group_num === 0 ? null : value.mep_group_num,
            "executor": null,
            'step_type': 'ingredient',
            'ing_name': value.col1,
            'ing_id': ingredientId[value.col1],
            'amount': value.col2,
            'send_to': value.step_type === 'Garnish' ? sendToDict['Human'] : sendToDict[value.col3],
            'notes': value.col6

          }
        );

        if (!mep_group_number_list.includes(value.mep_group_num) && value.mep_group_num !== 0) {
          mep_group_number_list.push(value.mep_group_num);
          mep_presets.push({
            'mep_group_num': value.mep_group_num,
            'executor': value.step_type === 'Garnish' ? executorName2Num['Human'] : executorName2Num[value.executor],
            'container_type': value.step_type === 'Garnish' ? 'any' : value.container_type,
            'store_temperature': value.step_type === 'Garnish' ? 'fridge' : value.store_temperature,
            'speed_factor': value.speed_factor,
            'recipe_id': 0
          })
        }

      } else if (value.step_type === 'Recipe') {
        child_recipe_steps.push(
          {
            "step_num": step_num,
            "step_group_num": value.step_group_num,
            // "mep_group_num": value.mep_group_num === 0 ? null : value.mep_group_num,
            // "executor": null,
            'step_type': 'child_recipe',
            // 'ing_name': value.col1,
            // 'ing_id': ingredientId[value.col1],
            // 'amount': value.col2,
            // 'send_to': sendToDict[value.col3],
            'child_recipe_category': "static",
            'notes': value.col6,
            'child_recipe': {
              'name': value.col1,
              'id': 0,
              'shelf_life': 0
            },
            'as_ingredient_settings': {
              'amount': value.col2,
              'executor': executorName2Num[value.executor],
              'send_to': sendToDict[value.col3],
              "mep_group_num": value.mep_group_num === 0 ? null : value.mep_group_num
            }

          }
        );

        if (!mep_group_number_list.includes(value.mep_group_num) && value.mep_group_num !== 0) {
          mep_group_number_list.push(value.mep_group_num);
          mep_presets.push({
            'mep_group_num': value.mep_group_num,
            'executor': executorName2Num[value.executor],
            'container_type': value.container_type,
            'store_temperature': value.store_temperature,
            'speed_factor': value.speed_factor,
            'recipe_id': 0
          })
        }

      } else if (value.step_type === 'Cooking') {
        // step_group_num = step_group_num + 1;
        cooking_steps.push(
          {
            "step_num": step_num,
            // "step_group_num": step_group_num,
            "step_group_num": value.step_group_num,
            // "mep_group_num": value.mep_group_num,
            // "executor": value.executor,
            "step_type": "cooking",
            "cook_time": value.col1,
            "cook_temp": value.col2,
            "cook_speed": value.col3,
            "increase_duration": value.col4,
            "lid_status": value.col5,
            'notes': value.col6
          }
        );
      } else if (value.step_type === 'Oven') {
        // step_group_num = step_group_num + 1;
        oven_steps.push(
          {
            "step_num": step_num,
            // "step_group_num": step_group_num,
            "step_group_num": value.step_group_num,
            // "mep_group_num": value.mep_group_num,
            // "executor": value.executor,
            "step_type": "oven",
            "cook_time": value.col1,
            "cook_temp": value.col2,
            "cook_humidity": value.col3,
            "mode": value.col4,
            'notes': value.col6
          }
        );
      } else if (value.step_type === 'Human') {
        // step_group_num = step_group_num + 1;
        human_steps.push(
          {
            "step_num": step_num,
            // "step_group_num": step_group_num,
            "step_group_num": value.step_group_num,
            // "mep_group_num": value.mep_group_num,
            // "executor": value.executor,
            "step_type": "human",
            'instruction': value.col6
          }
        );
      }
      step_num = step_num + 1;
      previousStepType = value.step_type;
    });


    // let mep_presets = [];

    let preRecipeName = recipeNameEng.trim();

    let globalRecipe = {
      portion: portion,
      // name: recipeNameEng,
      name: preRecipeName,
      name_french: recipeNameFr,
      description_en: recipeDescriptionEng,
      description_fr: recipeDescriptionFr,
      category: category,
      subcategory: subCategory,
      cuisine: cuisine,
      spice_level: spiceLevel,
      vegetarian: vegetarian === 'Yes' ? true : false,
      vegan: vegan === 'Yes' ? true : false,
      sugar_free: sugar_free === 'Yes' ? true : false,
      apt_for_lunchbox: lunchbox === 'Yes' ? true : false,
      gluten_free: glutenFree === 'Yes' ? true : false,
      percent_automation: percentAutomation,
      shelf_life: shelfLife,
      total_cooking_time: totalCookingTime,
      cost: totalCost,
      retail_cost: totalRetailCost,
      sides_combos: sides_table,
      baby_friendly: babyFriendly === 'Yes' ? true : false,
      senior_friendly: seniorFriendly === 'Yes' ? true : false,
      peanut_free: peanutFree === 'Yes' ? true : false,
      robot_tested: robotTested,
      human_tested: humanTested,
      support_offline: supportOffline,
      cook_at_hub: cookAtHub,
      review_stars: reviewStars,
      ing_steps: ing_steps,
      cooking_steps: cooking_steps,
      oven_steps: oven_steps,
      human_steps: human_steps,
      child_recipe_steps: child_recipe_steps,
      mep_presets: mep_presets,
      created_by_chef_name: createdByChef
    };

    setStringed(globalRecipe);

    // console.log("REREREREcipe", globalRecipe);

    // await YPCPutAxios2(setXPost, 'saveRecipeURL', globalRecipe, '');
    await YPCPutAxios2Alt(setXPost, 'putProxysaveRecipeURL', globalRecipe, '');

    getRecipes();

  }


  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getSelectedRowStepType = () => {
    let step_type = 'Ingredient';
    stepsList.map((value) => {
      if (selectStep === value.uuid) {
        step_type = value.step_type;
      }
    });
    return step_type;

  };

  const getValueFormater = (params) => {
    if (params.value === null) {
      return (null);
    } else if (params.value < 0) {
      return (0);
    } else {
      return (Math.round(params.value));
    }
  };

  const column1Header = {
    'Recipe': 'Recipe Name',
    'Ingredient': 'Ingredient Name',
    'Garnish': 'Ingredient Name',
    'Cooking': 'Duration (s)',
    'Oven': 'Duration (s)',
    'Human': ' '
  };
  const column1Type = {
    'Recipe': 'string',
    'Ingredient': 'string',
    'Garnish': 'string',
    'Cooking': 'number',
    'Oven': 'number',
    'Human': 'string'
  };
  const column1ValueOptions = {
    'Recipe': 'default',
    'Ingredient': 'default',
    'Garnish': 'default',
    'Cooking': 'default',
    'Oven': 'default',
    'Human': 'default'
  };
  const column1Editable = {
    'Recipe': false,
    'Ingredient': false,
    'Garnish': false,
    'Cooking': true,
    'Oven': true,
    'Human': false
  };

  const column2Header = {
    'Recipe': 'Amount (g)',
    'Ingredient': 'Amount (g)',
    'Garnish': 'Amount (g)',
    'Cooking': 'Temp (°C)',
    'Oven': 'Temp (°C)',
    'Human': ' '
  };
  const column2Type = {
    'Recipe': 'number',
    'Ingredient': 'number',
    'Garnish': 'number',
    'Cooking': 'number',
    'Oven': 'number',
    'Human': 'string'
  };
  const column2ValueOptions = {
    'Recipe': 'default',
    'Ingredient': 'default',
    'Garnish': 'default',
    'Cooking': 'default',
    'Oven': 'default',
    'Human': 'default'
  };
  const column2Editable = {
    'Recipe': true,
    'Ingredient': true,
    'Garnish': true,
    'Cooking': true,
    'Oven': true,
    'Human': false
  };

  const column3Header = {
    'Recipe': 'Send To',
    'Ingredient': 'Send To',
    'Garnish': ' ',
    'Cooking': 'Speed',
    'Oven': 'Humidity (%)',
    'Human': ' '
  };
  const column3Type = {
    'Recipe': 'singleSelect',
    'Ingredient': 'singleSelect',
    'Garnish': 'string',
    'Cooking': 'singleSelect',
    'Oven': 'number',
    'Human': 'string'
  };
  const column3ValueOptions = {
    'Recipe': ['Cooker', 'Oven', 'Human'],
    'Ingredient': ['Cooker', 'Oven'],
    'Garnish': 'default',
    'Cooking': [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    'Oven': 'default',
    'Human': 'default'
  };
  const column3Editable = {
    'Recipe': true,
    'Ingredient': true,
    'Garnish': false,
    'Cooking': true,
    'Oven': true,
    'Human': false
  };

  const column4Header = {
    'Recipe': ' ',
    'Ingredient': ' ',
    'Garnish': ' ',
    'Cooking': 'Speed Inc Dur (s)',
    'Oven': 'Mode',
    'Human': ' '
  };
  const column4Type = {
    'Recipe': 'string',
    'Ingredient': 'string',
    'Garnish': 'string',
    'Cooking': 'number',
    'Oven': 'singleSelect',
    'Human': 'string'
  };
  const column4ValueOptions = {
    'Recipe': 'default',
    'Ingredient': 'default',
    'Garnish': 'default',
    'Cooking': 'default',
    'Oven': ['combi', 'steam', 'convection'],
    'Human': 'default'
  };
  const column4Editable = {
    'Recipe': false,
    'Ingredient': false,
    'Garnish': false,
    'Cooking': true,
    'Oven': true,
    'Human': false
  };

  const column5Header = {
    'Recipe': ' ',
    'Ingredient': ' ',
    'Garnish': ' ',
    'Cooking': 'Lid Status',
    'Oven': ' ',
    'Human': ' '
  };
  const column5Type = {
    'Recipe': 'string',
    'Ingredient': 'string',
    'Garnish': 'string',
    'Cooking': 'singleSelect',
    'Oven': 'string',
    'Human': 'string'
  };
  const column5ValueOptions = {
    'Recipe': 'default',
    'Ingredient': 'default',
    'Garnish': 'default',
    'Cooking': ['open', 'close', 'no matter'],
    'Oven': 'default',
    'Human': 'default'
  };
  const column5Editable = {
    'Recipe': false,
    'Ingredient': false,
    'Garnish': false,
    'Cooking': true,
    'Oven': false,
    'Human': false
  };

  const column6Header = {
    'Recipe': 'Notes',
    'Ingredient': 'Notes',
    'Garnish': 'Notes',
    'Cooking': 'Notes',
    'Oven': 'Notes',
    'Human': 'Instruction'
  };
  const column6Type = {
    'Recipe': 'string',
    'Ingredient': 'string',
    'Garnish': 'string',
    'Cooking': 'string',
    'Oven': 'string',
    'Human': 'string'
  };
  const column6ValueOptions = {
    'Recipe': 'default',
    'Ingredient': 'default',
    'Garnish': 'default',
    'Cooking': 'default',
    'Oven': 'default',
    'Human': 'default'
  };
  const column6Editable = {
    'Recipe': true,
    'Ingredient': true,
    'Garnish': true,
    'Cooking': true,
    'Oven': true,
    'Human': true
  };

  const stepTypes = ['Ingredient', 'Garnish', 'Recipe', 'Cooking', 'Oven', 'Human'];
  const executorNum2Name = {null: '-', 0: 'Unknown(auto)', 4: 'Robot', 6: 'Human'};
  const executorName2Num = {'-': null, 'Unknown(auto)': 0, 'Robot': 4, 'Human': 6};
  const executorTypes = ['-', 'Unknown(auto)', 'Robot', 'Human'];

  const storeTempTypes = ['-', 'room', 'fridge', 'frozen'];
  const containerTypeTypes = ['-', 'oxo', 'GN6_Browne', 'GN1/6_4in_CAMBRO'];


  function updateIngredientCell(id, value) {

    if (value !== null) {
      let tempList = []
      stepsList.map((row) => {

        if (row.uuid == id) {
          let temp = row;
          temp['col1'] = value;
          tempList.push(temp)
        } else {
          tempList.push(row);
        }

      })
      setStepsList(tempList);
    }
  }

  async function updateRecipeCell(id, value) {

    if (value !== null) {

      let result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value);
      // console.log('GOGOGOGOGOGOGOGOGOGOGO', result);
      let cost_per_gram = 0.0;
      if (result.hasOwnProperty('nominal_portion_weight')) {
        cost_per_gram = result.cost / result.nominal_portion_weight;
      }

      let tempList = []
      stepsList.map((row) => {

        if (row.uuid == id) {

          let temp = row;
          temp['col1'] = value;

          temp['cost_per_gram'] = cost_per_gram;
          // console.log('G1G1G1G1G1G1G1G1G1', temp);

          tempList.push(temp);

        } else {
          tempList.push(row);
        }

      });

      setStepsList(tempList);

    }
  }


  const recipeStepsColumns = [
    {
      field: 'step_type',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Step Type',
      type: 'singleSelect',
      width: 140,
      sortable: false,
      valueOptions: stepTypes,
      editable: !robotTested || !humanTested,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          cooking: (params.row.step_type === 'Cooking'),
          oven: (params.row.step_type === 'Oven'),
          garnish: (params.row.step_type === 'Garnish'),
        }),
      valueFormatter: (params) => {
        if (params.row.col6 === "" || params.row.col6 === null) {
          return params.value;
        } else {
          return params.value + " *";
        }
      }
    },
    {
      field: 'col2',
      headerAlign: 'left',
      headerName: column2Header[getSelectedRowStepType()],
      align: 'left',
      width: 140,
      sortable: false,
      type: column2Type[getSelectedRowStepType()],
      valueOptions: column2ValueOptions[getSelectedRowStepType()],
      editable: column2Editable[getSelectedRowStepType()] && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (column2Editable[params.row.step_type] && params.row.uuid === selectStep) ? true : false,
          grey: (column2Editable[params.row.step_type] && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.step_type === 'Cooking' || params.row.step_type === 'Oven') {
          return params.value.toString() + ' (°C)';
        } else if (params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' || params.row.step_type === 'Recipe') {
          return params.value.toString() + ' (g)';
        }
      }
    },
    {
      field: 'col1',
      headerAlign: 'left',
      headerName: column1Header[getSelectedRowStepType()],
      // renderHeader: (params) => {
      //   let steptype = getSelectedRowStepType();
      //   return (
      //   <div>
      //     {column1Header[steptype]}
      //   </div>
      // )},
      align: 'left',
      width: 300,
      sortable: false,
      type: column1Type[getSelectedRowStepType()],
      // valueOptions: column1ValueOptions[getSelectedRowStepType()],
      editable: column1Editable[getSelectedRowStepType()] && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (column1Editable[params.row.step_type] && params.row.uuid === selectStep) ? true : false,
          grey: (column1Editable[params.row.step_type] && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.step_type === 'Cooking' || params.row.step_type === 'Oven') {
          return params.value.toString() + ' (s)';
        }
      },


      renderCell: (params) => {

        if ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' || params.row.step_type === 'Recipe') && (!robotTested || !humanTested) && params.row.uuid === selectStep) {

          let bkColor = 'rgba(157, 255, 118, 0.49)';
          if (params.row.col1 === '') {
            bkColor = 'rgba(255, 0, 0, 0.70)';
          }

          return (
            <Autocomplete
              onChange={(e, value) => {

                {
                  params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' ? updateIngredientCell(params.row.uuid, value) : updateRecipeCell(params.row.uuid, value)
                }


              }
              }
              popupIcon={""}
              id="combo-box-username"
              value={params.value}
              // options={ingredientList}
              options={params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' ? ingredientList : recipeList}

              style={{width: 300, backgroundColor: bkColor, lineHeight: '25px'}}

              variant='inherit'
              renderInput={(params) => <TextField {...params}

                                                  InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    style: {fontSize: 'inherit'}
                                                  }}
              />}
            />
          )
        } else if ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' || params.row.step_type === 'Recipe') && params.row.uuid === selectStep) {
          return (
            <Typography variant='inherit'
                        style={{width: 300, backgroundColor: 'rgba(157, 255, 118, 0.49)'}}>{params.value}</Typography>
          )
        } else if ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' || params.row.step_type === 'Recipe') && params.row.col1 === '') {
          return (
            <Typography variant='inherit'
                        style={{width: 300, backgroundColor: 'rgba(255, 0, 0, 0.70)'}}>&#160;</Typography>
          )
        } else if ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' || params.row.step_type === 'Recipe')) {
          return (
            <Typography variant='inherit'
                        style={{width: 300, backgroundColor: 'rgba(200, 200, 200, 0.49)'}}>{params.value}</Typography>
          )
        }
      }  // renderCell


    },
    {
      field: 'col3',
      headerAlign: 'left',
      headerName: column3Header[getSelectedRowStepType()],
      align: 'left',
      width: 140,
      sortable: false,
      type: column3Type[getSelectedRowStepType()],
      valueOptions: column3ValueOptions[getSelectedRowStepType()],
      editable: column3Editable[getSelectedRowStepType()] && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (column3Editable[params.row.step_type] && params.row.uuid === selectStep) ? true : false,
          grey: (column3Editable[params.row.step_type] && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.step_type === 'Oven') {
          return params.value.toString() + ' (%H)';
        } else if (params.row.step_type === 'Cooking') {
          return params.value.toString() + ' (Speed)'
        } else if (params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') {
          return params.value.toString() + ' (To)'
        }

      }
    },
    {
      field: 'col4',
      headerAlign: 'left',
      headerName: column4Header[getSelectedRowStepType()],
      align: 'left',
      width: 170,
      sortable: false,
      type: column4Type[getSelectedRowStepType()],
      valueOptions: column4ValueOptions[getSelectedRowStepType()],
      editable: column4Editable[getSelectedRowStepType()] && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (column4Editable[params.row.step_type] && params.row.uuid === selectStep) ? true : false,
          grey: (column4Editable[params.row.step_type] && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.step_type === 'Cooking') {
          return params.value.toString() + ' (s) Inc Dur';
        } else if (params.row.step_type === 'Oven') {
          return params.value.toString() + ' (Mode)'
        }
      }
    },
    {
      field: 'col5',
      headerAlign: 'left',
      headerName: column5Header[getSelectedRowStepType()],
      align: 'left',
      width: 140,
      sortable: false,
      type: column5Type[getSelectedRowStepType()],
      valueOptions: column5ValueOptions[getSelectedRowStepType()],
      editable: column5Editable[getSelectedRowStepType()] && (!robotTested || !humanTested),
      // valueFormatter: (params) => getValueFormater(params)
      // valueFormatter: (params) => column1ValueOptions[getSelectedRowStepType](params)
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (column5Editable[params.row.step_type] && params.row.uuid === selectStep) ? true : false,
          grey: (column5Editable[params.row.step_type] && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.step_type === 'Cooking') {
          return params.value.toString() + ' (Lid)';
        }
      }
    },
    {
      field: 'mep_group_num',
      headerAlign: 'left',
      headerName: 'Mep Group',
      // headerName: getSelectedRowStepType() === 'Ingredient' ? 'Mep Group': ' ',
      align: 'left',
      width: 140,
      sortable: false,
      type: 'number',
      // valueOptions: column6ValueOptions[getSelectedRowStepType()],
      editable: (getSelectedRowStepType() === 'Ingredient' || getSelectedRowStepType() === 'Garnish' || getSelectedRowStepType() === 'Recipe') && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' || params.row.step_type === 'Recipe') && params.row.uuid === selectStep) ? true : false,
          grey: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Garnish' || params.row.step_type === 'Recipe') && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.step_type !== 'Ingredient' && params.row.step_type !== 'Garnish' && params.row.step_type !== 'Recipe') {
          return ' ';
        }
          // if (params.row.executor === 0) {
          //   return ' ';
        // } 
        else {
          return params.value;
        }
      }
    },
    {
      field: 'executor',
      headerAlign: 'left',
      headerName: 'Executor',
      align: 'left',
      width: 140,
      sortable: false,
      type: 'singleSelect',
      valueOptions: executorTypes,
      editable: (getSelectedRowStepType() === 'Ingredient' || getSelectedRowStepType() === 'Recipe') && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') && params.row.uuid === selectStep) ? true : false,
          grey: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.executor === '-') {
          return ' ';
        } else {
          return params.value;
        }
      }
    },
    {
      field: 'container_type',
      headerAlign: 'left',
      headerName: 'Container',
      align: 'left',
      width: 140,
      sortable: false,
      type: 'singleSelect',
      valueOptions: containerTypeTypes,
      // valueOptions: column6ValueOptions[getSelectedRowStepType()],
      editable: (getSelectedRowStepType() === 'Ingredient' || getSelectedRowStepType() === 'Recipe') && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') && params.row.uuid === selectStep) ? true : false,
          grey: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.container_type === '-') {
          return ' ';
        } else {
          return params.value;
        }
      }
    },
    {
      field: 'store_temperature',
      headerAlign: 'left',
      headerName: 'Store Temp',
      align: 'left',
      width: 140,
      sortable: false,
      type: 'singleSelect',
      valueOptions: storeTempTypes,
      // valueOptions: column6ValueOptions[getSelectedRowStepType()],
      editable: (getSelectedRowStepType() === 'Ingredient' || getSelectedRowStepType() === 'Recipe') && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') && params.row.uuid === selectStep) ? true : false,
          grey: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.store_temperature === '-') {
          return ' ';
        } else {
          return params.value;
        }
      }
    },
    {
      field: 'speed_factor',
      headerAlign: 'left',
      // headerName: 'Step Group',
      headerName: 'Speed',
      align: 'left',
      width: 140,
      sortable: false,
      type: 'number',
      // valueOptions: column6ValueOptions[getSelectedRowStepType()],
      editable: (getSelectedRowStepType() === 'Ingredient' || getSelectedRowStepType() === 'Recipe') && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') && params.row.uuid === selectStep) ? true : false,
          grey: ((params.row.step_type === 'Ingredient' || params.row.step_type === 'Recipe') && params.row.uuid !== selectStep) ? true : false,
        }),
      valueFormatter: (params) => {
        if (params.row.speed_factor === 0 || (params.row.step_type !== 'Ingredient' && params.row.step_type !== 'Recipe')) {
          return ' ';
        } else {
          return params.value + " %";
        }
      }


    },
    {
      field: 'col6',
      headerAlign: 'left',
      headerName: column6Header[getSelectedRowStepType()],
      align: 'left',
      width: 600,
      sortable: false,
      type: column6Type[getSelectedRowStepType()],
      valueOptions: column6ValueOptions[getSelectedRowStepType()],
      editable: column6Editable[getSelectedRowStepType()] && (!robotTested || !humanTested),
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (column6Editable[params.row.step_type] && params.row.uuid === selectStep) ? true : false,
          grey: (column6Editable[params.row.step_type] && params.row.uuid !== selectStep) ? true : false,
        }),
    },
  ];


  const sidesColumns = [
    {
      field: 'dish',
      headerName: translate(props.language, 'Selection'),
      width: 300,
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          indent: params.row.side,
          noindent: !params.row.side,
        }),
      valueFormatter: (params) => {
        // add vegan here!!
        // note that spicelevel is actually not in params.row
        const valueFormatted = params.value + (Number.isInteger(params.row.spicelevel) ? ' (s' + params.row.spicelevel + ')' : '') + ((typeof params.row.vegetarian === 'string' || params.row.vegetarian instanceof String) ? ' (v)' : '');
        return `${valueFormatted}`;
      },
    },
    {
      field: 'cost',
      headerName: translate(props.language, 'Cost/Portion'),
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: ({value}) => currencyFormatter.format(Number(value)),
    },
    {
      field: 'calories',
      headerName: 'Calories/Portion',
      type: 'number',
      width: 180,
      sortable: false,
      valueFormatter: (params) => {
        return (params.value.toFixed(1))
      }
    },

  ];

  const senseColumns = [
    {
      field: 'costtype',
      headerName: translate(props.language, 'Cost Type'),
      width: 500,
      sortable: false,
    },
    {
      field: 'cost',
      headerName: translate(props.language, 'Cost'),
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: ({value}) => currencyFormatter.format(Number(value)),
    },
    {
      field: 'cost_retail',
      headerName: translate(props.language, 'Cost (Retail)'),
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: ({value}) => currencyFormatter.format(Number(value)),
    },
    {
      field: 'price',
      headerName: translate(props.language, 'Price'),
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: ({value}) => currencyFormatter.format(Number(value)),
    },
    {
      field: 'rounded_price',
      headerName: translate(props.language, 'Rounded'),
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: ({value}) => currencyFormatter.format(Number(value)),
    },

  ];


  const printColumns = [
    {
      field: 'steptype',
      headerName: 'Step Type',
      width: 300,
      sortable: false,
      editable: false
    },
    {
      field: 'col1',
      headerName: 'Name/Dur',
      width: 140,
      sortable: false,
      editable: false,

    },
    {
      field: 'col2',
      headerName: 'Amount/Temp',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'ing_cost',
      headerName: 'Ing Cost',
      width: 140,
      sortable: false,
      editable: false,
      valueFormatter: (params) => {
        if (params.row.steptype === 'Ingredient' || params.row.steptype === 'Garnish' || params.row.steptype === 'Recipe') {
          return currencyFormatter.format(Number(params.value))
        } else {
          return '';
        }
      }

      // ({ value }) => currencyFormatter.format(Number(value))

    },
    {
      field: 'col3',
      headerName: 'To/Speed/Hum',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'col4',
      headerName: 'IncDur/Mode',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'col5',
      headerName: 'Lid Stat',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'mep_group_num',
      // headerName: 'Step Group',
      headerName: 'Mep Group',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'executor',
      headerName: 'Executor',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'container_type',
      headerName: 'Container',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'store_temperature',
      headerName: 'Store Temp',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'speed_factor',
      headerName: 'Speed',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'col6',
      headerName: 'Note/Instruction',
      width: 140,
      sortable: false,
      editable: false,
    }
  ]


  function calculateStats() {

    // console.log('ABABABABABAB', stepsList);
    let cost = 0.0;
    let cost_retail = 0.0;
    let duration = 0;
    let grams = 0.0;
    let calories = 0.0
    let child_recipes_cost = 0.0;
    let child_recipes_cost_retail = 0.0;
    stepsList.map((value) => {
      if (value.step_type === 'Ingredient' || value.step_type === 'Garnish') {
        cost = cost + value.col2 * (ingredientUnitPrice[value.col1] / 100.00) / ingredientUnitSize[value.col1];
        cost_retail = cost_retail + value.col2 * (ingredientRetailPrice[value.col1] / 100.00) / ingredientUnitSize[value.col1];
        grams = grams + value.col2;
        calories = calories + value.col2 * ingredientUnitCalories[value.col1] / ingredientUnitSize[value.col1];
      }
      if (value.step_type === 'Cooking' || value.step_type == 'Oven') {
        duration = duration + value.col1;
      }
      if (value.step_type === 'Recipe') {
        child_recipes_cost = child_recipes_cost + value.col2 * value.cost_per_gram;
        child_recipes_cost_retail = child_recipes_cost_retail + value.col2 * value.cost_retail_per_gram;
      }

    });

    child_recipes_cost = child_recipes_cost / portion
    child_recipes_cost_retail = child_recipes_cost_retail / portion

    cost = cost / portion;
    cost_retail = cost_retail / portion;
    calories = calories / portion;
    setTotalCost(cost);
    setTotalRetailCost(cost_retail);
    setTotalCookingTime(duration);
    setTotalGrams(parseInt(grams));
    setTotalCalories(calories);

    // let totalPrice = cost + (category==='' ? 0.0 :  markups[category]);

    let totalPrice = 0.0;
    let totalChildRecipePrice = 0.0;

    if (category === 'Main') {
      if (vegetarian === 'Yes') {
        totalPrice = cost * markupMultiplier['MainVeg'];
        totalChildRecipePrice = child_recipes_cost * markupMultiplier['MainVeg'];
      } else {
        totalPrice = cost * markupMultiplier['Main'];
        totalChildRecipePrice = child_recipes_cost * markupMultiplier['Main'];
      }
    } else if (category !== '') {
      totalPrice = cost * markupMultiplier[category];
      totalChildRecipePrice = child_recipes_cost * markupMultiplier[category];
    }


    let sidesCostTotal = [0.0, 0.0, 0.0];
    let sidesCostRetailTotal = [0.0, 0.0, 0.0];
    let sidesPriceTotal = [0.0, 0.0, 0.0];
    [0, 1, 2].map((index) => {
      sidesOrderList.map((value) => {
        if (value.index === index + 1) {
          sidesCostTotal[index] = sidesCostTotal[index] + value.cost;
          sidesCostRetailTotal[index] = sidesCostRetailTotal[index] + value.cost_retail;
          sidesPriceTotal[index] = sidesPriceTotal[index] + value.cost * markupMultiplier['Side'];
        }
      });
    });


    let preSenseList = []
    if (category !== "") {
      preSenseList.push(
        {
          id: 1,
          costtype: translate(props.language, "Dish Only/Portion"),
          cost: cost,
          cost_retail: cost_retail,
          price: totalPrice,
          rounded_price: Math.ceil((totalPrice) * 10) / 10
        });

      if (child_recipes_cost !== 0.0) {
        preSenseList.push(
          {
            id: 2,
            costtype: "Child Recipes Only/Portion",
            cost: child_recipes_cost,
            cost_retail: child_recipes_cost_retail,
            price: totalChildRecipePrice,
            rounded_price: Math.ceil((totalChildRecipePrice) * 10) / 10
          });
      }

      if (child_recipes_cost !== 0.0) {
        preSenseList.push(
          {
            id: 3,
            costtype: "Dish & Child Recipes Only/Portion",
            cost: cost + child_recipes_cost,
            cost_retail: cost_retail + child_recipes_cost_retail,
            price: totalPrice + totalChildRecipePrice,
            rounded_price: Math.ceil((totalPrice + totalChildRecipePrice) * 10) / 10
          });
      }

      [0, 1, 2].map((index) => {
        if (sidesCostTotal[index] !== 0.0) {
          // preSenseList.push({id: 2+index, costtype: translate(props.language,"Dish & Side Combo " +
          // (index+1).toString()), cost: cost + sidesCostTotal[index], price: totalPrice + sidesCostTotal[index]});
          preSenseList.push(
            {
              id: 4 + index,
              costtype: translate(props.language, "Dish & Side Combo " + (index + 1).toString()),
              cost: cost + sidesCostTotal[index],
              cost_retail: cost_retail + sidesCostRetailTotal[index],
              price: totalPrice + sidesPriceTotal[index],
              rounded_price: Math.ceil((totalPrice + sidesPriceTotal[index]) * 10) / 10
            });

          if (child_recipes_cost !== 0.0) {
            preSenseList.push(
              {
                id: 10 + index,
                costtype: "Dish & Child Recipes & Side Combo " + (index + 1).toString(),
                cost: cost + child_recipes_cost + sidesCostTotal[index],
                cost_retail: cost_retail + child_recipes_cost_retail + sidesCostRetailTotal[index],
                price: totalPrice + totalChildRecipePrice + sidesPriceTotal[index],
                rounded_price: Math.ceil((totalPrice + totalChildRecipePrice + sidesPriceTotal[index]) * 10) / 10
              });
          }
        }
      });


    } else {
      preSenseList.push({id: 1, costtype: translate(props.language, "Dish"), cost: "", price: ""});
    }


    setSenseList(preSenseList);

  }


  function SenseTable() {
    return (
      <div style={{width: '100%'}}>
        <DataGrid

          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={senseList}
          columns={senseColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  }


  // Sides Functions Section


  function SidesTable(index) {
    let newSidesList = [];
    sidesOrderList.map((value) => {
      if (value.index === index) {
        newSidesList.push(value);
      }
    });

    return (
      <div style={{width: '100%'}}>
        <DataGrid

          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={newSidesList}
          // rows={sidesOrderList}
          columns={sidesColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleSidesSelect}
          selectionModel={selectSidesDelete}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  }


  const handleSidesSelect = (params) => {
    // console.log('UIUIUIUI', params.row);
    setSelectSidesDelete(params.row.uuid);
  };

  const handleSidesDelete = () => {
    let newSidesList = [];
    sidesOrderList.map((value) => {
      if (selectSidesDelete !== value.uuid) {
        newSidesList.push(value);
      }
    });
    setSidesOrderList(newSidesList);
  };

  async function handleSidesMoveRight(index) {
    if (selectedSidesValue !== null) {

      // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', selectedSidesValue);
      var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', selectedSidesValue);

      let id = uuidv4();
      let newValue = {
        uuid: id,
        id: id,
        dish: selectedSidesValue,
        cost: result.cost,
        index: index,
        calories: result.calorie
      };
      let newValues = []
      sidesOrderList.map((value) => {
        newValues.push(value);
      });
      newValues.push(newValue);

      setSidesOrderList(newValues);

    }
  }


  // end of Sides Section


  const handleShelfLife = (event) => {
    event.target.value = parseInt(event.target.value).toString();
    if (parseInt(event.target.value) < 1) {
      event.target.value = 0;
    }
    setShelfLife(parseInt(event.target.value));
  };

  const handlePortion = (event) => {
    event.target.value = parseInt(event.target.value); // .toString();
    if (parseInt(event.target.value) < 1) {
      event.target.value = 1;
    }
    setPortion(parseInt(event.target.value));
  };

  const handleMultiplier = (event) => {
    if (event.target.value < 0) {
      setMultiplier(1.0);
    } else {
      setMultiplier(event.target.value);
    }
  };

  const handleMultiplierButton = () => {
    if (isNaN(parseFloat(multiplier)) || parseFloat(multiplier) === 0 || parseFloat(multiplier) === 0.0) {
      setMultiplier(1.0)
    } else {

      let updatedRows = [];
      stepsList.map((row) => {
        let temp = row;
        if (temp["step_type"] === 'Ingredient' || temp["step_type"] === 'Garnish') {
          temp["amount"] = parseFloat((temp["amount"] * parseFloat(multiplier)).toFixed(2));
          temp["col2"] = parseFloat((temp["col2"] * parseFloat(multiplier)).toFixed(2));
          updatedRows.push(temp);
        } else {
          updatedRows.push(temp);
        }
      });

      setStepsList(updatedRows);
      setMultiplier(1.0);
    }
  };

  const handleRecipeNameEng = (event) => {
    let preRecipeName = event.target.value.replace('&', 'and');

    // setRecipeNameEng(event.target.value);
    setRecipeNameEng(preRecipeName);
  };

  const handleRecipeNameFr = (event) => {
    setRecipeNameFr(event.target.value);
  };


  const getSubCategory = () => {
    if (category !== '' && category !== null) {
      return subCategoryList[category];

    } else {
      return []
    }
  };

  const handleCellEditCommit = React.useCallback(
    ({id, field, value}) => {
      const updatedRows = stepsList.map((row) => {
        if (row.id === id) {
          if (field === 'step_type' && value !== lastStepType) {
            let temp = row;
            temp[field] = value;
            if (value === 'Ingredient') {
              temp['col1'] = "";
              temp['col2'] = 0;
              temp['col3'] = 'None';
              temp['col4'] = "";
              temp['col5'] = "";
              temp['col6'] = "";
              temp['step_group_num'] = 0;
              temp['mep_group_num'] = 0;
              temp['executor'] = '-';
              temp['container_type'] = 'oxo';
              temp['store_temperature'] = 'room';
              temp['speed_factor'] = 0;
            } else if (value === 'Garnish') {
              temp['col1'] = "";
              temp['col2'] = 0;
              temp['col3'] = "";
              temp['col4'] = "";
              temp['col5'] = "";
              temp['col6'] = "";
              temp['step_group_num'] = 0;
              temp['mep_group_num'] = 0;
              temp['executor'] = '-';
              temp['container_type'] = '-';
              temp['store_temperature'] = '-';
              temp['speed_factor'] = 0;
            } else if (value === 'Recipe') {
              temp['col1'] = "";
              temp['col2'] = 0;
              temp['col3'] = 'None';
              temp['col4'] = "";
              temp['col5'] = "";
              temp['col6'] = "";
              temp['step_group_num'] = 0;
              temp['mep_group_num'] = 0;
              temp['executor'] = '-';
              temp['container_type'] = 'oxo';
              temp['store_temperature'] = 'room';
              temp['speed_factor'] = 0;
            } else if (value === 'Cooking') {
              temp['col1'] = 0;
              temp['col2'] = 0;
              temp['col3'] = 0;
              temp['col4'] = 0;
              temp['col5'] = "no matter";
              temp['col6'] = "";
              temp['step_group_num'] = 0;
              temp['mep_group_num'] = 0;
              temp['executor'] = ' ';
              temp['container_type'] = ' ';
              temp['store_temperature'] = ' ';
              temp['speed_factor'] = 0;
            } else if (value === 'Oven') {
              temp['col1'] = 0;
              temp['col2'] = 0;
              temp['col3'] = 0;
              temp['col4'] = "";
              temp['col5'] = "";
              temp['col6'] = "";
              temp['step_group_num'] = 0;
              temp['mep_group_num'] = 0;
              temp['executor'] = ' ';
              temp['container_type'] = ' ';
              temp['store_temperature'] = ' ';
              temp['speed_factor'] = 0;
            } else if (value === 'Human') {
              temp['col1'] = "";
              temp['col2'] = "";
              temp['col3'] = "";
              temp['col4'] = "";
              temp['col5'] = "";
              temp['col6'] = "";
              temp['step_group_num'] = 0;
              temp['mep_group_num'] = 0;
              temp['executor'] = ' ';
              temp['container_type'] = ' ';
              temp['store_temperature'] = ' ';
              temp['speed_factor'] = 0;
            }
            setLastStepType(value);
            return temp;
          } else {
            let temp = row;
            temp[field] = value;
            return temp;
          }
        }
        return row;
      });
      setStepsList(updatedRows);
    },
    [stepsList],
  );


  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport/>
      </GridToolbarContainer>
    );
  }

  function stepsTable() {
    return (
      <div style={{width: '100%'}}>
        <DataGrid
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={stepsList}
          columns={recipeStepsColumns}
          onCellEditCommit={handleCellEditCommit}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          disableSelectionOnClick={robotTested && humanTested}
          onRowClick={handleStepsSelect}
          // headerHeight={0}
          // getEstimatedRowHeight={() => 200}
          // getRowHeight={() => 'auto'}
          // rowHeight={200}
          autoHeight={true}
          selectionModel={selectStep}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay className={classes.gridoverlay}>
                <div className={classes.label}>
                  Add Step
                </div>
              </GridOverlay>
            )
          }}
        />
      </div>
    );
  }

  const handleAddStep = () => {

    let id = uuidv4();
    // let newValue = {
    //     uuid: id,
    //     id: id,
    //     ingredient: '',
    //     amount: 0,
    //     sendto: 'None',
    //     duration: 0,
    //     temperature: 0,
    //     devicespeed: 0,
    //     speedincduration: 0,
    //     lidstatus: 'None',
    //     humidity: 0,
    //     mode: 'None'
    // };

    let newValue = {
      uuid: id,
      id: id,
      step_type: 'Ingredient',
      col1: '',
      col2: 0,
      col3: 'Cooker',
      col4: '',
      col5: '',
      col6: '',
      step_group_num: 0,
      mep_group_num: 0,
      executor: '-',
      container_type: '-',
      store_temperature: '-',
      speed_factor: 100
    };

    let newValues = [];
    stepsList.map((value) => {
      newValues.push(value);
    });
    newValues.push(newValue);
    setStepsList(newValues);
    setSelectStep(id);
    setLastStepType('Ingredient');

  };


  const handleStepsSelect = (params) => {
    setSelectStep(params.row.uuid);
  };

  const handleStepsInsert = () => {
    if (stepsList.length === 0) {
      handleAddStep();
    } else {
      let id = uuidv4();
      let newValue = {
        uuid: id,
        id: id,
        step_type: 'Ingredient',
        col1: '',
        col2: 0,
        col3: 'Cooker',
        col4: '',
        col5: '',
        col6: '',
        step_group_num: 0,
        mep_group_num: 0,
        executor: '-',
        container_type: '-',
        store_temperature: '-',
        speed_factor: 100
      };
      let newStepsList = [];
      stepsList.map((value) => {
        if (selectStep === value.uuid) {
          newStepsList.push(newValue);
        }
        newStepsList.push(value);
      });
      setStepsList(newStepsList);
      setSelectStep(id);
      setLastStepType('Ingredient');
    }

  };

  const handleStepsDelete = () => {
    let newStepsList = [];
    stepsList.map((value) => {
      if (selectStep !== value.uuid) {
        newStepsList.push(value);
      }
    });
    setStepsList(newStepsList)
  };


  async function handleDeleteRecipe() {

    if (selectedEditRecipe !== '' && selectedEditRecipe !== null) {

      // var result = await YPCDeleteAxios(xget, setXGet, 'deleteRecipeURL', selectedEditRecipe);
      var result = await YPCDeleteAxiosAlt(xget, setXGet, 'delProxydeleteRecipeURL', selectedEditRecipe);
      // console.log('PSPSPSPSP', result);
      setSelectedEditRecipe('');
      getRecipes();

    }
  }


  const convertBoolToEng = (value) => {
    if (value === null) {
      return 'No';
    } else if (value === false) {
      return 'No';
    } else if (value === true) {
      return 'Yes';
    }

  };


  const checkCategory = (value) => {
    if (categoryList.includes(value)) {
      return value;
    } else {
      return 'Starter';
    }

  };

  async function handleLoadEditRecipe() {

    if (selectedEditRecipe !== '' && selectedEditRecipe !== null) {


      // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', selectedEditRecipe);
      var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', selectedEditRecipe);


      // console.log('LALALALALALAL', result)

      setPortion(result.portion);
      setRecipeNameEng(selectedEditRecipe);
      setRecipeNameFr(result.name_french);
      setRecipeDescriptionEng(result.description_en);
      setRecipeDescriptionFr(result.description_fr);
      setRecipeId(result.id);
      setCategory(checkCategory(result.category));
      setCreatedByChef(result.created_by_chef_name);
      setSubCategory(result.subcategory !== null ? result.subcategory : "");
      setCuisine(result.cuisine !== null ? result.cuisine : "");
      setSpiceLevel(result.spice_level !== null ? result.spice_level : 0);
      setVegetarian(convertBoolToEng(result.vegetarian));
      setVegan(convertBoolToEng(result.vegan));
      setSugarfree(convertBoolToEng(result.sugar_free))
      setLunchbox(convertBoolToEng(result.apt_for_lunchbox));
      setGlutenFree(convertBoolToEng(result.gluten_free));
      setPercentAutomation(result.percent_automation !== null ? result.percent_automation : 0);
      setShelfLife(result.shelf_life);
      setSelectStep('');
      setSelectedSidesValue(null);
      setSelectSidesDelete("");
      setBabyFriendly(convertBoolToEng(result.baby_friendly));
      setSeniorFriendly(convertBoolToEng(result.senior_friendly));
      setPeanutFree(convertBoolToEng(result.peanut_free));
      setRobotTested(result.robot_tested);
      setHumanTested(result.human_tested);
      setSupportOffline(result.support_offline);
      setCookAtHub(result.cook_at_hub);
      setReviewStars(result.review_stars !== null ? result.review_stars : 0);
      setCreatedTime(result.created_time);
      setUpdatedTime(result.updated_time);

      // console.log('IQIQIQIQIQIQIQI', moment(new Date(result.updated_time)).format('llll'))

      const tempSides = await Promise.all(result.sides_combos.map(async (value) => {
        let id = uuidv4();
        // var sideValue = await YPCGetAxios(xget, setXGet, 'getRecipeURL', value.side_recipe_name);
        var sideValue = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value.side_recipe_name);
        return {
          uuid: id,
          id: id,
          dish: value.side_recipe_name,
          cost: sideValue.cost,
          cost_retail: sideValue.cost_retail,
          index: value.composition_num,
          calories: sideValue.calorie
        }

      }));

      setSidesOrderList(tempSides);

      let mepPresetsPresent = false;
      let presetDict = {'dummy': null};
      if (result.mep_presets.length !== 0) {
        mepPresetsPresent = true;
        result.mep_presets.map((value) => {
          presetDict[value.mep_group_num] = {
            'executor': value.executor,
            'container_type': value.container_type,
            'store_temperature': value.store_temperature,
            'speed_factor': value.speed_factor
          };
        });
      }


      // do steps
      var tempStepsList = [];
      tempStepsList.push(...result.ing_steps, ...result.cooking_steps, ...result.oven_steps, ...result.human_steps, ...result.child_recipe_steps);


      let finalStepsList = [];
      let mepGroupList = [];
      for (let i = 0; i < tempStepsList.length; i++) {

        await Promise.all(tempStepsList.map(async (value) => {

            // tempStepsList.map((value) => {
            if (i + 1 === value.step_num) {
              let id = uuidv4();
              let temp = value;
              temp['id'] = id;
              temp['uuid'] = id;
              if (value.step_type === 'ingredient') {
                temp['col1'] = value.ing_name;
                temp['col2'] = value.amount;
                // temp['col3'] = value.send_to;
                temp['col3'] = sendToList[value.send_to] === 'Human' ? "" : sendToList[value.send_to];
                temp['col4'] = "";
                temp['col5'] = "";
                temp['col6'] = value.notes;
                temp['step_type'] = sendToList[value.send_to] !== 'Human' ? 'Ingredient' : 'Garnish';
                temp['step_group_num'] = value.step_group_num;
                temp['mep_group_num'] = value.mep_group_num !== null ? value.mep_group_num : 0;

                if (sendToList[value.send_to] !== 'Human' && mepPresetsPresent && value.mep_group_num !== null && value.mep_group_num !== 0 && !mepGroupList.includes(value.mep_group_num)) {
                  temp['executor'] = mepPresetsPresent ? executorNum2Name[presetDict[value.mep_group_num]['executor']] : '-';
                  temp['container_type'] = mepPresetsPresent ? presetDict[value.mep_group_num]['container_type'] : '-';
                  temp['store_temperature'] = mepPresetsPresent ? presetDict[value.mep_group_num]['store_temperature'] : '-';
                  temp['speed_factor'] = mepPresetsPresent ? presetDict[value.mep_group_num]['speed_factor'] : 0;
                  mepGroupList.push(value.mep_group_num);
                } else {
                  temp['executor'] = '-';
                  temp['container_type'] = '-';
                  temp['store_temperature'] = '-';
                  temp['speed_factor'] = 0;
                }

              } else if (value.step_type === 'child_recipe') {

                let tresult = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value.child_recipe.name);
                console.log('UAUAUAUAUAUAU', tresult);
                let cost_per_gram = 0.0;
                let cost_retail_per_gram = 0.0;
                if (tresult.hasOwnProperty('nominal_portion_weight')) {
                  cost_per_gram = tresult.cost / tresult.nominal_portion_weight;
                  cost_retail_per_gram = tresult.cost_retail / tresult.nominal_portion_weight;
                }
                temp['cost_per_gram'] = cost_per_gram;
                temp['cost_retail_per_gram'] = cost_retail_per_gram;


                temp['col1'] = value.child_recipe.name;
                temp['col2'] = value.as_ingredient_settings.amount;
                // temp['col3'] = value.send_to;
                temp['col3'] = sendToList[value.as_ingredient_settings.send_to];
                temp['col4'] = "";
                temp['col5'] = "";
                temp['col6'] = value.notes;
                temp['step_type'] = 'Recipe';
                temp['step_group_num'] = value.step_group_num;
                temp['mep_group_num'] = value.as_ingredient_settings.mep_group_num !== null ? value.as_ingredient_settings.mep_group_num : 0;

                if (value.as_ingredient_settings.mep_group_num !== null && value.as_ingredient_settings.mep_group_num !== 0 && !mepGroupList.includes(value.as_ingredient_settings.mep_group_num)) {
                  temp['executor'] = mepPresetsPresent ? executorNum2Name[presetDict[value.as_ingredient_settings.mep_group_num]['executor']] : '-';
                  temp['container_type'] = mepPresetsPresent ? presetDict[value.as_ingredient_settings.mep_group_num]['container_type'] : '-';
                  temp['store_temperature'] = mepPresetsPresent ? presetDict[value.as_ingredient_settings.mep_group_num]['store_temperature'] : '-';
                  temp['speed_factor'] = mepPresetsPresent ? presetDict[value.as_ingredient_settings.mep_group_num]['speed_factor'] : 0;
                  mepGroupList.push(value.mep_group_num);
                } else {
                  temp['executor'] = '-';
                  temp['container_type'] = '-';
                  temp['store_temperature'] = '-';
                  temp['speed_factor'] = 0;
                }

              } else if (value.step_type === 'cooking') {
                temp['col1'] = value.cook_time;
                temp['col2'] = value.cook_temp;
                temp['col3'] = value.cook_speed;
                temp['col4'] = value.increase_duration;
                temp['col5'] = value.lid_status;
                temp['col6'] = value.notes;
                temp['step_type'] = 'Cooking';
                temp['step_group_num'] = value.step_group_num;
                temp['mep_group_num'] = value.mep_group_num;
                temp['executor'] = ' ';
                temp['container_type'] = ' ';
                temp['store_temperature'] = ' ';
                temp['speed_factor'] = ' ';
              } else if (value.step_type === 'oven') {
                temp['col1'] = value.cook_time;
                temp['col2'] = value.cook_temp;
                temp['col3'] = value.cook_humidity;
                temp['col4'] = value.mode;
                temp['col5'] = "";
                temp['col6'] = value.notes;
                temp['step_type'] = 'Oven';
                temp['step_group_num'] = value.step_group_num;
                temp['mep_group_num'] = value.mep_group_num;
                temp['executor'] = ' ';
                temp['container_type'] = ' ';
                temp['store_temperature'] = ' ';
                temp['speed_factor'] = ' ';
              } else if (value.step_type === 'human') {
                temp['col1'] = "";
                temp['col2'] = "";
                temp['col3'] = "";
                temp['col4'] = "";
                temp['col5'] = "";
                temp['col6'] = value.instruction;
                temp['step_type'] = 'Human';
                temp['step_group_num'] = value.step_group_num;
                temp['mep_group_num'] = value.mep_group_num;
                temp['executor'] = ' ';
                temp['container_type'] = ' ';
                temp['store_temperature'] = ' ';
                temp['speed_factor'] = ' ';
              }
              finalStepsList.push(temp)
            }
          })
        );  // this one
      }

      setStepsList(finalStepsList);

      setSelectedEditRecipe('');

    }

  }


  // gen dialog

  const handleGenDialog = () => {
    setXPost(null);
    setXGet(null);
  }

  function genDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
          {/* <Dialog> */}
          <DialogTitle id="alert-dialog-title">Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {xpost}{xget}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  }


  // delete dialog
  function deleteCheckSave() {
    return (
      <div>
        <Dialog open={deleteCheckOpen}>
          <DialogTitle>Are you sure you want to delete!?</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCheckCancel} color="primary">
              {translate(props.language, 'Cancel')}
            </Button>
            <Button onClick={handleDeleteCheckDelete} color="primary">
              {translate(props.language, 'Delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleDeleteCheckCancel = () => {
    setDeleteCheckOpen(false);
  }

  const handleDeleteCheckDelete = () => {
    setDeleteCheckOpen(false);
    handleDeleteRecipe();

  }

  const preDeleteRecipe = () => {
    if (recipeList.includes(selectedEditRecipe)) {
      setDeleteCheckOpen(true);
    }
  };

  // save dialog

  function doubleCheckSave() {
    return (
      <div>
        <Dialog open={doubleCheckOpen}>
          <DialogTitle>Recipe Name Already in Database!</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDoubleCheckCancel} color="primary">
              {translate(props.language, 'Cancel')}
            </Button>
            <Button onClick={handleDoubleCheckSave} color="primary">
              {translate(props.language, 'Save')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleDoubleCheckCancel = () => {
    setDoubleCheckOpen(false);
  }

  const handleDoubleCheckSave = () => {
    setDoubleCheckOpen(false);
    handleSaveRecipe();

  }

  const handleSupportOfflineChange = (event) => {
    setSupportOffline(event.target.checked);
  };


  const handleCookAtHubChange = (event) => {
    setCookAtHub(event.target.checked);
  };

  const handleHumanCheckChange = (event) => {
    setHumanTested(event.target.checked);
  };

  const handleRobotCheckChange = (event) => {
    setRobotTested(event.target.checked);
  };

  const handleCategoryFilterChoice = (event) => {
    if (event == null) {
      setCategoryFilterChoice('All');
    } else {
      setCategoryFilterChoice(event);
    }
    setMenuLoaded(false);
  };

  const handleSubcategoryFilterChoice = (event) => {
    if (event == null) {
      setSubcategoryFilterChoice('All');
    } else {
      setSubcategoryFilterChoice(event);
    }
    setMenuLoaded(false);
  };

  const handleRobotHumanChoice = (event) => {
    // console.log('KAKAKAKAKAK', event);
    // ['All', 'Tested', 'Robot Tested', 'Human Tested']
    if (event === 'All' || event == null) {
      setTagString('');
    } else if (event === "Tested") {
      setTagString("tags=robot_tested,human_tested");
    } else if (event === "Robot Tested") {
      setTagString("tags=robot_tested");
    } else if (event === "Human Tested") {
      setTagString("tags=human_tested");
    } else {
      setTagString("");
    }

    if (event == null) {
      setAllRobotHumanChoice('All');
    } else {
      setAllRobotHumanChoice(event);
    }
    setMenuLoaded(false);
    // setRobotTested(event.target.checked);
  };

  const calculatePrintTable = () => {

    // console.log('SLSLSLSLSLSLSLSLSL', stepsList);

    let tempList = [];
    let id = 0;
    stepsList.map((value) => {
      if (value.step_type === "Ingredient" || value.step_type === "Garnish") {
        tempList.push({
          id: id,
          steptype: value.step_type,
          col1: value.col1,
          col2: value.col2.toString() + ' (g)',
          col3: value.step_type === "Garnish" ? "" : '(Send To) ' + value.col3,
          col4: "",
          col5: "",
          col6: '(Note) ' + value.col6,
          step_group_num: value.step_group_num,
          mep_group_num: value.mep_group_num,
          executor: value.executor,
          container_type: value.container_type,
          store_temperature: value.store_temperature,
          speed_factor: value.step_type === "Garnish" ? "-" : value.speed_factor,
          ing_cost: value.col2 * (ingredientUnitPrice[value.col1] / 100.00) / ingredientUnitSize[value.col1]
        });
      } else if (value.step_type === 'Recipe') {
        tempList.push({
          id: id,
          steptype: value.step_type,
          col1: value.col1,
          col2: value.col2.toString() + ' (g)',
          col3: '(Send To) ' + value.col3,
          col4: "",
          col5: "",
          col6: '(Note) ' + value.col6,
          step_group_num: value.step_group_num,
          mep_group_num: value.mep_group_num,
          executor: value.executor,
          container_type: value.container_type,
          store_temperature: value.store_temperature,
          speed_factor: value.speed_factor,
          ing_cost: value.col2 * value.cost_per_gram
        });
      } else if (value.step_type === 'Cooking') {
        tempList.push({
          id: id,
          steptype: value.step_type,
          col1: value.col1.toString() + ' (s)',
          col2: value.col2.toString() + ' (°C)',
          col3: value.col3.toString() + ' (Speed)',
          col4: value.col4.toString() + ' (s) Inc Dur',
          col5: value.col5 + ' (Lid)',
          col6: '(Note) ' + value.col6,
          step_group_num: value.step_group_num,
          mep_group_num: value.mep_group_num,
          executor: value.executor,
          container_type: value.container_type,
          store_temperature: value.store_temperature,
          speed_factor: value.speed_factor,
          ing_cost: ""
        });

      } else if (value.step_type === 'Oven') {
        tempList.push({
          id: id,
          steptype: value.step_type,
          col1: value.col1.toString() + ' (s)',
          col2: value.col2.toString() + ' (°C)',
          col3: value.col3.toString() + ' (%H)',
          col4: value.col4 + ' (Mode)',
          col5: "",
          col6: '(Note) ' + value.col6,
          step_group_num: value.step_group_num,
          mep_group_num: value.mep_group_num,
          executor: value.executor,
          container_type: value.container_type,
          store_temperature: value.store_temperature,
          speed_factor: value.speed_factor,
          ing_cost: ""
        });

      } else if (value.step_type === 'Human') {
        tempList.push({
          id: id,
          steptype: value.step_type,
          col1: '',
          col2: '',
          col3: '',
          col4: '',
          col5: "",
          col6: '(Note) ' + value.col6,
          step_group_num: value.step_group_num,
          mep_group_num: value.mep_group_num,
          executor: value.executor,
          container_type: value.container_type,
          store_temperature: value.store_temperature,
          speed_factor: value.speed_factor,
          ing_cost: ""
        });

      }

      id = id + 1;

    });

    // console.log('SLSLSLSLSLSLSL', tempList);

    setPrintBuffer(tempList);


  };

  function printTable() {
    return (
      <div style={{width: '100%'}}>
        <DataGrid
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={printBuffer}
          columns={printColumns}
          // onCellEditCommit={handleCellEditCommit}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStepsSelect}
          // headerHeight={0}
          autoHeight={true}
          // selectionModel={selectStep}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay className={classes.gridoverlay}>
                <div className={classes.label}>
                </div>
              </GridOverlay>
            ),
            Toolbar: CustomToolbar
          }}
        />
      </div>
    );
  }

  return (

    <React.Fragment>

      <Container className={classes.container} style={{marginTop: "2em"}}>
        <ThemeProvider theme={theme}>

          <Grid container direction="row" alignItems="stretch" justify="left" spacing={2}>
            <Grid item xs={12}>


              <div className={classes.sendleft}>

                {doubleCheckOpen ? doubleCheckSave() : null}
                {deleteCheckOpen ? deleteCheckSave() : null}
                {xpost !== null || xget !== null ? genDialog() : null}
                <Typography component="h4" variant="h4">
                  {translate(props.language, 'Create/Edit Recipe')}
                </Typography>
              </div>


              <div style={{clear: "both"}}></div>
            </Grid>

            {/* Start of Edit Existing Recipe Section */}
            <Grid item xs={12}>
              <br/>
              <div style={{
                backgroundColor: '#78cbff',
                color: 'white',
                paddingLeft: '10px',
                height: '40px',
                paddingTop: '10px'
              }}>{translate(props.language, 'Edit Existing Recipe')} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
              {menuDB ?
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Autocomplete
                      onChange={(event, value) => setSelectedEditRecipe(value)}
                      value={selectedEditRecipe}
                      id="combo-box11"
                      // options={getEditRecipeList()}
                      options={recipeList}
                      // getOptionLabel={(option) => option.name}
                      // style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label={translate(props.language, 'Select')}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                            style: {color: '#78cbff'},
                                                          }}
                        // variant="outlined"

                      />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      onChange={(event, value) => handleCategoryFilterChoice(value)}
                      id="combo-box7"
                      options={categoryList}
                      value={categoryFilterChoice}
                      // getOptionLabel={(option) => option.name}
                      // style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}
                                                          label={translate(props.language, 'Category')}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                            style: {color: '#78cbff'},
                                                          }}
                        // variant="outlined"
                      />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      onChange={(event, value) => handleSubcategoryFilterChoice(value)}
                      id="combo-box7"
                      options={subCategoryList[categoryFilterChoice]}
                      value={subcategoryFilterChoice}
                      // getOptionLabel={(option) => option.name}
                      // style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}
                                                          label={translate(props.language, 'Subcategory')}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                            style: {color: '#78cbff'},
                                                          }}
                        // variant="outlined"
                      />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      onChange={(event, value) => handleRobotHumanChoice(value)}
                      id="combo-box7"
                      options={['All', 'Tested', 'Robot Tested', 'Human Tested']}
                      value={allRobotHumanChoice}
                      // getOptionLabel={(option) => option.name}
                      // style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}
                                                          label={translate(props.language, 'Recipes by')}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                            style: {color: '#78cbff'},
                                                          }}
                        // variant="outlined"
                      />}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleLoadEditRecipe}
                      disabled={false}
                      aria-label="select existing recipe"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      Load
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={preDeleteRecipe}
                      disabled={false}
                      aria-label="delete existing recipe"
                      style={{backgroundColor: 'red', color: 'white'}}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
                : null}
              <Grid container>
                <p>
                  <small>{recipeList.length} {translate(props.language, 'recipe')}{recipeList.length > 1 ? 's' : ''} </small>
                </p>
              </Grid>
            </Grid>
            {/* End Existing Recipe Section */}

            {/* Attributes Section  */}
            <Grid item xs={12}>
              <br/>
              <div style={{
                backgroundColor: '#78cbff',
                color: 'white',
                paddingLeft: '10px',
                height: '40px',
                paddingTop: '10px'
              }}>{translate(props.language, 'Attributes')} &#8198;</div>
            </Grid>


            <Grid item xs={12}>
              <Grid container direction="row" alignItems="stretch" spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth='true'
                    color='primary'
                    value={recipeNameEng}
                    id="outlined-name"
                    label={translate(props.language, 'Name') + ' (English)'}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                    }}
                    onChange={handleRecipeNameEng}
                    // variant="outlined"
                    disabled={robotTested && humanTested}
                  />
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth='true'
                    color='primary'
                    value={recipeNameFr}
                    id="outlined-name"
                    label={translate(props.language, 'Name') + ' (Française)'}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                    }}
                    onChange={handleRecipeNameFr}
                    // variant="outlined"
                    disabled={robotTested && humanTested}
                  />
                </Grid>
                <Grid item xs={2}>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12}>
              <Grid container direction="row" alignItems="stretch" spacing={2}>
                <Grid item xs={6}>
                  <Typography disabled={robotTested && humanTested}
                              style={{color: "#78cbff", fontSize: '12px'}}>{"Description (English)"}</Typography>
                  <TextareaAutosize
                    disabled={robotTested && humanTested}
                    maxRows={10}
                    minRows={10}
                    aria-label="maximum height notes en"
                    label="Description (English)"
                    value={recipeDescriptionEng}
                    helperText="Description (English)"
                    onChange={(e) => {
                      setRecipeDescriptionEng(
                        e.target.value)
                    }}
                    style={{width: 600, resize: 'none'}}
                  />

                </Grid>
                <Grid item xs={6}>
                  <Typography disabled={robotTested && humanTested}
                              style={{color: "#78cbff", fontSize: '12px'}}>{"Description (Française)"}</Typography>
                  <TextareaAutosize
                    disabled={robotTested && humanTested}
                    maxRows={10}
                    minRows={10}
                    aria-label="maximum height notes fr"
                    label="Description (Française)"
                    value={recipeDescriptionFr}
                    helperText="Description (Française)"
                    onChange={(e) => {
                      setRecipeDescriptionFr(
                        e.target.value)
                    }}
                    style={{width: 600, resize: 'none'}}
                  />
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12}>
              <Grid container direction="row" alignItems="stretch" spacing={2}>
                <Grid item xs={3}>

                  <Autocomplete
                    onChange={(event, value) => {
                      if (value === null) {
                        setCreatedByChef("")
                      } else {
                        setCreatedByChef(value)
                      }
                    }}
                    id="combo-box55"
                    options={chefList}
                    value={createdByChef}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={"Recipe Created By"} InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                    }}
                      // variant="outlined"
                      // disabled={robotTested && humanTested}
                    />}
                    disabled={robotTested && humanTested}
                  />


                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" alignItems="stretch" spacing={2}>
                <Grid item xs={3}>
                  <Autocomplete
                    onChange={(event, value) => setCategory(value)}
                    id="combo-box1"
                    options={categoryList}
                    value={category}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params}
                                                        label={translate(props.language, 'Select') + ' ' + translate(props.language, 'Category')}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                          style: {color: '#78cbff'},
                                                        }}
                      // variant="outlined"
                      // disabled={robotTested && humanTested}
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    onChange={(event, value) => setSubCategory(value)}
                    id="combo-box2"
                    value={subCategory}
                    options={getSubCategory()}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params}
                                                        label={translate(props.language, 'Select') + " " + translate(props.language, 'Subcategory')}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                          style: {color: '#78cbff'},
                                                        }}
                      // variant="outlined"
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Autocomplete
                    onChange={(event, value) => setSeniorFriendly(value)}
                    id="combo-box7"
                    options={['No', 'Yes']}
                    value={seniorFriendly}
                    renderInput={(params) => <TextField {...params} label={translate(props.language, 'Senior Friendly')}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                          style: {color: '#78cbff'},
                                                        }}
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>

              </Grid>
            </Grid>


            <Grid item xs={12}>
              <Grid container direction="row" alignItems="stretch" spacing={2}>


                {/* </Grid>
                 </Grid> */}


                <Grid item xs={1}>
                  <TextField
                    fullWidth='true'
                    value={portion}
                    id="outlined-number"
                    label="Portions"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                    }}
                    onChange={handlePortion}
                    // variant="outlined"
                    disabled={robotTested && humanTested}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Autocomplete
                    onChange={(event, value) => setVegetarian(value)}
                    id="combo-box4"
                    options={['Yes', 'No']}
                    value={vegetarian}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={translate(props.language, 'Vegetarian')}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                          style: {color: '#78cbff'},
                                                        }}
                      // variant="outlined"
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Autocomplete
                    onChange={(event, value) => setSugarfree(value)}
                    id="combo-box4x"
                    options={['Yes', 'No']}
                    value={sugar_free}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={'Sugar Free'} InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                    }}
                      // variant="outlined"
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>

                <Grid item xs={1}>
                  <Autocomplete
                    onChange={(event, value) => setVegan(value)}
                    id="combo-box4a"
                    options={['Yes', 'No']}
                    value={vegan}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={'Vegan'} InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                    }}
                      // variant="outlined"
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>


                <Grid item xs={1}>
                  <Autocomplete
                    // onChange={(event, value) => setSpiceLevel(parseInt(value))}
                    onChange={(event, value) => setSpiceLevel(spiceLookupToNumber[value])}
                    id="combo-box5"
                    // options={['0','1','2','3','4','5']}
                    options={['None', 'Low', 'Med', 'High']}
                    // value = {spiceLevel.toString()}
                    value={spiceLookupToString[spiceLevel]}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={translate(props.language, 'Spice Level')}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                          style: {color: '#78cbff'},
                                                        }}
                      // variant="outlined"
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>

                <Grid item xs={1}>
                  <Autocomplete
                    onChange={(event, value) => setLunchbox(value)}
                    id="combo-box6"
                    options={['No', 'Yes']}
                    value={lunchbox}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={translate(props.language, 'Lunchbox')}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                          style: {color: '#78cbff'},
                                                        }}
                      // variant="outlined"
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Autocomplete
                    onChange={(event, value) => setGlutenFree(value)}
                    id="combo-box7"
                    options={['No', 'Yes']}
                    value={glutenFree}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={translate(props.language, 'Gluten Free')}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                          style: {color: '#78cbff'},
                                                        }}
                      // variant="outlined"
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>

                <Grid item xs={1}>
                  <Autocomplete
                    onChange={(event, value) => setPeanutFree(value)}
                    id="combo-box7"
                    options={['No', 'Yes']}
                    value={peanutFree}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={translate(props.language, 'Peanut Free')}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                          style: {color: '#78cbff'},
                                                        }}
                      // variant="outlined"
                    />}
                    disabled={robotTested && humanTested}
                  />
                </Grid>

                <Grid item xs={1}>
                  <TextField
                    fullWidth='true'
                    value={shelfLife}
                    id="outlined-number"
                    label="Shelflife (mins)*"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                    }}
                    onChange={handleShelfLife}
                    // variant="outlined"
                    disabled={robotTested && humanTested}
                  />
                </Grid>

                {/* <Grid item xs={1}>
                 <Autocomplete
                 onChange={(event, value) => setReviewStars(parseInt(value))}
                 id="combo-box7"
                 value={reviewStars.toString()}
                 options={['1','2','3','4','5','6','7','8','9','10']}
                 // getOptionLabel={(option) => option.name}
                 // style={{ width: 300 }}
                 renderInput={(params) => <TextField {...params} label='Review Stars' InputLabelProps={{
                 shrink: true,
                 style: {color: '#78cbff'},
                 }}
                 // variant="outlined"
                 />}
                 disabled={robotTested && humanTested}
                 />
                 </Grid> */}

                <Grid item xs={1}>
                  <Typography align="right" style={{color: '#78cbff', fontSize: '13px', paddingTop: '0px'}}>Review
                    Stars</Typography>
                </Grid>

                <Grid item xs={2}>
                  {/* <Rating name="half-rating" value={reviewStars} precision={0.5} max={10} /> */}
                  <Rating
                    name="star-rating"
                    value={reviewStars / 2}
                    // max={10}
                    precision={0.5}
                    onChange={(event, value) => setReviewStars(value * 2)}
                    disabled={robotTested && humanTested}
                  />
                </Grid>

                {/* <Grid item xs={2}>
                 <FormControlLabel control={
                 <Checkbox
                 value="checkedA"
                 checked={supportOffline}
                 onChange={handleSupportOfflineChange}
                 // inputProps={{
                 //   'aria-label': 'Checkbox A',
                 // }}
                 disabled={robotTested && humanTested}
                 />}
                 label="Offline" />
                 </Grid> */}


              </Grid>
            </Grid>


            <Grid item xs={2}>
              <FormControlLabel control={
                <Checkbox
                  value="checkedA"
                  checked={humanTested}
                  onChange={handleHumanCheckChange}
                  // inputProps={{
                  //   'aria-label': 'Checkbox A',
                  // }}
                />}

                                label="Human Tested"/>

            </Grid>


            <Grid item xs={2}>


              <FormControlLabel control={
                <Checkbox
                  value="checkedA"
                  checked={robotTested}
                  onChange={handleRobotCheckChange}
                  // inputProps={{
                  //   'aria-label': 'Checkbox A',
                  // }}
                />}
                                label="Robot Tested"/>

            </Grid>


            <Grid item xs={2}>
              <TextField
                fullWidth='true'
                color='primary'
                value={(totalCookingTime / 60).toFixed(2)}
                id="outlined-number-1"
                label={'Cooking Time (mins)**'}
                type="text"
                InputLabelProps={{
                  shrink: true,
                  style: {color: '#78cbff'},
                }}
                // variant="outlined"
                disabled={robotTested && humanTested}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth='true'
                color='primary'
                value={(createdTime == '') ? '' : moment(new Date(createdTime)).format('llll')}
                id="outlined-number-x1"
                label={'Created Time'}
                type="text"
                InputLabelProps={{
                  shrink: true,
                  style: {color: '#78cbff'},
                }}
                disabled={robotTested && humanTested}
                // variant="outlined"
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth='true'
                color='primary'
                value={(updatedTime == '') ? '' : moment(new Date(updatedTime)).format('llll')}
                id="outlined-number-x2"
                label={'Updated Time'}
                type="text"
                InputLabelProps={{
                  shrink: true,
                  style: {color: '#78cbff'},
                }}
                disabled={robotTested && humanTested}
                // variant="outlined"
              />
            </Grid>


            <Grid item xs={2}>
              <FormControlLabel control={
                <Checkbox
                  value="checkedA"
                  checked={supportOffline}
                  onChange={handleSupportOfflineChange}
                  // inputProps={{
                  //   'aria-label': 'Checkbox A',
                  // }}
                  disabled={robotTested && humanTested}
                />}
                                label="Offline"/>
            </Grid>


            <Grid item xs={2}>
              <FormControlLabel control={
                <Checkbox
                  value="checkedA"
                  checked={cookAtHub}
                  onChange={handleCookAtHubChange}
                  // inputProps={{
                  //   'aria-label': 'Checkbox A',
                  // }}
                  disabled={robotTested && humanTested}
                />}
                                label="Cook At Hub"/>
            </Grid>

            <Grid item xs={1}>
              <TextField
                fullWidth='true'
                color='primary'
                value={(totalCalories.toFixed(1))}
                id="outlined-number-11"
                label={'Calories/Portion**'}
                type="text"
                InputLabelProps={{
                  shrink: true,
                  style: {color: '#78cbff'},
                }}
                // variant="outlined"
                disabled={robotTested && humanTested}
              />
            </Grid>


            <Grid item xs={1}>
              <TextField
                fullWidth='true'
                color='primary'
                value={(totalGrams)}
                id="outlined-number-1"
                label={'Grams/' + portion + (portion > 1 ? ' Portions**' : ' Portion**')}
                type="text"
                InputLabelProps={{
                  shrink: true,
                  style: {color: '#78cbff'},
                }}
                // variant="outlined"
                disabled={robotTested && humanTested}
              />
            </Grid>

            <Grid item xs={1}>
              <TextField
                fullWidth='true'
                value={multiplier}
                id="outlined-number"
                label="Multiplier"
                type="number"
                InputLabelProps={{
                  shrink: true,
                  style: {color: '#78cbff'},
                }}
                onChange={handleMultiplier}
                // variant="outlined"
                disabled={robotTested && humanTested}
              />
            </Grid>

            <Grid item xs={2}>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleMultiplierButton}
                // disabled={false}
                disabled={robotTested && humanTested}
                aria-label="handle multiplier button"
                style={{backgroundColor: '#78cbff', color: 'white'}}
              >
                Multiply
              </Button>
            </Grid>

            <Grid item xs={12}>
              <p><small>Notes -</small></p>
              <p><small>* Quality is perfect up until X minutes after cooked</small></p>
              <p><small>** Totals do not include Recipe steps value</small></p>
            </Grid>

            {/* Start of Steps Section */}
            <Grid item xs={12}>
              <br/>
              <div style={{
                backgroundColor: '#78cbff',
                color: 'white',
                paddingLeft: '10px',
                height: '40px',
                paddingTop: '10px'
              }}>{translate(props.language, 'Steps')} &#8198;</div>
            </Grid>

            <Grid item xs={2}>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAddStep}
                // disabled={false}
                disabled={robotTested && humanTested}
                aria-label="move selected right"
                style={{backgroundColor: '#78cbff', color: 'white'}}
              >
                Add Step
              </Button>
            </Grid>

            <Grid item xs={12}>

              {menuDB ?
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {stepsTable()}
                    <p></p>
                    {/* <Grid item xs={12}> */}
                    <p><small>* Indicates that Notes column is populated</small></p>
                    {/* </Grid> */}
                    <p></p>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      onClick={handleStepsInsert}
                      // disabled={false}
                      disabled={robotTested && humanTested}
                      aria-label="whatever 1"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      {translate(props.language, 'Insert Step')}
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      onClick={handleStepsDelete}
                      // disabled={false}
                      disabled={robotTested && humanTested}
                      aria-label="whatever 1"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      {translate(props.language, 'Delete Step')}
                    </Button>
                  </Grid>
                </Grid>
                : null}
            </Grid>


            {/* End of Steps Section */}

            {/* Sides Select Section */}
            <Grid item xs={12}>
              <br/>
              <div style={{
                backgroundColor: '#78cbff',
                color: 'white',
                paddingLeft: '10px',
                height: '40px',
                paddingTop: '10px'
              }}>{translate(props.language, 'Sides Select')} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
              {menuDB ?
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Autocomplete
                      onChange={(event, value) => setSelectedSidesValue(value)}
                      id="combo-box9"
                      // options={getSides()}
                      options={sideList}
                      // getOptionLabel={(option) => option.name}
                      // style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label={translate(props.language, 'Select')}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                            style: {color: '#78cbff'},
                                                          }}
                        // variant="outlined"
                      />}
                      disabled={robotTested && humanTested}
                    />
                  </Grid>
                </Grid>
                : null}
            </Grid>
            {/* End of Sides Select */}


            {/* Start of Sides 1 Section */}
            <Grid item xs={12}>
              <br/>
              <div style={{
                backgroundColor: '#78cbff',
                color: 'white',
                paddingLeft: '10px',
                height: '40px',
                paddingTop: '10px'
              }}>{translate(props.language, 'Sides Combo 1')} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
              {menuDB ?
                <Grid container spacing={3}>
                  {/* <Grid item xs={3}>
                   <Autocomplete
                   onChange={(event, value) => setSelectedSidesValue(value)}
                   id="combo-box9"
                   // options={getSides()}
                   options={sideList}
                   // getOptionLabel={(option) => option.name}
                   // style={{ width: 300 }}
                   renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                   shrink: true,
                   style: {color: '#78cbff'},
                   }}
                   // variant="outlined"
                   />}
                   />
                   </Grid> */}
                  <Grid item xs={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={() => handleSidesMoveRight(1)}
                      // disabled={false}
                      disabled={robotTested && humanTested}
                      aria-label="move selected right 1"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      Add Side &gt;
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    {SidesTable(1)}
                    <p></p>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      onClick={handleSidesDelete}
                      // disabled={false}
                      disabled={robotTested && humanTested}
                      aria-label="sides table 1"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      {translate(props.language, 'Delete Side')}
                    </Button>
                  </Grid>
                </Grid>
                : null}
            </Grid>
            {/* End of Sides 1 Section */}

            {/* Start of Sides 2 Section */}
            <Grid item xs={12}>
              <br/>
              <div style={{
                backgroundColor: '#78cbff',
                color: 'white',
                paddingLeft: '10px',
                height: '40px',
                paddingTop: '10px'
              }}>{translate(props.language, 'Sides Combo 2')} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
              {menuDB ?
                <Grid container spacing={3}>
                  {/* <Grid item xs={3}>
                   <Autocomplete
                   onChange={(event, value) => setSelectedSidesValue(value)}
                   id="combo-box10"
                   // options={getSides()}
                   options={sideList}
                   // getOptionLabel={(option) => option.name}
                   // style={{ width: 300 }}
                   renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                   shrink: true,
                   style: {color: '#78cbff'},
                   }}
                   // variant="outlined"
                   />}
                   />
                   </Grid> */}
                  <Grid item xs={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={() => handleSidesMoveRight(2)}
                      // disabled={false}
                      disabled={robotTested && humanTested}
                      aria-label="move selected right 2"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      Add Side &gt;
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    {SidesTable(2)}
                    <p></p>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      onClick={handleSidesDelete}
                      // disabled={false}
                      disabled={robotTested && humanTested}
                      aria-label="sides table 2"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      {translate(props.language, 'Delete Side')}
                    </Button>
                  </Grid>
                </Grid>
                : null}
            </Grid>
            {/* End of Sides 2 Section */}


            {/* Start of Sides 3 Section */}
            <Grid item xs={12}>
              <br/>
              <div style={{
                backgroundColor: '#78cbff',
                color: 'white',
                paddingLeft: '10px',
                height: '40px',
                paddingTop: '10px'
              }}>{translate(props.language, 'Sides Combo 3')} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
              {menuDB ?
                <Grid container spacing={3}>
                  {/* <Grid item xs={3}>
                   <Autocomplete
                   onChange={(event, value) => setSelectedSidesValue(value)}
                   id="combo-box11"
                   // options={getSides()}
                   options={sideList}
                   // getOptionLabel={(option) => option.name}
                   // style={{ width: 300 }}
                   renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                   shrink: true,
                   style: {color: '#78cbff'},
                   }}
                   // variant="outlined"
                   />}
                   />
                   </Grid> */}
                  <Grid item xs={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={() => handleSidesMoveRight(3)}
                      // disabled={false}
                      disabled={robotTested && humanTested}
                      aria-label="move selected right 3"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      Add Side &gt;
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    {SidesTable(3)}
                    <p></p>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      onClick={handleSidesDelete}
                      // disabled={false}
                      disabled={robotTested && humanTested}
                      aria-label="sides table 3"
                      style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                      {translate(props.language, 'Delete Side')}
                    </Button>
                  </Grid>
                </Grid>
                : null}
            </Grid>
            {/* End of Sides 3 Section */}

            {/* Start of Check Balances */}
            <Grid item xs={12}>
              <div className={classes.sendleft}>

                <Typography component="h5" variant="h5">
                  {translate(props.language, 'Sense Check Balanced Pricing')}
                </Typography>
              </div>
              <div style={{clear: "both"}}></div>
            </Grid>

            <Grid item xs={12}>
              {menuDB ? SenseTable() : null}
            </Grid>
            {/* End of Check Balances */}

            {/* Upload and Place Order Section */}
            <Grid item xs={12}>
              <Grid item xs={3}>
                <p></p>
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  className={classes.button}
                  onClick={preSaveRecipe}
                  disabled={false}
                  // disabled={robotTested && humanTested}
                  aria-label="whatever 1"
                  style={{backgroundColor: '#78cbff', color: 'white'}}
                >
                  {translate(props.language, 'Save to DB')}
                </Button>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>

            {stringed !== null ?
              <pre>{JSON.stringify(stringed, null, 2)}</pre>
              :
              null}

            <Grid item xs={12}>
              <br/>
              <div style={{
                backgroundColor: '#78cbff',
                color: 'white',
                paddingLeft: '10px',
                height: '40px',
                paddingTop: '10px'
              }}>{translate(props.language, 'Print Recipe Step Table')} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
              {printTable()}
            </Grid>

          </Grid>
        </ThemeProvider>
      </Container>

    </ React.Fragment>


  );


}



