import 'date-fns';
import React from 'react';
import './altstyles.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ShareIcon from '@material-ui/icons/Share';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import {Container} from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DoneIcon from '@mui/icons-material/Done';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import SyncIcon from '@mui/icons-material/Sync';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import MuiAlert from '@material-ui/lab/Alert';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import translate from "./YPCTranslate";
import moment from 'moment';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));
  
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const icons = {
      1: <SyncIcon />, // Choose date and fetch orders
      2: <SyncIcon />, // Generate containers
      3: <SyncIcon />,  // Shelf stocking
      4: <HistoryToggleOffIcon />,  // Run Scheduler
      5: <RunningWithErrorsIcon />,  // Robot Cooking
      6: <ShareIcon />,  //  Disconnect
      7: <ShareIcon />,  //  Finish
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? <DoneIcon /> : icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
    
  const steps = [
    {
      label: 'Step 1 - Place Orders',
      description: `Place Orders`,
      heading: 'Place Orders',
      subheading: 'Place Orders',
    },
    {
      label: 'Step 2 - Generate Containers',
      description: `Generate Containers`,
      heading: 'Generate Containers',
      subheading: 'Generate Containers',
    },        
    {
      label: 'Step 3 - Shelf Stocking',
      description: `Shelf Stocking`,
      heading: 'Shelf Stocking',
      subheading: 'Shelf Stocking',
    },
    {
      label: 'Step 4 - Disconnect',
      description: `Disconnect`,
      heading: 'Disconnect',
      subheading: 'Disconnect',
    },
  ];



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      backgroundColor: 'white',
      height: 'auto',
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      fontSize: 'large',
    },
    image: {
      float: 'left',
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));



const initSelectedEndTime = () => {
  let test = moment((new Date()));
  test.set('minute', 59);
  test.set('hour', 23);
  return test;
};

const initSelectedStartTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 0);
  return test2;
};

const startTimeList = ['11:30', '12:00', '12:30', '13:00', '13:30','17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];
const endTimeList = ['11:30', '12:00', '12:30', '13:00', '13:30','17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];


export default function YPCRobotPrep(props) {

  const classes = useStyles();


  const [selectedTimeStart, setSelectedTimeStart] = React.useState(initSelectedStartTime());
  const [selectedTimeEnd, setSelectedTimeEnd] = React.useState(initSelectedEndTime());

  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [activeStep, setActiveStep] = React.useState(0);
  const activeStepRef = React.useRef(activeStep);
  activeStepRef.current = activeStep;

  const [cookingState, setCookingState] = React.useState({'state': 'Inactive'});
  const cookingStateRef = React.useRef(cookingState);
  cookingStateRef.current = cookingState;


  const [error, setError] = React.useState(false);

  const [connected, setConnected] = React.useState(false);
  const [connectedMsg, setConnectedMsg] = React.useState("");

  const [errorMsg, setErrorMsg] = React.useState("");

  const connectedRef = React.useRef(connected);
  connectedRef.current = connected;

  const [textBox, setTextBox] = React.useState('');
  const textBoxRef = React.useRef(textBox);
  textBoxRef.current = textBox;

  const [checkOffline, setCheckOffline] = React.useState(false);
  const [checkLunch, setCheckLunch] = React.useState(false);
  const [checkDinner, setCheckDinner] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(true);
  const [fromForecast, setFromForecast] = React.useState(false);

  const [lastCommandSent, setLastCommandSent] = React.useState("")

  const [indicators, setIndicators] = React.useState({"cooking_machine_1": {"time" : "", "color":"black"}, "cooking_machine_2": {"time" : "", "color":"black"}, 
                                                      "cooking_machine_3": {"time" : "", "color":"black"}, "cooking_machine_4": {"time" : "", "color":"black"}, 
                                                      "cooking_machine_5": {"time" : "", "color":"black"}, "cooking_machine_6": {"time" : "", "color":"black"}, 
                                                      "cooking_machine_7": {"time" : "", "color":"black"}, "cooking_machine_8": {"time" : "", "color":"black"}, 
                                                      "cooking_machine_9": {"time" : "", "color":"black"}, 
                                                      'robot_axis' : 'black', 
                                                      'liquid_axis': 'black', 
                                                      'gripper' : 'black', 
                                                      'robot_arm': 'black'});

  const [runningStatus, setRunningStatus] = React.useState({"motion": false});

  const colorDict = {'black' : "#000000", 'green': "#00873E", 'orange': "#ffa500"};
  const [startNow, setStartNow] = React.useState(false);


  React.useEffect(() => {
    setInterval(() => {
      // Used for the others steps
      if (cookingStateRef.current.state !== 'Inactive') {
        handleMsg(cookingStateRef.current.state);
      }
      else {
        serverConnectStatus();
      };
    }, 2500);
  }, []);


  async function Comms (command) {
    if (!connectedRef.current) {
      setConnected(false);
      setConnectedMsg('Comms - tried to send msg when not connected')
      return {'command': command, 'state': -1};
    };

    let baseNumber = Math.floor(Math.random() * 100);
    let testBuffer = {'command': command, 'value': baseNumber};

    if (cookingStateRef.current.hasOwnProperty('data')) {
      testBuffer['data'] = cookingStateRef.current.data;
    };

    if (cookingStateRef.current.hasOwnProperty('start_now')) {
      testBuffer['start_now'] = cookingStateRef.current.start_now;
    };

    setLastCommandSent(command)

    let recBuffer =  await YPCPutAxios2Alt(setXPost, 'commsRobotControlURL', testBuffer, '');

    if (recBuffer.response === undefined) {
      setConnected(false);
      setConnectedMsg('Comms - undefined')
      return {'command': command, 'state': -1};
    } else if (recBuffer.response.hasOwnProperty('value')) {
      if (recBuffer.response.value === baseNumber + 3) {
        setConnected(true);
        setConnectedMsg("")
        if (recBuffer.response.hasOwnProperty('text')) {
          setTextBox(textBoxRef.current + recBuffer.response.text);
        };
        if (recBuffer.response.hasOwnProperty('hardware')) {
          setIndicators(recBuffer.response.hardware);
        };
        if (recBuffer.response.hasOwnProperty('running_status')) {
          setRunningStatus(recBuffer.response.running_status);
        };
        return recBuffer.response.status
      } else {
        setConnected(false);
        setConnectedMsg('Comms - bad response')
        return {'command': command, 'state': -1};
      };
    } else {
      setConnected(false);
      setConnectedMsg('Comms - unknown')
      return {'command': command, 'state': -1};
    };

  }

  async function handleMsg () {
    if (cookingStateRef.current.state === 'Shelf_stocking') {
      let status = await Comms('shelf_stocking');
      if (status.state === -1 || status.command !== 'shelf_stocking') {
        setError(true);
        setErrorMsg('Handle Msg, state:' + status.state + ', command:' + status.command);
      }
      else if (status.state === 1) {
          console.log('Stocking Shelves');
          setError(false);
          setErrorMsg("");
      } else if (status.state === 2) {
          console.log('Shelf stocking is done running');
          setCookingState({'state':'Inactive'});
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setError(false);
          setErrorMsg("");
      };               
    } else if (cookingStateRef.current.state === 'Place_orders') {
      let status = await Comms('place_orders');
      if (status.state === -1 || status.command !== 'place_orders') {
        setError(true);
        setErrorMsg('Handle Msg, state:' + status.state + ', command:' + status.command);
      }
      else if (status.state === 1) {
          console.log('Fetching & placing orders');
          setError(false);
          setErrorMsg("");
      } else if (status.state === 2) {
          console.log('Fetching & placing orders done');
          setCookingState({'state':'Inactive'});
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setError(false);
      }; 
    } else if (cookingStateRef.current.state === 'Generate_containers') {
      let status = await Comms('generate_containers');
      if (status.state === -1 || status.command !== 'generate_containers') {
        setCookingState({'state':'Inactive'});
        setError(true);
      }
      else if (status.state === 1) {
          console.log('Generating containers');
          setError(false);
      } else if (status.state === 2) {
          console.log('Generating containers done');
          setCookingState({'state':'Inactive'});
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setError(false);
      };           
    } else if (cookingStateRef.current.state === 'Disconnect') {
      let status = await Comms('disconnect');
      if (status.state === -1 || status.command !== 'disconnect') {
        setError(true);
        setErrorMsg('Handle Msg, state:' + status.state + ', command:' + status.command);
      }
      else if (status.state === 1) {
          console.log('Disconnecting');
          setError(false);
          setErrorMsg("");
      } else if (status.state === 2) {
          console.log('You are disconnected');
          setCookingState({'state':'Inactive'});
          setActiveStep(0);
          setTextBox("");
          setError(false);
          setErrorMsg("");
      };
    } else {}
  };

  async function handleNextStep () {
    // Place orders step    
    if (activeStep === 0) {
        let orderData = null;
        orderData = await orderFetch()
        setCookingState({'state':'Place_orders', 'data': orderData});
    } 
    // Generate containers step   
    else if (activeStep === 1) {
        setCookingState({'state':'Generate_containers'});
    }
    // Shelf Stocking step
    else if (activeStep === 2) {
        setCookingState({'state':'Shelf_stocking'});
    } 
    // Disconnecting step
    else if (activeStep === 3) {
          setCookingState({'state':'Disconnect'});
    } 
    else if (activeStep > 4) {
          setCookingState({'state':'Inactive'})          
    };
  };

  // Fetches the orders based on date and time selection
  async function orderFetch() {
    let meal_type = "";
    if (checkOffline) {
      meal_type = "&filter_by_meal_type=offline";
    } else if (checkLunch) {
      meal_type = "&filter_by_meal_type=lunch";
    } else if (checkDinner) {
      meal_type = "&filter_by_meal_type=dinner";
    } else if (checkAll) {
      meal_type = "";
    };

    let params = '?fetch_start_time=' + selectedDate.format("YYYY-MM-DDT") + 
                selectedTimeStart.format("HH:mm") + '&fetch_end_time=' + selectedDate.format("YYYY-MM-DDT") +  selectedTimeEnd.format("HH:mm") +
                meal_type + "&just_view_robot_orders=true" + "&only_mc_recipes=true" + (fromForecast ? "&forecast=True" : '')

    return {'do_fetch' : true, 'params' : params}
  }

  // Used for calling the default server status endpoint
  async function serverConnectStatus() {
    let baseNumber = Math.floor(Math.random() * 100);
    let testBuffer = {'command': 'server_status', 'value': baseNumber}
    setXPost(null);
    let recBuffer =  await YPCPutAxios2Alt(setXPost, 'commsRobotControlURL', testBuffer, '');
    if (recBuffer.response === undefined) {
      setConnected(false);
      setConnectedMsg('Server Connection - undefined')
    } else if (recBuffer.response.hasOwnProperty('value')) {
      if (recBuffer.response.value === baseNumber + 3) {
        setConnected(true);
        if (recBuffer.response.hasOwnProperty('text')) {
          setTextBox(textBoxRef.current + recBuffer.response.text);
        };
        if (recBuffer.response.hasOwnProperty('hardware')) {
          setIndicators(recBuffer.response.hardware);
        };
        if (recBuffer.response.hasOwnProperty('running_status')) {
          setRunningStatus(recBuffer.response.running_status);
        };
      } else {
        setConnected(false);
        setConnectedMsg('Server Connection - bad response')
      };
    } else {
      setConnected(false);
      setConnectedMsg('Server Connection - unknown')
    }
    setXPost(null);
  };

  const handleDateChange = (date) => {
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };

  const handleStartTimeList = (value) => {
    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeStart(newTime);

  };

  const handleEndTimeList = (value) => {

    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeEnd(newTime);

  };

  // Visual color indicator for cooking machines
  function cookingMachineIndicators() {
      let machineList = [];
      let backColor = 'black';
      for (let c = 1; c < 10; c++) {
        backColor = colorDict[indicators['cooking_machine_' + c.toString()]['color']];
        machineList.push(
          <Grid item xs={1}>
                  <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          disabled={false}
                          aria-label="next stepxx"
                          style={{backgroundColor: backColor, color: 'white'}}
                          >
                          {'Machine ' + c.toString()}
                  </Button>
          </Grid>

        );
      };
      return machineList;
  };

  // Visual time remaining for cooking machines
  function cookingMachineTimeIndicators() {
    let machineList = [];
    let showTime = '00:00'
    for (let c = 1; c < 10; c++) {
      showTime = indicators['cooking_machine_' + c.toString()]['time'];
      machineList.push(
            <Grid item xs={1}>
                <Typography component="h5" variant="h5" >
                    {showTime}
                </Typography>
            </Grid>
      );
    };
    return machineList;
};

  // Visual color indicators for others hardware components
  function robotIndicators() {
    let robotList = [];
    let backColor = 'black';
    ['robot_axis', 'liquid_axis', 'gripper', 'robot_arm'].map((value) => {
      backColor = colorDict[indicators[value]];
      robotList.push(
            <Grid item xs={1}>
                <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        disabled={false}
                        aria-label="next stepxx"
                        style={{backgroundColor: backColor, color: 'white'}}
                        >
                        {value}
                </Button>
            </Grid>
      );
    });
    return robotList;
};
  
    // Layout
    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h4" variant="h4">
                      Cooking Steps - {connected ? 'Connected' : 'Not Connected'}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>


          {connected ? null :
              <Grid item xs={12}>
                  <div className={classes.sendleft}>
                      <Typography component="h6" variant="h6">
                          {connectedMsg}
                      </Typography>
                  </div>
                  <div style={{clear: "both"}}></div>
              </Grid>
          }

          <Grid item xs={12}>
                        <div><p></p></div>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>

                            {steps.map((step) => (
                                <Step key={step.label} >
                                  <StepLabel StepIconComponent={ColorlibStepIcon} >{step.label}</StepLabel>
                                </Step>
                            ))}

                        </Stepper>
                    </Stack>
                    <div><p></p></div>
                    <Typography>{steps[activeStep].description}</Typography>
                    <div><p></p></div>
                    <div>
                             <Grid item xs={12}>
                                <Grid item xs={1}>
                                      <Button
                                          variant="outlined"
                                          size="small"
                                          className={classes.button}
                                          onClick={handleNextStep}
                                          disabled={false}
                                          aria-label="next step"
                                          style={{backgroundColor: '#78cbff', color: 'white'}}
                                      >
                                          {activeStep === steps.length - 1 ? 'Finish' : 'Run'}
                                      </Button> 
                                </Grid>
                              </Grid>
                            {error ? (                     
                                <Typography component="h5" variant="h5">
                                    {"Error! " + errorMsg} 
                                </Typography>
                              ) :
                              null
                            }

                    </div>


                    <p></p>
                    { lastCommandSent !== "" ?
                        <div>Last command sent - {lastCommandSent}</div>
                        :
                        null
                    }

          </Grid>

          {/* Set Calendar */}
          { activeStep === 0? (
            <React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.sendleft}>
                        <Typography component="h5" variant="h5">
                            {steps[activeStep].heading}
                        </Typography>
                    </div>
                    <div style={{clear: "both"}}></div>
                </Grid>       
                <Grid item xs={12}>
                          <br />
                          <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{steps[activeStep].subheading} &#8198;</div>
                          <br />
                </Grid>
                <Grid item xs={12}>   
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                            <Grid item xs={12} sm={12} md={12} lg={12}> 
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                  <DatePicker
                                      orientation={"landscape"}
                                      variant="static"
                                      margin="normal"
                                      id="date-picker-dialog"
                                      label="Date picker dialog"
                                      // format="MM/dd/yyyy"
                                      format={"dddd, " + "MMMM" + ", yyyy"}
                                      value={selectedDate}
                                      onChange={handleDateChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                  />
                              {/* </LocalizationProvider> */}
                            </Grid>
                          </MuiThemeProvider>
                      </MuiPickersUtilsProvider>
                </Grid>

                <Grid container item xs={12} direction='column'> 
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                                <Grid item xs={2}> 
                                      <Autocomplete
                                          onChange={(event, value) => handleStartTimeList(value)}
                                          id="combo-box-start"
                                          value={selectedTimeStart.format("HH:mm")}
                                          options={startTimeList}
                                          renderInput={(params) => <TextField {...params} label='Start' InputLabelProps={{
                                              shrink: true,
                                              style: {color: '#78cbff'},
                                          }}
                                          />} 
                                      />
                                      <p></p>
                                      <Autocomplete
                                            onChange={(event, value) => handleEndTimeList(value)}
                                            id="combo-box-end"
                                            value={selectedTimeEnd.format("HH:mm")}
                                            options={endTimeList}
                                            renderInput={(params) => <TextField {...params} label={translate(props.language, 'End')} InputLabelProps={{
                                                shrink: true,
                                                style: {color: '#78cbff'},
                                            }}
                                            />} 
                                        />
                                </Grid>
                                </MuiThemeProvider>
                            </MuiPickersUtilsProvider>
                      </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="row" alignItems="stretch" spacing={2}>
                          <MuiThemeProvider theme={theme}>
                              <Grid item xs={1}>
                                  <FormControlLabel control={
                                    <Checkbox
                                        value="checkedoffline"
                                        checked={checkOffline}
                                        onChange = {(event, value) => {
                                            setCheckOffline(value)
                                            setCheckLunch(false)
                                            setCheckDinner(false)
                                            setCheckAll(false)

                                          }
                                        
                                        
                                        }
                                      />}

                                    label="Offline" />
                                </Grid>
                              <Grid item xs={1}>
                                  <FormControlLabel control={
                                    <Checkbox
                                        value="checkedlunch"
                                        checked={checkLunch}
                                        onChange = {(event, value) => {
                                            setCheckOffline(false)
                                            setCheckLunch(value)
                                            setCheckDinner(false)
                                            setCheckAll(false)

                                          }
                                        
                                        }

                                      />}
                                    label="Lunch" />
                              </Grid>
                              <Grid item xs={1}>
                                  <FormControlLabel control={
                                    <Checkbox
                                        value="checkeddinner"
                                        checked={checkDinner}
                                        onChange = {(event, value) => {
                                          
                                            setCheckOffline(false)
                                            setCheckLunch(false)
                                            setCheckDinner(value)
                                            setCheckAll(false)

                                          }

                                        
                                        }

                                      />}
                                    label="Dinner" />
                              </Grid>
                              <Grid item xs={1}>
                                  <FormControlLabel control={
                                    <Checkbox
                                        value="checkedlunchdinner"
                                        checked={checkAll}
                                        onChange = {(event, value) => {                                        
                                            setCheckAll(value)
                                            setCheckLunch(false)
                                            setCheckDinner(false)
                                            setCheckOffline(false)  
                                          }
                                        }
                                      />}
                                    label="All day" />
                              </Grid>
                              <Grid item xs={8}>
                              </Grid>
                            </MuiThemeProvider>
                          </Grid>
                      </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="row" alignItems="stretch" spacing={2}>
                          <MuiThemeProvider theme={theme}>
                              <Grid item xs={1}>
                                  <FormControlLabel control={
                                    <Checkbox
                                        value="fromForecast"
                                        checked={fromForecast}
                                        onChange = {(event, value) => {
                                            setFromForecast(value)
                                          }
                                        }
                                      />}

                                    label="Use Forecast" />
                                </Grid>
                              <Grid item xs={11}>
                              </Grid>
                            </MuiThemeProvider>
                          </Grid>
                      </Grid>
            </React.Fragment>
        ) : null
      }

  { activeStep === 2 ? 
    ( <React.Fragment>
      { (activeStep === 2) ? (
            <React.Fragment>
            <Grid item xs={12}>
                <div className={classes.sendleft}>
                    <Typography component="h5" variant="h5">
                        {steps[activeStep].heading}
                    </Typography>
                </div>
                <div style={{clear: "both"}}></div>
            </Grid>          
            <Grid item xs={12}>
                          <br />
                          <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{steps[activeStep].subheading} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <MuiThemeProvider theme={theme}>
                      {cookingMachineIndicators()}
                  </MuiThemeProvider>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <MuiThemeProvider theme={theme}>
                      {cookingMachineTimeIndicators()}
                  </MuiThemeProvider>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <MuiThemeProvider theme={theme}>
                      {robotIndicators()}
                  </MuiThemeProvider>
                </Grid>
            </Grid>
            <Grid item xs={12}>
            <p></p>
              <MuiThemeProvider theme={theme}>
                <TextField
                      value={textBox}
                      id="outlined-multiline-static"
                      multiline
                      rows={20}
                      variant="outlined"
                      fullWidth={true}
                  />
              </MuiThemeProvider>
            </Grid>
            </React.Fragment>
          ) : null
      }
  </React.Fragment>
  ) : null
  }
          <div><p></p></div>
          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}
        </Grid>
      </Container>
      </ React.Fragment>



    );
}


