import { useState, useEffect } from 'react'
import { useROS } from 'react-ros'
var listener = null;

function GetMotionPyTopic(props) {
    const { createListener, topics, isConnected , removeAllListeners} = useROS();
    // const [ topic, setTopic ] = useState('/lid_actuator_command');
    const [ topic, setTopic ] = useState('/system_state');
    const [ queue, setQueue ] = useState(0);
    const [ compression, setCompression ] = useState('none');
    const [ topicset, SetTopicSet] = useState(false);

    // const [dateValue, setDateValue] = React.useState(new Date());
    // const [buttonState, SetButtonState] = React.useState('idle');


    useEffect(() => {
        if(isConnected && topics.length !==0 && !topicset) {
            // handleTopic('/lid_actuator_command');          
            handleTopic('/system_state');
            SetTopicSet(true);
        }

    });

    const unsubscribe = () => {
      if (listener) {
        console.log("Unsubscribing");
        listener.unsubscribe();
        removeAllListeners();

      }
    }

    const handleTopic = (topicInput) => {
      // if (topic !== topicInput) {
      //   setTopic(topicInput);
      //   unsubscribe();
      //   return;
      // }

      unsubscribe();
      listener = null;
      for (var i in topics) {
        if (topics[i].path === topicInput) {
          listener = createListener( topics[i].path,
                                     topics[i].msgType,
                                     Number(queue),
                                     compression);
          break;
        }
      }

      if (listener) {
        console.log('OOOOOOOOOOO Ok got here!')
        listener.subscribe(handleMsg);

      } else {
        console.log("Topic '" + topic + "' not found...make sure to input the full topic path - including the leading '/'");
      }
    }

    const handleMsg = (msg) => {
            // console.log('XGXGXGXGXGXGXG got here!!!!!!!!!!!!1', msg)
            var new_response = JSON.parse(msg.data);
            // console.log('GGGGGet Motion Topic', new_response)

            // props.parentCallback(new_response);
            props.setMotionPyResponse(new_response);

    }



    return (
      null
    );
  }
export default GetMotionPyTopic;