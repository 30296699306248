import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPostAxios2Alt from './YPCPostAxios2Alt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();


function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


export default function YPCInventory(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [menuLoaded, setMenuLoaded] = React.useState(false);
  const [menuDB, setMenuDB] = React.useState(null);

  const [daysOrderList, setDaysOrderList] = React.useState([]);
  const [altDaysOrderList, setAltDaysOrderList] = React.useState([]);

  const [inventoryList, setInventoryList] = React.useState([]);

  const [selectStarterDelete, setSelectStarterDelete] = React.useState("");

  const [dessertsOrderList, setDessertsOrderList] = React.useState([]);
  const [selectDessertsDelete, setSelectDessertsDelete] = React.useState("");

  const [fruitsOrderList, setFruitsOrderList] = React.useState([]);
  const [selectFruitsDelete, setSelectFruitsDelete] = React.useState("");

  const [drinksOrderList, setDrinksOrderList] = React.useState([]);
  const [selectDrinksDelete, setSelectDrinksDelete] = React.useState("");

  const [mainsOrderList, setMainsOrderList] = React.useState([]);
  const [selectMainsDelete, setSelectMainsDelete] = React.useState("");
  
  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [lunchBoxChecked, setLunchBoxChecked] = React.useState({'test': 'test2'});
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = React.useState({'test': 'test2'});

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [selectedFromDate, setSelectedFromDate] = React.useState(moment(new Date()));
  const [selectedToDate, setSelectedToDate] = React.useState(moment(new Date()));

  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);

  const [locale, setLocale] = React.useState()

  const [lunchBlocked, setLunchBlocked] = React.useState(false);
  const [dinnerBlocked, setDinnerBlocked] = React.useState(false);

  const [hideToBuy, setHideToBuy] = React.useState(false);
  const [fromForecast, setFromForecast] = React.useState(false);

  const [loading, setLoading] = React.useState(false);


  // React.useEffect(() => {
  //   getMenu();
  // }, [selectedFromDate, selectedToDate]);


  React.useEffect(() => {
    if (xget !== null) {
       if (xget[0] === 'E') {
            setLoading(false);
       }; 
    };

  }, [xget]);





  // const handleLoadDB = () => {
  //   YPCGetMenuDB(xget, setXGet).then(
  //     function(value) { setMenuDB(value);
  //       xtranslate.addLookup(value);
  //     }
  //   );
  //   setMenuLoaded(true);
  // };




  async function getMenu() {
    setLoading(true);
    // let subDate = selectedDate.toString().split(" ")
    // let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
    // var vorder_structure = await YPCGetAxios(xget, setXGet, 'getDaysOrdersURL', 'Feb17Thu2022');
    // console.log('VEVEEVEVEVEV', vorder_structure);

    // console.log('PAPAPAPAPAPAP', moment(selectedDate).format("YYYY-MM-DDTHH:mm"))

    // this is the new stuff

    setXGet(null);
    setInventoryList([]);

    var purchaseCheckList = [];
    var purchaseCheckList = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetCheckingPurchaseListURL',
        '?check_start_time=' + moment(selectedFromDate).format("YYYY-MM-DDT") + '00:00' + 
        "&check_end_time=" + moment(selectedToDate).format("YYYY-MM-DDT") + '23:59' + (fromForecast ? '&forecast=True' : ""));
    

    if (purchaseCheckList.length !== 0) {

        let temp = null;
        let tempList = []
        purchaseCheckList.map((value) => {
            temp = value;
            temp['actual_purchase_amount'] = null;
            if (value['stock'] === NaN) {
                temp['stock'] = 0;
            }
            tempList.push(temp);
        });

        // console.log('VEVEVEVEVE', tempList);
        setInventoryList(tempList);

        // setInventoryList(
        //     [
        //         {name: 'whatever 1', required_amount: 0.75, stock: 1.7, amount_to_purchase: null, actual_purchase_amount:null, id:0 },
        //         {name: 'whatever 2', required_amount: 0.85, stock: 5.0, amount_to_purchase: 4.3, actual_purchase_amount:null, id:1 },
        //         {name: 'whatever 3', required_amount: 0.95, stock: 6.8, amount_to_purchase: null, actual_purchase_amount:null, id:2 },
        //         {name: 'whatever 4', required_amount: 1.75, stock: 7.3, amount_to_purchase: 5.1, actual_purchase_amount:null, id:3 },
        //         {name: 'whatever 5', required_amount: 2.0, stock: 3.3, amount_to_purchase: 1.2, actual_purchase_amount:null, id:4 },
        //         {name: 'whatever 6', required_amount: 2.22, stock: 2.7, amount_to_purchase: 3.3, actual_purchase_amount:null, id:5 },
        //         {name: 'whatever 7', required_amount: 4.57, stock: 9.0, amount_to_purchase: 7.7, actual_purchase_amount:null, id:6 },
        //     ]
        //     );

    } else {
      setInventoryList([])
    };

    setLoading(false);

  };

  const handleFromDateChange = (date) => {
    // console.log('GAGAGA', date);
    if(!loading) {
        if (date > selectedToDate) {
            setSelectedFromDate(moment(date));
            setSelectedToDate(moment(date));
        }
        else if (date !== selectedFromDate) {
        setSelectedFromDate(moment(date));
        }
    }
  };

  const handleToDateChange = (date) => {
    // console.log('GAGAGA', date);
    if(!loading) {
        if (date < selectedFromDate) {
            setSelectedFromDate(moment(date));
            setSelectedToDate(moment(date));
        }
        else if (date !== selectedToDate) {
        setSelectedToDate(moment(date));
        }
    }
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      field: 'name',
      headerName: 'Ingredient',
      type: 'string',
      sortable: true,
      width: 500,
      editable: false,
    },   
    {
        field: 'category',
        headerName: 'Category',
        type: 'string',
        sortable: true,
        width: 180,
        editable: false,
      },   
    {
      field: 'required_amount',
      headerName: 'Required (g)',
      type: 'number',
      width: 180,
      sortable: true,
      editable: false,
    },  

    {
      field: 'stock',
      headerName: 'Stock Level (g)',
      type: 'number',
      width: 180,
      sortable: true,
      editable: true,
    },
    {
      field: 'amount_to_purchase',
      headerName: 'To Buy (g)',
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
    },
    {
      field: 'actual_purchase_amount',
      headerName: 'Actual Buy (g)',
      type: 'number',
      width: 180,
      sortable: true,
      editable: true,
    },
  ];


  const handleCellEditCommit = React.useCallback(

    ({ id, field, value }) => {
        // console.log('papapaapapap', id, field, value);
        const updatedRows = inventoryList.map((row) => {
          if (row.id === id) {
            if (field === 'actual_purchase_amount') {
              let temp = row;
              temp['actual_purchase_amount'] = value
              return temp;
            } else if (field === 'stock') {
                let temp = row;
                temp['stock'] = value
                return temp;
            } else {
              let temp = row;
              temp[field] = value;
              return temp;
            }
          }
          return row;
        });
        setInventoryList(updatedRows);
    },
    [inventoryList],
  );


function getTableContents() {

    if (inventoryList.length !== 0) {
            if (hideToBuy) {
                let tempList = []
                inventoryList.map((value) => {
                    if (value["amount_to_purchase"] !== 0) {
                        tempList.push(value);
                    }
                });
                return tempList;
            } else {
                return inventoryList;
            };
    } else {
        return inventoryList;
    };

};


  function inventoryTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          // hideFooter={true}
          // hideFooterPagination={true}
          density="compact"
        //   rows={inventoryList}
          rows={getTableContents()}
          columns={columns}
          onCellEditCommit={handleCellEditCommit}
          disableColumnSelector={true}
          disableColumnFilter={true}
        //   onRowClick={handleStarterSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };



  const handleStarterSelect = (params) => {
    setSelectStarterDelete(params.row.id);
  };


  async function handleBuyUpdate() {      

    setLoading(true);

    // await Promise.all(inventoryList.map(async (value) => {
    //     if (value['actual_purchase_amount'] !== 0 && value['actual_purchase_amount'] !== null && value['actual_purchase_amount'] !== NaN) {
    //         await YPCPostAxios2Alt(setXPost, 'postProxyChangeIngredientStockByNameURL', '', 
    //             '?ingredient_name=' + value['name'] + '&amount_to_change=' + value['actual_purchase_amount'].toString());
    //     }
    // }));

    let tempDict = {};
    inventoryList.map((value) => {
        if (value['actual_purchase_amount'] !== 0 && value['actual_purchase_amount'] !== null && value['actual_purchase_amount'] !== NaN) {
            tempDict[value['name']] = value['actual_purchase_amount'];
        };
    });

    await YPCPostAxios2Alt(setXPost, 'postProxyBatchChangeIngredientStockByNameURL', tempDict, '');

    setLoading(false);

    await getMenu();

  };



  async function handleStockUpdate() {      

    setLoading(true);

    let tempDict = {};
    inventoryList.map((value) => {
        tempDict[value['name']] = value['stock'];
    });

    await YPCPutAxios2Alt(setXPost, 'putProxyBatchOverwriteIngredientStockByNameURL', tempDict, '');

    setLoading(false);

    await getMenu();

  };




  const handleHideToBuy = (event) => {
    setHideToBuy(event.target.checked);
  };

  const handleFromForecast = (event) => {
    setFromForecast(event.target.checked);
  };


// function errorBanner() {
const errorBanner = () => {

    return (
        <div style={{ width: '100%' }} >
            {xget !== null ? 
                xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
            : null}


            {xpost !== null ? 
                xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost} 22</Typography></Alert>: null 
                : null}

            {/* {xpost !== null ? 
                xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
                : null} */}
        </div>

    )

};

    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
      <MuiThemeProvider theme={theme}>

        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>

                  <Typography component="h4" variant="h4">
                      Inventory
                      {/* {translate(props.language,"Day's Orders")} */}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>

            <Grid item xs={6}>
                        <div className={classes.sendleft}>

                            <Typography component="h5" variant="h5">
                                From -
                            </Typography>
                        </div>

                        <div style={{clear: "both"}}></div>
                        <br />
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              {/* <KeyboardDatePicker */}
                              <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="from-date-picker-dialog"
                                  label="From Date picker dialog"
                                  // format="MM/dd/yyyy"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedFromDate}
                                  onChange={handleFromDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />

                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={6}>

                        <div className={classes.sendleft}>

                            <Typography component="h5" variant="h5">
                                To -
                                {/* {translate(props.language,"Day's Orders")} */}
                            </Typography>
                        </div>
                        <div style={{clear: "both"}}></div>
                        <br />
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                             <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="To-date-picker-dialog"
                                  label="To Date picker dialog"
                                  // format="MM/dd/yyyy"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedToDate}
                                  onChange={handleToDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>
            </Grid>


            <Grid item xs={2} >
                <Button
                    variant="contained"
                    // color="primary"
                    size="small"
                    className={classes.button}
                    onClick={getMenu}
                    disabled={false}
                    aria-label="Update Details"
                    style={{backgroundColor: '#78cbff', color:"white"}}
                >
                    Get Details
                </Button>   
            </Grid>

            <Grid item xs={1}></Grid>
            
            <Grid item xs={2}>
                <FormControlLabel control={
                <Checkbox
                    value="checkedForCast"
                    checked={fromForecast}
                    onChange={handleFromForecast}
                    // inputProps={{
                    //   'aria-label': 'Checkbox A',
                    // }}
                    />}
                label="Use Forecast" />
            </Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={2}>
                <FormControlLabel control={
                <Checkbox
                    value="checkedA"
                    checked={hideToBuy}
                    onChange={handleHideToBuy}
                    // inputProps={{
                    //   'aria-label': 'Checkbox A',
                    // }}
                    />}
                label="Hide To Buy = 0" />
            </Grid>
            <Grid item xs={2} >
                <Button
                    variant="contained"
                    // color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleStockUpdate}
                    disabled={false}
                    aria-label="Update Stock"
                    style={{backgroundColor: '#78cbff', color:"white"}}
                >
                    Stock Update
                </Button>   
            </Grid>
            <Grid item xs={2} >
                <Button
                    variant="contained"
                    // color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleBuyUpdate}
                    disabled={false}
                    aria-label="Update Buy"
                    style={{backgroundColor: '#78cbff', color:"white"}}
                >
                    Buy Update
                </Button>   
            </Grid>


            <Grid item xs={12}>
                    {errorBanner()}
            </Grid>        

          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>Details &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {(loading) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : inventoryTable()}
                    </Grid>
                  </Grid>
              : null }
          </Grid>


          <div><p></p></div>

          {/* {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null} */}


          {/* {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null} */}

          {/* {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null} */}

        </Grid>
        </MuiThemeProvider>
      </Container>

      </ React.Fragment>



    );
}


