import React from 'react';
import axios from 'axios';
import * as settings from '../settings';

import { useLocation } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Container, CssBaseline, TextField, Typography } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import translate from './YPCTranslate';
import YPCPutAxios2 from './YPCPutAxios2';


const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#78cbff'
      }
    }
  });

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  }
}));

function ResetPassword(props) {
  const classes = useStyles();

  let idString = new URLSearchParams(useLocation().search).get("id");

  const [new_password1, setNewPassword1] = React.useState(null);
  const [new_password2, setNewPassword2] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [xnull, setXNull] = React.useState(null);
  const [username, setUserName] = React.useState(null);
  const [passwordUpdated, setPasswordUpdated] = React.useState(false);
  const [showErrMsg, setShowErrMsg] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);

  const handleFormFieldChange = (event) => {
    setSuccess(false);
    switch (event.target.id) {
      case 'new_password1': setNewPassword1(event.target.value); break;
      case 'new_password2': setNewPassword2(event.target.value); break;
    //   case 'username': setUserName(event.target.value); break;
      default: return null;
    }

  };


  async function handleUpdate () {

    setErrMsg(null);
    setShowErrMsg(false);
    setPasswordUpdated(false);
    setSuccess(false);
    let newSendData = {
        // username: username,
        password1: new_password1,
        password2: new_password2,
        id: idString
      };

    let info = await YPCPutAxios2(setXNull, 'putXChangeResetPasswordURL', newSendData, '');
    setXNull(null);

    console.log('OTOTOTOTOTOTO', info);

    if (info['password_changed']) {
        setPasswordUpdated(true);
        setSuccess(true);
    } else if (!info['password_changes']) {
        setErrMsg(info['password_validations'][info['password_validations'].length-1])
        setShowErrMsg(true)
    };

    }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (new_password1 !== new_password2) {
        alert(translate(props.language,"The passwords don't match."))
    } else if (new_password1 === null) {
        alert(translate(props.language,"Your password must contain at least 8 characters."))
    } else if (new_password1.length < 8) {
        alert(translate(props.language,"Your password must contain at least 8 characters."))
    } else if (new_password1.indexOf(' ') >= 0)
        alert(translate(props.language,"Your password must not contain spaces."))
    else {

        handleUpdate();        
    //   let headers = { 'Authorization': `Token ${props.token}` };
    //   let method = 'post';
    //   let url = settings.API_SERVER + '/api/auth/update_password/';
    //   let passwordFormData = new FormData();
    //   passwordFormData.append("new_password1", new_password1);
    //   passwordFormData.append("new_password2", new_password2);
    //   let config = { headers, method, url, data: passwordFormData};
    //   //Axios update_password API call
    //   axios(config).then(res => {
    //     setSuccess(true);
    //   }).catch(
    //     error => {
    //       alert(error)
    //     })



    }

  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {success ? <Typography variant="button" className={classes.success} gutterBottom>{translate(props.language, 'Password reset successful!  Click on the home icon to sign in.')}</Typography> : null}
        {showErrMsg ? <Typography variant="button" className={classes.error} gutterBottom>{translate(props.language, errMsg)}</Typography> : null}
        <Avatar className={classes.avatar}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {translate(props.language,'Reset Password')}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>

        {/* <MuiThemeProvider theme={theme}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label={translate(props.language, "User Name")}
              name="username"
              autoComplete="name"
              autoFocus
              onChange={handleFormFieldChange}
              color='secondary'
            />
          </MuiThemeProvider> */}
        <MuiThemeProvider theme={theme}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="new_password1"
            label={translate(props.language, "Enter New Password")}
            type="password"
            id="new_password1"
            onChange={handleFormFieldChange}
            error={new_password1 !== new_password2}
            helperText={new_password1 !== new_password2 ? translate(props.language,"Passwords don't match") : null}
            color='secondary'
          />
        </MuiThemeProvider>

        {/* {passwordUpdated ? <Typography variant="button" className={classes.success} gutterBottom>{translate(props.language, 'Password reset successful!  Click on the home icon to sign in.')}</Typography> : null} */}

        <MuiThemeProvider theme={theme}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="new_password2"
                label={translate(props.language, "Enter Your Password Again")}
                type="password"
                id="new_password2"
                onChange={handleFormFieldChange}
                error={new_password1 !== new_password2}
                helperText={new_password1 !== new_password2 ? translate(props.language,"Passwords don't match") : null}
                color='secondary'
            />
        </MuiThemeProvider>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{backgroundColor: '#78cbff'}}
          >
            {translate(props.language,'Reset Password')}
          </Button>
        </form>
      </div>
    </Container>
  );
}


export default ResetPassword;