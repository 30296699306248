import axios from "axios";
// import React from "react";
// import jsonData from './shelf.json';

import config from './YPCconfig.js';

export default async function YPCGetAxios(xget, setXGet, url, id) {


    let baseURL = '';

    if (url === 'shelfURL') {
        baseURL = config.shelfURL + id.toString();
    };
    if (url === 'recipeURL') {
        baseURL = config.recipeURL;
    };
    if (url === 'conciergeRecipesURL') {
        baseURL = config.conciergeRecipesURL;
    };
    if (url === 'conciergeMenuGetURL') {
        baseURL = config.conciergeMenuGetURL + id;
    };
    if (url === 'conciergeOrderGetURL') {
        baseURL = config.conciergeOrderGetURL + id;
    };
    if (url === 'allIngredientsURL') {
        baseURL = config.allIngredientsURL;
    };
    if (url === 'allIngredientsFullURL') {
        baseURL = config.allIngredientsFullURL;
    };
    if (url === 'getProxyallIngredientsFullURL') {
        baseURL = config.getProxyallIngredientsFullURL;
    };
    if (url === 'allRecipesURL') {
        baseURL = config.allRecipesURL;
    };
    if (url === 'getRecipeURL') {
        baseURL = config.getRecipeURL + id;
    };
    if (url === 'allRecipesCategoryURL') {
        baseURL = config.allRecipesCategoryURL;
    };
    if (url === 'getIngredientURL') {
        baseURL = config.getIngredientURL + id;
    };
    if (url === 'allRecipesByCategoryURL') {
        baseURL = config.allRecipesByCategoryURL + id;
    };
    if (url === 'getMenuURL') {
        baseURL = config.getMenuURL + id;
    };
    if (url === 'testMenuURL') {
        baseURL = config.testMenuURL;
    };
    if (url === 'getOrderURL') {
        baseURL = config.getOrderURL + '?datestring=' + id[0] + '&username=' + id[1];
    };
    if (url === 'getOrdersAllURL') {
        baseURL = config.getOrdersAllURL + '?datestring=' + id;
    };
    if (url === 'getDaysOrdersURL') {
        baseURL = config.getDaysOrdersURL + id;
    };
    if (url === 'getUsernamesURL') {
        baseURL = config.getUsernamesURL;
    };
    if (url === 'allRecipesWithTagsURL') {
        baseURL = config.allRecipesWithTagsURL + id;
    };
    if (url === 'getSurveyURL') {
        baseURL = config.getSurveyURL + '?id=' + id;
    };


    console.log('AXAXAXAXAXAX', baseURL, id);

    return new Promise(function (resolve, reject) {

         axios
            .get(baseURL)
            .then((response) => {
                console.log('GGGGGGGGGGGGGet', response.data);
                    if (response.status === 200) {
                        // setXGet(JSON.parse(response.data)   );                    
                        // setXGet(JSON.stringify(response.data, null, 2));
                        // setXGet(response.data);
                        setXGet(null);
                    }
                    else {
                        setXGet('Error.  Fix and reload!');
                    }
                var result = response.data;
                resolve(result);

            }).catch(error => {
                console.log('EEEEEEEEE',error);

                if ('response' in error) {
                    if ('data' in error.response) {
                        if ('detail' in error.response.data) {
                            setXGet('Error loading from DB!\n ' + error.response.data.detail);
                        } else {
                            setXGet('Error.  Fix and reload! \n');
                        }
                    } else {
                        setXGet('Error.  Fix and reload! \n');
                    }
                } else {
                    setXGet('Error.  Fix and reload! \n');
                };

                reject(error);
            });

        });



}
