import React from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@material-ui/core';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
import { useTheme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import StarBorder from '@material-ui/icons/StarBorder';
import PieChartIcon from '@material-ui/icons/PieChart';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import PublicIcon from '@material-ui/icons/Public';
import CallMadeIcon from '@material-ui/icons/CallMade';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShareIcon from '@material-ui/icons/Share';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AddIcon from '@material-ui/icons/Add';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import BuildIcon from '@material-ui/icons/Build';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AppsIcon from '@material-ui/icons/Apps';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InventoryIcon from '@mui/icons-material/Inventory';
import DateRangeIcon from '@mui/icons-material/DateRange';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PolicyIcon from '@mui/icons-material/Policy';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ExtensionIcon from '@mui/icons-material/Extension';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import PageviewIcon from '@mui/icons-material/Pageview';
import HubIcon from '@mui/icons-material/Hub';
import PortraitIcon from '@mui/icons-material/Portrait';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import RecommendIcon from '@mui/icons-material/Recommend';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';


import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import List from '@material-ui/core/List';

import translate from "./YPCTranslate";

// import mainLogo from './images/KitchinClubShine.png';
import mainLogo from './images/Jasper_Logo_11.png';
// import orderLogo from  './images/KitchinClubOrder.png';
import orderLogo from  './images/Jasper_Logo_11.png';

import referEnglishLogo from  './images/referral.png';
import referFrenchLogo from  './images/reference.png';

const StyledBadgeEng = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -9,
    top: 10,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: 15,
  },
}));

const StyledBadgeFr = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -7,
    top: 8,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: 12,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    textTransform: "none"
  },
  button: {
    textTransform: "none"
  },
  whiteColor: {
    color: "black"
  }
}));

export default function TopBar(props) {

    const mediatheme = useTheme();
    const showText = useMediaQuery(mediatheme.breakpoints.up('md'));
    const showLessText = useMediaQuery(mediatheme.breakpoints.up('sm'));

    const classes = useStyles();

    let location = useLocation();
    // let historicalchartpage = location.pathname==="/historicalvolume/" || location.pathname==="/historicalvolume";
    // let symbolinURL = new URLSearchParams(useLocation().search).get("symbol");

    const [open, setOpen] = React.useState(false);
    const [subMenuTools, setSubMenuTools] = React.useState(false);
    // const [language, setLanguage] = React.useState(
    //         localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'FRENCH'
    //         );
    const [subMenuCook, setSubMenuCook] = React.useState(false);

    const [subMenuCreateEdit, setSubMenuCreateEdit] = React.useState(false);
    const [subMenuOrders, setSubMenuOrders] = React.useState(false);
    const [subMenuSales, setSubMenuSales] = React.useState(false);



    const toggleDrawer = (openit) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setOpen(openit);
      if (openit === false) {
        setSubMenuTools(false);
        setSubMenuCook(false);


        setSubMenuCreateEdit(false);
        setSubMenuOrders(false);
        setSubMenuSales(false);
      };
    };


    const handleSubMenuSalesClick = () => {
      setSubMenuSales(!subMenuSales);            
      setSubMenuOrders(false);      
      setSubMenuCreateEdit(false);

      setSubMenuTools(false);
      setSubMenuCook(false);      
    };

    const handleSubMenuOrdersClick = () => {
      setSubMenuSales(false);            
      setSubMenuOrders(!subMenuOrders);      
      setSubMenuCreateEdit(false);

      setSubMenuTools(false);
      setSubMenuCook(false);      
    };


    const handleSubMenuCreateEditClick = () => {
      setSubMenuSales(false);            
      setSubMenuOrders(false);      
      setSubMenuCreateEdit(!subMenuCreateEdit);

      setSubMenuTools(false);
      setSubMenuCook(false);      
    };

    const handleSubMenuToolsClick = () => {
      setSubMenuSales(false);            
      setSubMenuOrders(false);      
      setSubMenuCreateEdit(false);

      setSubMenuTools(!subMenuTools);
      setSubMenuCook(false);
    };

    const handleSubMenuCookClick = () => {
      setSubMenuSales(false);            
      setSubMenuOrders(false);      
      setSubMenuCreateEdit(false);

      setSubMenuTools(false);
      setSubMenuCook(!subMenuCook);
    };

    const handleLocaleChange = (event) => {
      props.setLanguage(event.target.value);
      localStorage.setItem('language', event.target.value);
    };

    const menu = () => (
      <div
        className={clsx(classes.list)}
        // role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >

        {/* General User Items */}
        <List>
          <ListItem button className={classes.button} component={Button} href="/">
            <ListItemIcon><HomeIcon/></ListItemIcon>
            <ListItemText primary={translate(props.language,'Home')} />
          </ListItem>

          <ListItem button className={classes.button} component={Button} href="/ordermealcustomers">
            <ListItemIcon><DinnerDiningIcon /></ListItemIcon>
            <ListItemText primary="Order Meal" />
          </ListItem>

          <ListItem button className={classes.button} component={Button} href="/weeklybillings">
            <ListItemIcon><LocalOfferIcon /></ListItemIcon>
            <ListItemText primary="Weekly Billings" />
          </ListItem>



          { props.universeMaster ?  (
                  <React.Fragment> 
                    {/* Create Edit Menu */}

                      <ListItem button  onClick={handleSubMenuCreateEditClick}>
                        <ListItemIcon><BorderColorIcon/></ListItemIcon>
                        <ListItemText primary={'Create/Edit'} />
                        {subMenuCreateEdit ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>

                      <Collapse in={subMenuCreateEdit} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>



                        {/* <React.Fragment> */}
                              <ListItem button className={classes.nested} component={Button} href="/createrecipe">
                              <ListItemIcon>
                                < MenuBookIcon />
                              </ListItemIcon>
                              <ListItemText primary="Create Recipe" />
                              </ListItem>

                              <ListItem button className={classes.nested} component={Button} href="/createingredient">
                                <ListItemIcon>
                                <ExtensionIcon />
                                </ListItemIcon>
                                <ListItemText primary="Create Ingredient" />
                              </ListItem>

                              <ListItem button className={classes.nested} component={Button} href="/createmenu">
                                <ListItemIcon>
                                  < RestaurantMenuIcon />
                                </ListItemIcon>
                                <ListItemText primary="Create Menu" />
                              </ListItem>

                              <ListItem button className={classes.nested} component={Button} href="/getallrecipes">
                              <ListItemIcon>
                                < PageviewIcon />
                              </ListItemIcon>
                              <ListItemText primary="View All Recipes" />
                              </ListItem>

                            {/* </React.Fragment> */}

                        </List>

                      </Collapse>



                    {/* Create Orders */}

                    <ListItem button  onClick={handleSubMenuOrdersClick}>
                        <ListItemIcon><PlaylistPlayIcon/></ListItemIcon>
                        <ListItemText primary={'Orders'} />
                        {subMenuOrders ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>

                      <Collapse in={subMenuOrders} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                        {/* <React.Fragment> */}

                              <ListItem button className={classes.nested} component={Button} href="/viewdayorders">
                                <ListItemIcon>
                                  < FormatListBulletedIcon />
                                </ListItemIcon>
                                <ListItemText primary="View Day Orders" />
                              </ListItem>

                              <ListItem button className={classes.nested} component={Button} href="/viewweeklyorders">
                                <ListItemIcon>
                                  < DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText primary="View Weekly Orders" />
                              </ListItem>                  

                              <ListItem button className={classes.nested} component={Button} href="/ordertickets">
                                <ListItemIcon>
                                  <ConfirmationNumberIcon />
                                </ListItemIcon>
                                <ListItemText primary="Order Tickets" />
                              </ListItem>
                              <ListItem button className={classes.nested} component={Button} href="/inventory">
                                <ListItemIcon>
                                  <InventoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Inventory" />
                              </ListItem>

                            {/* </React.Fragment> */}

                        </List>

                      </Collapse>          


                    {/* Create Sales */}

                    <ListItem button  onClick={handleSubMenuSalesClick}>
                        <ListItemIcon><ReceiptLongIcon/></ListItemIcon>
                        <ListItemText primary={'Sales'} />
                        {subMenuSales ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>

                      <Collapse in={subMenuSales} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                        {/* <React.Fragment> */}



                            <ListItem button className={classes.nested} component={Button} href="/portraitmaster">
                                <ListItemIcon>
                                  <PortraitIcon />
                                </ListItemIcon>
                                <ListItemText primary="Portrait Master" />
                              </ListItem>
                            <ListItem button className={classes.nested} component={Button} href="/masterorder">
                                <ListItemIcon>
                                  < VisibilityIcon />
                                </ListItemIcon>
                                <ListItemText primary="Master Orders" />
                              </ListItem>
                              <ListItem button className={classes.nested} component={Button} href="/masterbillings">
                                <ListItemIcon>
                                  <LocalOfferIcon />
                                </ListItemIcon>
                                <ListItemText primary="Master Billings" />
                              </ListItem>

                              <ListItem button className={classes.nested} component={Button} href="/customerreturns">
                                <ListItemIcon>
                                  <AssignmentReturnedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Returns/Pickups" />
                              </ListItem>
                              <ListItem button className={classes.nested} component={Button} href="/getallsurveys">
                                <ListItemIcon>
                                  <SentimentSatisfiedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Survey Data" />
                              </ListItem>




                            {/* </React.Fragment> */}

                        </List>

                      </Collapse>          



                      {/* Tools Menu */}

                      <ListItem button  onClick={handleSubMenuToolsClick}>
                        <ListItemIcon><BuildIcon/></ListItemIcon>
                        <ListItemText primary={translate(props.language, 'Tools')} />
                        {subMenuTools ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>

                      <Collapse in={subMenuTools} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                        {/* { props.universeMaster ?  ( */}


                            <React.Fragment>

                              <ListItem button className={classes.nested} component={Button} href="/simorder">
                                <ListItemIcon>
                                  < AccountTreeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sim Orders" />
                              </ListItem>

                              <ListItem button className={classes.nested} component={Button} href="/hubmep">
                                <ListItemIcon>
                                  < HubIcon />
                                </ListItemIcon>
                                <ListItemText primary="Hub MEP" />
                              </ListItem>

                              <ListItem button className={classes.nested} component={Button} href="/operator">
                                <ListItemIcon>
                                  < RunningWithErrorsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Operator" />
                              </ListItem>

                              {/* <Divider /> */}
                            </React.Fragment>



                          {/* ) : null
                        } */}


                        </List>

                      </Collapse>

                  </React.Fragment>

          ): null }





          {/* remaing general user items */}

          <ListItem button className={classes.button} component={Button} href="/update_password">
            <ListItemIcon><VpnKeyIcon/></ListItemIcon>
            <ListItemText primary={translate(props.language,'Update Password')} />
          </ListItem>

          <ListItem button className={classes.button} component={Button} href="/disclaimer">
            <ListItemIcon><PolicyIcon/></ListItemIcon>
            <ListItemText primary={translate(props.language,'Disclaimer')} />
          </ListItem>

          <ListItem button className={classes.button} component={Button} onClick={()=>props.logout()}>
            <ListItemIcon><ExitToAppIcon/></ListItemIcon>
            <ListItemText primary={translate(props.language,'Logout')} />
          </ListItem>


        </List>
        <Divider />

      </div>
    );


    return (
      <div className={classes.root}>
        <AppBar position="static">
          {/* <Toolbar style={{backgroundColor: '#503c30', fontWeight: 'bold', minHeight: '100px'}}> */}
          <Toolbar style={{backgroundColor: '#78cbff', fontWeight: 'bold', minHeight: '100px'}}>

                { showLessText && 
                <img src={(location.pathname === '/' || location.pathname === '/ordermeal' || location.pathname === '/ordermealcustomers' || location.pathname === '/test') && showLessText  ? orderLogo : mainLogo} alt="fireSpot"/>
                }
                { !showLessText &&
                <img width="105" src={(location.pathname === '/' || location.pathname === '/ordermeal' ||  location.pathname === '/ordermealcustomers' || location.pathname === '/test') && showLessText  ? orderLogo : mainLogo} alt="fireSpot"/>
                }

                {/* {location.pathname === '/' || location.pathname === '/ordermeal' ? orderLogo : mainLogo} */}

                  {/* <Typography variant="h3" className={classes.title} style={{fontWeight: 'bold'}} >
                    {" "}
                  </Typography> */}


                  {/* {props.isAuthenticated && showLessText && props.language !== 'FRANCAIS' && 
                        (location.pathname === '/' || location.pathname === '/ordermeal' || location.pathname === '/ordermealcustomers' || location.pathname === '/weeklybillings') && 
                    <a href="/referral">
                        <img   width="120" src={referEnglishLogo} alt="fireSpot"/>
                    </a>
                  }


                  {props.isAuthenticated && !showLessText &&  props.language !== 'FRANCAIS' &&
                        (location.pathname === '/' || location.pathname === '/ordermeal' || location.pathname === '/ordermealcustomers' || location.pathname === '/weeklybillings') && 
                    <a href="/referral">
                        <img  width="90" src={referEnglishLogo} alt="fireSpot"/>  
                    </a>
                  }


                  {props.isAuthenticated && showLessText && props.language === 'FRANCAIS' &&
                        (location.pathname === '/' || location.pathname === '/ordermeal' || location.pathname === '/ordermealcustomers' || location.pathname === '/weeklybillings') && 
                    <a href="/referral">
                        <img   width="120" src={referFrenchLogo} alt="fireSpot"/>  
                    </a>
                  }


                  {props.isAuthenticated && !showLessText &&  props.language === 'FRANCAIS' &&
                        (location.pathname === '/' || location.pathname === '/ordermeal' || location.pathname === '/ordermealcustomers' || location.pathname === '/weeklybillings') && 
                    <a href="/referral">
                        <img  width="90" src={referFrenchLogo} alt="fireSpot"/>  
                    </a>
                  } */}

                  {props.isAuthenticated && props.language !== 'FRANCAIS' && 
                        (location.pathname === '/' || location.pathname === '/ordermeal' || location.pathname === '/ordermealcustomers' || location.pathname === '/weeklybillings') && 
                          <IconButton  href="/referral" aria-label="rewards">
                              <StyledBadgeEng badgeContent={'Rewards'} color="error">
                                  <PersonAddAlt1Icon sx={{ color: '#daa520', fontSize: 45, marginTop: '12px' }} />
                              </StyledBadgeEng>
                          </IconButton>
                  }

                  {props.isAuthenticated && props.language === 'FRANCAIS' && 
                        (location.pathname === '/' || location.pathname === '/ordermeal' || location.pathname === '/ordermealcustomers' || location.pathname === '/weeklybillings') && 
                          <IconButton  href="/referral" aria-label="rewards">
                              <StyledBadgeFr badgeContent={'Récompenses'} color="error">
                                  <PersonAddAlt1Icon sx={{ color: '#daa520', fontSize: 45, marginTop: '16px', marginLeft: '-6px' }} />
                              </StyledBadgeFr>
                          </IconButton>
                  }

                  {/* <Typography variant="h3" className={classes.title} style={{fontWeight: 'bold'}} >
                    {" "}
                  </Typography> */}

                  <Typography className={classes.title} >
                    {" "}
                  </Typography>

                  {/* {props.isAuthenticated ?   <Typography style={{color: 'black', marginTop: '-2px'}} >&nbsp;{translate(props.language, 'Hi')} {props.firstName}! &nbsp;</Typography> : null} */}

                  {/* { showLessText ?
                      // <IconButton aria-label="home page" color="inherit" href="/">
                      <IconButton aria-label="home page" color="black" href="/">
                        <HomeIcon />
                      </IconButton> : 
                      <Typography variant="h3" className={classes.title} style={{fontWeight: 'bold'}} >
                      {" "}
                    </Typography>
                  } */}


                  <Typography>
                    {" "}
                  </Typography>

                  {!showLessText ?
                      <div>
                          <div>
                          {props.isAuthenticated ?   <Typography style={{color: 'black'}} >{translate(props.language, 'Hi')} {props.firstName}! &nbsp;</Typography> : null}
                          </div>
                          <div>
                          <FormControl >
                              <Select 
                                classes={{
                                  root: classes.whiteColor,
                                  icon: classes.whiteColor
                                }} 
                                value={props.language}
                                onChange={handleLocaleChange}
                                displayEmpty
                                disableUnderline
                              >
                                  <MenuItem value={'ENGLISH'}>ENGLISH</MenuItem>
                                  <MenuItem value={'FRANCAIS'}>FRENCH</MenuItem>
                              </Select>
                          </FormControl>     
                          </div>
                      </div>
                    :
                    <React.Fragment>
                      {props.isAuthenticated ?   <Typography style={{color: 'black', marginTop: "-2px"}} >{translate(props.language, 'Hi')} {props.firstName}! &nbsp;</Typography> : null}

                      <FormControl >
                          <Select 
                            classes={{
                              root: classes.whiteColor,
                              icon: classes.whiteColor
                            }} 
                            value={props.language}
                            onChange={handleLocaleChange}
                            displayEmpty
                            disableUnderline
                          >
                              <MenuItem value={'ENGLISH'}>ENGLISH</MenuItem>
                              <MenuItem value={'FRANCAIS'}>FRENCH</MenuItem>
                          </Select>
                      </FormControl>     
                    </React.Fragment>
                  }



                  {props.isAuthenticated && showText ? <Button color="black" href="/update_password">{translate(props.language,'Update Password')}</Button> : null}
                  {props.isAuthenticated && showText ? <Button color="black" onClick={()=>props.logout()}>{translate(props.language,'Logout')}</Button> : null}

                  {props.isAuthenticated ?
                    <React.Fragment>
                      <IconButton
                        // color="inherit"
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={toggleDrawer(true)}
                        // className={clsx(open && classes.hide)}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                        {/* {list(anchor)} */}
                        {menu()}
                        {/* Test */}
                      </Drawer>
                    </React.Fragment>

                    : null }

   
          </Toolbar>
        </AppBar>
      </div>
    );
  }