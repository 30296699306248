let API_SERVER_VAL = '';
let machine_ip = process.env.REACT_APP_IP;

switch (process.env.NODE_ENV) {
    case 'development':
        // API_SERVER_VAL = 'http://localhost:8088';
        //API_SERVER_VAL = 'http://127.0.0.1:8088';
	API_SERVER_VAL = 'http://18.189.158.253:8088';
        // API_SERVER_VAL = 'http://' + machine_ip + ':8088';
        break;
    case 'production':
        //API_SERVER_VAL = process.env.REACT_APP_API_SERVER;
	API_SERVER_VAL = 'https://order.jaspercooks.com';
        break;
    default:
        // API_SERVER_VAL = 'http://localhost:8088';
        //API_SERVER_VAL = 'http://127.0.0.1:8088';
	API_SERVER_VAL = 'http://18.189.158.253:8088';
        // API_SERVER_VAL = 'http://' + machine_ip + ':8088';
        break;
}

export const API_SERVER = API_SERVER_VAL;

export const SESSION_DURATION = 12*3600*1000;


