import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { DataGrid } from '@mui/x-data-grid';
import { GridOverlay, DataGrid,  GridToolbarContainer,
    GridToolbarExport,
    gridClasses } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import YPCFilePutAxios2Alt from './YPCFilePutAxios2Alt';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCGetMenuDB from './YPCGetMenuDB';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "75%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();

const initSelectedDinnerTime = () => {
  let test = moment((new Date()));
  test.set('minute', 0);
  test.set('hour', 18);
  return test;
};

const initSelectedLunchTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 12);
  return test2;
};


const lunchTimeList = ['11:30', '11:45' , '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30'];
const dinnerTimeList = ['17:30', '17:45','18:00', '18:15', '18:30', '18:45', '19:00', '19:15','19:30', '19:45','20:00'];

export default function YPCSimOrders(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [menuLoaded, setMenuLoaded] = React.useState(false);
  const [menuDB, setMenuDB] = React.useState(null);

  const [starterOrderList, setStarterOrderList] = React.useState([]);
  const [selectStarterDelete, setSelectStarterDelete] = React.useState("");

  const [dessertsOrderList, setDessertsOrderList] = React.useState([]);
  const [selectDessertsDelete, setSelectDessertsDelete] = React.useState("");

  const [fruitsOrderList, setFruitsOrderList] = React.useState([]);
  const [selectFruitsDelete, setSelectFruitsDelete] = React.useState("");

  const [drinksOrderList, setDrinksOrderList] = React.useState([]);
  const [selectDrinksDelete, setSelectDrinksDelete] = React.useState("");

  const [mainsOrderList, setMainsOrderList] = React.useState([]);
  const [selectMainsDelete, setSelectMainsDelete] = React.useState("");
  
  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [lunchBoxChecked, setLunchBoxChecked] = React.useState({'test': 'test2'});
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = React.useState({'test': 'test2'});

  const [summaryList, setsummaryList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [selectedTimeLunch, setSelectedTimeLunch] = React.useState(initSelectedLunchTime());
  const [selectedTimeDinner, setSelectedTimeDinner] = React.useState(initSelectedDinnerTime());

  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);
  const [noneSubsriberCost, setNoneSubscriberCost] = React.useState(5.0);

  const [locale, setLocale] = React.useState()

  const [lunchBlocked, setLunchBlocked] = React.useState(false);
  const [dinnerBlocked, setDinnerBlocked] = React.useState(false);

  const [cutoffMsgOpen, setCutoffMsgOpen] = React.useState(false);

  const [username, setUsername] = React.useState("");
  const [usernameList, setUsernameList] = React.useState([]);

  const [subscriptionType, setSubscriptionType] = React.useState('None');

  const [rawMenu, setRawMenu] = React.useState([]);
  const [finalMenu, setFinalMenu] = React.useState([]);
  
  const [orderList, setOrderList] = React.useState([]);

  const [badClients, setBadClients] = React.useState([]);

  // const [value, setValue] = React.useState(new Date("2021-10-26 13:00"));

  // React.useEffect(() => {
  //   if (!menuLoaded) {
  //     handleLoadDB();
  //   }
  // }, [menuLoaded]);


  React.useEffect(() => {
    calculatesummaryTable();
  }, [mainsOrderList, starterOrderList, dessertsOrderList, drinksOrderList, fruitsOrderList, props.language]);


  React.useEffect(() => {
    getMenu();
    getUserNames();
  }, [selectedDate, username]);

  React.useEffect(() => {
    getFinalMenu();
  }, [rawMenu]);

  // const handleLoadDB = () => {
  //   YPCGetMenuDB(xget, setXGet).then(
  //     function(value) { setMenuDB(value);
  //       xtranslate.addLookup(value);
  //     }
  //   );
  //   setMenuLoaded(true);
  // };

  // const onFileUpload = (event) => {
  async function onFileUpload(event) {
    console.log('BBBBBBBB',event.target.files);
    console.log('AAAAAAAA',event.target.files[0]);

    console.log('CCCCCCCCC', finalMenu);

    // const formData = new FormData();
    // formData.append(
    //   "myFile",

    //   event.target.files[0],
    //   event.target.files[0].name
    // );

    const formData = new FormData();
    formData.append(
      'file',event.target.files[0],
    );
    // formData.append('name', "IrvMeister")
    formData.append('payload', JSON.stringify(rawMenu));
    // formData.append('payload', handleSaveOrder());
    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
    formData.append('datestring', dateString)

    let currentTime = moment((new Date()));
    // formData.append('lunchTimeHour', selectedTimeLunch.get('hour'));
    // formData.append('lunchTimeMinute', selectedTimeLunch.get('minute'));
    // formData.append('dinnerTimeHour', selectedTimeDinner.get('hour'));
    // formData.append('dinnerTimeMinute', selectedTimeDinner.get('minute'));
    formData.append('saveTime', currentTime.format("YYYY-MM-DDTHH:mm"));
    formData.append('saveDate', moment(selectedDate).format("YYYY-MM-DDTHH:mm"));
    // formData.append('lunchDeliveryTime', moment(selectedTimeLunch).format("YYYY-MM-DDTHH:mm"));
    // formData.append('dinnerDeliveryTime', moment(selectedTimeDinner).format("YYYY-MM-DDTHH:mm"));


    var results = await YPCFilePutAxios2Alt(setXPost, 'fileUploadURL', formData, '');
    console.log('RRRRRRRRRRRRRRR', results);
    setBadClients(results['bad_clients']);

  };


  async function getUserNames() {
    // let unames = await YPCGetAxios(xget, setXGet, 'getUsernamesURL', '');
    let unames = await YPCGetAxiosAlt(xget, setXGet, 'getUsernamesURL', '');
    setUsernameList(unames.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }));
    // console.log('IQIQIQIQIQI', test);
  }


  async function getMenu() {

    // get subscription type
    // let subType = 'None';
    // if (parseInt(selectedDate.format('YY')) > 21) {
    //   subType = await YPCGetAxiosAlt(xget, setXGet, 'getSubsciptionTypeURL', username);
    // };
    // setSubscriptionType(subType);

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
    // var menu_structure = await YPCGetAxios(xget, setXGet, 'getMenuURL', dateString);
    var menu_structure = await YPCGetAxiosAlt(xget, setXGet, 'getMenuURL', dateString);
    let result = [];
    if (menu_structure.length !== 0) {
      console.log('ZMZMZMZMZMZM', JSON.parse(menu_structure[0].menu));
      result = JSON.parse(menu_structure[0].menu);
    } else {
      console.log('ZMZMZMZMZMZM', menu_structure);
    }

    setRawMenu(result);

  };

  async function getFinalMenu() {


        if (rawMenu.length > 0) {

            const tempList1 = await Promise.all(rawMenu.map(async ( value ) => {
                var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value.name);
                // let tempRecord = value;
                // tempRecord.cost = result.cost;
                // return tempRecord;
                return result
            }));

            console.log('TTTTTTTTTTTT', tempList1);
            // starterList = tempList1;


            setFinalMenu(tempList1);

        } else {
          setFinalMenu([])
        }

    };


    const printcolumns = [
        {
          field: 'id',
          headerName: 'id_recipe',
          type: 'string',
          width: 160,
          sortable: false,
        },
        {
          field: 'name',
          headerName: 'recipe_name',
          width: 300,
          sortable: false,
          type: 'string'
        },
        {
          field: 'category',
          headerName: 'category',
          type: 'string',
          width: 140,
          sortable: false
        },
        {
          field: 'subcategory',
          headerName: 'subcategory',
          type: 'string',
          width: 140,
          sortable: false
        },
        {
          field: 'portion',
          headerName: 'portion',
          sortable: false,
          editable: false,
          type: 'string',
          width: 100,
        },
        {
          field: 'shelf_life',
          headerName: 'shelf_life',
          sortable: false,
          editable: false,
          type: 'string',
          width: 160,
        },
        {
          field: 'support_offline',
          headerName: 'offline',
          sortable: false,
          editable: false,
          type: 'boolean',
          width: 100,
        },

      ];

      function CustomToolbar() {
        return (
          <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <GridToolbarExport />
          </GridToolbarContainer>
        );
      }

    function everythingTable() {
        return (
          <div style={{ width: '100%' }}>
            <DataGrid
      
              className={classes.gridcell}
              hideFooter={true}
              hideFooterPagination={true}
              // hideFooterRowCount={true}
              // hideFooterSelectedRowCount={true}
              density="compact"
              rows={finalMenu}
              columns={printcolumns}
              // pageSize={5}
              // checkboxSelection
              // disableSelectionOnClick
              disableColumnSelector={true}
              disableColumnFilter={true}
              // onRowClick={handleFruitsSelect}
              // headerHeight={0}
              autoHeight={true}
              components={{
                NoRowsOverlay: () => (
                  <GridOverlay className={classes.gridoverlay}>
                    <div className={classes.label}>
                    </div>
                  </GridOverlay>
                ),
                Toolbar:CustomToolbar
              }}
            />
          </div>
        );
      };


  const handleClearAll = () => {

    let tempList = [];
    mainsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setMainsOrderList(tempList)

    tempList = [];
    starterOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setStarterOrderList(tempList)

    tempList = [];
    dessertsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setDessertsOrderList(tempList)    

    tempList = [];
    fruitsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setFruitsOrderList(tempList)

    tempList = [];
    drinksOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setDrinksOrderList(tempList)

  }


  const handleSaveOrder = () => {
    let globalOrderList = [];
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    console.log('PPPPPPPP', globalOrderList);

    let minimalList = {};
    globalOrderList.map((value) => {

        minimalList[value.uuid] = {
            id: value.id,
            lunchcount: value.lunchcount,
            dinnercount: value.dinnercount,
            lunchboxcount: value.lunchboxcount
        };
    });


    let currentTime = moment((new Date()));
    // console.log('TXTXTXTXTXT', currentTime.format("YYYY-MM-DDTHH:mm"));

    // console.log('XCXCXCXCXCXCXCX',  moment(selectedTimeLunch).format("YYYY-MM-DDTHH:mm"), moment(selectedTimeDinner).format("YYYY-MM-DDTHH:mm"));

    let frenchList = {'none': 'none'}
    globalOrderList.map((value) => {
      frenchList[value['dish']] = xtranslate.x('FRANCAIS', value['dish']);
    });

    let combinedLists = {
      minimalList: minimalList,
      fullList: globalOrderList,
      lunchTimeHour:  selectedTimeLunch.get('hour'),
      lunchTimeMinute:  selectedTimeLunch.get('minute'),
      dinnerTimeHour:  selectedTimeDinner.get('hour'),
      dinnerTimeMinute:  selectedTimeDinner.get('minute'),
      saveTime: currentTime.format("YYYY-MM-DDTHH:mm"),
      saveDate: moment(selectedDate).format("YYYY-MM-DDTHH:mm"),
      lunchDeliveryTime: moment(selectedTimeLunch).format("YYYY-MM-DDTHH:mm"),
      dinnerDeliveryTime: moment(selectedTimeDinner).format("YYYY-MM-DDTHH:mm"),
      frenchList: frenchList
    }

    // console.log('TMTMTMTMTMTMTTM', combinedLists.lunchDeliveryTime, combinedLists.dinnerDeliveryTime);

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];


    // YPCPutAxios(setXPost, 'conciergeSaveOrderURL', saveList, props.userName + "_" + dateString);


    let sendData = {
      username: 'TestName',
      datestring: dateString,
      order: JSON.stringify(combinedLists)
    };

    return sendData;

    YPCPutAxios2Alt(setXPost, 'saveOrderURL', sendData, '');

    // check for cutoff Time
    let cutOffTime = moment(selectedDate).startOf('week').subtract(1, 'days').set('hour', 19);
    // console.log('WWWWWWWWWWWWWWWWweek', cutOffTime.format("YYYY-MM-DDTHH:mm"));
    if (currentTime > cutOffTime) {
      setCutoffMsgOpen(true);
      // console.log('No changes allowed')
    };

  };

  const handleDateChange = (date) => {
    console.log('GAGAGA', moment(date).format("YYYY-MM-DDTHH:mm"));
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };


  const handleLunchTimeList = (value) => {
    console.log('XXXXXXXXXX', value, selectedDate.format("YYYY-MM-DDTHH:mm"));
    let newTime = new moment(selectedDate);

    lunchTimeList.map((timeValue) => {
      if (value === timeValue) {
        newTime.set('hour', timeValue.split(":")[0])
        newTime.set('minute', timeValue.split(":")[1])
      };
    });
    console.log('XXXXXXXX22222222222', newTime.format("YYYY-MM-DDTHH:mm"), selectedDate.format("YYYY-MM-DDTHH:mm"));
    setSelectedTimeLunch(newTime);
  };

  const handleDinnerTimeList = (value) => {
    console.log('YYYYYYYYYYYYYYYYY', value);
    // let newTime = selectedDate;
    let newTime = new moment(selectedDate);
    dinnerTimeList.map((timeValue) => {
      if (value === timeValue) {
        newTime.set('hour', timeValue.split(":")[0])
        newTime.set('minute', timeValue.split(":")[1])
      };
    });
    console.log('YYYYYYYYYYYYYYYY22222222222', newTime.format("YYYY-MM-DDTHH:mm"))
    setSelectedTimeDinner(newTime);
  };  

  const handleTimeLunchChange = (time) => {
    console.log('MMMMMMMMMMMMMM111111111', moment(time).format("YYYY-MM-DDTHH:mm"), )

    let test = moment(time);
    let hour = time.get('hour');
    let min = time.get('minute');

    //
    test = moment(selectedDate);
    test.set('hour', hour);
    test.set('minute', min)
    //

    // console.log('ZMZMZMZMZ', min, test);
    if (hour > 13) {
      test.set('hour', 12);
      console.log('AAAA')
    } else if (hour < 11) {
      test.set('hour', 12);
      console.log('BBBB')
    } else {
      test.set('hour', hour);
      console.log('CCCC')
    };

    if (min === 0) {
      test.set('minute', 0 );
    } else if (min === 30) {
      test.set('minute', 30 );
    } else if (min === 15) {
      test.set('minute', 15 );
    } else if (min === 45) {
      test.set('minute', 45 );
    } else {
      test.set('minute' , 0);
    };

    // console.log('ZNZNZNZNZN', min, test);

    setSelectedTimeLunch(test);
  };


  const handleTimeDinnerChange = (time) => {

    console.log('MMMMMMMMMMMMMM22222222', moment(time).format("YYYY-MM-DDTHH:mm"), )
    let test = moment(time);
    let hour = time.get('hour');
    let min = time.get('minute');

    //
    test = moment(selectedDate);
    test.set('hour', hour);
    test.set('minute', min)
    //    

    console.log('ZAZAZAZAZA', min, test);
    if (hour > 20) {
      test.set('hour', 18);
      console.log('AAAA')
    } else if (hour < 17) {
      test.set('hour', 18);
      console.log('BBBB')
    } else {
      test.set('hour', hour);
      console.log('CCCC')
    };

    if (min === 0) {
      test.set('minute', 0 );
    } else if (min === 30) {
      test.set('minute', 30 );
    } else if (min === 15) {
      test.set('minute', 15 );
    } else if (min === 45) {
      test.set('minute', 45 );
    } else {
      test.set('minute' , 0);
    };

    console.log('ZMZMZMZMZ', min, test);

    setSelectedTimeDinner(test);
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      field: 'dish',
      // headerName: 'Dish',
      headerName: translate(props.language, 'Selection'),
      width: 300,
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
          indent: params.row.side,
          noindent: !params.row.side,
      }),
      valueFormatter: (params) => {
        const valueFormatted = xtranslate.x(props.language, params.value) + ( Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 ? ' (s' + params.row.spicelevel + ')' : '') + 
          (params.row.vegetarian ? ' (v)' : '') + 
          (params.row.vegan ? ' (ve)' : '') +
          (params.row.gluten_free ? ' (gf)' : '') + 
          (!params.row.peanut_free ? ' (p)' : '') + 
          (params.row.baby_friendly ? ' (bf)' : '');
        return `${valueFormatted}`;
      },

    },
    {
      field: 'price',
      headerName: translate(props.language, 'Price/Portion'),
      // headerName: 'Price/Portion',
      type: 'number',
      width: 140,
      sortable: false,
       valueFormatter: (params) => {
        if (params.value === null) {
            return (null);
        }
        else {
            return (currencyFormatter.format(Number(params.value)))
        }
       },

    },
    {
        field: 'lunchcount',
        headerName: translate(props.language, 'Lunch'),
        // headerName: 'Lunch',
        type: 'number',
        sortable: false,
        width: 120,
        editable: true,
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                hide: params.row.side  || params.row.uuid in dinnerOnlyChecked,
                show: !params.row.side && !(params.row.uuid in dinnerOnlyChecked) && !lunchBlocked,
                blocked: !params.row.side && !(params.row.uuid in dinnerOnlyChecked) && lunchBlocked
            }),
      },
      {
        field: 'dinnercount',
        headerName: translate(props.language, 'Dinner'),
        // headerName: 'Dinner',
        type: 'number',
        sortable: false,
        width: 120,
        editable: true,
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                hide: params.row.side,
                show: !params.row.side && !dinnerBlocked,
                blocked: !params.row.side && dinnerBlocked
            }),
      },
      {
        field: 'lunchboxcount',
        headerName: translate(props.language, 'Lunchbox') + '⁽¹⁾',
        // headerName: 'Lunchbox',
        type: 'number',
        sortable: false,
        width: 140,
        editable: true,
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                hide: params.row.side || !(params.row.uuid in lunchBoxChecked),
                show: !params.row.side && (params.row.uuid in lunchBoxChecked) && !dinnerBlocked,
                blocked: !params.row.side && (params.row.uuid in lunchBoxChecked) && dinnerBlocked
            }),
      },      


  ];

  const summaryColumns = [
    {
      field: 'item',
      headerName: translate(props.language, 'Item'),
      // headerName: 'Item',
      width: 300,
      sortable: false,
      valueFormatter: (params) => {
        return xtranslate.x(props.language, params.value);
      },
    },
    {
        field: 'itemtype',
        headerName: translate(props.language, 'Type'),
        // headerName: 'Type',
        width: 140,
        sortable: false,
      },
      {
        field: 'quantity',
        headerName: translate(props.language, 'Quantity'),
        // headerName: 'Quantity',
        type: 'number',
        width: 140,
        sortable: false
      },      
    {
      field: 'price',
      headerName: translate(props.language, 'Price'),
      // headerName: 'Price',
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
        field: 'total',
        headerName: translate(props.language, 'Total'),
        // headerName: 'Total',
        type: 'number',
        width: 140,
        sortable: false,
        valueFormatter: (params) => {
            if (typeof params.value === 'string' || params.value instanceof String) {
                return params.value;
            } else {
                const valueFormatted = currencyFormatter.format(Number(params.value));
                return `${valueFormatted}`;
            }
          },
      },
  ];





  function calculatesummaryTable() {
    let globalOrderList = [];
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    let tempList = []
    let itemId = 0;
    let item = null;
    globalOrderList.map((value) => {
        if (!value.side) {
            if (value.lunchcount !== null || value.dinnercount !== null || value.lunchboxcount !== null) {
                item = value.dish;
                if (value.lunchcount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Lunch)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Lunch') + ")",
                        quantity: value.lunchcount,
                        price: value.price,
                        total: value.lunchcount * value.price
                    });
                    itemId = itemId + 1;
                };
                if (value.dinnercount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Dinner)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Dinner') + ")",
                        quantity: value.dinnercount,
                        price: value.price,
                        total: value.dinnercount * value.price
                    });
                    itemId = itemId + 1;
                };
                if (value.lunchboxcount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Lunchbox)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Lunchbox') + ")",
                        quantity: value.lunchboxcount,
                        price: value.price,
                        total: value.lunchboxcount * value.price
                    });
                    itemId = itemId + 1;
                };



            }
        }
    });

    let subTotal = 0;
    tempList.map((value) => {
        subTotal = subTotal + value.total;
    });


    // check on subscription
    if (subscriptionType === 'None' && tempList.length !== 0) {
      subTotal = subTotal + noneSubsriberCost;
      tempList.push({
        id: itemId,
        item: translate(props.language, 'Non-member fee (cooking & dishwashing)'),
        itemtype: null,
        quantity: null,
        price: noneSubsriberCost,
        total: noneSubsriberCost
      });
      itemId = itemId + 1;
    };

    let totalGST = gst * subTotal;
    let totalPST = pst * subTotal;
    let grandTotal = subTotal + totalGST + totalPST;

    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        price: " ",
        total: " "
    });
    itemId = itemId + 1;

    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'SubTotal',
        price: translate(props.language,'SubTotal'),
        total: subTotal
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'GST (5%)',
        price: translate(props.language,'GST') + ' (5%)',
        total: totalGST
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'PST (9.975%)',
        price: translate(props.language,'PST') + ' (9.975%)',
        total: totalPST
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'Total',
        price: translate(props.language,'Total'),        
        total: grandTotal
    });
    setsummaryList(tempList);

  };



  function summaryTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={summaryList}
          columns={summaryColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };


  const handleEditable = (params) => {
    if (params.row.side) {
        return (false);
    } else if (params.field === 'lunchboxcount') {
        if (params.row.uuid in lunchBoxChecked && !dinnerBlocked) {
            return (true);
        } else {
            return (false);
        };
    } else if (params.field === 'lunchcount') {
        if (params.row.uuid in dinnerOnlyChecked) {
          return (false);
        } else if (lunchBlocked) {
          return (false);
        } else {
          return (true);
        };
    } else if (params.field === 'dinnercount') {
        if (dinnerBlocked) {
          return (false);
        } else {
        return (true);
        };
    };
 };



  // Start Functions Section


  function StarterTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
          onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={starterOrderList}
          columns={columns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleStarterSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
        //   getCellClassName={(params) => {
        //     console.log('NNNNNNNNNNNNNNNNNNN',params);
        //     if (params.field === 'city') {
        //       return '';
        //     }
        //     return Number(params.value) >= 15 ? 'hot' : 'cold';
        //   }}


          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  const handleStarterLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = starterOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 
                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan
                            }

                };
                return row;
            
            });
            setStarterOrderList(updatedRows);
        };
    },
    // [starterOrderList],
  );

  const handleStarterSelect = (params) => {
    setSelectStarterDelete(params.row.uuid);
  };


  // End of Starters Section


  // Mains Functions Section

 
  function MainsTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          onCellEditCommit={handleMainsLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={mainsOrderList}
          // rows={mainsAltOrderList}
          columns={columns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleMainsSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  const handleMainsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = mainsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 
                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan
                            }

                };
                return row;
            
            });
            setMainsOrderList(updatedRows);
        };
    },
    // [mainsOrderList],
  );

  const handleMainsSelect = (params) => {
    setSelectMainsDelete(params.row.uuid);
  };
  


// end of Mains Section

// start of Desserts Section

function DessertsTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleDessertsLunchCount}
        //   isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={dessertsOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleDessertsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const handleDessertsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = dessertsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 
                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan
                            }

                };
                return row;
            
            });
            setDessertsOrderList(updatedRows);
        };
    },
    // [dessertsOrderList],
  );

const handleDessertsSelect = (params) => {
  setSelectDessertsDelete(params.row.uuid);
};



// end of Desserts Section


// start of Drinks section


function DrinksTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleDrinksLunchCount}
        //   isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={drinksOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleDrinksSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const handleDrinksLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = drinksOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 
                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan
                            }

                };
                return row;
            
            });
            setDrinksOrderList(updatedRows);
        };
    },
    // [drinksOrderList],
  );

const handleDrinksSelect = (params) => {
  setSelectDrinksDelete(params.row.uuid);
};




// end of drinks sectiion


// start of Fruits Section


function FruitsTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleFruitsLunchCount}
        //   isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={fruitsOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleFruitsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const handleFruitsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = fruitsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 
                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan
                            }

                };
                return row;
            
            });
            setFruitsOrderList(updatedRows);
        };
    },
    // [fruitsOrderList],
  );

const handleFruitsSelect = (params) => {
  setSelectFruitsDelete(params.row.uuid);
};


function cutoffMsg() {
  return (
    <div>
      <Dialog open={cutoffMsg} >
        <DialogTitle>{translate(props.language, 'If you have modified your order, please notify concierge@jaspercooks.com about this change so we can confirm feasibility.')}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCutoffMsg} color="primary">
            {translate(props.language, 'Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const handleCutoffMsg = () => {
  setCutoffMsgOpen(false);
}



// end of Fruits Section


// gen dialog

const handleGenDialog = () => {
  setXPost(null);
  setXGet(null);
}

  function genDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {xpost}{xget}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };

  async function handleCancelOrders () {

    // handleClearAll();

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    let sendData = {
      datestring: dateString,
    };


    // console.log('WXWXWXWXWX', combinedLists);

    await YPCPutAxios2Alt(setXPost, 'deleteFakeOrdersURL', sendData, '');

    // setXNull(null);

  };

    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>
                  {/* {cutoffMsgOpen ? cutoffMsg() : null} */}
                  {xpost !== null || xget !== null ? genDialog() : null}
                  <Typography component="h4" variant="h4">
                      {'Order Simulation'}
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>

          <Grid item xs={12}>

                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              {/* <KeyboardDatePicker */}
                              <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date picker dialog"
                                  // format="MM/dd/yyyy"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>

          </Grid>

          {(badClients.length !== 0) ?
            <Grid item xs={12}>
              <Typography>The following client names do not exist in database:</Typography>
              <pre>{JSON.stringify(badClients, null, 2) }</pre>
            </Grid>
            : null}

          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Menu Summary'} &#8198;</div>
          </Grid>

          <Grid item xs={12}>

              { mealDB ?     
                    <Grid item xs={8} >
                        {everythingTable()}
                    </Grid>
              : null }
          </Grid>


          {/* End of Check Balances */}


          {/* Upload and Place Order Section */}
          <Grid item xs={12} >          


              <Grid item xs={3} >
                <p></p>
                  <Button
                      variant="contained"
                      component="label"
                      size="small"
                      color="primary"
                      className={classes.button}
                      disabled={false}
                      aria-label="whatever 20"
                      style={{backgroundColor: '#78cbff', color: "white"}}
                    >
                      Upload File
                      <input
                        type="file"
                        name="file"
                        hidden
                        onChange={onFileUpload}
                      />
                    </Button>
                </Grid>                




              <Grid item xs={6} ></Grid>
          </Grid>

          <Grid item xs={12} >          

          <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        // color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleCancelOrders}
                        disabled={false}
                        aria-label="whatever 116"
                        style={{backgroundColor: '#78cbff', color:"white"}}
                    >
                        {translate(props.language, 'Cancel Fake Orders')}
                    </Button>        

              </Grid>

            <Grid item xs={9} >
            </Grid>
          </Grid>






          <div><p></p></div>

          {/* {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null} */}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}



