import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import * as Square from '@square/web-sdk';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Skeleton from '@mui/material/Skeleton';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
// import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "75%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();



export default function YPCWeeklyBillings(props) {

  const classes = useStyles();
  const mediatheme = useTheme();
  const showText = useMediaQuery(mediatheme.breakpoints.up('sm'));
  // const theme = useTheme();
  
  let dateInURL = new URLSearchParams(useLocation().search).get("date");
  let momentDate = null;

  if (dateInURL === null) {
    momentDate = moment(new Date());
  } else { 
    let momentInURL = moment(dateInURL, "DDMMYYYY");
    if (momentInURL._isValid) {
      momentDate = momentInURL;
    } else {
      momentDate = moment(new Date());
    };
  };
  

  const [starterOrderArrayList, setStarterOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [dessertsOrderArrayList, setDessertsOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [fruitsOrderArrayList, setFruitsOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [drinksOrderArrayList, setDrinksOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [mainsOrderArrayList, setMainsOrderArrayList] = React.useState([[],[],[],[],[],[],[]]);
  const [lunchDeliveryTime, setLunchDeliveryTime] = React.useState(['','','','','','','']);
  const [dinnerDeliveryTime, setDinnerDeliveryTime] = React.useState(['','','','','','','']);

  const [subscriptionTypeArrayList, setSubscriptionTypeArrayList] = React.useState(['None','None','None','None','None','None','None'])

  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);


  const [summaryList, setsummaryList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);
  const [summaryArrayList, setsummaryArrayList] = React.useState([
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}],
                                            [{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]
                                        ]);

  // const [combinedSummaryList, setCombinedSummaryList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);

  // const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));
  const [selectedDate, setSelectedDate] = React.useState(momentDate);

  const [datesArray, setDatesArray] = React.useState([moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD"),
              moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD"),
              moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD"),])

  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);
  const [noneSubsriberCost, setNoneSubscriberCost] = React.useState(5.0);

  const noneSubscriberCostDict = {'None' : 9.00, 'None5': 5.00};

  const [subscriptionType, setSubscriptionType] = React.useState('None');

  const [locale, setLocale] = React.useState()

  const [loaded, setLoaded] = React.useState(false);
  const [payButtonDisable, setPayButtonDisable] = React.useState(false);

  const [amountDue, setAmountDue] = React.useState(0.0);
  const [billingDate, setBillingDate] = React.useState(null);
  const [showBillingDate, setShowBillingDate] = React.useState(false);
  const [billPaymentDue, setBillPaymentDue] = React.useState(false);
  const [cardToken, setCardToken] = React.useState(null);

  const [displayPaymentDialog1, setDisplayPaymentDialog1] = React.useState(false);
  // const [paymentDialogMsg1, setPaymentDialogMsg1] = React.useState("");

  const [displayPaymentDialogDone, setDisplayPaymentDialogDone] = React.useState(false);
  const [paymentDialogMsgDone, setPaymentDialogMsgDone] = React.useState("");

  const [xnull, setXNull] = React.useState(null);

  const bi_monthly_subscription_cost = 45.0;

  const subscriptionCostLookup = {'Individual': 30.0, 'Family': 50.0, 'Individual 4 Weeks': 100.0,  'Family 4 Weeks':170.0, 'None': 0.0, 'None5': 0.0, 'Free':0.0, 'Free Subscription':0.0, 'CmpPaysAll':0.0, 
        'CmpPaysSub': 0.0, 'MngPaysAll': 0.0, 'MngPaysSub' : 0.0, 'Company Subscription': 30.0 }

  const [chargesubscription, setChargeSubscription] = React.useState(false);
  const [subsType, setSubsType] = React.useState(null);
  const [compType, setCompType] = React.useState('None');

  const [subscriptionStatement, setSubscriptionStatement] = React.useState(translate(props.language, 'Non-member fee (cooking & dishwashing)'))

  const [allEmployees, setAllEmployees] = React.useState([]);

  const [dateDisplay, setDateDisplay] = React.useState(['','','','','','','']);

  const [hideSummary, setHideSummary] = React.useState(false);
  
  const hidePayment = true;

  // React.useEffect(() => {
  //   moment.locale('en-ca', { week: { dow: 1 } })
  // }, [])


  // React.useEffect(() => {
  //   moment.locale('fr-ca', { week: { dow: 1 } })
  // }, [])


  React.useEffect(() => {
    calculatesummaryTable();
  }, [subscriptionTypeArrayList, mainsOrderArrayList, starterOrderArrayList, dessertsOrderArrayList, drinksOrderArrayList, fruitsOrderArrayList, props.language, dinnerDeliveryTime, lunchDeliveryTime]);

  React.useEffect(() => {
     getEverything();
  }, [selectedDate]);


  React.useEffect(() => {
    if (!loaded) {
      start();
      setLoaded(true);
    }
  }, [loaded]);

  // React.useEffect(() => {
  //   if (amountDue !== 0.0) {
  //     updateStart();
  //   }
  // }, [amountDue]);


  React.useEffect(() => {
    if (loaded) {
      processToken();
    }
  }, [cardToken]);

    async function  getEverything() {

        // setHideSummary(false);
        // setAllEmployees([])

        // get subscription type
        let subType = 'None';

        // following was replaced by below - see A**
        // if (parseInt(selectedDate.format('YY')) > 21) {
        //   subType = await YPCGetAxiosAlt(xget, setXGet, 'getXSubsciptionTypeURL', '');
        // };
        // setSubscriptionType(subType);


        // let subsTypeCompanyHistory = [];
        // let newDate = new moment(selectedDate).startOf('week').format("YYYY-MM-DD")
        // subsTypeCompanyHistory = await YPCGetAxiosAlt(xget, setXGet, 'getXSubsTypeCompHistoryURL', newDate);

        let subDate = selectedDate.toString().split(" ")
        let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
        let subsTypeCompanyHistory = [];
        subsTypeCompanyHistory = await YPCGetAxiosAlt(xget, setXGet, 'getXDateSubscriptionTypeURL', dateString);


        console.log('HIHIHIHIHIHIHI', subsTypeCompanyHistory);
        setChargeSubscription(subsTypeCompanyHistory['pay_subscription']);
        setSubsType(subsTypeCompanyHistory['subs_type']);
        setCompType(subsTypeCompanyHistory['comp_type']);

        // A**
        subType = subsTypeCompanyHistory['subs_type']
        setSubscriptionType(subType);

        let mainsOList = [];
        let starterOList = [];
        let dessertsOList = [];
        let fruitsOList = [];
        let drinksOList = [];

        let dateList = [];
        let altDateList = [];

        let lunchODelivery = [];
        let dinnerODelivery = [];

        let subscriptionTypeOList = [];

        for (let count=0; count<7; count ++) {

                let sDate = new moment(selectedDate).startOf('week').add(count + 1,'days');
                // let sDate = new moment(selectedDate).startOf('week').add(count,'days');

                dateList.push(sDate.format("YYYY-MM-DD"));
                altDateList.push(sDate.format("ddd MMM D"));
                
                let subDate = sDate.toString().split(" ");

               
                // let subDate = selectedDate.toString().split(" ")
                let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

                // console.log("BABABABABABABABABAB", subDate, dateString);

                var menu_structure = await YPCGetAxiosAlt(xget, setXGet, 'getXMenuURL', dateString);
                let result = [];
                if (menu_structure.length !== 0) {
                console.log('ZMZMZMZMZMZM', JSON.parse(menu_structure[0].menu));
                result = JSON.parse(menu_structure[0].menu);
                } else {
                console.log('ZMZMZMZMZMZM', menu_structure);
                }

                // check for bill payment due
                if (count === 0) {

                  // what 1 week before showing billing
                  let billDateShow = new moment(selectedDate).startOf('week').add(count + 6,'days');
                  // let billDateShow = new moment(selectedDate).startOf('week').add(count + 5,'days');
                  let nowDate = moment(new Date())
                  if (nowDate > billDateShow) {
                    setShowBillingDate(true);
                  } else {
                    setShowBillingDate(false);
                  };
                  setBillingDate(dateString);
                  var detail_structure =  await YPCGetAxiosAlt(xget, setXGet, 'getXPaymentURL', [dateString, props.userName]);
                  // console.log('BXBXBXBX', detail_structure);
                  if (detail_structure.length !== 0) {
                    setBillPaymentDue(false);
                  } else {
                    setBillPaymentDue(true);
                  };
                }

                let orderResult = [];
                let order = {};
                let lunchDelivery = '';
                let dinnerDelivery = '';

                let subscriptionTypeValue = 'None';

                // var order_structure =  await YPCGetAxios(xget, setXGet, 'getOrderURL', [dateString, props.userName]);
                var order_structure =  await YPCGetAxiosAlt(xget, setXGet, 'getXOrderURL', [dateString, props.userName]);
                if (order_structure.length !== 0) {
                  console.log('ZOZOZOZOZOZ', count, JSON.parse(order_structure[0].order));
                  orderResult = JSON.parse(order_structure[0].order);
                  order = orderResult.minimalList;

                  dinnerDelivery = String(orderResult.dinnerTimeHour) + ':' + String(orderResult.dinnerTimeMinute)
                  lunchDelivery = String(orderResult.lunchTimeHour) + ':' + String(orderResult.lunchTimeMinute)

                  if (orderResult.hasOwnProperty('subscriptionType')) {
                    subscriptionTypeValue = orderResult.subscriptionType;
                  } else {
                    subscriptionTypeValue = subType;
                  }

                } else {
                  // console.log('ZOZOZOZOZO', order_structure);
                  console.log('ZOZOZOZOZOZ', count);
                  order = {crap: {tests: 1}};
                  dinnerDelivery = '';
                  lunchDelivery = ''

                  subscriptionTypeValue = subType;
                }


                let translateList = [];

                if (result.length !== 0) {
                    let mainsList = [];
                    let starterList = [];
                    let dessertsList = [];
                    let fruitsList = [];
                    let drinksList = [];
                    let category = null;
                    let tempValue = null;
                    let tempLunch = {};
                    let tempDinner = {};
                    result.map((value) => {

                    translateList.push(value);

                    tempValue = {
                        category: value.category,
                        dish: value.dish,
                        id: value.id,
                        name: value.name,
                        price: value.price,
                        side: value.side,
                        uuid: value.uuid,
                        lunchcount: value.uuid in order ? order[value.uuid].lunchcount : null,
                        dinnercount:  value.uuid in order ? order[value.uuid].dinnercount : null,
                        lunchboxcount:  value.uuid in order ? order[value.uuid].lunchboxcount : null,
                        spicelevel: value.spicelevel,
                        vegetarian: value.vegetarian,
                        gluten_free: value.gluten_free,
                        peanut_free: value.peanut_free,
                        baby_friendly: value.baby_friendly,
                    };
                    if (value.lunchbox) {
                        tempLunch[value.uuid] = true;
                    };
                    if (value.dinneronly) {
                        tempDinner[value.uuid] = true;
                    };
                    if (!value.side) {
                        category = value.category.slice();
                    };
                    if (category === 'Starter') {
                        starterList.push(tempValue);
                    } else if (category === 'Main') {
                        mainsList.push(tempValue);
                    } else if (category === 'Dessert') {
                        dessertsList.push(tempValue);
                    } else if (category === 'Drink') {
                        drinksList.push(tempValue);
                    } else if (category === 'Fruit') {
                        fruitsList.push(tempValue)
                    };

                    });

                    xtranslate.addLookupNew(translateList);

                    mainsOList.push(mainsList);
                    starterOList.push(starterList);
                    dessertsOList.push(dessertsList);
                    fruitsOList.push(fruitsList);
                    drinksOList.push(drinksList);

                    dinnerODelivery.push(dinnerDelivery);
                    lunchODelivery.push(lunchDelivery);

                    // following is modified on major change
                    // subscriptionTypeOList.push(subscriptionTypeValue);
                    subscriptionTypeOList.push(subType);

                } else {

                    mainsOList.push([]);
                    starterOList.push([]);
                    dessertsOList.push([]);
                    fruitsOList.push([]);
                    drinksOList.push([]);

                    dinnerODelivery.push('');
                    lunchODelivery.push('');

                    subscriptionTypeOList.push(subType);

                };

        }; // for loop end
    
        setDateDisplay(altDateList);

        setMainsOrderArrayList(mainsOList);
        setStarterOrderArrayList(starterOList);
        setDessertsOrderArrayList(dessertsOList);
        setFruitsOrderArrayList(fruitsOList);
        setDrinksOrderArrayList(drinksOList);

        setDatesArray(dateList);

        setLunchDeliveryTime(lunchODelivery);
        setDinnerDeliveryTime(dinnerODelivery);

        setSubscriptionTypeArrayList(subscriptionTypeOList)

        // setHideSummary(true);

    };


  const handleDateChange = (date) => {
    console.log('GAGAGA', moment(date).format("YYYY-MM-DDTHH:mm"));
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };





  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  const summaryColumns = [
    {
      field: 'item',
      headerName: translate(props.language, 'Item'),
      // headerName: 'Item',
      width: 300,
      sortable: false,
      valueFormatter: (params) => {
        return xtranslate.x(props.language, params.value);
      },
    },
    {
        field: 'itemtype',
        headerName: translate(props.language, 'Time/Type'),
        // headerName: 'Type',
        width: 200,
        sortable: false,
      },
      {
        field: 'quantity',
        headerName: translate(props.language, 'Quantity'),
        // headerName: 'Quantity',
        type: 'number',
        width: 140,
        sortable: false
      },      
    {
      field: 'price',
      headerName: translate(props.language, 'Price'),
      // headerName: 'Price',
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
        field: 'total',
        headerName: translate(props.language, 'Total'),
        // headerName: 'Total',
        type: 'number',
        width: 140,
        sortable: false,
        valueFormatter: (params) => {
            if (typeof params.value === 'string' || params.value instanceof String) {
                return params.value;
            } else {
                const valueFormatted = currencyFormatter.format(Number(params.value));
                return `${valueFormatted}`;
            }
          },
      },
  ];


  const finalColumns = [
    {
      field: 'price',
      headerName: translate(props.language, 'Price'),
      type: 'number',
      width: 300,
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
        field: 'total',
        headerName: translate(props.language, 'Total'),
        type: 'number',
        width: 140,
        sortable: false,
        valueFormatter: (params) => {
            if (typeof params.value === 'string' || params.value instanceof String) {
                return params.value;
            } else {
                const valueFormatted = currencyFormatter.format(Number(params.value));
                return `${valueFormatted}`;
            }
          },
      },
  ];


  async function calculatesummaryTable() {

    setHideSummary(false);
    setAllEmployees([])

    let combinedGiantOrderList = [];
    let giantOrderList = [];
    let weeklyTotal = 0.0;
    let weeklyNoSubscribeTotal = 0.0;
    let itemId = 0;
    for (let count=0; count<7; count ++) {
            let globalOrderList = [];
            globalOrderList.push(...starterOrderArrayList[count], ...mainsOrderArrayList[count], ...dessertsOrderArrayList[count],
                   ...drinksOrderArrayList[count], ...fruitsOrderArrayList[count]);
            let tempList = []
            // let itemId = 0;
            let item = null;
            globalOrderList.map((value) => {
                if (!value.side) {
                    if (value.lunchcount !== null || value.dinnercount !== null || value.lunchboxcount !== null) {
                        item = value.dish;
                        if (value.lunchcount !== null) {
                            tempList.push({
                                id: itemId,
                                item: value.dish,
                                // itemtype: value.category + " (Lunch)",
                                itemtype: lunchDeliveryTime[count]  + " (" + translate(props.language, 'Lunch') + ") " + translate(props.language, value.category),
                                quantity: value.lunchcount,
                                price: value.price,
                                total: value.lunchcount * value.price
                            });
                            itemId = itemId + 1;
                        };
                        if (value.dinnercount !== null) {
                            tempList.push({
                                id: itemId,
                                item: value.dish,
                                // itemtype: value.category + " (Dinner)",
                                itemtype: dinnerDeliveryTime[count] + " (" + translate(props.language, 'Dinner') + ") " + translate(props.language, value.category),
                                quantity: value.dinnercount,
                                price: value.price,
                                total: value.dinnercount * value.price
                            });
                            itemId = itemId + 1;
                        };
                        if (value.lunchboxcount !== null) {
                            tempList.push({
                                id: itemId,
                                item: value.dish,
                                // itemtype: value.category + " (Lunchbox)",
                                itemtype: dinnerDeliveryTime[count]  + " (" + translate(props.language, 'Lunchbox') + ") " + translate(props.language, value.category),
                                quantity: value.lunchboxcount,
                                price: value.price,
                                total: value.lunchboxcount * value.price
                            });
                            itemId = itemId + 1;
                        };
                    }
                }
            });

            let subTotal = 0;
            tempList.map((value) => {
                subTotal = subTotal + value.total;
            });

            // check on subscription
            // if (subscriptionType === 'None' && tempList.length !== 0) {
            let subSub = 0.0
            if ( (subscriptionTypeArrayList[count] === 'None' || subscriptionTypeArrayList[count] === 'None5') && tempList.length !== 0) {  
              // subSub = noneSubsriberCost;
              subSub = noneSubscriberCostDict[subscriptionTypeArrayList[count]];
              // subTotal = subTotal + noneSubsriberCost;
              // weeklyNoSubscribeTotal = weeklyNoSubscribeTotal + noneSubsriberCost;
              weeklyNoSubscribeTotal = weeklyNoSubscribeTotal + noneSubscriberCostDict[subscriptionTypeArrayList[count]];
              tempList.push({
                id: itemId,
                item: translate(props.language, 'Non-member fee (cooking & dishwashing)'),
                itemtype: null,
                quantity: null,
                // price: noneSubsriberCost,
                // total: noneSubsriberCost
                price: noneSubscriberCostDict[subscriptionTypeArrayList[count]],
                total: noneSubscriberCostDict[subscriptionTypeArrayList[count]]
              });
              itemId = itemId + 1;
            };

            weeklyTotal = weeklyTotal + subTotal;

            let totalGST = gst * (subTotal + subSub);
            let totalPST = pst * (subTotal + subSub);
            let grandTotal = subTotal + subSub + totalGST + totalPST;

            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                price: " ",
                total: " "
            });
            itemId = itemId + 1;

            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                // price: 'SubTotal',
                price: translate(props.language,'SubTotal'),
                total: subTotal + subSub
            });
            itemId = itemId + 1;
            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                // price: 'GST (5%)',
                price: translate(props.language,'GST') + ' (5%)',
                total: totalGST
            });
            itemId = itemId + 1;
            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                // price: 'PST (9.975%)',
                price: translate(props.language,'PST') + ' (9.975%)',
                total: totalPST
            });
            itemId = itemId + 1;
            tempList.push({
                id: itemId,
                item: null,
                itemtype: null,
                quantity: null,
                // price: 'Total',
                price: translate(props.language,'Total'),        
                total: grandTotal
            });

            giantOrderList.push(tempList);
            combinedGiantOrderList.push(...tempList)
            // console.log('XZXZXZXZXZXZ', tempList)
            // console.log('222222XZXZXZXZXZXZ', ...tempList)
    }; // end of for loop


      let tempList = []
      // let itemId = 0;
      let item = null;

      tempList.push({
        id: itemId,
        price: translate(props.language,'Subscription Total'),
        // total: chargesubscription ? bi_monthly_subscription_cost: 0.0
        total: chargesubscription ? subscriptionCostLookup[subsType]: 0.0
      });
      itemId = itemId + 1;      
      // big change here
      tempList.push({
        id: itemId,
        price: translate(props.language, 'Non Member Total'),
        total: weeklyNoSubscribeTotal
      });
      itemId = itemId + 1;      
      tempList.push({
        id: itemId,
        price: translate(props.language,'Food Total'),
        total: weeklyTotal
      });
      itemId = itemId + 1;
      tempList.push({
          id: itemId,
          // price: 'GST (5%)',
          price: translate(props.language,'GST') + ' (5%)',
          // total: gst * (weeklyTotal + weeklyNoSubscribeTotal + (chargesubscription ? bi_monthly_subscription_cost: 0.0))
          total: gst * (weeklyTotal + weeklyNoSubscribeTotal + (chargesubscription ? subscriptionCostLookup[subsType]: 0.0))
      });
      itemId = itemId + 1;
      tempList.push({
          id: itemId,
          price: translate(props.language,'PST') + ' (9.975%)',
          // total: pst * (weeklyTotal + weeklyNoSubscribeTotal  + (chargesubscription ? bi_monthly_subscription_cost: 0.0))
          total: pst * (weeklyTotal + weeklyNoSubscribeTotal  + (chargesubscription ? subscriptionCostLookup[subsType]: 0.0))
      });
      itemId = itemId + 1;
      tempList.push({
          id: itemId,
          price: translate(props.language,'Total'),        
          // total: (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? bi_monthly_subscription_cost: 0.0)) + gst * (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? bi_monthly_subscription_cost: 0.0)) + pst * (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? bi_monthly_subscription_cost: 0.0))          
          total: (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? subscriptionCostLookup[subsType]: 0.0)) + gst * (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? subscriptionCostLookup[subsType]: 0.0)) + pst * (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? subscriptionCostLookup[subsType]: 0.0))          
      });


      // this has everything - weekly total, weeklyNoSubscribeTotal (5.00/day), and bi-monthly subscription
      // setAmountDue(parseFloat(Number(
      //   (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? bi_monthly_subscription_cost: 0.0)) + 
      //       gst * (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? bi_monthly_subscription_cost: 0.0)) + 
      //       pst * (weeklyTotal +  weeklyNoSubscribeTotal + (chargesubscription ? bi_monthly_subscription_cost: 0.0))
      // ).toFixed(2)));
      if (subsType === 'None' || subsType === 'None5') {
          setAmountDue(parseFloat(Number(
            (weeklyTotal +  weeklyNoSubscribeTotal ) + 
                gst * (weeklyTotal +  weeklyNoSubscribeTotal ) + 
                pst * (weeklyTotal +  weeklyNoSubscribeTotal )
          ).toFixed(2)));
          setSubscriptionStatement(translate(props.language, 'Non-member fee (cooking & dishwashing)'));
      } else if (subsType === "Free") {
        setAmountDue(parseFloat(Number(
          0.0
        ).toFixed(2)));
        setSubscriptionStatement(translate(props.language, 'Free'));
      } else if ( subsType === 'Individual' || subsType === 'Family' || subsType === 'Individual 4 Weeks' || subsType === 'Family 4 Weeks'  || subsType === 'Free Subscription' ) {
        // setAmountDue(parseFloat(Number(
        //   (weeklyTotal +  (chargesubscription ? bi_monthly_subscription_cost: 0.0)) + 
        //       gst * (weeklyTotal +  (chargesubscription ? bi_monthly_subscription_cost: 0.0)) + 
        //       pst * (weeklyTotal +  (chargesubscription ? bi_monthly_subscription_cost: 0.0))
        // ).toFixed(2)));
        setAmountDue(parseFloat(Number(
          (weeklyTotal +  (chargesubscription ? subscriptionCostLookup[subsType]: 0.0)) + 
              gst * (weeklyTotal +  (chargesubscription ? subscriptionCostLookup[subsType]: 0.0)) + 
              pst * (weeklyTotal +  (chargesubscription ? subscriptionCostLookup[subsType]: 0.0))
        ).toFixed(2)));


        if (subsType === 'Individual') {
          setSubscriptionStatement(translate(props.language, 'Individual'));
        };
        if (subsType === 'Family') {
          setSubscriptionStatement(translate(props.language, 'Family'));
        };
        if (subsType === 'Individual 4 Weeks') {
          setSubscriptionStatement(translate(props.language, 'Individual 4 Weeks'));
        };
        if (subsType === 'Family 4 Weeks') {
          setSubscriptionStatement(translate(props.language, 'Family 4 Weeks'));
        };
        if (subsType === 'Free Subscription') {
          setSubscriptionStatement(translate(props.language, 'Free Subscription'));
        };


      } else if (subsType === 'CmpPaysSub') {
        setAmountDue(parseFloat(Number(
          (weeklyTotal ) + 
              gst * (weeklyTotal ) + 
              pst * (weeklyTotal )
        ).toFixed(2)));
        setSubscriptionStatement(translate(props.language, 'Company (subscription paid by ') + compType + ')');
      } else if (subsType === 'CmpPaysAll') {
        setAmountDue(parseFloat(Number(0.0).toFixed(2)));
        setSubscriptionStatement(translate(props.language, 'Company (food and subscription paid by ')  + compType + ')');
      } else if (subsType === 'MngPaysSub') {
        // setAmountDue(parseFloat(Number(
        //   (weeklyTotal ) + 
        //       gst * (weeklyTotal ) + 
        //       pst * (weeklyTotal )
        // ).toFixed(2)));

        setSubscriptionStatement(translate(props.language, 'Company (subscription paid by ') + compType + ')');

          let sDate = new moment(selectedDate).startOf('week').add(1,'days');
          let subDate = sDate.toString().split(" ");
          let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
          var testCompOrders = await YPCGetAxiosAlt(xget, setXGet, 'getXWeeklyCompanyOrdersURL', dateString);

          if (testCompOrders['status'] === 'good') {
            setAllEmployees(testCompOrders['final_orders'])
            setAmountDue(parseFloat(Number(
              testCompOrders['grand_grand_total']
            ).toFixed(2)));
            setHideSummary(true);
          };

          // console.log('BOBOBOBOBOBOBO', testCompOrders)


      } else if (subsType === 'MngPaysAll') {
        // setAmountDue(parseFloat(Number(0.0).toFixed(2)));
        setSubscriptionStatement(translate(props.language, 'Company (food and subscription paid by ')  + compType + ')');

          let sDate = new moment(selectedDate).startOf('week').add(1,'days');
          let subDate = sDate.toString().split(" ");
          let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
          var testCompOrders = await YPCGetAxiosAlt(xget, setXGet, 'getXWeeklyCompanyOrdersURL', dateString);

          if (testCompOrders['status'] === 'good') {
            setAllEmployees(testCompOrders['final_orders'])
            setAmountDue(parseFloat(Number(
              testCompOrders['grand_grand_total']
            ).toFixed(2)));
            setHideSummary(true);

          };

          // console.log('BOBOBOBOBOBOBO', testCompOrders)
      };


      giantOrderList.push(tempList);
      combinedGiantOrderList.push(...tempList);



    setsummaryArrayList(giantOrderList);

    // setCombinedSummaryList(combinedGiantOrderList)

  };

  const allEmployeeColumns = [
    {
      field: 'username',
      headerName: 'Employee',
      width: 140,
      sortable: false,
    },
    {
      field: 'Mon',
      headerName: dateDisplay[0],
      width: 120,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Tue',
      headerName: dateDisplay[1],
      width: 120,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Wed',
      headerName: dateDisplay[2],
      width: 120,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Thu',
      headerName: dateDisplay[3],
      width: 120,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Fri',
      headerName: dateDisplay[4],
      width: 120,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Sat',
      headerName: dateDisplay[5],
      width: 120,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'Sun',
      headerName: dateDisplay[6],
      width: 120,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'subscription_cost',
      headerName: 'Subscription',
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'total',
      headerName: 'Sub-Total',
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
      field: 'grand_total',
      headerName: 'Total (w/Taxes)',
      width: 140,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else if (params.value === 0.0) {
          return '';
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },

  ];

  function summaryTable(index) {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          // rows={summaryList}
          rows={summaryArrayList[index]}
          columns={summaryColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  function finalTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          // rows={summaryList}
          rows={summaryArrayList[7]}
          columns={finalColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };


  async function processToken() {
    setDisplayPaymentDialog1(true)
    if(parseInt(amountDue * 100) !== 0) {
        let sendData = {
          // username: 'irv.lustigman', // should be props.userName ?
          username: props.userName,
          datestring: billingDate,
          result: cardToken,
          payment_amount: parseInt(amountDue * 100)
        };


        let result = await YPCPutAxios2Alt(setXPost, 'createXPaymentURL', sendData, '');
        console.log('GGGGGGGGGGGG', result);     
        if (result['success']) {

          // this is where you should send payment made email message

          // combinedLists includes french and english for translation
          // let newSendData = {
          //   username: props.userName,
          //   datestring: dateString,
          //   order: JSON.stringify(newCombinedLists)
          // };

          // send the subtotal, pst, gst, total and user name. Also send dateArray 0 and 4, also summaryArrayList[5]

          let newSendData = {
            datestring: billingDate,
            username: props.userName,
            summary: summaryArrayList[7],
            date0: datesArray[0],
            date1: datesArray[6]
          }
          YPCPutAxios2Alt(setXPost, 'sendXKitchinEmailPaidOrderURL', newSendData, '');
          setXNull(null);


          setPaymentDialogMsgDone(translate(props.language,'Payment succeeded'))
        } else {
          setPaymentDialogMsgDone(translate(props.language,'Payment failed, contact YPC'))
        }
        setDisplayPaymentDialog1(false)
        setDisplayPaymentDialogDone(true);
        
        await  getEverything();
      };

  };

  async function start() {
 
    // sandbox
    const payments = await Square.payments('sandbox-sq0idb-7jfQNXPv5lSWXpH_apgysQ', 'L4W9Z7A1VRDD8');
    // production
    // const payments = await Square.payments('sq0idp-h0_bpoBih0VdP5cXQhB3qA', 'LRBMX0VM6XSCX');

    await payments.setLocale(translate(props.language,'en-CA'))

    const card = await payments.card({
      style: {
        '.input-container.is-focus': {
          borderColor: '#78cbff'
        },
        '.message-text.is-error': {
          color: '#BF0020'
        }
      }
    });


    await card.attach('#card-container');


    document.querySelector('#card-button').addEventListener('click', async () => {
      setPayButtonDisable(true);
      try {

        const result = await card.tokenize();

        console.log('XCXCXCXC',result);
        // TODO: use result.token as source_id in /v2/payments API call

        console.log('PAPAPAP',amountDue);

        if (result['status'] === 'OK') {

          setCardToken(result);

          // let sendData = {
          //   username: 'irv.lustigman', // should be props.userName ?
          //   result: result,
          //   payment_amount: parseInt(amountDue * 100)
          // };


          // let test = await YPCPutAxios2Alt(setXPost, 'createXPaymentURL', sendData, '');
          // console.log('GGGGGGGGGGGG', test);          
        };
      } catch (ex) {
        console.error(ex);
      };
      setPayButtonDisable(false);
    });

  };


  function displayCard() {
    if (parseFloat(amountDue) !== 0.0 && billPaymentDue && showBillingDate) {
      return "inline"
    } else {
      return "none"
    }

  }

  function paymentNotDue() {
    if (parseFloat(amountDue) !== 0.0 && billPaymentDue && showBillingDate) {
      return false;
    } else {
      return true;
    };
  };


  // gen dialog

  const handleGenDialog = () => {
    setDisplayPaymentDialogDone(false);
    setPaymentDialogMsgDone("");
  }

  function paymentDialogDone() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {paymentDialogMsgDone}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };

  function paymentDialog1() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate(props.language,'Processing payment')}
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>

    );
  };

  

    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>
                  {displayPaymentDialogDone ? paymentDialogDone() : null}
                  {displayPaymentDialog1 ? paymentDialog1() : null}
                  <Typography component="h4" variant="h4">
                      {translate(props.language,'Weekly Billings')}
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>

          <Grid item xs={12}>

                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              {/* <KeyboardDatePicker */}
                              <DatePicker
                                  orientation={showText && "landscape"}                                  
                                  variant="static"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date picker dialog"
                                  // format="MM/dd/yyyy"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>
                          <p><small>{translate(props.language,"Choose any day in the week to get full week's order billings")}</small></p>
          </Grid>


      {/* Payment stuff */}

              {/* <Grid item xs={12} display={{ xs: displayCard() }} component={Box} > */}
              <Grid item xs={12}>  
                  <Grid item xs={6}>

                      {paymentNotDue() ?
                        <Typography component="h5" variant="h5">
                            {translate(props.language,'No payment due for the Week of')} {datesArray[0]} {translate(props.language,'to')} {datesArray[6]}
                        </Typography>
                        : null
                      }

                      {paymentNotDue() && amountDue !== 0.0 ?
                                <Typography component="h6" variant="h6">
                                    {translate(props.language,'Payment will be due following week')}
                                </Typography>
                        : null
                      }

                      <div className={classes.sendleft} style={{ display:displayCard() }}>
                          <Typography component="h5" variant="h5">
                          {translate(props.language,'Payment due for the Week of')} {datesArray[0]} {translate(props.language,'to')} {datesArray[6]}
                          </Typography>
                          <br />
                          <Typography component="h5" variant="h5">
                          ${amountDue.toFixed(2)}
                          </Typography>
                          <br />
                      </div>
                      <div style={{clear: "both"}}></div>

                      {hidePayment ?
                            <React.Fragment>
                                <div  style={{ display:"none" }}><p><small>{translate(props.language,"Secured by Square payments.")}</small></p></div>

                                <div id="card-container" style={{ display:"none" }}></div>

                                <div style={{ display:"none" }}>
                                  <Button
                                      id="card-button"
                                      variant="outlined"
                                      size="small"
                                      className={classes.button}
                                      // onClick={handlePayButton}
                                      disabled={payButtonDisable}
                                      aria-label="pay for it"
                                      style={{backgroundColor: '#78cbff', color: 'white'}}
                                >
                                      {translate(props.language,'Pay')} ${amountDue}
                                  </Button>                      
                                </div>
                            </React.Fragment>
                          :
                            <React.Fragment>
                                <div  style={{ display:displayCard() }}><p><small>{translate(props.language,"Secured by Square payments.")}</small></p></div>

                                <div id="card-container" style={{ display:displayCard() }}></div>

                                <div style={{ display:displayCard() }}>
                                  <Button
                                      id="card-button"
                                      variant="outlined"
                                      size="small"
                                      className={classes.button}
                                      // onClick={handlePayButton}
                                      disabled={payButtonDisable}
                                      aria-label="pay for it"
                                      style={{backgroundColor: '#78cbff', color: 'white'}}
                                >
                                      {translate(props.language,'Pay')} ${amountDue}
                                  </Button>                      
                                </div>
                            </React.Fragment>

                      }  {/* End of hidePayment */}
 

                  </Grid>

                  
                  <Grid item xs={6}>
                  </Grid>
            </Grid>





          {/* Start of Check Balances */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h6" variant="h6">
                    {translate(props.language,'Subscription type')} - {subscriptionStatement}
                  </Typography>
              </div>
              {/* <div style={{clear: "both"}}></div> */}
          </Grid>

          {/* All Users */}

            { subscriptionType === 'MngPaysAll' || subscriptionType === 'MngPaysSub' ?
              <React.Fragment>
                    <Grid item xs={12}>
                                  <br />
                                  <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Summary All Employees'} &#8198;</div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.sendleft}>
                            <Typography component="h5" variant="h5">
                            {translate(props.language,'Summary for Week of')} {datesArray[0]} {translate(props.language,'to')} {datesArray[6]}
                            </Typography>
                        </div>
                        <div style={{clear: "both"}}></div>
                    </Grid>
                    <Grid item xs={12}>
                        { allEmployees.length !== 0 ?     
                              <Grid item xs={12} >

                                  <div style={{ width: '100%' }} >
                                    <DataGrid
                                    //   isCellEditable={handleEditable}
                                      className={classes.gridcell}
                                      hideFooter={true}
                                      hideFooterPagination={true}
                                      density="compact"
                                    //   rows={inventoryList}
                                      rows={allEmployees}
                                      columns={allEmployeeColumns}
                                      // onCellEditCommit={handleCellEditCommit}
                                      disableColumnSelector={true}
                                      disableColumnFilter={true}
                                    //   onRowClick={handleStarterSelect}
                                      autoHeight={true}
                                    />
                                  </div>

                              </Grid>
                        : <Skeleton variant="rectangular" width={"100%"} height={120} /> }
                    </Grid>

              </React.Fragment>

            :
            null
            }

          {/* Summary */}
          <Grid item xs={12}>
                        {/* <br /> */}
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Summary')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Summary for Week of')} {datesArray[0]} {translate(props.language,'to')} {datesArray[6]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={8} >
                        {finalTable()}

                    </Grid>
              : null }
          </Grid>


          {/* Monday */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Daily')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Monday')} {datesArray[0]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={12} >
                        {summaryTable(0)}
                    </Grid>
              : null }
          </Grid>

          {/* Tuesday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Tuesday')} {datesArray[1]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={12} >
                        {summaryTable(1)}

                    </Grid>
              : null }
          </Grid>

          {/* Wednesday */}
           <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Wednesday')} {datesArray[2]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={12} >
                        {summaryTable(2)}

                    </Grid>
              : null }
          </Grid>

          {/* Thursday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Thursday')} {datesArray[3]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={12} >
                        {summaryTable(3)}

                    </Grid>
              : null }
          </Grid>


          {/* Friday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Friday')} {datesArray[4]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={12} >
                        {summaryTable(4)}

                    </Grid>
              : null }
          </Grid>





          {/* Saturday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Saturday')} {datesArray[5]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={12} >
                        {summaryTable(5)}

                    </Grid>
              : null }
          </Grid>

          {/* Sunday */}
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Sunday')} {datesArray[6]}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={12} >
                        {summaryTable(6)}

                    </Grid>
              : null }
          </Grid>

          {/* End of Check Balances */}

          {/* Test Section */}

          {/* <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Test')}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                    <Grid item xs={8} >
                        {combinedSummaryTable(3)}
                    </Grid>
              : null }
          </Grid> */}

          {/* End of Test Section */}

          <div><p></p></div>

          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


