import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import * as actions from '../store/authActions';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import translate from "./YPCTranslate";
import YPCPutAxios2 from './YPCPutAxios2';

function Copyright(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {translate(props.language,'Copyright') + ' © '}
      <Link color="inherit" href="https://ypc-technologies/">
        YPC Technologies
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  success: {
    color: theme.palette.success.main,
  }

}));



// export default function Login() {  // should this be changed?
function Login(props) {
  const classes = useStyles();


  const [username, setuserName] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [xnull, setXNull] = React.useState(null);
  const [badUserName, setBadUserName] = React.useState(false);
  const [badEmail, setBadEmail] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);

  const handleFormFieldChange = (event) => {
    switch (event.target.id) {
      case 'username': setuserName(event.target.value); break;
      case 'password': setPassword(event.target.value); break;
      default: return null;
    }

  };


  async function handleResetPassword () {
    setBadEmail(false);
    setBadUserName(false);
    setEmailSent(false);
    let newSendData = {
      username: username
    };
    // console.log('XAXAXAXAXAXAXAXA', newSendData);
    let info = await YPCPutAxios2(setXNull, 'sendXKitchinEmailResetPasswordURL', newSendData, '');
    setXNull(null);
    console.log('XPXPXPXPXXP', info);
    if (!info['userexists']) {
      setBadUserName(true);
    } else if (!info['emailexists']) {
      setBadEmail(true);
    } else if (info['userexists'] && info['emailexists']) {
      setEmailSent(true);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    props.onAuth(username, password);
  }

  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  React.useEffect(() => {
    if (props.isAuthenticated) { history.replace(from) };
  });

  // React.useEffect(() => {
  //   console.log('EEEEEEEEEEEE', props.badUserName, props.badPassword);
  //   setBadUserName(props.badUserName);
  // }, [props.badUserName, props.badPassword]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>

        { emailSent ?
              <Typography variant="button" className={classes.success} gutterBottom>
                {translate(props.language, 'Password reset instructions have been sent to your email!')}
              </Typography>
              : null
        }


        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {translate(props.language, 'Sign In')}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <MuiThemeProvider theme={theme}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label={translate(props.language, "User Name")}
              name="username"
              autoComplete="name"
              autoFocus
              onChange={handleFormFieldChange}
              color='secondary'
            />
          </MuiThemeProvider>

          { props.badUserName ?
              <Typography color='secondary'>
                {translate(props.language, 'Couldn’t find an account associated with this user name. Please try again.')}
              </Typography>
              : null
          }



          <MuiThemeProvider theme={theme}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={translate(props.language, "Password")}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleFormFieldChange}
              color='secondary'
            />
          </MuiThemeProvider>

          { props.badPassword ?
              <Typography color='secondary'>
                {translate(props.language, 'That is not the right password. Please try again.')}
              </Typography>
              : null
          }

          <Button 
            variant="text"
            style={{color: '#78cbff'}}
            onClick={handleResetPassword}
          >
            {translate(props.language, 'Forgot Password?')}
          </Button>


          { badUserName ?
              <Typography color='secondary'>
                {translate(props.language, 'Couldn’t find an account associated with this user name. Please try again.')}
              </Typography>
              : null
          }

          { badEmail ?
              <Typography color='secondary'>
                {translate(props.language, 'No email associated with this user name. Please contact Jasper Cooks.')}
              </Typography>
              : null
          }


          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{backgroundColor: '#78cbff'}}
          >
            {translate(props.language, "Sign In")}
          </Button>
        </form>

      </div>
      {/* <Box mt={8}>
        <Copyright language={props.language}/>
      </Box> */}
    </Container>
  );
}

const mapDispathToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }
}

export default connect(null, mapDispathToProps)(Login);