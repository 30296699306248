import axios from "axios";
import React from "react";
import config from './YPCconfig.js';


export default function YPCPostAxios2Alt(setXPost, url, content, id) {

    let baseURL = '';

    if (url === 'postProxyChangeIngredientStockByNameURL') {
        baseURL = config.postProxyChangeIngredientStockByNameURL + id;
    };
    if (url === 'postProxyBatchChangeIngredientStockByNameURL') {
        baseURL = config.postProxyBatchChangeIngredientStockByNameURL;
    };
    if (url === 'postProxyaddNewContainerURL') {
        baseURL = config.postProxyaddNewContainerURL;
    };
    if (url === 'postProxycreateMEPEmptyContainersURL') {
        baseURL = config.postProxycreateMEPEmptyContainersURL;
    };
    if (url === 'postProxyMEPShelfURL') {
        baseURL = config.postProxyMEPShelfURL + id;
    };
    if (url === 'postProxyPlaceDishOrdersURL') {
        baseURL = config.postProxyPlaceDishOrdersURL;
    };
    if (url === 'postProxyMEPGetPrepInstructionsURL') {
        baseURL = config.postProxyMEPGetPrepInstructionsURL + id;
    };    

    return new Promise(function (resolve, reject) {

        let token = localStorage.getItem('token');
        let userName = localStorage.getItem('userName')

        axios
        .put(baseURL, 
            // {'content' : content} 

            {'content': content, 'token': token, 'username': userName}
        )
        .then((response) => {
            console.log('PPPPostOrderAxios', response.data.data);
                if (response.status == 200) {
                    setXPost('Saved to DB');
                    // setXPost(response.data.data);
                }
                else {
                    setXPost('Error saving to DB!');
                    // setXPost('Error');
                }
            var result = response.data;
            resolve(result);

        }).catch(error => {
            console.log('EEEEEEEEE',error);
            if ('response' in error) {
                if (error.response === undefined) {
                    setXPost('Error.  Contact YPC Help Line\n');
                } else if ('data' in error.response) {
                    if ('detail' in error.response.data) {
                        setXPost('Error saving to DB!\n ' + error.response.data.detail);
                    } else {
                        setXPost('Error saving to DB!');
                    }
                } else {
                    setXPost('Error saving to DB!');
                }
            } else {
                setXPost('Error saving to DB!');
            };
            // setXPost('Error');
            reject(error);
        });

    });
}

