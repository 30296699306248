import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import translate from "./YPCTranslate";

function Footer(props) {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {translate(props.language,'Copyright') + ' © '}
        <Link color="inherit" href="https://ypc-techologies.com/">
          YPC Technologies
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
export default Footer