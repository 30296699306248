import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import {makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';


import { MuiThemeProvider, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, GridColumnHeaderSeparator } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';

import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

import peanutImage from './images/peanut.svg';
import glutenfreeImage from './images/glutenfree.svg';
import vegetarianImage from './images/tea-leaf.svg';
import veganImage from './images/vegan.svg'
import babyfriendlyImage from './images/baby.svg';
import spicelevel1Image from './images/s1.svg';
import spicelevel0Image from './images/s0.svg';
import lunchboxImage from './images/lunchbox.svg';
import sugarfreeImage from './images/sugarfree.svg';

import FALLBACK_IMAGE from './images/tumyum.png';
import SUB_FALLBACK_IMAGE from './images/tumyum.png';

// import FALLBACK_IMAGE_STARTER from './images/Starters_default.png';
// import FALLBACK_IMAGE_MAIN from './images/Mains_default.png';
// import FALLBACK_IMAGE_DESSERT from './images/Desserts_default.png';
// import FALLBACK_IMAGE_DRINK from './images/Drinks_default.png';
// import FALLBACK_IMAGE_FRUIT from './images/Fruits_default.png';

import FALLBACK_IMAGE_STARTER from './images/info.png';
import FALLBACK_IMAGE_MAIN from './images/info.png';
import FALLBACK_IMAGE_DESSERT from './images/info.png';
import FALLBACK_IMAGE_DRINK from './images/info.png';
import FALLBACK_IMAGE_FRUIT from './images/info.png';

import SUB_FALLBACK_IMAGE_STARTER from './images/info_4to1.png';
import SUB_FALLBACK_IMAGE_MAIN from './images/info_4to1.png';
import SUB_FALLBACK_IMAGE_DESSERT from './images/info_4to1.png';
import SUB_FALLBACK_IMAGE_DRINK from './images/info_4to1.png';
import SUB_FALLBACK_IMAGE_FRUIT from './images/info_4to1.png';

import { resolveComponentProps } from '@mui/base';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -12,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));


const theme = createTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({

  container: {
      maxWidth: "100%", //"75%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  container_thing: {
    maxWidth: "95%",
    marginTop: "2vh",
    marginBottom: "10vh",
    borderRadius: '5px',
    // backgroundColor: theme.palette.action.disabledBackground,
    backgroundColor: '#503c30',
    height: 'auto'// 1600,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      border: "none",
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
          borderBottom: 'none',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
      },

      '&.MuiDataGrid-root .MuiDataGrid-cell': {borderBottom: 'none !important'},
      '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {borderBottom: 'none !important'},
      '&.MuiDataGrid-root .MuiDataGrid-rowSeparator': {
        visibility: 'hidden',
      },


      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },
      '& .super-app.borderAddRight': {
        // marginLeft: "30px",
        // paddingLeft: "30px",
        borderRight: 'solid',
        borderWidth: '5px',
        borderColor: 'rgba(255, 255, 255, 1.0)',
      },
      '& .super-app.borderAddLeft': {
        // marginLeft: "30px",
        // paddingLeft: "30px",
        borderLeft: 'solid',
        borderWidth: '5px',
        borderColor: 'rgba(255, 255, 255, 1.0)',
      },
    },
    toggleselected: {
      '&.MuiToggleButton-root.Mui-selected,   &.MuiToggleButton-root.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#78cbff',
      },
      '&.MuiToggleButton-root:hover': {
        color: '#78cbff',
      },
    },
    sliderman: {

      color: '#78cbff',
      height: 8,
      '& .MuiSlider-track': {
        border: 'none',
      },
      '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
          boxShadow: 'inherit',
        },
        '&:before': {
          display: 'none',
        },
      },
      '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#78cbff',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
          transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
          transform: 'rotate(45deg)',
        },
      },
      '& .MuiSlider-markLabel': {
        transform: 
          'rotate(-90deg)'
        }
    },

}));


const xtranslate = new XTranslate();

const initSelectedDinnerTime = () => {
  let test = moment((new Date()));
  test.set('minute', 0);
  test.set('hour', 0);
  return test;
};

const initSelectedLunchTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 0);
  return test2;
};


const fullLunchTimeList = ['11:30', '11:45' , '12:00', '12:15', '12:30', '12:45','13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30'];
const fullDinnerTimeList = ['17:00', '17:15', '17:30', '17:45','18:00'];

const maxTimeSlots = {'11:30' : 9, '11:45'  : 9, '12:00' : 9, '12:15' : 9, '12:30' : 9, '12:45' : 9, '13:00' : 9, '13:15' : 9, '13:30' : 9,'13:45' : 9, '14:00' : 9, '14:15' : 9, '14:30' : 9,
                      '17:00' : 9,'17:15' : 9, '17:30' : 9, '17:45' : 9,'18:00' : 9 };

const defaultLunchTimeList = ['11:30', '11:45' , '12:00', '12:15', '12:30', '12:45','13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30'];
const defaultDinnerTimeList = ['17:00', '17:15', '17:30', '17:45','18:00'];

const noMsg = 'NOMSG';

// let currentQuantity = null;

export default function YPCOrderMealCust(props) {

  const classes = useStyles();

  const mediatheme = useTheme();
  const showText = useMediaQuery(mediatheme.breakpoints.up('sm'));
  // const theme = useTheme();
  
  const xsColumn = useMediaQuery(mediatheme.breakpoints.only('xs'));
  const smColumn = useMediaQuery(mediatheme.breakpoints.only('sm'));
  const mdColumn = useMediaQuery(mediatheme.breakpoints.only('md'));
  const lgColumn = useMediaQuery(mediatheme.breakpoints.only('lg'));

  const [menuLoaded, setMenuLoaded] = React.useState(false);
  const [menuDB, setMenuDB] = React.useState(null);

  const [starterOrderList, setStarterOrderList] = React.useState([]);
  const [selectStarterDelete, setSelectStarterDelete] = React.useState("");

  const [dessertsOrderList, setDessertsOrderList] = React.useState([]);
  const [selectDessertsDelete, setSelectDessertsDelete] = React.useState("");

  const [fruitsOrderList, setFruitsOrderList] = React.useState([]);
  const [selectFruitsDelete, setSelectFruitsDelete] = React.useState("");

  const [drinksOrderList, setDrinksOrderList] = React.useState([]);
  const [selectDrinksDelete, setSelectDrinksDelete] = React.useState("");

  const [mainsOrderList, setMainsOrderList] = React.useState([]);
  const [selectMainsDelete, setSelectMainsDelete] = React.useState("");
  
  const [selectOrderEdit, setSelectOrderEdit] = React.useState("");

  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);
  const [xnull, setXNull] = React.useState(null);

  const [lunchBoxChecked, setLunchBoxChecked] = React.useState({'test': 'test2'});
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = React.useState({'test': 'test2'});

  const [lunchSelectDict, setLunchSelectDict] = React.useState({'test': 'test2'});
  const [dinnerSelectDict, setDinnerSelectDict] = React.useState({'test': 'test2'});
  const [realsideSelectDict, setRealsideSelectDict] = React.useState({'test': 'test2'});

  const [summaryList, setsummaryList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [selectedTimeLunch, setSelectedTimeLunch] = React.useState(initSelectedLunchTime());
  const [selectedTimeDinner, setSelectedTimeDinner] = React.useState(initSelectedDinnerTime());

  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);
  // const [noneSubsriberCost, setNoneSubscriberCost] = React.useState(5.0);
  const noneSubscriberCostDict = {'None' : 9.00, 'None5': 5.00};

  const [locale, setLocale] = React.useState();

  const [lunchBlocked, setLunchBlocked] = React.useState(false);
  const [dinnerBlocked, setDinnerBlocked] = React.useState(false);

  const [cutoffMsgOpen, setCutoffMsgOpen] = React.useState(false);

  const [bothTimeNotSetMsgOpen, setBothTimeNotSetMsgOpen] = React.useState(false);
  const [lunchTimeNotSetMsgOpen, setLunchTimeNotSetMsgOpen] = React.useState(false);
  const [dinnerTimeNotSetMsgOpen, setDinnerTimeNotSetMsgOpen] = React.useState(false);
  const [lunchTimeSlotMsg, setLunchTimeSlotMsg] = React.useState(false);
  const [dinnerTimeSlotMsg, setDinnerTimeSlotMsg] = React.useState(false);

  const [subscriptionType, setSubscriptionType] = React.useState('None');

  const [menuPresent, setMenuPresent] = React.useState(false);


  const [choiceLunchOrDinner, setChoiceLunchOrDinner] = React.useState('Lunch');

  const [choiceLunchTime, setChoiceLunchTime] = React.useState();


  const [xvalue, setXvalue] = React.useState(new Date());

  // const [bookedTimeSlots, setBookedTimeSlots] = React.useState(null);

  const [daysBack, setDaysBack] = React.useState('-3');
  const [hourTime, setHourTime] = React.useState('14');
  const [minuteTime, setMinuteTime] = React.useState('0');

  const [daysBackDinner, setDaysBackDinner] = React.useState('-3');
  const [hourTimeDinner, setHourTimeDinner] = React.useState('14');
  const [minuteTimeDinner, setMinuteTimeDinner] = React.useState('0');

  // const [lunchTimeList, setLunchTimeList] = React.useState(['11:30', '11:45' , '12:00', '12:15', '12:30', '12:45','13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30']);
  // const [dinnerTimeList, setDinnerTimeList] = React.useState(['17:00', '17:15', '17:30', '17:45','18:00']);

  // const [lunchTimeList, setLunchTimeList] = React.useState(defaultLunchTimeList);
  // const [dinnerTimeList, setDinnerTimeList] = React.useState(defaultDinnerTimeList);
  const [lunchTimeList, setLunchTimeList] = React.useState([]);
  const [dinnerTimeList, setDinnerTimeList] = React.useState([]);

  const [orderWeights, setOrderWeights] = React.useState(null);
  const [capDetails, setCapDetails] = React.useState(null);

  const [chefsChoice, setChefsChoice] = React.useState(null);

  const [dialogScratchValue, setDialogScratchValue] = React.useState(null);
  
  const [noSlotSpaceDialog, setNoSlotSpaceDialog] = React.useState(null);
  const [noSaveSpaceDialog, setNoSaveSpaceDialog] = React.useState(null);

  const [orderResponseMsg, setOrderResponseMsg] = React.useState(noMsg);

  const [currentQty, setCurrentQty] = React.useState(0);
  const [currentQtyBusy, setCurrentQtyBusy] = React.useState(false);


  const [capHitMessage, setCapHitMessage] = React.useState(false);

  // const [value, setValue] = React.useState(new Date("2021-10-26 13:00"));

  // React.useEffect(() => {
  //   if (!menuLoaded) {
  //     handleLoadDB();
  //   }
  // }, [menuLoaded]);


  React.useEffect(() => {
    calculatesummaryTable();
  }, [mainsOrderList, starterOrderList, dessertsOrderList, drinksOrderList, fruitsOrderList, props.language]);


  React.useEffect(() => {
    getMenu();
  }, [selectedDate]);

  // React.useEffect(() => {
  //   moment.locale('en-ca', { week: { dow: 1 } });
  //   moment.locale('fr-ca', { week: { dow: 1 } })
  // }, [])


  // const handleLoadDB = () => {
  //   YPCGetMenuDB(xget, setXGet).then(
  //     function(value) { setMenuDB(value);
  //       xtranslate.addLookup(value);
  //     }
  //   );
  //   setMenuLoaded(true);
  // };



  const imageExists = (path) =>
    new Promise(resolve => {
       const img = new Image();
       img.onload = () => resolve({path, status: 'ok'});
       img.onerror = () => resolve({path, status: 'error'});
       img.src = path;
   });



  async function checkForImage(recipe_name) {
    let path = process.env.PUBLIC_URL + "/images/recipes/" + recipe_name + '.png';
    let result = await imageExists(path);
    if (result.status == 'ok') {
      return path;
    };
    path = process.env.PUBLIC_URL + "/images/recipes/" + recipe_name + '.jpg';
    result = await imageExists(path);
    if (result.status == 'ok') {
      return path;
    };

    return "bad";

  };


  
  async function getMenu() {

    // small test 

    // let thistest = await checkForImage("Butter Chicken - Signature");
    // console.log('AAAAAAAAAAAAAAA', thistest);



    
    // get subscription type
    // let subType = 'None';
    // if (parseInt(selectedDate.format('YY')) > 21) {
    //   subType = await YPCGetAxiosAlt(xget, setXGet, 'getXSubsciptionTypeURL', '');

      // console.log('LQLQLQLQLQL', subType);
    // };
    // setSubscriptionType(subType);

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    let subType = 'None';
    if (parseInt(selectedDate.format('YY')) > 21) {
      subType = await YPCGetAxiosAlt(xget, setXGet, 'getXDateSubscriptionTypeURL', dateString);
      console.log('IZIZIZIZIZI', subType);
    };
    setSubscriptionType(subType['subs_type'])


    // get blocked values
    var blocked_structure = await YPCGetAxiosAlt(xget, setXGet, 'getXMiscBlockedURL', [dateString, 'blocked']);
    setDaysBack(blocked_structure['daysBack']);
    setHourTime(blocked_structure['hourTime']);
    setMinuteTime(blocked_structure['minuteTime']);

    if (blocked_structure.hasOwnProperty('daysBackDinner')) {
      setDaysBackDinner(blocked_structure['daysBackDinner']);
      setHourTimeDinner(blocked_structure['hourTimeDinner']);
      setMinuteTimeDinner(blocked_structure['minuteTimeDinner']);
    } else {
      setDaysBackDinner(blocked_structure['daysBack']);
      setHourTimeDinner(blocked_structure['hourTime']);
      setMinuteTimeDinner(blocked_structure['minuteTime']);
    };

    var menu_structure = await YPCGetAxiosAlt(xget, setXGet, 'getXMenuURL', dateString);
    let result = [];
    if (menu_structure.length !== 0) {
      console.log('ZMZMZMZMZMZM', JSON.parse(menu_structure[0].menu));
      result = JSON.parse(menu_structure[0].menu);
      setMenuPresent(true);
    } else {
      console.log('ZMZMZMZMZMZM', menu_structure);
      setMenuPresent(false);
    }


    // get available slots
    // var orderSlots = await YPCGetAxiosAlt(xget, setXGet, 'getXOrderSlotsURL', dateString);
    // let temp = []
    // fullLunchTimeList.map((slot) => {
    //   if (maxTimeSlots[slot] - orderSlots['time_slots'][slot] > 0) {
    //     temp.push(slot);
    //   };
    // })
    // console.log('PXPXPXPXPXXPX', temp);
    // setLunchTimeList(temp)

    // temp = []
    // fullDinnerTimeList.map((slot) => {
    //   if (maxTimeSlots[slot] - orderSlots['time_slots'][slot] > 0) {
    //     temp.push(slot);
    //   };
    // })
    // setDinnerTimeList(temp)


    // var result = await YPCGetAxios(xget, setXGet, 'conciergeMenuGetURL', dateString);  // old one



    let orderResult = [];
    let order = {};
    let dinnerHour = 0;
    let dinnerMinute = 0;
    let lunchHour = 0;
    let lunchMinute = 0;

    let orderExists = false;
    let savedSubscriptionType = 'None';

    var order_structure =  await YPCGetAxiosAlt(xget, setXGet, 'getXOrderURL', [dateString, props.userName]);
    if (order_structure.length !== 0) {
      // console.log('ZOZOZOZOZOZ', JSON.parse(order_structure[0].order));
      orderResult = JSON.parse(order_structure[0].order);
      order = orderResult.minimalList;
      dinnerHour = orderResult.dinnerTimeHour;
      dinnerMinute = orderResult.dinnerTimeMinute;
      lunchHour = orderResult.lunchTimeHour;
      lunchMinute = orderResult.lunchTimeMinute;

      // new
      if (orderResult.hasOwnProperty('subscriptionType')) {
        savedSubscriptionType = orderResult.subscriptionType;
        orderExists = true;
      }

    } else {
      // console.log('ZOZOZOZOZO', order_structure);
      order = {crap: {tests: 1}};
    }



    // check if lunch should be blocked
    let currentTime = moment(new Date());
    let timeTest = moment(selectedDate);
    let timeTestDinner = moment(selectedDate);
    // console.log('CCCCCCCCCCCCCC', selectedDate.toString(), timeTest.toString())

    // original time test - 3 hours before service start
    // timeTest.set('hour', 9);
    // timeTest.set('minute', 0);
    // setLunchBlocked(currentTime > timeTest);
    // timeTest.set('hour', 14);
    // timeTest.set('minute', 0);
    // setDinnerBlocked(currentTime > timeTest);

    // last version this was hour 12, days -1
    // timeTest.set('hour', 14);
    // timeTest.set('minute', 0);
    // timeTest.add(-3, 'days')

    // timeTest.set('hour', parseInt(hourTime));
    // timeTest.set('minute', parseInt(minuteTime));
    // timeTest.add(parseInt(daysBack), 'days')

    // console.log('CACACACACACAC', currentTime.toString(), blocked_structure.toString());

    timeTest.set('hour', parseInt(blocked_structure['hourTime']));
    timeTest.set('minute', parseInt(blocked_structure['minuteTime']));
    timeTest.add(parseInt(blocked_structure['daysBack']), 'days')

    if (blocked_structure.hasOwnProperty('daysBackDinner')) {
      timeTestDinner.set('hour', parseInt(blocked_structure['hourTimeDinner']));
      timeTestDinner.set('minute', parseInt(blocked_structure['minuteTimeDinner']));
      timeTestDinner.add(parseInt(blocked_structure['daysBackDinner']), 'days')    
    } else {
      timeTestDinner.set('hour', parseInt(blocked_structure['hourTime']));
      timeTestDinner.set('minute', parseInt(blocked_structure['minuteTime']));
      timeTestDinner.add(parseInt(blocked_structure['daysBack']), 'days')    
    }
    
    // console.log('CBCBCBCBCBCBCB', timeTest.toString())

    setLunchBlocked(currentTime > timeTest);
    setDinnerBlocked(currentTime > timeTestDinner);

    // if (currentTime > timeTest && orderExists) {
    //   setSubscriptionType(savedSubscriptionType);
    // } else {
    //   setSubscriptionType(subType);
    // };


    // set serve/delivery time
    let test = moment(selectedDate);
    test.set('minute', dinnerMinute);
    test.set('hour', dinnerHour);
    setSelectedTimeDinner(test);
    let selectedTestDinner = test;

    let test2 = moment(selectedDate);
    test2.set('minute', lunchMinute);
    test2.set('hour', lunchHour);
    setSelectedTimeLunch(test2);
    let selectedTestLunch = test2;


    // TTTTTTTTTTTTTTest Irv
    var tempOrderWeights = await YPCGetAxiosAlt(xget, setXGet, 'getXOrderSlotWeightsURL', dateString);
    console.log('PRPRPRPRPR', tempOrderWeights);
    setOrderWeights(tempOrderWeights);

    var cap_details = await YPCGetAxiosAlt(xget, setXGet, 'getXMenuOrderCapURL', dateString);
    if (cap_details['status'] === 'ok') {
      setCapDetails(cap_details)
      setChefsChoice(cap_details['chefs_choice_dict']);
    } else {
      setCapDetails(null);
      setChefsChoice(null)
    };
    console.log('CWCWCWCWCWC', cap_details);

    // lunchTimeList

    var timinglists = await YPCGetAxiosAlt(xget, setXGet, 'getXMiscTimeListsURL', [dateString, 'timelists']);

    // console.log('VWVWVWVWVWVWV', timinglists);

    // This section deals with companies 
    if ( 
            // (subType['subs_type'] === 'CmpPaysAll' || subType['subs_type'] === 'CmpPaysSub' || subType['subs_type'] === 'MngPaysAll' || subType['subs_type'] === 'MngPaysSub') && 
      subType['comp_type'] === 'Gameloft' || subType['comp_type'] === 'Schneider') {
        timinglists = {'lunchList' : ['12:00'], 'dinnerList' : []};
    } else if (
      subType['comp_type'] === 'TouchTunes' || subType['comp_type'] === 'TDBank' || subType['comp_type'] === 'GlobalPayment'  || subType['comp_type'] === 'GolderWSP') {
        timinglists = {'lunchList' : ['12:00', '12:30'], 'dinnerList' : []};
    };


    let tempLunchTimeList = [];
    let tempDinnerTimeList = [];

    // defaultLunchTimeList.map((lnchdinTime) => {
    timinglists.lunchList.map((lnchdinTime) => {
        if (lnchdinTime === selectedTestLunch.format("HH:mm")) {
          tempLunchTimeList.push(lnchdinTime);
        } else {
          if (tempOrderWeights['slot_order_seconds'][lnchdinTime] < tempOrderWeights['max_slot_seconds'][lnchdinTime]) {
            tempLunchTimeList.push(lnchdinTime);
          }
        }
    });

    // defaultDinnerTimeList.map((lnchdinTime) => {    
    timinglists.dinnerList.map((lnchdinTime) => {
      if (lnchdinTime === selectedTestDinner.format("HH:mm")) {
        tempDinnerTimeList.push(lnchdinTime);
      } else {
        if (tempOrderWeights['slot_order_seconds'][lnchdinTime] < tempOrderWeights['max_slot_seconds'][lnchdinTime]) {
          tempDinnerTimeList.push(lnchdinTime);
        }
      }
    });

    console.log('DTDTDTDTDTDT', tempLunchTimeList, tempDinnerTimeList)

    setLunchTimeList(tempLunchTimeList);
    setDinnerTimeList(tempDinnerTimeList);



    let translateList = [];

    if (result.length !== 0) {
        let mainsList = [];
        let starterList = [];
        let dessertsList = [];
        let fruitsList = [];
        let drinksList = [];
        let category = null;
        let tempValue = null;
        let tempLunch = {};
        let tempDinner = {};
        let tempLunchSel = {};
        let tempDinnerSel = {};
        let tempRealsideSel = {};
        result.map((value) => {

          translateList.push(value);

          tempValue = {
            category: value.category,
            dish: value.dish,
            id: value.id,
            name: value.name,
            price: value.price,
            side: value.side,
            uuid: value.uuid,
            lunchcount: value.uuid in order ? order[value.uuid].lunchcount : null,
            dinnercount:  value.uuid in order ? order[value.uuid].dinnercount : null,
            lunchboxcount:  value.uuid in order ? order[value.uuid].lunchboxcount : null,

            spicelevel: value.spicelevel,
            vegetarian: value.vegetarian,
            vegan: value.vegan,
            sugar_free: value.sugar_free,
            gluten_free: value.gluten_free,
            peanut_free: value.peanut_free,
            baby_friendly: value.baby_friendly,
            name_french: value.name_french
          };

          if (value.hasOwnProperty('apt_for_lunchbox')) {
            tempValue['apt_for_lunchbox'] = value.apt_for_lunchbox;
          } else {
            tempValue['apt_for_lunchbox'] = false;
          };

          if (value.hasOwnProperty('calories')) {
            tempValue['calories'] = value.calories;
          } else {
            tempValue['calories'] = null;
          }

          if (value.hasOwnProperty('ingredients_list')) {
            tempValue['ingredients_list'] = value.ingredients_list;
          } else {
            tempValue['ingredients_list'] = 'N/A';
          };


          if (value.lunchbox) {
            tempLunch[value.uuid] = true;
          };
          if (value.dinneronly) {
            tempDinner[value.uuid] = true;
          };


          if (value.lunchselected) {
            tempLunchSel[value.uuid] = true;
          };
          if (value.dinnerselected) {
            tempDinnerSel[value.uuid] = true;
          };
          if (value.realsideselected) {
            tempRealsideSel[value.uuid] = true;
          };


          if (!value.side) {
            category = value.category.slice();
          };
          if (category === 'Starter') {
              starterList.push(tempValue);
          } else if (category === 'Main') {
              mainsList.push(tempValue);
          } else if (category === 'Dessert') {
              dessertsList.push(tempValue);
          } else if (category === 'Drink') {
              drinksList.push(tempValue);
          } else if (category === 'Fruit') {
              fruitsList.push(tempValue)
          };

        });



        setLunchBoxChecked(tempLunch);
        setDinnerOnlyChecked(tempDinner);

        setLunchSelectDict(tempLunchSel);
        setDinnerSelectDict(tempDinnerSel);
        setRealsideSelectDict(tempRealsideSel);

        setMainsOrderList(mainsList);
        setStarterOrderList(starterList);
        setDessertsOrderList(dessertsList);
        setFruitsOrderList(fruitsList);
        setDrinksOrderList(drinksList);        

        xtranslate.addLookupNew(translateList);
    } else {
      setMainsOrderList([]);
      setStarterOrderList([]);
      setDessertsOrderList([]);
      setFruitsOrderList([]);
      setDrinksOrderList([]);

    };

  };



  const handleClearAll = () => {

    let tempList = [];
    mainsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setMainsOrderList(tempList)

    tempList = [];
    starterOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setStarterOrderList(tempList)

    tempList = [];
    dessertsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setDessertsOrderList(tempList)    

    tempList = [];
    fruitsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setFruitsOrderList(tempList)

    tempList = [];
    drinksOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setDrinksOrderList(tempList)

  }


  async function handleCancelOrder () {

    handleClearAll();

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    let sendData = {
      username: props.userName,
      datestring: dateString,
    };


    // console.log('WXWXWXWXWX', combinedLists);

    setOrderResponseMsg(translate(props.language, 'Order canceled.'))
    await YPCPutAxios2Alt(setXPost, 'deleteXOrderURL', sendData, '');
    getMenu();

    let newSendData = {
      username: props.userName,
      datestring: dateString,
      order: JSON.stringify({'whatever': 'whatever'})
    };

    await YPCPutAxios2Alt(setXNull, 'sendXKitchinEmailCancelURL', newSendData, '');
    setXNull(null);


  };

  const showCancelClearButtons = () => {
    let testList = [];
    let show = false;
    testList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    testList.map((value) => { 
      if (Number.isInteger(value.lunchboxcount) || Number.isInteger(value.dinnercount) || Number.isInteger(value.lunchcount)) {
        show = true;
      }
    });
    return show;
  };


  // const showSectionHeader = (sectionName) => {
  //   let testList = [];
  //   let show = false;
  //   testList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
  //   testList.map((value) => { 
  //     if (value.category === sectionName) {
  //       show = true;
  //     }
  //   });
  //   return show;
  // };

  const showSectionHeader = (sectionName) => {
    let testList = [];
    let show = false;
    testList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    testList.map((value) => { 
      if ( value.category === sectionName &&  (  (choiceLunchOrDinner === 'Lunch' && value.uuid in lunchSelectDict) || (choiceLunchOrDinner !== 'Lunch' && value.uuid in dinnerSelectDict)  ) ) {
        show = true;
      }
    });
    return show;
  };

  const showUberHeader = () => {
    let testList = [];
    let show = false;
    testList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    testList.map((value) => { 
      if (   (choiceLunchOrDinner === 'Lunch' && value.uuid in lunchSelectDict) || (choiceLunchOrDinner !== 'Lunch' && value.uuid in dinnerSelectDict)   ) {
        show = true;
      }
    });
    return show;
  };



  async function handleSaveOrder () {
  

    let testList = [];
    testList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    // console.log('OIOIOIOIOIO', testList, selectedTimeLunch.format("HH:mm"), selectedTimeDinner.format("HH:mm"));

    console.log('OTOTOTOTOTOTOTOTO', testList)

    let lunchPresent = false;
    let dinnerPresent = false;
    let lunchboxPresent = false;

    testList.map((value) => {
      if (!value['side'] ) {
        if (value['lunchcount'] !== null) {
          lunchPresent = true;
        };
        if (value['dinnercount'] !== null) {
          dinnerPresent = true;
        };
        if (value['lunchboxcount'] !== null) {
          lunchboxPresent = true;
        };

      };
    });

    if ( !lunchPresent && !dinnerPresent && !lunchboxPresent) {
      return;
    }

    let lunchMessage = lunchPresent && selectedTimeLunch.format("HH:mm") === '00:00';
    let dinnerMessage = dinnerPresent && selectedTimeDinner.format("HH:mm") === '00:00';
    let lunchboxMessage = lunchboxPresent && selectedTimeLunch.format("HH:mm") === '00:00';



    if (lunchMessage && dinnerMessage && lunchboxMessage) {
      setBothTimeNotSetMsgOpen(true);
      return;
    } else if (lunchMessage && dinnerMessage && !lunchboxMessage) {
      setBothTimeNotSetMsgOpen(true);
      return;
    } else if (lunchMessage && !dinnerMessage && lunchboxMessage) {
      setBothTimeNotSetMsgOpen(true);
      return;
    } else if (lunchMessage && !dinnerMessage && !lunchboxMessage) {
      setLunchTimeNotSetMsgOpen(true);
      return;
    } else if (!lunchMessage && dinnerMessage && lunchboxMessage) {
      setDinnerTimeNotSetMsgOpen(true);
      return;
    } else if (!lunchMessage && dinnerMessage && !lunchboxMessage) {
      setDinnerTimeNotSetMsgOpen(true);
      return;
    } else if (!lunchMessage && !dinnerMessage && lunchboxMessage) {
      setDinnerTimeNotSetMsgOpen(true);
      return;
    };


    // check if time slot is still available
    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    // var orderSlots = await YPCGetAxiosAlt(xget, setXGet, 'getXOrderSlotsURL', dateString);
    // if (lunchPresent && (maxTimeSlots[selectedTimeLunch.format("HH:mm")] - orderSlots['time_slots'][selectedTimeLunch.format("HH:mm")] < 1 ) ) {
    //   let temp = []
    //   fullLunchTimeList.map((slot) => {
    //     if (maxTimeSlots[slot] - orderSlots['time_slots'][slot] > 0) {
    //       temp.push(slot);
    //     };
    //   })
    //   setLunchTimeList(temp)

    //   let test = moment(selectedDate);
    //   test.set('minute', 0);
    //   test.set('hour', 0);
    //   setSelectedTimeLunch(test);
    //   // !! problem - rechoose time slot
    //   setLunchTimeSlotMsg(true)
    //   return;
    // }

    // if ( (lunchboxPresent || dinnerPresent) && (maxTimeSlots[selectedTimeDinner.format("HH:mm")] - orderSlots['time_slots'][selectedTimeDinner.format("HH:mm")] < 1 ) ) {
    //   let temp = []
    //   fullDinnerTimeList.map((slot) => {
    //     if (maxTimeSlots[slot] - orderSlots['time_slots'][slot] > 0) {
    //       temp.push(slot);
    //     };
    //   })
    //   setDinnerTimeList(temp)

    //   let test = moment(selectedDate);
    //   test.set('minute', 0);
    //   test.set('hour', 0);
    //   setSelectedTimeDinner(test);
    //   // !! problem - rechoose time slot
    //   setDinnerTimeSlotMsg(true);
    //   return;
    // }

    /////////////////////////////////////////////////////
    // handle check if there's space available in weighted time slots




    let tempOrderWeights = await YPCGetAxiosAlt(xget, setXGet, 'getXOrderSlotWeightsURL', dateString);
    

    // ************** don't forget to check saved_order_seconds - if time is the same !!!

    // lunch first
    let noSaveSpaceLunch = false;
    let noSaveSpaceDinner = false;
    if (lunchPresent) {
      let currentLunchSlotOrderSeconds = getCurrentSlotOrderSeconds()['lunchseconds'];
      if (tempOrderWeights['saved_order_seconds']['lunchtime']['time'] === selectedTimeLunch.format("HH:mm")) {
        if (tempOrderWeights['max_slot_seconds'][selectedTimeLunch.format("HH:mm")] - tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] - tempOrderWeights['saved_order_seconds']['lunchtime']['order_seconds'] < currentLunchSlotOrderSeconds) {
          noSaveSpaceLunch = true;
        }
      }
      else if (tempOrderWeights['max_slot_seconds'][selectedTimeLunch.format("HH:mm")] - tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] < currentLunchSlotOrderSeconds) {
        noSaveSpaceLunch = true;
      };
    };
    if (dinnerPresent) {
      let currentDinnerSlotOrderSeconds = getCurrentSlotOrderSeconds()['dinnerseconds'];
      if (tempOrderWeights['saved_order_seconds']['dinnertime']['time'] === selectedTimeDinner.format("HH:mm")) {
        if (tempOrderWeights['max_slot_seconds'][selectedTimeDinner.format("HH:mm")] - tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")]  - tempOrderWeights['saved_order_seconds']['dinnertime']['order_seconds'] < currentDinnerSlotOrderSeconds) {
          noSaveSpaceDinner = true;
        }
      }
      else if (tempOrderWeights['max_slot_seconds'][selectedTimeDinner.format("HH:mm")] - tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] < currentDinnerSlotOrderSeconds) {
        noSaveSpaceDinner = true;
      };
    };
    if (noSaveSpaceLunch || noSaveSpaceDinner) {
      setNoSaveSpaceDialog({'lunch_problem': noSaveSpaceLunch, 'dinner_problem': noSaveSpaceDinner, 'tempOrderWeights' : tempOrderWeights, 'currentSlotOrderSeconds' : getCurrentSlotOrderSeconds() });
      // maybe update order Weights
      return;
    }


    // get on with it

    let globalOrderList = [];
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    // console.log('PPPPPPPP', globalOrderList);

    let minimalList = {};
    globalOrderList.map((value) => {

        minimalList[value.uuid] = {
            id: value.id,
            lunchcount: value.lunchcount,
            dinnercount: value.dinnercount,
            lunchboxcount: value.lunchboxcount
        };
    });


    let currentTime = moment((new Date()));
    // console.log('TXTXTXTXTXT', currentTime.format("YYYY-MM-DDTHH:mm"));

    // console.log('XCXCXCXCXCXCXCX',  moment(selectedTimeLunch).format("YYYY-MM-DDTHH:mm"), moment(selectedTimeDinner).format("YYYY-MM-DDTHH:mm"));


    let frenchList = {'none': 'none'}
    globalOrderList.map((value) => {
      frenchList[value['dish']] = xtranslate.x('FRANCAIS', value['dish']);
    })

    let combinedLists = {
      minimalList: minimalList,
      fullList: globalOrderList,
      lunchTimeHour:  selectedTimeLunch.get('hour'),
      lunchTimeMinute:  selectedTimeLunch.get('minute'),
      dinnerTimeHour:  selectedTimeDinner.get('hour'),
      dinnerTimeMinute:  selectedTimeDinner.get('minute'),
      saveTime: currentTime.format("YYYY-MM-DDTHH:mm"),
      saveDate: moment(selectedDate).format("YYYY-MM-DDTHH:mm"),
      lunchDeliveryTime: moment(selectedTimeLunch).format("YYYY-MM-DDTHH:mm"),
      dinnerDeliveryTime: moment(selectedTimeDinner).format("YYYY-MM-DDTHH:mm"),
      frenchList: frenchList,
      subscriptionType: subscriptionType
    }

    // console.log('TMTMTMTMTMTMTTM', combinedLists.lunchDeliveryTime, combinedLists.dinnerDeliveryTime);

    // let subDate = selectedDate.toString().split(" ")
    // let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];



    let sendData = {
      username: props.userName,
      datestring: dateString,
      order: JSON.stringify(combinedLists)
    };


    // console.log('WXWXWXWXWX', combinedLists);

    setOrderResponseMsg(translate(props.language, 'Order saved.  Enter order for another day.'))
    await YPCPutAxios2Alt(setXPost, 'saveXOrderURL', sendData, '');
    getMenu();

    let newCombinedLists = {
      fullList: globalOrderList,
      lunchTimeHour:  selectedTimeLunch.get('hour'),
      lunchTimeMinute:  selectedTimeLunch.get('minute'),
      dinnerTimeHour:  selectedTimeDinner.get('hour'),
      dinnerTimeMinute:  selectedTimeDinner.get('minute'),
      frenchList: frenchList,
      subscriptionType: subscriptionType
    };

    let newSendData = {
      username: props.userName,
      datestring: dateString,
      order: JSON.stringify(newCombinedLists)
    };

    YPCPutAxios2Alt(setXNull, 'sendXKitchinEmailURL', newSendData, '');
    setXNull(null);

    // check for cutoff Time
    let cutOffTime = moment(selectedDate).startOf('week').subtract(1, 'days').set('hour', 19);
    // console.log('WWWWWWWWWWWWWWWWweek', cutOffTime.format("YYYY-MM-DDTHH:mm"));
    if (currentTime > cutOffTime) {
      setCutoffMsgOpen(true);
      // console.log('No changes allowed')
    };

  };


  
  const handleDateChange = (date) => {
    console.log('GAGAGA', moment(date).format("YYYY-MM-DDTHH:mm"));
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };



  const handleLunchTimeList = (value) => {
    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeLunch(newTime);

  };


  const handleDinnerTimeList = (value) => {

    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeDinner(newTime);

  };


  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  function orderTable(category) {

    let entryList = mainsOrderList;
    if (category === 'Starter') {
      entryList = starterOrderList;
    } else if (category === 'Dessert') {
      entryList = dessertsOrderList;
    } else if (category === 'Drink') {
      entryList = drinksOrderList;
    } else if (category === 'Fruit') {
      entryList = fruitsOrderList;
    };

    // console.log('XXXXXXXXXXXXXXXXXXX', entryList);

    let newList = [];
    let saveNext = false;
    // console.log('BBBBBBBBBBBBB', lunchSelectDict);
    // console.log('CCCCCCCCCCCCC', dinnerSelectDict);

    entryList.map((value) => { 
      // console.log('AAAAAAAAAAAAAAAAA', saveNext, choiceLunchOrDinner, value);
      if (value.category === 'Side') {
        if (saveNext && choiceLunchOrDinner === 'Lunch') {
          newList.push(value);
        } else if (saveNext && choiceLunchOrDinner !== 'Lunch') {
          newList.push(value);
        };
      } else {
        if (choiceLunchOrDinner === 'Lunch' && value.uuid in lunchSelectDict) {
          saveNext = true;
          newList.push(value);
        } else if (choiceLunchOrDinner === 'Lunch' && !(value.uuid in lunchSelectDict)) {
          saveNext = false;
        } else if (choiceLunchOrDinner !== 'Lunch' && value.uuid in dinnerSelectDict) {
          saveNext = true;
          newList.push(value);
        } else if (choiceLunchOrDinner !== 'Lunch' && !(value.uuid in dinnerSelectDict)) {
          saveNext = false;
        }
      };

    });



    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={newList}
          columns={getOrderColumns(category + 's')}
          // columns={orderColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleOrderEdit}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  function noSaveDialog() {

    // console.log('MQMQMQMQMQMQMQ', noSaveSpaceDialog, noSaveSpaceDialog['lunch_problem'], noSaveSpaceDialog['dinner_problem']);
    let currentSlotOrderSecondsLunch = noSaveSpaceDialog['currentSlotOrderSeconds']['lunchseconds'];
    let currentSlotOrderSecondsDinner = noSaveSpaceDialog['currentSlotOrderSeconds']['dinnerseconds'];
    let goodListLunch = "";
    let goodListDinner = "";
    let tempOrderWeights = noSaveSpaceDialog['tempOrderWeights'];

    if (noSaveSpaceDialog['lunch_problem']) {
      lunchTimeList.map((timeValue) => {
        if (timeValue !== selectedTimeLunch.format("HH:mm")) {
          if (tempOrderWeights['max_slot_seconds'][timeValue] - tempOrderWeights['slot_order_seconds'][timeValue] >= currentSlotOrderSecondsLunch) {
            goodListLunch = goodListLunch + timeValue + ", ";
          };
        };
      });
    };

    if (noSaveSpaceDialog['dinner_problem']) {
      dinnerTimeList.map((timeValue) => {
        if (timeValue !== selectedTimeDinner.format("HH:mm")) {
          if (tempOrderWeights['max_slot_seconds'][timeValue] - tempOrderWeights['slot_order_seconds'][timeValue] >= currentSlotOrderSecondsDinner) {
            goodListDinner = goodListDinner + timeValue + ", ";
          };
        };
      });
    };

    let dialogMessageLunch = '';
    let dialogMessageDinner = '';
    if (goodListLunch !== "") {
      dialogMessageLunch = translate(props.language, 'Due to high demand for ') + selectedTimeLunch.format("HH:mm") + 
        translate(props.language, ', your current selection quantities are not possible.  The following times - ') +
        goodListLunch +
        translate(props.language, 'are available.  Either choose one of these times or lower your quantities for ') +
        selectedTimeLunch.format("HH:mm") + ".";
    } else if (noSaveSpaceDialog['lunch_problem']) {
      dialogMessageLunch = translate(props.language, 'Due to high demand for ') + selectedTimeLunch.format("HH:mm") + 
        translate(props.language, ', your current selection quantities are not possible. Lower your quantities for ') +
        selectedTimeLunch.format("HH:mm") + '.';
    };

    if (goodListDinner !== "") {
      dialogMessageDinner = translate(props.language, 'Due to high demand for ') + selectedTimeDinner.format("HH:mm") + 
        translate(props.language, ', your current selection quantities are not possible.  The following times - ') +
        goodListDinner +
        translate(props.language, 'are available.  Either choose one of these times or lower your quantities for ') +
        selectedTimeDinner.format("HH:mm") + ".";
    } else if (noSaveSpaceDialog['dinner_problem']) {
      dialogMessageDinner = translate(props.language, 'Due to high demand for ') + selectedTimeDinner.format("HH:mm") + 
        translate(props.language, ', your current selection quantities are not possible. Lower your quantities for ') +
        selectedTimeDinner.format("HH:mm") + '.';
    };

    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-noslotdialog-title"
                aria-describedby="alert-noslotdialog-description"
        >
          {/* <DialogTitle  id="alert-noslotdialog-title" >Got Here -</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-orderdialog-description">
              {dialogMessageLunch}
              {<br></br>}
              {dialogMessageDinner}
            </DialogContentText>
          </DialogContent>


          <DialogActions>
            <Button 
              onClick={handleNoSaveDialogOk}
              style={{backgroundColor: '#78cbff', color:"white"}}
            >
              {translate(props.language, 'Ok')}
            </Button>
            {/* <Button 
              onClick={handleNoSlotDialogCancel}
              style={{backgroundColor: '#78cbff', color:"white"}}
            >
              {translate(props.language, 'Cancel')}
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>

    );
  };

  const handleNoSaveDialogOk = () => {
    setNoSaveSpaceDialog(null);
  };

  function noSlotDialog() {

    let newChosenTime = noSlotSpaceDialog['newChosenTime'];
    let currentSlotOrderSeconds = noSlotSpaceDialog['currentSlotOrderSeconds'];
    let goodList = "";

    if (choiceLunchOrDinner === 'Lunch') {
      lunchTimeList.map((timeValue) => {
        if (timeValue !== newChosenTime && timeValue !== selectedTimeLunch.format("HH:mm")) {
          if (orderWeights['max_slot_seconds'][timeValue] - orderWeights['slot_order_seconds'][timeValue] >= currentSlotOrderSeconds) {
            goodList = goodList + timeValue + ", ";
          };
        };
      });
    } else {
      dinnerTimeList.map((timeValue) => {
        if (timeValue !== newChosenTime && timeValue !== selectedTimeLunch.format("HH:mm")) {
          if (orderWeights['max_slot_seconds'][timeValue] - orderWeights['slot_order_seconds'][timeValue] >= currentSlotOrderSeconds) {
            goodList = goodList + timeValue + ", ";
          };
        };
      });
    }

    let dialogMessage = '';
    if (goodList !== "") {
      dialogMessage = translate(props.language, 'Due to high demand for ') + newChosenTime + 
        translate(props.language, ', your current selection quantities are not possible.  The following times - ') +
        goodList +
        translate(props.language, 'are available.  Either choose one of these times or lower your quantities for ') +
        newChosenTime + ".";
    } else {
      dialogMessage = translate(props.language, 'Due to high demand for ') + newChosenTime + 
        translate(props.language, ', your current selection quantities are not possible. Lower your quantities for ') +
        newChosenTime + '.';
    }


    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-noslotdialog-title"
                aria-describedby="alert-noslotdialog-description"
        >
          {/* <DialogTitle  id="alert-noslotdialog-title" >Got Here -</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-orderdialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>


          <DialogActions>
            <Button 
              onClick={handleNoSlotDialogOk}
              style={{backgroundColor: '#78cbff', color:"white"}}
            >
              {translate(props.language, 'Ok')}
            </Button>
            {/* <Button 
              onClick={handleNoSlotDialogCancel}
              style={{backgroundColor: '#78cbff', color:"white"}}
            >
              {translate(props.language, 'Cancel')}
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>

    );
  };

  const handleNoSlotDialogOk = () => {
    setNoSlotSpaceDialog(null);
  };

  const handleNoSlotDialogCancel = () => {
    setNoSlotSpaceDialog(null);
  };


  const handleOrderQty = (highCount, addorsub, rvalue) => {
    // console.log("TQTQTQTQTQTQTQT", highCount, currentQty, addorsub);
    if (currentQty !== highCount && addorsub === '+') {
        setDialogScratchValue({'value': currentQty +1 , 'row': rvalue})
        setCurrentQty(currentQty + 1);
    } else if (currentQty !== 0 && addorsub === '-') {
        setDialogScratchValue({'value': currentQty -1 , 'row': rvalue})
        setCurrentQty(currentQty - 1);
    };
  };

  const handleTopOrderQty = (highCount, addorsub, rvalue) => {
    // console.log('KFKFKFKFKFKFKFK', highCount, addorsub, rvalue);


    let currentQ = (choiceLunchOrDinner === 'Lunch' ? rvalue.lunchcount : rvalue.dinnercount) === null ? 0 : choiceLunchOrDinner === 'Lunch' ? rvalue.lunchcount : rvalue.dinnercount;

    // order cap stuff
    if (addorsub === "+" && capDetails.menu_cap[rvalue['name']][choiceLunchOrDinner] !== 0) {
      let maxCap = capDetails.menu_cap[rvalue['name']][choiceLunchOrDinner] - capDetails.total_orders[rvalue['name']][choiceLunchOrDinner];
      // console.log('xxxxAAAAAAAAAAAAAAAAAAAAAA', maxCap, currentQ, capDetails);

      if (maxCap < 1) {
        setCapHitMessage(true);
        return;
      };
    };
    //

    let nextValue = 0;

    if (addorsub === "+") {
      if (currentQ === highCount) {
        return;
      } else {
        nextValue = currentQ + 1;
      };
    } else if (addorsub === "-") {
      if (currentQ === 0) {
        return;
      } else {
        nextValue = currentQ - 1;
      };      
    };

    let altScratchValue = {
      'value' : nextValue,
      'row' : rvalue
    };

    if (choiceLunchOrDinner === 'Lunch') {
      let tempOrderWeights = orderWeights;
      let tempTime = selectedTimeLunch.format("HH:mm");
      let tempMinus = altScratchValue.row.lunchcount * tempOrderWeights['not_main_allocated_seconds'][tempTime];
      let tempAdd = altScratchValue.value * tempOrderWeights['not_main_allocated_seconds'][tempTime];
      if (altScratchValue.row.category === 'Main') {
        tempMinus = altScratchValue.row.lunchcount * tempOrderWeights['main_allocated_seconds'][selectedTimeLunch.format("HH:mm")];
        tempAdd = altScratchValue.value * tempOrderWeights['main_allocated_seconds'][selectedTimeLunch.format("HH:mm")];
      };
      tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] = tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] - tempMinus + tempAdd;

      setOrderWeights(tempOrderWeights);

      let tempScratchValue = altScratchValue.value === 0 ? null : altScratchValue.value;

      if (altScratchValue.row.category === 'Starter') {
          setStarterOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Main') {
          setMainsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Dessert') {
          setDessertsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Drink') {
          setDrinksOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Fruit') {
          setFruitsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        };

        let tempCap = capDetails;
        tempCap.total_orders[rvalue['name']] = {'Lunch' : tempCap['total_orders'][rvalue['name']]['Lunch'] - currentQ + nextValue, 'Dinner' : tempCap['total_orders'][rvalue['name']]['Dinner']};
        setCapDetails(tempCap);

        // console.log('xxxxBBBBBBBBBBBBBBBBBBBBBBBBBBBB', currentQ, nextValue, tempCap);

      } else {

        let tempOrderWeights = orderWeights;
        let tempMinus = altScratchValue.row.lunchcount * tempOrderWeights['not_main_allocated_seconds'][selectedTimeDinner.format("HH:mm")];
        let tempAdd = altScratchValue.value * tempOrderWeights['not_main_allocated_seconds'][selectedTimeDinner.format("HH:mm")];
        if (altScratchValue.row.category === 'Main') {
          tempMinus = altScratchValue.row.lunchcount * tempOrderWeights['main_allocated_seconds'][selectedTimeDinner.format("HH:mm")];
          tempAdd = altScratchValue.value * tempOrderWeights['main_allocated_seconds'][selectedTimeDinner.format("HH:mm")];
        };
        tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] = tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] - tempMinus + tempAdd;
        setOrderWeights(tempOrderWeights);

        let tempScratchValue = altScratchValue.value === 0 ? null : altScratchValue.value;

        if (altScratchValue.row.category === 'Starter') {
          setStarterOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Main') {
          setMainsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Dessert') {
          setDessertsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Drink') {
          setDrinksOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Fruit') {
          setFruitsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        };

        let tempCap = capDetails;
        tempCap.total_orders[rvalue['name']] = {'Lunch' : tempCap['total_orders'][rvalue['name']]['Lunch'], 'Dinner' : tempCap['total_orders'][rvalue['name']]['Dinner'] - currentQ + nextValue};
        setCapDetails(tempCap);

      }



  };


  function orderDialog() {

    let globalOrderList = [];
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);


    let rowValue = null;
    let sideList = []
    let getSides = false;
    globalOrderList.map((value) => {
      if (selectOrderEdit === value.uuid) {
        rowValue = value;

        if (rowValue.ingredients_list === 'AAA replace 1 cent') {
          rowValue.ingredients_list = 'N/A';
        };
        rowValue.ingredients_list = rowValue.ingredients_list.replace("AAA replace 1 cent, ", ""); 
        rowValue.ingredients_list = rowValue.ingredients_list.replace(", AAA replace 1 cent", ""); 
        getSides = true;
      } else if (getSides && value.category === 'Side') {
        let tmpval = value;
        if (tmpval.ingredients_list === 'AAA replace 1 cent') {
          tmpval.ingredients_list = 'N/A';
        };
        tmpval.ingredients_list = tmpval.ingredients_list.replace("AAA replace 1 cent, ", ""); 
        tmpval.ingredients_list = tmpval.ingredients_list.replace(", AAA replace 1 cent", ""); 
        sideList.push(tmpval);
        // sideList.push(value);

      } else {
        getSides = false;
      }
    })


    // console.log('RVRVRVRV', rowValue);

    let onMediaFallbackChoice = null;
    if (rowValue.category === 'Starter') {
      onMediaFallbackChoice = onMediaFallbackStarterSub;
    } else if (rowValue.category === 'Main') {
      onMediaFallbackChoice = onMediaFallbackMainSub;
    } else if (rowValue.category === 'Dessert') {
      onMediaFallbackChoice = onMediaFallbackDessertSub;
    } else if (rowValue.category === 'Drink') {
      onMediaFallbackChoice = onMediaFallbackDrinkSub;  
    } else if (rowValue.category === 'Fruit') {
      onMediaFallbackChoice = onMediaFallbackFruitSub;
    };
    const valueFormatted = currencyFormatter.format(Number(rowValue.price));

    let currentQuantity = rowValue.dinnercount === null ? 0 : rowValue.dinnercount;
    if (choiceLunchOrDinner === 'Lunch') {
      currentQuantity = rowValue.lunchcount === null ? 0: rowValue.lunchcount;
    };

    // if (!currentQtyBusy) {
    //     if (currentQuantity === 0) {
    //         setCurrentQty(1);            
    //     } else {
    //         setCurrentQty(currentQuantity);
    //     };
    //     setCurrentQtyBusy(true);
    // };

    if (!currentQtyBusy) {
          setCurrentQty(currentQuantity);
          setCurrentQtyBusy(true);
    };

    
    let tempTime = selectedTimeDinner.format("HH:mm");
    let tempCount = rowValue.dinnercount === null ? 0 : rowValue.dinnercount;
    if (choiceLunchOrDinner === 'Lunch') {
      tempTime = selectedTimeLunch.format("HH:mm");
      tempCount = rowValue.lunchcount === null ? 0 : rowValue.lunchcount;
    };
    let maxCount = parseInt((orderWeights['max_slot_seconds'][tempTime] - orderWeights['slot_order_seconds'][tempTime])/orderWeights['not_main_allocated_seconds'][tempTime]   + tempCount);
    if (rowValue.category === 'Main') {
      maxCount =  parseInt((orderWeights['max_slot_seconds'][tempTime] - orderWeights['slot_order_seconds'][tempTime])/orderWeights['main_allocated_seconds'][tempTime]  + tempCount);
    };
    
    let orderOptions = [];
    for (var i = 0; i <= maxCount; i++) {
      orderOptions.push(i.toString());
    };


    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-orderdialog-title"
                aria-describedby="alert-orderdialog-description"
        >
          {/* <DialogTitle  id="alert-orderdialog-title" >Info -</DialogTitle> */}
          <DialogContent>
            {/* <DialogContentText id="alert-orderdialog-description">
              {xpost}{xget}
            </DialogContentText> */}
          </DialogContent>
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardHeader 
                  title={props.language !== 'FRANCAIS' ? rowValue.dish : rowValue.name_french} 
                  subheaderTypographyProps="h5"
                  // subheader={valueFormatted}
                  subheader= {<Typography>{valueFormatted}</Typography>}
              />
              <CardMedia
                  component="img"
                  // height="140"
                  image={process.env.PUBLIC_URL + "/images/recipes/" + rowValue.name + ".jpg"}
                  // image={"http://d3v7pvgfkimony.cloudfront.net/" + rowValue.name + ".jpg"}
                  // image={imageCheck}
                  onError={onMediaFallbackChoice}
                  alt="name"
                  style={{width: '50%'}}
                  // style={{paddingRight: '50%', backgroundColor: '#78cbff'}}
              />


              {/* style={{backgroundColor: '#503c30'}} */}
              <CardContent>
                {/* <Typography gutterBottom variant="h6" component="div">
                  {'This is the content'}
                </Typography> */}

                <StyledBadge badgeContent={'Chef'} color="error" style={{marginTop: '-4px'}}
                                //   anchorOrigin={{
                                //     vertical: 'bottom',
                                //     horizontal: 'right',
                                //   }}
                                >
                                    <StarIcon sx={{ color: '#daa520' }} />
                </StyledBadge>
                <p></p>
                <p></p>

                <Typography variant="body2" color="text.secondary">
                              {/* {xtranslate.x(props.language, row.dish)}  */}
                              {props.language !== 'FRANCAIS' ? rowValue.dish : rowValue.name_french}
                              &#160; &#160;
                              {/* {Number.isInteger(rowValue.spicelevel) && rowValue.spicelevel > 0 && rowValue.spicelevel < 2 ? <img src={spicelevel0Image} width="6"/> : null }
                              {Number.isInteger(rowValue.spicelevel) && rowValue.spicelevel > 0 && rowValue.spicelevel < 2 ? <span>&#160;</span> : null } */}
                              {Number.isInteger(rowValue.spicelevel) && rowValue.spicelevel > 0 && rowValue.spicelevel < 2 ? <img src={spicelevel1Image} width="6"/> : null }
                              {Number.isInteger(rowValue.spicelevel) && rowValue.spicelevel > 0 && rowValue.spicelevel < 2 ? <span>&#160;</span>  : null }
                              {Number.isInteger(rowValue.spicelevel) && rowValue.spicelevel > 1 ? <img src={spicelevel1Image}  width="6"/> : null }
                              {Number.isInteger(rowValue.spicelevel) && rowValue.spicelevel > 1 ? <img src={spicelevel1Image}  width="6"/> : null }
                              {Number.isInteger(rowValue.spicelevel) && rowValue.spicelevel > 1 ? <span>&#160;</span>  : null }
                              {rowValue.vegetarian ? <img src={vegetarianImage} width="12"/> : null }
                              {rowValue.vegetarian ? <span>&#160;</span> : null }
                              {rowValue.vegan ? <img src={veganImage} width="12"/> : null }
                              {rowValue.vegan ? <span>&#160;</span> : null }
                              {rowValue.sugar_free ? <img src={sugarfreeImage} width="12"/> : null }
                              {rowValue.sugar_free ? <span>&#160;</span> : null }
                              {rowValue.gluten_free ? <img src={glutenfreeImage} width="12"/> : null }
                              {rowValue.gluten_free ? <span>&#160;</span> : null }
                              {!rowValue.peanut_free ? <img src={peanutImage} width="12"/> : null }
                              {!rowValue.peanut_free ? <span>&#160;</span> : null }
                              {rowValue.baby_friendly ? <img src={babyfriendlyImage} width="12"/> : null }
                              {rowValue.baby_friendly ? <span>&#160;</span> : null }                              
                              {rowValue.apt_for_lunchbox ? <img src={lunchboxImage} width="12"/> : null }
                              {rowValue.apt_for_lunchbox ? <span>&#160;</span> : null }         
                              {rowValue.calories !== null ? <span style={{color: '#b06942'}}>&#160;{rowValue.calories.toFixed(1)} cal</span> : <span style={{color: '#b06942'}}>&#160;cal N/A</span>} 
                  </Typography>

                  {rowValue.category !== 'Dessert' ?
                      <Typography variant="body2" color="text.secondary" style={{marginLeft: '20px'}}>
                          {'('}Ingr - {rowValue.ingredients_list}{')'}
                      </Typography>
                      : 
                      <Typography variant="body2" color="text.secondary" style={{marginLeft: '20px'}}>
                        {'('}Ingr - {'N/A'}{')'}
                      </Typography>
                  }


                  { sideList.map((subrow) => {
                      return (
                          <React.Fragment>
                              <Typography variant="body2" color="text.secondary">
                                  {/* {xtranslate.x(props.language, subrow.dish)}  */}
                                  &#160; &#160;
                                  {props.language === 'ENGLISH' ? subrow.dish : subrow.name_french}
                                  &#160; &#160;
                                  {/* {Number.isInteger(subrow.spicelevel) && subrow.spicelevel > 0 && subrow.spicelevel < 2 ? <img src={spicelevel0Image} width="6"/> : null }
                                  {Number.isInteger(subrow.spicelevel) && subrow.spicelevel > 0 && subrow.spicelevel < 2 ? <span>&#160;</span> : null } */}
                                  {Number.isInteger(subrow.spicelevel) && subrow.spicelevel > 0 && subrow.spicelevel < 2 ? <img src={spicelevel1Image} width="6"/> : null }
                                  {Number.isInteger(subrow.spicelevel) && subrow.spicelevel > 0 && subrow.spicelevel < 2 ? <span>&#160;</span>  : null }
                                  {Number.isInteger(subrow.spicelevel) && subrow.spicelevel > 1 ? <img src={spicelevel1Image}  width="6"/> : null }
                                  {Number.isInteger(subrow.spicelevel) && subrow.spicelevel > 1 ? <img src={spicelevel1Image}  width="6"/> : null }
                                  {Number.isInteger(subrow.spicelevel) && subrow.spicelevel > 1 ? <span>&#160;</span>  : null }
                                  {subrow.vegetarian ? <img src={vegetarianImage} width="12"/> : null }
                                  {subrow.vegetarian ? <span>&#160;</span> : null }
                                  {subrow.vegan ? <img src={veganImage} width="12"/> : null }
                                  {subrow.vegan ? <span>&#160;</span> : null }
                                  {subrow.sugar_free ? <img src={sugarfreeImage} width="12"/> : null }
                                  {subrow.sugar_free ? <span>&#160;</span> : null }
                                  {subrow.gluten_free ? <img src={glutenfreeImage} width="12"/> : null }
                                  {subrow.gluten_free ? <span>&#160;</span> : null }
                                  {!subrow.peanut_free ? <img src={peanutImage} width="12"/> : null }
                                  {!subrow.peanut_free ? <span>&#160;</span> : null }
                                  {subrow.baby_friendly ? <img src={babyfriendlyImage} width="12"/> : null }
                                  {subrow.baby_friendly ? <span>&#160;</span> : null }
                                  {subrow.apt_for_lunchbox ? <img src={lunchboxImage} width="12"/> : null }
                                  {subrow.apt_for_lunchbox ? <span>&#160;</span> : null }
                                  {subrow.calories !== null ? <span style={{color: '#b06942'}}>&#160;{subrow.calories.toFixed(1)} cal</span> : <span style={{color: '#b06942'}}>&#160;cal N/A</span>} 
                              </Typography>

                              {rowValue.category !== 'Dessert' ?
                                    <Typography variant="body2" color="text.secondary" style={{marginLeft: '30px'}}>
                                        {'('}Ingr - {subrow.ingredients_list}{')'}
                                    </Typography>
                                    :
                                    <Typography variant="body2" color="text.secondary" style={{marginLeft: '30px'}}>
                                        {'('}Ingr - {'N/A'}{')'}
                                    </Typography>
                              }

                          </React.Fragment>

                      )
                      })
                  }

                  {/* Include the dish description here!! */}

                  <p></p>
                  <p></p>

                  {props.language !== 'FRANCAIS' ? 
                    <Typography variant="body2" color="text.secondary">
                            This list of ingredients is made when creating the recipe. Our food may come into contact with common allergens,
                            such as dairy products, eggs, wheat, soy, tree nuts, peanuts, fish or shellfish.
                            Although we take steps to safely handle foods that contain potential allergens, please be aware that cross-contamination may occur
                            (for example, suppliers can modify their recipe without our knowledge).
                    </Typography>
                    :
                    <Typography variant="body2" color="text.secondary">
                            Cette liste d’ingrédients est réalisée lors de la création de la recette. Il est possible que nos aliments soient en contact avec des allergènes courants, 
                            comme des produits laitiers, des œufs, du blé, du soja, des noix, des arachides, du poisson ou des crustacés.
                            Bien que nous prenions des mesures pour manipuler en toute sécurité les aliments qui contiennent des allergènes potentiels, sachez qu’une contamination croisée peut se produire 
                            (les fournisseurs peuvent par exemple modifier leur recette à notre insu).
                    </Typography>

                  }


                  <p></p>
                  {/* <Grid container item xs={12} sm={12} md={12} lg={12} direction='column'> 
                      <Grid item xs={4} sm={3} md={3} lg={3}> 
                        <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                  // onChange={(event, value) => setDialogScratchValue({'value': parseInt(value), 'row': rowValue})}
                                  onChange={(event, value) => {
                                    // console.log('KZKZKZKZKZKZKZK', event, value)
                                      if (value === null) {
                                        setDialogScratchValue({'value': 0, 'row': rowValue})
                                      } else {
                                        setDialogScratchValue({'value': parseInt(value), 'row': rowValue})
                                      };
                                    }
                                  }
                                  id="combo-box-choice-days"
                                  options={orderOptions}
                                  // value={daysBack}
                                  value={currentQuantity.toString()}
                                  // getOptionLabel={(option) => option.name}
                                  // style={{ width: 300 }}
                                  renderInput={(params) => <TextField {...params} label={translate(props.language, 'Quantity')} InputLabelProps={{
                                      shrink: true,
                                      style: {color: '#78cbff'},
                                  }}
                                  // variant="outlined"
                                  />} 
                              />
                        </MuiThemeProvider>
                      </Grid>
                  </Grid> */}

                  {/* <Typography variant="body2"  style={{color: '#78cbff'}}>{translate(props.language, 'Quantity')}{" -"}</Typography> */}

                {/* <Stack direction="row" spacing={2}>
                  <MuiThemeProvider theme={theme}>


                            <IconButton component="label"
                                    aria-label="iconbutton1" 
                                    style={{color: '#78cbff'}}
                                    onClick={() => handleOrderQty(maxCount, "+", rowValue)}


                            >
                                        
                                <AddBoxIcon />
                            </IconButton>


                        <Typography variant="h6" style={{paddingTop: '5px' }}>{currentQty}</Typography>
                        <IconButton component="label"
                                aria-label="iconbutton2"
                                style={{color: '#78cbff'}}
                                onClick={() => handleOrderQty(maxCount, "-", rowValue)}
                        >
                            <IndeterminateCheckBoxIcon />
                        </IconButton>
                    </MuiThemeProvider>
                </Stack>                   */}


              </CardContent>
            </Card>

          <DialogActions>

            <Button 
              onClick={() => handleOrderDialogOk(rowValue)}
              style={{backgroundColor: '#78cbff', color:"white"}}
            >
              {translate(props.language, 'Ok')}
            </Button>
            {/* <Button 
              onClick={handleOrderDialogCancel}
              style={{backgroundColor: '#78cbff', color:"white"}}
            >
              {translate(props.language, 'Cancel')}
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>

    );
  };


  const handleOrderDialogOk = (rvalue) => {

    // console.log('HWHWHWHWHWHWHWHWH', currentQty, dialogScratchValue, rvalue);

    setCurrentQtyBusy(false);

    let altScratchValue = dialogScratchValue;

    if (altScratchValue === null) {
      if (currentQty === 1) {
        altScratchValue = {
          'value' : currentQty,
          'row' : rvalue
        };
      } else {
        setSelectOrderEdit("");
        return;
      }
    };

    if (choiceLunchOrDinner === 'Lunch') {
      let tempOrderWeights = orderWeights;
      let tempTime = selectedTimeLunch.format("HH:mm");
      let tempMinus = altScratchValue.row.lunchcount * tempOrderWeights['not_main_allocated_seconds'][tempTime];
      let tempAdd = altScratchValue.value * tempOrderWeights['not_main_allocated_seconds'][tempTime];
      if (altScratchValue.row.category === 'Main') {
        tempMinus = altScratchValue.row.lunchcount * tempOrderWeights['main_allocated_seconds'][selectedTimeLunch.format("HH:mm")];
        tempAdd = altScratchValue.value * tempOrderWeights['main_allocated_seconds'][selectedTimeLunch.format("HH:mm")];
      };
      tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] = tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] - tempMinus + tempAdd;

      setOrderWeights(tempOrderWeights);

      let tempScratchValue = altScratchValue.value === 0 ? null : altScratchValue.value;

      if (altScratchValue.row.category === 'Starter') {
          setStarterOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Main') {
          setMainsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Dessert') {
          setDessertsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Drink') {
          setDrinksOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Fruit') {
          setFruitsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, lunchcount:tempScratchValue}) : el)
            );
        };
      } else {

        let tempOrderWeights = orderWeights;
        let tempMinus = altScratchValue.row.lunchcount * tempOrderWeights['not_main_allocated_seconds'][selectedTimeDinner.format("HH:mm")];
        let tempAdd = altScratchValue.value * tempOrderWeights['not_main_allocated_seconds'][selectedTimeDinner.format("HH:mm")];
        if (altScratchValue.row.category === 'Main') {
          tempMinus = altScratchValue.row.lunchcount * tempOrderWeights['main_allocated_seconds'][selectedTimeDinner.format("HH:mm")];
          tempAdd = altScratchValue.value * tempOrderWeights['main_allocated_seconds'][selectedTimeDinner.format("HH:mm")];
        };
        tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] = tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] - tempMinus + tempAdd;
        setOrderWeights(tempOrderWeights);

        let tempScratchValue = altScratchValue.value === 0 ? null : altScratchValue.value;

        if (altScratchValue.row.category === 'Starter') {
          setStarterOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Main') {
          setMainsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Dessert') {
          setDessertsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Drink') {
          setDrinksOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        } else if (altScratchValue.row.category === 'Fruit') {
          setFruitsOrderList(prevValue => 
            [...prevValue].map(el => 
                el.id === altScratchValue.row.id ? ({...el, dinnercount:tempScratchValue}) : el)
            );
        };
      }

    setDialogScratchValue(null);
    setSelectOrderEdit("");
  }


  const handleOrderDialogCancel = () => {
    setCurrentQtyBusy(false);
    setSelectOrderEdit("");
  }
  

  const handleACardEdit = (event, uuid) => {

    // if (lunchBlocked || dinnerBlocked) {
    //   setSelectOrderEdit("");
    // } else {
    //   setSelectOrderEdit(uuid);
    // };

      setSelectOrderEdit(uuid);
  };


  const handleOrderEdit = (params) => {
    if (lunchBlocked || dinnerBlocked) {
      setSelectOrderEdit("");
      return;
    };
    if (!params.row.side) {
      setSelectOrderEdit(params.row.uuid);
    } else {
      setSelectOrderEdit("");
    };
  };


  const getOrderColumns = (category) => {
    return (
    [
    // const orderColumns = [
      {
        field: 'dish',
        // headerName: translate(props.language, 'Selection'),
        headerName: ' ',
        // renderHeader: (params) => {
        //   console.log('LRLRLRLRLRLRLRL',params);
        //   return (
        //   <strong>
        //     {'Birthday '}
        //     <span role="img" aria-label="enjoy">
        //       🎂
        //     </span>
        //   </strong>)
        // },
        width: 300,
        sortable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          indent: params.row.side,
          noindent: !params.row.side,
        }),
        renderCell: (params) => { return (
          <div>
            {xtranslate.x(props.language, params.value)}
            &#160; &#160;
            {/* {Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 && params.row.spicelevel < 2 ? <img src={spicelevel0Image} width="6"/>: null }
            &#160; */}
            {Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 ? <img src={spicelevel1Image} width="6"/> : null }
            &#160;
            {Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 1 ? <img src={spicelevel1Image}  width="6"/> : null }
            &#160;
            {params.row.vegetarian ? <img src={vegetarianImage} width="12"/> : null }
            &#160;
            {params.row.vegan ? <img src={veganImage} width="12"/> : null }
            &#160;
            {params.row.sugar_free ? <img src={sugarfreeImage} width="12"/> : null }
            &#160;
            {params.row.gluten_free ? <img src={glutenfreeImage} width="12"/> : null }
            &#160;
            {!params.row.peanut_free ? <img src={peanutImage} width="12"/> : null }
            &#160;
            {params.row.baby_friendly ? <img src={babyfriendlyImage} width="12"/> : null }
            &#160;
            {params.row.apt_for_lunchbox ? <img src={lunchboxImage} width="12"/> : null }
            &#160;
          </div>
        )},
        // valueFormatter: (params) => {
        //   return xtranslate.x(props.language, params.value);
        // },
      },
      {
        field: 'price',
        // headerName: translate(props.language, 'Price/Portion'),
        headerName: ' ',
        type: 'number',
        width: 140,
        sortable: false,
        valueFormatter: (params) => {
          if (params.value === null) {
              return (null);
          }
          else {
              return (currencyFormatter.format(Number(params.value)))
          }
        },

      },
      {
        field: 'lunchcount',  // not really lunchcount - it depends on if lunch or dinner
        // headerName: translate(props.language, 'Quantity'),
        renderHeader: (params) => {
          // console.log('LRLRLRLRLRLRLRL',params);
          return ( <div>{translate(props.language, 'Quantity')}<sup>*</sup></div>)
        },
        // headerName: 'Lunch',
        type: 'number',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 200,
        // flex: 1,
        renderCell: (params) => {
              //if(params.row.side  || params.row.uuid in dinnerOnlyChecked) {  // hide
              if(params.row.side) {  // hide  
                return null;
              } else if (choiceLunchOrDinner === 'Lunch') {
                  return (params.row.lunchcount);
              } else if (choiceLunchOrDinner !== 'Lunch') {
                  return (params.row.dinnercount);
              }
        },  // renderCell
      },
      {
        field: 'dinnercount',   // not really dinnercount - it depends on what's left
        headerName: 'Avail',
        // headerName: 'Lunch',
        type: 'number',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 200,
        // flex: 1,
        renderCell: (params) => {
              //if(params.row.side  || params.row.uuid in dinnerOnlyChecked) {  // hide
              if(params.row.side) {  // hide  
                return null;
              }
              else {
                let tempTime = selectedTimeDinner.format("HH:mm");
                let tempCount = params.row.dinnercount === null ? 0 : params.row.dinnercount;
                if (choiceLunchOrDinner === 'Lunch') {
                  tempTime = selectedTimeLunch.format("HH:mm");
                  tempCount = params.row.lunchcount === null ? 0 : params.row.lunchcount;
                };
                if (tempTime === "00:00") {
                  return 'Pick a time';
                } else if (params.row.category === 'Main') {
                  return parseInt(  (orderWeights['max_slot_seconds'][tempTime] - orderWeights['slot_order_seconds'][tempTime])/orderWeights['main_allocated_seconds'][tempTime]  + tempCount );
                } else {
                  return parseInt((orderWeights['max_slot_seconds'][tempTime] - orderWeights['slot_order_seconds'][tempTime])/orderWeights['not_main_allocated_seconds'][tempTime] + tempCount);
                }
              }
        },  // renderCell
      },



    ]
    )


  }




  const columns = [

    {
      field: 'image',
      headerName: 'Selection',
      width: 300,
      editable: true,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
        indent: params.row.side,
        noindent: !params.row.side,
      }),
      renderCell: (params) => { 
              return (
                  <div>
                    {/* {xtranslate.x(props.language, params.row.dish)} */}
                    {props.language !== 'FRANCAIS' ? params.row.dish : params.row.name_french}
                    &#160; &#160;
                    {/* {Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 && params.row.spicelevel < 2 ? <img src={spicelevel0Image} width="6"/>: null }
                    &#160; */}
                    {Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 ? <img src={spicelevel1Image} width="6"/> : null }
                    &#160;
                    {Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 1 ? <img src={spicelevel1Image}  width="6"/> : null }
                    &#160;
                    {params.row.vegetarian ? <img src={vegetarianImage} width="12"/> : null }
                    &#160;
                    {params.row.vegan ? <img src={veganImage} width="12"/> : null }
                    &#160;
                    {params.row.sugar_free ? <img src={sugarfreeImage} width="12"/> : null }
                    &#160;
                    {params.row.gluten_free ? <img src={glutenfreeImage} width="12"/> : null }
                    &#160;
                    {!params.row.peanut_free ? <img src={peanutImage} width="12"/> : null }
                    &#160;
                    {params.row.baby_friendly ? <img src={babyfriendlyImage} width="12"/> : null }
                    &#160;
                    {params.row.apt_for_lunchbox ? <img src={lunchboxImage} width="12"/> : null }
                    &#160;
                  </div>) },
    },
    {
      field: 'price',
      headerName: translate(props.language, 'Price/Portion'),
      // headerName: 'Price/Portion',
      type: 'number',
      width: 140,
      sortable: false,
       valueFormatter: (params) => {
        if (params.value === null) {
            return (null);
        }
        else {
            return (currencyFormatter.format(Number(params.value)))
        }
       },

    },
    {
        field: 'lunchcount',
        headerName: translate(props.language, 'Lunch'),
        // headerName: 'Lunch',
        type: 'number',
        sortable: false,
        width: 120,
        editable: true,
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                hide: params.row.side  || params.row.uuid in dinnerOnlyChecked,
                show: !params.row.side && !(params.row.uuid in dinnerOnlyChecked) && !lunchBlocked,
                blocked: !params.row.side && !(params.row.uuid in dinnerOnlyChecked) && lunchBlocked
            }),
      },
      {
        field: 'dinnercount',
        headerName: translate(props.language, 'Dinner'),
        // headerName: 'Dinner',
        type: 'number',
        sortable: false,
        width: 120,
        editable: true,
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                hide: params.row.side,
                show: !params.row.side && !dinnerBlocked,
                blocked: !params.row.side && dinnerBlocked
            }),
      },
      {
        field: 'lunchboxcount',
        headerName: translate(props.language, 'Lunchbox') + '⁽ᴺᵒᵗᵉ⁾',
        // headerName: 'Lunchbox',
        type: 'number',
        sortable: false,
        width: 160,
        editable: true,
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                hide: params.row.side || !(params.row.uuid in lunchBoxChecked),
                show: !params.row.side && (params.row.uuid in lunchBoxChecked) && !dinnerBlocked,
                blocked: !params.row.side && (params.row.uuid in lunchBoxChecked) && dinnerBlocked
            }),
      },      


  ];

  // function getDisabled(val) {
  //   if (val) return true;
  //   return false;
  // }

  const getDisabled = val => {
    if (val) return { disabled: true };
    return {};
  };

  const cardColumns = [


    {
        field: 'lunchcount',
        headerName: translate(props.language, 'Lunch'),
        // headerName: 'Lunch',
        type: 'number',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        // width: 71,
        flex: 1,
        editable: true,
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                // was following but now see below
                // hide: params.row.side  || params.row.uuid in dinnerOnlyChecked,
                // show: !params.row.side && !(params.row.uuid in dinnerOnlyChecked) && !lunchBlocked,
                // blocked: !params.row.side && !(params.row.uuid in dinnerOnlyChecked) && lunchBlocked,
                hide: params.row.side  || !(params.row.uuid in lunchSelectDict),
                show: !params.row.side && (params.row.uuid in lunchSelectDict) && !lunchBlocked,
                blocked: !params.row.side && (params.row.uuid in lunchSelectDict) && lunchBlocked,
            }),
        renderCell: (params) => {
              //if(params.row.side  || params.row.uuid in dinnerOnlyChecked) {  // hide
              if(params.row.side  || !(params.row.uuid in lunchSelectDict)) {  // hide  
                return null;
              } else {
                  return (
                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label1">{""}</InputLabel> */}
                          <Select
                            // disabled = {(!params.row.side && !(params.row.uuid in dinnerOnlyChecked) && lunchBlocked )} // blocked
                            disabled = {(!params.row.side && (params.row.uuid in lunchSelectDict) && lunchBlocked )} // blocked
                            // IconComponent={() => (       // this hides down arrow icon
                            //   ""
                            // )}
                            labelId="demo-simple-select-label1"
                            id="demo-simple-select1"
                            value={params.row.lunchcount}
                            // label=""
                            disableUnderline
                            // style={{ border: '3px', borderColor: 'white'}}
                            onChange={(e) => {
                              // console.log('TRTRTRTRTRTR', e.target.value, params);
                              params.api.updateRows([{ ...params.row, lunchcount: e.target.value }])
                              lookupLunchCount(params.row.category, params.id, params.field, e.target.value)
                              }
                            }
                          >
                            <MenuItem value={null}>-</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                          </Select>
                        </FormControl>
                  )
              } 
        
        },  // renderCell
      },
      {
        field: 'dinnercount',
        headerName: translate(props.language, 'Dinner'),
        // headerName: 'Dinner',
        type: 'number',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        // width: 74,
        flex: 1,
        editable: true,
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                // hide: params.row.side,
                // show: !params.row.side && !dinnerBlocked,
                // blocked: !params.row.side && dinnerBlocked,
                hide: params.row.side  || !(params.row.uuid in dinnerSelectDict),
                show: !params.row.side && (params.row.uuid in dinnerSelectDict) && !dinnerBlocked,
                blocked: !params.row.side && (params.row.uuid in dinnerSelectDict) && dinnerBlocked,
                borderAddLeft: true
            }),
        renderCell: (params) => {
              // if(params.row.side) {  // hide
              if(params.row.side  || !(params.row.uuid in dinnerSelectDict)) {  // hide  
                return null;
              } else {
                  return (
                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label2">{""}</InputLabel> */}
                          <Select
                            //disabled = {(!params.row.side && dinnerBlocked)} // blocked
                            disabled = {(!params.row.side && (params.row.uuid in dinnerSelectDict) && dinnerBlocked )} // blocked
                            // IconComponent={() => (             // this hides down arrow icon
                            //   ""
                            // )}
                            labelId="demo-simple-select-label2"
                            id="demo-simple-select2"
                            value={params.row.dinnercount}
                            // label=""
                            disableUnderline
                            // style={{ border: '3px', borderColor: 'white'}}
                            onChange={(e) => {
                              // console.log('TRTRTRTRTRTR', e.target.value, params);
                              params.api.updateRows([{ ...params.row, dinnercount: e.target.value }])
                              lookupLunchCount(params.row.category, params.id, params.field, e.target.value)
                              }
                            }
                          >
                            <MenuItem value={null}>-</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                          </Select>
                        </FormControl>
                  )
              } 
        
        }  // renderCell


      },
      // {
      //   field: 'lunchboxcount',
      //   headerName: translate(props.language, 'Lunchbox'),
      //   // headerName: 'Lunchbox',
      //   type: 'number',
      //   sortable: false,
      //   // width: 85,
      //   editable: true,
      //   cellClassName: (params) =>  // you could use params.value or params.row.side
      //       clsx('super-app', {
      //           hide: params.row.side || !(params.row.uuid in lunchBoxChecked),
      //           show: !params.row.side && (params.row.uuid in lunchBoxChecked) && !dinnerBlocked,
      //           blocked: !params.row.side && (params.row.uuid in lunchBoxChecked) && dinnerBlocked
      //       }),

      //   renderCell: (params) => {
      //         if(params.row.side || !(params.row.uuid in lunchBoxChecked)) {  // hide
      //           return null;
      //         } else {
      //             return (
      //                   <FormControl fullWidth>
      //                     <InputLabel id="demo-simple-select-label3">{""}</InputLabel>
      //                     <Select
      //                       disabled = {(!params.row.side && (params.row.uuid in lunchBoxChecked) && dinnerBlocked)} // blocked
      //                       IconComponent={() => (
      //                         ""
      //                       )}
      //                       labelId="demo-simple-select-label3"
      //                       id="demo-simple-select"
      //                       value={params.row.lunchboxcount}
      //                       label=""
      //                       disableUnderline
      //                       onChange={(e) => {
      //                         // console.log('TRTRTRTRTRTR', e.target.value, params);
      //                         params.api.updateRows([{ ...params.row, lunchboxcount: e.target.value }])
      //                         lookupLunchCount(params.row.category, params.id, params.field, e.target.value)
      //                         }
      //                       }
      //                     >
      //                       <MenuItem value={null}>-</MenuItem>
      //                       <MenuItem value={1}>1</MenuItem>
      //                       <MenuItem value={2}>2</MenuItem>
      //                       <MenuItem value={3}>3</MenuItem>
      //                       <MenuItem value={4}>4</MenuItem>
      //                       <MenuItem value={5}>5</MenuItem>
      //                     </Select>
      //                   </FormControl>
      //             )
      //         } 
        
      //   }  // renderCell
      // },      
  ];


  const summaryColumns = [
    {
      field: 'item',
      headerName: translate(props.language, 'Item'),
      // headerName: 'Item',
      width: 300,
      sortable: false,
      valueFormatter: (params) => {
        return xtranslate.x(props.language, params.value);
      },
    },
    {
        field: 'itemtype',
        headerName: translate(props.language, 'Type'),
        // headerName: 'Type',
        width: 140,
        sortable: false,
      },
      {
        field: 'quantity',
        headerName: translate(props.language, 'Quantity'),
        // headerName: 'Quantity',
        type: 'number',
        width: 140,
        sortable: false
      },      
    {
      field: 'price',
      headerName: translate(props.language, 'Price'),
      // headerName: 'Price',
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
        field: 'total',
        headerName: translate(props.language, 'Total'),
        // headerName: 'Total',
        type: 'number',
        width: 140,
        sortable: false,
        valueFormatter: (params) => {
            if (typeof params.value === 'string' || params.value instanceof String) {
                return params.value;
            } else {
                const valueFormatted = currencyFormatter.format(Number(params.value));
                return `${valueFormatted}`;
            }
          },
      },
  ];





  function calculatesummaryTable() {
    let globalOrderList = [];
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    let tempList = []
    let itemId = 0;
    let item = null;
    globalOrderList.map((value) => {
        if (!value.side) {
            if (value.lunchcount !== null || value.dinnercount !== null || value.lunchboxcount !== null) {
                item = value.dish;
                if (value.lunchcount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Lunch)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Lunch') + ")",
                        quantity: value.lunchcount,
                        price: value.price,
                        total: value.lunchcount * value.price
                    });
                    itemId = itemId + 1;
                };
                if (value.dinnercount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Dinner)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Dinner') + ")",
                        quantity: value.dinnercount,
                        price: value.price,
                        total: value.dinnercount * value.price
                    });
                    itemId = itemId + 1;
                };
                if (value.lunchboxcount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Lunchbox)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Lunchbox') + ")",
                        quantity: value.lunchboxcount,
                        price: value.price,
                        total: value.lunchboxcount * value.price
                    });
                    itemId = itemId + 1;
                };



            }
        }
    });

    let subTotal = 0;
    tempList.map((value) => {
        subTotal = subTotal + value.total;
    });


    // check on subscription
    if ( (subscriptionType === 'None' || subscriptionType === 'None5') && tempList.length !== 0) {
      // subTotal = subTotal + noneSubsriberCost;
      subTotal = subTotal + noneSubscriberCostDict[subscriptionType];
      tempList.push({
        id: itemId,
        item: translate(props.language, 'Non-member fee (cooking & dishwashing)'),
        itemtype: null,
        quantity: null,
        // price: noneSubsriberCost,
        // total: noneSubsriberCost
        price: noneSubscriberCostDict[subscriptionType],
        total: noneSubscriberCostDict[subscriptionType]
      });
      itemId = itemId + 1;
    };



    let totalGST = gst * subTotal;
    let totalPST = pst * subTotal;
    let grandTotal = subTotal + totalGST + totalPST;

    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        price: " ",
        total: " "
    });
    itemId = itemId + 1;

    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'SubTotal',
        price: translate(props.language,'SubTotal'),
        total: subTotal
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'GST (5%)',
        price: translate(props.language,'GST') + ' (5%)',
        total: totalGST
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'PST (9.975%)',
        price: translate(props.language,'PST') + ' (9.975%)',
        total: totalPST
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'Total',
        price: translate(props.language,'Total'),        
        total: grandTotal
    });
    setsummaryList(tempList);

  };



  function summaryTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={summaryList}
          columns={summaryColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

// This is the last one, new one is below
//   const handleEditable = (params) => {
//     if (params.row.side) {
//         return (false);
//     } else if (params.field === 'lunchboxcount') {
//         if (params.row.uuid in lunchBoxChecked && !dinnerBlocked) {
//             return (true);
//         } else {
//             return (false);
//         };
//     } else if (params.field === 'lunchcount') {
//         if (params.row.uuid in dinnerOnlyChecked) {
//           return (false);
//         } else if (lunchBlocked) {
//           return (false);
//         } else {
//           return (true);
//         };
//     } else if (params.field === 'dinnercount') {
//         if (dinnerBlocked) {
//           return (false);
//         } else {
//         return (true);
//         };
//     };
//  };


 const handleEditable = (params) => {
  if (params.row.side) {
      return (false);
  } else if (params.field === 'lunchboxcount') {
      if (params.row.uuid in lunchBoxChecked && !dinnerBlocked) {
          return (true);
      } else {
          return (false);
      };
  } else if (params.field === 'lunchcount') {
    if (!lunchBlocked && params.row.uuid in lunchSelectDict) {
      return (true);
    } else {
    return (false);
    };

  } else if (params.field === 'dinnercount') {
      if (!dinnerBlocked && params.row.uuid in dinnerSelectDict) {
        return (true);
      } else {
      return (false);
      };
  };
};


  // Start Functions Section


  function StarterTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
          onCellEditCommit={handleStarterLunchCount}
          isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={starterOrderList}
          columns={columns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleStarterSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
        //   getCellClassName={(params) => {
        //     console.log('NNNNNNNNNNNNNNNNNNN',params);
        //     if (params.field === 'city') {
        //       return '';
        //     }
        //     return Number(params.value) >= 15 ? 'hot' : 'cold';
        //   }}


          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  const althandleStarterLunchCount = (id, field, value) => {
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = starterOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                            realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free,
                            name_french: row.name_french,
                            ingredients_list: row.ingredients_list
                            }

                };
                return row;
            
            });
            setStarterOrderList(updatedRows);
        };
  };



  const handleStarterLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = starterOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                            realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free,
                            name_french: row.name_french,
                            ingredients_list: row.ingredients_list
                            }

                };
                return row;
            
            });
            setStarterOrderList(updatedRows);
        };
    },
    // [starterOrderList],
  );

  const handleStarterSelect = (params) => {
    setSelectStarterDelete(params.row.uuid);
  };


  // End of Starters Section


  const onMediaFallback = event => event.target.src = FALLBACK_IMAGE;
  const onMediaFallbackStarter = event => event.target.src = FALLBACK_IMAGE_STARTER;
  const onMediaFallbackMain = event => event.target.src = FALLBACK_IMAGE_MAIN;
  const onMediaFallbackDessert = event => event.target.src = FALLBACK_IMAGE_DESSERT;
  const onMediaFallbackDrink = event => event.target.src = FALLBACK_IMAGE_DRINK;
  const onMediaFallbackFruit = event => event.target.src = FALLBACK_IMAGE_FRUIT;


  const onMediaFallbackSub = event => event.target.src = SUB_FALLBACK_IMAGE;
  const onMediaFallbackStarterSub = event => event.target.src = SUB_FALLBACK_IMAGE_STARTER;
  const onMediaFallbackMainSub = event => event.target.src = SUB_FALLBACK_IMAGE_MAIN;
  const onMediaFallbackDessertSub = event => event.target.src = SUB_FALLBACK_IMAGE_DESSERT;
  const onMediaFallbackDrinkSub = event => event.target.src = SUB_FALLBACK_IMAGE_DRINK;
  const onMediaFallbackFruitSub = event => event.target.src = SUB_FALLBACK_IMAGE_FRUIT;


  // let lookupLunchCount = {'Starter':althandleStarterLunchCount, 
  //   'Main':althandleMainsLunchCount, 
  //   'Dessert':althandleDessertsLunchCount, 
  //   'Drink':althandleDrinksLunchCount, 
  //   'Fruit':althandleFruitsLunchCount};



  let lookupSelect = {'Starter':handleStarterSelect, 
    'Main':handleMainsSelect, 
    'Dessert':handleDessertsSelect, 
    'Drink':handleDrinksSelect, 
    'Fruit':handleFruitsSelect};

  function lookupLunchCount(category, id, field, value) {
    if(category === 'Starter') {
      althandleStarterLunchCount(id, field, value);
    } else if(category === 'Main') {
      althandleMainsLunchCount(id, field, value);
    } else if(category === 'Dessert') {
      althandleDessertsLunchCount(id, field, value);
    } else if(category === 'Drink') {
      althandleDrinksLunchCount(id, field, value);
    } else if(category === 'Fruit') {
      althandleFruitsLunchCount(id, field, value);
    }

  }

  function queryGet(columnNumber) {
    let columnResult = 12;
    if ( (xsColumn === false && smColumn === false && mdColumn === false && lgColumn === false) ||
          (xsColumn === false && smColumn === false && mdColumn === false && lgColumn === true)) {
            switch (columnNumber) {
              case 0:
                columnResult = 12;
                break;
              case 1:
                columnResult = 3;
                break;
              case 2:
                columnResult = 6;
                break;
              case 3:
                columnResult = 9;
                break;
              default:
                columnResult =12;
            };

    } else if (xsColumn === false && smColumn === false && mdColumn === true && lgColumn === false) {
      switch (columnNumber) {
        case 0:
          columnResult = 12;
          break;
        case 1:
          columnResult = 4;
          break;
        case 2:
          columnResult = 8;
          break;
        default:
          columnResult =12;
      };


    } else if (xsColumn === false && smColumn === true && mdColumn === false && lgColumn === false) {
      switch (columnNumber) {
        case 0:
          columnResult = 12;
          break;
        case 1:
          columnResult = 6;
          break;
        default:
          columnResult =12;
      };
    } else if (xsColumn === true && smColumn === false && mdColumn === false && lgColumn === false) {
      switch (columnNumber) {
        case 0:
          columnResult = 12;
          break;
        default:
          columnResult =12;
      };
    } else {
        columnResult = 12;
      // good question
    };

    // console.log('EOEOEOEO', columnNumber, xsColumn, smColumn, mdColumn, lgColumn, columnResult);

    return columnResult;



  }

  function ACardTable(listType) {

    let genList = [];
    let titleName = '';

    if(listType === "Mains") {
      genList = mainsOrderList;
      titleName = 'Main Dishes';
    } else if (listType === "Starters") {
      genList = starterOrderList;
      titleName = 'Starters';
    } else if (listType === "Desserts") {
      genList = dessertsOrderList; 
      titleName = 'Desserts';
    } else if (listType === "Drinks") {
      genList = drinksOrderList;
      titleName = 'Drinks';
    } else if (listType === "Fruits") {
      genList = fruitsOrderList;
      titleName = 'Fruits';
    };


    let sideList = [];
    let tempObject = {'tst':'tst'};
    let finalList = [];
    let columnNumber = 0;      
    let columnResult = 12;          

    if (genList.length > 0) {
        genList.map((row, index) => {
          columnNumber = columnNumber + 1;
          if(!row.side) {
            if(index > 0) {
              tempObject['sideList'] = sideList;
              finalList.push(tempObject);
            };
            tempObject = row;
            sideList = [];
          } else {
            sideList.push(row);
          }
        });
        tempObject['sideList'] = sideList;
        finalList.push(tempObject);

        // console.log('ERERERERERERERERERERERERER', finalList);
        // console.log('DRDRDRDRDRDRDRDRDR', realsideSelectDict);
        // console.log('EOEOEOEO', xsColumn, smColumn, mdColumn, lgColumn);
        columnResult = queryGet(columnNumber);
      };

        return (

          <React.Fragment>



          <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} direction='row'>


          { finalList.map((row) => {

              if ( (choiceLunchOrDinner === 'Lunch' && row.uuid in lunchSelectDict) || (choiceLunchOrDinner !== 'Lunch' && row.uuid in dinnerSelectDict) ) {
                if(!row.side) {

                        // new stuff
                        let tempTime = selectedTimeDinner.format("HH:mm");
                        let tempCount = row.dinnercount === null ? 0 : row.dinnercount;
                        if (choiceLunchOrDinner === 'Lunch') {
                          tempTime = selectedTimeLunch.format("HH:mm");
                          tempCount = row.lunchcount === null ? 0 : row.lunchcount;
                        };
                        let maxCount = parseInt((orderWeights['max_slot_seconds'][tempTime] - orderWeights['slot_order_seconds'][tempTime])/orderWeights['not_main_allocated_seconds'][tempTime]   + tempCount);
                        if (row.category === 'Main') {
                          maxCount =  parseInt((orderWeights['max_slot_seconds'][tempTime] - orderWeights['slot_order_seconds'][tempTime])/orderWeights['main_allocated_seconds'][tempTime]  + tempCount);
                        };

                        const valueFormatted = currencyFormatter.format(Number(row.price));
                        let onMediaFallbackChoice = null;
                        if (row.category === 'Starter') {
                          onMediaFallbackChoice = onMediaFallbackStarter;
                        } else if (row.category === 'Main') {
                          onMediaFallbackChoice = onMediaFallbackMain;
                        } else if (row.category === 'Dessert') {
                          onMediaFallbackChoice = onMediaFallbackDessert;
                        } else if (row.category === 'Drink') {
                          onMediaFallbackChoice = onMediaFallbackDrink;  
                        } else if (row.category === 'Fruit') {
                          onMediaFallbackChoice = onMediaFallbackFruit;
                        };

                        let spiceLevelList = [row.spicelevel];
                        let vegetarianValue = row.vegetarian ? 1:0;
                        let veganValue = row.vegan  ? 1:0;
                        let sugar_freeValue = row.sugar_free  ? 1:0;
                        let lunchboxValue = row.apt_for_lunchbox ? 1:0;
                        let gluten_freeValue =  row.gluten_free ? 1:0;
                        let peanut_freeValue = row.peanut_free  ? 1:0;
                        let baby_friendlyValue = row.baby_friendly  ? 1:0;

                        let totCalories = row.calories;

                        let sideText = "";
                        row.sideList.map((sidedetails) => {
                          if( sidedetails.uuid in realsideSelectDict) {
                            if (sideText === "") {
                              sideText = sideText.concat( " - " ,props.language !== 'FRANCAIS' ? sidedetails.name : sidedetails.name_french);
                            } else {
                              sideText = sideText.concat( ", " ,props.language !== 'FRANCAIS' ? sidedetails.name : sidedetails.name_french);
                            };
                          };

                          spiceLevelList.push(sidedetails.spicelevel);
                          vegetarianValue = vegetarianValue + (sidedetails.vegetarian ? 1:0);
                          veganValue = veganValue + (sidedetails.vegan ? 1:0);
                          sugar_freeValue = sugar_freeValue + (sidedetails.sugar_free ? 1:0);
                          lunchboxValue = lunchboxValue + (sidedetails.apt_for_lunchbox ? 1:0);
                          gluten_freeValue = gluten_freeValue + (sidedetails.gluten_free ? 1:0);
                          peanut_freeValue = peanut_freeValue + (sidedetails.peanut_free ? 1:0);
                          baby_friendlyValue = baby_friendlyValue + (sidedetails.baby_friendly ? 1:0);

                          if (totCalories !== null && sidedetails.calories !== null) {
                            totCalories = totCalories + sidedetails.calories;
                          };

                        });

                        // console.log('V>V>V>V>V>V>V>V>', row.name, vegetarianValue, row.sideList.length, row.sideList);
                        
                        let finalSpiceLevel = Math.max(...spiceLevelList);
                        let finalVegetarian = vegetarianValue === row.sideList.length + 1 ? true : false;
                        let finalVegan = veganValue === row.sideList.length + 1 ? true : false;
                        let finalSugar_Free = sugar_freeValue === row.sideList.length + 1 ? true : false;
                        let finalLunchbox = lunchboxValue === row.sideList.length + 1 ? true : false;
                        let finalGluten_Free = gluten_freeValue === row.sideList.length + 1 ? true : false;
                        let finalBaby_Friendly = baby_friendlyValue === row.sideList.length + 1 ? true : false;
                        let not_finalPeanut_Free = peanut_freeValue === row.sideList.length + 1 ? false : true;


                        return (
                          // <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Grid item xs={12} sm={12} md={6}  lg={4}>

                            {/* style={{ border: "none", boxShadow: "none" }} */}
                            <Card 
                              raised={true} 
                              square={false} 
                              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '120px'}}
                              // onClick={handleACardEdit(row.uuid)}
                              // onClick={(event) => {handleACardEdit(event, row.uuid)}}
                              >
                                <CardMedia
                                  component="img"
                                  // height="140"
                                  image={process.env.PUBLIC_URL + "/images/recipes/" + row.name + ".jpg"}
                                  // image={"http://d3v7pvgfkimony.cloudfront.net/" + row.name + ".jpg"}
                                  // image={imageCheck}
                                  onError={onMediaFallbackChoice}
                                  alt="name"
                                  // style={{width: '50%', position: 'relative'}}
                                  style={{width: '100px', height: '120px', position: 'relative', cursor: 'pointer'}}
                                  // sx={{ width: 50 }}
                                  onClick={(event) => {handleACardEdit(event, row.uuid)}}
                                />
                                <CardContent  style={{paddingTop: '25px'}}>
                                  {/* <Typography variant="h6" color="text.secondary" component="div"> */}
                                  <Typography  style={{fontSize: '14px'}} variant="h6" color="text.secondary" component="div">
                                    {props.language !== 'FRANCAIS' ? row.name : row.name_french}
                                    {sideText}
                                  </Typography>

                                  <Stack direction="row" spacing={2} style={{paddingTop: '3px'}}>

                                            {chefsChoice.hasOwnProperty(row.name) ?

                                                chefsChoice[row.name] ? 
                                                    <React.Fragment>
                                                        <StyledBadge badgeContent={'Chef'} color="error"  style={{marginTop: '-4px'}}
                                                        //   anchorOrigin={{
                                                        //     vertical: 'bottom',
                                                        //     horizontal: 'right',
                                                        //   }}
                                                        >
                                                            <StarIcon sx={{ color: '#daa520' }} />
                                                        </StyledBadge>
                                                        <span>&#160;</span>
                                                    </React.Fragment>
                                                : null

                                            : null
                                            }

                                        <Typography variant="body2" color="text.secondary">
                                                    {/* {Number.isInteger(finalSpiceLevel) && finalSpiceLevel > 0 && finalSpiceLevel < 2 ? <img src={spicelevel0Image} width="6"/> : null }
                                                    {Number.isInteger(finalSpiceLevel) && finalSpiceLevel > 0 && finalSpiceLevel < 2 ? <span>&#160;</span> : null } */}
                                                    {Number.isInteger(finalSpiceLevel) && finalSpiceLevel > 0 && finalSpiceLevel < 2 ? <img src={spicelevel1Image} width="6"/> : null }
                                                    {Number.isInteger(finalSpiceLevel) && finalSpiceLevel > 0 && finalSpiceLevel < 2 ? <span>&#160;</span>  : null }
                                                    {Number.isInteger(finalSpiceLevel) && finalSpiceLevel > 1 ? <img src={spicelevel1Image}  width="6"/> : null }
                                                    {Number.isInteger(finalSpiceLevel) && finalSpiceLevel > 1 ? <img src={spicelevel1Image}  width="6"/> : null }
                                                    {Number.isInteger(finalSpiceLevel) && finalSpiceLevel > 1 ? <span>&#160;</span>  : null }
                                                    {finalVegetarian ? <img src={vegetarianImage} width="12"/> : null }
                                                    {finalVegetarian ? <span>&#160;</span> : null }
                                                    {finalVegan ? <img src={veganImage} width="12"/> : null }
                                                    {finalVegan ? <span>&#160;</span> : null }
                                                    {finalSugar_Free ? <img src={sugarfreeImage} width="12"/> : null }
                                                    {finalSugar_Free ? <span>&#160;</span> : null }
                                                    {finalGluten_Free ? <img src={glutenfreeImage} width="12"/> : null }
                                                    {finalGluten_Free ? <span>&#160;</span> : null }
                                                    {not_finalPeanut_Free ? <img src={peanutImage} width="12"/> : null }
                                                    {not_finalPeanut_Free ? <span>&#160;</span> : null }
                                                    {finalBaby_Friendly ? <img src={babyfriendlyImage} width="12"/> : null }
                                                    {finalBaby_Friendly ? <span>&#160;</span> : null }
                                                    {finalLunchbox ? <img src={lunchboxImage} width="12"/> : null }
                                                    {finalLunchbox ? <span>&#160;</span> : null }
                                                    {totCalories !== null ? <span style={{color: '#b06942'}}>&#160;{totCalories.toFixed(1)} cal</span> : <span style={{color: '#b06942'}}>&#160;cal N/A</span> }

                                        </Typography>
                                  </Stack>

                                  {/* <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {valueFormatted} {' '} &#160; &#160; &#160;
                                    {translate(props.language,'Qty') + " - "} {  (choiceLunchOrDinner === 'Lunch' ? row.lunchcount : row.dinnercount) === null ? '0' : choiceLunchOrDinner === 'Lunch' ? row.lunchcount : row.dinnercount}
                                  </Typography> */}

                                  {/* new stuff is here */}
                                  <Stack direction="row" spacing={2}>
                                    <MuiThemeProvider theme={theme}>
                                      <Typography variant="subtitle1" style={{paddingTop: '0px',  marginRight: '5px' }}>
                                        {valueFormatted}
                                        {/* {translate(props.language,'Qty')} */}
                                      </Typography>
                                      <Typography variant="subtitle1" style={{paddingTop: '0px',   marginLeft: '0px', marginRight: '2px' }}>
                                        {/* {valueFormatted} {' '} &#160; &#160; &#160; */}
                                        {translate(props.language,'Qty')}
                                      </Typography>

                                          {/* {!lunchBlocked && !dinnerBlocked ?
                                                <IconButton component="label"
                                                        aria-label="iconbutton1" 
                                                        style={{color: '#78cbff', paddingTop: '2px', marginLeft: '0px',  marginRight: '0px'}}
                                                        onClick={() => handleTopOrderQty(maxCount, "+", row)}
                                                >
                                                    <AddBoxIcon />
                                                </IconButton>
                                          : null } */}

                                          {(!lunchBlocked && choiceLunchOrDinner === 'Lunch') || (!dinnerBlocked && choiceLunchOrDinner !== 'Lunch') ?
                                                <IconButton component="label"
                                                        aria-label="iconbutton1" 
                                                        style={{color: '#78cbff', paddingTop: '2px', marginLeft: '0px',  marginRight: '0px'}}
                                                        onClick={() => handleTopOrderQty(maxCount, "+", row)}
                                                >
                                                    <AddBoxIcon />
                                                </IconButton>
                                          : null }

                                            <Typography variant="subtitle1" style={{paddingTop: '0px', marginLeft: '0px',  marginRight: '0px' }}>
                                                {(choiceLunchOrDinner === 'Lunch' ? row.lunchcount : row.dinnercount) === null ? '0' : choiceLunchOrDinner === 'Lunch' ? row.lunchcount : row.dinnercount}
                                            </Typography>

                                            {/* {!lunchBlocked && !dinnerBlocked ?
                                                <IconButton component="label"
                                                        aria-label="iconbutton2"
                                                        style={{color: '#78cbff',  paddingTop: '2px', marginLeft: '0px', marginRight: '0px'}}
                                                        onClick={() => handleTopOrderQty(maxCount, "-", row)}
                                                >
                                                    <IndeterminateCheckBoxIcon />
                                                </IconButton>
                                            : null }   */}

                                            {(!lunchBlocked && choiceLunchOrDinner === 'Lunch') || (!dinnerBlocked && choiceLunchOrDinner !== 'Lunch') ?
                                                <IconButton component="label"
                                                        aria-label="iconbutton2"
                                                        style={{color: '#78cbff',  paddingTop: '2px', marginLeft: '0px', marginRight: '0px'}}
                                                        onClick={() => handleTopOrderQty(maxCount, "-", row)}
                                                >
                                                    <IndeterminateCheckBoxIcon />
                                                </IconButton>
                                            : null }


                                        </MuiThemeProvider>
                                    </Stack>                  
                                    {/* end of new stuff */}


                                </CardContent>
                            </Card>
                            <div></div>
                          </Grid>    

                        )  // end of return
                  }


              }
            }
          )}
          </Grid>
          </React.Fragment>

        );
}



  // Mains Functions Section

 
  function MainsTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          onCellEditCommit={handleMainsLunchCount}
        //   isCellEditable={(params) => !params.row.side}
          isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={mainsOrderList}
          // rows={mainsAltOrderList}
          columns={columns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleMainsSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  const althandleMainsLunchCount = (id, field, value) => {
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = mainsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                            realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free,
                            name_french: row.name_french,
                            ingredients_list: row.ingredients_list
                            }

                };
                return row;
            
            });
            setMainsOrderList(updatedRows);
        };
    };


  const handleMainsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = mainsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                            realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free,
                            name_french: row.name_french,
                            ingredients_list: row.ingredients_list
                            }

                };
                return row;
            
            });
            setMainsOrderList(updatedRows);
        };
    },
    // [mainsOrderList],
  );

  const handleMainsSelect = (params) => {
    setSelectMainsDelete(params.row.uuid);
  };
  


// end of Mains Section

// start of Desserts Section

function DessertsTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleDessertsLunchCount}
          isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={dessertsOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleDessertsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const althandleDessertsLunchCount = (id, field, value) => {
      if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
          const updatedRows = dessertsOrderList.map((row) => {
              if (row.id === id) {
                  return { 
                          category: row.category,
                          dish: row.dish,
                          id: row.id,
                          name: row.name,
                          price: row.price,
                          side: row.side,
                          uuid: row.uuid,
                          dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                          lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                          dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                          realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                          lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                          lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                          dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                          lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                          spicelevel: row.spicelevel,
                          vegetarian: row.vegetarian,
                          vegan: row.vegan,
                          sugar_free: row.sugar_free,
                          name_french: row.name_french,
                          ingredients_list: row.ingredients_list
                          }

              };
              return row;
          
          });
          setDessertsOrderList(updatedRows);
      };
  }

const handleDessertsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = dessertsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                            realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free,
                            name_french: row.name_french,
                            ingredients_list: row.ingredients_list
                            }

                };
                return row;
            
            });
            setDessertsOrderList(updatedRows);
        };
    },
    // [dessertsOrderList],
  );

const handleDessertsSelect = (params) => {
  setSelectDessertsDelete(params.row.uuid);
};



// end of Desserts Section


// start of Drinks section


function DrinksTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleDrinksLunchCount}
          isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={drinksOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleDrinksSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const althandleDrinksLunchCount = (id, field, value) => {
      if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
          const updatedRows = drinksOrderList.map((row) => {
              if (row.id === id) {
                  return { 
                          category: row.category,
                          dish: row.dish,
                          id: row.id,
                          name: row.name,
                          price: row.price,
                          side: row.side,
                          uuid: row.uuid,
                          dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                          lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                          dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                          realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                          lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                          lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                          dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                          lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                          spicelevel: row.spicelevel,
                          vegetarian: row.vegetarian,
                          vegan: row.vegan,
                          sugar_free: row.sugar_free,
                          name_french: row.name_french,
                          ingredients_list: row.ingredients_list
                          }

              };
              return row;
          
          });
          setDrinksOrderList(updatedRows);
      };
  }

const handleDrinksLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = drinksOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                            realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free,
                            name_french: row.name_french,
                            ingredients_list: row.ingredients_list
                            }

                };
                return row;
            
            });
            setDrinksOrderList(updatedRows);
        };
    },
    // [drinksOrderList],
  );

const handleDrinksSelect = (params) => {
  setSelectDrinksDelete(params.row.uuid);
};




// end of drinks sectiion


// start of Fruits Section


function FruitsTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleFruitsLunchCount}
          isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={fruitsOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleFruitsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const althandleFruitsLunchCount = (id, field, value) => {
      if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
          const updatedRows = fruitsOrderList.map((row) => {
              if (row.id === id) {
                  return { 
                          category: row.category,
                          dish: row.dish,
                          id: row.id,
                          name: row.name,
                          price: row.price,
                          side: row.side,
                          uuid: row.uuid,
                          dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                          lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                          dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                          realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                          lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                          lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                          dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                          lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                          spicelevel: row.spicelevel,
                          vegetarian: row.vegetarian,
                          vegan: row.vegan,
                          sugar_free: row.sugar_free,
                          name_french: row.name_french,
                          ingredients_list: row.ingredients_list
                          }

              };
              return row;
          
          });
          setFruitsOrderList(updatedRows);
      };
  }

const handleFruitsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = fruitsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 
                            realsideselected: (row.uuid in realsideSelectDict ? realsideSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free,
                            name_french: row.name_french,
                            ingredients_list: row.ingredients_list
                            }

                };
                return row;
            
            });
            setFruitsOrderList(updatedRows);
        };
    },
    // [fruitsOrderList],
  );

const handleFruitsSelect = (params) => {
  setSelectFruitsDelete(params.row.uuid);
};


function cutoffMsg() {
  return (
    <div>
      <Dialog open={cutoffMsg} >
        <DialogTitle>{translate(props.language, 'If you have modified your order, please notify concierge@jaspercooks.com about this change so we can confirm feasibility.')}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCutoffMsg} color="primary">
            {translate(props.language, 'Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const handleCutoffMsg = () => {
  setCutoffMsgOpen(false);
}


function generalMsg(displayMsg) {
  return (
    <div>
      <Dialog open={generalMsg} >
        {/* <DialogTitle>{translate(props.language, 'Lunch and Dinner time values must be set before saving order!')}</DialogTitle> */}
        <DialogTitle>{translate(props.language, displayMsg)}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGeneralMsg} color="primary">
            {translate(props.language, 'Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



const handleGeneralMsg = () => {
  setBothTimeNotSetMsgOpen(false);
  setLunchTimeNotSetMsgOpen(false);
  setDinnerTimeNotSetMsgOpen(false);
  setLunchTimeSlotMsg(false);
  setDinnerTimeSlotMsg(false);
  setCapHitMessage(false);
};


// gen dialog

const handleGenDialog = () => {
  setXPost(null);
  setXGet(null);
  setOrderResponseMsg(noMsg);
}

  function genDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

              {orderResponseMsg !== noMsg ? orderResponseMsg
                : null
              }
              {orderResponseMsg === noMsg ? xpost
                : null
              }
              {orderResponseMsg === noMsg ? xget
                : null
              }
              {/* {xpost}{xget} */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };


  const handleChooseLunchOrDinner = (event, newLunchOrDinner) => {
    if (newLunchOrDinner !== null) {
      setChoiceLunchOrDinner(newLunchOrDinner);
    };
  };

  const getCurrentSlotOrderSeconds = () => {

    let testList = [];
    testList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    let lunchseconds = 0;
    let dinnerseconds = 0;
    testList.map((value) => {
      if (value['category'] !== 'Side') {
        if (value['category'] === 'Main') {
          if (value['lunchcount'] !== null) {
            lunchseconds = lunchseconds +  value.lunchcount * orderWeights['main_allocated_seconds'][selectedTimeLunch.format("HH:mm")]
          };
          if (value['dinnercount'] !== null ) {
            dinnerseconds = dinnerseconds + value.dinnercount * orderWeights['main_allocated_seconds'][selectedTimeDinner.format("HH:mm")]
          };
        } else {
          if (value['lunchcount'] !== null) {
            lunchseconds = lunchseconds +  value.lunchcount * orderWeights['not_main_allocated_seconds'][selectedTimeLunch.format("HH:mm")]
          };
          if (value['dinnercount'] !== null ) {
            dinnerseconds = dinnerseconds + value.dinnercount * orderWeights['not_main_allocated_seconds'][selectedTimeDinner.format("HH:mm")]
          };

        }
      }
    });


    return {'lunchseconds':lunchseconds, 'dinnerseconds': dinnerseconds};

  };

  // const handleChooseLunchTime = (event, newChosenLunchTime) => {
  async function handleChooseLunchTime(event, newChosenLunchTime) {

    // note that if it's the same time or it's 00:00, then it'll be null

    if (selectedTimeLunch.format("HH:mm") !== "00:00") {
      console.log('WAWAWAWAWAWA', newChosenLunchTime);
    }



    if (newChosenLunchTime !== null) {

      let subDate = selectedDate.toString().split(" ")
      let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
      let tempOrderWeights = await YPCGetAxiosAlt(xget, setXGet, 'getXOrderSlotWeightsURL', dateString);

      let currentLunchSlotOrderSeconds = getCurrentSlotOrderSeconds()['lunchseconds'];

      if (tempOrderWeights['max_slot_seconds'][newChosenLunchTime] - tempOrderWeights['slot_order_seconds'][newChosenLunchTime] >= currentLunchSlotOrderSeconds) {
        let newTime = new moment(selectedDate);

        if (newChosenLunchTime === null || newChosenLunchTime === '-') {
          newTime.set('hour', 0);
          newTime.set('minute', 0);
        } else {
          newTime.set('hour', newChosenLunchTime.split(":")[0])
          newTime.set('minute', newChosenLunchTime.split(":")[1])
        };

        // let tempOrderWeights = orderWeights;

        // console.log('WBWBWBWBWBWBWB', newChosenLunchTime,tempOrderWeights['slot_order_seconds'][newChosenLunchTime]);
        // console.log('WCWCWCWCWCWCWCW', selectedTimeLunch.format("HH:mm"),tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")]);

        tempOrderWeights['slot_order_seconds'][newChosenLunchTime] = tempOrderWeights['slot_order_seconds'][newChosenLunchTime] + currentLunchSlotOrderSeconds;
        if (selectedTimeLunch.format("HH:mm") === tempOrderWeights['saved_order_seconds']['lunchtime']['time']) {
          tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] = tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] - 
              tempOrderWeights['saved_order_seconds']['lunchtime']['order_seconds'];          
        } else {
          tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] = tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")] - currentLunchSlotOrderSeconds;
        }

        // console.log('WDWDWDWDWDWDWD', newChosenLunchTime,tempOrderWeights['slot_order_seconds'][newChosenLunchTime]);
        // console.log('WEWEWEWEWEWEWE', selectedTimeLunch.format("HH:mm"),tempOrderWeights['slot_order_seconds'][selectedTimeLunch.format("HH:mm")]);

        setOrderWeights(tempOrderWeights);
        setSelectedTimeLunch(newTime);

      } else {

        // deal with the problem
        setNoSlotSpaceDialog({'newChosenTime' : newChosenLunchTime, 'currentSlotOrderSeconds' : currentLunchSlotOrderSeconds} );

      };

    };

  };


  const sliderLunchTimeValue = () => {
    
    if (selectedTimeLunch.format("HH:mm") === "00:00") {
      return 0;
    } else {
      return lunchTimeList.indexOf(selectedTimeLunch.format("HH:mm")) + 1;
    }

  };

  const sliderDinnerTimeValue = () => {
    
    if (selectedTimeDinner.format("HH:mm") === "00:00") {
      return 0;
    } else {
      return dinnerTimeList.indexOf(selectedTimeDinner.format("HH:mm")) + 1;
    }

  };


  const handleSliderLunchTimeList = (value) => {
    let newValue = '-';
    if (value !== 0) {
      newValue = lunchTimeList[value - 1];
    };
    let newTime = new moment(selectedDate);

    if (newValue === null || newValue === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', newValue.split(":")[0])
      newTime.set('minute', newValue.split(":")[1])
    };
    setSelectedTimeLunch(newTime);

  };

  const handleSliderDinnerTimeList = (value) => {
    let newValue = '-';
    if (value !== 0) {
      newValue = dinnerTimeList[value - 1];
    };
    let newTime = new moment(selectedDate);

    if (newValue === null || newValue === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', newValue.split(":")[0])
      newTime.set('minute', newValue.split(":")[1])
    };
    setSelectedTimeDinner(newTime);

  };




  // const handleChooseDinnerTime = (event, newChosenDinnerTime) => {
    async function handleChooseDinnerTime(event, newChosenDinnerTime) {

      // note that if it's the same time or it's 00:00, then it'll be null
  
      if (selectedTimeDinner.format("HH:mm") !== "00:00") {
        console.log('WAWAWAWAWAWA', newChosenDinnerTime);
      }
  
  
  
      if (newChosenDinnerTime !== null) {
  
        let subDate = selectedDate.toString().split(" ")
        let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
        let tempOrderWeights = await YPCGetAxiosAlt(xget, setXGet, 'getXOrderSlotWeightsURL', dateString);
  
        let currentDinnerSlotOrderSeconds = getCurrentSlotOrderSeconds()['dinnerseconds'];
  
        if (tempOrderWeights['max_slot_seconds'][newChosenDinnerTime] - tempOrderWeights['slot_order_seconds'][newChosenDinnerTime] >= currentDinnerSlotOrderSeconds) {
          let newTime = new moment(selectedDate);
  
          if (newChosenDinnerTime === null || newChosenDinnerTime === '-') {
            newTime.set('hour', 0);
            newTime.set('minute', 0);
          } else {
            newTime.set('hour', newChosenDinnerTime.split(":")[0])
            newTime.set('minute', newChosenDinnerTime.split(":")[1])
          };
  
          // let tempOrderWeights = orderWeights;
  
          // console.log('WBWBWBWBWBWBWB', newChosenDinnerTime,tempOrderWeights['slot_order_seconds'][newChosenDinnerTime]);
          // console.log('WCWCWCWCWCWCWCW', selectedTimeDinner.format("HH:mm"),tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")]);
  
          tempOrderWeights['slot_order_seconds'][newChosenDinnerTime] = tempOrderWeights['slot_order_seconds'][newChosenDinnerTime] + currentDinnerSlotOrderSeconds;
          if (selectedTimeDinner.format("HH:mm") === tempOrderWeights['saved_order_seconds']['dinnertime']['time']) {
            tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] = tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] - 
                tempOrderWeights['saved_order_seconds']['dinnertime']['order_seconds'];          
          } else {
            tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] = tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")] - currentDinnerSlotOrderSeconds;
          }
  
          // console.log('WDWDWDWDWDWDWD', newChosenDinnerTime,tempOrderWeights['slot_order_seconds'][newChosenDinnerTime]);
          // console.log('WEWEWEWEWEWEWE', selectedTimeDinner.format("HH:mm"),tempOrderWeights['slot_order_seconds'][selectedTimeDinner.format("HH:mm")]);
  
          setOrderWeights(tempOrderWeights);
          setSelectedTimeDinner(newTime);
  
        } else {
  
          // deal with the problem
          setNoSlotSpaceDialog({'newChosenTime' : newChosenDinnerTime, 'currentSlotOrderSeconds' : currentDinnerSlotOrderSeconds} );
  
        };
  
      };
  
    };



  const lunchMarks = () => {

    let marks = [{value: 0, label: '-'}];
    lunchTimeList.map((entry, index) => {
      marks.push({value: index+1, label: entry})
    })
    return (
      marks
    )
  };


  const dinnerMarks = () => {

    let marks = [{value: 0, label: '-'}];
    dinnerTimeList.map((entry, index) => {
      marks.push({value: index+1, label: entry})
    })
    return (
      marks
    )
  };

  const getLunchTimeList = () => {

    return (

          <ToggleButtonGroup
          // color="primary"
          value={selectedTimeLunch.format("HH:mm") === "00:00" ? '-' : selectedTimeLunch.format("HH:mm")}
          exclusive
          onChange={handleChooseLunchTime}
          style={{ flexWrap: "wrap" }}
          disabled={lunchBlocked}
        >
          {/* <ToggleButton className={classes.toggleselected} value='-' >{'-'}</ToggleButton> */}
         { lunchTimeList.map((rowList) => (
            <ToggleButton className={classes.toggleselected} value={rowList} >{rowList}</ToggleButton>
         ))}

          {/* {selectedTimeLunch.format("HH:mm") === "00:00" ?
            <ToggleButton className={classes.toggleselected} value='-' >{'-'}</ToggleButton>
            : null
          } */}

      </ToggleButtonGroup>


    );

  };


  const getDinnerTimeList = () => {

    return (

          <ToggleButtonGroup
          // color="primary"
          value={selectedTimeDinner.format("HH:mm") === "00:00" ? '-' : selectedTimeDinner.format("HH:mm")}
          exclusive
          onChange={handleChooseDinnerTime}
          style={{ flexWrap: "wrap" }}
          disabled={dinnerBlocked}
        >
          {/* <ToggleButton className={classes.toggleselected} value='-' >{'-'}</ToggleButton> */}
         { dinnerTimeList.map((rowList) => (
            <ToggleButton className={classes.toggleselected} value={rowList} >{rowList}</ToggleButton>
         ))}

          {/* {selectedTimeDinner.format("HH:mm") === "00:00" ?
            <ToggleButton className={classes.toggleselected} value='-' >{'-'}</ToggleButton>
            : null
          } */}

      </ToggleButtonGroup>


    );

  };  

// end of Fruits Section


    // const handleOnCellClick = (params) => {
    //   console.log('TRTRTRTRTRTRTRT', params);
    // };


    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>
                  {/* {cutoffMsgOpen ? cutoffMsg() : null} */}

                  {capHitMessage ? generalMsg('Additional items for this dish are not available.') : null}

                  {bothTimeNotSetMsgOpen ? generalMsg('Lunch and Dinner time values must be set before saving order!') : null}
                  {lunchTimeNotSetMsgOpen ? generalMsg('Lunch time values must be set before saving order!') : null}
                  {dinnerTimeNotSetMsgOpen ? generalMsg('Dinner time values must be set before saving order!') : null}

                  {lunchTimeSlotMsg ? generalMsg('Not fast enough on your feet.  That lunch time slot is already taken.  Please choose another slot.') : null}
                  {dinnerTimeSlotMsg ? generalMsg('Not fast enough on your feet.  That supper time slot is already taken.  Please choose another slot.') : null}

                  {selectOrderEdit === "" ? null : orderDialog()}

                  {noSlotSpaceDialog === null ? null : noSlotDialog()}
                  {noSaveSpaceDialog === null ? null : noSaveDialog()}

                  {xpost !== null || xget !== null ? genDialog() : null}
                  <Typography component="h4" variant="h4">
                      {translate(props.language,'1. Pick a date')}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>

          </Grid>

                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>

                            <Grid item xs={12} sm={12} md={12} lg={12}> 
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                  <DatePicker
                                      orientation={showText && "landscape"}
                                      variant="static"
                                      margin="normal"
                                      id="date-picker-dialog"
                                      label="Date picker dialog"
                                      // format="MM/dd/yyyy"
                                      format={"dddd, " + "MMMM" + ", yyyy"}
                                      value={selectedDate}
                                      onChange={handleDateChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                  />
                              {/* </LocalizationProvider> */}

                              {!menuPresent ?
                                  <Grid item xs={12}>
                                      <p></p>
                                      <Typography component="h6" variant="h6">
                                            {translate(props.language, 'No menu or service available for this date. Please choose another date.')}
                                      </Typography>
                                  </Grid>
                              : null }                                                        

                            </Grid>


                            {/* {menuPresent && (lunchBlocked || dinnerBlocked) ? */}
                            {menuPresent && (lunchBlocked && dinnerBlocked) ?
                              <Grid item xs={12}>

                                    <div className={classes.sendleft}>
                                        <Typography component="h4" variant="h4">
                                            {translate(props.language,'Order modification for this date is closed.')}
                                        </Typography>
                                    </div>

                                    <div style={{clear: "both"}}></div>

                              </Grid>

                            : null }


                            {menuPresent && choiceLunchOrDinner === 'Lunch' && lunchBlocked && !dinnerBlocked ?
                              <Grid item xs={12}>

                                    <div className={classes.sendleft}>
                                        <Typography component="h4" variant="h4">
                                            {translate(props.language,'Order modification for lunch is closed.')}
                                        </Typography>
                                    </div>

                                    <div style={{clear: "both"}}></div>

                              </Grid>

                            : null }

                            {menuPresent && choiceLunchOrDinner !== 'Lunch' && !lunchBlocked && dinnerBlocked ?
                              <Grid item xs={12}>

                                    <div className={classes.sendleft}>
                                        <Typography component="h4" variant="h4">
                                            {translate(props.language,'Order modification for dinner is closed.')}
                                        </Typography>
                                    </div>

                                    <div style={{clear: "both"}}></div>

                              </Grid>

                            : null }                            


                            {menuPresent ?
                              <Grid item xs={12}>

                                    <div className={classes.sendleft}>
                                        <Typography component="h4" variant="h4">
                                            {translate(props.language,'2. Choose lunch or dinner')}
                                        </Typography>
                                    </div>

                                    <div style={{clear: "both"}}></div>

                              </Grid>

                            : null }

                            {menuPresent ?
                              <Grid container item xs={12} sm={12} md={12} lg={12} direction='column'> 
                                <Grid item xs={6} sm={3} md={3} lg={2}> 
                                    <ToggleButtonGroup
                                      // color="primary"
                                      value={choiceLunchOrDinner}
                                      exclusive
                                      onChange={handleChooseLunchOrDinner}
                                    >
                                      <ToggleButton className={classes.toggleselected} value="Lunch" >{translate(props.language,'Lunch')}</ToggleButton>
                                      <ToggleButton className={classes.toggleselected} value="Dinner">{translate(props.language,'Dinner')}</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                              </Grid>
                            : null }


                            {menuPresent && !showUberHeader() && choiceLunchOrDinner === 'Lunch' ?
                              <Grid item xs={12}>

                                    <div className={classes.sendleft}>
                                        <Typography component="h4" variant="h4">
                                            {translate(props.language,'No lunch menu available.  Choose dinner.')}
                                        </Typography>
                                    </div>

                                    <div style={{clear: "both"}}></div>

                              </Grid>

                            : null }

                            {menuPresent && !showUberHeader() && choiceLunchOrDinner !== 'Lunch' ?
                              <Grid item xs={12}>

                                    <div className={classes.sendleft}>
                                        <Typography component="h4" variant="h4">
                                            {translate(props.language,'No dinner menu available.  Choose lunch.')}
                                        </Typography>
                                    </div>

                                    <div style={{clear: "both"}}></div>

                              </Grid>

                            : null }                            


                            {menuPresent && showUberHeader() ?
                              <Grid item xs={12}>

                                    <div className={classes.sendleft}>
                                        <Typography component="h4" variant="h4">
                                            {translate(props.language,'3. Pick a time')}
                                        </Typography>
                                    </div>

                                    <div style={{clear: "both"}}></div>

                              </Grid>

                            : null }


                            {/* {menuPresent ?
                                <Grid container item xs={12} sm={12} md={12} lg={12} direction='column'> 
                                    <Grid item xs={6} sm={3} md={3} lg={2}> 
                                        {choiceLunchOrDinner === 'Lunch' ? 
                                          <Autocomplete
                                              onChange={(event, value) => handleLunchTimeList(value)}
                                              id="combo-box-lunch"
                                              value={selectedTimeLunch.format("HH:mm") === "00:00" ? '-' : selectedTimeLunch.format("HH:mm")}
                                              options={lunchTimeList}
                                              // getOptionLabel={(option) => option.name}
                                              // style={{ width: 300 }}
                                              disabled={lunchBlocked}
                                              renderInput={(params) => <TextField {...params} label='Lunch' InputLabelProps={{
                                                  shrink: true,
                                                  style: {color: '#78cbff'},
                                              }}
                                              // variant="outlined"
                                              />} 
                                          />
                                        :
                                          <Autocomplete
                                                onChange={(event, value) => handleDinnerTimeList(value)}
                                                id="combo-box-dinner"
                                                value={selectedTimeDinner.format("HH:mm") === "00:00" ? '-' : selectedTimeDinner.format("HH:mm")}
                                                options={dinnerTimeList}
                                                disabled={dinnerBlocked}
                                                // getOptionLabel={(option) => option.name}
                                                // style={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label={translate(props.language, 'Dinner')} InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: '#78cbff'},
                                                }}
                                                // variant="outlined"
                                                />} 
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            : null } */}




                              {menuPresent && showUberHeader() ?
                                      <Grid item xs={12}>

                                        {choiceLunchOrDinner === 'Lunch' ?
                                          getLunchTimeList()
                                          : getDinnerTimeList()
                                        }

                                      </Grid>
                              : null }

                              {/* {menuPresent ?
                                      <Grid item xs={12}>
                                          {choiceLunchOrDinner === 'Lunch' ?
                                              <Slider
                                                  aria-label="Always visible1"
                                                  // defaultValue={80}
                                                  // getAriaValueText={valueLunchText}
                                                  value={sliderLunchTimeValue()}
                                                  onChange={(event, value) => handleSliderLunchTimeList(value)}
                                                  step={1}
                                                  marks={lunchMarks()}
                                                  valueLabelDisplay="on"
                                                  min={0}
                                                  max={lunchTimeList.length}
                                                  style={{color:'#78cbff'}}
                                                  disabled={lunchBlocked}
                                                  valueLabelFormat={selectedTimeLunch.format("HH:mm") === "00:00" ? '-' : selectedTimeLunch.format("HH:mm")}
                                                  className={classes.sliderman}
                                              />
                                            :
                                              <Slider
                                                  aria-label="Always visible2"
                                                  // defaultValue={80}
                                                  // getAriaValueText={valueDinnerText}
                                                  value={sliderDinnerTimeValue()}
                                                  onChange={(event, value) => handleSliderDinnerTimeList(value)}
                                                  step={1}
                                                  marks={dinnerMarks()}
                                                  valueLabelDisplay="on"
                                                  min={0}
                                                  max={dinnerTimeList.length}
                                                  style={{color:'#78cbff'}}
                                                  disabled={dinnerBlocked}
                                                  valueLabelFormat={selectedTimeDinner.format("HH:mm") === "00:00" ? '-' : selectedTimeDinner.format("HH:mm")}
                                                  className={classes.sliderman}
                                              />
                                            }
                                      </Grid>
                              : null } */}



                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>

          {showUberHeader() && menuPresent && ( (choiceLunchOrDinner === 'Lunch' && selectedTimeLunch.format("HH:mm") !== "00:00") ||  (choiceLunchOrDinner !== 'Lunch' && selectedTimeDinner.format("HH:mm") !== "00:00")) ?



                <Grid item xs={12}>

                      <div className={classes.sendleft}>
                          <Typography component="h4" variant="h4">
                              {translate(props.language,'4. Select possible menu items')}
                          </Typography>
                      </div>

                      <div style={{clear: "both"}}></div>
                </Grid>



            : null }


            {showUberHeader() && menuPresent  && ( (choiceLunchOrDinner === 'Lunch' && selectedTimeLunch.format("HH:mm") !== "00:00") ||  (choiceLunchOrDinner !== 'Lunch' && selectedTimeDinner.format("HH:mm") !== "00:00")) ?

                  <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2} direction='row'>
                      {/*  alignItems="flex-end" */}
                      <Grid item xs={12}>
                          {translate(props.language, 'Notes')} -
                      </Grid>           


                      {/* <Grid item xs={12}>
                          {translate(props.language, 'Lunchbox delivered at dinner time')}
                      </Grid>                           */}
                      <Grid item  xs={12} sm={6} md={4} lg={4}>
                        <Stack direction="row" spacing={2}>
                                <StyledBadge badgeContent={'Chef'} color="error" style={{marginTop: '-4px'}}
                                    //   anchorOrigin={{
                                    //     vertical: 'bottom',
                                    //     horizontal: 'right',
                                    //   }}
                                    >
                                        <StarIcon sx={{ color: '#daa520' }} />
                                </StyledBadge>
                                <span>&#160;</span> <span>&#160;</span>
                                {props.language !== 'FRANCAIS' ? "Chef's recommendation" : 'Le choix du chef'}
                        </Stack>
                          <img src={lunchboxImage} width="12"/>&#160;&#160;{translate(props.language, 'Lunchbox friendly')}<br></br>
                          <img src={peanutImage} width="12"/>&#160;&#160;{translate(props.language, 'May contain peanuts')}<br></br>
                          <img src={glutenfreeImage} width="12"/>&#160;&#160;{translate(props.language, 'Gluten Free')}<br></br>
                          <img src={sugarfreeImage} width="12"/>&#160;&#160;{translate(props.language, 'Sugar Free')}<br></br>
                      </Grid>                            
                      <Grid item  xs={12} sm={6} md={4} lg={4}>
                          <img src={vegetarianImage} width="12"/>&#160;&#160;{translate(props.language, 'Vegetarian')}<br></br>
                          <img src={veganImage} width="12"/>&#160;&#160;{translate(props.language, 'Vegan')}<br></br>
                          {/* <img src={spicelevel0Image} width="6"/>&#160; */}
                          <img src={spicelevel1Image} width="6"/>&#160;<img src={spicelevel1Image} width="6"/><img src={spicelevel1Image} width="6"/>&#160;&#160;{translate(props.language, 'Spice Level')}<br></br>
                          <img src={babyfriendlyImage} width="12"/>&#160;&#160;{translate(props.language, 'Baby Friendly')}<br></br>
                          <span style={{color: '#b06942'}}>cal</span>&#160;&#160;Calories<br></br>
                      </Grid>

                  </Grid>


              : null }                             


          {/*   Starters Section */}

          {showSectionHeader('Starter')  && ( (choiceLunchOrDinner === 'Lunch' && selectedTimeLunch.format("HH:mm") !== "00:00") ||  (choiceLunchOrDinner !== 'Lunch' && selectedTimeDinner.format("HH:mm") !== "00:00")) ?
            <Grid item xs={12}>
                          <br />
                          <div style={{marginRight: '15px',  backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Starters')} &#8198;</div>
              <Grid container spacing={3}>
                <Grid item xs={12} >
                    {/* {orderTable("Starter")}
                    <p><small>* {translate(props.language, 'Click on menu item to edit quantity.  Sides are indented.')}</small></p> */}
                    <p></p>
                    <p><small>* {translate(props.language, 'Click on info to see description, sides and ingredients.')}</small></p>
                    {ACardTable("Starters")}
                </Grid>
              </Grid>


            </Grid>
            : null
          }

          {/* <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {cardTable("Starters")}
                    </Grid>
                  </Grid>
              : null }
          </Grid> */}
          {/* End of Starters Section

          {/* Start of Mains Section */}
          {showSectionHeader('Main')  && ( (choiceLunchOrDinner === 'Lunch' && selectedTimeLunch.format("HH:mm") !== "00:00") ||  (choiceLunchOrDinner !== 'Lunch' && selectedTimeDinner.format("HH:mm") !== "00:00")) ?
            <Grid item xs={12}>
                          <br />
                          <div style={{marginRight: '15px',  backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Main Dishes')} &#8198;</div>
              <Grid container spacing={3}>
                <Grid item xs={12} >
                    {/* {orderTable("Main")} */}
                    {/* <p><small>* {translate(props.language, 'Click on menu item to edit quantity.  Sides are indented.')}</small></p> */}
                    <p></p>
                    <p><small>* {translate(props.language, 'Click on info to see description, sides and ingredients.')}</small></p>
                    {ACardTable("Mains")}
                </Grid>
              </Grid>

            </Grid>
            : null
          }


          {/* <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {cardTable("Mains")}
                        <p></p>
                    </Grid>
                  </Grid>
              : null }
          </Grid> */}



          {/* End of Mains Section */}

          {/* Beginning of Desserts */}
          {showSectionHeader('Dessert')  && ( (choiceLunchOrDinner === 'Lunch' && selectedTimeLunch.format("HH:mm") !== "00:00") ||  (choiceLunchOrDinner !== 'Lunch' && selectedTimeDinner.format("HH:mm") !== "00:00")) ?
            <Grid item xs={12}>
                          <br />
                          <div style={{marginRight: '15px',  backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Desserts')} &#8198;</div>
              <Grid container spacing={3}>
                <Grid item xs={12} >
                    {/* {orderTable("Dessert")}
                    <p><small>* {translate(props.language, 'Click on menu item to edit quantity.  Sides are indented.')}</small></p> */}
                    <p></p>
                    <p><small>* {translate(props.language, 'Click on info to see description, sides and ingredients.')}</small></p>
                    {ACardTable("Desserts")}
                </Grid>
              </Grid>

            </Grid>
            : null
          }


          {/* <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {cardTable("Desserts")}
                    </Grid>
                  </Grid>
              : null }
          </Grid> */}



          {/* End of Desserts */}

          {/* Start of Drinks */}
          {showSectionHeader('Drink')  && ( (choiceLunchOrDinner === 'Lunch' && selectedTimeLunch.format("HH:mm") !== "00:00") ||  (choiceLunchOrDinner !== 'Lunch' && selectedTimeDinner.format("HH:mm") !== "00:00")) ?
            <Grid item xs={12}>
                          <br />
                          <div style={{marginRight: '15px',  backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Drinks')} &#8198;</div>
              <Grid container spacing={3}>
                <Grid item xs={12} >
                    {/* {orderTable("Drink")}
                    <p><small>* {translate(props.language, 'Click on menu item to edit quantity.  Sides are indented.')}</small></p> */}
                    <p></p>
                    <p><small>* {translate(props.language, 'Click on info to see description, sides and ingredients.')}</small></p>
                    {ACardTable("Drinks")}
                </Grid>
              </Grid>
            </Grid>
            : null
          }         

          {/* <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {cardTable("Drinks")}
                    </Grid>
                  </Grid>
              : null }
          </Grid> */}


          {/* End of Drinks */}

          {showSectionHeader('Fruit')  && ( (choiceLunchOrDinner === 'Lunch' && selectedTimeLunch.format("HH:mm") !== "00:00") ||  (choiceLunchOrDinner !== 'Lunch' && selectedTimeDinner.format("HH:mm") !== "00:00")) ?
            <Grid item xs={12}>
                          <br />
                          <div style={{marginRight: '15px',  backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Fruits')} &#8198;</div>
              <Grid container spacing={3}>
                <Grid item xs={12} >
                    {/* {orderTable("Fruit")}
                    <p><small>* {translate(props.language, 'Click on menu item to edit quantity.  Sides are indented.')}</small></p> */}
                    <p></p>
                    <p><small>* {translate(props.language, 'Click on info to see description, sides and ingredients.')}</small></p>
                    {ACardTable("Fruits")}
                </Grid>
              </Grid>
            </Grid>
            : null
          }         

          {/* <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {cardTable("Fruits")}
                    </Grid>
                  </Grid>
              : null }
          </Grid> */}

          {/* End of Fruits */}

          {/* Start of Check Balances */}

          {menuPresent && summaryList.length > 5 ?
              <Grid item xs={12}>
                  <div className={classes.sendleft}>

                      <Typography component="h4" variant="h4">
                      {translate(props.language,'5. Here is your order summary')}
                      </Typography>
                  </div>
                  <div style={{clear: "both"}}></div>
              </Grid>
          : null}
          {menuPresent  && summaryList.length > 5 ?
              <Grid item xs={12}>

                  { mealDB ?     
                        <Grid item xs={12} >
                            {summaryTable()}

                        </Grid>
                  : null }
              </Grid>
          : null }

          {/* End of Check Balances */}

          {/* Upload and Place Order Section */}
          {/* {!lunchBlocked && !dinnerBlocked   && summaryList.length > 5 ? */}
          {(!lunchBlocked || !dinnerBlocked)   && summaryList.length > 5 ?

                  <Grid item xs={12} >          

                    {/* {showCancelClearButtons() ?
                        <Grid item xs={3} >
                              <p></p>
                              <Button
                                  variant="contained"
                                  // color="primary"
                                  size="small"
                                  className={classes.button}
                                  onClick={handleCancelOrder}
                                  disabled={false}
                                  aria-label="whatever 116"
                                  style={{backgroundColor: '#78cbff', color:"white"}}
                              >
                                  {translate(props.language, 'Cancel Order')}
                              </Button>        

                        </Grid>
                      : <Grid  item xs={3} ></Grid>
                    } */}

                    {/* {showCancelClearButtons() ?
                        <Grid item xs={3} >
                              <p></p>
                              <Button
                                  variant="contained"
                                  // color="primary"
                                  size="small"
                                  className={classes.button}
                                  onClick={handleClearAll}
                                  disabled={false}
                                  aria-label="whatever 1"
                                  style={{backgroundColor: '#78cbff', color:"white"}}
                              >
                                  {translate(props.language, 'Clear All')}
                              </Button>        

                              <p></p>
                        </Grid>
                      : <Grid  item xs={3} ></Grid>
                    }   */}


                    {menuPresent   && summaryList.length > 5 ?
                      <Grid item xs={12}>
                          <Grid item xs={12}>

                          <div className={classes.sendleft}>
                              <Typography component="h4" variant="h4">
                                  {translate(props.language,'6. Save or cancel your order')}
                              </Typography>
                          </div>

                          <div style={{clear: "both"}}></div>
                          </Grid>

                    
                          <Grid item xs={3} >
                            <p></p>
                            <Button
                                variant="contained"
                                // color="primary"
                                size="small"
                                className={classes.button}
                                onClick={handleSaveOrder}
                                disabled={false}
                                aria-label="whatever 1"
                                style={{backgroundColor: '#78cbff', color:"white"}}
                            >
                                {translate(props.language, 'Save Order')}
                            </Button>        
                          </Grid>

                          <Grid item xs={3} >
                              <p></p>
                              <Button
                                  variant="contained"
                                  // color="primary"
                                  size="small"
                                  className={classes.button}
                                  onClick={handleCancelOrder}
                                  disabled={false}
                                  aria-label="whatever 116"
                                  style={{backgroundColor: '#78cbff', color:"white"}}
                              >
                                  {translate(props.language, 'Cancel Order')}
                              </Button>        

                        </Grid>

                      </Grid>

                        : null }

                        <Grid item xs={3} ></Grid>
                  </Grid>
          
          : null}
          <div><p></p></div>

          {/* {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null} */}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


