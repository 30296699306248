import axios from "axios";
// import React from "react";
// import jsonData from './shelf.json';

import config from './YPCconfig.js';

export default async function YPCGetAxiosAlt(xget, setXGet, url, id) {


    let baseURL = '';

    if (url === 'shelfURL') {
        baseURL = config.shelfURL + id.toString();
    };
    if (url === 'recipeURL') {
        baseURL = config.recipeURL;
    };
    if (url === 'conciergeRecipesURL') {
        baseURL = config.conciergeRecipesURL;
    };
    if (url === 'conciergeMenuGetURL') {
        baseURL = config.conciergeMenuGetURL + id;
    };
    if (url === 'conciergeOrderGetURL') {
        baseURL = config.conciergeOrderGetURL + id;
    };
    if (url === 'allIngredientsURL') {
        baseURL = config.allIngredientsURL;
    };
    if (url === 'getProxyallIngredientsURL') {
        baseURL = config.getProxyallIngredientsURL;
    };
    if (url === 'allIngredientsFullURL') {
        baseURL = config.allIngredientsFullURL;
    };
    if (url === 'getProxyallIngredientsFullURL') {
        baseURL = config.getProxyallIngredientsFullURL;
    };
    if (url === 'allRecipesURL') {
        baseURL = config.allRecipesURL;
    };
    if (url === 'getProxyallRecipesURL') {
        baseURL = config.getProxyallRecipesURL;
    };
    if (url === 'getRecipeURL') {
        baseURL = config.getRecipeURL + id;
    };
    if (url === 'getProxygetRecipeNullURL') {
        baseURL = config.getProxygetRecipeNullURL + id;
    };
    if (url === 'getProxygetRecipeURL') {
        baseURL = config.getProxygetRecipeURL + id;
    };
    if (url === 'allRecipesCategoryURL') {
        baseURL = config.allRecipesCategoryURL;
    };
    if (url === 'getProxyallRecipesCategoryURL') {
        baseURL = config.getProxyallRecipesCategoryURL;
    };
    if (url === 'getIngredientURL') {
        baseURL = config.getIngredientURL + id;
    };
    if (url === 'getProxygetIngredientURL') {
        baseURL = config.getProxygetIngredientURL + id;
    };
    if (url === 'allRecipesByCategoryURL') {
        baseURL = config.allRecipesByCategoryURL + id;
    };
    if (url === 'getProxyallRecipesByCategoryURL') {
        baseURL = config.getProxyallRecipesByCategoryURL + id;
    };
    if (url === 'getMenuURL') {
        baseURL = config.getMenuURL + id;
    };
    if (url === 'getXMenuURL') {
        baseURL = config.getXMenuURL + id;
    };
    if (url === 'testMenuURL') {
        baseURL = config.testMenuURL;
    };
    if (url === 'getOrderURL') {
        baseURL = config.getOrderURL + '?datestring=' + id[0] + '&username=' + id[1];
    };
    if (url === 'getXOrderURL') {
        baseURL = config.getXOrderURL + '?datestring=' + id[0] + '&username=' + id[1];
    };
    if (url === 'getOrdersAllURL') {
        baseURL = config.getOrdersAllURL + '?datestring=' + id;
    };
    if (url === 'getDaysOrdersURL') {
        baseURL = config.getDaysOrdersURL + id;
    };
    if (url === 'getUsernamesURL') {
        baseURL = config.getUsernamesURL;
    };
    if (url === 'getUsernamesIDsURL') {
        baseURL = config.getUsernamesIDsURL;
    };
    if (url === 'allRecipesWithTagsURL') {
        baseURL = config.allRecipesWithTagsURL + id;
    };
    if (url === 'getProxyallRecipesWithTagsURL') {
        baseURL = config.getProxyallRecipesWithTagsURL + id;
    };
    if (url === 'getXSubsciptionTypeURL') {
        baseURL = config.getXSubsciptionTypeURL;
    };
    if (url === 'getSubsciptionTypeURL') {
        baseURL = config.getSubsciptionTypeURL + '?username=' + id;
    };
    if (url === 'getProxygetCheckingPurchaseListURL') {
        baseURL = config.getProxygetCheckingPurchaseListURL + id;
    };
    if (url === 'getProxyShelfURL') {
        baseURL = config.getProxyShelfURL + id;
    };
    if (url === 'getProxyFetchDishOrdersURL') {
        baseURL = config.getProxyFetchDishOrdersURL + id;
    };
    if (url === 'getXPaymentURL') {
        baseURL = config.getXPaymentURL + '?datestring=' + id[0] + '&username=' + id[1];
    };
    if (url === 'getWeeklyUnpaidOrdersURL') {
        baseURL = config.getWeeklyUnpaidOrdersURL + '?datestring=' + id;
    };
    if (url === 'getXOrderSlotsURL') {
        baseURL = config.getXOrderSlotsURL + id;
    };
    if (url === 'getXSubsTypeCompHistoryURL') {
        baseURL = config.getXSubsTypeCompHistoryURL + id;
    };
    if (url === 'getSubsTypeCompHistoryURL') {
        baseURL = config.getSubsTypeCompHistoryURL  + '?datestring=' + id[0] + '&username=' + id[1];
    };    
    if (url === 'getAllSubsTypeCompHistoriesURL') {
        baseURL = config.getAllSubsTypeCompHistoriesURL  + '?datestring=' + id;
    };
    if (url === 'getMiscBlockedURL') {
        baseURL = config.getMiscBlockedURL  + '?datestring=' + id[0] + '&reason=' + id[1];
    }; 
    if (url === 'getXMiscBlockedURL') {
        baseURL = config.getXMiscBlockedURL  + '?datestring=' + id[0] + '&reason=' + id[1];
    };
    if (url === 'getAllCustomerReturnsURL') {
        baseURL = config.getAllCustomerReturnsURL;
    };
    if (url === 'getProxyDishOrdersURL') {
        baseURL = config.getProxyDishOrdersURL;
    };
    if (url === 'getXOrderSlotWeightsURL') {
        baseURL = config.getXOrderSlotWeightsURL + id;
    };
    if (url === 'getCompaniesURL') {
        baseURL = config.getCompaniesURL;
    };
    if (url === 'getXCompanyNameURL') {
        baseURL = config.getXCompanyNameURL;
    };
    if (url === 'getCompanyNameURL') {
        baseURL = config.getCompanyNameURL + '?username=' + id;
    };
    if (url === 'getMiscTimeListsURL') {
        baseURL = config.getMiscTimeListsURL  + '?datestring=' + id[0] + '&reason=' + id[1];
    };  
    if (url === 'getMiscCostPriceListsURL') {
        baseURL = config.getMiscCostPriceListsURL  + '?datestring=' + id[0] + '&reason=' + id[1];
    };  
    if (url === 'getXMiscTimeListsURL') {
        baseURL = config.getXMiscTimeListsURL  + '?datestring=' + id[0] + '&reason=' + id[1];
    };
    if (url === 'getAllUserDataURL') {
        baseURL = config.getAllUserDataURL;
    };
    if (url === 'getXMenuOrderCapURL') {
        baseURL = config.getXMenuOrderCapURL + id;
    };
    if (url === 'getAllSurveysURL') {
        baseURL = config.getAllSurveysURL;
    };
    if (url === 'getXDateSubscriptionTypeURL') {
        baseURL = config.getXDateSubscriptionTypeURL + id;
    };
    if (url === 'getDateSubscriptionTypeURL') {
        baseURL = config.getDateSubscriptionTypeURL  + '?datestring=' + id[0] + '&username=' + id[1];
    };    
    if (url === 'getAllDateSubscriptionTypeURL') {
        baseURL = config.getAllDateSubscriptionTypeURL  + '?datestring=' + id;
    };
    if (url === 'getXWeeklyCompanyOrdersURL') {
        baseURL = config.getXWeeklyCompanyOrdersURL + '?datestring=' + id;
    };    

    console.log('AXAXAXAXAXAXP', baseURL, id);

    return new Promise(function (resolve, reject) {

        let token = localStorage.getItem('token');
        let userName = localStorage.getItem('userName')

         axios
            // .put(baseURL, {'token': token, 'userName': userName})
            .put(baseURL, {'token': token, 'username': userName})
            // .get(baseURL, {headers: {'Authorization': `Token ${token}`}})
            .then((response) => {
                console.log('GGGGGGGGGGGGGet', response.data);
                    if (response.status === 200) {
                        // setXGet(JSON.parse(response.data)   );                    
                        // setXGet(JSON.stringify(response.data, null, 2));
                        // setXGet(response.data);
                        setXGet(null);
                    }
                    else {
                        setXGet('Error.  Fix and reload!');
                    }
                var result = response.data;
                resolve(result);

            }).catch(error => {
                console.log('EEEEEEEEE',error);

                if ('response' in error) {
                    // console.log('FFFFFFFFFFF', error.response);
                    if (error.response === undefined) {
                        setXGet('Error.  Contact YPC Help Line\n');
                    } else if ('data' in error.response) {
                        if ('detail' in error.response.data) {
                            setXGet('Error loading from DB!\n ' + error.response.data.detail);
                        } else {
                            setXGet('Error.  Fix and reload! \n');
                        }
                    } else {
                        setXGet('Error.  Fix and reload! \n');
                    }
                } else {
                    setXGet('Error.  Fix and reload! \n');
                };

                reject(error);
            });

        });



}
