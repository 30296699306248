import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';

import translate from "./YPCTranslate";

// import Aimage from '../images/A.jpg';
// import Bimage from '../images/B.jpg';
// import Cimage from '../images/C.jpg';
// import Dimage from '../images/D.jpg';
// import Eimage from '../images/E.jpg';
// import Fimage from '../images/F.jpg';
// import Gimage from '../images/G.jpg';
// import Himage from '../images/H.jpg';
// import Iimage from '../images/I.jpg';
// import Jimage from '../images/J.jpg';
// import Kimage from '../images/K.jpg';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3cb8b1',
    },      
    secondary: {
      main: '#3cb8b1'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "75%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));

// const imageStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//     overflow: 'hidden',
//     backgroundColor: theme.palette.background.paper,
//   },
//   imageList: {
//     width: 1000,
//     height: 450,
//   },
// }));




export default function YPCHome(props) {

  const classes = useStyles();
  // const theme = useTheme();
  


//   React.useEffect(() => {
//     if (!menuLoaded) {
//       handleLoadDB();
//     }
//   }, [menuLoaded]);


    // function TitlebarImageList() {
    //   const imageclasses = imageStyles();
    //   return (
    //     <div className={imageclasses.root}>
    //       <ImageList rowHeight={160} className={imageclasses.imageList} cols={3}>
    //         {itemData.map((item) => (
    //           <ImageListItem key={item.img} cols={item.cols || 1}>
    //             <img src={item.img} alt={item.title} />
    //           </ImageListItem>
    //         ))}
    //       </ImageList>
    //     </div>
    //   );

    // }

    console.log(props.language)

    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2}>
          <Grid item xs={12}>

                  <Typography component="h4" variant="h4">
                      {translate(props.language,'Home Screen')}
                  </Typography>
          </Grid>
          <Grid item xs={12}>
        {/* {TitlebarImageList()} */}
        </Grid>

        </Grid>
      </Container>

      </ React.Fragment>


    );
}


// const itemData = [
//   {
//     img: Aimage,
//     title: 'AImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Bimage,
//     title: 'BImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Cimage,
//     title: 'CImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Dimage,
//     title: 'DImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Eimage,
//     title: 'EImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Fimage,
//     title: 'FImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Gimage,
//     title: 'GImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Himage,
//     title: 'HImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Iimage,
//     title: 'IImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Jimage,
//     title: 'JImage',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: Kimage,
//     title: 'KImage',
//     author: 'author',
//     cols: 2,
//   },
// ];
