import axios from "axios";
import React from "react";
import config from './YPCconfig.js';


export default function YPCPutAxios2(setXPost, url, content, id) {

    let baseURL = '';

    if (url === 'conciergeSaveURL') {
        baseURL = config.conciergeSaveURL + id.toString();
    };
    if (url === 'conciergeSaveOrderURL') {
        baseURL = config.conciergeSaveOrderURL + id.toString();
    };
    if (url === 'saveRecipeURL') {
        baseURL = config.saveRecipeURL;
    };
    if (url === 'saveIngredientURL') {
        baseURL = config.saveIngredientURL;
    };
    if (url === 'saveMenuURL') {
        baseURL = config.saveMenuURL;
    };
    if (url === 'testMenuURL') {
        baseURL = config.testMenuURL;
    };
    if (url === 'saveOrderURL') {
        baseURL = config.saveOrderURL;
    };
    if (url === 'sendXKitchinEmailResetPasswordURL') {
        baseURL = config.sendXKitchinEmailResetPasswordURL;
    };
    if (url === 'putXChangeResetPasswordURL') {
        baseURL = config.putXChangeResetPasswordURL;
    };
    if (url === 'saveSurveyURL') {
        baseURL = config.saveSurveyURL + id;
    };
    if (url === 'printContainerTicketURL') {
        baseURL = config.printContainerTicketURL;
    };

    return new Promise(function (resolve, reject) {
        axios
        .put(baseURL, 
            content
        )
        .then((response) => {
            console.log('PPPPutOrderAxios', response.data.data);
                if (response.status == 200) {
                    setXPost('Saved to DB');
                    // setXPost(response.data.data);
                }
                else {
                    setXPost('Error saving to DB!');
                    // setXPost('Error');
                }
            var result = response.data;
            resolve(result);

        }).catch(error => {
            console.log('EEEEEEEEE',error);
            if ('response' in error) {
                if ('data' in error.response) {
                    if ('detail' in error.response.data) {
                        setXPost('Error saving to DB!\n ' + error.response.data.detail);
                    } else {
                        setXPost('Error saving to DB!');
                    }
                } else {
                    setXPost('Error saving to DB!');
                }
            } else {
                setXPost('Error saving to DB!');
            };
            // setXPost('Error');
            reject(error);
        });

    });
}

