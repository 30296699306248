import 'date-fns';
import React from 'react';
import './altstyles.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ShareIcon from '@material-ui/icons/Share';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import {Container} from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DoneIcon from '@mui/icons-material/Done';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import SyncIcon from '@mui/icons-material/Sync';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import MuiAlert from '@material-ui/lab/Alert';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import translate from "./YPCTranslate";
import moment from 'moment';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));
  
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const icons = {
      1: <SyncIcon />,  // Connect to robot control
      2: <HistoryToggleOffIcon />,  // Run Scheduler
      3: <RunningWithErrorsIcon />,  // Robot Cooking
      4: <ShareIcon />,  //  Disconnect
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? <DoneIcon /> : icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
    
  const steps = [
    {
      label: 'Step 1 - Connect Robot Ctrl',
      description: `Connect Robot Ctrl`,
      heading: 'Connect Robot Ctrl',
      subheading: 'Connect Robot Ctrl',
    },
    {
      label: 'Step 2 - Scheduler',
      description: `Scheduler`,
      heading: 'Scheduler',
      subheading: 'Scheduler',
    },
    {
      label: 'Step 3 - Robot Cooking',
      description: `Robot Cooking`,
      heading: 'Robot Cooking',
      subheading: 'Robot Cooking',
    },
    {
      label: 'Step 4 - Disconnect',
      description: `Disconnect`,
      heading: 'Disconnect',
      subheading: 'Disconnect',
    },
  ];



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      backgroundColor: 'white',
      height: 'auto',
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      fontSize: 'large',
    },
    image: {
      float: 'left',
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));



export default function YPCRobotCook(props) {

  const classes = useStyles();

  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [activeStep, setActiveStep] = React.useState(0);
  const activeStepRef = React.useRef(activeStep);
  activeStepRef.current = activeStep;

  const [cookingState, setCookingState] = React.useState({'state': 'Inactive'});
  const cookingStateRef = React.useRef(cookingState);
  cookingStateRef.current = cookingState;

  const [commandControl, setCommandControl] = React.useState(null);
  const commandControlRef = React.useRef(commandControl);
  commandControlRef.current = commandControl;


  const [error, setError] = React.useState(false);

  const [connected, setConnected] = React.useState(false);
  const [connectedMsg, setConnectedMsg] = React.useState("");

  const [errorMsg, setErrorMsg] = React.useState("");

  const connectedRef = React.useRef(connected);
  connectedRef.current = connected;

  const [textBox, setTextBox] = React.useState('');
  const textBoxRef = React.useRef(textBox);
  textBoxRef.current = textBox;


  const [pumpFridge, setPumpFridge] = React.useState('Fridge 1');

  const pumpFridgeList = ['Fridge 1', 'Fridge 2', 'Pump 0', 'Pump 1', 'Pump 2', 'Pump 3', 'Pump 4', 'Pump 5', 'Pump 6', 'Pump 7', 'Pump 8', 'Pump 9', 'Pump 10', 'Pump 11'];

  const [lastCommandSent, setLastCommandSent] = React.useState("")

  const [indicators, setIndicators] = React.useState({"cooking_machine_1": {"time" : "", "color":"black"}, "cooking_machine_2": {"time" : "", "color":"black"}, 
                                                      "cooking_machine_3": {"time" : "", "color":"black"}, "cooking_machine_4": {"time" : "", "color":"black"}, 
                                                      "cooking_machine_5": {"time" : "", "color":"black"}, "cooking_machine_6": {"time" : "", "color":"black"}, 
                                                      "cooking_machine_7": {"time" : "", "color":"black"}, "cooking_machine_8": {"time" : "", "color":"black"}, 
                                                      "cooking_machine_9": {"time" : "", "color":"black"}, 
                                                      'robot_axis' : 'black', 
                                                      'liquid_axis': 'black', 
                                                      'gripper' : 'black', 
                                                      'robot_arm': 'black'});

  const [runningStatus, setRunningStatus] = React.useState({"motion": false});

  const colorDict = {'black' : "#000000", 'green': "#00873E", 'orange': "#ffa500"};
  const [startNow, setStartNow] = React.useState(false);


  React.useEffect(() => {
    setInterval(() => {
      // Used for controlling fridges and pumps
      if (commandControlRef.current !== null) {
        commandControlStatus();
      }
      // Used for the others steps
      else if (cookingStateRef.current.state !== 'Inactive') {
        handleMsg(cookingStateRef.current.state);
      }
      else {
        serverConnectStatus();
      };
    }, 2500);
  }, []);


  async function Comms (command) {
    if (!connectedRef.current) {
      setConnected(false);
      setConnectedMsg('Comms - tried to send msg when not connected')
      return {'command': command, 'state': -1};
    };

    let baseNumber = Math.floor(Math.random() * 100);
    let testBuffer = {'command': command, 'value': baseNumber};

    if (cookingStateRef.current.hasOwnProperty('data')) {
      testBuffer['data'] = cookingStateRef.current.data;
    };

    if (cookingStateRef.current.hasOwnProperty('start_now')) {
      testBuffer['start_now'] = cookingStateRef.current.start_now;
    };

    setLastCommandSent(command)

    let recBuffer =  await YPCPutAxios2Alt(setXPost, 'commsRobotControlURL', testBuffer, '');

    if (recBuffer.response === undefined) {
      setConnected(false);
      setConnectedMsg('Comms - undefined')
      return {'command': command, 'state': -1};
    } else if (recBuffer.response.hasOwnProperty('value')) {
      if (recBuffer.response.value === baseNumber + 3) {
        setConnected(true);
        setConnectedMsg("")
        if (recBuffer.response.hasOwnProperty('text')) {
          setTextBox(textBoxRef.current + recBuffer.response.text);
        };
        if (recBuffer.response.hasOwnProperty('hardware')) {
          setIndicators(recBuffer.response.hardware);
        };
        if (recBuffer.response.hasOwnProperty('running_status')) {
          setRunningStatus(recBuffer.response.running_status);
        };
        return recBuffer.response.status
      } else {
        setConnected(false);
        setConnectedMsg('Comms - bad response')
        return {'command': command, 'state': -1};
      };
    } else {
      setConnected(false);
      setConnectedMsg('Comms - unknown')
      return {'command': command, 'state': -1};
    };

  }

  async function handleMsg () {
    if (cookingStateRef.current.state === 'Connect') {
      let status = await Comms('connect');
      if (status.state === -1 || status.command !== 'connect') {
        setError(true);
        setErrorMsg('Handle Msg, state:' + status.state + ', command:' + status.command);
      }
      else if (status.state === 1) {
          console.log('Connecting to motion.py');
          setError(false);
          setErrorMsg("");
      } else if (status.state === 2) {
          console.log('Connected to motion.py');
          setCookingState({'state':'Inactive'});
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setError(false);
          setErrorMsg("");
      };    
    } else if (cookingStateRef.current.state === 'Peak_schedule') {
      let status = await Comms('scheduling_peak_cooking');
      if (status.state === -1 || status.command !== 'scheduling_peak_cooking') {
        setError(true);
        setErrorMsg('Handle Msg, state:' + status.state + ', command:' + status.command);
      }
      else if (status.state === 1) {
          console.log('Running Scheduler');
          setError(false);
          setErrorMsg("");
      } else if (status.state === 2) {
          console.log('Scheduler is done running');
          setCookingState({'state':'Inactive'});
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setError(false);
          setErrorMsg("");
      };      
    } else if (cookingStateRef.current.state === 'Peak_cooking') {
      let status = await Comms('peak_cooking');
      if (status.state === -1 || status.command !== 'peak_cooking') {
        setError(true);
        setErrorMsg('Handle Msg, state:' + status.state + ', command:' + status.command);
      }
      else if (status.state === 1) {
          console.log('Running Cooking');
          setError(false);
          setErrorMsg("");
      } else if (status.state === 2) {
          console.log('Cooking is done running');
          setCookingState({'state':'Inactive'});
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setError(false);
          setErrorMsg("");
      };      
    } else if (cookingStateRef.current.state === 'Disconnect') {
      let status = await Comms('disconnect');
      if (status.state === -1 || status.command !== 'disconnect') {
        setError(true);
        setErrorMsg('Handle Msg, state:' + status.state + ', command:' + status.command);
      }
      else if (status.state === 1) {
          console.log('Disconnecting');
          setError(false);
          setErrorMsg("");
      } else if (status.state === 2) {
          console.log('You are disconnected');
          setCookingState({'state':'Inactive'});
          setActiveStep(0);
          setTextBox("");
          setError(false);
          setErrorMsg("");
      };
    } else {}
  };


  async function handleFridgePump (btn) {
    let pumpFridgeArray = pumpFridge.split(" ");
    let chanNum = parseInt(pumpFridgeArray[1]);
    let utilType = pumpFridgeArray[0];
    let action = null;
    if (utilType === 'Pump' && btn === 'left') {
      action = 'On';
    } else if (utilType === 'Pump' && btn === 'right') {
      action = 'Off';
    } else if (utilType === 'Fridge' && btn === 'left') {
      action = 'Open';
    } else if (utilType === 'Fridge' && btn === 'right') {
      action = 'Close';
    } 
    
    setCommandControl({'unit': utilType, 'chan': chanNum, 'action': action})
  };

  async function handleNextStep () {
    // Place orders step    
    if (activeStep === 0) {
        setCookingState({'state':'Connect'});
    } 
    // Scheduling step
    else if (activeStep === 1) {
          setCookingState({'state':'Peak_schedule', 'start_now': startNow});
    } 
    // Cooking step
    else if (activeStep === 2) {
          setCookingState({'state':'Peak_cooking'});
    }
    // Disconnecting step
    else if (activeStep === 3) {
          setCookingState({'state':'Disconnect'});
    } 
    else if (activeStep > 4) {
          setCookingState({'state':'Inactive'})          
    };
  };

  // Used for controlling pumps and fridges
  async function commandControlStatus() {
    let baseNumber = Math.floor(Math.random() * 100);
    let testBuffer = {'command': 'command_control', 'value': baseNumber}
    testBuffer['control_data'] = commandControlRef.current;
    setCommandControl(null);
    setXPost(null);
    let recBuffer =  await YPCPutAxios2Alt(setXPost, 'commsRobotControlURL', testBuffer, '');
    if (recBuffer.response === undefined) {
      setConnected(false);
      setConnectedMsg('Command Control - undefined')
    } else if (recBuffer.response.hasOwnProperty('value')) {
      if (recBuffer.response.value === baseNumber + 3) {
        setConnected(true);
        setConnectedMsg("")
        if (recBuffer.response.hasOwnProperty('text')) {
          setTextBox(textBoxRef.current + recBuffer.response.text);
        };
        if (recBuffer.response.hasOwnProperty('hardware')) {
          setIndicators(recBuffer.response.hardware);
        };
        if (recBuffer.response.hasOwnProperty('running_status')) {
          setRunningStatus(recBuffer.response.running_status);
        };
      } else {
        setConnected(false);
        setConnectedMsg('Command Control - bad response')
      };
    } else {
      setConnected(false);
      setConnectedMsg('Command Control - unknown')
    }
    setXPost(null);
  };


  // Used for calling the default server status endpoint
  async function serverConnectStatus() {
    let baseNumber = Math.floor(Math.random() * 100);
    let testBuffer = {'command': 'server_status', 'value': baseNumber}
    setXPost(null);
    let recBuffer =  await YPCPutAxios2Alt(setXPost, 'commsRobotControlURL', testBuffer, '');
    if (recBuffer.response === undefined) {
      setConnected(false);
      setConnectedMsg('Server Connection - undefined')
    } else if (recBuffer.response.hasOwnProperty('value')) {
      if (recBuffer.response.value === baseNumber + 3) {
        setConnected(true);
        if (recBuffer.response.hasOwnProperty('text')) {
          setTextBox(textBoxRef.current + recBuffer.response.text);
        };
        if (recBuffer.response.hasOwnProperty('hardware')) {
          setIndicators(recBuffer.response.hardware);
        };
        if (recBuffer.response.hasOwnProperty('running_status')) {
          setRunningStatus(recBuffer.response.running_status);
        };
      } else {
        setConnected(false);
        setConnectedMsg('Server Connection - bad response')
      };
    } else {
      setConnected(false);
      setConnectedMsg('Server Connection - unknown')
    }
    setXPost(null);
  };


  // Visual color indicator for cooking machines
  function cookingMachineIndicators() {
      let machineList = [];
      let backColor = 'black';
      for (let c = 1; c < 10; c++) {
        backColor = colorDict[indicators['cooking_machine_' + c.toString()]['color']];
        machineList.push(
          <Grid item xs={1}>
                  <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          disabled={false}
                          aria-label="next stepxx"
                          style={{backgroundColor: backColor, color: 'white'}}
                          >
                          {'Machine ' + c.toString()}
                  </Button>
          </Grid>

        );
      };
      return machineList;
  };

  // Visual time remaining for cooking machines
  function cookingMachineTimeIndicators() {
    let machineList = [];
    let showTime = '00:00'
    for (let c = 1; c < 10; c++) {
      showTime = indicators['cooking_machine_' + c.toString()]['time'];
      machineList.push(
            <Grid item xs={1}>
                <Typography component="h5" variant="h5" >
                    {showTime}
                </Typography>
            </Grid>
      );
    };
    return machineList;
};

  // Visual color indicators for others hardware components
  function robotIndicators() {
    let robotList = [];
    let backColor = 'black';
    ['robot_axis', 'liquid_axis', 'gripper', 'robot_arm'].map((value) => {
      backColor = colorDict[indicators[value]];
      robotList.push(
            <Grid item xs={1}>
                <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        disabled={false}
                        aria-label="next stepxx"
                        style={{backgroundColor: backColor, color: 'white'}}
                        >
                        {value}
                </Button>
            </Grid>
      );
    });
    return robotList;
};
  
    // Layout
    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  <Typography component="h4" variant="h4">
                      Cooking Steps - {connected ? 'Connected' : 'Not Connected'}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>


          {connected ? null :
              <Grid item xs={12}>
                  <div className={classes.sendleft}>
                      <Typography component="h6" variant="h6">
                          {connectedMsg}
                      </Typography>
                  </div>
                  <div style={{clear: "both"}}></div>
              </Grid>
          }

          <Grid item xs={12}>
                        <div><p></p></div>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>

                            {steps.map((step) => (
                                <Step key={step.label} >
                                  <StepLabel StepIconComponent={ColorlibStepIcon} >{step.label}</StepLabel>
                                </Step>
                            ))}

                        </Stepper>
                    </Stack>
                    <div><p></p></div>
                    <Typography>{steps[activeStep].description}</Typography>
                    <div><p></p></div>
                    <div>
                             <Grid item xs={12}>
                                <Grid item xs={1}>
                                      <Button
                                          variant="outlined"
                                          size="small"
                                          className={classes.button}
                                          onClick={handleNextStep}
                                          disabled={false}
                                          aria-label="next step"
                                          style={{backgroundColor: '#78cbff', color: 'white'}}
                                      >
                                          {activeStep === steps.length - 1 ? 'Finish' : 'Run'}
                                      </Button> 
                                </Grid>
                                <Grid item xs={2}>
                                      {activeStep === 1 ?
                                        <MuiThemeProvider theme={theme}>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={startNow}
                                                    helperText="Whatever"
                                                    onChange={(e) => {
                                                        setStartNow(e.target.checked)
                                                    }}

      
                                                    />}

                                            label="Start Now" />
                                        </MuiThemeProvider>

                                      : null}
                                  </Grid>
                              </Grid>




                            {error ? (                     
                                <Typography component="h5" variant="h5">
                                    {"Error! " + errorMsg} 
                                </Typography>
                              ) :
                              null
                            }

                    </div>


                    <p></p>
                    { lastCommandSent !== "" ?
                        <div>Last command sent - {lastCommandSent}</div>
                        :
                        null
                    }

          </Grid>


  { activeStep === 1 || activeStep === 2 ? 
    ( <React.Fragment>


      { (activeStep === 1 || activeStep === 2) ? (

            <React.Fragment>
            <Grid item xs={12}>
                <div className={classes.sendleft}>
                    <Typography component="h5" variant="h5">
                        {steps[activeStep].heading}
                    </Typography>
                </div>
                <div style={{clear: "both"}}></div>
            </Grid>          
            <Grid item xs={12}>
                          <br />
                          <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{steps[activeStep].subheading} &#8198;</div>
            </Grid>


            <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <MuiThemeProvider theme={theme}>
                      {cookingMachineIndicators()}
                  </MuiThemeProvider>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <MuiThemeProvider theme={theme}>
                      {cookingMachineTimeIndicators()}
                  </MuiThemeProvider>
                </Grid>
            </Grid>

            <Grid item xs={12}>

                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <MuiThemeProvider theme={theme}>
                      {robotIndicators()}
                  </MuiThemeProvider>
                </Grid>
            </Grid>

            <Grid item xs={12}>

                <Grid container direction="row" alignItems="stretch" spacing={2}>
                <MuiThemeProvider theme={theme}>
                        <Grid item xs={2}> 
                            <Autocomplete
                                onChange={(event, value) => {
                                    if (value === null) {
                                      setPumpFridge(pumpFridgeList[0])
                                    } else {
                                      setPumpFridge(value)}
                                    }
                                }
                                id="combo-box-pumpfridge"
                                value={pumpFridge}
                                options={pumpFridgeList}
                                renderInput={(params) => <TextField {...params} label='Fridge/Pump Send' InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                />} 
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={(event, value='left') => handleFridgePump(value)}
                                disabled={false}
                                aria-label="next step"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                {pumpFridge[0] === 'P' ? 'On' : 'Open'}
                            </Button> 
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={(event, value='right') => handleFridgePump(value)}
                                disabled={false}
                                aria-label="next step"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                {pumpFridge[0] === 'P' ? 'Off' : 'Close'}
                            </Button> 
                        </Grid>
                        <Grid item xs={8}></Grid>
                </MuiThemeProvider>
                </Grid>
            </Grid>

            <Grid item xs={12}>
            <p></p>
              <MuiThemeProvider theme={theme}>
                <TextField
                      value={textBox}
                      id="outlined-multiline-static"
                      multiline
                      rows={20}
                      variant="outlined"
                      fullWidth={true}
                  />
              </MuiThemeProvider>
            </Grid>

            </React.Fragment>
          ) : null

      }

  </React.Fragment>
  ) : null
  }


          <div><p></p></div>



          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


