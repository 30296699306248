import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from  '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ErrorIcon from '@mui/icons-material/Error';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  container_thing: {
    maxWidth: "100%",
    marginTop: "2vh",
    marginBottom: "10vh",
    borderRadius: '5px',
    // backgroundColor: theme.palette.action.disabledBackground,
    backgroundColor: '#503c30',
    height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },

    gridcell:{
      backgroundColor: 'white',
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
          borderBottom: 'none',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
      },

      '&.MuiDataGrid-root .MuiDataGrid-cell': {borderBottom: 'none !important'},

      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.delivery': {
        backgroundColor: 'rgba(50, 80, 255, 0.49)',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.starter': {       // blue
        backgroundColor: 'rgba(0, 0, 255, 0.10)',
      },
      '& .super-app.main': {          // yellow
        backgroundColor: 'rgba(255, 255, 0, 0.10)',
      },
      '& .super-app.dessert': {       // orange
        backgroundColor: 'rgba(255, 69, 0, 0.10)',
      },
      '& .super-app.drink': {         // violet
        backgroundColor: 'rgba(255, 0, 255, 0.10)',
      },
      '& .super-app.fruit': {         // red
        backgroundColor: 'rgba(255, 0, 0, 0.10)',
      },
      '& .super-app.starterSelect': {       // blue
        backgroundColor: 'rgba(0, 0, 255, 0.50)',
      },
      '& .super-app.mainSelect': {          // yellow
        backgroundColor: 'rgba(255, 255, 0, 0.50)',
      },
      '& .super-app.dessertSelect': {       // orange
        backgroundColor: 'rgba(255, 69, 0, 0.50)',
      },
      '& .super-app.drinkSelect': {         // violet
        backgroundColor: 'rgba(255, 0, 255, 0.50)',
      },
      '& .super-app.fruitSelect': {         // red
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },
      '& .test-app': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },

    },



}));


const xtranslate = new XTranslate();

const initSelectedDinnerTime = () => {
  let test = moment((new Date()));
  test.set('minute', 0);
  test.set('hour', 18);
  return test;
};

const initSelectedLunchTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 12);
  return test2;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


export default function YPCOrderTickets(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [menuLoaded, setMenuLoaded] = React.useState(false);
  const [menuDB, setMenuDB] = React.useState(null);

  const [daysOrderList, setDaysOrderList] = React.useState([]);
  const daysOrderListRef = React.useRef(daysOrderList);
  daysOrderListRef.current = daysOrderList;

  const [altDaysOrderList, setAltDaysOrderList] = React.useState([]);
  const [tallyDaysOrderList, setTallyDaysOrderList] = React.useState([]);
  const [timesList, setTimesList] = React.useState([]);
  const [daysTimesNames, setDaysTimesNames] = React.useState(null);

  const [serveTime, setServeTime] = React.useState(null);
  const serveTimeRef = React.useRef(serveTime);
  serveTimeRef.current = serveTime;

  const [selectPointer, setSelectPointer] = React.useState(0);

  const [selectStarterDelete, setSelectStarterDelete] = React.useState("");

  const [dessertsOrderList, setDessertsOrderList] = React.useState([]);
  const [selectDessertsDelete, setSelectDessertsDelete] = React.useState("");

  const [fruitsOrderList, setFruitsOrderList] = React.useState([]);
  const [selectFruitsDelete, setSelectFruitsDelete] = React.useState("");

  const [drinksOrderList, setDrinksOrderList] = React.useState([]);
  const [selectDrinksDelete, setSelectDrinksDelete] = React.useState("");

  const [mainsOrderList, setMainsOrderList] = React.useState([]);
  const [selectMainsDelete, setSelectMainsDelete] = React.useState("");
  
  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [lunchBoxChecked, setLunchBoxChecked] = React.useState({'test': 'test2'});
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = React.useState({'test': 'test2'});

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));
  const selectedDateRef = React.useRef(selectedDate);
  selectedDateRef.current = selectedDate;

  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);

  const [locale, setLocale] = React.useState()

  const [lunchBlocked, setLunchBlocked] = React.useState(false);
  const [dinnerBlocked, setDinnerBlocked] = React.useState(false);

  const [allergyMessage, setAllergyMessage] = React.useState(false);
  const [allergyContent, setAllergyContent] = React.useState('');

  const [refreshMessage, setRefreshMessage] = React.useState(null);
  const [refreshContent, setRefreshContent] = React.useState(null);

  const [refreshIsActive, setRefreshIsActive] = React.useState(false);
  const refreshIsActiveRef = React.useRef(refreshIsActive);
  refreshIsActiveRef.current = refreshIsActive;

  const [refreshButton, setRefreshButton] = React.useState(true);
  const refreshButtonRef = React.useRef(refreshButton);
  refreshButtonRef.current = refreshButton;

  // const [stuff, setStuff] = React.useState('')

  // const [value, setValue] = React.useState(new Date("2021-10-26 13:00"));

  // React.useEffect(() => {
  //   if (!menuLoaded) {
  //     handleLoadDB();
  //   }
  // }, [menuLoaded]);


//   React.useEffect(() => {
//     calculatesummaryTable();
//   }, [daysOrderList, props.language]);


  React.useEffect(() => {
    setRefreshIsActive(false);
    getOrders();
  }, [selectedDate]);

  React.useEffect(() => {
    // getTallys();
    // getNewTallys();
  }, [altDaysOrderList]);

  React.useEffect(() => {
    getNewTallys();
  }, [daysTimesNames, serveTime]);

  React.useEffect(() => {
    setInterval(() => {
      // if (refreshIsActive.current) {
          // console.log('1 sec.', serveTimeRef.current);
        if(refreshButtonRef.current) {
            refreshOrders();
        };
      // };
    }, 30000);
  }, []);





  function getNewTallys() {
    // console.log('NANANANANAN', serveTime);
    // console.log('NBNBNBNBNBN', daysTimesNames);

    if (serveTime !== null && daysTimesNames !== null && daysTimesNames.hasOwnProperty(serveTime)) {
      let tempDict = {};
      let tempList = [];

      let typeDict = {};
      let namesList = [];

      // ['Starter', 'Main', 'Dessert', 'Drink', 'Fruit'].map((selType) => {
      ['Fruit', 'Drink', 'Dessert', 'Starter', 'Main'].map((selType) => {        
            // altDaysOrderList.map((value) => {
            Object.keys(daysTimesNames[serveTime]).map((customer) => {
              daysTimesNames[serveTime][customer].map((value) => {

                    if (value['name'] !== "" && value['category'] === selType && !namesList.includes(value['name']) ) {
                      namesList.push(value['name']);
                      tempDict[value['name']] = 0;
                      typeDict[value['name']] = value['category'];
                    };
                    if (value['name'] !== ""  && value['category'] === selType ) {
                      tempList.push(value)                
                    };
                  });


            });
      });

      // console.log('NCNCNCNCNCNCN', tempList);

      tempList.map((value) => {
        tempDict[value['name']] = tempDict[value['name']] + value['portions'];
      });

      let finalList = [];
      let id = 0;
      namesList.map((name) => {
        finalList.push({'name': name, 'portions': tempDict[name], 'category': typeDict[name], 'id': id});
        id = id + 1;
      })

      setTallyDaysOrderList(finalList);
      // console.log('NHNHNHNHNH', finalList);
      setSelectPointer(0);

    } else {
      setTallyDaysOrderList([]);
      setSelectPointer(0)
    }


  }

  function getTallys() {

    if (altDaysOrderList.length !== 0) {
      let tempDict = {};
      let tempList = [];

      altDaysOrderList.map((value) => {
        if (value['name'] !== "") {
          tempDict[value['name']] = 0;
          tempList.push(value)
        }
      });

      tempList.map((value) => {
        tempDict[value['name']] = tempDict[value['name']] + value['portions'];
      });

      let finalList = [];
      let id = 0;
      for (const [key, value] of Object.entries(tempDict)) {
        finalList.push({'name': key, 'portions': value, 'id': id })
        id = id + 1;
      }

      setTallyDaysOrderList(finalList);
    } else {
      setTallyDaysOrderList([]);
    }

  };


  async function refreshOrders() {
    let subDate = selectedDateRef.current.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
    var order_structure = await YPCGetAxiosAlt(xget, setXGet, 'getDaysOrdersURL', dateString);

    let refresh_message1 = "";
    let refresh_message2 = "";

    let newStructureServeTimeList = []
    let new_structure = []
    order_structure.map((value) => {
      let new_line = value;
      delete new_line.id;
      delete new_line.save_time;
      new_structure.push(new_line);
      if (serveTimeRef.current === new_line.serving_time_range.slice(-5)) {
        newStructureServeTimeList.push(new_line);
      };
    });
    
    let oldStructureServeTimeList = []
    let old_structure = []
    daysOrderListRef.current.map((value) => {
      let new_line = value;
      delete new_line.id;
      delete new_line.save_time;
      old_structure.push(new_line);
      if (serveTimeRef.current === new_line.serving_time_range.slice(-5)) {
        oldStructureServeTimeList.push(new_line);
      };
    });

    // console.log('BABABABAB', oldStructureServeTimeList, newStructureServeTimeList);


    if (JSON.stringify(oldStructureServeTimeList) !== JSON.stringify(newStructureServeTimeList)) {
      if (newStructureServeTimeList.length === 0) {
        refresh_message1 = "The orders that were in this timeslot have been deleted!!";
        // setRefreshMessage("The orders that were in this timeslot have been delete!!");
      } else {
        refresh_message1 = "There have been changes to the orders in this timeslot!!";
        // setRefreshMessage("There have been changes to the orders in this timeslot!!");
      };
    } else {

      for(let i=0;i<old_structure.length; i++ ) {
        // console.log('LALALAL',JSON.stringify(old_structure[i]) === JSON.stringify(order_structure[i]) , old_structure[i], order_structure[i]);
        if (JSON.stringify(old_structure[i]) !== JSON.stringify(new_structure[i])) {
          if (refresh_message1 === "") {
            refresh_message2 = "Orders outside of this timeslot have been modified!!";
          } else {
            refresh_message2 = "\nNote that Orders outside of this timeslot have also been modified!!";
          }
        };
      };
    };

    if (refresh_message1 !== "" || refresh_message2 !== "") {
      setRefreshMessage(refresh_message1 + refresh_message2);
    };
  };


  async function getRefreshOfOrders() {
    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
    var order_structure = await YPCGetAxiosAlt(xget, setXGet, 'getDaysOrdersURL', dateString);

    console.log('PXPXPXPXPXP', order_structure);

    setDaysOrderList(order_structure);

    let times = [];
    let timesNames = {};

    if (order_structure.length !== 0) {
        let orderList = [];
        order_structure.map((value) => {
            let temp = value;
            let id = uuidv4();
            temp['id'] = id;
            orderList.push(temp);
        });
        // setDaysOrderList(orderList);

        // new stuff
        let newOrderList = [];
        let dishName = "";
        let customerName = "";
        let customerFullName = "";
        let pickupOrDelivery = "";
        let portions = 0;
        let mealType = "";
        let lunchBox = false;
        let category = "";
        let servingTime = "2021-12-14T12:00";
        let firstTime = true;
        let buildingId = "";
        let phoneNumber = '';
        let foodRestrictionsAllergies = '';

        // console.log('OOrderStructure', order_structure);
        order_structure.map((value) => {
          let temp = value;
          let id = uuidv4();
          temp['id'] = id;
          if (firstTime) {
            if (temp['category'] === "Side") {
              newOrderList.push({id: 1, name : "Menu Starts With Side!!", category: "", customer_id: "", customer_fullname: "", pickup_or_delivery: "",  portions:0, lunch_box:false, meal_type:"", serving_time_range: "", building_id:"", phone_number: "", food_restrictions_allergies: ""})
              newOrderList.push({id: 2, name : "", category: "", customer_id: "",  customer_fullname: "", pickup_or_delivery: "", portions:0, lunch_box:null, meal_type:"", serving_time_range:"", building_id:"", phone_number: "", food_restrictions_allergies: ""})
            } else {
              times.push(temp['serving_time_range'].split("T")[1]);
            };

            dishName = temp['name'];            
            customerName = temp['customer_id'];
            customerFullName = temp['user_full_name'];
            pickupOrDelivery = temp['pickup_or_delivery']
            portions = temp['portions'];
            mealType = temp['meal_type'];
            category = temp['category'];
            lunchBox = temp['lunch_box'];
            servingTime = temp['serving_time_range'];
            firstTime = false;
            buildingId = temp['building_id'];
            phoneNumber = temp['phone_number'];
            foodRestrictionsAllergies = temp['food_restrictions_allergies'];


          } else {
            if (temp['category'] === "Side") {
              dishName = dishName + ", " + temp['name'];
            } else {


              newOrderList.push({id: id, name : dishName, category: category, customer_id: customerName, customer_fullname: customerFullName, pickup_or_delivery: pickupOrDelivery, 
                    portions: portions, lunch_box:lunchBox, meal_type: mealType, serving_time_range: servingTime, building_id: buildingId, phone_number: phoneNumber, food_restrictions_allergies: foodRestrictionsAllergies});


              // if (servingTime !== temp['serving_time_range'] || customerName !== temp['customer_id']) {
              //   id = uuidv4(); 
              //   newOrderList.push({id: id, name : "", category: "", customer_id: "", customer_fullname: "", portions:null, lunch_box:null, meal_type:"", serving_time_range:""})
              // };

              if (servingTime !== temp['serving_time_range']) {
                times.push(temp['serving_time_range'].split("T")[1]);
              };

              dishName = temp['name'];            
              customerName = temp['customer_id'];
              customerFullName = temp['user_full_name'];
              pickupOrDelivery = temp['pickup_or_delivery']
              portions = temp['portions'];
              mealType = temp['meal_type'];
              category = temp['category'];
              lunchBox = temp['lunch_box'];
              servingTime = temp['serving_time_range'];
              buildingId = temp['building_id'];
              phoneNumber = temp['phone_number'];
              foodRestrictionsAllergies = temp['food_restrictions_allergies'];
  
            };
          };
        });

        let id = uuidv4();
        newOrderList.push({id: id , name : dishName, category: category, customer_id: customerName, customer_fullname: customerFullName, pickup_or_delivery: pickupOrDelivery, portions: portions, 
              lunch_box:lunchBox, meal_type: mealType, serving_time_range: servingTime, building_id: buildingId, phone_number: phoneNumber, food_restrictions_allergies: foodRestrictionsAllergies});

        setAltDaysOrderList(newOrderList);

        // console.log('AAAAAAAAAAAAUUTUTUTUTUTUTUTUTU', newOrderList);

        times.map((timeValue) => {
          timesNames[timeValue] = {};
            newOrderList.map((record) => { 
              if ( !timesNames[timeValue].hasOwnProperty(record['customer_id'])  && record['serving_time_range'].split("T")[1] === timeValue) {
                timesNames[timeValue][record['customer_id']] = [record];
              } else if (record['serving_time_range'].split("T")[1] === timeValue) {
                timesNames[timeValue][record['customer_id']].push(record);
              };
            });
        });


        setTimesList(times);

        // this is where you make a decision on what ServeTime should be !!!
        if (times.includes(serveTime)) {
          // pass
        } else {
          let new_slot = null;
          times.map((value) => {
            if (new_slot === null && value > serveTime) {
              new_slot = value;
            }
          });

          if (new_slot === null) {
            setServeTime(times[0]);
          } else {
            setServeTime(new_slot);
          }
        };

        setDaysTimesNames(timesNames);

    } else {
      // setDaysOrderList([]);
      setAltDaysOrderList([])
      setTimesList([]);
      setServeTime(null);
      setDaysTimesNames(null);
    };

    setRefreshIsActive(true);
    // console.log('BBBBBBBBBBBBUUTUTUTUTUTUTUTUTU', times);
    // console.log('CCCCCCCCCCCCUUTUTUTUTUTUTUTUTU', timesNames);


  };



  async function getOrders() {
    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
    var order_structure = await YPCGetAxiosAlt(xget, setXGet, 'getDaysOrdersURL', dateString);

    console.log('PXPXPXPXPXP', order_structure);

    setDaysOrderList(order_structure);

    let times = [];
    let timesNames = {};

    if (order_structure.length !== 0) {
        let orderList = [];
        order_structure.map((value) => {
            let temp = value;
            let id = uuidv4();
            temp['id'] = id;
            orderList.push(temp);
        });
        // setDaysOrderList(orderList);

        // new stuff
        let newOrderList = [];
        let dishName = "";
        let customerName = "";
        let customerFullName = "";
        let pickupOrDelivery = "";
        let portions = 0;
        let mealType = "";
        let lunchBox = false;
        let category = "";
        let servingTime = "2021-12-14T12:00";
        let firstTime = true;
        let buildingId = "";
        let phoneNumber = '';
        let foodRestrictionsAllergies = '';

        // console.log('OOrderStructure', order_structure);
        order_structure.map((value) => {
          let temp = value;
          let id = uuidv4();
          temp['id'] = id;
          if (firstTime) {
            if (temp['category'] === "Side") {
              newOrderList.push({id: 1, name : "Menu Starts With Side!!", category: "", customer_id: "", customer_fullname: "", pickup_or_delivery: "",  portions:0, lunch_box:false, meal_type:"", serving_time_range: "", building_id:"", phone_number: "", food_restrictions_allergies: ""})
              newOrderList.push({id: 2, name : "", category: "", customer_id: "",  customer_fullname: "", pickup_or_delivery: "", portions:0, lunch_box:null, meal_type:"", serving_time_range:"", building_id:"", phone_number: "", food_restrictions_allergies: ""})
            } else {
              times.push(temp['serving_time_range'].split("T")[1]);
            };

            dishName = temp['name'];            
            customerName = temp['customer_id'];
            customerFullName = temp['user_full_name'];
            pickupOrDelivery = temp['pickup_or_delivery']
            portions = temp['portions'];
            mealType = temp['meal_type'];
            category = temp['category'];
            lunchBox = temp['lunch_box'];
            servingTime = temp['serving_time_range'];
            firstTime = false;
            buildingId = temp['building_id'];
            phoneNumber = temp['phone_number'];
            foodRestrictionsAllergies = temp['food_restrictions_allergies'];


          } else {
            if (temp['category'] === "Side") {
              dishName = dishName + ", " + temp['name'];
            } else {


              newOrderList.push({id: id, name : dishName, category: category, customer_id: customerName, customer_fullname: customerFullName, pickup_or_delivery: pickupOrDelivery, 
                    portions: portions, lunch_box:lunchBox, meal_type: mealType, serving_time_range: servingTime, building_id: buildingId, phone_number: phoneNumber, food_restrictions_allergies: foodRestrictionsAllergies});


              // if (servingTime !== temp['serving_time_range'] || customerName !== temp['customer_id']) {
              //   id = uuidv4(); 
              //   newOrderList.push({id: id, name : "", category: "", customer_id: "", customer_fullname: "", portions:null, lunch_box:null, meal_type:"", serving_time_range:""})
              // };

              if (servingTime !== temp['serving_time_range']) {
                times.push(temp['serving_time_range'].split("T")[1]);
              };

              dishName = temp['name'];            
              customerName = temp['customer_id'];
              customerFullName = temp['user_full_name'];
              pickupOrDelivery = temp['pickup_or_delivery']
              portions = temp['portions'];
              mealType = temp['meal_type'];
              category = temp['category'];
              lunchBox = temp['lunch_box'];
              servingTime = temp['serving_time_range'];
              buildingId = temp['building_id'];
              phoneNumber = temp['phone_number'];
              foodRestrictionsAllergies = temp['food_restrictions_allergies'];
  
            };
          };
        });

        let id = uuidv4();
        newOrderList.push({id: id , name : dishName, category: category, customer_id: customerName, customer_fullname: customerFullName, pickup_or_delivery: pickupOrDelivery, portions: portions, 
              lunch_box:lunchBox, meal_type: mealType, serving_time_range: servingTime, building_id: buildingId, phone_number: phoneNumber, food_restrictions_allergies: foodRestrictionsAllergies});

        setAltDaysOrderList(newOrderList);

        // console.log('AAAAAAAAAAAAUUTUTUTUTUTUTUTUTU', newOrderList);

        times.map((timeValue) => {
          timesNames[timeValue] = {};
            newOrderList.map((record) => { 
              if ( !timesNames[timeValue].hasOwnProperty(record['customer_id'])  && record['serving_time_range'].split("T")[1] === timeValue) {
                timesNames[timeValue][record['customer_id']] = [record];
              } else if (record['serving_time_range'].split("T")[1] === timeValue) {
                timesNames[timeValue][record['customer_id']].push(record);
              };
            });
        });

        setTimesList(times);
        setServeTime(times[0]);
        setDaysTimesNames(timesNames);

    } else {
      // setDaysOrderList([]);
      setAltDaysOrderList([])
      setTimesList([]);
      setServeTime(null);
      setDaysTimesNames(null);
    };

    setRefreshIsActive(true);
    // console.log('BBBBBBBBBBBBUUTUTUTUTUTUTUTUTU', times);
    // console.log('CCCCCCCCCCCCUUTUTUTUTUTUTUTUTU', timesNames);


  };

  const handleDateChange = (date) => {
    // console.log('GAGAGA', date);
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      field: 'name',
      headerName: translate(props.language, 'Selection'),
      type: 'string',
      width: 340,
      sortable: false,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
          show: (tallyDaysOrderList[selectPointer]['name'] === params.row.name),
      }),
        renderCell: (params) => { 
          // console.log("XXXXXXXXXXXXXX", tallyDaysOrderList[selectPointer]['name'], params.row);
        }
    },
    {
      field: 'portions',
      headerName: '',
      type: 'number',
      sortable: false,
      width: 50,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
          show: (tallyDaysOrderList[selectPointer]['name'] === params.row.name),
      }),
    },
     
  ];


  const tallyColumns = [
    {
      field: 'name',
      headerName: translate(props.language, 'Selection'),
      type: 'string',
      // width: 340,
      flex: 1,
      sortable: false,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {

            // starterSelect: (params.row.category === 'Starter' && params.row.id === selectPointer),
            // mainSelect: (params.row.category === 'Main' && params.row.id === selectPointer),
            // desserSelectt: (params.row.category === 'Dessert' && params.row.id === selectPointer),
            // drinkSelect: (params.row.category === 'Drink' && params.row.id === selectPointer),
            // fruitSelect: (params.row.category === 'Fruit' && params.row.id === selectPointer),
            show: (params.row.id === selectPointer),
            starter: (params.row.category === 'Starter' && params.row.id !== selectPointer),
            main: (params.row.category === 'Main' && params.row.id !== selectPointer),
            dessert: (params.row.category === 'Dessert' && params.row.id !== selectPointer),
            drink: (params.row.category === 'Drink' && params.row.id !== selectPointer),
            fruit: (params.row.category === 'Fruit' && params.row.id !== selectPointer),
            

        }),
        valueFormatter: (params) => {
          return params.row.portions + '  ' + params.row.name;
        }
        // renderCell: (params) => { 
        //   console.log("XXXXXXXXXXXXXX", params.row.category === 'Main', params.row);
        // }
      
    },
    // {
    //   field: 'portions',
    //   headerName: '',
    //   type: 'number',
    //   sortable: false,
    //   width: 50,
    //   // flex: 1,
    //   editable: false,
    //   cellClassName: (params) =>  // you could use params.value or params.row.side
    //   clsx('super-app', {

    //         // starterSelect: (params.row.category === 'Starter' && params.row.id === selectPointer),
    //         // mainSelect: (params.row.category === 'Main' && params.row.id === selectPointer),
    //         // desserSelectt: (params.row.category === 'Dessert' && params.row.id === selectPointer),
    //         // drinkSelect: (params.row.category === 'Drink' && params.row.id === selectPointer),
    //         // fruitSelect: (params.row.category === 'Fruit' && params.row.id === selectPointer),
    //         show: (params.row.id === selectPointer),
    //         starter: (params.row.category === 'Starter' && params.row.id !== selectPointer),
    //         main: (params.row.category === 'Main' && params.row.id !== selectPointer),
    //         dessert: (params.row.category === 'Dessert' && params.row.id !== selectPointer),
    //         drink: (params.row.category === 'Drink' && params.row.id !== selectPointer),
    //         fruit: (params.row.category === 'Fruit' && params.row.id !== selectPointer),
          

    //   }),
    // }
  ];

  const handleEditable = (params) => {
    if (params.row.side) {
        return (false);
    } else if (params.field === 'lunchboxcount') {
        if (params.row.uuid in lunchBoxChecked && !dinnerBlocked) {
            return (true);
        } else {
            return (false);
        };
    } else if (params.field === 'lunchcount') {
        if (params.row.uuid in dinnerOnlyChecked) {
          return (false);
        } else if (lunchBlocked) {
          return (false);
        } else {
          return (true);
        };
    } else if (params.field === 'dinnercount') {
        if (dinnerBlocked) {
          return (false);
        } else {
        return (true);
        };
    };
 };


 function refreshMsg() {
  return (
    <div>
      <Dialog open={refreshMsg} >
        {/* <DialogTitle>{translate(props.language, 'Lunch and Dinner time values must be set before saving order!')}</DialogTitle> */}
        <DialogTitle>{refreshMessage}</DialogTitle>
        <DialogContent>
          {/* {allergyContent} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefreshMsg} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

async function handleRefreshMsg() {
  await getRefreshOfOrders();
  setRefreshMessage(null);
};


 function allergyMsg() {
  return (
    <div>
      <Dialog open={allergyMsg} >
        {/* <DialogTitle>{translate(props.language, 'Lunch and Dinner time values must be set before saving order!')}</DialogTitle> */}
        <DialogTitle>Food Restrictions or Allergies</DialogTitle>
        <DialogContent>
          {allergyContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGeneralMsg} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const handleGeneralMsg = () => {
  setAllergyMessage(false);
};

 function foodAllergies(rowValue) {
  if (rowValue[0]['food_restrictions_allergies'] === null || rowValue[0]['food_restrictions_allergies'] === '') {
    return '';
  } else {
    // return '** ';
    return <AnnouncementIcon   style={{marginTop: '8px',  marginRight: '5px' }} />;
  }
 }

  const handleColumnSelect = (rowValue) => {
    // console.log('XLXLXLXLXLXLXLXLXLXL', rowValue);
    setAllergyContent(rowValue[0]['food_restrictions_allergies'])
    // setSelectPointer(params.row.id);
    if (rowValue[0]['food_restrictions_allergies'] === null || rowValue[0]['food_restrictions_allergies'] === '') {
      return;
    } else {
      setAllergyMessage(true)
    }

  };

  // Start Functions Section

  function OrderTable(rowValue, customerId) {
    const localColumns = [
      {
        field: 'name',
        // headerName: foodAllergies(rowValue) + customerId + " - " + rowValue[0]['customer_fullname'],
        headerClassName: (params) => 
          clsx('super-app', {
              delivery: (rowValue[0].pickup_or_delivery === 'Delivery'),
          }),
        renderHeader: () => (
          // <div>
          //   <span role="img" aria-label="enjoy" >
          //       {foodAllergies(rowValue)}
          //   </span>
          //   {customerId + " - " + rowValue[0]['customer_fullname']}
          // </div>

          <Stack direction="row" spacing={1} style={{cursor: 'pointer'}}>
            {foodAllergies(rowValue)}
            {customerId + " - " + rowValue[0]['customer_fullname']}
          </Stack>


        ),          
        type: 'string',
        // width: 340,
        flex:1,
        sortable: false,
        editable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
            show: (tallyDaysOrderList[selectPointer]['name'] === params.row.name),
        }),
        valueFormatter: (params) => {
            return params.row.portions + '  ' + params.row.name;
        },
      },
      // {
      //   field: 'portions',
      //   headerName: '',
      //   type: 'number',
      //   sortable: false,
      //   // width: 50,
      //   flex: 0.15,
      //   editable: false,
      //   cellClassName: (params) =>  // you could use params.value or params.row.side
      //   clsx('super-app', {
      //       show: (tallyDaysOrderList[selectPointer]['name'] === params.row.name),
      //   }),
      // }
    ];


    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
        //   onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          // rows={altDaysOrderList}
          rows={rowValue}
          columns={localColumns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleRowSelect}
          onColumnHeaderClick={() => handleColumnSelect (rowValue)}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            // Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };


  function OrderTableGrid() {
    if (serveTime !== null && daysTimesNames !== null ) {
      if (daysTimesNames.hasOwnProperty(serveTime)) {
          let tNames = Object.keys(daysTimesNames[serveTime])

          return (
            <React.Fragment>
              {tNames.map((name) => (
                <Grid item xs={3}>
                    {OrderTable(daysTimesNames[serveTime][name], name)}
                </Grid>
              ))}
            </React.Fragment>
          );
      } else {
        return null;
      };
    } else {
      return null;
    };
  }

  function TallyTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
        //   onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          rows={tallyDaysOrderList}
          columns={tallyColumns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleRowSelect}
          // onRowClick={handleStarterSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            // Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };



  const handleStarterLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = daysOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 
                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian
                            }

                };
                return row;
            
            });
            setDaysOrderList(updatedRows);
        };
    },
  );

  const handleRowSelect = (params) => {
    // console.log('XXXXXXXXXXXXX', params);
    setSelectPointer(params.row.id);
  };


  const handleLow = () => {
    // let listLen = timesList.length;
    let currentIndex = timesList.indexOf(serveTime);
    if (currentIndex !== 0) {
      setServeTime(timesList[currentIndex - 1]);
    };
  };

  const handleHigh = () => {
    let listLen = timesList.length;
    let currentIndex = timesList.indexOf(serveTime);
    if (currentIndex !== listLen -1) {
      setServeTime(timesList[currentIndex + 1]);
    };

  };

  const handlePrev = () => {
    if (selectPointer !== 0) {
      setSelectPointer(selectPointer - 1);
    };
  };

  const handleNext = () => {
    if (selectPointer !== tallyDaysOrderList.length -1) {
      setSelectPointer(selectPointer+1)
    }

  };

  async function handleSendPrintInfo() {

    let tempPrintBuffer = [];
    if (serveTime !== null && daysTimesNames !== null ) {
      if (daysTimesNames.hasOwnProperty(serveTime)) {
          let tNames = Object.keys(daysTimesNames[serveTime])
          tNames.map((name) => {
            tempPrintBuffer.push(daysTimesNames[serveTime][name])
          });
          console.log('CXCXCXCXCX', tempPrintBuffer);
      };
    };

    
    if (tempPrintBuffer.length !== 0) {

      let finalBuffer = []
      tempPrintBuffer.map((record) => {
        let printBuffer = {};
        printBuffer['username'] = record[0]['customer_id'];
        printBuffer['full_name'] = record[0]['customer_fullname'];
        printBuffer['pickup_or_delivery'] = record[0]['pickup_or_delivery'];
        printBuffer['building_id'] = record[0]['building_id'];
        printBuffer['time'] = serveTime;
        printBuffer['phone_number'] = record[0]['phone_number'];
        let items = [];
        record.map((value) => {
          items.push({'item': value['name'], 'portions': value['portions']})
        })
        printBuffer['items'] = items;
        finalBuffer.push(printBuffer);
      })


      console.log('BXXXXXXX', finalBuffer)

      // setStuff(JSON.stringify(finalBuffer))
      await YPCPutAxios2Alt(setXPost, 'printContainerTicketURL', finalBuffer, '?printall=True');
    };
    
  };


    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>

          <Grid item xs={12}>
              <div className={classes.sendleft}>
                  {allergyMessage ? allergyMsg() : null}
                  {refreshMessage !== null ? refreshMsg() : null}

                  <Typography component="h4" variant="h4">
                      {'Order Tickets'}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>

          {/* <Grid item xs={12}>
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date picker dialog"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>
          </Grid> */}






          {/*   Orders Section */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Order Tickets'} &#8198;</div>
          </Grid>

          <Grid container spacing={3}>
                <Grid item xs={3}>
                    { (tallyDaysOrderList.length !== 0) ?
                          <Grid item xs={12} >

                                  <Grid item xs={12} >
                                    <div><p></p></div>
                                    <Grid container spacing={3}>
                                      <Grid item xs={1} >
                                      </Grid>                                      
                                      <Grid item xs={3} >
                                                <Button
                                                    variant="contained"
                                                    // color="primary"
                                                    size="large"
                                                    className={classes.button}
                                                    onClick={handleLow}
                                                    disabled={false}
                                                    aria-label="whatever low"
                                                    style={{backgroundColor: '#78cbff', color:"white"}}
                                                >
                                                    {'<'}
                                                </Button>     
                                      </Grid>
                                      <Grid item xs={4} >
                                                <Typography component="h5" variant="h5">
                                                    {serveTime}
                                                </Typography>
                                      </Grid>
                                      <Grid item xs={3} >
                                                <Button
                                                    variant="contained"
                                                    // color="primary"
                                                    size="large"
                                                    className={classes.button}
                                                    onClick={handleHigh}
                                                    disabled={false}
                                                    aria-label="whatever high"
                                                    style={{backgroundColor: '#78cbff', color:"white"}}
                                                >
                                                    {'>'}
                                                </Button>   
                                      </Grid>
                                      <Grid item xs={1} >
                                      </Grid>                                      
                                    </Grid>
                                    <div><p></p></div>
                                  </Grid>

                                  <Grid item xs={12} >
                                      {TallyTable()}
                                      <div><p></p></div>
                                  </Grid>

                                  <Grid item xs={12} >
                                    <div><p></p></div>
                                    <Grid container spacing={3}>
                                      <Grid item xs={1} >
                                      </Grid>                                      
                                      <Grid item xs={3} >
                                                <Button
                                                    variant="contained"
                                                    // color="primary"
                                                    size="large"
                                                    className={classes.button}
                                                    onClick={handlePrev}
                                                    disabled={false}
                                                    aria-label="whatever prev"
                                                    style={{backgroundColor: '#78cbff', color:"white"}}
                                                >
                                                    {'Prev'}
                                                </Button>     
                                      </Grid>
                                      <Grid item xs={4} >
                                                <Typography component="h5" variant="h5">
                                                    {''}
                                                </Typography>
                                      </Grid>
                                      <Grid item xs={3} >
                                                <Button
                                                    variant="contained"
                                                    // color="primary"
                                                    size="large"
                                                    className={classes.button}
                                                    onClick={handleNext}
                                                    disabled={false}
                                                    aria-label="whatever next"
                                                    style={{backgroundColor: '#78cbff', color:"white"}}
                                                >
                                                    {'Next'}
                                                </Button>   
                                      </Grid>
                                      <Grid item xs={1} >
                                      </Grid>                                      
                                    </Grid>
                                    <div><p></p></div>
                                  </Grid>     


                                  <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5}></Grid>                                    
                                        <Grid item xs={2}>
                                              { mealDB ? 
                                                      <Grid item xs={2} >
                                                                <Button
                                                                    variant="contained"
                                                                    // color="primary"
                                                                    size="small"
                                                                    className={classes.button}
                                                                    onClick={handleSendPrintInfo}
                                                                    disabled={false}
                                                                    aria-label="whatever nextx"
                                                                    style={{backgroundColor: '#78cbff', color:"white"}}
                                                                >
                                                                    Print Tickets
                                                                </Button>   
                                                      </Grid>
                                                : null }
                                        </Grid>
                                        <Grid item xs={5}></Grid>                                    
                                    </Grid>
                                    <div><p></p></div>
                                  </Grid>


                                  <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5}></Grid>                                    
                                        <Grid item xs={2}>
                                              { mealDB ? 
                                                      <Grid item xs={2} >
                                                        <Button
                                                            variant="contained"
                                                            // color="primary"
                                                            size="small"
                                                            className={classes.button}
                                                            onClick={() => {
                                                                setRefreshButton(!refreshButton)
                                                              }
                                                            }
                                                            disabled={false}
                                                            aria-label="whatever nextx"
                                                            style={{backgroundColor: '#78cbff', color:"white"}}
                                                        >
                                                            {refreshButton ? 'Disable Refresh' : 'Enable Refresh'}
                                                        </Button>   
                                                      </Grid>
                                                : null }
                                        </Grid>
                                        <Grid item xs={5}></Grid>                                    
                                    </Grid>
                                  </Grid>



                          </Grid>
                    : null }
                </Grid>
                <Grid item xs={9}>
                  { mealDB ? 
                          <Grid className={classes.container_thing} container spacing={3}>
                                  {OrderTableGrid()}
                          </Grid>
                    : null }

                </Grid>


          </Grid>

          <div><p></p></div>

          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Choose Date'} &#8198;</div>
          </Grid>

          <div><p></p></div>


          {/* <Grid item xs={12}>
                  <Grid item xs={2} >
                            <Button
                                variant="contained"
                                // color="primary"
                                size="small"
                                className={classes.button}
                                onClick={() => {
                                    setRefreshButton(!refreshButton)
                                  }
                                }
                                disabled={false}
                                aria-label="whatever nextx"
                                style={{backgroundColor: '#78cbff', color:"white"}}
                            >
                                {refreshButton ? 'Disable Refresh' : 'Enable Refresh'}
                            </Button>   
                  </Grid>
                  <Grid item xs={10}></Grid>                                    
          </Grid>           */}

          <Grid item xs={12}>
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date picker dialog"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>
          </Grid>


          {/* <Grid item xs={12}>
            <div>
              {stuff}
            </div>

          </Grid> */}



          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


