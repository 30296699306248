import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';
import './altstyles.css';

import jsonData from './shelf.json';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  gridClasses,
  GridToolbar,
  } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import DoneIcon from '@mui/icons-material/Done';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';


import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepContent from '@mui/material/StepContent';
import Skeleton from '@mui/material/Skeleton';


import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCDeleteAxiosAlt from './YPCDeleteAxiosAlt';
import YPCPostAxios2Alt from './YPCPostAxios2Alt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import PutToLight from './PutToLight';
import ButtonLight from './ButtonLight';
import SendToMotionPy from './SendToMotionPy';
import GetMotionPyTopic from './GetMotionPyTopic.js';

import { ROS } from 'react-ros'
import { useROS } from 'react-ros'

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';
import { milliseconds, sub } from 'date-fns';

  
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '&.MuiDataGrid-root .Mui-selected': {
        backgroundColor: "rgba(148, 184, 184, 0.8) !important"
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.8)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "60px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarColumnsButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

// const shelfType = 'JSON_FILE';
const shelfType = 'JSON_FILE_AXIOS';

const initSelectedEndTime = () => {
  let test = moment((new Date()));
  test.set('minute', 59);
  test.set('hour', 23);
  return test;
};

const initSelectedStartTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 0);
  return test2;
};

const startTimeList = ['11:30', '12:00', '12:30', '13:00', '13:30','17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];
const endTimeList = ['11:30', '12:00', '12:30', '13:00', '13:30','17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];

const sendToDictNameToNumber = {'None': 0, 'Cooker':1, 'Oven':2, 'Scale':3, 'Robotic Arm':4, 'Liquid Dispenser':5, 'Human':6};
const sendToDictNumberToName = {0:'None', 1:'Cooker', 2:'Oven', 3:'Scale', 4:'Robotic Arm', 5:'Liquid Dispenser', 6:'Human'};

export default function YPCHubMep(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [ingredientsLoaded, setIngredientsLoaded] = React.useState(false);

  const [selectedTimeStart, setSelectedTimeStart] = React.useState(initSelectedStartTime());
  const [selectedTimeEnd, setSelectedTimeEnd] = React.useState(initSelectedEndTime());

  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [locale, setLocale] = React.useState()

  const [ingredientList, setIngredientList] = React.useState([]);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [activeStep, setActiveStep] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const [humanObject, setHumanObject] = React.useState(null);

  const [containerContents, setContainerContents] = React.useState(null);
  const [containerState, setContainerState] = React.useState(null);
  const [containerPointer, setContainerPointer] = React.useState(null);
  const [selectedContent, setSelectedContent] = React.useState(null);

  const [mousePress, setMousePress] = React.useState(false);
  const [mousePressMessage, setMousePressMessage] = React.useState(null);

  const [shelfSizes, setShelfSizes] = React.useState({0:{row: 3, columns: 9}, 4:{row: 1, columns: 6}, 1:{row: 4, columns: 6}, 
    2:{row: 4, columns: 6}, 5: {row: 6, columns:3}, 6: {row: 4, columns:5 }});

  const frontView = {0:true, 1: false, 6:true};

  const [allowDBWrite, setAllowDBWrite] = React.useState(false);

  const [rosConnected, SetRosConnected] = React.useState(false);

  const [motionPyText, setMotionPyText] = React.useState('');
  const [msgID, setMsgId] = React.useState(0);
  const [cookingState, setCookingState] = React.useState('idle');
  const [countState, setCountState] = React.useState(0);
  const [motionPyTopic, setMotionPyTopic] = React.useState(null);

  const [error, setError] = React.useState(false);

  const [checkOffline, setCheckOffline] = React.useState(false);
  const [checkLunch, setCheckLunch] = React.useState(false);
  const [checkDinner, setCheckDinner] = React.useState(false);
  const [checkLunchDinner, setCheckLunchDinner] = React.useState(true);

  const [checkDemo, setCheckDemo] = React.useState(false);

  const [selectRow, setSelectRow] = React.useState(null);

  const [selectNonRowHub, setSelectNonRowHub] = React.useState(null);
  const [selectNonRowOven, setSelectNonRowOven] = React.useState(null);
  const [selectNonRowOutsourced, setSelectNonRowOutsourced] = React.useState(null);

  const [globalDisplay, setGlobalDisplay] = React.useState(null);

  // const [orderString, setOrderString] = React.useState(JSON.stringify(defaultOrder));
  const [orderString, setOrderString] = React.useState(JSON.stringify(defaultOrder, null, 2));

  const [useShelfUrl, setUseShelfUrl ] = React.useState(false);

  const [hubList, setHubList] = React.useState([]);
  const [outsourcedList, setOutsourcedList] = React.useState([]);
  const [ovenList, setOvenList] = React.useState([]);

  const [showRow, setShowRow] = React.useState(false);

  const [tableChoice, setTableChoice] = React.useState('Robot');

  const [fromForecast, setFromForecast] = React.useState(false);

  const handleDeleteDishOrders = () => {
    YPCDeleteAxiosAlt(xget, setXGet, 'delProxydeleteAllDishOrdersURL', '');
  };


  async function getMEPShelfIngredients() {

    setSelectRow(null);
    setSelectNonRowHub(null);
    setSelectNonRowOven(null);
    setSelectNonRowOutsourced(null);
    setGlobalDisplay(null);

    setGlobalDisplay(null)

    setLoading(true);
    let obj = {};
    let tempList = [];
    let shelfList = ['0','1','2','3','4','5','6']
    let id = 1;
    let tempHubList = [];
    let tempOutsourcedList = [];
    let tempOvenList = [];

    if (shelfType === 'JSON_FILE') {
        jsonData.forEach( (entry) => {
            // if (parseInt(entry.pose.charAt(4)) === shelfId) {
            if (parseInt(entry.pose.charAt(4)) === '1') {   // above replaced by this just for now
                let row = parseInt(entry.pose.charAt(5))-1;
                let column = parseInt(entry.pose.charAt(6))-1;
                let depth = entry.depth_counter-1;
                obj[[row, column, depth]] = entry;
            }
        });
    } else if  (shelfType === 'JSON_FILE_AXIOS') {

        let shelfIngredients = [];
        let hubIng = [];
        let outsourcedIng = [];
        let ovenIng = [];

        let fetchedOrders = [];

        let offlineOrders = [];
        let lunchOrders = [];
        let dinnerOrders = [];
        let demoOrders = [];
        let lunchDinnerOrders = [];

        let onlyMCRecipes = "&only_mc_recipes=false";
        if (useShelfUrl) {
          onlyMCRecipes = "&only_mc_recipes=true";
        }

        if (!checkDemo) {

            if (checkOffline) {
                offlineOrders = await YPCGetAxiosAlt(xget, setXGet, 'getProxyFetchDishOrdersURL', '&fetch_start_time=' + selectedDate.format("YYYY-MM-DDT") + 
                    selectedTimeStart.format("HH:mm") + '&fetch_end_time=' + selectedDate.format("YYYY-MM-DDT") +  selectedTimeEnd.format("HH:mm") +
                    '&filter_by_meal_type=offline' + onlyMCRecipes + (fromForecast ? "&forecast=True" : ''));
                if (useShelfUrl) {
                    var subShelfIngredients = await YPCPostAxios2Alt(setXPost, 'postProxyMEPShelfURL', offlineOrders, '?&modify_db=false');
                } else {
                    var subAllInstructions = await YPCPostAxios2Alt(setXPost, 'postProxyMEPGetPrepInstructionsURL', offlineOrders, '?show_dish_order_summary_table=false');
                    var subShelfIngredients = subAllInstructions.robot;
                    var subHubIng = subAllInstructions.hub;
                    var subOvenIng = subAllInstructions.oven;
                    var subOutsourcedIng = subAllInstructions.outsourced;

                };
                setXPost(null);

                shelfIngredients = shelfIngredients.concat(subShelfIngredients);

                if (!useShelfUrl) {
                    hubIng = hubIng.concat(subHubIng);
                    ovenIng = ovenIng.concat(subOvenIng);
                    outsourcedIng = outsourcedIng.concat(subOutsourcedIng);
                };
            };
            if (checkLunchDinner) {
              lunchDinnerOrders = await YPCGetAxiosAlt(xget, setXGet, 'getProxyFetchDishOrdersURL', '&fetch_start_time=' + selectedDate.format("YYYY-MM-DDT") + 
                  selectedTimeStart.format("HH:mm") + '&fetch_end_time=' + selectedDate.format("YYYY-MM-DDT") +  selectedTimeEnd.format("HH:mm") +
                    onlyMCRecipes + (fromForecast ? "&forecast=True" : ''));
              if (useShelfUrl) {
                  var subShelfIngredients = await YPCPostAxios2Alt(setXPost, 'postProxyMEPShelfURL', lunchDinnerOrders, '?&modify_db=false');
              } else {
                  var subAllInstructions = await YPCPostAxios2Alt(setXPost, 'postProxyMEPGetPrepInstructionsURL', lunchDinnerOrders, '?show_dish_order_summary_table=false');
                  var subShelfIngredients = subAllInstructions.robot;
                  var subHubIng = subAllInstructions.hub;
                  var subOvenIng = subAllInstructions.oven;
                  var subOutsourcedIng = subAllInstructions.outsourced;
              };
              setXPost(null);

              tempList = []
              subShelfIngredients.map((value) => {
                  let temp = value;
                  temp['lunch'] = false;
                  temp['dinner'] = false;
                  temp['lunchdinner'] = true;
                  tempList.push(temp);
              });
              shelfIngredients = shelfIngredients.concat(tempList);

              if (!useShelfUrl) {
                  tempList = []
                  subHubIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = false;
                      temp['dinner'] = false;
                      temp['lunchdinner'] = true;
                      tempList.push(temp);
                  });
                  hubIng = hubIng.concat(tempList);

                  tempList = []
                  subOutsourcedIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = false;
                      temp['dinner'] = false;
                      temp['lunchdinner'] = true;
                      tempList.push(temp);
                  });
                  outsourcedIng = outsourcedIng.concat(tempList);

                  tempList = []
                  subOvenIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = false;
                      temp['dinner'] = false;
                      temp['lunchdinner'] = true;
                      tempList.push(temp);
                  });
                  ovenIng = ovenIng.concat(tempList);
                };

            };
            if (checkLunch) {
              lunchOrders = await YPCGetAxiosAlt(xget, setXGet, 'getProxyFetchDishOrdersURL', '&fetch_start_time=' + selectedDate.format("YYYY-MM-DDT") + 
                  selectedTimeStart.format("HH:mm") + '&fetch_end_time=' + selectedDate.format("YYYY-MM-DDT") +  selectedTimeEnd.format("HH:mm") +
                  '&filter_by_meal_type=lunch' + onlyMCRecipes + (fromForecast ? "&forecast=True" : ''));
              if (useShelfUrl) {
                  var subShelfIngredients = await YPCPostAxios2Alt(setXPost, 'postProxyMEPShelfURL', lunchOrders, '?&modify_db=false');
              } else {
                  var subAllInstructions = await YPCPostAxios2Alt(setXPost, 'postProxyMEPGetPrepInstructionsURL', lunchOrders, '?show_dish_order_summary_table=false');
                  var subShelfIngredients = subAllInstructions.robot;
                  var subHubIng = subAllInstructions.hub;
                  var subOvenIng = subAllInstructions.oven;
                  var subOutsourcedIng = subAllInstructions.outsourced;
              };
              setXPost(null);

              tempList = []
              subShelfIngredients.map((value) => {
                  let temp = value;
                  temp['lunch'] = true;
                  temp['dinner'] = false;
                  temp['lunchdinner'] = false;
                  tempList.push(temp);
              });
              shelfIngredients = shelfIngredients.concat(tempList);

              if (!useShelfUrl) {
                  tempList = []
                  subHubIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = true;
                      temp['dinner'] = false;
                      temp['lunchdinner'] = false;
                      tempList.push(temp);
                  });
                  hubIng = hubIng.concat(tempList);

                  tempList = []
                  subOutsourcedIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = true;
                      temp['dinner'] = false;
                      temp['lunchdinner'] = false;
                      tempList.push(temp);
                  });
                  outsourcedIng = outsourcedIng.concat(tempList);

                  tempList = []
                  subOvenIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = true;
                      temp['dinner'] = false;
                      temp['lunchdinner'] = false;
                      tempList.push(temp);
                  });
                  ovenIng = ovenIng.concat(tempList);
                };

            };
            if (checkDinner) {
              dinnerOrders = await YPCGetAxiosAlt(xget, setXGet, 'getProxyFetchDishOrdersURL', '&fetch_start_time=' + selectedDate.format("YYYY-MM-DDT") + 
                  selectedTimeStart.format("HH:mm") + '&fetch_end_time=' + selectedDate.format("YYYY-MM-DDT") +  selectedTimeEnd.format("HH:mm") +
                  '&filter_by_meal_type=dinner' + onlyMCRecipes + (fromForecast ? "&forecast=True" : ''));
              if (useShelfUrl) {
                  var subShelfIngredients = await YPCPostAxios2Alt(setXPost, 'postProxyMEPShelfURL', dinnerOrders, '?&modify_db=false');
              } else {
                  var subAllInstructions = await YPCPostAxios2Alt(setXPost, 'postProxyMEPGetPrepInstructionsURL', dinnerOrders, '?show_dish_order_summary_table=false');
                  var subShelfIngredients = subAllInstructions.robot;
                  var subHubIng = subAllInstructions.hub;
                  var subOvenIng = subAllInstructions.oven;
                  var subOutsourcedIng = subAllInstructions.outsourced;
              };
              setXPost(null);
              tempList = []
              subShelfIngredients.map((value) => {
                  let temp = value;
                  temp['lunch'] = false;
                  temp['dinner'] = true;
                  temp['lunchdinner'] = false;
                  tempList.push(temp);
              });

              shelfIngredients = shelfIngredients.concat(tempList);

              if (!useShelfUrl) {
                  tempList = []
                  subHubIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = false;
                      temp['dinner'] = true;
                      temp['lunchdinner'] = false;
                      tempList.push(temp);
                  });
                  hubIng = hubIng.concat(tempList);

                  tempList = []
                  subOutsourcedIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = false;
                      temp['dinner'] = true;
                      temp['lunchdinner'] = false;
                      tempList.push(temp);
                  });
                  outsourcedIng = outsourcedIng.concat(tempList);

                  tempList = []
                  subOvenIng.map((value) => {
                      let temp = value;
                      temp['lunch'] = false;
                      temp['dinner'] = true;
                      temp['lunchdinner'] = false;
                      tempList.push(temp);
                  });
                  ovenIng = ovenIng.concat(tempList);
                };

            };

          } else {

              await YPCPostAxios2Alt(setXPost, 'postProxyPlaceDishOrdersURL', JSON.parse(orderString), '');
              demoOrders = await YPCGetAxiosAlt(xget, setXGet, 'getProxyDishOrdersURL', '');
              if (useShelfUrl) {
                  var subShelfIngredients = await YPCPostAxios2Alt(setXPost, 'postProxyMEPShelfURL', demoOrders, '?&modify_db=false');
              } else {
                  var subAllInstructions = await YPCPostAxios2Alt(setXPost, 'postProxyMEPGetPrepInstructionsURL', demoOrders, '?show_dish_order_summary_table=false');
                  var subShelfIngredients = subAllInstructions.robot;
                  var subHubIng = subAllInstructions.hub;
                  var subOvenIng = subAllInstructions.oven;
                  var subOutsourcedIng = subAllInstructions.outsourced;
              };
              setXPost(null);

              shelfIngredients = shelfIngredients.concat(subShelfIngredients);

              if (!useShelfUrl) {
                hubIng = hubIng.concat(subHubIng);
                ovenIng = ovenIng.concat(subOvenIng);
                outsourcedIng = outsourcedIng.concat(subOutsourcedIng);
              };

          };

      console.log('YIYIYIYIYIYIYI', shelfIngredients);

        // console.log('YIYIYIYIYI', hubIng);

        // console.log('XIXIXIXIXI', shelfIngredients);


        // this section does the original shelf ingredient stuff

        const IngredientNames = new Set();
        shelfIngredients.map((value) => {  
          if (  !IngredientNames.has(value['ing_name']) ) {
            IngredientNames.add(value['ing_name']);
          };
        });


        tempList = [];
        IngredientNames.forEach (function(record) {

          // count Names
          let count = 0;
          let lunchcount = 0;
          let dinnercount = 0;
          let offlinecount = 0;
          let lunchdinnercount = 0;
          let location = '';
          let prep_notes_list =[];
          shelfIngredients.map((value) => {
            if (value['ing_name'] === record) {
              count = count + 1;
              if (value.hasOwnProperty('lunch')) {
                if (value['lunch']) {
                    lunchcount = lunchcount + 1;
                };
                if (value['dinner']) {
                    dinnercount = dinnercount + 1;
                };
                if (value['lunchdinner']) {
                    lunchdinnercount = lunchdinnercount + 1
                };
                
              };
              offlinecount = count - dinnercount - lunchcount - lunchdinnercount
              location = location + value['pose'].substr(4, 6) + value['depth_counter'].toString() + '-'
              if (value['preparing_notes'] !== "") {
                let tempPrep = value['preparing_notes']
                tempPrep = tempPrep.replace('[', '');
                tempPrep = tempPrep.replace(']',' - ');
                prep_notes_list.push( value['pose'].substr(4, 6) + value['depth_counter'].toString() + " - " + tempPrep)
              };
            };

          })

          location = location.slice(0,-1);
          // create ingredient list as string
          for (let i = 0; i< shelfIngredients.length; i ++) {
            let newName = '';
            let infoDict = [];
            if (shelfIngredients[i]['ing_name'] === record) {

                if (shelfIngredients[i]['mise_en_place_steps'].length !== 0) {
                    shelfIngredients[i]['mise_en_place_steps'].map((subValue) => {
                        newName = newName + subValue['amount'] + 'g ' +  subValue['ing_name'] + ' | '
                        // infoDict[subValue['ing_name']] = {'ing_id': subValue['ing_id'], 'amount': subValue['amount'] }
                        infoDict.push({'ing_name': subValue['ing_name'], 'ing_id': subValue['ing_id'], 'amount': subValue['amount'] })
                    });
                    newName = newName.slice(0, -3);
                    tempList.push({'id': id, 'print_info': infoDict, 'name': newName, 'container': shelfIngredients[i]['container_type_info']['name'], 'count': count, 'lunchdinnercount': lunchdinnercount, 'dinnercount': dinnercount, 'lunchcount': lunchcount, 'offlinecount':offlinecount,'location': location, 'prep_notes': prep_notes_list});    
                } else {
                    newName = shelfIngredients[i]['remaining_quantity'] + 'g ' + shelfIngredients[i]['ing_name'];
                    // infoDict[shelfIngredients[i]['ing_name']] =  {'ing_id': shelfIngredients[i]['ing_id'], 'amount': shelfIngredients[i]['remaining_quantity'] }
                    infoDict.push({'ing_name': shelfIngredients[i]['ing_name'], 'ing_id': shelfIngredients[i]['ing_id'], 'amount': shelfIngredients[i]['remaining_quantity'] })
                    tempList.push({'id': id, 'print_info' : infoDict, 'name': newName, 'container': shelfIngredients[i]['container_type_info']['name'], 'count': count, 'lunchdinnercount': lunchdinnercount, 'dinnercount': dinnercount, 'lunchcount': lunchcount, 'offlinecount':offlinecount,'location': location, 'prep_notes': prep_notes_list});    
                }

              break;         
            }
          }

          id = id + 1;

        })

        // console.log('XUXUXUXUXUXUXU', hubIng);

        if (!useShelfUrl) {
          tempHubList = processNonShelf(hubIng, 'Hub');
          tempOutsourcedList = processNonShelf(outsourcedIng, 'Outsourced');
          // console.log('NANANANANANANANAN')
          tempOvenList = processNonShelf(ovenIng, 'Oven');


          console.log('XPXPXPXPXPXXP', tempHubList);
          console.log('XQXQXQXQXQXQX', tempOutsourcedList);
          console.log('XRQRQQRRQRQRQR', tempOvenList);


          // console.log('XPXPXPXPXPXPXPXP', tempHubList);
        };

          // console.log('XKXKXKXKXKXKXKXK', tempList);

        
    };

    // console.log('JQJQJQJQJ', tempList);
    setIngredientList(tempList)

    if (!useShelfUrl) {
      setHubList(tempHubList);
      setOutsourcedList(tempOutsourcedList);
      setOvenList(tempOvenList);
    };

    setIngredientsLoaded(true);
    setLoading(false);

  };


  function processNonShelf(nonShelfList, tableType) {

        // console.log('CZCZCZCZCZC', nonShelfList);

        //  collect all recipe names
        const RecipeNames = new Set();
        nonShelfList.map((value) => {  
          if (  !RecipeNames.has(value['recipe_name']) ) {
            RecipeNames.add(value['recipe_name']);
          };
        });

        let tempList = [];
        let recipeTemp = null;
        let id = 1;
        if (tableType === 'Hub') {
          id = 10001;
        } else if (tableType === 'Oven') {
          id = 20001;
        };

        RecipeNames.forEach (function(record) {

          // count Names
          let count = 0;
          let lunchcount = 0;
          let dinnercount = 0;
          let lunchdinnercount = 0;
          let offlinecount = 0;
          let stepList = null;
          nonShelfList.map((value) => {
            if (value['recipe_name'] === record) {
              count = count + value['portions'];
              if (value.hasOwnProperty('lunch')) {
                if (value['lunch']) {
                    lunchcount = lunchcount + value['portions'];
                };
                if (value['dinner']) {
                    dinnercount = dinnercount + value['portions'];
                };
                if (value['lunchdinner']) {
                    lunchdinnercount = lunchdinnercount + value['portions'];
              };
                
              };
              offlinecount = count - dinnercount - lunchcount;
              recipeTemp = value; // this is actually not needed
              // new stuff
              if (stepList === null) {
                stepList = value['steps'];
              } else {
                for(let i = 0; i< stepList.length; i++) {
                  if (stepList[i]['step_type'] === 'ingredient') {
                    stepList[i]['amount'] = stepList[i]['amount'] + value['steps'][i]['amount'];
                  };
                };
              };

            };

          })

          // console.log('ZBZBZBZBZBZB', stepList);
          // tempList.push({'id':id, 'recipe_name': record, 'steps': recipeTemp['steps'], 'count':count, 'dinnercount':dinnercount, 'lunchcount':lunchcount, 'offlinecount':offlinecount});
          tempList.push({'id':id, 'recipe_name': record, 'steps': stepList, 'count':count, 'dinnercount':dinnercount, 'lunchcount':lunchcount, 'lunchdinnercount': lunchdinnercount, 'offlinecount':offlinecount});
          id = id + 1;

        })

        // console.log('AZAZAZAZAZAZ', tempList);

        id = 1;
        if (tableType === 'Hub') {
          id = 10001;
        } else if (tableType === 'Oven') {
          id = 20001;
        };

        let finalList = [];
        tempList.map((record) => {
          finalList.push({'id':id, 'listtype':'recipe', 'description': record['recipe_name'], 'count':record['count'], 'dinnercount': record['dinnercount'], 'lunchcount':record['lunchcount'], 'lunchdinnercount':record['lunchdinnercount'], 'offlinecount':record['offlinecount'], 'tabletype': tableType });
          id = id + 1;
          if(record.hasOwnProperty('steps')) {
              let description = ''
                  record['steps'].map((value) => {
                    if (value['step_type'] === 'ingredient') {
                      description = '    ' + 'ingredient step - ' + value['ing_name'] + ', ' + value['amount'].toString() + ' (g), to ' + sendToDictNumberToName[value['send_to']];
                      if (value['notes'] !== ""  || value['notes'] !== undefined  || value['notes'] !== null) {
                        description = description + ', ' + value['notes']
                      };
                      finalList.push({'id':id, 'listtype':'ingredient','description': description, 'count':' ', 'dinnercount': ' ', 'lunchcount': ' ', 'lunchdinnercount': ' ', 'offlinecount':' ', 'tabletype': tableType });
                    } else if (value['step_type'] === 'oven') {
                      description = '    ' + 'oven step - ' + value['cook_temp'].toString() + ' (°C), ' + value['cook_time'].toString() + ' (s), '  + value['cook_humidity'].toString() + ' (%H), ' + value['mode'] + ' (Mode)';
                      if (value['notes'] !== ""  || value['notes'] !== undefined || value['notes'] !== null) {
                        description = description + ', ' + value['notes']
                      };
                      finalList.push({'id':id, 'listtype':'oven', 'description': description, 'count':' ', 'dinnercount': ' ', 'lunchcount': ' ',  'lunchdinnercount': ' ','offlinecount':' ', 'tabletype': tableType });
                    } else if (value['step_type'] === 'cooking') {
                      description = '    ' + 'cooking step - ' + value['cook_temp'].toString() + ' (°C), ' + value['cook_time'].toString() + ' (s), '  + value['cook_speed'].toString() + ' (Speed), '
                          + value['increase_duration'].toString() + ' (s) Inc Dur, ' + value['lid_status'] + ' Lid Status';
                      if (value['notes'] !== "" || value['notes'] !== undefined || value['notes'] !== null) {
                        description = description + ', ' + value['notes']
                      };
                      finalList.push({'id':id, 'listtype':'cooking', 'description': description, 'count':' ', 'dinnercount': ' ', 'lunchcount': ' ',  'lunchdinnercount': ' ','offlinecount':' ', 'tabletype': tableType });
                    } else if (value['step_type'] === 'human') {
                      description = '    ' + 'human step - ' + ' '
                      if (value['notes'] !== ""  || value['notes'] !== undefined || value['notes'] !== null) {
                        description = description + ', ' + value['notes']
                      };                  
                      finalList.push({'id':id, 'listtype':'human','description': description, 'count':' ', 'dinnercount': ' ',  'lunchdinnercount': ' ','lunchcount': ' ', 'offlinecount':' ', 'tabletype': tableType });
                    };
                    id = id + 1;
                  });
          };
        });

        return finalList;


  }



  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  const ingredientColumns = [
    {
      field: 'container',
      headerName: 'Container',
      type: 'string',
      width: 200,
      sortable: true,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Ingredient',
      type: 'string',
      width: 1200,
      sortable: true,
      editable: false,
    },
    {
      field: 'count',
      headerName: 'Count',
      type: 'number',
      sortable: true,
      width: 160,
      editable: false,
    },
    {
      field: 'location',
      headerName: 'Location',
      type: 'string',
      width: 1200,
      sortable: true,
      editable: false,
    },

  ];

  const ingredientLunchDinnerColumns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
      sortable: true,
      width: 100,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
    },
    {
      field: 'count',
      headerName: 'Count',
      type: 'number',
      sortable: true,
      width: 130,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
    },      
    {
      field: 'container',
      headerName: 'Container',
      type: 'string',
      width: 190,
      sortable: true,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
    },
    {
      field: 'name',
      headerName: 'Ingredient',
      type: 'string',
      width: 1500,
      sortable: true,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
    },
    // {
    //   field: 'count',
    //   headerName: 'Count',
    //   type: 'number',
    //   sortable: true,
    //   width: 130,
    //   editable: false,
    //   cellClassName: (params) =>  // you could use params.value or params.row.side
    //     clsx('super-app', {
    //       show: (params.row.id === selectRow) ? true: false,
    //     }),
    // },    
    {
      field: 'offlinecount',
      headerName: 'Offline',
      type: 'number',
      sortable: true,
      width: 130,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
    },    
    {
        field: 'lunchcount',
        headerName: 'Lunch',
        type: 'number',
        sortable: true,
        width: 130,
        editable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
      },    
      {
        field: 'dinnercount',
        headerName: 'Dinner',
        type: 'number',
        sortable: true,
        width: 130,
        editable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
      },
      {
        field: 'lunchdinnercount',
        headerName: 'Optimized',
        type: 'number',
        sortable: true,
        width: 130,
        editable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
      },    
      {
        field: 'location',
        headerName: 'Location',
        type: 'string',
        width: 1500,
        sortable: true,
        editable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectRow) ? true: false,
        }),
      }, 
  ];


  const handleDateChange = (date) => {
    // console.log('GAGAGA', moment(date).format("YYYY-MM-DDTHH:mm"));
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };


  const nonIngredientColumns = [
    // {
    //   field: 'id',
    //   headerName: 'Id',
    //   type: 'number',
    //   sortable: true,
    //   width: 100,
    //   editable: false,
    //   cellClassName: (params) =>  // you could use params.value or params.row.side
    //     clsx('super-app', {
    //       show: (params.row.id === selectNonRowHub) ? true: false,
    //     }),
    //   valueFormatter: (params) => {
    //     if (params.row.listtype === 'record') {
    //       return params.value;
    //     } else {
    //       return ' '
    //     };
    //   },
    //  },
    {
      field: 'count',
      headerName: 'Count',
      type: 'number',
      sortable: true,
      width: 130,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectNonRowHub || params.row.id === selectNonRowOven || params.row.id === selectNonRowOutsourced) ? true: false,
        }),
    },      
    {
      field: 'description',
      headerName: 'Recipe',
      type: 'string',
      width: 700,
      sortable: true,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectNonRowHub || params.row.id === selectNonRowOven || params.row.id === selectNonRowOutsourced) ? true: false,
          indent: params.row.listtype !== 'recipe',
          noindent: !params.row.listtype === 'recipe',
        }),
    },
    {
      field: 'offlinecount',
      headerName: 'Offline',
      type: 'number',
      sortable: true,
      width: 130,
      editable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectNonRowHub || params.row.id === selectNonRowOven || params.row.id === selectNonRowOutsourced) ? true: false,
        }),
    },    
    {
        field: 'lunchcount',
        headerName: 'Lunch',
        type: 'number',
        sortable: true,
        width: 130,
        editable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectNonRowHub || params.row.id === selectNonRowOven || params.row.id === selectNonRowOutsourced) ? true: false,
        }),
      },    
      {
        field: 'dinnercount',
        headerName: 'Dinner',
        type: 'number',
        sortable: true,
        width: 130,
        editable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectNonRowHub || params.row.id === selectNonRowOven || params.row.id === selectNonRowOutsourced) ? true: false,
        }),
      },
      {
        field: 'lunchdinnercount',
        headerName: 'Optimized',
        type: 'number',
        sortable: true,
        width: 130,
        editable: false,
        cellClassName: (params) =>  // you could use params.value or params.row.side
        clsx('super-app', {
          show: (params.row.id === selectNonRowHub || params.row.id === selectNonRowOven || params.row.id === selectNonRowOutsourced) ? true: false,
        }),
      },
  ];


  const handleStartTimeList = (value) => {
    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeStart(newTime);

  };

  const handleEndTimeList = (value) => {

    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeEnd(newTime);

  };

  const handleRowSelect = (params) => {

    let tempGlobalDisplay = {
      'container': params.row.container,
      'name' : params.row.name,
      'count': params.row.count,
      'offlinecount': params.row.offlinecount,
      'lunchcount': params.row.lunchcount,
      'dinnercount': params.row.dinnercount,
      'lunchdinnercount': params.row.lunchdinnercount,
      'location': params.row.location,
      'id': params.row.id,
      'notes': params.row.prep_notes,
      'print_info': params.row.print_info
    };

    // console.log('XUXUXUXUUXU', tempGlobalDisplay);

    setGlobalDisplay(tempGlobalDisplay);
    setSelectRow(params.row.id);
    setShowRow(true);
  };


  const handleNonRowSelect = (params) => {

    if (tableChoice === 'Hub') {
      setSelectNonRowHub(params.row.id);
    } else if (tableChoice === 'Oven') {
      setSelectNonRowOven(params.row.id);
    } else if (tableChoice === 'Outsourced') {
      setSelectNonRowOutsourced(params.row.id);
    }

  };


  function IngredientTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          rows={ingredientList}
        //   columns={ingredientColumns}
          // columns={selectedPeriod === 'lunch & dinner' ? ingredientLunchDinnerColumns  :ingredientColumns}
          columns={ ingredientLunchDinnerColumns}
          // disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleRowSelect}
          autoHeight={true}
          checkboxSelection
          disableSelectionOnClick
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
            // Toolbar: GridToolbar
          }}
        />
      </div>
    );
  };

  function nonIngredientTable(tList) {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          rows={tList}
        //   columns={ingredientColumns}
          // columns={selectedPeriod === 'lunch & dinner' ? ingredientLunchDinnerColumns  :ingredientColumns}
          columns={ nonIngredientColumns}
          // disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleNonRowSelect}
          autoHeight={true}
          checkboxSelection
          disableSelectionOnClick
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
            // Toolbar: GridToolbar
          }}
        />
      </div>
    );
  };


// shelves stuff

function handleClick(i, j,k, auto) {
  console.log('OWOWOWOWOWOWO', i,j,k, auto);
  // setMousePressMessage(containerContents[i][j][k]);

}

function handleMouseDown(i, j, k) {
  setMousePress(true);
  setMousePressMessage(containerContents[i][j][k]);
}

function handleMouseUp(i, j, k) {
  setMousePress(false);
  setMousePressMessage(null);
}

function AltContainer(value, onclick, onmousedown, onmouseup,    r, c, d) {

  let classValue = null;
  if (value === null) {
    classValue = "noshow-container";
  } else if (value === 'x') {
    classValue = "black-container";
  } else if (value === 's') {
    classValue = "green-container";
  } else if (value === 'S') {
    classValue = "green-select";
  } else {
    classValue = "container";
  }

  if (onclick === null) {
    return (
        <button className={classValue} onClick={onclick} onMouseOver={onmousedown} onMouseOut={onmouseup}>
        </button>
    );
  } else {
    return (
        // <button className={classValue} onClick={onclick} onMouseDown={onmousedown} onMouseUp={onmouseup}>
        // </button>

        <button className={classValue} onClick={() => onclick(r,c,d,false)} onMouseOver={() => onmousedown(r,c,d)} onMouseOut={() => onmouseup(r,c,d)}>
        </button>

    );
    }


}


function NoContainer() {  
  return ( // get rid of below button
      <button className="empty-container"> 
        {/* <img src={props.image}></img> */}
      </button>
  );
}

function renderContainer(r, d, c, d1, ScontainerState) {
  if (d === d1 && ScontainerState[r][c][d] !== null) {
    return ( 
      // AltContainer(ScontainerState[r][c][d], handleClick(r, c, d, false), handleMouseDown, handleMouseUp,)
      AltContainer(ScontainerState[r][c][d], handleClick, handleMouseDown, handleMouseUp, r,c,d)
    );
  }
  else if (d === d1) {          
      return (
        AltContainer(null, null, null, null, null,   null, null)
      );
  } else {
      return ( 
        NoContainer()

      );
  }
}

function SmallContainer() {
  return (
    <button className="empty-container" style={{height: `30px`}}></button>
  )
}

function BiggerSmallContainer() {
  return (
    <button className="bigger-empty-container" style={{height: `30px`}}></button>
  )
}

function renderEmptyRow(cols, depth) {
  let testList = [];
  for(let c = 0;c<cols; c++) {
    testList.push(
      <div>
        <BiggerSmallContainer/>
      </div>
    );
    for(let d = 0;d<depth-1; d++) {
      testList.push(
        <div>
          <SmallContainer/>
        </div>
      );
    }
  }
  return testList
}

function renderRows(r, d, Scolumns, Sdepth, ScontainerState, viewPoint) {
  let testList=[];

  if (viewPoint) {
      for(let c = 0; c<Scolumns; c++) {
        for(let d1 = 0; d1<Sdepth; d1++) {
          testList.push(
            <div key={c*Scolumns+d1}>
                {renderContainer(r, d, c, d1, ScontainerState )}
            </div>
          );
        }
      }
  }
  else {
      for(let c = Scolumns-1; c>=0; c--) {
        for(let d1 = 0; d1<Sdepth; d1++) {
          testList.push(
            <div key={c*Scolumns+d1}>
                {renderContainer(r, d, c, d1,ScontainerState )}
            </div>
          );
        }
      }
  }
  return testList;
}

  function shelfFinal() {
    if (humanObject) {
    // if (containerState) {
      let shelfID = 1;
      let keyList = Object.keys(humanObject);
      let depth = 0;
      keyList.forEach((indexList) => {
        if (parseInt(indexList[4]) > depth) {
          depth = parseInt(indexList[4])
        };
      });
      depth = depth + 1;
      if (activeStep === 4) {
        shelfID = 1;
      } else {
        shelfID = 6;
      }
      let rows = shelfSizes[shelfID].row;
      let columns = shelfSizes[shelfID].columns;

      const containerWidth = columns * 46 + columns * (depth-1) * 34;

      let itemList=[];
      for(let r = 0; r<rows; r++) {
        for(let d=depth-1; d>=0; d--) {
              itemList.push(
                  <div key={r*depth + d} className="Shelf-row">
                      {renderRows(r, d, columns, depth, containerState, frontView[shelfID])}
                  </div>);
        }
        itemList.push(
          <div  className="Shelf-row">
            {renderEmptyRow(columns, depth)}
          </div>);
      }

      return (
        <React.Fragment>
            <div className="rack">
              <div className="rack-shelf" style={{width: `${containerWidth}px` }}>
                {itemList}
              </div>
            </div>
        </React.Fragment>
      )


    } else {
      return null;
    }

  };


  const ROSConnect = () => {
    const { isConnected, topics, url, changeUrl, toggleConnection, toggleAutoconnect, autoconnect } = useROS();

    if (!autoconnect) {
        toggleAutoconnect();
    }
      SetRosConnected(isConnected);
    return (
      null
    );
  }  


  function showRowDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div>
                        <div>
                          Id: {globalDisplay['id']}
                        </div>
                        <div>
                          Container: {globalDisplay['container']}
                        </div>
                        <div>
                          Contents: {globalDisplay['name']}
                        </div>
                        <div>
                          Total count: {globalDisplay['count']}
                        </div>
                        <div>
                          Offline count: {globalDisplay['offlinecount']}
                        </div>
                        <div>
                          Lunch count: {globalDisplay['lunchcount']}
                        </div>
                        <div>
                          Dinner count: {globalDisplay['dinnercount']}
                        </div>
                        <div>
                          Optimized count: {globalDisplay['lunchdinnercount']}
                        </div>                 
                        <div>
                          Location Details (-shelf row column depth-): {globalDisplay['location']}
                        </div>
                        {globalDisplay['notes'].length !== 0 ? "Notes:" : null}
                        <div>
                        {globalDisplay['notes'].length !== 0 ? 
                            globalDisplay['notes'].map((value) => { 
                              return (<div style = {{marginLeft: '20px'}}>{value}</div>)
                            })
                        : null}
                        </div>

                    </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSendPrintInfo} color="primary">
              Print
            </Button>
            <Button onClick={handleShowRowDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };

  const handleShowRowDialog = () => {
    setShowRow(false);
};

async function handleSendPrintInfo() {
  console.log('LXLXLXLXXLXL',{'container': globalDisplay['container'], 'count':globalDisplay['count'], 'info': globalDisplay['print_info']});
  let content = {'container': globalDisplay['container'], 'count':globalDisplay['count'], 'info': globalDisplay['print_info']}
  // let response = YPCPutAxios2(setXPost, 'printContainerTicketURL', content, '');

  await YPCPutAxios2Alt(setXPost, 'printContainerTicketURL', content, '');
  // await YPCPutAxios2Alt(setXPost, 'printContainerTicketURL', globalDisplay, '');

  setShowRow(false);
};


async function handleSendPrintAll() {

    if (ingredientList.length !== 0) {
      // setStuff(JSON.stringify(finalBuffer))
      await YPCPutAxios2Alt(setXPost, 'printContainerTicketURL', ingredientList, '?mepprint=True');
    }
};


    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>
                  {showRow ? showRowDialog(): null}
                  <Typography component="h4" variant="h4">
                      Hub MEP Containers
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>




          {/* Set Calendar */}
            <React.Fragment>

                <Grid item xs={12}>   
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                            <Grid item xs={12} sm={12} md={12} lg={12}> 
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                  <DatePicker
                                      orientation={"landscape"}
                                      variant="static"
                                      margin="normal"
                                      id="date-picker-dialog"
                                      label="Date picker dialog"
                                      // format="MM/dd/yyyy"
                                      format={"dddd, " + "MMMM" + ", yyyy"}
                                      value={selectedDate}
                                      onChange={handleDateChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                  />
                              {/* </LocalizationProvider> */}
                            </Grid>
                          </MuiThemeProvider>
                      </MuiPickersUtilsProvider>
                </Grid>


                <Grid container item xs={12} direction='column'> 
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                                <Grid item xs={2}> 
                                      <Autocomplete
                                          onChange={(event, value) => handleStartTimeList(value)}
                                          id="combo-box-start"
                                          value={selectedTimeStart.format("HH:mm")}
                                          options={startTimeList}
                                          // getOptionLabel={(option) => option.name}
                                          // style={{ width: 300 }}
                                          renderInput={(params) => <TextField {...params} label='Start' InputLabelProps={{
                                              shrink: true,
                                              style: {color: '#78cbff'},
                                          }}
                                          // variant="outlined"
                                          />} 
                                      />


                                      <p></p>

                                      <Autocomplete
                                            onChange={(event, value) => handleEndTimeList(value)}
                                            id="combo-box-end"
                                            value={selectedTimeEnd.format("HH:mm")}
                                            options={endTimeList}
                                            // getOptionLabel={(option) => option.name}
                                            // style={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label={translate(props.language, 'End')} InputLabelProps={{
                                                shrink: true,
                                                style: {color: '#78cbff'},
                                            }}
                                            // variant="outlined"
                                            />} 
                                        />
                                </Grid>
                                </MuiThemeProvider>
                            </MuiPickersUtilsProvider>
                      </Grid>


                      <Grid item xs={12}>

                        <Grid container direction="row" alignItems="stretch" spacing={2}>
                        <MuiThemeProvider theme={theme}>
                            <Grid item xs={1}>
                                <FormControlLabel control={
                                  <Checkbox
                                      value="checkedshelf"
                                      checked={!useShelfUrl}
                                      onChange = {(event, value) => setUseShelfUrl(!value)}
                                      // onChange={handleHumanCheckChange}
                                      // inputProps={{
                                      //   'aria-label': 'Checkbox A',
                                      // }}
                                    />}

                                  label="Chef Prep" />
                              </Grid>

                          </MuiThemeProvider>
                          </Grid>
                      </Grid>


                      <Grid item xs={12}>

                        <Grid container direction="row" alignItems="stretch" spacing={2}>
                        <MuiThemeProvider theme={theme}>
                            <Grid item xs={1}>
                                <FormControlLabel control={
                                  <Checkbox
                                      value="checkeddemo"
                                      checked={checkDemo}
                                      onChange = {(event, value) => setCheckDemo(value)}
                                      // onChange={handleHumanCheckChange}
                                      // inputProps={{
                                      //   'aria-label': 'Checkbox A',
                                      // }}
                                    />}

                                  label="Demo" />
                              </Grid>

                          </MuiThemeProvider>
                          </Grid>
                      </Grid>


                      {checkDemo ?
                          <Grid item xs={12}>
                            <Grid container direction="row" alignItems="stretch" spacing={2}>
                                <Grid item xs={6}>
                                    <Typography style={{color: "#78cbff",  fontSize: '12px' }}>{"Demo Order"}</Typography>
                                    <TextareaAutosize
                                        maxRows={40}
                                        minRows={40}
                                        aria-label="maximum height notes en"
                                        label="Demo Order"
                                        value = {orderString}
                                        helperText="Demo Order"
                                        onChange={(e) => {
                                            setOrderString(
                                                e.target.value)
                                        }}
                                        // style={{ width: 600, resize: 'none' }}
                                        style={{ width: 1000}}
                                    />

                                </Grid>             
                            </Grid>
                          </Grid>
                      : null }

                      {!checkDemo ?
                          <Grid item xs={12}>

                            <Grid container direction="row" alignItems="stretch" spacing={2}>
                            <MuiThemeProvider theme={theme}>
                                <Grid item xs={1}>
                                    <FormControlLabel control={
                                      <Checkbox
                                          value="checkedoffline"
                                          checked={checkOffline}
                                          onChange = {(event, value) => {
                                              setCheckOffline(value)
                                              setCheckLunchDinner(false)

                                            }
                                          
                                          
                                          }
                                          // onChange={handleHumanCheckChange}
                                          // inputProps={{
                                          //   'aria-label': 'Checkbox A',
                                          // }}
                                        />}

                                      label="Offline" />
                                  </Grid>


                                <Grid item xs={1}>
                                    <FormControlLabel control={
                                      <Checkbox
                                          value="checkedlunch"
                                          checked={checkLunch}
                                          onChange = {(event, value) => {
                                            
                                              setCheckLunch(value)
                                              setCheckLunchDinner(false)

                                            }
                                          
                                          }

                                        />}
                                      label="Lunch" />
                                </Grid>

                                <Grid item xs={1}>
                                    <FormControlLabel control={
                                      <Checkbox
                                          value="checkeddinner"
                                          checked={checkDinner}
                                          onChange = {(event, value) => {
                                            
                                            
                                              setCheckDinner(value)
                                              setCheckLunchDinner(false)

                                            }

                                          
                                          }

                                        />}
                                      label="Dinner" />
                                </Grid>

                                <Grid item xs={1}>
                                    <FormControlLabel control={
                                      <Checkbox
                                          value="checkedlunchdinner"
                                          checked={checkLunchDinner}
                                          onChange = {(event, value) => { 
                                            
                                              setCheckLunchDinner(value)
                                              setCheckLunch(false)
                                              setCheckDinner(false)
                                              setCheckOffline(false)
                                          
                                            }
                                          }
                                          // onChange={handleRobotCheckChange}
                                          // inputProps={{
                                          //   'aria-label': 'Checkbox A',
                                          // }}
                                        />}
                                      label="Offline/Lunch/Dinner Optimize" />
                                </Grid>

                                <Grid item xs={8}>
                                </Grid>
                              </MuiThemeProvider>
                            </Grid>
                        </Grid>

                      : null }


                      {!checkDemo ?
                          <Grid item xs={12}>

                            <Grid container direction="row" alignItems="stretch" spacing={2}>
                            <MuiThemeProvider theme={theme}>
                                <Grid item xs={1}>
                                    <FormControlLabel control={
                                      <Checkbox
                                          value="fromForecast"
                                          checked={fromForecast}
                                          onChange = {(event, value) => {
                                              setFromForecast(value)
                                            }
                                          }
                                        />}

                                      label="Use Forecast" />
                                  </Grid>


                                <Grid item xs={11}>
                                </Grid>
                              </MuiThemeProvider>
                            </Grid>
                        </Grid>

                      : null }




                    <Grid item xs={12}>
                        <div>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={getMEPShelfIngredients}
                                disabled={false}
                                aria-label="select existing recipe"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                {/* {activeStep === steps.length - 1 ? 'Finish' : 'Continue'} */}
                                {'Load Container List'}
                            </Button> 
                            {/* {error ? (                     
                                <Typography component="h5" variant="h5">
                                    Error!
                                </Typography>
                              ) :
                              null
                            } */}

                        </div>
                    </Grid>                    



            </React.Fragment>


            <React.Fragment>
              <Grid item xs={12}>
                <Grid item xs={2}>
                  <Autocomplete
                          onChange={(event, value) => 
                            // handleRobotHumanChoice(value)
                            {
                              setTableChoice(value)

                            }
                          
                          }
                          id="combo-box7"
                          options={['Robot', 'Oven', 'Hub', 'Outsourced']}
                          value={tableChoice}
                          // getOptionLabel={(option) => option.name}
                          // style={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label={translate(props.language,'Preparation Table Type')} InputLabelProps={{
                              shrink: true,
                              style: {color: '#78cbff'},
                          }}
                          // variant="outlined"
                          />} 
                      />
                </Grid>
              </Grid>
            </React.Fragment>


      {/* Prep Raw Ingredients */}

      {tableChoice === 'Robot' ?
            <React.Fragment>
              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Robot Container List'} &#8198;</div>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                    <MuiThemeProvider theme={theme}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                {(loading) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : IngredientTable()}
                                {/* {IngredientTable()} */}
                            </Grid>
                        </Grid>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
              </Grid>

            
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                                  <Grid item xs={2} >
                                            <Button
                                                variant="contained"
                                                // color="primary"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleSendPrintAll}
                                                disabled={false}
                                                aria-label="whatever nextxx"
                                                style={{backgroundColor: '#78cbff', color:"white"}}
                                            >
                                                Print Tickets
                                            </Button>   
                                  </Grid>
                    </Grid>
                    <Grid item xs={9}></Grid>                                    
                </Grid>
              </Grid>


            


            <div><p></p></div>

              <Grid container item xs={12} direction='column'>
                {globalDisplay !== null ?
                    <div>
                        <div>
                          Id: {globalDisplay['id']}
                        </div>
                        <div>
                          Container: {globalDisplay['container']}
                        </div>
                        <div>
                          Contents: {globalDisplay['name']}
                        </div>
                        <div>
                          Total count: {globalDisplay['count']}
                        </div>
                        <div>
                          Offline count: {globalDisplay['offlinecount']}
                        </div>
                        <div>
                          Lunch count: {globalDisplay['lunchcount']}
                        </div>
                        <div>
                          Dinner count: {globalDisplay['dinnercount']}
                        </div>
                        <div>
                          Optimized count: {globalDisplay['lunchdinnercount']}
                        </div>                 
                        <div>
                          Location Details (-shelf row column depth-): {globalDisplay['location']}
                        </div>
                        {globalDisplay['notes'].length !== 0 ? "Notes:" : null}
                        <div>
                        {globalDisplay['notes'].length !== 0 ? 
                            globalDisplay['notes'].map((value) => { 
                              return (<div style = {{marginLeft: '20px'}}>{value}</div>)
                            })
                        : null}

                          {/* Notes: {
                            JSON.stringify(globalDisplay['notes'], null, 2)
                          } */}
                        </div>

                    </div>
                : null} 
              </Grid>
            </React.Fragment>
          : null 
          }

          { !useShelfUrl && tableChoice === 'Oven' ?
              <React.Fragment>
                <Grid item xs={12}>
                              <br />
                              <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Oven List'} &#8198;</div>
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                      <MuiThemeProvider theme={theme}>
                          <Grid container spacing={3}>
                              <Grid item xs={12} >
                                  {(loading) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : nonIngredientTable(ovenList)}
                                  {/* {IngredientTable()} */}
                              </Grid>
                          </Grid>
                      </MuiThemeProvider>
                  </MuiPickersUtilsProvider>
                </Grid>

              
              </React.Fragment>
            : null
          }

          { !useShelfUrl && tableChoice === 'Hub' ?
              <React.Fragment>
                <Grid item xs={12}>
                              <br />
                              <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Hub List'} &#8198;</div>
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                      <MuiThemeProvider theme={theme}>
                          <Grid container spacing={3}>
                              <Grid item xs={12} >
                                  {(loading) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : nonIngredientTable(hubList)}
                                  {/* {IngredientTable()} */}
                              </Grid>
                          </Grid>
                      </MuiThemeProvider>
                  </MuiPickersUtilsProvider>
                </Grid>

              
              </React.Fragment>
            : null
          }


          { !useShelfUrl && tableChoice === 'Outsourced' ?
              <React.Fragment>
                <Grid item xs={12}>
                              <br />
                              <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Outsource List'} &#8198;</div>
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                      <MuiThemeProvider theme={theme}>
                          <Grid container spacing={3}>
                              <Grid item xs={12} >
                                  {(loading) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : nonIngredientTable(outsourcedList)}
                                  {/* {IngredientTable()} */}
                              </Grid>
                          </Grid>
                      </MuiThemeProvider>
                  </MuiPickersUtilsProvider>
                </Grid>

              
              </React.Fragment>
            : null
          }

          <Grid container item xs={12} direction='column'> 
                            <div><p></p></div>
                            <Typography>Or Delete All Dish Orders -</Typography>
                            <p></p>
                        <div>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleDeleteDishOrders}
                                disabled={false}
                                aria-label="handle dish orders"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                Delete
                            </Button>                      

                        </div>

            </Grid>


          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}

        </Grid>
      </Container>

      </ React.Fragment>



    );
};

const defaultOrder = [
  {
    "name": "Gazpacho - Signature",
    "portions": 1,
    "num_of_batch": 1,
    "serving_time_range": [
      "2022-08-16T15:35:00-04:00",
      "2022-08-17T12:00:00-04:00"
    ],
    "dish_order_id": "955f39b0-cb1a-4816-9fed-206022683ac9"
  },
  {
    "name": "Lentil Tabouleh - Signature",
    "portions": 1,
    "num_of_batch": 1,
    "serving_time_range": [
      "2022-08-16T15:40:00-04:00",
      "2022-08-17T12:05:00-04:00"
    ],
    "dish_order_id": "19646a86-a5a9-4dff-98a2-30dac9497499"
  },
  {
    "name": "Broccoli Soup - Signature",
    "portions": 1,
    "num_of_batch": 1,
    "serving_time_range": [
      "2022-08-16T15:40:00-04:00",
      "2022-08-17T12:12:00-04:00"
    ],
    "dish_order_id": "63ee6574-896b-4bf4-b9a0-104e7812219c"
  },
  {
    "name": "Orzo Risotto - Signature",
    "portions": 1,
    "num_of_batch": 1,
    "serving_time_range": [
      "2022-08-16T15:40:00-04:00",
      "2022-08-17T12:18:00-04:00"
    ],
    "dish_order_id": "4c7e2bde-e354-48f5-88b5-106b9686aed4"
  },
  {
    "name": "Green Thai Prawn Curry - Signature",
    "portions": 1,
    "num_of_batch": 1,
    "serving_time_range": [
      "2022-08-16T15:40:00-04:00",
      "2022-08-17T12:23:00-04:00"
    ],
    "dish_order_id": "8d28e456-73c3-42d4-8d15-0358319b19e7"
  },
  {
    "name": "Potato purée - Signature",
    "portions": 1,
    "num_of_batch": 1,
    "serving_time_range": [
      "2022-08-16T15:40:00-04:00",
      "2022-08-17T12:29:00-04:00"
    ],
    "dish_order_id": "7d6464af-eecd-4053-b8c6-80001801bdd8"
  },
  {
    "name": "Sabayon - Signature",
    "portions": 1,
    "num_of_batch": 1,
    "serving_time_range": [
      "2022-08-16T15:40:00-04:00",
      "2022-08-17T12:37:00-04:00"
    ],
    "dish_order_id": "48541950-e4cb-4079-920a-e7695e08f952"
  },
  {
    "name": "Banana and Chocolate Sorbet - Signature",
    "portions": 1,
    "num_of_batch": 1,
    "serving_time_range": [
      "2022-08-16T15:40:00-04:00",
      "2022-08-17T12:42:00-04:00"
    ],
    "dish_order_id": "2053a500-aa92-42a5-89cd-b5544c3d1b0a"
  }
];


