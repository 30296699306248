import { useState, useEffect } from 'react'
import { useROS } from 'react-ros'
import ROSLIB from 'roslib';


var ros = new ROSLIB.Ros();

function ButtonLight(lightButtonOn) {

    // console.log('GGGGGGGGgot here');

    ros.connect('ws://localhost:9090');

    let lightOn = null;
    if (lightButtonOn) {
        lightOn = 1.0;
    } else {
        lightOn = 0.0;
    };

    var cmdButton = new ROSLIB.Topic({
        ros : ros,
        name : '/relay_board_cmd',
        messageType : 'geometry_msgs/Point32'
      });
    
      var buttonVal = new ROSLIB.Message({ x: 0.0, y: 8.0, z: lightOn });

      cmdButton.publish(buttonVal);


    return (
      null
    );
  }
export default ButtonLight;