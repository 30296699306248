import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCGetMenuDB from './YPCGetMenuDB';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "75%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();

const initSelectedDinnerTime = () => {
  let test = moment((new Date()));
  test.set('minute', 0);
  test.set('hour', 18);
  return test;
};

const initSelectedLunchTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 12);
  return test2;
};


// const lunchTimeList = ['11:30', '11:45' , '12:00', '12:10', '12:20', '12:30', '12:40','12:50', '13:00', '13:15', '13:30'];
// const dinnerTimeList = ['17:30', '17:45','18:00', '18:10', '18:20',, '18:30', '18:40', '18:50', '19:00', '19:10','19:20','19:30', '19:45','20:00'];

// const lunchTimeList = ['11:30', '11:45' , '12:00', '12:15', '12:30', '12:45','13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30'];
// const dinnerTimeList = ['17:00', '17:15', '17:30', '17:45','18:00'];


const spiceLookupToString = ['None', 'Low', 'Med', 'Med', 'High', 'High'];

export default function YPCOrderMaster(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [menuLoaded, setMenuLoaded] = React.useState(false);
  const [menuDB, setMenuDB] = React.useState(null);

  const [selectedRow, setSelectedRow] = React.useState("");

  const [starterOrderList, setStarterOrderList] = React.useState([]);
  const [selectStarterDelete, setSelectStarterDelete] = React.useState("");

  const [dessertsOrderList, setDessertsOrderList] = React.useState([]);
  const [selectDessertsDelete, setSelectDessertsDelete] = React.useState("");

  const [fruitsOrderList, setFruitsOrderList] = React.useState([]);
  const [selectFruitsDelete, setSelectFruitsDelete] = React.useState("");

  const [drinksOrderList, setDrinksOrderList] = React.useState([]);
  const [selectDrinksDelete, setSelectDrinksDelete] = React.useState("");

  const [mainsOrderList, setMainsOrderList] = React.useState([]);
  const [selectMainsDelete, setSelectMainsDelete] = React.useState("");
  
  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);
  const [xnull, setXNull] = React.useState(null);

  const [lunchBoxChecked, setLunchBoxChecked] = React.useState({'test': 'test2'});
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = React.useState({'test': 'test2'});

  const [lunchSelectDict, setLunchSelectDict] = React.useState({'test': 'test2'});
  const [dinnerSelectDict, setDinnerSelectDict] = React.useState({'test': 'test2'});

  const [summaryList, setsummaryList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [selectedTimeLunch, setSelectedTimeLunch] = React.useState(initSelectedLunchTime());
  const [selectedTimeDinner, setSelectedTimeDinner] = React.useState(initSelectedDinnerTime());

  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);
  
  // const [noneSubsriberCost, setNoneSubscriberCost] = React.useState(5.0);
  const noneSubscriberCostDict = {'None' : 9.00, 'None5': 5.00, '-' : 9.00};

  const [locale, setLocale] = React.useState()

  const [lunchBlocked, setLunchBlocked] = React.useState(false);
  const [dinnerBlocked, setDinnerBlocked] = React.useState(false);

  const [cutoffMsgOpen, setCutoffMsgOpen] = React.useState(false);

  const [username, setUsername] = React.useState("");
  const [usernameList, setUsernameList] = React.useState([]);

  const [subscriptionType, setSubscriptionType] = React.useState('None');
  
  const [masterSubscriptionSelect, setMasterSubscriptionSelect] = React.useState('-');

  const [lunchTimeList, setLunchTimeList] = React.useState([]);
  const [dinnerTimeList, setDinnerTimeList] = React.useState([]);

  // const [value, setValue] = React.useState(new Date("2021-10-26 13:00"));

  // React.useEffect(() => {
  //   if (!menuLoaded) {
  //     handleLoadDB();
  //   }
  // }, [menuLoaded]);


  React.useEffect(() => {
    calculatesummaryTable();
  }, [mainsOrderList, starterOrderList, dessertsOrderList, drinksOrderList, fruitsOrderList, props.language, masterSubscriptionSelect]);


  React.useEffect(() => {
    getMenu();
    getUserNames();
  }, [selectedDate, username]);


  // const handleLoadDB = () => {
  //   YPCGetMenuDB(xget, setXGet).then(
  //     function(value) { setMenuDB(value);
  //       xtranslate.addLookup(value);
  //     }
  //   );
  //   setMenuLoaded(true);
  // };


  async function getUserNames() {
    // let unames = await YPCGetAxios(xget, setXGet, 'getUsernamesURL', '');
    let unames = await YPCGetAxiosAlt(xget, setXGet, 'getUsernamesURL', '');
    setUsernameList(unames.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }));
    // console.log('IQIQIQIQIQI', test);
  }


  async function getMenu() {

    // big change
    // get subscription type
    // let subType = 'None';
    // if (parseInt(selectedDate.format('YY')) > 21) {
    //   subType = await YPCGetAxiosAlt(xget, setXGet, 'getSubsciptionTypeURL', username);
    // };
    // setSubscriptionType(subType);

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];


    var timinglists = await YPCGetAxiosAlt(xget, setXGet, 'getMiscTimeListsURL', [dateString, 'timelists']);
    console.log('AUAUAUAUAUAU', timinglists)
    setLunchTimeList(timinglists.lunchList);
    setDinnerTimeList(timinglists.dinnerList);

    //  get subscription type
    let subType = 'None';
    if (parseInt(selectedDate.format('YY')) > 21) {
      subType = await YPCGetAxiosAlt(xget, setXGet, 'getDateSubscriptionTypeURL', [dateString, username]);
      setSubscriptionType(subType['subs_type']);      
    };




    // var menu_structure = await YPCGetAxios(xget, setXGet, 'getMenuURL', dateString);
    var menu_structure = await YPCGetAxiosAlt(xget, setXGet, 'getMenuURL', dateString);
    let result = [];
    if (menu_structure.length !== 0) {
      console.log('ZMZMZMZMZMZM', JSON.parse(menu_structure[0].menu));
      result = JSON.parse(menu_structure[0].menu);
    } else {
      console.log('ZMZMZMZMZMZM', menu_structure);
    }

    // var result = await YPCGetAxios(xget, setXGet, 'conciergeMenuGetURL', dateString);  // old one

    let orderResult = [];
    let order = {};
    let dinnerHour = 18;
    let dinnerMinute = 0;
    let lunchHour = 12;
    let lunchMinute = 0;

    let orderExists = false;
    let savedSubscriptionType = 'None';

    // var order_structure =  await YPCGetAxios(xget, setXGet, 'getOrderURL', [dateString, username]);
    var order_structure =  await YPCGetAxiosAlt(xget, setXGet, 'getOrderURL', [dateString, username]);
    if (order_structure.length !== 0) {
      // console.log('ZOZOZOZOZOZ', JSON.parse(order_structure[0].order));
      orderResult = JSON.parse(order_structure[0].order);
      order = orderResult.minimalList;
      dinnerHour = orderResult.dinnerTimeHour;
      dinnerMinute = orderResult.dinnerTimeMinute;
      lunchHour = orderResult.lunchTimeHour;
      lunchMinute = orderResult.lunchTimeMinute;

      // new
      if (orderResult.hasOwnProperty('subscriptionType')) {
        savedSubscriptionType = orderResult.subscriptionType;
        orderExists = true;
      }

    } else {
      // console.log('ZOZOZOZOZO', order_structure);
      order = {crap: {tests: 1}};
    }



    // check if lunch should be blocked
    let currentTime = moment(new Date());
    let timeTest = moment(selectedDate);

    // original time test - 3 hours before service start
    // timeTest.set('hour', 9);
    // timeTest.set('minute', 0);
    // setLunchBlocked(currentTime > timeTest);
    // timeTest.set('hour', 14);
    // timeTest.set('minute', 0);
    // setDinnerBlocked(currentTime > timeTest);

    timeTest.set('hour', 14);
    timeTest.set('minute', 0);
    timeTest.add(-2, 'days')
    setLunchBlocked(currentTime > timeTest);
    setDinnerBlocked(currentTime > timeTest);

    // big change
    // if (currentTime > timeTest && orderExists) {
    // if (orderExists) {      
    //   setSubscriptionType(savedSubscriptionType);
    // } else {
    //   setSubscriptionType(subType);
    // };


    // set serve/delivery time
    let test = moment(selectedDate);
    test.set('minute', dinnerMinute);
    test.set('hour', dinnerHour);
    setSelectedTimeDinner(test);

    let test2 = moment(selectedDate);
    test2.set('minute', lunchMinute);
    test2.set('hour', lunchHour);
    setSelectedTimeLunch(test2);


    // var order1 =  await YPCGetAxios(xget, setXGet, 'conciergeOrderGetURL', props.userName + "_" + dateString);
    // console.log('xaxaxasxa', orderResult);

    let translateList = [];

    if (result.length !== 0) {
        let mainsList = [];
        let starterList = [];
        let dessertsList = [];
        let fruitsList = [];
        let drinksList = [];
        let category = null;
        let tempValue = null;
        let tempLunch = {};
        let tempDinner = {};
        let tempLunchSel = {};
        let tempDinnerSel = {};
        result.map((value) => {

          translateList.push(value);

          tempValue = {
            category: value.category,
            dish: value.dish,
            id: value.id,
            name: value.name,
            price: value.price,
            side: value.side,
            uuid: value.uuid,
            lunchcount: value.uuid in order ? order[value.uuid].lunchcount : null,
            dinnercount:  value.uuid in order ? order[value.uuid].dinnercount : null,
            lunchboxcount:  value.uuid in order ? order[value.uuid].lunchboxcount : null,
            spicelevel: value.spicelevel,
            vegetarian: value.vegetarian,
            vegan: value.vegan,
            sugar_free: value.sugar_free,
            gluten_free: value.gluten_free,
            peanut_free: value.peanut_free,
            baby_friendly: value.baby_friendly,
          };
          if (value.lunchbox) {
            tempLunch[value.uuid] = true;
          };
          if (value.dinneronly) {
            tempDinner[value.uuid] = true;
          };

          if (value.lunchselected) {
            tempLunchSel[value.uuid] = true;
          };
          if (value.dinnerselected) {
            tempDinnerSel[value.uuid] = true;
          };


          if (!value.side) {
            category = value.category.slice();
          };
          if (category === 'Starter') {
              starterList.push(tempValue);
          } else if (category === 'Main') {
              mainsList.push(tempValue);
          } else if (category === 'Dessert') {
              dessertsList.push(tempValue);
          } else if (category === 'Drink') {
              drinksList.push(tempValue);
          } else if (category === 'Fruit') {
              fruitsList.push(tempValue)
          };

        });



        setLunchBoxChecked(tempLunch);
        setDinnerOnlyChecked(tempDinner);

        setLunchSelectDict(tempLunchSel);
        setDinnerSelectDict(tempDinnerSel);

        setMainsOrderList(mainsList);
        setStarterOrderList(starterList);
        setDessertsOrderList(dessertsList);
        setFruitsOrderList(fruitsList);
        setDrinksOrderList(drinksList);        

        xtranslate.addLookupNew(translateList);
    } else {
      setMainsOrderList([]);
      setStarterOrderList([]);
      setDessertsOrderList([]);
      setFruitsOrderList([]);
      setDrinksOrderList([]);

    };

  };



  const handleClearAll = () => {

    let tempList = [];
    mainsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setMainsOrderList(tempList)

    tempList = [];
    starterOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setStarterOrderList(tempList)

    tempList = [];
    dessertsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setDessertsOrderList(tempList)    

    tempList = [];
    fruitsOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setFruitsOrderList(tempList)

    tempList = [];
    drinksOrderList.map((value) => {
        value.lunchcount = null;
        value.dinnercount = null;
        value.lunchboxcount = null;
        tempList.push(value);
    });
    setDrinksOrderList(tempList)

  }


  async function handleCancelOrder () {

    handleClearAll();

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    let sendData = {
      username: username,
      datestring: dateString,
    };


    // console.log('WXWXWXWXWX', combinedLists);

    await YPCPutAxios2Alt(setXPost, 'deleteOrderURL', sendData, '');
    getMenu();

    let newSendData = {
      username: username,
      datestring: dateString,
      order: JSON.stringify({'whatever': 'whatever'})
    };

    await YPCPutAxios2Alt(setXNull, 'sendKitchinEmailCancelURL', newSendData, '');
    setXNull(null);

  };


  const handleSaveOrder = () => {
    let globalOrderList = [];
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    console.log('PPPPPPPP', globalOrderList);

    let minimalList = {};
    globalOrderList.map((value) => {

        minimalList[value.uuid] = {
            id: value.id,
            lunchcount: value.lunchcount,
            dinnercount: value.dinnercount,
            lunchboxcount: value.lunchboxcount
        };
    });


    let currentTime = moment((new Date()));
    // console.log('TXTXTXTXTXT', currentTime.format("YYYY-MM-DDTHH:mm"));

    // console.log('XCXCXCXCXCXCXCX',  moment(selectedTimeLunch).format("YYYY-MM-DDTHH:mm"), moment(selectedTimeDinner).format("YYYY-MM-DDTHH:mm"));

    // console.log('JQJQJQJQJQJ', 
    //   selectedTimeLunch.get('hour'),
    //   selectedTimeLunch.get('minute'),
    //   selectedTimeDinner.get('hour'),
    //   selectedTimeDinner.get('minute'),
    //   moment(selectedTimeLunch).format("YYYY-MM-DDTHH:mm"),
    //   moment(selectedTimeDinner).format("YYYY-MM-DDTHH:mm"),
    //         );

    let frenchList = {'none': 'none'}
    globalOrderList.map((value) => {
      frenchList[value['dish']] = xtranslate.x('FRANCAIS', value['dish']);
    })

    let combinedLists = {
      minimalList: minimalList,
      fullList: globalOrderList,
      lunchTimeHour:  selectedTimeLunch.get('hour'),
      lunchTimeMinute:  selectedTimeLunch.get('minute'),
      dinnerTimeHour:  selectedTimeDinner.get('hour'),
      dinnerTimeMinute:  selectedTimeDinner.get('minute'),
      saveTime: currentTime.format("YYYY-MM-DDTHH:mm"),
      saveDate: moment(selectedDate).format("YYYY-MM-DDTHH:mm"),
      lunchDeliveryTime: moment(selectedTimeLunch).format("YYYY-MM-DDTHH:mm"),
      dinnerDeliveryTime: moment(selectedTimeDinner).format("YYYY-MM-DDTHH:mm"),
      frenchList: frenchList,
      // subscriptionType: subscriptionType
      subscriptionType: masterSubscriptionSelect === '-' ? subscriptionType : masterSubscriptionSelect
    }

    // console.log('TMTMTMTMTMTMTTM', combinedLists.lunchDeliveryTime, combinedLists.dinnerDeliveryTime);

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];


    // YPCPutAxios(setXPost, 'conciergeSaveOrderURL', saveList, props.userName + "_" + dateString);


    let sendData = {
      username: username,
      datestring: dateString,
      order: JSON.stringify(combinedLists)
    };


    // YPCPutAxios2(setXPost, 'saveOrderURL', sendData, '');
    YPCPutAxios2Alt(setXPost, 'saveOrderURL', sendData, '');


    let newCombinedLists = {
      fullList: globalOrderList,
      lunchTimeHour:  selectedTimeLunch.get('hour'),
      lunchTimeMinute:  selectedTimeLunch.get('minute'),
      dinnerTimeHour:  selectedTimeDinner.get('hour'),
      dinnerTimeMinute:  selectedTimeDinner.get('minute'),
      frenchList: frenchList,
      // subscriptionType: subscriptionType
      subscriptionType: masterSubscriptionSelect === '-' ? subscriptionType : masterSubscriptionSelect
    };

    let newSendData = {
      username: username,
      datestring: dateString,
      order: JSON.stringify(newCombinedLists)
    };

    YPCPutAxios2Alt(setXNull, 'sendKitchinEmailURL', newSendData, '');
    setXNull(null);




    // check for cutoff Time
    let cutOffTime = moment(selectedDate).startOf('week').subtract(1, 'days').set('hour', 19);
    // console.log('WWWWWWWWWWWWWWWWweek', cutOffTime.format("YYYY-MM-DDTHH:mm"));
    if (currentTime > cutOffTime) {
      setCutoffMsgOpen(true);
      // console.log('No changes allowed')
    };

  };

  const handleDateChange = (date) => {
    console.log('GAGAGA', moment(date).format("YYYY-MM-DDTHH:mm"));
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };


  // const handleLunchTimeList = (value) => {
  //   console.log('XXXXXXXXXX', value, selectedDate.format("YYYY-MM-DDTHH:mm"));
  //   let newTime = new moment(selectedDate);

  //   lunchTimeList.map((timeValue) => {
  //     if (value === timeValue) {
  //       newTime.set('hour', timeValue.split(":")[0])
  //       newTime.set('minute', timeValue.split(":")[1])
  //     };
  //   });
  //   console.log('XXXXXXXX22222222222', newTime.format("YYYY-MM-DDTHH:mm"), selectedDate.format("YYYY-MM-DDTHH:mm"));
  //   setSelectedTimeLunch(newTime);
  // };

  const handleLunchTimeList = (value) => {
    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeLunch(newTime);

  };

  // const handleDinnerTimeList = (value) => {
  //   console.log('YYYYYYYYYYYYYYYYY', value);
  //   // let newTime = selectedDate;
  //   let newTime = new moment(selectedDate);
  //   dinnerTimeList.map((timeValue) => {
  //     if (value === timeValue) {
  //       newTime.set('hour', timeValue.split(":")[0])
  //       newTime.set('minute', timeValue.split(":")[1])
  //     };
  //   });
  //   console.log('YYYYYYYYYYYYYYYY22222222222', newTime.format("YYYY-MM-DDTHH:mm"))
  //   setSelectedTimeDinner(newTime);
  // };  

  const handleDinnerTimeList = (value) => {

    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeDinner(newTime);

  };


  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  const allowEdit = (lunch) => {
    let globalOrderList = []
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    let final = false;
    globalOrderList.map((value) => {
      if (selectedRow === value.uuid) {
        if (lunch && value.uuid in lunchSelectDict) {
          final = true
        } else if (!lunch && value.uuid in dinnerSelectDict) {
          final = true
        };
      }
    });
    if (username === 'recipetest') {
      final = true;
    }
    return final;
  }

  const columns = [
    {
      field: 'dish',
      // headerName: 'Dish',
      headerName: translate(props.language, 'Selection'),
      width: 300,
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
          indent: params.row.side,
          noindent: !params.row.side,
      }),
      valueFormatter: (params) => {
        const valueFormatted = xtranslate.x(props.language, params.value) + ( Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 ? ' (s' + spiceLookupToString[params.row.spicelevel] + ')' : '') + 
          (params.row.vegetarian ? ' (v)' : '') + 
          (params.row.vegan ? ' (ve)' : '') +
          (params.row.sugar_free ? ' (sf)' : '') +
          (params.row.gluten_free ? ' (gf)' : '') + 
          (!params.row.peanut_free ? ' (p)' : '') + 
          (params.row.baby_friendly ? ' (bf)' : '');
        return `${valueFormatted}`;
      },

    },
    {
      field: 'price',
      headerName: translate(props.language, 'Price/Portion'),
      // headerName: 'Price/Portion',
      type: 'number',
      width: 140,
      sortable: false,
       valueFormatter: (params) => {
        if (params.value === null) {
            return (null);
        }
        else {
            return (currencyFormatter.format(Number(params.value)))
        }
       },

    },
    {
        field: 'lunchcount',
        headerName: translate(props.language, 'Lunch'),
        // headerName: 'Lunch',
        type: 'number',
        sortable: false,
        width: 120,
        // editable: true,
        editable: allowEdit(true),
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                // hide: params.row.side  || params.row.uuid in dinnerOnlyChecked,
                // show: !params.row.side && !(params.row.uuid in dinnerOnlyChecked) && !lunchBlocked,
                // blocked: !params.row.side && !(params.row.uuid in dinnerOnlyChecked) && lunchBlocked
                hide: params.row.side  || !(params.row.uuid in lunchSelectDict),
                show: !params.row.side && (params.row.uuid in lunchSelectDict),
            }),
      },
      {
        field: 'dinnercount',
        headerName: translate(props.language, 'Dinner'),
        // headerName: 'Dinner',
        type: 'number',
        sortable: false,
        width: 120,
        // editable: false,
        editable: allowEdit(false),
        cellClassName: (params) =>  // you could use params.value or params.row.side
            clsx('super-app', {
                // hide: params.row.side,
                // show: !params.row.side && !dinnerBlocked,
                // blocked: !params.row.side && dinnerBlocked
                hide: params.row.side  || !(params.row.uuid in dinnerSelectDict),
                show: !params.row.side && (params.row.uuid in dinnerSelectDict),
        }),
      },
      // {
      //   field: 'lunchboxcount',
      //   headerName: translate(props.language, 'Lunchbox') + '⁽¹⁾',
      //   // headerName: 'Lunchbox',
      //   type: 'number',
      //   sortable: false,
      //   width: 140,
      //   editable: true,
      //   cellClassName: (params) =>  // you could use params.value or params.row.side
      //       clsx('super-app', {
      //           hide: params.row.side || !(params.row.uuid in lunchBoxChecked),
      //           show: !params.row.side && (params.row.uuid in lunchBoxChecked) && !dinnerBlocked,
      //           blocked: !params.row.side && (params.row.uuid in lunchBoxChecked) && dinnerBlocked
      //       }),
      // },      


  ];

  const summaryColumns = [
    {
      field: 'item',
      headerName: translate(props.language, 'Item'),
      // headerName: 'Item',
      width: 300,
      sortable: false,
      valueFormatter: (params) => {
        return xtranslate.x(props.language, params.value);
      },
    },
    {
        field: 'itemtype',
        headerName: translate(props.language, 'Type'),
        // headerName: 'Type',
        width: 140,
        sortable: false,
      },
      {
        field: 'quantity',
        headerName: translate(props.language, 'Quantity'),
        // headerName: 'Quantity',
        type: 'number',
        width: 140,
        sortable: false
      },      
    {
      field: 'price',
      headerName: translate(props.language, 'Price'),
      // headerName: 'Price',
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: (params) => {
        if (typeof params.value === 'string' || params.value instanceof String) {
            return params.value;
        } else {
            const valueFormatted = currencyFormatter.format(Number(params.value));
            return `${valueFormatted}`;
        }
      },
    },
    {
        field: 'total',
        headerName: translate(props.language, 'Total'),
        // headerName: 'Total',
        type: 'number',
        width: 140,
        sortable: false,
        valueFormatter: (params) => {
            if (typeof params.value === 'string' || params.value instanceof String) {
                return params.value;
            } else {
                const valueFormatted = currencyFormatter.format(Number(params.value));
                return `${valueFormatted}`;
            }
          },
      },
  ];





  function calculatesummaryTable() {
    let globalOrderList = [];
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    let tempList = []
    let itemId = 0;
    let item = null;
    globalOrderList.map((value) => {
        if (!value.side) {
            if (value.lunchcount !== null || value.dinnercount !== null || value.lunchboxcount !== null) {
                item = value.dish;
                if (value.lunchcount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Lunch)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Lunch') + ")",
                        quantity: value.lunchcount,
                        price: value.price,
                        total: value.lunchcount * value.price
                    });
                    itemId = itemId + 1;
                };
                if (value.dinnercount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Dinner)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Dinner') + ")",
                        quantity: value.dinnercount,
                        price: value.price,
                        total: value.dinnercount * value.price
                    });
                    itemId = itemId + 1;
                };
                if (value.lunchboxcount !== null) {
                    tempList.push({
                        id: itemId,
                        item: value.dish,
                        // itemtype: value.category + " (Lunchbox)",
                        itemtype: translate(props.language, value.category) + " (" + translate(props.language, 'Lunchbox') + ")",
                        quantity: value.lunchboxcount,
                        price: value.price,
                        total: value.lunchboxcount * value.price
                    });
                    itemId = itemId + 1;
                };



            }
        }
    });

    let subTotal = 0;
    tempList.map((value) => {
        subTotal = subTotal + value.total;
    });

    // big change
    // check on subscription
    if ( (masterSubscriptionSelect === '-' &&  subscriptionType === 'None' && tempList.length !== 0) || 
          (masterSubscriptionSelect === '-' &&  subscriptionType === 'None5' && tempList.length !== 0) ||
          (masterSubscriptionSelect === 'None' && tempList.length !== 0)   ) {
      // subTotal = subTotal + noneSubsriberCost;
      subTotal = subTotal + noneSubscriberCostDict[subscriptionType];
      tempList.push({
        id: itemId,
        item: translate(props.language, 'Non-member fee (cooking & dishwashing)'),
        itemtype: null,
        quantity: null,
        // price: noneSubsriberCost,
        // total: noneSubsriberCost
        price: noneSubscriberCostDict[subscriptionType],
        total: noneSubscriberCostDict[subscriptionType]
      });
      itemId = itemId + 1;
    };



    let totalGST = gst * subTotal;
    let totalPST = pst * subTotal;
    let grandTotal = subTotal + totalGST + totalPST;

    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        price: " ",
        total: " "
    });
    itemId = itemId + 1;

    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'SubTotal',
        price: translate(props.language,'SubTotal'),
        total: subTotal
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'GST (5%)',
        price: translate(props.language,'GST') + ' (5%)',
        total: totalGST
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'PST (9.975%)',
        price: translate(props.language,'PST') + ' (9.975%)',
        total: totalPST
    });
    itemId = itemId + 1;
    tempList.push({
        id: itemId,
        item: null,
        itemtype: null,
        quantity: null,
        // price: 'Total',
        price: translate(props.language,'Total'),        
        total: grandTotal
    });
    setsummaryList(tempList);

  };



  function summaryTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={summaryList}
          columns={summaryColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };


  const handleEditable = (params) => {
    if (params.row.side) {
        return (false);
    } else if (params.field === 'lunchboxcount') {
        if (params.row.uuid in lunchBoxChecked && !dinnerBlocked) {
            return (true);
        } else {
            return (false);
        };
    } else if (params.field === 'lunchcount') {
        if (params.row.uuid in dinnerOnlyChecked) {
          return (false);
        } else if (lunchBlocked) {
          return (false);
        } else {
          return (true);
        };
    } else if (params.field === 'dinnercount') {
        if (dinnerBlocked) {
          return (false);
        } else {
        return (true);
        };
    };
 };



  // Start Functions Section


  function StarterTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
          onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={starterOrderList}
          columns={columns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleStarterSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
        //   getCellClassName={(params) => {
        //     console.log('NNNNNNNNNNNNNNNNNNN',params);
        //     if (params.field === 'city') {
        //       return '';
        //     }
        //     return Number(params.value) >= 15 ? 'hot' : 'cold';
        //   }}


          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  const handleStarterLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = starterOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null),                             

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free,
                            }

                };
                return row;
            
            });
            setStarterOrderList(updatedRows);
        };
    },
    // [starterOrderList],
  );

  const handleStarterSelect = (params) => {
    setSelectStarterDelete(params.row.uuid);
    setSelectedRow(params.row.uuid);
  };


  // End of Starters Section


  // Mains Functions Section

 
  function MainsTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          onCellEditCommit={handleMainsLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={mainsOrderList}
          // rows={mainsAltOrderList}
          columns={columns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleMainsSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  const handleMainsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = mainsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free
                            }

                };
                return row;
            
            });
            setMainsOrderList(updatedRows);
        };
    },
    // [mainsOrderList],
  );

  const handleMainsSelect = (params) => {
    setSelectMainsDelete(params.row.uuid);
    setSelectedRow(params.row.uuid);
  };
  


// end of Mains Section

// start of Desserts Section

function DessertsTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleDessertsLunchCount}
        //   isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={dessertsOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleDessertsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const handleDessertsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = dessertsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free
                            }

                };
                return row;
            
            });
            setDessertsOrderList(updatedRows);
        };
    },
    // [dessertsOrderList],
  );

const handleDessertsSelect = (params) => {
  setSelectDessertsDelete(params.row.uuid);
  setSelectedRow(params.row.uuid);
};



// end of Desserts Section


// start of Drinks section


function DrinksTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleDrinksLunchCount}
        //   isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={drinksOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleDrinksSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const handleDrinksLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = drinksOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free
                            }

                };
                return row;
            
            });
            setDrinksOrderList(updatedRows);
        };
    },
    // [drinksOrderList],
  );

const handleDrinksSelect = (params) => {
  setSelectDrinksDelete(params.row.uuid);
  setSelectedRow(params.row.uuid);
};




// end of drinks sectiion


// start of Fruits Section


function FruitsTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          onCellEditCommit={handleFruitsLunchCount}
        //   isCellEditable={handleEditable}
        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={fruitsOrderList}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleFruitsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <div>
            </div>
          )
        }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const handleFruitsLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = fruitsOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 

                            lunchselected: (row.uuid in lunchSelectDict ? lunchSelectDict[row.uuid] : null), 
                            dinnerselected: (row.uuid in dinnerSelectDict ? dinnerSelectDict[row.uuid] : null), 

                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian,
                            vegan: row.vegan,
                            sugar_free: row.sugar_free
                            }

                };
                return row;
            
            });
            setFruitsOrderList(updatedRows);
        };
    },
    // [fruitsOrderList],
  );

const handleFruitsSelect = (params) => {
  setSelectFruitsDelete(params.row.uuid);
  setSelectedRow(params.row.uuid);
};


function cutoffMsg() {
  return (
    <div>
      <Dialog open={cutoffMsg} >
        <DialogTitle>{translate(props.language, 'If you have modified your order, please notify concierge@jaspercooks.com about this change so we can confirm feasibility.')}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCutoffMsg} color="primary">
            {translate(props.language, 'Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const handleCutoffMsg = () => {
  setCutoffMsgOpen(false);
}



// end of Fruits Section


// gen dialog

const handleGenDialog = () => {
  setXPost(null);
  setXGet(null);
}

  function genDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {xpost}{xget}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };

    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>
                  {/* {cutoffMsgOpen ? cutoffMsg() : null} */}
                  {xpost !== null || xget !== null ? genDialog() : null}
                  <Typography component="h4" variant="h4">
                      {translate(props.language,'Order Viewer')}
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>

          <Grid item xs={12}>

                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              {/* <KeyboardDatePicker */}
                              <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date picker dialog"
                                  // format="MM/dd/yyyy"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>

          </Grid>



          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'User Name')} &#8198;</div>
          </Grid>


          <Grid item xs={12}>
              <Autocomplete
                  onChange={(event, value) => setUsername(value)}
                  id="combo-box-username"
                  value={username}
                  options={usernameList}
                  // getOptionLabel={(option) => option.name}
                  style={{ width: 200 }}
                  renderInput={(params) => <TextField {...params} label={translate(props.language, 'User Name')} InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                  }}
                  // variant="outlined"
                  />} 
              />
            </Grid>




          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Time')} &#8198;</div>
          </Grid>


            {/* <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={translate(props.language, 'en')}>
                    <MuiThemeProvider theme={theme}>
                    <TimePicker
                    // showTodayButton
                    todayLabel="now"
                    label={translate(props.language, 'Lunch')}
                    value={selectedTimeLunch}
                    minutesStep={5}
                    onChange={handleTimeLunchChange}
                    // onChange={(newValue) => {
                    //   setSelectedTimeLunch(newValue);
                    // }}
                    ampm={false}
                  />
                  </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </Grid> */}

            {/* <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={translate(props.language, 'en')}>
                    <MuiThemeProvider theme={theme}>

                    <TimePicker
                    // showTodayButton
                    todayLabel="now"
                    label={translate(props.language, 'Dinner')}
                    value={selectedTimeDinner}
                    minutesStep={5}
                    onChange={handleTimeDinnerChange}
                    ampm={false}
                  />
                  </MuiThemeProvider>
                </MuiPickersUtilsProvider>

            </Grid> */}


            <Grid item xs={12}>

                <Autocomplete
                    onChange={(event, value) => handleLunchTimeList(value)}
                    id="combo-box-lunch"
                    value={selectedTimeLunch.format("HH:mm")}
                    options={lunchTimeList}
                    // getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    style={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} label='Lunch' InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                    }}
                    // variant="outlined"
                    />} 
                />

            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                  onChange={(event, value) => handleDinnerTimeList(value)}
                  id="combo-box-dinner"
                  value={selectedTimeDinner.format("HH:mm")}
                  options={dinnerTimeList}
                  // getOptionLabel={(option) => option.name}
                  // style={{ width: 300 }}
                  style={{ width: 200 }}
                  renderInput={(params) => <TextField {...params} label='Dinner' InputLabelProps={{
                      shrink: true,
                      style: {color: '#78cbff'},
                  }}
                  // variant="outlined"
                  />} 
              />
            </Grid>


          {/*   Starters Section */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Starters')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={8} >
                        {StarterTable()}
                        {starterOrderList.length > 0 ?
                              <p><small>{translate(props.language,'(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s<None/Low/Med/High>) spice level, (bf) baby friendly, (1) lunchbox deliverd at dinner time')}</small></p>
                              : <p></p>}
                    </Grid>
                  </Grid>
              : null }
          </Grid>
          {/* End of Starters Section */}


          {/* Start of Mains Section */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Mains')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>

              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={8} >
                        {/* {startOrderForm(starterOrderList)} */}
                        {MainsTable()}
                        {mainsOrderList.length > 0 ?
                              <p><small>{translate(props.language,'(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s<None/Low/Med/High>) spice level, (bf) baby friendly, (1) lunchbox deliverd at dinner time')}</small></p>
                              : <p></p>}
                        <p></p>
                    </Grid>
                  </Grid>
              : null }
          </Grid>



          {/* End of Mains Section */}

          {/* Beginning of Desserts */}

          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Desserts')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={8} >
                        {/* {startOrderForm(starterOrderList)} */}
                        {DessertsTable()}
                        {dessertsOrderList.length > 0 ?
                              <p><small>{translate(props.language,'(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (ve) vegan, (s<None/Low/Med/High>) spice level, (bf) baby friendly')}</small></p>
                              : <p></p>}
                    </Grid>
                  </Grid>
              : null }
          </Grid>



          {/* End of Desserts */}


          {/* Start of Drinks */}


          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Drinks')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={8} >
                        {/* {startOrderForm(starterOrderList)} */}
                        {DrinksTable()}
                        {drinksOrderList.length > 0 ?
                              <p><small>{translate(props.language,'(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s<None/Low/Med/High>) spice level, (bf) baby friendly, (1) lunchbox deliverd at dinner time')}</small></p>
                              : <p></p>}
                    </Grid>
                  </Grid>
              : null }
          </Grid>


          {/* End of Drinks */}


          {/* Start of Fruits */}

          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Fruits')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={8} >
                        {/* {startOrderForm(starterOrderList)} */}
                        {FruitsTable()}
                        {fruitsOrderList.length > 0 ?
                              <p><small>{translate(props.language,'(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s<None/Low/Med/High>) spice level, (bf) baby friendly, (1) lunchbox deliverd at dinner time')}</small></p>
                              : <p></p>}
                    </Grid>
                  </Grid>
              : null }
          </Grid>

          {/* End of Fruits */}

          {/* Start of Check Balances */}

          <Grid item xs={12}>
              <div className={classes.sendleft}>

                  <Typography component="h5" variant="h5">
                  {translate(props.language,'Summary')}
                  </Typography>
              </div>
              <div style={{clear: "both"}}></div>
          </Grid>

          <Grid item xs={12}>

              { mealDB ?     
                    <Grid item xs={8} >
                        {summaryTable()}

                    </Grid>
              : null }
          </Grid>


          {/* End of Check Balances */}

          {/* <Grid item xs={12}>
                        <Grid item xs={2}>
                            <Autocomplete
                                onChange={(event, value) => setMasterSubscriptionSelect(value)}
                                value = {masterSubscriptionSelect}
                                id="combo-subchoice"
                                // options={getEditRecipeList()}
                                options={['-', 'None', 'Individual', 'Individual 4 Weeks', 'Family',  'Family 4 Weeks', 'Free', 'Free Subscription', 'CmpPaysAll', 'CmpPaysSub', 'MngPaysAll', 'MngPaysSub']}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={'Subscription Type Override'} InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"

                                />} 
                            />
                        </Grid>
          </Grid> */}

          {/* Upload and Place Order Section */}
          <Grid item xs={12} >          

              <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        // color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleCancelOrder}
                        disabled={false}
                        aria-label="whatever 116"
                        style={{backgroundColor: '#78cbff', color:"white"}}
                    >
                        {translate(props.language, 'Cancel Order')}
                    </Button>        

              </Grid>


              <Grid item xs={3} >
                <p></p>
                <Button
                    variant="contained"
                    // color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleClearAll}
                    disabled={false}
                    aria-label="whatever 1"
                    style={{backgroundColor: '#78cbff', color:"white"}}
                >
                    {translate(props.language, 'Clear All')}
                </Button>        

                <p></p>
              </Grid>



              <Grid item xs={3} >
                <p></p>
                <Button
                    variant="contained"
                    // color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleSaveOrder}
                    disabled={false}
                    aria-label="whatever 1"
                    style={{backgroundColor: '#78cbff', color: "white"}}
                >
                    {translate(props.language, 'Save')}
                </Button>        

              </Grid>


              <Grid item xs={3} ></Grid>
          </Grid>

          <div><p></p></div>

          {/* {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null} */}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}



