import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { GridOverlay, DataGrid,  GridToolbarContainer,
  GridToolbarExport,
  gridClasses } from '@mui/x-data-grid';

import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCGetMenuDB from './YPCGetMenuDB';
import YPCPutAxios from './YPCPutAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import YPCGetAxios from './YPCGetAxios';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCDeleteAxios from './YPCDeleteAxios';
import YPCDeleteAxiosAlt from './YPCDeleteAxiosAlt';

import XTranslate from "./YPCXTranslate";
import translate from "./YPCTranslate";

import moment from 'moment';
import tz from 'moment';

// import { duration } from 'moment';
// import { set } from 'date-fns';
// import { id, te } from 'date-fns/locale';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});


const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "100%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.grey': {
        backgroundColor: 'rgba(200, 200, 200, 0.49)',
      },
      '& .super-app.cooking': {
        backgroundColor: 'rgba(255, 0, 0, 0.25)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
    gridoverlay: {
      flexDirection: 'column'
    },
    label: {
      marginTop: theme.spacing(1),
    },

}));

const categoryList = ['Starter', 'Main', 'Side', 'Fruit', 'Drink', 'Dessert']


const sendToList = ['None', 'Cooker', 'Oven', 'Scale', 'Robotic Arm', 'Liquid Dispenser', 'Human'];


export default function YPCGetAllRecipes(props) {

  const classes = useStyles();
  // const theme = useTheme();
  const [recipeLoaded, setRecipeLoaded] = React.useState(false);
  
  const [menuDB, setMenuDB] = React.useState([]);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);


  const [portion, setPortion] = React.useState(1);
  const [recipeNameEng, setRecipeNameEng] = React.useState("");
  const [recipeNameFr, setRecipeNameFr] = React.useState("");
  const [category, setCategory] = React.useState("Starter");
  const [subCategory, setSubCategory] = React.useState("Soup");
  const [cuisine, setCuisine] = React.useState("World");
  const [spiceLevel, setSpiceLevel] = React.useState(0);
  const [vegetarian, setVegetarian] = React.useState('No');
  const [vegan, setVegan] = React.useState('No');
  const [lunchbox, setLunchbox] = React.useState('Yes');
  const [totalCost, setTotalCost] = React.useState(0.0);
  const [glutenFree, setGlutenFree] = React.useState('No');
  const [percentAutomation, setPercentAutomation] = React.useState(0);
  const [totalCookingTime, setTotalCookingTime] = React.useState(0.0);
  const [totalGrams, setTotalGrams] = React.useState(0.0);
  const [shelfLife, setShelfLife] = React.useState(0.0);
  const [stepsList, setStepsList] = React.useState([]);
  const [selectStep, setSelectStep] = React.useState("");
  const [selectedSidesValue, setSelectedSidesValue] = React.useState(null);
  const [selectSidesDelete, setSelectSidesDelete] = React.useState("");
  const [sidesOrderList, setSidesOrderList] = React.useState([]);
  const [lastStepType, setLastStepType] = React.useState(null);
  const [babyFriendly, setBabyFriendly] = React.useState('No');
  const [peanutFree, setPeanutFree] = React.useState('No');
  const [robotTested, setRobotTested] = React.useState(false);
  const [humanTested, setHumanTested] = React.useState(false);
  const [supportOffline, setSupportOffline] = React.useState(false);
  const [reviewStars, setReviewStars] = React.useState(0);
  const [selectedEditRecipe, setSelectedEditRecipe] = React.useState("");
  const [doubleCheckOpen, setDoubleCheckOpen] = React.useState(false);
  const [deleteCheckOpen, setDeleteCheckOpen] = React.useState(false);

  const [recipeList, setRecipeList] = React.useState([]);
  const [recipeId, setRecipeId] = React.useState('');

  const [ingredientUnitSize, setIngredientUnitSize] = React.useState({'': 1});
  const [ingredientUnitPrice, setIngredientUnitPrice] = React.useState({'' : 0.0});


  const [allRobotHumanChoice, setAllRobotHumanChoice] = React.useState('All');
  const [tagString, setTagString] = React.useState('');

  const [printBuffer, setPrintBuffer] = React.useState([]);

  const [createdTime, setCreatedTime] = React.useState("");
  const [updatedTime, setUpdatedTime] = React.useState("")

  React.useEffect(() => {
    if (!recipeLoaded) {
      getRecipeList();
    }
  }, [recipeLoaded]);


  React.useEffect(() => {
    getRecipes();
  }, [recipeList]);

//   React.useEffect(() => {
//     console.log('PPPPPPPPPPPPPPPP', printBuffer);
//   }, [printBuffer]);

  async function getRecipeList() {
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesURL', '');   // not used
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesWithTagsURL', tagString);
    // var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', tagString);
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', '');
    setRecipeList(result.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }));
    setRecipeLoaded(true);
  }


  
  const printColumns = [
    {
      field: 'name',
      headerName: 'Recipe',
      width: 400,
      type: 'string',
      sortable: true,
      editable: false
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 250,
      type: 'string',
      sortable: true,
      editable: false,
      
    },
    {
      field: 'subcategory',
      headerName: 'Sub-Category',
      width: 250,
      type: 'string',
      sortable: true,
      editable: false,
    },
    {
      field: 'human_tested',
      headerName: 'Human Tested',
      width: 200,
      type: 'boolean',
      sortable: true,
      editable: false,
    },
    {
      field: 'robot_tested',
      headerName: 'Robot Tested',
      width: 200,
      type: 'boolean',
      sortable: true,
      editable: false,
    },
    {
      field: 'portion',
      headerName: 'Portions',
      width: 200,
      type: 'string',
      sortable: true,
      editable: false,
    },
  ]




  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }






  const convertBoolToEng = (value) => {
    if (value === null) {
      return 'No';
    } else if (value === false) {
      return 'No';
    } else if (value === true) {
      return 'Yes';
    }

  };


  const checkCategory = (value) => {
    if (categoryList.includes(value)) {
      return value;
    } else {
      return 'Starter';
    }

  };

  
  async function getRecipesTwo() {
    let temp = []
    for (let i = 0; i < recipeList.length; i++) {
        let id = uuidv4();
        let recipe = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeNullURL', recipeList[i]);
        console.log('WWWWWW', recipe['name'], recipe['category']);
        if (recipe) {
            temp.push({
                id: id,
                name: recipe['name'],
                category: recipe['category'],
                subcategory: recipe['subcategory'],
                robot_tested: recipe['robot_tested'],
                human_tested: recipe['human_tested'],
                portion: recipe['portion']
            });
        }
        if (i === recipeList.length-1) {
            console.log('KKKKKKKKKKKKKKKKKKKKKKKK', temp)
        }
    };
    console.log('AAAAAAAA', temp)
    setPrintBuffer(temp);
  };


  async function getRecipes() {
    // let recTest = [];
    const temp = await Promise.all(recipeList.map(async ( value ) => {
        let id = uuidv4();
        var recipe = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeNullURL', value);
        if (recipe) {
            // console.log('GGGGGGGGGood');
            // recTest.push(recipe);
            // console.log('MMMMMMMM', recTest)
        // };
        // console.log('AAAAAAAA', recipe)

            return {
            id: id,
            name: recipe['name'],
            category: recipe['category'],
            subcategory: recipe['subcategory'],
            robot_tested: recipe['robot_tested'],
            human_tested: recipe['human_tested'],
            portion: recipe['portion']
            };
        };
      }));    
    setPrintBuffer(temp);
  };



// gen dialog

const handleGenDialog = () => {
  setXPost(null);
  setXGet(null);
}

  function genDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {xpost}{xget}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };



  function printTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          className={classes.gridcell}
        //   hideFooter={true}
        //   hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={printBuffer}
          columns={printColumns}
          // onCellEditCommit={handleCellEditCommit}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStepsSelect}
          // headerHeight={0}
          autoHeight={true}
          // selectionModel={selectStep}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay className={classes.gridoverlay}>
                <div className={classes.label}>
                </div>
              </GridOverlay>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };

    return (

          <React.Fragment>

          <Container className={classes.container}  style={{ marginTop: "2em" }}>
          <MuiThemeProvider theme={theme}>

            <Grid container direction="row" alignItems="stretch" justify="left" spacing={2}>
              <Grid item xs={12}>



                  <div className={classes.sendleft}>

                      {xpost !== null || xget !== null ? genDialog() : null}
                      <Typography component="h4" variant="h4">
                          {translate(props.language, 'List All Recipes')}
                      </Typography>
                  </div>


                  <div style={{clear: "both"}}></div>
              </Grid>






              <div><p></p></div>

              {/* {xget !== null ? 
                xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
              : null} */}


              {/* {xpost !== null ? 
                  xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
                : null}

              {xpost !== null ? 
                  xpost[0] === 'O' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
                : null} */}


            <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Print All Recipes')} &#8198;</div>
              </Grid>
                <Grid item xs={12} >
                      {(printBuffer.length === 0) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : printTable()}
                </Grid>      

            </Grid>
          </MuiThemeProvider>
          </Container>

          </ React.Fragment>



    );



}



