import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { GridOverlay, DataGrid,  GridToolbarContainer,
  GridToolbarExport,
  gridClasses } from '@mui/x-data-grid';

import Rating from '@mui/material/Rating';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCGetMenuDB from './YPCGetMenuDB';
import YPCPutAxios from './YPCPutAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import YPCGetAxios from './YPCGetAxios';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCDeleteAxios from './YPCDeleteAxios';
import YPCDeleteAxiosAlt from './YPCDeleteAxiosAlt';

import XTranslate from "./YPCXTranslate";
import translate from "./YPCTranslate";

import moment from 'moment';
import tz from 'moment';

// import { duration } from 'moment';
// import { set } from 'date-fns';
// import { id, te } from 'date-fns/locale';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});


const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "100%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.grey': {
        backgroundColor: 'rgba(200, 200, 200, 0.49)',
      },
      '& .super-app.cooking': {
        backgroundColor: 'rgba(255, 0, 0, 0.25)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
    gridoverlay: {
      flexDirection: 'column'
    },
    label: {
      marginTop: theme.spacing(1),
    },

}));

const categoryList = ['Starter', 'Main', 'Side', 'Fruit', 'Drink', 'Dessert']


const sendToList = ['None', 'Cooker', 'Oven', 'Scale', 'Robotic Arm', 'Liquid Dispenser', 'Human'];


export default function YPCGetAllSurveys(props) {

  const classes = useStyles();
  // const theme = useTheme();
  const [surveyLoaded, setSurveyLoaded] = React.useState(false);
  
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [surveyList, setSurveyList] = React.useState([]);
  const [totalSurveys, setTotalSurveys] = React.useState('0');
  const [surveysSent, setSurveysSent] = React.useState('0');

  const [printBuffer, setPrintBuffer] = React.useState([]);
  const [recipeList, setRecipeList] = React.useState([]);

  const [selectedEditRecipe, setSelectedEditRecipe] = React.useState("");

  const [resultsList, setResultsList] = React.useState([]);

  React.useEffect(() => {
    if (!surveyLoaded) {
      getSurveyList();
    }
  }, [surveyLoaded]);


//   React.useEffect(() => {
//     getSurveys();
//   }, [surveyList]);

//   React.useEffect(() => {
//     console.log('PPPPPPPPPPPPPPPP', printBuffer);
//   }, [printBuffer]);

  async function getSurveyList() {
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesURL', '');   // not used
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesWithTagsURL', tagString);
    // var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', tagString);
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getAllSurveysURL', '');

    setSurveyList(result.survey_list);
    setTotalSurveys(result.total_surveys);
    setSurveysSent(result.surveys_sent);

    setRecipeList(result.recipe_list);
    setResultsList(result.results);
    // setSurveyList(result.sort(function (a, b) {
    //   return a.toLowerCase().localeCompare(b.toLowerCase());
    // }));
    console.log('XQXQXQXQXQXQXQXQX', result);
    setSurveyLoaded(true);
  }


  
  const printColumns = [
    {
        field: 'username',
        headerName: 'User ID',
        width: 150,
        type: 'string',
        sortable: true,
        editable: false
      },
      {
        field: 'datestring',
        headerName: 'Date',
        width: 150,
        type: 'string',
        sortable: true,
        editable: false
      },
      {
        field: 'surveytime',
        headerName: 'Lunch/Dinner',
        width: 100,
        type: 'string',
        sortable: true,
        editable: false
      },
      {
        field: 'question',
        headerName: 'Type',
        width: 150,
        type: 'string',
        sortable: true,
        editable: false
      },      
    {
        field: 'category',
        headerName: 'Category',
        width: 150,
        type: 'string',
        sortable: true,
        editable: false
      },
      {
        field: 'name',
        headerName: 'Recipe',
        width: 400,
        type: 'string',
        sortable: true,
        editable: false
      },
      {
        field: 'value',
        headerName: 'Response',
        width: 400,
        type: 'string',
        sortable: true,
        editable: false
      },      
  ]


  const statsColumns = [
    {
        field: 'recipe',
        headerName: 'Recipe',
        width: 400,
        type: 'string',
        sortable: true,
        editable: false
      },
      {
        field: 'category',
        headerName: 'Category',
        width: 200,
        // type: 'string',
        sortable: true,
        editable: false
      },
      {
        field: 'count',
        headerName: 'Responses',
        width: 150,
        // type: 'string',
        sortable: true,
        editable: false
      },      
      {
        field: 'average',
        headerName: 'Rating',
        width: 150,
        // type: 'string',
        sortable: true,
        editable: false
      },
      {
        field: 'actions',
        headerName: 'Stars',
        width: 200,
        renderCell: (params) => {
            return (
                <Rating 
                  readOnly
                  name="star-rating" 
                  value={params.row.average} 
                  // max={10}
                  precision={0.5}
                  // onChange={(event, value) => setReviewStars(value * 2)}
                  // disabled={robotTested && humanTested}
              />
            )
        },
      },




  ]



  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }




// gen dialog

const handleGenDialog = () => {
  setXPost(null);
  setXGet(null);
}

  function genDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {xpost}{xget}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };

  function statsTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          className={classes.gridcell}
        //   hideFooter={true}
        //   hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={resultsList}
          columns={statsColumns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
          // onCellEditCommit={handleCellEditCommit}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStepsSelect}
          // headerHeight={0}
          autoHeight={true}
          // selectionModel={selectStep}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay className={classes.gridoverlay}>
                <div className={classes.label}>
                </div>
              </GridOverlay>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };


  function printTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          className={classes.gridcell}
        //   hideFooter={true}
        //   hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={surveyList}
          columns={printColumns}
          // onCellEditCommit={handleCellEditCommit}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStepsSelect}
          // headerHeight={0}
          autoHeight={true}
          // selectionModel={selectStep}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay className={classes.gridoverlay}>
                <div className={classes.label}>
                </div>
              </GridOverlay>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };

    return (

          <React.Fragment>

          <Container className={classes.container}  style={{ marginTop: "2em" }}>
          <MuiThemeProvider theme={theme}>

            <Grid container direction="row" alignItems="stretch" justify="left" spacing={2}>
              <Grid item xs={12}>



                  <div className={classes.sendleft}>

                      {xpost !== null || xget !== null ? genDialog() : null}
                      <Typography component="h4" variant="h4">
                          List Survey Data
                      </Typography>
                  </div>


                  <div style={{clear: "both"}}></div>
              </Grid>






              <div><p></p></div>



              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Survey Statistics'} &#8198;</div>
                            <p><small>Rating: 1 - Very Bad, 2 - Not Good, 4 - Good, 5 - Very Good.  Scale of 1 to 5</small></p>
              </Grid>

              <Grid item xs={12} >
                      {(resultsList.length === 0) ? <Skeleton variant="rectangular" width={"100%"} height={200} /> : statsTable()}
              </Grid>

              {/* <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Autocomplete
                                onChange={(event, value) => setSelectedEditRecipe(value)}
                                value = {selectedEditRecipe}
                                id="combo-box11"
                                // options={getEditRecipeList()}
                                options={recipeList}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"

                                />} 
                            />
                        </Grid>
                    </Grid>
              </Grid> */}





              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Print Survey Results'} &#8198;</div>
                            <p><small>VB - Very Bad, NG - Not Good, G - Good, VG - Very Good.  Recommend range 1-10</small></p>
              </Grid>
              <Grid item xs={12} >
                  <Typography>Surveys Sent - {surveysSent}</Typography>
                </Grid>
                <Grid item xs={12} >
                  <Typography>Surveys Answered - {totalSurveys}</Typography>
                </Grid>
                <Grid item xs={12} >
                      {(surveyList.length === 0) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : printTable()}
                </Grid>

            </Grid>
          </MuiThemeProvider>
          </Container>

          </ React.Fragment>



    );



}



