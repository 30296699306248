import { useState, useEffect } from 'react'
import { useROS } from 'react-ros'
import ROSLIB from 'roslib';


var ros = new ROSLIB.Ros();

function SendToMotionPy(message) {

    ros.connect('ws://localhost:9090');

    if (message === 'schedule_mep_command') {
        var cmdMEPMsg = new ROSLIB.Topic({
            ros : ros,
            name : '/enter_schedule_mep_command',
            messageType : 'std_msgs/String'
          });
        var mepMSG = new ROSLIB.Message({data: ''});

    } else if (message === 'mep_start_cooking_command') {
        var cmdMEPMsg = new ROSLIB.Topic({
            ros : ros,
            name : '/enter_start_mep_cooking_command',
            messageType : 'std_msgs/String'
          });
        var mepMSG = new ROSLIB.Message({data: ''});

    } else if (message === 'schedule_peak_command') {
          var cmdMEPMsg = new ROSLIB.Topic({
              ros : ros,
              name : '/enter_schedule_peak_time_cooking_command',
              messageType : 'std_msgs/String'
            });
          var mepMSG = new ROSLIB.Message({data: ''});

    } else if (message === 'peak_start_cooking_command') {
        var cmdMEPMsg = new ROSLIB.Topic({
            ros : ros,
            name : '/enter_start_peak_cooking_command',
            messageType : 'std_msgs/String'
          });
        var mepMSG = new ROSLIB.Message({data: ''});
    } else if (message === 'connect_command') {
      var cmdMEPMsg = new ROSLIB.Topic({
          ros : ros,
          name : '/connect_command',
          messageType : 'std_msgs/String'
        });
      var mepMSG = new ROSLIB.Message({data: ''});
  }
  

    cmdMEPMsg.publish(mepMSG);


    return (
      null
    );
  }
export default SendToMotionPy;