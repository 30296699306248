import axios from "axios";
// import React from "react";
// import jsonData from './shelf.json';

import config from './YPCconfig.js';

export default async function YPCDeleteAxiosAlt(xget, setXGet, url, id) {

    let baseURL = '';

    if (url === 'deleteRecipeURL') {
        baseURL = config.deleteRecipeURL+ id;
    };
    if (url === 'delProxydeleteRecipeURL') {
        baseURL = config.delProxydeleteRecipeURL+ id;
    };
    if (url === 'deleteIngredientURL') {
        baseURL = config.deleteIngredientURL+ id;
    };
    if (url === 'delProxydeleteIngredientURL') {
        baseURL = config.delProxydeleteIngredientURL+ id;
    };
    if (url === 'delProxydeleteAllDishOrdersURL') {
        baseURL = config.delProxydeleteAllDishOrdersURL;
    };
    if (url === 'delProxyemptyShelfURL') {
        baseURL = config.delProxyemptyShelfURL + id;
    };


    return new Promise(function (resolve, reject) {

        let token = localStorage.getItem('token');
        let userName = localStorage.getItem('userName')

         axios
            // .put(baseURL, {'token': token, 'userName': userName})
            .put(baseURL, {'token': token, 'username': userName})
            // .get(baseURL, {headers: {'Authorization': `Token ${token}`}})
            .then((response) => {
                console.log('DGGGGGGGGGGGGGet', response.data.data);
                    if (response.status === 200) {
                        // setXGet(JSON.parse(response.data)   );                    
                        // setXGet(JSON.stringify(response.data, null, 2));
                        // setXGet(response.data);
                        setXGet(null);
                    }
                    else {
                        setXGet('Error.  Fix and reload!');
                    }
                var result = response.data;
                resolve(result);

            }).catch(error => {
                console.log('EEEEEEEEE',error, Object.keys(error));

                if ('response' in error) {
                    // console.log('FFFFFFFFFFF', error.response);
                    if (error.response === undefined) {
                        setXGet('Error.  Contact YPC Help Line\n');
                    } else if ('data' in error.response) {
                        if ('detail' in error.response.data) {
                            setXGet('Error loading from DB!\n ' + error.response.data.detail);
                        } else {
                            setXGet('Error.  Fix and reload! \n');
                        }
                    } else {
                        setXGet('Error.  Fix and reload! \n');
                    }
                } else if (baseURL === config.delProxydeleteAllDishOrdersURL) { 
                    console.log('Incorrect response but it got deleted ok');
                    setXGet(null);
                }
                else {
                    setXGet('Error.  Fix and reload! \n');
                };

                reject(error);
            });

        });





}

