import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';
import './altstyles.css';

import jsonData from './shelf.json';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { SquarePaymentsForm, CreditCardInput } from "react-square-web-payments-sdk";
import * as Square from '@square/web-sdk';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import DoneIcon from '@mui/icons-material/Done';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';


import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepContent from '@mui/material/StepContent';
import Skeleton from '@mui/material/Skeleton';


import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCDeleteAxiosAlt from './YPCDeleteAxiosAlt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));
  
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    // console.log('HQHQHHQQHQH', active, completed, className, props);
  
    const icons = {
      1: <SettingsIcon />,
      2: <GroupAddIcon />,
      3: <GroupAddIcon />,
      4: <Grid4x4Icon />,
      5: <Grid4x4Icon />,
      // 3: <VideoLabelIcon />,
      6: <ShareIcon />,
    };

    
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>

        {completed ? <DoneIcon /> : icons[String(props.icon)]}

        {/* {icons[String(props.icon)]} */}

      </ColorlibStepIconRoot>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  
  // const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad', 'Newer Thing'];
  
  const steps = [
    {
      label: 'Step 1 - Fetch Orders',
      description: `Choose order range dates and hit continue to fetch orders.`,
      heading: 'Order Date Range Select',
      subheading: 'Date & Time'
    },
    {
      label: 'Step 2 - Raw Prep 1',
      description: `Prepare raw ingredients for shelf 1 and hit to go to next step`,
      heading: 'Ingredient Prep Shelf 1',
      subheading: 'Containers',
    },
    {
      label: 'Step 3 - Raw Prep 2',
      description: `Prepare raw ingredients for shelf 1 and hit to go to next step`,
      heading: 'Ingredient Prep Shelf 0',
      subheading: 'Containers',
    },
    {
      label: 'Step 4 - Prep Shelf 1',
      description: `Human stock ingredients for shelf 1 and hit to go to next step`,
      heading: 'Human Shelf 1 Prep',
      subheading: 'Shelf 1 Containers',
    },
    {
      label: 'Step 5 - Prep Shelf 0',
      description: `Human stock ingredients for shelf 0 and hit to go to next step`,
      heading: 'Human Shelf 0 Prep',
      subheading: 'Shelf 0 Containers',
    },

    {
      label: 'Ending',
      description: `Ending.`,
      heading: 'Next one !!!!',
      subheading: 'Ending'
    },
  
  ];


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3cb8b1',
    },      
    secondary: {
      main: '#3cb8b1'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

// const shelfType = 'JSON_FILE';
const shelfType = 'JSON_FILE_AXIOS';

const initSelectedEndTime = () => {
  let test = moment((new Date()));
  test.set('minute', 59);
  test.set('hour', 23);
  return test;
};

const initSelectedStartTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 0);
  return test2;
};

const startTimeList = ['11:30', '12:00', '12:30', '13:00', '13:30','17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];
const endTimeList = ['11:30', '12:00', '12:30', '13:00', '13:30','17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];


export default function YPCTest(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [ingredientsLoaded, setIngredientsLoaded] = React.useState(false);

  const [selectedTimeStart, setSelectedTimeStart] = React.useState(initSelectedStartTime());
  const [selectedTimeEnd, setSelectedTimeEnd] = React.useState(initSelectedEndTime());

  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [locale, setLocale] = React.useState()

  const [ingredientList, setIngredientList] = React.useState([]);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));

  const [activeStep, setActiveStep] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const [humanObject, setHumanObject] = React.useState(null);

  const [containerContents, setContainerContents] = React.useState(null);
  const [containerState, setContainerState] = React.useState(null);
  const [containerPointer, setContainerPointer] = React.useState(null);
  const [selectedContent, setSelectedContent] = React.useState(null);

  const [mousePress, setMousePress] = React.useState(false);
  const [mousePressMessage, setMousePressMessage] = React.useState(null);

  const [loaded, setLoaded] = React.useState(false);

  const [shelfSizes, setShelfSizes] = React.useState({0:{row: 3, columns: 9}, 4:{row: 1, columns: 6}, 1:{row: 4, columns: 6}, 
    2:{row: 4, columns: 6}, 5: {row: 6, columns:3}, 6: {row: 4, columns:5 }});

  const frontView = {0:true, 1: false}

  const [paymentAmount, setPaymentAmount] = React.useState(3.33);
  const [payButtonDisable, setPayButtonDisable] = React.useState(false);

  React.useEffect(() => {
    if (!loaded) {
      start();
      setLoaded(true);
    }
  }, [loaded]);


  const handleSendEmail = () => {
    let general = {whatever : 'This is a test'};

    YPCPutAxios2Alt(setXPost, 'sendXKitchinEmailURL', general, '');
  }

  const handleCreateUser = () => {
    let general = {username : 'Abraman', password: 'asdfghjkl++', email: 'test@whatever.com', first_name: 'Johnny', last_name: 'Campbell'};

    YPCPutAxios2Alt(setXPost, 'createUserURL', general, '');
  }


  const handleNextStep = () => {
    if (activeStep === 0) {
      console.log('GAGAGA', selectedDate.format("YYYY-MM-DDTHH:mm"), selectedTimeStart.format("HH:mm"), selectedTimeEnd.format("HH:mm"));
      YPCGetAxiosAlt(xget, setXGet, 'getProxyFetchDishOrdersURL', '&fetch_start_time=' + selectedDate.format("YYYY-MM-DDT") + 
              selectedTimeStart.format("HH:mm") + '&fetch_end_time=' + selectedDate.format("YYYY-MM-DDT") +  selectedTimeEnd.format("HH:mm"));
      getShelfIngredients('1');
    } else if (activeStep === 1) {
      getShelfIngredients('0');
    } else if (activeStep === 2) {
      getHumanShelfIngredients('1')
    } else if (activeStep === 3) {
      getHumanShelfIngredients('0');
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleDeleteDishOrders = () => {
    YPCDeleteAxiosAlt(xget, setXGet, 'delProxydeleteAllDishOrdersURL', '');
  };


  const handleHumanLoadNext = () => {
      let shelfID = 1;
      let tempContainerContents = containerContents;
      let tempContainerState = JSON.parse(JSON.stringify(containerState));


      let rows = tempContainerContents.length;
      let columns = tempContainerContents[0].length;
      let depth = tempContainerContents[0][0].length;

      let pointerMatch = false;
      let breakLoop = false;
      let finalIndices = null;
      let tempContainerPointer = null;

      // do the save to database here!


      //

      if (frontView[shelfID]) {
        for(let r=0; r < rows; r++ ) {
          if(breakLoop) break;
          for(let c = 0; c<columns; c++ ) {
            if(breakLoop) break;
            for(let d=depth-1; d >= 0; d--) {
              finalIndices = [r,c,d];
              if (pointerMatch) {
                if (containerState[r][c][d] !== null) {
                  // this.state.previousContainerPointer = this.state.autoContainerPointer.slice();
                  tempContainerPointer = [r,c,d];
                  breakLoop = true;
                  break;
                }
              }
              else if (containerPointer[0] === r && containerPointer[1] === c && containerPointer[2] === d) {
                pointerMatch = true;
              }
            }
          }
        }
      } else {
        for(let r=0; r < rows; r++ ) {
          if(breakLoop) break;
          for(let c = 0; c<columns; c++ ) {
            if(breakLoop) break;
            for(let d=0; d < depth; d++) {
              finalIndices = [r,c,d];
              if (pointerMatch) {
                if (containerState[r][c][d] !== null) {
                  // this.state.previousContainerPointer = this.state.autoContainerPointer.slice();
                  tempContainerPointer = [r,c,d];
                  breakLoop = true;
                  break;
                }
              }
              else if (containerPointer[0] === r && containerPointer[1] === c && containerPointer[2] === d) {
                pointerMatch = true;
              }
            }
          }
        }
      }


      // following is back
      if (!frontView[shelfID] && finalIndices[0] === rows-1 && finalIndices[1] === columns-1 && finalIndices[2] === depth-1) {
        // console.log('XAXAXAXAXAXAXAXAXAXA Final Indices is True BackView', containerPointer, tempContainerPointer);
        tempContainerState[containerPointer[0]][containerPointer[1]][containerPointer[2]] = 's';
        // console.log('CLCLCLCLCLCLCL',tempContainerState[containerPointer[0]][containerPointer[1]][containerPointer[2]], tempContainerState);
        setContainerState(tempContainerState);

      }
      // following is front
      else if (frontView[shelfID] && finalIndices[0] === rows-1 && finalIndices[1] === columns-1 && finalIndices[2] === 0) {
        // console.log('XAXAXAXAXAXAXAXAXAXA Final Indices is True FrontView', containerPointer, tempContainerPointer);
        tempContainerState[containerPointer[0]][containerPointer[1]][containerPointer[2]] = 's';      
        // console.log('CLCLCLCLCLCLCL',tempContainerState[containerPointer[0]][containerPointer[1]][containerPointer[2]],tempContainerState);
        setContainerState(tempContainerState);
      }

      else {
        // console.log('XTXTXTXTXTXTXT', tempContainerPointer, finalIndices);

        tempContainerState[containerPointer[0]][containerPointer[1]][containerPointer[2]] = 's';      
        tempContainerState[tempContainerPointer[0]][tempContainerPointer[1]][tempContainerPointer[2]] = 'x';

        setSelectedContent(tempContainerContents[tempContainerPointer[0]][tempContainerPointer[1]][tempContainerPointer[2]]);
        setContainerPointer(tempContainerPointer)
        setContainerState(tempContainerState);
      }


  };

  async function getShelfIngredients(shelfId) {

    setLoading(true);
    let obj = {};
    let tempList = [];
    if (shelfType === 'JSON_FILE') {
        jsonData.forEach( (entry) => {
            if (parseInt(entry.pose.charAt(4)) === shelfId) {
                let row = parseInt(entry.pose.charAt(5))-1;
                let column = parseInt(entry.pose.charAt(6))-1;
                let depth = entry.depth_counter-1;
                obj[[row, column, depth]] = entry;
            }
        });
    } else if  (shelfType === 'JSON_FILE_AXIOS') {
        var shelfIngredients = await YPCGetAxiosAlt(xget, setXGet, 'getProxyShelfURL', shelfId);
        let id = 0;
        shelfIngredients.map((value) => {
            tempList.push({'name': value['ing_name'], 'container': value['container_type_info']['name'] , 'amount': value['remaining_quantity'], 'row': value['pose'][5], 'column': value['pose'][6], 'id': id});
            id = id + 1;
        });

        
    };

    setIngredientList(tempList)
    setIngredientsLoaded(true);
    setLoading(false);

  };


  async function getHumanShelfIngredients(shelfId) {

    setLoading(true);
    let obj = {};
    if (shelfType === 'JSON_FILE') {
        jsonData.forEach( (entry) => {
            if (parseInt(entry.pose.charAt(4)) === shelfId) {
                let row = parseInt(entry.pose.charAt(5))-1;
                let column = parseInt(entry.pose.charAt(6))-1;
                let depth = entry.depth_counter-1;
                obj[[row, column, depth]] = entry;
            }
        });
    } else if  (shelfType === 'JSON_FILE_AXIOS') {
        var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyShelfURL', shelfId);
        result.forEach( (entry) => {
          let row = parseInt(entry.pose.charAt(5))-1;
          let column = parseInt(entry.pose.charAt(6))-1;
          let depth = entry.depth_counter-1;
          obj[[row, column, depth]] = entry;
        });
       
    };

    setHumanObject(obj);

    if (obj) {
      let keyList = Object.keys(obj);
      let depth = 0;
      keyList.forEach((indexList) => {
        if (parseInt(indexList[4]) > depth) {
          depth = parseInt(indexList[4])
        };
      });
      depth = depth + 1;
      let rows = shelfSizes[parseInt(shelfId)].row;
      let columns = shelfSizes[parseInt(shelfId)].columns;

      const containerWidth = columns * 46 + columns * (depth-1) * 34;

      let tempContainerContents = new Array(rows).fill(null).map(arow => new Array(columns).fill(null).map(adepth => new Array(depth).fill(null)));
      let tempContainerState = new Array(rows).fill(null).map(arow => new Array(columns).fill(null).map(adepth => new Array(depth).fill(null)));

      keyList = Object.keys(obj);
      keyList.forEach((indexList) => {
        tempContainerContents[parseInt(indexList[0])][parseInt(indexList[2])][parseInt(indexList[4])] = obj[indexList];
        tempContainerState[parseInt(indexList[0])][parseInt(indexList[2])][parseInt(indexList[4])] = 'o';
      });

      // set container pointer and starting state
      let tempContainerPointer = null;
      let breakLoop = false;
      if(frontView[parseInt(shelfId)]) {
        // front facing shelf
        for(let r=0; r < rows; r++ ) {
          if(breakLoop) break;
          for(let c = 0; c<columns; c++ ) {
            if(breakLoop) break;
            // for(let d=0; d < depth; d++) {
            for(let d=depth-1; d >= 0; d--) {              
              if (tempContainerState[r][c][d] !== null) {
                tempContainerPointer = [r,c,d];
                breakLoop = true;
                break;
              }
            }
          }
        }
      } else {
        // back facing shelf
        for(let r=0; r < rows; r++ ) {
          if(breakLoop) break;
          for(let c = 0; c<columns; c++ ) {
            if(breakLoop) break;
            for(let d=0; d < depth; d++) {
              if (tempContainerState[r][c][d] !== null) {
                tempContainerPointer = [r,c,d];
                breakLoop = true;
                break;
              }
            }
          }
        }
      }

      tempContainerState[tempContainerPointer[0]][tempContainerPointer[1]][tempContainerPointer[2]] = 'x';

      setSelectedContent(tempContainerContents[tempContainerPointer[0]][tempContainerPointer[1]][tempContainerPointer[2]]);
      setContainerPointer(tempContainerPointer)
      setContainerContents(tempContainerContents);
      setContainerState(tempContainerState);

    }
    else {
      setSelectedContent(null);
      setContainerPointer(null);
      setContainerContents(null);
      setContainerState(null);
    };

    setLoading(false);
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  const ingredientColumns = [
    {
      field: 'name',
      headerName: 'Ingredient',
      type: 'string',
      width: 500,
      sortable: true,
      editable: false,
    },
    {
        field: 'container',
        headerName: 'Container',
        type: 'string',
        width: 200,
        sortable: true,
        editable: false,
      },
    {
      field: 'amount',
      headerName: 'Amount (g)',
      type: 'number',
      sortable: true,
      width: 160,
      editable: false,
    },
    {
        field: 'row',
        headerName: 'Row',
        type: 'string',
        sortable: true,
        width: 140,
        editable: false,
      },
      {
        field: 'column',
        headerName: 'Column',
        type: 'string',
        sortable: true,
        width: 140,
        editable: false,
      },

  ];


  const handleDateChange = (date) => {
    // console.log('GAGAGA', moment(date).format("YYYY-MM-DDTHH:mm"));
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };


  const handleStartTimeList = (value) => {
    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeStart(newTime);

  };

  const handleEndTimeList = (value) => {

    let newTime = new moment(selectedDate);

    if (value === null || value === '-') {
      newTime.set('hour', 0);
      newTime.set('minute', 0);
    } else {
      newTime.set('hour', value.split(":")[0])
      newTime.set('minute', value.split(":")[1])
    };

    setSelectedTimeEnd(newTime);

  };


  function IngredientTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          rows={ingredientList}
          columns={ingredientColumns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStarterSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };


// shelves stuff

function handleClick(i, j,k, auto) {
  console.log('OWOWOWOWOWOWO', i,j,k, auto);
  // setMousePressMessage(containerContents[i][j][k]);

}

function handleMouseDown(i, j, k) {
  setMousePress(true);
  setMousePressMessage(containerContents[i][j][k]);
}

function handleMouseUp(i, j, k) {
  setMousePress(false);
  setMousePressMessage(null);
}

function AltContainer(value, onclick, onmousedown, onmouseup,    r, c, d) {

  let classValue = null;
  if (value === null) {
    classValue = "noshow-container";
  } else if (value === 'x') {
    classValue = "black-container";
  } else if (value === 's') {
    classValue = "green-container";
  } else if (value === 'S') {
    classValue = "green-select";
  } else {
    classValue = "container";
  }

  if (onclick === null) {
    return (
        <button className={classValue} onClick={onclick} onMouseOver={onmousedown} onMouseOut={onmouseup}>
        </button>
    );
  } else {
    return (
        // <button className={classValue} onClick={onclick} onMouseDown={onmousedown} onMouseUp={onmouseup}>
        // </button>

        <button className={classValue} onClick={() => onclick(r,c,d,false)} onMouseOver={() => onmousedown(r,c,d)} onMouseOut={() => onmouseup(r,c,d)}>
        </button>

    );
    }


}


function NoContainer() {  
  return ( // get rid of below button
      <button className="empty-container"> 
        {/* <img src={props.image}></img> */}
      </button>
  );
}

function renderContainer(r, d, c, d1, ScontainerState) {
  if (d === d1 && ScontainerState[r][c][d] !== null) {
    return ( 
      // AltContainer(ScontainerState[r][c][d], handleClick(r, c, d, false), handleMouseDown, handleMouseUp,)
      AltContainer(ScontainerState[r][c][d], handleClick, handleMouseDown, handleMouseUp, r,c,d)
    );
  }
  else if (d === d1) {          
      return (
        AltContainer(null, null, null, null, null,   null, null)
      );
  } else {
      return ( 
        NoContainer()

      );
  }
}

function SmallContainer() {
  return (
    <button className="empty-container" style={{height: `30px`}}></button>
  )
}

function BiggerSmallContainer() {
  return (
    <button className="bigger-empty-container" style={{height: `30px`}}></button>
  )
}

function renderEmptyRow(cols, depth) {
  let testList = [];
  for(let c = 0;c<cols; c++) {
    testList.push(
      <div>
        <BiggerSmallContainer/>
      </div>
    );
    for(let d = 0;d<depth-1; d++) {
      testList.push(
        <div>
          <SmallContainer/>
        </div>
      );
    }
  }
  return testList
}

function renderRows(r, d, Scolumns, Sdepth, ScontainerState, viewPoint) {
  let testList=[];

  if (viewPoint) {
      for(let c = 0; c<Scolumns; c++) {
        for(let d1 = 0; d1<Sdepth; d1++) {
          testList.push(
            <div key={c*Scolumns+d1}>
                {renderContainer(r, d, c, d1, ScontainerState )}
            </div>
          );
        }
      }
  }
  else {
      for(let c = Scolumns-1; c>=0; c--) {
        for(let d1 = 0; d1<Sdepth; d1++) {
          testList.push(
            <div key={c*Scolumns+d1}>
                {renderContainer(r, d, c, d1,ScontainerState )}
            </div>
          );
        }
      }
  }
  return testList;
}

  function shelfFinal() {
    if (humanObject) {
    // if (containerState) {
      let shelfID = 1;
      let keyList = Object.keys(humanObject);
      let depth = 0;
      keyList.forEach((indexList) => {
        if (parseInt(indexList[4]) > depth) {
          depth = parseInt(indexList[4])
        };
      });
      depth = depth + 1;
      if (activeStep === 3) {
        shelfID = 1;
      } else {
        shelfID = 0;
      }
      let rows = shelfSizes[shelfID].row;
      let columns = shelfSizes[shelfID].columns;

      const containerWidth = columns * 46 + columns * (depth-1) * 34;

      let itemList=[];
      for(let r = 0; r<rows; r++) {
        for(let d=depth-1; d>=0; d--) {
              itemList.push(
                  <div key={r*depth + d} className="Shelf-row">
                      {renderRows(r, d, columns, depth, containerState, frontView[shelfID])}
                  </div>);
        }
        itemList.push(
          <div  className="Shelf-row">
            {renderEmptyRow(columns, depth)}
          </div>);
      }

      return (
        <React.Fragment>
            <div className="rack">
              <div className="rack-shelf" style={{width: `${containerWidth}px` }}>
                {itemList}
              </div>
            </div>
        </React.Fragment>
      )


    } else {
      return null;
    }

  };



  async function start() {
 

    const payments = await Square.payments('sandbox-sq0idb-7jfQNXPv5lSWXpH_apgysQ', 'L4W9Z7A1VRDD8');

    const card = await payments.card({
      style: {
        '.input-container.is-focus': {
          borderColor: '#3cb8b1'
        },
        '.message-text.is-error': {
          color: '#BF0020'
        }
      }
    });

    await card.attach('#card-container');

    document.querySelector('#card-button').addEventListener('click', async () => {
      setPayButtonDisable(true);
      try {

        const result = await card.tokenize();

        console.log(result);
        // TODO: use result.token as source_id in /v2/payments API call

        let sendData = {
          username: 'irv.lustigman', // should be props.userName ?
          result: result,
          payment_amount: parseInt(paymentAmount * 100)
        };


        let test = await YPCPutAxios2Alt(setXPost, 'createXPaymentURL', sendData, '');
        console.log('GGGGGGGGGGGG', test);
      } catch (ex) {
        console.error(ex);
      }
      setPayButtonDisable(false);
    });

    // return (

    //   <form class="payment-form" id="fast-checkout">
    //     <div class="wrapper">
    //       <div id="card-container"></div>
    //       <button id="card-button" type="button">
    //         Pay with Card
    //       </button>
    //       {/* <span id="payment-flow-message"></span> */}
    //     </div>
    //   </form>
    // )


  };

  // const myPaymentForm = () => {

  //     return (
  //           <SquarePaymentsForm

  //                 // formId="payment-form"
  //                 /**
  //                  * Identifies the calling form with a verified application ID
  //                  * generated from the Square Application Dashboard.
  //                  */
  //                 applicationId={"sandbox-sq0idb-7jfQNXPv5lSWXpH_apgysQ"}
  //                 /**
  //                  * Invoked when payment form receives the result of a tokenize generation request.
  //                  * The result will be a valid credit card or wallet token, or an error.
  //                  */
  //                 cardTokenizeResponseReceived={async (token, buyer) => {
  //                   // console.log({ token, buyer });
  //                   alert(JSON.stringify(token, null, 2));
  //                 }}

  //                 /**
  //                  * This function enable the Strong Customer Authentication (SCA) flow
  //                  *
  //                  * We strongly recommend use this function to verify the buyer and
  //                  * reduce the chance of fraudulent transactions.
  //                  */
  //                 // createVerificationDetails={() => ({
  //                 //   amount: '1.00',
  //                 //   /* collected from the buyer */
  //                 //   billingContact: {
  //                 //     addressLines: ['123 Main Street', 'Apartment 1'],
  //                 //     familyName: 'Doe',
  //                 //     givenName: 'John',
  //                 //     countryCode: 'CA',
  //                 //     city: 'Montreal',
  //                 //   },
  //                 //   currencyCode: 'CAD',
  //                 //   intent: 'CHARGE',
  //                 // })}

  //                 /**
  //                  * Identifies the location of the merchant that is taking the payment.
  //                  * Obtained from the Square Application Dashboard - Locations tab.
  //                  */
  //                 locationId={"L4W9Z7A1VRDD8"}
  //               >
  //                 <CreditCardInput />
  //           </SquarePaymentsForm>
  //     )
  // };


    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>

          <Grid item xs={12}>
              <Grid item xs={6}>
                
                  <div>

                      <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCreateUser}
                          disabled={false}
                          aria-label="select existing recipe"
                          style={{backgroundColor: '#3cb8b1', color: 'white'}}
                      >
                          Test Create User
                      </Button>                      

                  </div>


                {/* {myPaymentForm()} */}
                {/* {myPaymentFormTwo()} */}
                  <div id="card-container"></div>
                  {/* <button type="button" id="card-button">Pay ${paymentAmount}</button> */}
                
                  <div>

                    <Button
                        id="card-button"
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        // onClick={handlePayButton}
                        disabled={payButtonDisable}
                        aria-label="pay for it"
                        style={{backgroundColor: '#3cb8b1', color: 'white'}}
                    >
                        Pay ${paymentAmount}
                    </Button>                      

                  </div>


              </Grid>
              <Grid item xs={6}>

          </Grid>

        </Grid>


          <Grid item xs={12}>



              <div className={classes.sendleft}>

                  <Typography component="h4" variant="h4">
                      Cooking Steps
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>


          <Grid item xs={12}>
                        <div><p></p></div>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>


                        {steps.map((step) => (
                            <Step key={step.label}>
                              <StepLabel StepIconComponent={ColorlibStepIcon}>{step.label}</StepLabel>
                              {/* <StepContent>
                            </StepContent> */}
                            </Step>
                        ))}

                        </Stepper>
                    </Stack>
                    <div><p></p></div>
                    <Typography>{steps[activeStep].description}</Typography>
                    <div><p></p></div>
                    <div>

                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleSendEmail}
                                disabled={false}
                                aria-label="select existing recipe"
                                style={{backgroundColor: '#3cb8b1', color: 'white'}}
                            >
                                Send Email
                            </Button>                      

                        </div>

          </Grid>

          {/* Set Calendar */}
          { activeStep === 0 ? (
            <React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.sendleft}>
                        <Typography component="h5" variant="h5">
                            {steps[activeStep].heading}
                        </Typography>
                    </div>
                    <div style={{clear: "both"}}></div>
                </Grid>       
                <Grid item xs={12}>
                          <br />
                          <div style={{backgroundColor: '#3cb8b1', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{steps[activeStep].subheading} &#8198;</div>
                          <br />
                </Grid>
                <Grid item xs={12}>   
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                            <Grid item xs={12} sm={12} md={12} lg={12}> 
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                  <DatePicker
                                      orientation={"landscape"}
                                      variant="static"
                                      margin="normal"
                                      id="date-picker-dialog"
                                      label="Date picker dialog"
                                      // format="MM/dd/yyyy"
                                      format={"dddd, " + "MMMM" + ", yyyy"}
                                      value={selectedDate}
                                      onChange={handleDateChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                  />
                              {/* </LocalizationProvider> */}
                            </Grid>
                          </MuiThemeProvider>
                      </MuiPickersUtilsProvider>
                </Grid>


                <Grid container item xs={12} direction='column'> 
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                                <Grid item xs={2}> 
                                      <Autocomplete
                                          onChange={(event, value) => handleStartTimeList(value)}
                                          id="combo-box-start"
                                          value={selectedTimeStart.format("HH:mm")}
                                          options={startTimeList}
                                          // getOptionLabel={(option) => option.name}
                                          // style={{ width: 300 }}
                                          renderInput={(params) => <TextField {...params} label='Start' InputLabelProps={{
                                              shrink: true,
                                              style: {color: '#3cb8b1'},
                                          }}
                                          // variant="outlined"
                                          />} 
                                      />


                                      <p></p>

                                      <Autocomplete
                                            onChange={(event, value) => handleEndTimeList(value)}
                                            id="combo-box-end"
                                            value={selectedTimeEnd.format("HH:mm")}
                                            options={endTimeList}
                                            // getOptionLabel={(option) => option.name}
                                            // style={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label={translate(props.language, 'End')} InputLabelProps={{
                                                shrink: true,
                                                style: {color: '#3cb8b1'},
                                            }}
                                            // variant="outlined"
                                            />} 
                                        />
                                </Grid>
                                </MuiThemeProvider>
                            </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid container item xs={12} direction='column'> 
                            <div><p></p></div>
                            <Typography>Or Delete All Dish Orders -</Typography>
                            <p></p>
                        <div>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleDeleteDishOrders}
                                disabled={false}
                                aria-label="handle dish orders"
                                style={{backgroundColor: '#3cb8b1', color: 'white'}}
                            >
                                Delete
                            </Button>                      

                        </div>

                      </Grid>

            </React.Fragment>
        ) : null
      }


      {/* Prep Raw Ingredients */}
      { activeStep === 1 || activeStep === 2 ? (
          <React.Fragment>
            <Grid item xs={12}>
                <div className={classes.sendleft}>
                    <Typography component="h5" variant="h5">
                        {steps[activeStep].heading}
                    </Typography>
                </div>
                <div style={{clear: "both"}}></div>
            </Grid>          
            <Grid item xs={12}>
                          <br />
                          <div style={{backgroundColor: '#3cb8b1', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{steps[activeStep].subheading} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                  <MuiThemeProvider theme={theme}>
                      <Grid container spacing={3}>
                          <Grid item xs={12} >
                              {(loading) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : IngredientTable()}
                              {/* {IngredientTable()} */}
                          </Grid>
                      </Grid>
                  </MuiThemeProvider>
              </MuiPickersUtilsProvider>
            </Grid>
          </React.Fragment>
        ) : null
      }


      {/* Stock Shelf Human */}
      { containerState !== null && (activeStep === 3 || activeStep === 4) ? (        
          <React.Fragment>

            <Grid item xs={12}>
                <div className={classes.sendleft}>
                    <Typography component="h5" variant="h5">
                        {steps[activeStep].heading}
                    </Typography>
                </div>
                <div style={{clear: "both"}}></div>
            </Grid>          
            <Grid item xs={12}>
                          <br />
                          <div style={{backgroundColor: '#3cb8b1', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{steps[activeStep].subheading} &#8198;</div>
            </Grid>
            <Grid item xs={12}>
                          <br />
            <div><p></p></div>
              {selectedContent && !loading ? 
                (
                  <React.Fragment>
                      <Grid item xs={12}>
                        <Typography component="h6" variant="h6">
                          {selectedContent.ing_name + ', ' + selectedContent.remaining_quantity + ' grams, ' + selectedContent.container_type_info.name + ', row ' + selectedContent.pose[5] 
                          + ', column ' + selectedContent.pose[6] + ', depth ' + selectedContent.depth_counter}
                        </Typography>
                      </Grid>

                     
                      <Grid item xs={12}>
                          <br />
                          <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleHumanLoadNext}
                            disabled={false}
                            aria-label="shelf continue"
                            style={{backgroundColor: '#3cb8b1', color: 'white'}}
                          >
                          {'Next'}
                          </Button>                      
                      </Grid>
                  </React.Fragment>

                )
                : null
              }

              <br />
            </Grid>

            <div><p></p></div>
            {(loading) ? <Skeleton variant="rectangular" width={"100%"} height={400} /> : shelfFinal()}

            {mousePressMessage !== null && mousePress ? 
              <Grid item xs={12}>
                <Typography component="h6" variant="h6">
                    {mousePressMessage !== null ? 
                        mousePressMessage.ing_name + ', ' + mousePressMessage.remaining_quantity + ' grams, ' + mousePressMessage.container_type_info.name + ', row ' + mousePressMessage.pose[5] 
                        + ', column ' + mousePressMessage.pose[6] + ', depth ' + mousePressMessage.depth_counter
                     : ' '}

                </Typography>
              </Grid>
              : null
            }
          </React.Fragment>
        ) : null
      }


          <div><p></p></div>



          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


