import * as settings from '../settings';

// Remotely run from tomato
// const config = {
//     'recipeURL' :  "http://192.168.0.11:8000/all_recipes",
//     'shelfURL' : "http://192.168.0.11:8000/get_target_shelf?shelf_id=",
//     'dishorderURL' : "http://192.168.0.11:8000/place_dish_orders",
//     'addContainerURL' : "http://192.168.0.11:8000/add_new_container",
//     'fileUploadURL' :  "http://192.168.0.11/8000/file_order_upload"
// }


// const config = {
// 'recipeURL' :  "http://10.10.140.117:8000/all_recipes",
// 'shelfURL' : "http://10.10.140.117:8000/get_target_shelf?shelf_id=",
// 'dishorderURL' : "http://10.10.140.117:8000/place_dish_orders",
// 'addContainerURL' : "http://10.10.140.117:8000/add_new_container",
// 'fileUploadURL' :  "http://10.10.140.117/8000/file_order_upload"
// }

// const machine_ip = process.env.REACT_APP_IP;

const machine_ip = settings.API_SERVER  // ypc_concierge/frontend/react_app/src/settings.js

// Irv's local test stuff
const config = {
    'recipeURL':  "http://127.0.0.1:8080/all_recipes",      // all recipes - used
    'shelfURL' :  "http://127.0.0.1:8080/get_target_shelf/ing_based?shelf_id=",
    'dishorderURL' : "http://127.0.0.1:8080/place_dish_orders",
    'addContainerURL' : "http://127.0.0.1:8080/add_new_container",
    'fileUploadURL' :  "http://127.0.0.1:8080/file_order_upload",
    'conciergeRecipesURL' :  "http://127.0.0.1:8080/get_concierge_recipes",
    'conciergeSaveURL' : "http://127.0.0.1:8080/save_menu?date=",
    'conciergeMenuGetURL' : "http://127.0.0.1:8080/get_menu?date=",
    'conciergeSaveOrderURL' : "http://127.0.0.1:8080/save_order?date=",
    'conciergeOrderGetURL' : "http://127.0.0.1:8080/get_order?date=",
//     'dishorderURL' : "https://httpbin.org/post",
//     'addContainerURL' : "https://httpbin.org/post"


    // // this is older stuff with 
    // // accesses blue chef endpoints
    // 'allIngredientsFullURL':  "http://" + machine_ip + ":8000/all_ingredients/full_details",      // ingredients  -- done
    // 'allIngredientsURL':  "http://" + machine_ip + ":8000/all_ingredients",      // ingredients -- done
    // 'allRecipesURL': "http://" + machine_ip + ":8000/all_recipes",  // -- done but not used
    // 'getRecipeURL': "http://" + machine_ip + ":8000/recipe?silently_validate=false&skip_cooking_time=true&skip_recipe_code=true&recipe_name=",  // -- done
    // // 'allRecipesCategoryURL' : 'http://' + machine_ip + ':8000/all_recipes/by_category?category=Side',
    // 'allRecipesCategoryURL' : 'http://' + machine_ip + ':8000/all_recipes?category=Side',   // -- done
    // 'saveRecipeURL' : 'http://' + machine_ip + ':8000/recipe',                           // -- done
    // 'deleteRecipeURL' : 'http://' + machine_ip + ':8000/recipe?recipe_name=',    //  -- done
    // 'saveIngredientURL' : 'http://' + machine_ip + ':8000/ingredient',              //  -- done
    // 'deleteIngredientURL' : 'http://' + machine_ip + ':8000/ingredient?ingredient_name=',
    // 'getIngredientURL': "http://" + machine_ip + ":8000/ingredient?ingredient_name=",       // -- done
    // // menu creation
    // // 'allRecipesByCategoryURL' : 'http://' + machine_ip + ':8000/all_recipes/by_category?category=',
    // 'allRecipesByCategoryURL' : 'http://' + machine_ip + ':8000/all_recipes?category=',   // -- done but not used
    // 'allRecipesWithTagsURL' : 'http://' + machine_ip + ':8000/all_recipes',     // -- done

    // // django endpoints
    // 'saveMenuURL' : 'http://' + machine_ip + ':8088/api/save_menu',  // updated to AxiosAlt
    // 'getXMenuURL' : 'http://' + machine_ip + ':8088/api/xcreate_menu?datestring=',   // added this one (master vs. not master)    // updated to AxiosAlt
    // 'getMenuURL' : 'http://' + machine_ip + ':8088/api/create_menu?datestring=',       // updated to AxiosAlt
    // // 'testMenuURL' : 'http://' + machine_ip + ':8088/api/test_menu?datestring=2031',  // !!!!! Note that his hasn't been updated
    // 'saveXOrderURL' : 'http://' + machine_ip + ':8088/api/xsave_order',  // added this one (master vs. not master)  // updated to AxiosAlt
    // 'saveOrderURL' : 'http://' + machine_ip + ':8088/api/save_order',   // updated to AxiosAlt
    // 'getOrdersAllURL' : 'http://' + machine_ip + ':8088/api/create_order_all',      // updated to AxiosAlt
    // 'getXOrderURL' : 'http://' + machine_ip + ':8088/api/xcreate_order',    // added this one (master vs. not master) // updated to AxiosAlt
    // 'getOrderURL' : 'http://' + machine_ip + ':8088/api/create_order',      // updated to AxiosAlt
    // 'getDaysOrdersURL' : 'http://' + machine_ip + ':8088/api/days_orders?datestring=',      // updated to AxiosAlt
    // 'getUsernamesURL' : 'http://' + machine_ip + ':8088/api/auth/usernames',                // updated to AxiosAlt

    // // blue chef proxy endpoints
    // 'getProxyallIngredientsFullURL' : 'http://' + machine_ip + ':8088/api/getproxy/all_ingredients/full_details',   // updated to AxiosAlt
    // 'getProxyallIngredientsURL' : 'http://' + machine_ip + ':8088/api/getproxy/all_ingredients',   // updated to AxiosAlt
    // 'getProxyallRecipesURL' : 'http://' + machine_ip + ':8088/api/getproxy/all_recipes',   // updated to AxiosAlt  Not used !!!
    // 'getProxygetRecipeURL' : 'http://' + machine_ip + ':8088/api/getproxy/recipe?silently_validate=false&skip_cooking_time=true&skip_recipe_code=true&recipe_name=',  // updated to AxiosAlt
    // 'getProxyallRecipesCategoryURL' : 'http://' + machine_ip + ':8088/api/getproxy/all_recipes?category=Side',   // updated to AxiosAlt


    // 'putProxysaveRecipeURL' : 'http://' + machine_ip + ':8088/api/putproxy/recipe',    // updated to AxiosAlt
    // 'putProxysaveIngredientURL' : 'http://' + machine_ip + ':8088/api/putproxy/ingredient',       // updated to AxiosAlt
    // 'delProxydeleteRecipeURL' : 'http://' + machine_ip + ':8088/api/delproxy/recipe?recipe_name=',    // updated to AxiosAlt
    // 'delProxydeleteIngredientURL' : 'http://' + machine_ip + ':8088/api/delproxy/ingredient?ingredient_name=',     // updated to AxiosAlt
    // 'getProxygetIngredientURL': "http://" + machine_ip + ":8088/api/getproxy/ingredient?ingredient_name=",    // updated to AxiosAlt
    // 'getProxyallRecipesByCategoryURL' : 'http://' + machine_ip + ':8088/api/getproxy/all_recipes?category=',  // updated to AxiosAlt  Not used !!!
    // 'getProxyallRecipesWithTagsURL' : 'http://' + machine_ip + ':8088/api/getproxy/all_recipes',      // updated to AxiosAlt


    // accesses blue chef endpoints
    'allIngredientsFullURL':  machine_ip + ":8000/all_ingredients/full_details",      // ingredients  -- done
    'allIngredientsURL':  machine_ip + ":8000/all_ingredients",      // ingredients -- done
    'allRecipesURL': machine_ip + ":8000/all_recipes",  // -- done but not used
    'getRecipeURL': machine_ip + ":8000/recipe?silently_validate=false&skip_cooking_time=true&skip_recipe_code=true&recipe_name=",  // -- done
    // 'allRecipesCategoryURL' : machine_ip + ':8000/all_recipes/by_category?category=Side',
    'allRecipesCategoryURL' : machine_ip + ':8000/all_recipes?category=Side',   // -- done
    'saveRecipeURL' : machine_ip + ':8000/recipe',                           // -- done
    'deleteRecipeURL' : machine_ip + ':8000/recipe?recipe_name=',    //  -- done
    'saveIngredientURL' : machine_ip + ':8000/ingredient',              //  -- done
    'deleteIngredientURL' : machine_ip + ':8000/ingredient?ingredient_name=',
    'getIngredientURL': machine_ip + ":8000/ingredient?ingredient_name=",       // -- done
    // menu creation
    // 'allRecipesByCategoryURL' : machine_ip + ':8000/all_recipes/by_category?category=',
    'allRecipesByCategoryURL' : machine_ip + ':8000/all_recipes?category=',   // -- done but not used
    'allRecipesWithTagsURL' : machine_ip + ':8000/all_recipes',     // -- done

    // django endpoints
    'saveMenuURL' : machine_ip + ':8088/api/save_menu',  // updated to AxiosAlt
    'getXMenuURL' : machine_ip + ':8088/api/xcreate_menu?datestring=',   // added this one (master vs. not master)    // updated to AxiosAlt
    'getMenuURL' : machine_ip + ':8088/api/create_menu?datestring=',       // updated to AxiosAlt
    // 'testMenuURL' : machine_ip + ':8088/api/test_menu?datestring=2031',  // !!!!! Note that his hasn't been updated
    'saveXOrderURL' : machine_ip + ':8088/api/xsave_order',  // added this one (master vs. not master)  // updated to AxiosAlt
    'saveOrderURL' : machine_ip + ':8088/api/save_order',   // updated to AxiosAlt
    'getOrdersAllURL' : machine_ip + ':8088/api/create_order_all',      // updated to AxiosAlt
    'getWeeklyUnpaidOrdersURL' : machine_ip + ':8088/api/get_weekly_unpaid_orders',      // updated to AxiosAlt
    'getXOrderURL' : machine_ip + ':8088/api/xcreate_order',    // added this one (master vs. not master) // updated to AxiosAlt
    'getOrderURL' : machine_ip + ':8088/api/create_order',      // updated to AxiosAlt
    'getDaysOrdersURL' : machine_ip + ':8088/api/days_orders?datestring=',      // updated to AxiosAlt
    'getUsernamesURL' : machine_ip + ':8088/api/auth/usernames',                // updated to AxiosAlt

    'getXWeeklyCompanyOrdersURL' : machine_ip + ':8088/api/xget_weekly_company_orders',      // updated to AxiosAlt

    'getUserEmailNamesURL' : machine_ip + ':8088/api/auth/user_email_names',                // updated to AxiosAlt

    'createUserURL' : machine_ip + ':8088/api/auth/create_user',   // updated to AxiosAlt

    'getUsernamesIDsURL' : machine_ip + ':8088/api/auth/usernamesids',                // updated to AxiosAlt

    'getXSubsciptionTypeURL' : machine_ip + ':8088/api/xget_subscription',   // non master uses GetAxiosAlt

    'getSubsciptionTypeURL' : machine_ip + ':8088/api/get_subscription',   // master uses GetAxiosAlt

    'getXCompanyNameURL' : machine_ip + ':8088/api/xget_company_name',   // non master uses GetAxiosAlt
    'getCompanyNameURL' : machine_ip + ':8088/api/get_company_name',   // master uses GetAxiosAlt

    'savePortraitURL' : machine_ip + ':8088/api/save_portrait',   // master uses GetAxiosAlt
    'getPortraitURL' : machine_ip + ':8088/api/get_portrait',   // master uses GetAxiosAlt

    'getXSubsTypeCompHistoryURL' : machine_ip + ':8088/api/get_xsubstype_comp_history?datestring=',   // master uses GetAxiosAlt
    'getSubsTypeCompHistoryURL' : machine_ip + ':8088/api/get_substype_comp_history',   // master uses GetAxiosAlt

    'getXDateSubscriptionTypeURL' : machine_ip + ':8088/api/xget_date_subscription?datestring=',   // master uses GetAxiosAlt
    'getDateSubscriptionTypeURL' : machine_ip + ':8088/api/get_date_subscription',   // master uses GetAxiosAlt


    'getAllSubsTypeCompHistoriesURL' : machine_ip + ':8088/api/get_all_substype_comp_histories',   // master uses GetAxiosAlt

    'getAllDateSubscriptionTypeURL' : machine_ip + ':8088/api/get_all_date_subscription',   // master uses GetAxiosAlt

    'fileUploadURL' : machine_ip + ":8088/api/file_order_upload",  // upload URL - uses YPCPutAxios2Alt

    'getRecipeDictsURL' : machine_ip + ':8088/api/get_recipe_dicts',   // updated to AxiosAlt

    'sendXRecommendationEmailURL' : machine_ip + ':8088/api/xsend_recommendation_email',   // updated to AxiosAlt
    'sendXKitchinEmailURL' : machine_ip + ':8088/api/xsend_kitchin_email',   // updated to AxiosAlt
    'sendKitchinEmailURL' : machine_ip + ':8088/api/send_kitchin_email',   // updated to AxiosAlt
    'sendXKitchinEmailCancelURL' : machine_ip + ':8088/api/xsend_kitchin_email_cancel',   // updated to AxiosAlt

    'sendKitchinEmailCancelURL' : machine_ip + ':8088/api/send_kitchin_email_cancel',   // updated to AxiosAlt

    'sendXKitchinEmailPaidOrderURL' : machine_ip + ':8088/api/xsend_kitchin_email_paidorder',   // updated to AxiosAlt

    'sendXKitchinEmailResetPasswordURL' : machine_ip + ':8088/api/xsend_kitchin_email_reset_password',   // updated to AxiosAlt
    'putXChangeResetPasswordURL' : machine_ip + ':8088/api/auth/xreset_password',   // updated to AxiosAlt

    'createXPaymentURL' : machine_ip + ':8088/api/xcreate_payment',  // added this one (master vs. not master)  // updated to AxiosAlt
    'getXPaymentURL' : machine_ip + ':8088/api/xget_payment',    // added this one (master vs. not master) // updated to AxiosAlt

    'PayEditURL' : machine_ip + ':8088/api/pay_edit',    // added this one (master vs. not master) // updated to AxiosAlt
    'setPayAllURL' : machine_ip + ':8088/api/set_pay_all',    // added this one (master vs. not master) // updated to AxiosAlt

    'deleteXOrderURL' : machine_ip + ':8088/api/xdelete_order',  // added this one (master vs. not master)  // updated to AxiosAlt
    'deleteOrderURL' : machine_ip + ':8088/api/delete_order',  // added this one (master vs. not master)  // updated to AxiosAlt

    'deleteMenuURL' : machine_ip + ':8088/api/delete_menu',  // added this one (master vs. not master)  // updated to AxiosAlt

    'deleteFakeOrdersURL' : machine_ip + ':8088/api/delete_fake_orders',  // added this one (master vs. not master)  // updated to AxiosAlt

    'getXOrderSlotsURL' : machine_ip + ':8088/api/xorder_slots?datestring=',   // added this one (master vs. not master)    // updated to AxiosAlt
    'getXOrderSlotWeightsURL' : machine_ip + ':8088/api/xorder_slot_weights?datestring=',   // added this one (master vs. not master)    // updated to AxiosAlt

    'getXMenuOrderCapURL' : machine_ip + ':8088/api/xmenu_order_cap?datestring=',   // added this one (master vs. not master)    // updated to AxiosAlt

    'saveCompanyURL' : machine_ip + ':8088/api/company_save_company',  // updated to AxiosAlt
    'getCompaniesURL' : machine_ip + ':8088/api/company_get_companies',       // updated to AxiosAlt    

    'getAllUserDataURL' : machine_ip + ':8088/api/get_all_user_data',       // updated to AxiosAlt    

    'saveMiscBlockedURL' : machine_ip + ':8088/api/misc_save_blocked',  // updated to AxiosAlt

    'getMiscBlockedURL' : machine_ip + ':8088/api/misc_get_blocked',       // updated to AxiosAlt

    'getXMiscBlockedURL' : machine_ip + ':8088/api/xmisc_get_blocked',   // added this one (master vs. not master)    // updated to AxiosAlt

    'saveMiscCostPriceListsURL' : machine_ip + ':8088/api/misc_save_costpricelists',  // updated to AxiosAlt
    'getMiscCostPriceListsURL' : machine_ip + ':8088/api/misc_get_costpricelists',       // updated to AxiosAlt
    'deleteMiscCostPriceListsURL' : machine_ip + ':8088/api/misc_delete_costpricelists',  // updated to AxiosAlt

    'saveMiscTimeListsURL' : machine_ip + ':8088/api/misc_save_timelists',  // updated to AxiosAlt
    'deleteMiscTimeListsURL' : machine_ip + ':8088/api/misc_delete_timelists',  // updated to AxiosAlt
    'getMiscTimeListsURL' : machine_ip + ':8088/api/misc_get_timelists',       // updated to AxiosAlt
    'getXMiscTimeListsURL' : machine_ip + ':8088/api/xmisc_get_timelists',       // updated to AxiosAlt

    'getAllCustomerReturnsURL' : machine_ip + ':8088/api/get_all_customer_returns',      // updated to AxiosAlt

    'deleteCustomerReturnsURL' : machine_ip + ':8088/api/delete_customerif_notices',  // added this one (master vs. not master)  // updated to AxiosAlt

    'getSurveyURL' : machine_ip + ':8088/api/get_survey',      // updated to AxiosAlt

    'saveSurveyURL' : machine_ip + ':8088/api/save_survey',  // updated to AxiosAlt
    'getAllSurveysURL' : machine_ip + ':8088/api/get_all_surveys',       // updated to AxiosAlt

    // 'printContainerTicketURL' : 'http://65.0.1.3:8005/print_container_ticket',
    'printContainerTicketURL' : machine_ip + ':8088/api/print_container_ticket',  // updated to AxiosAlt
    'commsRobotControlURL' : machine_ip + ':8088/api/robot_control',  // updated to AxiosAlt


    // following to be used for legal/disclaimer
    // 'getXReadLegalURL' : machine_ip + ':8088/api/xget_read_legal',   // non master uses GetAxiosAlt
    // 'saveXReadLegalURL' : machine_ip + ':8088/api/xsave_read_legal',   // non master uses GetAxiosAlt

    // 'getSubsciptionTypesAllURL' : machine_ip + ':8088/api/get_subsciptions_all',   // non master uses GetAxiosAlt
    

    // blue chef proxy endpoints
    'getProxyallIngredientsFullURL' : machine_ip + ':8088/api/getproxy/all_ingredients/full_details',   // updated to AxiosAlt
    'getProxyallIngredientsURL' : machine_ip + ':8088/api/getproxy/all_ingredients',   // updated to AxiosAlt
    'getProxyallRecipesURL' : machine_ip + ':8088/api/getproxy/all_recipes',   // updated to AxiosAlt  Not used !!!
    'getProxygetRecipeURL' : machine_ip + ':8088/api/getproxy/recipe?silently_validate=false&skip_nutrition=true&skip_cooking_time=true&skip_recipe_code=true&recipe_name=',  // updated to AxiosAlt
    'getProxyallRecipesCategoryURL' : machine_ip + ':8088/api/getproxy/all_recipes?category=Side',   // updated to AxiosAlt

    'getProxygetRecipeNullURL' : machine_ip + ':8088/api/getproxy/recipe?silently_validate=true&skip_cost=true&skip_cooking_time=true&skip_recipe_code=true&recipe_name=',  // updated to AxiosAlt


    'putProxysaveRecipeURL' : machine_ip + ':8088/api/putproxy/recipe',    // updated to AxiosAlt
    'putProxysaveIngredientURL' : machine_ip + ':8088/api/putproxy/ingredient',       // updated to AxiosAlt
    'delProxydeleteRecipeURL' : machine_ip + ':8088/api/delproxy/recipe?recipe_name=',    // updated to AxiosAlt
    'delProxydeleteIngredientURL' : machine_ip + ':8088/api/delproxy/ingredient?ingredient_name=',     // updated to AxiosAlt
    'getProxygetIngredientURL': machine_ip + ":8088/api/getproxy/ingredient?ingredient_name=",    // updated to AxiosAlt
    'getProxyallRecipesByCategoryURL' : machine_ip + ':8088/api/getproxy/all_recipes?category=',  // updated to AxiosAlt  Not used !!!
    'getProxyallRecipesWithTagsURL' : machine_ip + ':8088/api/getproxy/all_recipes',      // updated to AxiosAlt

    'getProxygetCheckingPurchaseListURL': machine_ip + ":8088/api/getproxy/get_ing_checking_and_purchase_list",    // updated to AxiosAlt

    'postProxyChangeIngredientStockByNameURL' : machine_ip + ':8088/api/postproxy/change_ingredient_stock/by_name',       // updated to AxiosAlt
    // 'postProxyBatchChangeIngredientStockByNameURL' : machine_ip + ':8088/api/postproxy/add_or_reduce_ing_stock_in_batch',       // updated to AxiosAlt
    'postProxyBatchChangeIngredientStockByNameURL' : machine_ip + ':8088/api/postproxy/change_ingredient_stock/batch/by_name',       // updated to AxiosAlt

    'postProxyPlaceDishOrdersURL' : machine_ip + ':8088/api/postproxy/place_dish_orders',       // updated to AxiosAlt

    'putProxyBatchOverwriteIngredientStockByNameURL' : machine_ip + ':8088/api/putproxy/overwrite_ing_stock/batch/by_name',       // updated to AxiosAlt

    'getProxyShelfURL' : machine_ip + ":8088/api/getproxy/get_target_shelf/ing_based?shelf_id=",  // get raw ingredients for shelf stocking
    'getProxyFetchDishOrdersURL' : machine_ip + ":8088/api/getproxy/fetch_dish_orders?just_view_raw_customer_orders=false&just_view_robot_orders=false",  // get raw ingredients for shelf stocking

    'getProxyDishOrdersURL' : machine_ip + ":8088/api/getproxy/dish_orders",  // get raw ingredients for shelf stocking

    'delProxydeleteAllDishOrdersURL' : machine_ip + ':8088/api/delproxy/all_dish_orders',
    'delProxyemptyShelfURL' : machine_ip + ':8088/api/delproxy/empty_shelf?shelf_id=',

    'postProxyaddNewContainerURL' : machine_ip + ':8088/api/postproxy/add_new_container',       // add container to shelf DB

    'postProxycreateMEPEmptyContainersURL' : machine_ip + ':8088/api/postproxy/create_required_mep_containers',      // updated to AxiosAlt

    'postProxycreateMEPEmptyContainersURL' : machine_ip + ':8088/api/postproxy/create_required_mep_containers',      // updated to AxiosAlt

    'postProxyMEPShelfURL' : machine_ip + ":8088/api/postproxy/get_target_shelf/ing_based_mep",  // get raw ingredients for shelf stocking

    'postProxyMEPGetPrepInstructionsURL' : machine_ip + ":8088/api/postproxy/get_prep_instructions",  // get raw ingredients for shelf stocking

}

export default config;

