import React from 'react';


export default function Translate(lang, words) {

  const lookup = {
    'word': 'mot',
    'Dish': 'Plat',
    'Dishes': 'Plats',
    'Create Order': 'Placer Une Commande',
    'Automation': 'Automatisation',
    'Update Password': 'Changer Le Mot de Passe',
    'Logout': 'Se Déconnecter',
    'Hi': 'Salut',
    'Home': 'Accueil',
    'Tools': 'Outils',
    'Order Meal': 'Commander Un Repas',
    'recipe': 'recette',
    'Create Menu': 'Créer Un Menu',
    'Create Recipe': 'Créer Une Recette',
    'Category': 'Catégorie',
    'Price/Portion': 'Prix/Portion',
    'Steps': 'Etapes',
    'Lunch': 'Lunch',
    'Dinner': 'Souper',
    'Lunchbox': 'Boîte à Lunch',
    'Vegetarian': 'Végétarien',
    'Vegan': 'Végane',
    'Spice Level': "Niveau d'épice",
    "Gluten Free": 'Sans Gluten',
    'Peanut Free': 'Sans Arachide',
    "Sugar Free": 'Sans Sucre',
    'Starters': 'Entrées',
    'Subcategory': 'Sous-catégorie',
    'Ethnicity': 'Ethnie',
    'Starter': 'Entrée',
    'Mains': 'Plat Principal',
    'Main': 'Plat Principal',
    'Dessert': 'Dessert',
    'Desserts': 'Desserts',
    'Drinks': 'Boissons',
    'Drink': 'Boisson',
    'Fresh': 'Frais',
    'Fruits': 'Fruits',
    'Fruit': 'Fruit',
    'Attributes': 'Attributs',
    'Summary': 'Sommaire',
    'Item': 'Article',
    'Type': 'Type',
    'Quantity': 'Quantité',
    'Price': 'Prix',
    'Total': 'Total',
    'Name': 'Nom',
    'User Name': "Nom D'Utilisateur",
    'SubTotal': 'Sous-Total',
    'GST': 'TPS',
    'PST': 'TVQ',
    'Clear All': 'Tout Effacer',
    'Save': 'Sauvegarder',
    'Copyright': "Droits D'Auteur",
    'Sign In': 'Enregister',
    'Delete': 'Effacer',
    'Cost/Portion': 'Coût/Portion',
    'Dinner Only': 'Souper Seulement',
    'Cost': 'Coût',
    'Cost (Retail)': 'Coût (Détail)',
    'Sense Check Balanced Pricing': 'Tarification Equilibrée Sense Check',
    'Cost Type': 'Type de Coût',
    'Min Main Dish CU': 'Plat Principal Min CU',
    'Max Main Dish CU': 'Plat Principal Max CU',
    'Min 4 Course Meal': 'Repas Min Quatre Plats',
    'Max 4 Course Meal': 'Repas Max Quatre Plats',
    'Home Screen': "Ecran d'Accueil",
    "Page Does Not Exist": "La Page N'Existe Pas",
    'Choose Another Option': 'Choisissez Une Autre Option',
    'Enter New Password': 'Nouveau Mot De Passe',
    'Enter Your Password Again': 'Entrez à Nouveau Le Mot De Passe',
    "Passwords don't match": 'Le Mots de passe ne correspondent pas',
    "Password update successful!": 'Mise à jour du mot de passe réussie',
    "Password": "Mot de Passe",
    "Select": 'Sélectionner',
    "Selection": 'Sélection',
    "Selections": 'Sélections',
    "Sides": 'Accompagnements',
    "Choose Side Combination": "Choisissez Les Accompagnements",
    "Cancel": "Annuler",
    "Ok": "D'Accord",
    "en": "fr",
    "en-ca": "fr-ca",
    "(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (s<0-5>) spice level, (bf) baby friendly, (1) lunchbox deliverd at dinner time":
      "(p) peut contenir des arachides, (gf) sans gluten, (v) végétarien, (s<0-5>) niveau d'épice, (bf) adaptée aux bébés, (1) boîte à lunch livrée à l'heure du dîner",
    "Time": "Temps",
    "If you have modified your order, please notify concierge@jaspercooks.com about this change so we can confirm feasibility.":
      "Si vous avez modifié votre commande, veuillez aviser concierge@jaspercooks.com de ce changement afin que nous puissions confirmer la faisabilité.",
    "Couldn’t find an account associated with this user name. Please try again.":
      "Impossible de trouver un compte associé à ce nom d'utilisateur. Veuillez réessayer.",
    "That is not the right password. Please try again.":
      "Ce n'est pas le bon mot de passe. Veuillez réessayer.",
    "Order Viewer": "Visionneuse de Commandes",
    "Weekly Billings": "Facturation Hebdomadaire",
    'Monday': 'Lundi',
    'Tuesday': 'Mardi',
    'Wednesday': 'Mercredi',
    'Thursday': 'Jeudi',
    'Friday': 'Vendredi',
    'Saturday': 'Samedi',
    'Sunday': 'Dimanche',
    'Summary for Week of': 'Résumé pour la Semaine',
    'Payment due for the Week of': 'Paiement dû pour la Semaine du',
    'to': 'à',
    "Choose any day in the week to get full week's order billings":
      "Choisissez n'importe quel jour de la semaine pour obtenir les factures de commande de la semaine complète",
    'Daily': 'Quotidien',
    'Recipes by': 'Recettes par',
    '1. Pick a date and time': '1. Choisissez une date et une heure disponible',
    '2. Select menu items': '2. Sélectionnez les éléments du menu',
    '3. Here is your order summary': '3. Voici le récapitulatif de votre commande',
    'Main Dishes': 'Plats Principaux',
    'Note: Lunchbox delivered at dinner time': "Remarque : Boîte à lunch livrée à l'heure du dîner",
    'May contain peanuts': 'Peut contenir des arachides',
    'Baby Friendly': 'Adaptée Aux Bébés',
    'Senior Friendly': 'Adaptée Aux Seniors',
    'User Summary': "Résumé de L'utilisateur",
    'Summary All Users': "Résumé Tous les Utilisateurs",
    'Lunch and/or Dinner time values must be set before saving order!': "L'heure du dîner et/ou du souper doivent être définies avant d'enregistrer la commande!",
    'Non-member fee (cooking & dishwashing)': 'Frais de non-membre (cuisine et vaisselle)',
    'Notes': 'Remarques',
    'Lunchbox delivered at dinner time': "Boîte à lunch livrée à l'heure du dîner",
    'Disclaimer': 'Avertissement',
    'Time/Type': 'Heure/Type',
    'Pay': 'Payez',
    'Payment succeeded': 'Paiement réussi',
    'Payment failed, contact YPC': 'Échec du paiement, contactez YPC',
    'Processing payment': 'Traitement du paiement',
    'Cancel Order': 'Annuler la Commande',
    'Lunch and Dinner time values must be set before saving order!': "Les valeurs de l'heure du dîner et du souper doivent être définies avant d'enregistrer la commande !",
    'Lunch time values must be set before saving order!': "L'heure du dîner doit être définie avant d'enregistrer la commande!",
    'Dinner time values must be set before saving order!': "L'heure du souper doit être définie avant d'enregistrer la commande !",
    'No menu or service available for this date. Please choose another date.': "Aucun menu ou service n'est disponible pour cette date. Veuillez choisir une autre date.",
    'en-CA': 'fr-CA',
    'Portions': 'Portions',
    'Not fast enough on your feet.  That lunch time slot is already taken.  Please choose another slot.': 'Pas assez rapide sur vos pieds. Ce créneau horaire du lunch est déjà pris. Veuillez choisir un autre créneau.',
    'Not fast enough on your feet.  That supper time slot is already taken.  Please choose another slot.': 'Pas assez rapide sur vos pieds. Ce créneau horaire pour le souper est déjà pris. Veuillez choisir un autre créneau.',
    'Forgot Password?': 'Mot de passe oublié?',
    'Password reset instructions have been sent to your email!': 'Les instructions de réinitialisation du mot de passe ont été envoyées à votre adresse e-mail!',
    'No email associated with this user name. Please contact Jasper Cooks.': "Aucun e-mail associé à ce nom d'utilisateur. Veuillez contacter le Jasper Cooks.",
    'Reset Password': 'Réinitialiser Mot de Passe',
    'Password reset successful!  Click on the home icon to sign in.': "Réinitialisation du mot de passe réussie ! Cliquez sur l'icône d'accueil pour vous connecter.",
    "The passwords don't match.": "Les mots de passe ne correspondent pas.",
    "Your password must contain at least 8 characters.": "Votre mot de passe doit contenir au moins 8 caractères.",
    'This password is too common.': 'Ce mot de passe est trop commun.',
    'This password is entirely numeric.': 'Ce mot de passe est entièrement numérique.',
    'Problem reseting password, please contact Jasper Cooks.': "Problem reseting password, please contact Jasper Cooks.",
    'Alloted time for password reset exceeded.  Please use Forgot Password link in login page.': 'Le temps imparti pour la réinitialisation du mot de passe a été dépassé. Veuillez utiliser le lien Mot de passe oublié sur la page de connexion.',
    'Your password must not contain spaces': "Votre mot de passe ne doit pas contenir d'espaces",
    'Secured by Square payments.': 'Sécurisé par les paiements Square.',
    'Subscription Total': "Total de l'Abonnement",
    'Food Total': "Nourriture Totale",
    "No payment due for the Week of": "Aucun paiement dû pour la Semaine du",
    '1. Pick a date': '1. Choisissez une date',
    '2. Choose lunch or dinner': '2. Choisissez le lunch ou le souper',
    '3. Pick a time': '3. Choisissez une heure disponible',
    '4. Select possible menu items': '4. Sélectionnez les éléments de menu possibles',
    'Lunch or Dinner': "Lunch ou souper",
    '5. Here is your order summary': '5. Voici le récapitulatif de votre commande',
    '6. Save your order': '6. Enregistrez votre commande',
    'Click on menu item to edit quantity.  Sides are indented.': "Cliquez sur l'élément de menu pour modifier la quantité. Les accompagnements sont indentés.",
    "Due to high demand for ": "En raison de la forte demande pour ",
    ", your current selection quantities are not possible.  The following times - ": ", vos quantités de sélection actuelles ne sont pas possibles.  Les heures suivantes - ",
    'are available.  Either choose one of these times or lower your quantities for ': "sont disponibles. Choisissez l'une de ces heures ou réduisez vos quantités pour ",
    ', your current selection quantities are not possible. Lower your quantities for ': ", vos quantités de sélection actuelles ne sont pas possibles.  Baissez vos quantités pour ",
    "Order modification for this date is closed.": "La modification de la commande pour cette date est fermée.",
    'Order modification for lunch is closed.': "La modification de commande pour le lunch est fermée.",
    'Order modification for dinner is closed.': "La modification de commande pour le souper est fermée.",
    '6. Save or cancel your order': "6. Enregistrez ou annulez votre commande",
    "Qty": "Qté",
    'Click on photo to see description, sides and edit.': "Cliquez sur la photo pour afficher la description, les accompagnements et choisir les quantités.",
    'Click on info to see description, sides and ingredients.': "Cliquez sur la photo pour afficher la description, les accompagnements et les ingrédients.",
    'Save Order': "Enregistrer la commande",
    'No lunch menu available.  Choose dinner.': 'Aucun lunch disponible.  Choisissez le souper.',
    'No dinner menu available.  Choose lunch.': 'Aucun souper disponible.  Choisissez le lunch.',
    'Non Member Total': 'Total non-membres',
    'Individual': 'Individuel',
    'Family': 'Famille',
    'Company (subscription paid by ': 'Entreprise (abonnement payé par ',
    'Company (food and subscription paid by ': 'Entreprise (nourriture et abonnement payé par ',
    'Subscription type': "Type d'abonnement",
    'Payment will be due following week': 'Le paiement sera dû la semaine suivante',
    'Order saved.  Enter order for another day.': "Commande enregistrée. Entrez la commande pour un autre jour.",
    'Order canceled.': "Commande annulée.",
    'Lunchbox friendly': "Compatible pour boite à lunch",
    "Additional items for this dish are not available.": "Il n'est pas possible d'ajouter un plat supplémentaires pour cet article.",
    "Free": "Gratuit",
    'Individual 4 Weeks': 'Individuel 4 Semaines',
    'Family 4 Weeks': 'Famille 4 Semaines',
    "Free Subscription": 'Abonnement Gratuit',
    "Save to DB": "Enregistrer dans la base de données",
    "Nutrition Facts": "Valeur nutritive",
    "Serving Size": "Taille de Portion",
    "Fat": "Lipides",
    "Saturated Fat": "Lipides saturés",
    "Trans Fat": "Lipides trans",
    "Cholesterol": "Cholestérol",
    "Carbohydrates": "Glucides",
    "Fibre": "Fibres",
    "Sugars": "Sucres",
    "Protein": "Protéines",
    "Iron": "Fer",
    "Data Source": "Source de données"
  }

  if (lang === 'FRANCAIS') {
    return lookup[words];
  } else {
    return words;
  }
}