import axios from 'axios';
import * as actionTypes from './authActionTypes';
import * as settings from '../settings';

const SESSION_DURATION = settings.SESSION_DURATION

// ########################################################
// ########################################################
// Contains Auth Action Functions. These perform two kinds of things:
// 1) Return Action Objects
    // a) Simply Return an Action Object
    // b) Perform some action and then return an Action Objet
// 2) Return A Dispatch(Action) combination
    // a)Perform an action then return a Dispatch(Action) combination.
        // This Dispatch(Action) could be used by some other function to dispatch action to the store
// ########################################################
// ########################################################


// ########################################################
// ########################################################
// Auth Action Functions returning Action Objects
// ########################################################
// ########################################################

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (token, customer, firstName, universeMaster, userName) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        customer: customer,
        firstName: firstName,
        userName: userName,
        universeMaster: universeMaster,
        badUserName: false,
        badPassword: false
    }
}


export const authFail = (badUserName, badPassword, error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
        badUserName: badUserName,
        badPassword: badPassword
    }
}

export const authLogout = () => {
    const token = localStorage.getItem('token');
    if (token === undefined){
        localStorage.removeItem('expirationDate');
    } else {
        axios.post(`${settings.API_SERVER}:8088/api/auth/logout/`, {
        }, {headers: {'Authorization': `Token ${token}`}} ).then(res => {console.log(res)}).catch(err => {console.log(err)});
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('customer');
        localStorage.removeItem('universeMaster');
        localStorage.removeItem('firstName');
        localStorage.removeItem('userName');
    }

    return {
        type: actionTypes.AUTH_LOGOUT,
        // badUserName: false,
        // badPassword: false
    };
}

// ########################################################
// ########################################################
// Auth Action Functions returning A Dispatch(Action) combination after performing some action
// ########################################################
// ########################################################

// This sets a timer, which would automatically logout the user after a specified time
export const authCheckTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(authLogout());
        }, expirationTime)
    }
}


export const authLogin = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.post(`${settings.API_SERVER}:8088/api/auth/login/`, {
            username: username,
            password: password
        })
        .then(res => {
            // console.log('LALALALA', res);
            const token = res.data.key;
            const universeMaster = res.data.universe_master;
            const customer = res.data.customer;
            const firstName = res.data.first_name;
            const userName = res.data.user_name;
            const expirationDate = new Date(new Date().getTime() + SESSION_DURATION );
            
            localStorage.setItem('token', token);
            localStorage.setItem('customer', customer);
            localStorage.setItem('firstName', firstName);
            localStorage.setItem('userName', userName);
            localStorage.setItem('universeMaster', universeMaster);
            localStorage.setItem('expirationDate', expirationDate);
            dispatch(authSuccess(token, customer, firstName, universeMaster, userName));
            dispatch(authCheckTimeout(SESSION_DURATION));
            // dispatch();
        })
        .catch(err => {
            // console.log('RRRRRPPPPPPRRRRRRRRRRRRRR', authFail(err));
            axios
            .post(`${settings.API_SERVER}:8088/api/auth/usernamecheck/`, {username: username})
            .then((response) => {
                const badUserName = response.data.badusername;
                const badPassword = response.data.badpassword;
                dispatch(authFail(badUserName, badPassword, err))
            }).catch(error => {
                console.log('EEEEEEEEE',error);
            });


            // dispatch(authFail(username, err))
        });
    }
}


export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const customer = JSON.parse(localStorage.getItem('customer'));
        const firstName = localStorage.getItem('firstName');
        const userName = localStorage.getItem('userName');
        const universeMaster = JSON.parse(localStorage.getItem('universeMaster'));
        // console.log('PEPEPEP', typeof(universeMaster));
        if (token === undefined) {
            dispatch(authLogout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if ( expirationDate <= new Date() ) {
                dispatch(authLogout());
            } else {
                dispatch(authSuccess(token, customer, firstName, universeMaster, userName));
                dispatch(authCheckTimeout( expirationDate.getTime() - new Date().getTime()) );
            }
        }
    }
}
