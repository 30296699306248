import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  } from '@mui/x-data-grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCGetMenuDB from './YPCGetMenuDB';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import translate from "./YPCTranslate";
import XTranslate from "./YPCXTranslate";
import moment from 'moment';
import tz from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.blocked': {
        backgroundColor: 'rgba(255, 0, 0, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
}));


const xtranslate = new XTranslate();

const initSelectedDinnerTime = () => {
  let test = moment((new Date()));
  test.set('minute', 0);
  test.set('hour', 18);
  return test;
};

const initSelectedLunchTime = () => {
  let test2 = moment((new Date()));
  test2.set('minute', 0);
  test2.set('hour', 12);
  return test2;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


export default function YPCViewDayOrders(props) {

  const classes = useStyles();
  // const theme = useTheme();
  
  const [menuLoaded, setMenuLoaded] = React.useState(false);
  const [menuDB, setMenuDB] = React.useState(null);

  const [daysOrderList, setDaysOrderList] = React.useState([]);
  const daysOrderListRef = React.useRef(daysOrderList);
  daysOrderListRef.current = daysOrderList;

  const [altDaysOrderList, setAltDaysOrderList] = React.useState([]);
  const [tallyDaysOrderList, setTallyDaysOrderList] = React.useState([]);

  const [selectStarterDelete, setSelectStarterDelete] = React.useState("");

  const [dessertsOrderList, setDessertsOrderList] = React.useState([]);
  const [selectDessertsDelete, setSelectDessertsDelete] = React.useState("");

  const [fruitsOrderList, setFruitsOrderList] = React.useState([]);
  const [selectFruitsDelete, setSelectFruitsDelete] = React.useState("");

  const [drinksOrderList, setDrinksOrderList] = React.useState([]);
  const [selectDrinksDelete, setSelectDrinksDelete] = React.useState("");

  const [mainsOrderList, setMainsOrderList] = React.useState([]);
  const [selectMainsDelete, setSelectMainsDelete] = React.useState("");
  
  const [mealDB, setMealDB] = React.useState(true);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [lunchBoxChecked, setLunchBoxChecked] = React.useState({'test': 'test2'});
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = React.useState({'test': 'test2'});

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()));
  const selectedDateRef = React.useRef(selectedDate);
  selectedDateRef.current = selectedDate;


  const [gst, setGST] = React.useState(0.05);
  const [pst, setPST] = React.useState(.09975);

  const [locale, setLocale] = React.useState()

  const [lunchBlocked, setLunchBlocked] = React.useState(false);
  const [dinnerBlocked, setDinnerBlocked] = React.useState(false);

  const [refreshButton, setRefreshButton] = React.useState(true);
  const refreshButtonRef = React.useRef(refreshButton);
  refreshButtonRef.current = refreshButton;

  const [toggle, setToggle] = React.useState(false);
  const toggleRef = React.useRef(toggle);
  toggleRef.current = toggle;

  const [refreshMessage, setRefreshMessage] = React.useState(null);

  React.useEffect(() => {
    getOrders();
  }, [selectedDate, toggle]);

  React.useEffect(() => {
    getTallys();
  }, [altDaysOrderList]);


  React.useEffect(() => {
    setInterval(() => {
      // if (refreshIsActive.current) {
          // console.log('1 sec.', serveTimeRef.current);
        if(refreshButtonRef.current) {
            refreshOrders();
            // getOrders();
            // setToggle(!toggleRef.current);
        };
      // };
    }, 30000);
  }, []);


  async function refreshOrders() {
    let subDate = selectedDateRef.current.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
    var order_structure = await YPCGetAxiosAlt(xget, setXGet, 'getDaysOrdersURL', dateString);

    let refresh_message = "";

    let new_structure = []
    order_structure.map((value) => {
      let new_line = value;
      delete new_line.id;
      delete new_line.save_time;
      new_structure.push(new_line);
    });
    
    let old_structure = []
    daysOrderListRef.current.map((value) => {
      let new_line = value;
      delete new_line.id;
      delete new_line.save_time;
      old_structure.push(new_line);
    });

    // console.log('BABABABAB', oldStructureServeTimeList, newStructureServeTimeList);



      for(let i=0;i<old_structure.length; i++ ) {
        // console.log('LALALAL',JSON.stringify(old_structure[i]) === JSON.stringify(order_structure[i]) , old_structure[i], order_structure[i]);
        if (JSON.stringify(old_structure[i]) !== JSON.stringify(new_structure[i])) {
            refresh_message = "Orders have been modified!!";
        };
      };

    if (refresh_message !== "") {
      setToggle(!toggleRef.current);
      setRefreshMessage(refresh_message);
    };
  };


  async function getTallys() {

    // get menu

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    let result = [];
    var menu_structure = await YPCGetAxiosAlt(xget, setXGet, 'getMenuURL', dateString);
    if (menu_structure.length !== 0) {
      result = JSON.parse(menu_structure[0].menu);
    };
    
    console.log('JWJWJWJWJWJ', result);

    let condensed_menu = [];
    let newName = '';
    let lunchCap = 0;
    let dinnerCap = 0;
    let bowlportions = '';
    if (result.length > 0) {


      var fullRecipeDict = await YPCPutAxios2Alt(setXPost, 'getRecipeDictsURL', result, '');
      console.log('PZPZPZPZPPZ', fullRecipeDict)
      let tempRecipeDict = {}
      if (fullRecipeDict['status'] === 'good') {
        // setRecipeDict(fullRecipeDict['recipe_dicts']);
        tempRecipeDict = fullRecipeDict['recipe_dicts']
      } else {
        // setRecipeDict({});
      };

      result.map((value, index) => {
        if (value['category'] !== 'Side' && index !== 0) {
          condensed_menu.push({'name': newName, 'lunchcap': lunchCap, 'dinnercap': dinnerCap, 'bowlportions' : bowlportions})
        };

        if (value['category'] !== 'Side') {
          bowlportions = tempRecipeDict[value['name']]['portion']
          newName = value['name'];
          if (value['lunchselected']) {
            if (value.hasOwnProperty('lunch_cap')) {
              lunchCap = value['lunch_cap']
            } else {
              lunchCap = 0;
            };
          } else {
            lunchCap = 0;
          };

          if (value['dinnerselected']) {
            if (value.hasOwnProperty('dinner_cap')) {
              dinnerCap = value['dinner_cap']
            } else {
              dinnerCap = 0;
            };
          } else {
            dinnerCap = 0;
          };

        } else if (value['category'] === 'Side') {
          newName = newName + ', ' + value['name'];
        };
      });

      condensed_menu.push({'name': newName, 'lunchcap': lunchCap, 'dinnercap': dinnerCap, 'bowlportions': bowlportions})



    };

    console.log('ZMZMZMZMemu', condensed_menu);

    // process

    if (altDaysOrderList.length !== 0) {
      let tempDict = {};
      let tempList = [];

      let tempDictDinner = {};
      let tempDictLunch = {};

      altDaysOrderList.map((value) => {
        if (value['name'] !== "") {
          tempDict[value['name']] = 0;
          tempDictLunch[value['name']] = 0;
          tempDictDinner[value['name']] = 0;
          tempList.push(value)
        }
      });

      tempList.map((value) => {
        tempDict[value['name']] = tempDict[value['name']] + value['portions'];
        if (value['meal_type'] == 'lunch') {
          tempDictLunch[value['name']] = tempDictLunch[value['name']] + value['portions'];
        };
        if (value['meal_type'] == 'dinner') {
          tempDictDinner[value['name']] = tempDictDinner[value['name']] + value['portions'];
        };

      });

      let finalList = [];
      let id = 0;
      // for (const [key, value] of Object.entries(tempDict)) {
      //   finalList.push({'name': key, 'portions': value, 'id': id, 'dinnerportions': tempDictDinner[key], 'lunchportions': tempDictLunch[key]})
      //   id = id + 1;
      // }


      // if (value.hasOwnProperty('lunch_cap')) {
      console.log('XPXPXPXPXPXP', tempDict);
      condensed_menu.map((record) => {
        if (tempDict.hasOwnProperty(record['name'])) {
          finalList.push({'name': record['name'], 'bowlportions' : record['bowlportions'] , 'portions': tempDict[record['name']], 'id': id, 'dinnerportions': tempDictDinner[record['name']], 'lunchportions': tempDictLunch[record['name']], 'lunchcap': record['lunchcap'] , 'dinnercap': record['dinnercap']})
        } else {
          finalList.push({'name': record['name'],  'bowlportions' : record['bowlportions'], 'portions': 0, 'id': id, 'dinnerportions': 0, 'lunchportions': 0, 'lunchcap': record['lunchcap'] , 'dinnercap': record['dinnercap']})
        }

        // finalList.push({'name': key, 'portions': value, 'id': id, 'dinnerportions': tempDictDinner[key], 'lunchportions': tempDictLunch[key]})
        id = id + 1;
      })

      // console.log('MAMAMAMAMAM', finalList);

      setTallyDaysOrderList(finalList);
    } else {
      setTallyDaysOrderList([]);
    }

  };


  async function getOrders() {
    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    // var order_structure = await YPCGetAxios(xget, setXGet, 'getDaysOrdersURL', dateString);
    var order_structure = await YPCGetAxiosAlt(xget, setXGet, 'getDaysOrdersURL', dateString);

    if (order_structure.length !== 0) {
        let orderList = [];
        order_structure.map((value) => {
            let temp = value;
            let id = uuidv4();
            temp['id'] = id;
            orderList.push(temp);
        });
        setDaysOrderList(orderList);

        // console.log('SQSQSQSQSQSQSQ', order_structure);


        // new stuff
        let newOrderList = [];
        let dishName = "";
        let customerName = "";
        let customerFullName = "";
        let portions = 0;
        let mealType = "";
        let lunchBox = false;
        let category = "";
        let servingTime = "2021-12-14T12:00";
        let firstTime = true;
        let p_or_d = "";
        let building_id = "";
        let phone_number = "";
        // console.log('OOrderStructure', order_structure);
        order_structure.map((value) => {
          let temp = value;
          let id = uuidv4();
          temp['id'] = id;
          if (firstTime) {
            if (temp['category'] === "Side") {
              newOrderList.push({id: 1, name : "Menu Starts With Side!!", category: "", customer_id: "", customer_fullname: "", portions:0, lunch_box:false, meal_type:"", serving_time_range: "", pickup_or_delivery : "", building_id: "", phone_number:""})
              newOrderList.push({id: 2, name : "", category: "", customer_id: "",  customer_fullname: "", portions:0, lunch_box:null, meal_type:"", serving_time_range:"",  pickup_or_delivery : "", building_id: "", phone_number:""})
            }
            dishName = temp['name'];            
            customerName = temp['customer_id'];
            customerFullName = temp['user_full_name'];
            portions = temp['portions'];
            mealType = temp['meal_type'];
            category = temp['category'];
            lunchBox = temp['lunch_box'];
            servingTime = temp['serving_time_range'];
            p_or_d = temp['pickup_or_delivery'];
            building_id = temp['building_id'];
            phone_number = temp['phone_number'];
            firstTime = false;
          } else {
            if (temp['category'] === "Side") {
              dishName = dishName + ", " + temp['name'];
            } else {


              newOrderList.push({id: id, name : dishName, category: category, customer_id: customerName, customer_fullname: customerFullName, portions: portions, 
                      lunch_box:lunchBox, meal_type: mealType, serving_time_range: servingTime, pickup_or_delivery: p_or_d, building_id: building_id, phone_number: phone_number});

              if (servingTime !== temp['serving_time_range'] || customerName !== temp['customer_id']) {
                id = uuidv4(); 
                newOrderList.push({id: id, name : "", category: "", customer_id: "", customer_fullname: "", portions:null, lunch_box:null, meal_type:"", serving_time_range:"", pickup_or_delivery: "", phone_number: "", building_id: ""})
              };

              dishName = temp['name'];            
              customerName = temp['customer_id'];
              customerFullName = temp['user_full_name'];
              portions = temp['portions'];
              mealType = temp['meal_type'];
              category = temp['category'];
              lunchBox = temp['lunch_box'];
              servingTime = temp['serving_time_range'];
              p_or_d = temp['pickup_or_delivery'];
              building_id = temp['building_id'];
              phone_number = temp['phone_number'];
  
            };

          };

          // save one last one out here!!




        });

        let id = uuidv4();
        newOrderList.push({id: id , name : dishName, category: category, customer_id: customerName, customer_fullname: customerFullName, portions: portions, lunch_box:lunchBox, meal_type: mealType, serving_time_range: servingTime, pickup_or_delivery: p_or_d});
        setAltDaysOrderList(newOrderList);

        // end of new stuff



        // xtranslate.addLookupNew(translateList);
    } else {
      setDaysOrderList([]);
      setAltDaysOrderList([])
    };

  };

  const handleDateChange = (date) => {
    console.log('GAGAGA', date);
    if (date !== selectedDate) {
      setSelectedDate(moment(date));
    }
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      field: 'serving_time_range',
      headerName: translate(props.language, 'Serve Time'),
      // headerName: 'Lunchbox',
      type: 'string',
      sortable: true,
      width: 160,
      editable: false,
      valueFormatter: (params) => {
          let timevalue = params.value.split("T")
          if (timevalue.length = 2) {
            return timevalue[1];
          } else {
            return timevalue;
          };
         },        
    },   
    // {
    //   field: 'customer_id',
    //   headerName: translate(props.language, 'Customer'),
    //   type: 'string',
    //   width: 200,
    //   sortable: true,
    // },  
    {
      field: 'customer_fullname',
      headerName: translate(props.language, 'Customer'),
      type: 'string',
      width: 300,
      sortable: true,
    },  

    {
      field: 'name',
      headerName: translate(props.language, 'Selection'),
      type: 'string',
      width: 500,
      sortable: true,
      editable: false,
    },
    {
      field: 'portions',
      headerName: translate(props.language, 'Portions'),
      type: 'number',
      sortable: true,
      width: 140,
      editable: false,
    },
    {
      field: 'meal_type',
      headerName: 'Type',
      type: 'string',
      width: 140,
      sortable: true,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
          // hide: params.row.side,
          // show: !params.row.side && !dinnerBlocked,
          blocked: (params.value === 'lunchbox')
      }),
    },
    {
      field: 'category',
      headerName: translate(props.language, 'Category'),
      type: 'string',
      width: 140,
      sortable: true,
      editable: false,
    },
      // {
      //   field: 'lunch_box',
      //   headerName: translate(props.language, 'Lunchbox'),
      //   // headerName: 'Dinner',
      //   type: 'boolean',
      //   sortable: false,
      //   width: 120,
      //   editable: false,
      //   // valueFormatter: (params) => {
      //   //     if (!params.value) {
      //   //         return "";
      //   //     }
      //   //     else {
      //   //         return 'yes';
      //   //     }
      //   //    },
      // }
      {
        field: 'pickup_or_delivery',
        headerName: 'Pickup/Delivery',
        type: 'string',
        width: 140,
        sortable: true,
      },
      {
        field: 'building_id',
        headerName: 'Building',
        type: 'string',
        width: 140,
        sortable: true,
      },
      {
        field: 'phone_number',
        headerName: 'Phone Number',
        type: 'string',
        width: 140,
        sortable: true,
      },            
      
  ];


  const tallyColumns = [
    {
      field: 'name',
      headerName: translate(props.language, 'Selection'),
      type: 'string',
      width: 500,
      sortable: true,
      editable: false,
    },
    {
      field: 'lunchcap',
      headerName: 'Lunch Cap',
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
    },
    {
      field: 'dinnercap',
      headerName: 'Dinner Cap',
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
    },

    {
      field: 'portions',
      headerName: translate(props.language, 'Portions'),
      type: 'number',
      sortable: true,
      width: 140,
      editable: false,
    },
    {
      field: 'lunchportions',
      headerName: translate(props.language, 'Lunch'),
      type: 'number',
      sortable: true,
      width: 140,
      editable: false,
    },
    {
      field: 'dinnerportions',
      headerName: translate(props.language, 'Dinner'),
      type: 'number',
      sortable: true,
      width: 140,
      editable: false,
    },
    {
      field: 'bowlportions',
      headerName: 'Portions/Bowl',
      type: 'number',
      sortable: true,
      width: 180,
      editable: false,
    },
    
  ];

  const handleEditable = (params) => {
    if (params.row.side) {
        return (false);
    } else if (params.field === 'lunchboxcount') {
        if (params.row.uuid in lunchBoxChecked && !dinnerBlocked) {
            return (true);
        } else {
            return (false);
        };
    } else if (params.field === 'lunchcount') {
        if (params.row.uuid in dinnerOnlyChecked) {
          return (false);
        } else if (lunchBlocked) {
          return (false);
        } else {
          return (true);
        };
    } else if (params.field === 'dinnercount') {
        if (dinnerBlocked) {
          return (false);
        } else {
        return (true);
        };
    };
 };



  // Start Functions Section

  function OrderTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
        //   onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          // hideFooter={true}
          // hideFooterPagination={true}
          density="compact"
          rows={altDaysOrderList}
          columns={columns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleStarterSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };

  function TallyTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
        //   onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          rows={tallyDaysOrderList}
          columns={tallyColumns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStarterSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };


  function StarterTable() {
    return (
    //   <div style={{ width: '100%' }} className={gridClasses.root} >
      <div style={{ width: '100%' }} >
        <DataGrid
        //   onCellEditCommit={handleStarterLunchCount}
        //   isCellEditable={handleEditable}
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          density="compact"
          rows={daysOrderList}
          columns={columns}
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleStarterSelect}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };



  const handleStarterLunchCount = React.useCallback(
    ({ id, field, value }) => {
        // console.log('XXXXXXXXXXX', id, field, value);
        if (field === 'lunchcount' || field === 'dinnercount' || field === 'lunchboxcount') {
            const updatedRows = daysOrderList.map((row) => {
                if (row.id === id) {
                    return { 
                            category: row.category,
                            dish: row.dish,
                            id: row.id,
                            name: row.name,
                            price: row.price,
                            side: row.side,
                            uuid: row.uuid,
                            dinneronly: (row.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[row.uuid] : null), 
                            lunchbox:  (row.uuid in lunchBoxChecked ? lunchBoxChecked[row.uuid] : null),
                            lunchcount: (field === 'lunchcount') ? ((value > 0) ? value: null) : row.lunchcount,
                            dinnercount: (field === 'dinnercount') ? ((value > 0) ? value: null) : row.dinnercount,
                            lunchboxcount: (field === 'lunchboxcount') ? ((value > 0) ? value: null) : row.lunchboxcount,
                            spicelevel: row.spicelevel,
                            vegetarian: row.vegetarian
                            }

                };
                return row;
            
            });
            setDaysOrderList(updatedRows);
        };
    },
  );

  const handleStarterSelect = (params) => {
    setSelectStarterDelete(params.row.uuid);
  };


  // End of Starters Section


  function refreshMsg() {
    return (
      <div>
        <Dialog open={refreshMsg} >
          {/* <DialogTitle>{translate(props.language, 'Lunch and Dinner time values must be set before saving order!')}</DialogTitle> */}
          <DialogTitle>{refreshMessage}</DialogTitle>
          <DialogContent>
            {/* {allergyContent} */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRefreshMsg} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  
  async function handleRefreshMsg() {
    // await getRefreshOfOrders();
    // setToggle(!toggle);
    setRefreshMessage(null);
  };


    return (
      <React.Fragment>

      <Container className={classes.container}  style={{ marginTop: "2em" }}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={2} xs={12}>
          <Grid item xs={12}>



              <div className={classes.sendleft}>

                  {refreshMessage !== null ? refreshMsg() : null}

                  <Typography component="h4" variant="h4">
                      {translate(props.language,"Day's Orders")}
                  </Typography>
              </div>


              <div style={{clear: "both"}}></div>
          </Grid>

          <Grid item xs={12}>

                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                            <MuiThemeProvider theme={theme}>
                              {/* <KeyboardDatePicker */}
                              <DatePicker
                                  orientation="landscape"
                                  variant="static"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date picker dialog"
                                  // format="MM/dd/yyyy"
                                  format={"dddd, " + "MMMM" + ", yyyy"}
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                              />
                            </MuiThemeProvider>
                          </MuiPickersUtilsProvider>

          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                      { mealDB ? 
                              <Grid item xs={2} >
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    size="small"
                                    className={classes.button}
                                    onClick={() => {
                                        setRefreshButton(!refreshButton)
                                      }
                                    }
                                    disabled={false}
                                    aria-label="whatever nextx"
                                    style={{backgroundColor: '#78cbff', color:"white"}}
                                >
                                    {refreshButton ? 'Disable Refresh' : 'Enable Refresh'}
                                </Button>   
                              </Grid>
                        : null }
                </Grid>
                <Grid item xs={10}></Grid>                                    
            </Grid>
          </Grid>


          {/*   Final Tally Section */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Final Tally'} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {TallyTable()}
                    </Grid>
                  </Grid>
              : null }
          </Grid>
          {/* Final Tally Section */}


          <div><p></p></div>



          {/*   Orders Section */}
          <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Orders')} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {OrderTable()}
                    </Grid>
                  </Grid>
              : null }
          </Grid>
          {/* Orders Section */}


          {/* <div><p></p></div> */}

          {/*   Final Tally Section */}
          {/* <Grid item xs={12}>
                        <br />
                        <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Final Tally'} &#8198;</div>
          </Grid>
          <Grid item xs={12}>
              { mealDB ?     
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {TallyTable()}
                    </Grid>
                  </Grid>
              : null }
          </Grid> */}
          {/* Final Tally Section */}


          <div><p></p></div>


          {xget !== null ? 
            xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
          : null}


          {xpost !== null ? 
              xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
            : null}

          {xpost !== null ? 
              xpost[0] === 'S' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
            : null}

        </Grid>
      </Container>

      </ React.Fragment>



    );
}


