import React from 'react';
import Urls from './Urls';
import Layout from './components/Layout';
import {connect} from 'react-redux';
import * as actions from './store/authActions';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, useLocation, useHistory } from "react-router-dom";


function App(props) {

  const [language, setLanguage] = React.useState(
    localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'FRANCAIS'
    );


  // let symbolinURL = new URLSearchParams(useLocation().search).get("symbol");
  // let history = useLocation();


  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    props.setAuthenticatedIfRequired();
  }, []);

  return (
    <div className="App">
      <CookiesProvider>
      <BrowserRouter>
        <Layout {...props} language={language} setLanguage={setLanguage}>
          <Urls {...props}  language={language} />
        </Layout>
      </BrowserRouter>
      </CookiesProvider>
    </div>
  );
}

//This means that one or more of the redux states in the store are available as props
const mapStateToProps = (state) => {
  // console.log('MXMXMXMXXM', state.auth.isStaff, state.auth);
  // console.log('SSSSSSSSSSSSS', state)
  return {
    // this was original

    isAuthenticated: state.auth.token !== null && typeof state.auth.token !== 'undefined',
    token: state.auth.token,
    // replaced by following
    // isAuthenticated: true,
    // token: 'ABCD'
    customer: state.auth.customer,
    firstName: state.auth.firstName,
    userName: state.auth.userName,
    universeMaster: state.auth.universeMaster,
    badUserName: state.auth.badUserName,
    badPassword: state.auth.badPassword
  }
}

//This means that one or more of the redux actions in the form of dispatch(action) combinations are available as props
const mapDispatchToProps = (dispatch) => {
  return {
    setAuthenticatedIfRequired: () => dispatch(actions.authCheckState()),
    logout: () => dispatch(actions.authLogout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);