import { useState, useEffect } from 'react'
import { useROS } from 'react-ros'
import ROSLIB from 'roslib';


var ros = new ROSLIB.Ros();

function PutToLight(content, on) {


    ros.connect('ws://localhost:9090');


    var cmdLight = new ROSLIB.Topic({
        ros : ros,
        name : '/devices/put_2_light_cmd',
        messageType : 'std_msgs/Int16MultiArray'
      });
   
    if (on) {
      var p2light = new ROSLIB.Message({ data: [1,1, parseInt(content[5]), parseInt(content[6]) ,255,255,255] });
    } else {
      var p2light = new ROSLIB.Message({ data: [0,1, parseInt(content[5]), parseInt(content[6]) ,255,255,255] });
    }

      cmdLight.publish(p2light);


    return (
      null
    );
  }
export default PutToLight;