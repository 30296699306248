import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { DataGrid } from '@mui/x-data-grid';
import { GridOverlay, DataGrid,  GridToolbarContainer,
  GridToolbarExport,
  gridClasses } from '@mui/x-data-grid';
import Rating from '@mui/material/Rating';  
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Card, Container} from '@material-ui/core';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCGetMenuDB from './YPCGetMenuDB';
import YPCPutAxios from './YPCPutAxios';
import YPCGetAxios from './YPCGetAxios';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';

import XTranslate from "./YPCXTranslate";
import translate from "./YPCTranslate";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});


const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "95%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },

    },
    gridoverlay: {
      flexDirection: 'column'
    },
    label: {
      marginTop: theme.spacing(1),
    },

}));

const spiceLookupToString = ['None', 'Low', 'Med', 'Med', 'High', 'High'];


const getTimeLunchList = () => {
  let hourList = ['11','12','13','14'];
  let minuteList = ['00','10', '15', '20', '30', '40', '45', '50'];
  let finalList = [];
  hourList.map((hour) => {
    minuteList.map((minute) => {
      finalList.push(hour + ':' + minute);
    });
  });
  return finalList;
};

const getTimeDinnerList = () => {
  let hourList = ['17','18','19','20'];
  let minuteList = ['00','10', '15', '20', '30', '40', '45', '50'];
  let finalList = [];
  hourList.map((hour) => {
    minuteList.map((minute) => {
      finalList.push(hour + ':' + minute);
    });
  });
  return finalList;
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function YPCCreateMenu(props) {

  const classes = useStyles();
  // const theme = useTheme();
  const [menuLoaded, setMenuLoaded] = React.useState(false);
  
  const [selectedRow, setSelectedRow] = React.useState("");

  const [starterOrderList, setStarterOrderList] = React.useState([]);
  const [selectedStarterValue, setSelectedStarterValue] = React.useState(null);
  const [selectStarterDelete, setSelectStarterDelete] = React.useState("");
  const [starterRecipes, setStarterRecipes] = React.useState([]);

  const [dessertsOrderList, setDessertsOrderList] = React.useState([]);
  const [selectedDessertsValue, setSelectedDessertsValue] = React.useState(null);
  const [selectDessertsDelete, setSelectDessertsDelete] = React.useState("");
  const [dessertRecipes, setDessertRecipes] = React.useState([]);

  const [fruitsOrderList, setFruitsOrderList] = React.useState([]);
  const [selectedFruitsValue, setSelectedFruitsValue] = React.useState(null);
  const [selectFruitsDelete, setSelectFruitsDelete] = React.useState("");
  const [fruitRecipes, setFruitRecipes] = React.useState([]);

  const [drinksOrderList, setDrinksOrderList] = React.useState([]);
  const [selectedDrinksValue, setSelectedDrinksValue] = React.useState(null);
  const [selectDrinksDelete, setSelectDrinksDelete] = React.useState("");
  const [drinkRecipes, setDrinkRecipes] = React.useState([]);

  const [mainsOrderList, setMainsOrderList] = React.useState([]);
  const [selectedMainsValue, setSelectedMainsValue] = React.useState(null);
  const [selectMainsDelete, setSelectMainsDelete] = React.useState("");
  const [mainRecipes, setMainRecipes] = React.useState([]);
  
  const [recipedb, setRecipeDB] = React.useState(null);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);

  const [comboOpen, setComboOpen] = React.useState(false);
  const [comboValue, setComboValue] = React.useState(null);
  const [compositionList, setCompositionList] = React.useState(null);
  const [comboChoice, setComboChoice] = React.useState(null);  // set to "mains", "starter", etc
  
  const [lunchBoxChecked, setLunchBoxChecked] = React.useState({'test': 'test2'});
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = React.useState({'test': 'test2'});

  const [lunchSelectDict, setLunchSelectDict] = React.useState({'test': 'test2'});
  const [dinnerSelectDict, setDinnerSelectDict] = React.useState({'test': 'test2'});
  const [realsideSelectDict, setRealsideSelectDict] = React.useState({'test': 'test2'});

  const [senseList, setSenseList] = React.useState([{id: 0, costtype: "Abra cadabra", cost: 0.15}, {id:1, costtype: "Abra cadabra2", cost: 1.15}]);

  const [listPointer, setListPointer] = React.useState(null);

  // const [markups, setMarkups] = React.useState({'mains':0.70, 'starter': 0.7, 'desserts':0.7, 'drinks': 0.5, 'fruits': 0.0 });
    // following changed August 15, 2022
  const [markups, setMarkups] = React.useState({'mains':1.00, 'starter': 0.7, 'desserts':0.7, 'drinks': 0.5, 'fruits': 0.0 });

  const [markupMultiplier, setMarkupMultiplier] = React.useState({'Main':1.5, 'MainVeg': 2.0, 'Starter': 2.0, 'Dessert':1.5, 'Drink': 1.2, 'Fruit': 0.0, 'Side': 1.5, '': 0.0 });

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [allRobotHumanChoice, setAllRobotHumanChoice] = React.useState('All');
  const [tagString, setTagString] = React.useState('');

  const [daysBack, setDaysBack] = React.useState('-3');
  const [hourTime, setHourTime] = React.useState('14');
  const [minuteTime, setMinuteTime] = React.useState('0');

  const [daysBackDinner, setDaysBackDinner] = React.useState('-3');
  const [hourTimeDinner, setHourTimeDinner] = React.useState('14');
  const [minuteTimeDinner, setMinuteTimeDinner] = React.useState('0');

  const [editTimeList, setEditTimeList] = React.useState(false);

  const [lunchDefaultTimeList, setLunchDefaultTimeList] = React.useState([]);
  const [dinnerDefaultTimeList, setDinnerDefaultTimeList] = React.useState([]);

  const [lunchTimeList, setLunchTimeList] = React.useState([]);
  const [dinnerTimeList, setDinnerTimeList] = React.useState([]);
  const [surveyResultDict, setSurveyResultDict] = React.useState({})

  React.useEffect(() => {
    if (!menuLoaded) {
      handleLoadRecipes();
    }
  }, [menuLoaded]);

  React.useEffect(() => {
    calculateSenseTable();
    everythingTable();
  }, [mainsOrderList, starterOrderList, dessertsOrderList, drinksOrderList, fruitsOrderList, dinnerOnlyChecked, lunchBoxChecked, lunchSelectDict, dinnerSelectDict, realsideSelectDict, props.language]);


  React.useEffect(() => {
    getMenu();
  }, [selectedDate]);



  async function recipeFullStepsString(ingSteps) {
    let stepString = "";
    ingSteps.map((value) => {
      if (value.step_type === 'ingredient') {
            let tempName = value.ing_name;
            if (stepString !== "") {
              if (!stepString.includes(tempName)) {
                stepString = stepString + "<>" +  tempName;
              };
            } else {
              stepString = tempName;
            };
      };
    });
    console.log('BSBSBSBSBS', stepString, ingSteps);
    return stepString
  };


  
  async function recipeStepsString(ingSteps) {
    let stepString = "";
    ingSteps.map((value) => {
      if (value.step_type === 'ingredient') {
            let tempName = value.ing_name.split('(')[0].replace('ZZZ','').trim();
            if (stepString !== "") {
              if (!stepString.includes(tempName)) {
                stepString = stepString + ", " +  tempName;
              };
            } else {
              stepString = tempName;
            };
      };
    });
    console.log('ASASASASAS', stepString, ingSteps);
    return stepString
  };


  async function addRawPrice(costpricedata, record, raw_price) {

      if (costpricedata.hasOwnProperty('empty')) {

        let alt_raw_price = 0.0;
        let lMarkup = 0.0
        if (record.category === 'Starter') {
          lMarkup = markupMultiplier['Starter'];
        } else if (record.category === 'Main') {
          lMarkup = markupMultiplier['Main'];
          if (record.vegetarian) {
            lMarkup = markupMultiplier['MainVeg'];
          };
        } else if (record.category === 'Dessert') {
          lMarkup = markupMultiplier['Dessert'];
        } else if (record.category === 'Drink') {
          lMarkup = markupMultiplier['Drink'];
        } else if (record.category === 'Fruit') {
          lMarkup = markupMultiplier['Fruit'];
        } else if (record.category === 'Side') {
          lMarkup = markupMultiplier['Side'];
        };
        alt_raw_price = Math.ceil((record.cost * lMarkup)*10)/10
        return alt_raw_price;
      } else {
        return raw_price;
      };

  };

  async function getMenu() {

    var survey_result = await YPCGetAxiosAlt(xget, setXGet, 'getAllSurveysURL', '');

    setSurveyResultDict(survey_result.result_dict);

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    let tempLunchDefaultTimeList = getTimeLunchList();
    let tempDinnerDefaultTimeList = getTimeDinnerList();

    var blocked_structure = await YPCGetAxiosAlt(xget, setXGet, 'getMiscBlockedURL', [dateString, 'blocked']);
    setDaysBack(blocked_structure['daysBack']);
    setHourTime(blocked_structure['hourTime']);
    setMinuteTime(blocked_structure['minuteTime']);

    if (blocked_structure.hasOwnProperty('daysBackDinner')) {
      setDaysBackDinner(blocked_structure['daysBackDinner']);
      setHourTimeDinner(blocked_structure['hourTimeDinner']);
      setMinuteTimeDinner(blocked_structure['minuteTimeDinner']);
    } else {
      setDaysBackDinner(blocked_structure['daysBack']);
      setHourTimeDinner(blocked_structure['hourTime']);
      setMinuteTimeDinner(blocked_structure['minuteTime']);
    };

    var timinglists = await YPCGetAxiosAlt(xget, setXGet, 'getMiscTimeListsURL', [dateString, 'timelists']);

    setLunchTimeList(timinglists.lunchList);
    setDinnerTimeList(timinglists.dinnerList);
    
    let newLunchDefaultList = [];
    tempLunchDefaultTimeList.map((value) => {

      if ( !timinglists.lunchList.includes(value)) {
        newLunchDefaultList.push(value);
      }
    });

    setLunchDefaultTimeList(newLunchDefaultList);
    
    let newDinnerDefaultList = [];
    tempDinnerDefaultTimeList.map((value) => {
      if ( !timinglists.dinnerList.includes(value)) {
        newDinnerDefaultList.push(value);
      }
    });

    setDinnerDefaultTimeList(newDinnerDefaultList);


    // get menu
    let result = [];
    // var menu_structure = await YPCGetAxios(xget, setXGet, 'getMenuURL', dateString);
    var menu_structure = await YPCGetAxiosAlt(xget, setXGet, 'getMenuURL', dateString);
    if (menu_structure.length !== 0) {
      console.log('ZMZMZMZMZMZM', JSON.parse(menu_structure[0].menu));
      result = JSON.parse(menu_structure[0].menu);
    } else {
      console.log('ZMZMZMZMZMZM', menu_structure)
    }


    var costpricelists = await YPCGetAxiosAlt(xget, setXGet, 'getMiscCostPriceListsURL', [dateString, 'costprice']);

    // console.log('XUXUXUXUXUXUBBBBBBBBBBB', costpricelists );

    // var result = await YPCGetAxios(xget, setXGet, 'conciergeMenuGetURL', dateString);
    // console.log("NBNBNBNBNBNBN", result);

    if (result.length !== 0) {
        let mainsList = [];
        let starterList = [];
        let dessertsList = [];
        let fruitsList = [];
        let drinksList = [];
        let category = null;
        let tempValue = null;
        let tempLunch = {};
        let tempDinner = {};
        let tempLunchSel = {};
        let tempDinnerSel = {};
        let tempRealSideSel = {};
        let tempAptForLunchbox = null;
        result.map((value) => {

          tempValue = {
            category: value.category,

            subcategory: value.subcategory,
            support_offline: value.support_offline,

            // cost: value.cost,
            cost: 0.0,
            dish: value.dish,
            id: value.id,
            name: value.name,
            name_french: value.name_french,
            price: value.price,
            side: value.side,
            uuid: value.uuid,
            spicelevel: value.spicelevel,
            vegetarian: value.vegetarian,
            vegan: value.vegan,
            sugar_free: value.sugar_free,
            gluten_free: value.gluten_free,
            peanut_free: value.peanut_free,
            baby_friendly: value.baby_friendly
          };


          if (costpricelists.hasOwnProperty('empty')) {
            tempValue.raw_price = 0.0;            
          } else {
            tempValue.raw_price = costpricelists[value.name]['raw_price'];
          };

          // console.log('XUXUXUXUXUXUAAA', tempValue);

          if (value.hasOwnProperty('chefs_choice')) {
            tempValue['chefs_choice'] = value.chefs_choice;
          } else {
            tempValue['chefs_choice'] = false;
          };

          if (value.hasOwnProperty('lunch_cap')) {
            tempValue['lunch_cap'] = value.lunch_cap;
          } else {
            tempValue['lunch_cap'] = 0;
          };

          if (value.hasOwnProperty('dinner_cap')) {
            tempValue['dinner_cap'] = value.dinner_cap;

          } else {
            tempValue['dinner_cap'] = 0;
          };


          if (value.hasOwnProperty('calories')) {
            tempValue['calories'] = value.calories;
          } else {
            tempValue['calories'] = null;
          };

          if (value.lunchbox) {
            tempLunch[value.uuid] = true;
          };
          if (value.dinneronly) {
            tempDinner[value.uuid] = true;
          };

          if (value.lunchselected) {
            tempLunchSel[value.uuid] = true;
          };
          if (value.dinnerselected) {
            tempDinnerSel[value.uuid] = true;
          };
          if (value.realsideselected) {
            tempRealSideSel[value.uuid] = true;
          };

          if (!value.side) {
            category = value.category.slice();
          };
          if (category === 'Starter') {
              starterList.push(tempValue);
          } else if (category === 'Main') {
              mainsList.push(tempValue);
          } else if (category === 'Dessert') {
              dessertsList.push(tempValue);
          } else if (category === 'Drink') {
              drinksList.push(tempValue);
          } else if (category === 'Fruit') {
              fruitsList.push(tempValue)
          };

        });
        setLunchBoxChecked(tempLunch);
        setDinnerOnlyChecked(tempDinner);

        setLunchSelectDict(tempLunchSel);
        setDinnerSelectDict(tempDinnerSel);
        setRealsideSelectDict(tempRealSideSel);

        if (starterList.length > 0) {
          const tempList1 = await Promise.all(starterList.map(async ( value ) => {
            // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', value.name);
            var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value.name);

            var ingredientString = await recipeStepsString(result.ing_steps);
            var ingredientFullString = await recipeFullStepsString(result.ing_steps);

            let tempRecord = value;
            tempRecord.apt_for_lunchbox = result.apt_for_lunchbox;
            tempRecord.cost = result.cost;
            tempRecord.calories = result.calorie;
            tempRecord.ingredients_list = ingredientString;
            tempRecord.ingredients_fulllist = ingredientFullString;
            tempRecord.raw_price = await addRawPrice(costpricelists, result, tempRecord.raw_price);
            return tempRecord
          }));
          starterList = tempList1;
        };
        setStarterOrderList(starterList);


        if (mainsList.length > 0) {
          const tempList1 = await Promise.all(mainsList.map(async ( value ) => {
            // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', value.name);
            var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value.name);

            var ingredientString = await recipeStepsString(result.ing_steps);
            var ingredientFullString = await recipeFullStepsString(result.ing_steps);

            let tempRecord = value;
            tempRecord.cost = result.cost;
            tempRecord.calories = result.calorie;
            tempRecord.apt_for_lunchbox = result.apt_for_lunchbox;
            tempRecord.ingredients_list = ingredientString;
            tempRecord.ingredients_fulllist = ingredientFullString;
            tempRecord.raw_price = await addRawPrice(costpricelists, result, tempRecord.raw_price);
            return tempRecord
          }));
          mainsList = tempList1;
        };
        setMainsOrderList(mainsList);

        if (dessertsList.length > 0) {
          const tempList1 = await Promise.all(dessertsList.map(async ( value ) => {
            // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', value.name);
            var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value.name);

            var ingredientString = await recipeStepsString(result.ing_steps);
            var ingredientFullString = await recipeFullStepsString(result.ing_steps);

            let tempRecord = value;
            tempRecord.cost = result.cost;
            tempRecord.calories = result.calorie;
            tempRecord.apt_for_lunchbox = result.apt_for_lunchbox;
            tempRecord.ingredients_list = ingredientString;
            tempRecord.ingredients_fulllist = ingredientFullString;
            tempRecord.raw_price = await addRawPrice(costpricelists, result, tempRecord.raw_price);
            return tempRecord
          }));
          dessertsList = tempList1;
        };        
        setDessertsOrderList(dessertsList);

        if (fruitsList.length > 0) {
          const tempList1 = await Promise.all(fruitsList.map(async ( value ) => {
            // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', value.name);
            var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value.name);

            var ingredientString = await recipeStepsString(result.ing_steps);
            var ingredientFullString = await recipeFullStepsString(result.ing_steps);

            let tempRecord = value;
            tempRecord.cost = result.cost;
            tempRecord.calories = result.calorie;
            tempRecord.apt_for_lunchbox = result.apt_for_lunchbox;
            tempRecord.ingredients_list = ingredientString;
            tempRecord.ingredients_fulllist = ingredientFullString;
            tempRecord.raw_price = await addRawPrice(costpricelists, result, tempRecord.raw_price);
            return tempRecord
          }));
          fruitsList = tempList1;
        };        
        setFruitsOrderList(fruitsList);

        if (drinksList.length > 0) {
          const tempList1 = await Promise.all(drinksList.map(async ( value ) => {
            // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', value.name);
            var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', value.name);

            var ingredientString = await recipeStepsString(result.ing_steps);
            var ingredientFullString = await recipeFullStepsString(result.ing_steps);

            let tempRecord = value;
            tempRecord.cost = result.cost;
            tempRecord.calories = result.calorie;
            tempRecord.apt_for_lunchbox = result.apt_for_lunchbox;
            tempRecord.ingredients_list = ingredientString;
            tempRecord.ingredients_fulllist = ingredientFullString;
            tempRecord.raw_price = await addRawPrice(costpricelists, result, tempRecord.raw_price);
            return tempRecord
          }));
          drinksList = tempList1;
        };
        setDrinksOrderList(drinksList);


    } else {
      setMainsOrderList([]);
      setStarterOrderList([]);
      setDessertsOrderList([]);
      setFruitsOrderList([]);
      setDrinksOrderList([]);

    };

  };

  const handleClearAll = () => {
      setMainsOrderList([]);
      setStarterOrderList([]);
      setDessertsOrderList([]);
      setFruitsOrderList([]);
      setDrinksOrderList([]); 

      let tempLunchDefaultTimeList = getTimeLunchList();
      let tempDinnerDefaultTimeList = getTimeDinnerList();
      setLunchDefaultTimeList(tempLunchDefaultTimeList);
      setDinnerDefaultTimeList(tempDinnerDefaultTimeList );

      setLunchTimeList([]);
      setDinnerTimeList([]);
      
      
  }

  async function handleLoadRecipes() {
    // YPCGetMenuDB(xget, setXGet).then(
    //   function(value) { setRecipeDB(value)
    //   }
    // );


    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesByCategoryURL', 'Starter');   // not used
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesWithTagsURL', '?category='+'Starter'+ tagString);
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', '?category='+'Starter'+ tagString);
    setStarterRecipes(result.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
      }));
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesWithTagsURL', '?category='+'Main'+tagString);
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', '?category='+'Main'+tagString);
    setMainRecipes(result.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
      }));
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesWithTagsURL', '?category='+'Drink'+tagString);
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', '?category='+'Drink'+tagString);
    setDrinkRecipes(result.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
      }));
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesWithTagsURL', '?category='+'Dessert'+tagString);
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', '?category='+'Dessert'+tagString);
    setDessertRecipes(result.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
      }));
    // var result = await YPCGetAxios(xget, setXGet, 'allRecipesWithTagsURL', '?category='+'Fruit'+tagString);
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallRecipesWithTagsURL', '?category='+'Fruit'+tagString);
    setFruitRecipes(result.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
      }));

    setRecipeDB(true);
    setMenuLoaded(true);
  };

  // async function handleTestButton() {
  //   var test = await YPCGetAxios(xget, setXGet, 'testMenuURL', '');
  //   let testit = {
  //     datestring: 'Test',
  //     menu: JSON.stringify([{value1: 2000, value2: 5000}])
  //   };
  //   YPCPutAxios2(setXPost, 'testMenuURL', testit, '');
  //   console.log('GGGGGGGZZZZZZ', test);
  // };

  const handleSaveMenu = () => {

    // console.log('SLSLSLSLSLSL', mainsOrderList);

    let globalOrderList = [];
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);

    let costDict = {};

    let sendList = [];
    globalOrderList.map((value) => {
        sendList.push({
            category: value.category,

            subcategory: value.subcategory,
            support_offline: value.support_offline,

            // cost: value.cost,
            dish: value.dish,
            id: value.id,
            name: value.name,
            name_french: value.name_french,
            price: value.price,
            calories: value.calories,
            side: value.side,
            uuid: value.uuid,
            dinneronly: (value.uuid in dinnerOnlyChecked ? dinnerOnlyChecked[value.uuid] : null), 
            lunchselected: (value.uuid in lunchSelectDict ? lunchSelectDict[value.uuid] : null), 
            dinnerselected: (value.uuid in dinnerSelectDict ? dinnerSelectDict[value.uuid] : null),
            realsideselected:  (value.uuid in realsideSelectDict ? realsideSelectDict[value.uuid] : null),
            lunchbox:  (value.uuid in lunchBoxChecked ? lunchBoxChecked[value.uuid] : null),
            spicelevel: value.spicelevel,
            vegetarian: value.vegetarian,
            vegan: value.vegan,
            sugar_free: value.sugar_free,
            gluten_free: value.gluten_free,
            peanut_free: value.peanut_free,
            baby_friendly: value.baby_friendly,
            apt_for_lunchbox: value.apt_for_lunchbox,
            ingredients_list: value.ingredients_list,
            ingredients_fulllist: value.ingredients_fulllist,
            lunch_cap: value.lunch_cap,
            chefs_choice: value.chefs_choice,
            dinner_cap: value.dinner_cap
          }
        );
        

        // costList.push({name: value.name, cost: value.cost});
        costDict[value.name] = {raw_price: value.raw_price, price: value.price, cost: value.cost };

    });

    console.log('SASASASA', sendList);

    console.log('LSLSLSLSLSL', costDict);

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];
    // YPCPutAxios(setXPost, 'conciergeSaveURL', sendList, dateString);





    let testit = {
      datestring: dateString,
      reason: 'blocked',
      misc: JSON.stringify({
        'daysBack': daysBack,
        'hourTime' : hourTime,
        'minuteTime' : minuteTime,
        'daysBackDinner': daysBackDinner,
        'hourTimeDinner' : hourTimeDinner,
        'minuteTimeDinner' : minuteTimeDinner
      })
    }
    YPCPutAxios2Alt(setXPost, 'saveMiscBlockedURL', testit, '');


    testit = {
      datestring: dateString,
      reason: 'timelists',
      misc: JSON.stringify({
        'lunchList': lunchTimeList,
        'dinnerList' : dinnerTimeList
      })
    }
    YPCPutAxios2Alt(setXPost, 'saveMiscTimeListsURL', testit, '');

    testit = {
      datestring: dateString,
      reason: 'costprice',
      misc: JSON.stringify(costDict)
    }
    YPCPutAxios2Alt(setXPost, 'saveMiscCostPriceListsURL', testit, '');



    testit = {
      datestring: dateString,
      menu: JSON.stringify(sendList)
    };

    YPCPutAxios2Alt(setXPost, 'saveMenuURL', testit, '');

 


  };

  const handleDateChange = (date) => {
    console.log('GGGGGGGGG', date);
    if (date !== selectedDate) {
      setSelectedDate(date);
    }
  };

  const handleLunchBoxCheckChange = (event, params) => {
    setLunchBoxChecked({...lunchBoxChecked, [event.target.ariaLabel]: params });
  };

  const handleDinnerCheckChange = (event, params) => {
    setDinnerOnlyChecked({...dinnerOnlyChecked, [event.target.ariaLabel]: params });
  };

  const handleDinnerSelectChange = (event, params) => {
    setDinnerSelectDict({...dinnerSelectDict, [event.target.ariaLabel]: params });
  };

  const handleLunchSelectChange = (event, params) => {
    setLunchSelectDict({...lunchSelectDict, [event.target.ariaLabel]: params });
  };

  const handleRealSideSelectChange = (event, params) => {
    setRealsideSelectDict({...realsideSelectDict, [event.target.ariaLabel]: params });
  };

  const handleChefsChoiceChange = (event, params) => {
    // setRealsideSelectDict({...realsideSelectDict, [event.target.ariaLabel]: params });
    // console.log('XTXTXTXTXTXT', event, params);

    let tempList = [];
    let tempValue = null;
    if (params.category == 'Starter') {
      starterOrderList.map((value) => {
        if (value.uuid === params.uuid) {
          tempValue = value;
          tempValue.chefs_choice = event.target.checked;
          tempList.push(tempValue);
        } else {
          tempList.push(value);
        }
      });
      setStarterOrderList(tempList);

    } else if (params.category == 'Main') {
      mainsOrderList.map((value) => {
        if (value.uuid === params.uuid) {
          tempValue = value;
          tempValue.chefs_choice = event.target.checked;
          tempList.push(tempValue);
        } else {
          tempList.push(value);
        }
      });
      setMainsOrderList(tempList);
    } else if (params.category == 'Dessert') {
      dessertsOrderList.map((value) => {
        if (value.uuid === params.uuid) {
          tempValue = value;
          tempValue.chefs_choice = event.target.checked;
          tempList.push(tempValue);
        } else {
          tempList.push(value);
        }
      });
      setDessertsOrderList(tempList);

    } else if (params.category == 'Drink') {
      drinksOrderList.map((value) => {
        if (value.uuid === params.uuid) {
          tempValue = value;
          tempValue.chefs_choice = event.target.checked;
          tempList.push(tempValue);
        } else {
          tempList.push(value);
        }
      });
      setDrinksOrderList(tempList);

    } else if (params.category == 'Fruit') {
      fruitsOrderList.map((value) => {
        if (value.uuid === params.uuid) {
          tempValue = value;
          tempValue.chefs_choice = event.target.checked;
          tempList.push(tempValue);
        } else {
          tempList.push(value);
        }
      });
      setFruitsOrderList(tempList);

    } else {
      return;
    }


  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  const allowEdit = () => {
    let globalOrderList = []
    globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
    let final = false;
    globalOrderList.map((value) => {
      // if (selectedRow === value.uuid) {
      if (!value.side && selectedRow === value.uuid) {
        final = true;
      }
    });
    return final;
  }



  const columns = [
    {
      field: 'dish',
      headerName: translate(props.language, 'Selection'),
      // headerName: 'Dish',
      width: 300,
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
          indent: params.row.side,
          noindent: !params.row.side,
      }),
      valueFormatter: (params) => {
        // const valueFormatted = params.value + ( Number.isInteger(params.row.spicelevel) ? ' (s' + spiceLookupToString[params.row.spicelevel] + ')' : '') + 
        const valueFormatted = params.value + ( Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 ? ' (s' + spiceLookupToString[params.row.spicelevel] + ')' : '') + 
            (params.row.vegetarian ? ' (v)' : '') + 
            (params.row.vegan ? ' (ve)' : '') + 
            (params.row.sugar_free ? ' (sf)' : '') +             
            (params.row.gluten_free ? ' (gf)' : '') + 
            (!params.row.peanut_free ? ' (p)' : '') + 
            (params.row.baby_friendly ? ' (bf)' : '');
        return `${valueFormatted}`;
      },
    },
    {
      field: 'actions',
      headerName: 'Rating',
      width: 150,
      renderCell: (params) => {
          if ( surveyResultDict.hasOwnProperty(params.row.dish)) {
              return (
                  <Rating 
                    name="star-rating" 
                    readOnly
                    value={surveyResultDict[params.row.dish]} 
                    // max={10}
                    precision={0.5}
                    // onChange={(event, value) => setReviewStars(value * 2)}
                    // disabled={robotTested && humanTested}
                />
              )
          } else {
            return ("")
          }
      },
    },
    {
      field: 'calories',
      headerName: 'Cal/Portion',
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value === null) {
          return ('N/A')
        } else {
          return (params.value.toFixed(1))       
        }
      },
    },
    {
      field: 'cost',
      // headerName: 'Cost/Portion',
      headerName: translate(props.language, 'Cost/Portion'),
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    },
    {
      field: 'price',
      // headerName: 'Price/Portion',
      headerName: translate(props.language, 'Price/Portion'),
      type: 'number',
      width: 140,
      sortable: false,
      // valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
      valueFormatter: (params) => {
        if (params.value === null) {
            return (null);
        }
        else {
            return (currencyFormatter.format(Number(params.value)))
        }
       },
    },
    // {
    //   field: 'lunchbox',
    //   // headerName: 'Lunchbox',
    //   headerName: translate(props.language, 'Lunchbox'),
    //   sortable: false,
    //   // editable: true,
    //   width: 120,
    //   renderCell: (params) => (
    //     <strong>

    //       {/* {console.log('LLLLLLLLLLLLL',params)} */}
    //       {!params.row.side ?
    //           <Checkbox
    //             style= {{color: '#78cbff'}}
    //             checked={lunchBoxChecked[params.id]}
    //             onChange={handleLunchBoxCheckChange}
    //             inputProps={{ 'aria-label': params.id }}
    //           /> : null
    //       }
    //     </strong>
    //   ),
    // },
    // {
    //   field: 'dinneronly',
    //   headerName: translate(props.language, 'Dinner Only'),
    //   sortable: false,
    //   // editable: true,
    //   width: 130,
    //   renderCell: (params) => (
    //     <strong>

    //       {/* {console.log('LLLLLLLLLLLLL',params)} */}
    //       {!params.row.side ?
    //           <Checkbox
    //             style= {{color: '#78cbff'}}
    //             // style={{backgroundColor: '#78cbff'}}
    //             checked={dinnerOnlyChecked[params.id]}
    //             onChange={handleDinnerCheckChange}
    //             inputProps={{ 'aria-label': params.id }}
    //           /> : null
    //       }
    //     </strong>
    //   ),
    // },
    {
      field: 'lunchselected',
      headerName: 'Lunch',
      sortable: false,
      // editable: true,
      width: 130,
      renderCell: (params) => (
        <strong>

          {/* {console.log('LLLLLLLLLLLLL',params)} */}
          {!params.row.side ?
              <Checkbox
                style= {{color: '#78cbff'}}
                // style={{backgroundColor: '#78cbff'}}
                checked={lunchSelectDict[params.id]}
                onChange={handleLunchSelectChange}
                inputProps={{ 'aria-label': params.id }}
              /> : null
          }
        </strong>
      ),
    },
    {
      field: 'dinnerselected',
      headerName: 'Dinner',
      sortable: false,
      // editable: true,
      width: 130,
      renderCell: (params) => (
        <strong>

          {/* {console.log('LLLLLLLLLLLLL',params)} */}
          {!params.row.side ?
              <Checkbox
                style= {{color: '#78cbff'}}
                // style={{backgroundColor: '#78cbff'}}
                checked={dinnerSelectDict[params.id]}
                onChange={handleDinnerSelectChange}
                inputProps={{ 'aria-label': params.id }}
              /> : null
          }
        </strong>
      ),
    },
    {
      field: 'realsideselected',
      headerName: 'Show Side',
      sortable: false,
      // editable: true,
      width: 130,
      renderCell: (params) => (
        <strong>

          {/* {console.log('LLLLLLLLLLLLL',params)} */}
          {params.row.side ?
              <Checkbox
                style= {{color: '#78cbff'}}
                // style={{backgroundColor: '#78cbff'}}
                checked={realsideSelectDict[params.id]}
                onChange={handleRealSideSelectChange}
                inputProps={{ 'aria-label': params.id }}
              /> : null
          }
        </strong>
      ),
    },

    {
      field: 'lunch_cap',
      // headerName: 'Cost/Portion',
      headerName: 'Lunch Cap',
      type: 'number',
      width: 140,
      sortable: false,
      // editable: true,
      editable: allowEdit(),
      // valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
      renderCell: (params) => (
        <div>
          {!params.row.side ?
            params.row.lunch_cap
            : null
          }
        </div>
      ),
    },    
    {
      field: 'dinner_cap',
      // headerName: 'Cost/Portion',
      headerName: 'Dinner Cap',
      type: 'number',
      width: 140,
      sortable: false,
      // editable: true,
      editable: allowEdit(),
      // valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
      renderCell: (params) => (
        <div>
          {!params.row.side ?
            params.row.dinner_cap
            : null
          }
        </div>
      ),

    },    
    {
      field: 'chefs_choice',
      headerName: "Chef's Choice",
      sortable: false,
      // editable: true,
      width: 130,
      renderCell: (params) => (
        <strong>

          {/* {console.log('LLLLLLLLLLLLL',params)} */}
          {!params.row.side ?
              <Checkbox
                style= {{color: '#78cbff'}}
                // style={{backgroundColor: '#78cbff'}}
                checked={params.row.chefs_choice}
                onChange={(e) => handleChefsChoiceChange(e, params.row)}
                inputProps={{ 'aria-label': params.id }}
              /> : null
          }
        </strong>
      ),
    },
    
  ];


  const printcolumns = [
    {
      field: 'dish',
      headerName: translate(props.language, 'Selection'),
      // headerName: 'Dish',
      width: 300,
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
          indent: params.row.side,
          noindent: !params.row.side,
      }),
      valueFormatter: (params) => {
        // const valueFormatted = params.value + ( Number.isInteger(params.row.spicelevel) ? ' (s' + spiceLookupToString[params.row.spicelevel] + ')' : '') + 
        const valueFormatted = params.value;
            // + ( Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 ? ' (s' + spiceLookupToString[params.row.spicelevel] + ')' : '') + 
            // (params.row.vegetarian ? ' (v)' : '') + 
            // (params.row.vegan ? ' (ve)' : '') + 
            // (params.row.gluten_free ? ' (gf)' : '') + 
            // (!params.row.peanut_free ? ' (p)' : '') + 
            // (params.row.baby_friendly ? ' (bf)' : '');
        return `${valueFormatted}`;
      },
    },
    {
      field: 'name_french',
      headerName: translate(props.language, 'Selection (fr)'),
      // headerName: 'Dish',
      width: 300,
      sortable: false,
      cellClassName: (params) =>  // you could use params.value or params.row.side
      clsx('super-app', {
          indent: params.row.side,
          noindent: !params.row.side,
      }),
      valueFormatter: (params) => {
        const valueFormatted = params.value;
        return `${valueFormatted}`;
      },
    },
    {
      field: 'tags',
      headerName: 'Tags',
      // headerName: 'Dish',
      width: 150,
      sortable: false,
      valueFormatter: (params) => {
        const valueFormatted = ( Number.isInteger(params.row.spicelevel) && params.row.spicelevel > 0 ? ' (s' + spiceLookupToString[params.row.spicelevel] + ')' : '') + 
            (params.row.vegetarian ? ' (v)' : '') + 
            (params.row.vegan ? ' (ve)' : '') + 
            (params.row.sugar_free ? ' (sf)' : '') +             
            (params.row.gluten_free ? ' (gf)' : '') + 
            (!params.row.peanut_free ? ' (p)' : '') + 
            (params.row.baby_friendly ? ' (bf)' : '');
        return `${valueFormatted}`;
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 120,
      sortable: false,
    },    
    {
      field: 'subcategory',
      headerName: 'Subcategory',
      width: 120,
      sortable: false,
    },
    {
      field: 'support_offline',
      headerName: 'Offline',
      width: 120,
      sortable: false,
      valueFormatter: (params) => {
        return params.value === true ? 'Yes' : 'No'
       },
    },    


    {
      field: 'cost',
      // headerName: 'Cost/Portion',
      headerName: translate(props.language, 'Cost/Portion'),
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    },
    {
      field: 'price',
      // headerName: 'Price/Portion',
      headerName: translate(props.language, 'Price/Portion'),
      type: 'number',
      width: 140,
      sortable: false,
      // valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
      valueFormatter: (params) => {
        if (params.value === null) {
            return (null);
        }
        else {
            return (currencyFormatter.format(Number(params.value)))
        }
       },
    },
    // {
    //   field: 'lunchbox',
    //   // headerName: 'Lunchbox',
    //   headerName: translate(props.language, 'Lunchbox'),
    //   sortable: false,
    //   editable: false,
    //   type: 'string',
    //   width: 120,
    //   valueFormatter: (params) => {
    //     if (params.row.side) {
    //         return (null);
    //     }
    //     else {
    //         return (lunchBoxChecked[params.id]? 'Yes' : 'No')
    //     }
    //    },
    // },
    // {
    //   field: 'dinneronly',
    //   headerName: translate(props.language, 'Dinner Only'),
    //   sortable: false,
    //   editable: false,
    //   type: 'string',
    //   width: 130,
    //   valueFormatter: (params) => {
    //     if (params.row.side) {
    //         return (null);
    //     }
    //     else {
    //         return (dinnerOnlyChecked[params.id]? 'Yes' : 'No')
    //     }
    //    },
    // },
    {
      field: 'lunchselected',
      headerName: 'Lunch',
      sortable: false,
      editable: false,
      type: 'string',
      width: 130,
      valueFormatter: (params) => {
        if (params.row.side) {
            return (null);
        }
        else {
            return (lunchSelectDict[params.id]? 'Yes' : 'No')
        }
       },
    },
    {
      field: 'dinnerselected',
      headerName: 'Dinner',
      sortable: false,
      editable: false,
      type: 'string',
      width: 130,
      valueFormatter: (params) => {
        if (params.row.side) {
            return (null);
        }
        else {
            return (dinnerSelectDict[params.id]? 'Yes' : 'No')
        }
       },
    },
  ];

  const senseColumns = [
    {
      field: 'costtype',
      // headerName: 'Cost Type',
      headerName: translate(props.language, 'Price Type'),
      width: 300,
      sortable: false,
    },
    {
      field: 'cost',
      // headerName: 'Cost',
      headerName: translate(props.language, 'Price'),
      type: 'number',
      width: 140,
      sortable: false,
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    }
  ];


  async function handleComboClose() {

    let totalPrice = 0.0;

    if (comboValue !== null) {
        let compvalue = comboValue.split(" , ");
        // let newValues = [];

        const newValues = await Promise.all(compvalue.map(async ( cvalue ) => {
        // compvalue.map((cvalue) => {
          if(cvalue !== null) {
            // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', cvalue);
            var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', cvalue);

            if (result !== null) {
              var ingredientString = await recipeStepsString(result.ing_steps);
              var ingredientFullString = await recipeFullStepsString(result.ing_steps);
            };

            // irv
            // totalPrice = totalPrice + result.cost;
            totalPrice = totalPrice + result.cost * markupMultiplier['Side'];

            if (result !== null) {
              let id = uuidv4();
              // let newValue = {
              return {
                  uuid: id,
                  id: id,
                  name: result.name,
                  name_french: result.name_french,
                  dish: result.name,
                  cost: result.cost,

                  // price: cost + starterMarkup,
                  // price: Math.ceil((result.cost + markups['mains'])*10)/10,
                  price: null,
                  raw_price: Math.ceil((result.cost * markupMultiplier['Side'])*10)/10,
                  side: true,
                  category: result.category,

                  calories: result.calorie,

                  support_offline: result.support_offline,

                  vegetarian: result.vegetarian,
                  vegan: result.vegan,
                  sugar_free: result.sugar_free,
                  spicelevel: result.spice_level,
                  apt_for_lunchbox: result.apt_for_lunchbox,
                  baby_friendly: result.baby_friendly,
                  cuisine: result.cuisine,
                  gluten_free: result.gluten_free,
                  name_french: result.name_french,
                  peanut_free: result.peanut_free,
                  percent_automation: result.percent_automation,
                  portion: result.portion,
                  review_stars: result.review_stars,
                  shelf_life: result.shelf_life,
                  subcategory: result.subcategory,
                  total_cooking_time: result.total_cooking_time,
                  ingredients_list: ingredientString,
                  ingredients_fulllist: ingredientFullString,
                  lunch_cap: null,
                  dinner_cap: null,
                  chefs_choice: false
              };

              // newValues.push(newValue);
            };
          };
        }));



        if (comboChoice === 'mains') {
            let finalValues = []
            mainsOrderList.map((value, count) => {
              if (count === listPointer) {

                let localMarkup = markupMultiplier['Main'];
                if (value.vegetarian) {
                  localMarkup = markupMultiplier['MainVeg'];
                };

                finalValues.push(
                      {
                        uuid: value.id,
                        id: value.id,
                        name: value.name,
                        dish: value.name,
                        cost: value.cost,
                        // price: Math.ceil((value.cost + totalPrice + markups['mains'])*10)/10,

                        price: Math.ceil((value.cost * localMarkup + totalPrice)*10)/10,
                        raw_price: Math.ceil((value.cost * localMarkup)*10)/10,
                        side: value.side,
                        category: value.category,

                        calories: value.calories,

                        support_offline: value.support_offline,

                        vegetarian: value.vegetarian,
                        vegan: value.vegan,
                        sugar_free: value.sugar_free,
                        spicelevel: value.spicelevel,
                        apt_for_lunchbox: value.apt_for_lunchbox,
                        baby_friendly: value.baby_friendly,
                        cuisine: value.cuisine,
                        gluten_free: value.gluten_free,
                        name_french: value.name_french,
                        peanut_free: value.peanut_free,
                        percent_automation: value.percent_automation,
                        portion: value.portion,
                        review_stars: value.review_stars,
                        shelf_life: value.shelf_life,
                        subcategory: value.subcategory,
                        total_cooking_time: value.total_cooking_time,
                        ingredients_list: value.ingredients_list,
                        ingredients_fulllist: value.ingredients_fulllist,
                        lunch_cap: value.lunch_cap,
                        dinner_cap: value.dinner_cap,
                        chefs_choice: value.chefs_choice
                    }
                );
              } else {
                finalValues.push(value);
              };
            });
            newValues.map((value) => {
              finalValues.push(value);
            });
            setMainsOrderList(finalValues);
        } else if (comboChoice === "starters") {
            let finalValues = []
            starterOrderList.map((value, count) => {
              if (count === listPointer) {
                finalValues.push(
                      {
                        uuid: value.id,
                        id: value.id,
                        name: value.name,
                        dish: value.name,
                        cost: value.cost,
                        // price: Math.ceil((value.cost + totalPrice + markups['starter'])*10)/10,
                        price: Math.ceil((value.cost * markupMultiplier['Starter'] + totalPrice)*10)/10,
                        raw_price: Math.ceil((value.cost * markupMultiplier['Starter'])*10)/10,
                        side: value.side,
                        category: value.category,

                        calories: value.calories,

                        support_offline: value.support_offline,

                        vegetarian: value.vegetarian,
                        vegan: value.vegan,
                        sugar_free: value.sugar_free,
                        spicelevel: value.spicelevel,
                        apt_for_lunchbox: value.apt_for_lunchbox,
                        baby_friendly: value.baby_friendly,
                        cuisine: value.cuisine,
                        gluten_free: value.gluten_free,
                        name_french: value.name_french,
                        peanut_free: value.peanut_free,
                        percent_automation: value.percent_automation,
                        portion: value.portion,
                        review_stars: value.review_stars,
                        shelf_life: value.shelf_life,
                        subcategory: value.subcategory,
                        total_cooking_time: value.total_cooking_time,
                        ingredients_list: value.ingredients_list,
                        ingredients_fulllist: value.ingredients_fulllist,
                        lunch_cap: value.lunch_cap,
                        dinner_cap: value.dinner_cap,
                        chefs_choice: value.chefs_choice
                    }
                );
              } else {
                finalValues.push(value);
              };

            });
            newValues.map((value) => {
              finalValues.push(value);
            });
            setStarterOrderList(finalValues);
        } else if (comboChoice === "desserts") {
          let finalValues = []
          dessertsOrderList.map((value, count) => {
            if (count === listPointer) {
              finalValues.push(
                    {
                      uuid: value.id,
                      id: value.id,
                      name: value.name,
                      dish: value.name,
                      cost: value.cost,
                      // price: Math.ceil((value.cost + totalPrice + markups['desserts'])*10)/10,
                      price: Math.ceil((value.cost * markupMultiplier['Dessert'] + totalPrice)*10)/10,
                      raw_price: Math.ceil((value.cost * markupMultiplier['Dessert'])*10)/10,
                      side: value.side,
                      category: value.category,

                      calories: value.calories,

                      support_offline: value.support_offline,

                      vegetarian: value.vegetarian,
                      vegan: value.vegan,
                      sugar_free: value.sugar_free,
                      spicelevel: value.spicelevel,
                      apt_for_lunchbox: value.apt_for_lunchbox,
                      baby_friendly: value.baby_friendly,
                      cuisine: value.cuisine,
                      gluten_free: value.gluten_free,
                      name_french: value.name_french,
                      peanut_free: value.peanut_free,
                      percent_automation: value.percent_automation,
                      portion: value.portion,
                      review_stars: value.review_stars,
                      shelf_life: value.shelf_life,
                      subcategory: value.subcategory,
                      total_cooking_time: value.total_cooking_time,
                      ingredients_list: value.ingredients_list,
                      ingredients_fulllist: value.ingredients_fulllist,
                      lunch_cap: value.lunch_cap,
                      dinner_cap: value.dinner_cap,
                      chefs_choice: value.chefs_choice
                  }
              );
            } else {
              finalValues.push(value);
            };
          });
          newValues.map((value) => {
            finalValues.push(value);
          });
          setDessertsOrderList(finalValues);
        } else if (comboChoice === "fruits") {
          let finalValues = []
          fruitsOrderList.map((value, count) => {
            if (count === listPointer) {
              finalValues.push(
                    {
                      uuid: value.id,
                      id: value.id,
                      name: value.name,
                      dish: value.name,
                      cost: value.cost,
                      // price: Math.ceil((value.cost + totalPrice + markups['fruits'])*10)/10,
                      price: Math.ceil((value.cost * markupMultiplier['Fruit'] + totalPrice)*10)/10,
                      raw_price: Math.ceil((value.cost * markupMultiplier['Fruit'])*10)/10,
                      side: value.side,
                      category: value.category,

                      calories: value.calories,

                      support_offline: value.support_offline,

                      vegetarian: value.vegetarian,
                      vegan: value.vegan,
                      sugar_free: value.sugar_free,
                      spicelevel: value.spicelevel,
                      apt_for_lunchbox: value.apt_for_lunchbox,
                      baby_friendly: value.baby_friendly,
                      cuisine: value.cuisine,
                      gluten_free: value.gluten_free,
                      name_french: value.name_french,
                      peanut_free: value.peanut_free,
                      percent_automation: value.percent_automation,
                      portion: value.portion,
                      review_stars: value.review_stars,
                      shelf_life: value.shelf_life,
                      subcategory: value.subcategory,
                      total_cooking_time: value.total_cooking_time,
                      ingredients_list: value.ingredients_list,
                      ingredients_fulllist: value.ingredients_fulllist,
                      lunch_cap: value.lunch_cap,
                      dinner_cap: value.dinner_cap,
                      chefs_choice: value.chefs_choice
                  }
              );
            } else {
              finalValues.push(value);
            };
          });
          newValues.map((value) => {
            finalValues.push(value);
          });
          setFruitsOrderList(finalValues);
        }  else if (comboChoice === "drinks") {
          let finalValues = []
          drinksOrderList.map((value, count) => {
            if (count === listPointer) {
              finalValues.push(
                    {
                      uuid: value.id,
                      id: value.id,
                      name: value.name,
                      dish: value.name,
                      cost: value.cost,
                      // price: Math.ceil((value.cost + totalPrice + markups['drinks'])*10)/10,
                      price: Math.ceil((value.cost * markupMultiplier['Drink'] + totalPrice)*10)/10,
                      raw_price: Math.ceil((value.cost * markupMultiplier['Drink'])*10)/10,
                      side: value.side,
                      category: value.category,

                      calories: value.calories,

                      support_offline: value.support_offline,

                      vegetarian: value.vegetarian,
                      vegan: value.vegan,
                      sugar_free: value.sugar_free,
                      spicelevel: value.spicelevel,
                      apt_for_lunchbox: value.apt_for_lunchbox,
                      baby_friendly: value.baby_friendly,
                      cuisine: value.cuisine,
                      gluten_free: value.gluten_free,
                      name_french: value.name_french,
                      peanut_free: value.peanut_free,
                      percent_automation: value.percent_automation,
                      portion: value.portion,
                      review_stars: value.review_stars,
                      shelf_life: value.shelf_life,
                      subcategory: value.subcategory,
                      total_cooking_time: value.total_cooking_time,
                      ingredients_list: value.ingredients_list,
                      ingredients_fulllist: value.ingredients_fulllist,
                      lunch_cap: value.lunch_cap,
                      dinner_cap: value.dinner_cap,
                      chefs_choice: value.chefs_choice
                  }
              );
            } else {
              finalValues.push(value);
            };
          });
          newValues.map((value) => {
            finalValues.push(value);
          });
          setFruitsOrderList(finalValues);
        };
    };


    setComboOpen(false);
    setCompositionList(null);
  };

  const handleComboCancel = () => {
    setComboOpen(false);
    setCompositionList(null);

  }


  function ComboSelect() {
    return (
      <div>
        <Dialog open={comboOpen} onClose={handleComboClose}>
          <DialogTitle>{translate(props.language, 'Choose Side Combination')}</DialogTitle>
          <DialogContent>
          <Autocomplete
              onChange={(event, value) => setComboValue(value)}
              id="combo-box1"
              options={compositionList}
              // getOptionLabel={(option) => option.name}
              // style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label={translate(props.language, 'Select')} InputLabelProps={{
                  shrink: true,
              }}
              // variant="outlined"
              />} 
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleComboCancel} color="primary">
              {translate(props.language, 'Cancel')}
            </Button>
            <Button onClick={handleComboClose} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function getCommaDelList(compValue) {
    let list1 = '';
    let list2 = '';
    let list3 = '';
    compValue.map((value) => {
      if (value.composition_num === 1) {
        if (list1 !== '') {
          list1 = list1 + ' , ';
        }
        list1 = list1 + value.side_recipe_name;
      };
      if (value.composition_num === 2) {
        if (list2 !== '') {
          list2 = list2 + ' , ';
        }
        list2 = list2 + value.side_recipe_name;
      };
      if (value.composition_num === 3) {
        if (list3 !== '') {
          list3 = list3 + ' , ';
        }
        list3 = list3 + value.side_recipe_name;
      };
    });
  
    let finalList = [];
    if (list1 !== '') {
      finalList.push(list1);
    }; 
    if (list2 !== '') {
      finalList.push(list2);
    };
    if (list3 !== '') {
      finalList.push(list3);
    };
  
    return finalList;
  
  };  


  function calculateSenseTable() {

    function getMin(OrderList) {
      let workList = [];
      OrderList.map((value) => {
        if (!value.side) {
          workList.push(value.price);
        };
      });
      let minCost = 0.0
      let tempList = workList.slice();
      tempList.push(1000);
      if (tempList.length !== 1) {
        minCost = Math.min.apply(Math, tempList);
      };    
      return minCost;
    };

    function getMax(OrderList) {
      let workList = [];
      OrderList.map((value) => {
        if (!value.side) {
          workList.push(value.price);
        };
      });
      let maxCost = 0.0
      let tempList = workList.slice();
      tempList.push(-10.0);
      if (tempList.length !== 1) {
        maxCost = Math.max.apply(Math, tempList);
      };    
      return maxCost;
    };

    let minMainDishCost = getMin(mainsOrderList);    
    let maxMainDishCost = getMax(mainsOrderList);    
    let minStarterDishCost = getMin(starterOrderList);    
    let maxStarterDishCost = getMax(starterOrderList);    
    let minDessertsDishCost = getMin(dessertsOrderList);    
    let maxDessertsDishCost = getMax(dessertsOrderList);    
    let minDrinksDishCost = getMin(drinksOrderList);    
    let maxDrinksDishCost = getMax(drinksOrderList);    
    let minFruitsDishCost = getMin(fruitsOrderList);    
    let maxFruitsDishCost = getMax(fruitsOrderList);    
    let minAllDishCost = minMainDishCost + minStarterDishCost + minDessertsDishCost + minDrinksDishCost + minFruitsDishCost;
    let maxAllDishCost = maxMainDishCost + maxStarterDishCost + maxDessertsDishCost + maxDrinksDishCost + maxFruitsDishCost;


    setSenseList([{id: 1, costtype: translate(props.language,"Min Main Dish CU"), cost: minMainDishCost},
                  {id: 2, costtype: translate(props.language,"Max Main Dish CU"), cost: maxMainDishCost},
                  {id: 3, costtype: translate(props.language,"Min 4 Course Meal"), cost: minAllDishCost},
                  {id: 4, costtype: translate(props.language,"Max 4 Course Meal"), cost: maxAllDishCost}
              ]);

  };



  function SenseTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={senseList}
          columns={senseColumns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleFruitsSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <div>
              </div>
            )
          }}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  const GeneralDelete = (genList, selectDelete) => {
    let newOrderList = [];
    let toDelete = false;
    genList.map((value) => {
      if(selectDelete === value.uuid && !value.side) {
        toDelete = true;
      } else if (selectDelete !== value.uuid && !value.side) {
        toDelete = false;
      }
      if (!toDelete) {
        newOrderList.push(value);
      }
    });
    return newOrderList;
  };

  // Start Functions Section

  function StarterTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={starterOrderList}
          columns={columns}
          onCellEditCommit={handleCellEditCommitStarter}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleStarterSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay className={classes.gridoverlay}>
                <div className={classes.label}>
                  Add Starter
                </div>
              </GridOverlay>
            )
          }}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  
  const handleCellEditCommitStarter = React.useCallback(
    ({ id, field, value }) => {
        const updatedRows = starterOrderList.map((row) => {
          if (row.id === id && row.category !== 'Side') {
            let tempRow = row;
            tempRow[field] = value;
            return tempRow;
          } else {
            return row;
          };
        });
        setStarterOrderList(updatedRows);
    },
    [starterOrderList],
  );


  const handleStarterSelect = (params) => {
    if (!params.row.side) {
      setSelectStarterDelete(params.row.uuid);
    } else {
      setSelectStarterDelete("");
    };
    setSelectedRow(params.row.uuid);
  };

  const handleStarterDelete = () => {
    // let newOrderList = [];
    // starterOrderList.map((value) => {
    //     if(selectStarterDelete !== value.uuid) {
    //         newOrderList.push(value);
    //     }
    // });
    // setStarterOrderList(newOrderList);
    setStarterOrderList(GeneralDelete(starterOrderList, selectStarterDelete));
  };

  async function handleStarterMoveRight() {
    if (selectedStarterValue !== null) {
        // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', selectedStarterValue);
        var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', selectedStarterValue);

        var ingredientString = await recipeStepsString(result.ing_steps);
        var ingredientFullString = await recipeFullStepsString(result.ing_steps);

        let composition = result.sides_combos;

        let id = uuidv4();
        let newValue = {
            uuid: id,
            id: id,
            name: result.name,
            name_french: result.name_french,
            dish: result.name,
            cost: result.cost,
            // price: cost + starterMarkup,
            // price: Math.ceil((result.cost + markups['starter'])*10)/10,
            price: Math.ceil((result.cost * markupMultiplier['Starter'])*10)/10,
            raw_price: Math.ceil((result.cost * markupMultiplier['Starter'])*10)/10,
            side: false,
            category: result.category,

            calories: result.calorie,

            support_offline: result.support_offline,

            vegetarian: result.vegetarian,
            vegan: result.vegan,
            sugar_free: result.sugar_free,
            spicelevel: result.spice_level,
            apt_for_lunchbox: result.apt_for_lunchbox,
            baby_friendly: result.baby_friendly,
            cuisine: result.cuisine,
            gluten_free: result.gluten_free,
            name_french: result.name_french,
            peanut_free: result.peanut_free,
            percent_automation: result.percent_automation,
            portion: result.portion,
            review_stars: result.review_stars,
            shelf_life: result.shelf_life,
            subcategory: result.subcategory,
            total_cooking_time: result.total_cooking_time,
            ingredients_list: ingredientString,
            ingredients_fulllist: ingredientFullString,
            lunch_cap: 0,
            dinner_cap: 0,
            chefs_choice: false
        };

        let newValues = []
        starterOrderList.map((value) => {
          newValues.push(value);
        });
        newValues.push(newValue);

        if (composition.length > 0) {
          setComboChoice('starters');
          // setCompositionList(composition);
          setCompositionList(getCommaDelList(composition));
          setComboOpen(true);
        };


        let lunchbox = {};
        lunchbox[id] = false;
        setLunchBoxChecked(Object.assign({},lunchBoxChecked,lunchbox));

        let dinneronly = {};
        dinneronly[id] = false;
        setDinnerOnlyChecked(Object.assign({},dinnerOnlyChecked,dinneronly));     
        
        let lunchdict = {};
        lunchdict[id] = false;
        setLunchSelectDict(Object.assign({},lunchSelectDict,lunchdict));     
        
        let dinnerdict = {};
        dinnerdict[id] = false;
        setDinnerSelectDict(Object.assign({},dinnerSelectDict,dinnerdict));     

        let realsidedict = {};
        realsidedict[id] = false;
        setRealsideSelectDict(Object.assign({},realsideSelectDict,realsidedict));     

        setListPointer(starterOrderList.length);
        setStarterOrderList(newValues);

    };
  };



  // End of Starters Section


  // Mains Functions Section

 
  function MainsTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
  
          className={classes.gridcell}
          hideFooter={true}
          hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={mainsOrderList}
          onCellEditCommit={handleCellEditCommitMains}
          // rows={mainsAltOrderList}
          columns={columns}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          onRowClick={handleMainsSelect}
          // headerHeight={0}
          autoHeight={true}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay className={classes.gridoverlay}>
                <div className={classes.label}>
                  Add Main
                </div>
              </GridOverlay>
            )
          }}
          // components={{
          //   NoRowsOverlay: () => (
          //     <div>
          //     </div>
          //   )
          // }}
          // components={{
          //   Footer: () => <div></div>,
          // }}
          // componentsProps={{Footer:  () => <div>This is my footer</div>,
          //                   }}
        />
      </div>
    );
  };

  const handleCellEditCommitMains = React.useCallback(
    ({ id, field, value }) => {
        const updatedRows = mainsOrderList.map((row) => {
          if (row.id === id && row.category !== 'Side') {
            let tempRow = row;
            tempRow[field] = value;
            return tempRow;
          } else {
            return row;
          };
        });
        setMainsOrderList(updatedRows);
    },
    [mainsOrderList],
  );  
  

  const handleMainsSelect = (params) => {
    if (!params.row.side) {
      setSelectMainsDelete(params.row.uuid);
    } else {
      setSelectMainsDelete("");
    };
    setSelectedRow(params.row.uuid);
  };




  const handleMainsDelete = () => {
    // let newOrderList = [];
    // mainsOrderList.map((value) => {
    //     if(selectMainsDelete !== value.uuid) {
    //         newOrderList.push(value);
    //     }
    // });
    // setMainsOrderList(newOrderList);
    setMainsOrderList(GeneralDelete(mainsOrderList, selectMainsDelete));
  };


  async function handleMainsMoveRight() {
    if (selectedMainsValue !== null) {
        // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', selectedMainsValue);
        var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', selectedMainsValue);

        var ingredientString = await recipeStepsString(result.ing_steps);
        var ingredientFullString = await recipeFullStepsString(result.ing_steps);

        let composition = result.sides_combos;

        let localMarkup = markupMultiplier['Main']
        if (result.vegetarian) {
          localMarkup = markupMultiplier['MainVeg'];
        };

        let id = uuidv4();
        let newValue = {
            uuid: id,
            id: id,
            name: result.name,
            name_french: result.name_french,
            dish: result.name,
            cost: result.cost,
            // price: cost + starterMarkup,
            // price: Math.ceil((result.cost + markups['mains'])*10)/10,
            price: Math.ceil((result.cost * localMarkup)*10)/10,
            raw_price: Math.ceil((result.cost * localMarkup)*10)/10,
            side: false,
            category: result.category,

            calories: result.calorie,

            support_offline: result.support_offline,

            vegetarian: result.vegetarian,
            vegan: result.vegan,
            sugar_free: result.sugar_free,
            spicelevel: result.spice_level,
            apt_for_lunchbox: result.apt_for_lunchbox,
            baby_friendly: result.baby_friendly,
            cuisine: result.cuisine,
            gluten_free: result.gluten_free,
            name_french: result.name_french,
            peanut_free: result.peanut_free,
            percent_automation: result.percent_automation,
            portion: result.portion,
            review_stars: result.review_stars,
            shelf_life: result.shelf_life,
            subcategory: result.subcategory,
            total_cooking_time: result.total_cooking_time,
            ingredients_list: ingredientString,
            ingredients_fulllist: ingredientFullString,
            lunch_cap: 0,
            dinner_cap: 0,
            chefs_choice: false
        };


        let newValues = []
        mainsOrderList.map((value) => {
          newValues.push(value);
        });
        newValues.push(newValue);

        if (composition.length > 0) {
          setComboChoice('mains');
          // setCompositionList(composition);
          setCompositionList(getCommaDelList(composition));
          setComboOpen(true);
        };


        let lunchbox = {};
        lunchbox[id] = false;
        setLunchBoxChecked(Object.assign({},lunchBoxChecked,lunchbox));
        let dinneronly = {};
        dinneronly[id] = false;
        setDinnerOnlyChecked(Object.assign({},dinnerOnlyChecked,dinneronly));  

        let lunchdict = {};
        lunchdict[id] = false;
        setLunchSelectDict(Object.assign({},lunchSelectDict,lunchdict));     
        
        let dinnerdict = {};
        dinnerdict[id] = false;
        setDinnerSelectDict(Object.assign({},dinnerSelectDict,dinnerdict));     

        let realsidedict = {};
        realsidedict[id] = false;
        setRealsideSelectDict(Object.assign({},realsideSelectDict,realsidedict));     

        setListPointer(mainsOrderList.length);
        setMainsOrderList(newValues);

    };

  };


// end of Mains Section


// start of Desserts Section

function DessertsTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid

        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={dessertsOrderList}
        onCellEditCommit={handleCellEditCommitDesserts}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleDessertsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <GridOverlay className={classes.gridoverlay}>
              <div className={classes.label}>
                Add Dessert
              </div>
            </GridOverlay>
          )
        }}
        // components={{
        //   NoRowsOverlay: () => (
        //     <div>
        //     </div>
        //   )
        // }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};

const handleCellEditCommitDesserts = React.useCallback(
  ({ id, field, value }) => {
      const updatedRows = dessertsOrderList.map((row) => {
        if (row.id === id && row.category !== 'Side') {
          let tempRow = row;
          tempRow[field] = value;
          return tempRow;
        } else {
          return row;
        };
      });
      setDessertsOrderList(updatedRows);
  },
  [dessertsOrderList],
);

const handleDessertsSelect = (params) => {
  if (!params.row.side) {
    setSelectDessertsDelete(params.row.uuid);
  } else {
    setSelectDessertsDelete("");
  };
  setSelectedRow(params.row.uuid);
};

const handleDessertsDelete = () => {
  // let newOrderList = [];
  // dessertsOrderList.map((value) => {
  //     if(selectDessertsDelete !== value.uuid) {
  //         newOrderList.push(value);
  //     }
  // });
  // setDessertsOrderList(newOrderList);
  setDessertsOrderList(GeneralDelete(dessertsOrderList, selectDessertsDelete));
};


async function handleDessertsMoveRight() {
  if (selectedDessertsValue !== null) {
      // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', selectedDessertsValue);
      var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', selectedDessertsValue);

      var ingredientString = await recipeStepsString(result.ing_steps);
      var ingredientFullString = await recipeFullStepsString(result.ing_steps);

      let composition = result.sides_combos;


      let id = uuidv4();
      let newValue = {
          uuid: id,
          id: id,
          name: result.name,
          name_french: result.name_french,
          dish: result.name,
          cost: result.cost,
          // price: cost + starterMarkup,
          // price: Math.ceil((result.cost + markups['desserts'])*10)/10,
          price: Math.ceil((result.cost * markupMultiplier['Dessert'])*10)/10,
          raw_price: Math.ceil((result.cost * markupMultiplier['Dessert'])*10)/10,
          side: false,
          category: result.category,

          calories: result.calorie,

          support_offline: result.support_offline,

          vegetarian: result.vegetarian,
          vegan: result.vegan,
          sugar_free: result.sugar_free,
          spicelevel: result.spice_level,
          apt_for_lunchbox: result.apt_for_lunchbox,
          baby_friendly: result.baby_friendly,
          cuisine: result.cuisine,
          gluten_free: result.gluten_free,
          name_french: result.name_french,
          peanut_free: result.peanut_free,
          percent_automation: result.percent_automation,
          portion: result.portion,
          review_stars: result.review_stars,
          shelf_life: result.shelf_life,
          subcategory: result.subcategory,
          total_cooking_time: result.total_cooking_time,
          ingredients_list: ingredientString,
          ingredients_fulllist: ingredientFullString,
          lunch_cap: 0,
          dinner_cap: 0,
          chefs_choice: false
      };


      let newValues = []
      dessertsOrderList.map((value) => {
        newValues.push(value);
      });
      newValues.push(newValue);

      if (composition.length > 0) {
        setComboChoice('desserts');
        // setCompositionList(composition);
        setCompositionList(getCommaDelList(composition));
        setComboOpen(true);
      };


      let lunchbox = {};
      lunchbox[id] = false;
      setLunchBoxChecked(Object.assign({},lunchBoxChecked,lunchbox));
      let dinneronly = {};
      dinneronly[id] = false;
      setDinnerOnlyChecked(Object.assign({},dinnerOnlyChecked,dinneronly));        

      let lunchdict = {};
      lunchdict[id] = false;
      setLunchSelectDict(Object.assign({},lunchSelectDict,lunchdict));     
      
      let dinnerdict = {};
      dinnerdict[id] = false;
      setDinnerSelectDict(Object.assign({},dinnerSelectDict,dinnerdict));     

      let realsidedict = {};
      realsidedict[id] = false;
      setRealsideSelectDict(Object.assign({},realsideSelectDict,realsidedict));     

      setListPointer(dessertsOrderList.length);
      setDessertsOrderList(newValues);

  };
};



// end of Desserts Section


// start of Drinks section


function DrinksTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid

        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={drinksOrderList}
        onCellEditCommit={handleCellEditCommitDrinks}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleDrinksSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <GridOverlay className={classes.gridoverlay}>
              <div className={classes.label}>
                Add Drink
              </div>
            </GridOverlay>
          )
        }}
        // components={{
        //   NoRowsOverlay: () => (
        //     <div>
        //     </div>
        //   )
        // }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};


const handleCellEditCommitDrinks = React.useCallback(
  ({ id, field, value }) => {
      const updatedRows = drinksOrderList.map((row) => {
        if (row.id === id && row.category !== 'Side') {
          let tempRow = row;
          tempRow[field] = value;
          return tempRow;
        } else {
          return row;
        };
      });
      setDrinksOrderList(updatedRows);
  },
  [drinksOrderList],
);


const handleDrinksSelect = (params) => {
  if (!params.row.side) {
    setSelectDrinksDelete(params.row.uuid);
  } else {
    setSelectDrinksDelete("");
  };
  setSelectedRow(params.row.uuid);
};

const handleDrinksDelete = () => {
  // let newOrderList = [];
  // drinksOrderList.map((value) => {
  //     if(selectDrinksDelete !== value.uuid) {
  //         newOrderList.push(value);
  //     }
  // });
  // setDrinksOrderList(newOrderList);
  setDrinksOrderList(GeneralDelete(drinksOrderList, selectDrinksDelete));
};


async function handleDrinksMoveRight() {
  if (selectedDrinksValue !== null) {
      // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', selectedDrinksValue);
      var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', selectedDrinksValue);

      var ingredientString = await recipeStepsString(result.ing_steps);
      var ingredientFullString = await recipeFullStepsString(result.ing_steps);

      let composition = result.sides_combos;

      let id = uuidv4();
      let newValue = {
          uuid: id,
          id: id,
          name: result.name,
          name_french: result.name_french,
          dish: result.name,
          cost: result.cost,
          // price: cost + starterMarkup,
          // price: Math.ceil((result.cost + markups['drinks'])*10)/10,
          price: Math.ceil((result.cost * markupMultiplier['Drink'])*10)/10,
          raw_price: Math.ceil((result.cost * markupMultiplier['Drink'])*10)/10,
          side: false,
          category: result.category,

          calories: result.calorie,

          support_offline: result.support_offline,

          vegetarian: result.vegetarian,
          vegan: result.vegan,
          sugar_free: result.sugar_free,
          spicelevel: result.spice_level,
          apt_for_lunchbox: result.apt_for_lunchbox,
          baby_friendly: result.baby_friendly,
          cuisine: result.cuisine,
          gluten_free: result.gluten_free,
          name_french: result.name_french,
          peanut_free: result.peanut_free,
          percent_automation: result.percent_automation,
          portion: result.portion,
          review_stars: result.review_stars,
          shelf_life: result.shelf_life,
          subcategory: result.subcategory,
          total_cooking_time: result.total_cooking_time,
          ingredients_list: ingredientString,
          ingredients_fulllist: ingredientFullString,
          lunch_cap: 0,
          dinner_cap: 0,
          chefs_choice: false
      };

      let newValues = []
      drinksOrderList.map((value) => {
        newValues.push(value);
      });
      newValues.push(newValue);

      if (composition.length > 0) {
        setComboChoice('drinks');
        // setCompositionList(composition);
        setCompositionList(getCommaDelList(composition));
        setComboOpen(true);
      };


      let lunchbox = {};
      lunchbox[id] = false;
      setLunchBoxChecked(Object.assign({},lunchBoxChecked,lunchbox));
      let dinneronly = {};
      dinneronly[id] = false;
      setDinnerOnlyChecked(Object.assign({},dinnerOnlyChecked,dinneronly));        

      let lunchdict = {};
      lunchdict[id] = false;
      setLunchSelectDict(Object.assign({},lunchSelectDict,lunchdict));     
      
      let dinnerdict = {};
      dinnerdict[id] = false;
      setDinnerSelectDict(Object.assign({},dinnerSelectDict,dinnerdict));     

      let realsidedict = {};
      realsidedict[id] = false;
      setRealsideSelectDict(Object.assign({},realsideSelectDict,realsidedict));     

      setListPointer(drinksOrderList.length);
      setDrinksOrderList(newValues);

  };
};



// end of drinks sectiion


// start of Fruits Section


function FruitsTable() {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid

        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={fruitsOrderList}
        onCellEditCommit={handleCellEditCommitFruits}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        onRowClick={handleFruitsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <GridOverlay className={classes.gridoverlay}>
              <div className={classes.label}>
                Add Fruit
              </div>
            </GridOverlay>
          )
        }}
        // components={{
        //   NoRowsOverlay: () => (
        //     <div>
        //     </div>
        //   )
        // }}
        // components={{
        //   Footer: () => <div></div>,
        // }}
        // componentsProps={{Footer:  () => <div>This is my footer</div>,
        //                   }}
      />
    </div>
  );
};


const handleCellEditCommitFruits = React.useCallback(
  ({ id, field, value }) => {
      const updatedRows = fruitsOrderList.map((row) => {
        if (row.id === id && row.category !== 'Side') {
          let tempRow = row;
          tempRow[field] = value;
          return tempRow;
        } else {
          return row;
        };
      });
      setFruitsOrderList(updatedRows);
  },
  [fruitsOrderList],
);


const handleFruitsSelect = (params) => {
  if (!params.row.side) {
    setSelectFruitsDelete(params.row.uuid);
  } else {
    setSelectFruitsDelete("");
  };
  setSelectedRow(params.row.uuid);
};

const handleFruitsDelete = () => {
  // let newOrderList = [];
  // fruitsOrderList.map((value) => {
  //     if(selectFruitsDelete !== value.uuid) {
  //         newOrderList.push(value);
  //     }
  // });
  // setFruitsOrderList(newOrderList);
  setFruitsOrderList(GeneralDelete(fruitsOrderList, selectFruitsDelete));
};


async function handleFruitsMoveRight() {
  if (selectedFruitsValue !== null) {
      // var result = await YPCGetAxios(xget, setXGet, 'getRecipeURL', selectedFruitsValue);
      var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetRecipeURL', selectedFruitsValue);

      var ingredientString = await recipeStepsString(result.ing_steps);
      var ingredientFullString = await recipeFullStepsString(result.ing_steps);

      let composition = result.sides_combos;


      let id = uuidv4();
      let newValue = {
          uuid: id,
          id: id,
          name: result.name,
          name_french: result.name_french,
          dish: result.name,
          cost: result.cost,
          // price: cost + starterMarkup,
          // price: Math.ceil((result.cost + markups['fruits'])*10)/10,
          price: Math.ceil((result.cost * markupMultiplier['Fruit'])*10)/10,
          raw_price: Math.ceil((result.cost * markupMultiplier['Fruit'])*10)/10,
          side: false,
          category: result.category,

          calories: result.calorie,

          support_offline: result.support_offline,

          vegetarian: result.vegetarian,
          vegan: result.vegan,
          sugar_free: result.sugar_free,
          spicelevel: result.spice_level,
          apt_for_lunchbox: result.apt_for_lunchbox,
          baby_friendly: result.baby_friendly,
          cuisine: result.cuisine,
          gluten_free: result.gluten_free,
          name_french: result.name_french,
          peanut_free: result.peanut_free,
          percent_automation: result.percent_automation,
          portion: result.portion,
          review_stars: result.review_stars,
          shelf_life: result.shelf_life,
          subcategory: result.subcategory,
          total_cooking_time: result.total_cooking_time,
          ingredients_list: ingredientString,
          ingredients_fulllist: ingredientFullString,
          lunch_cap: 0,
          dinner_cap: 0,
          chefs_choice: false
      };

      let newValues = []
      fruitsOrderList.map((value) => {
        newValues.push(value);
      });
      newValues.push(newValue);

      if (composition.length > 0) {
        setComboChoice('fruits');
        // setCompositionList(composition);
        setCompositionList(getCommaDelList(composition));
        setComboOpen(true);
      };


      let lunchbox = {};
      lunchbox[id] = false;
      setLunchBoxChecked(Object.assign({},lunchBoxChecked,lunchbox));
      let dinneronly = {};
      dinneronly[id] = false;
      setDinnerOnlyChecked(Object.assign({},dinnerOnlyChecked,dinneronly));        

      let lunchdict = {};
      lunchdict[id] = false;
      setLunchSelectDict(Object.assign({},lunchSelectDict,lunchdict));     
      
      let dinnerdict = {};
      dinnerdict[id] = false;
      setDinnerSelectDict(Object.assign({},dinnerSelectDict,dinnerdict));           

      let realsidedict = {};
      realsidedict[id] = false;
      setRealsideSelectDict(Object.assign({},realsideSelectDict,realsidedict));     

      setListPointer(fruitsOrderList.length);
      setFruitsOrderList(newValues);

  };
};



// end of Fruits Section


// everything Print section
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function everythingTable() {
  let globalOrderList = []
  globalOrderList.push(...starterOrderList, ...mainsOrderList, ...dessertsOrderList, ...drinksOrderList, ...fruitsOrderList);
  // console.log('MVMVMVMVMVM', globalOrderList, lunchBoxChecked, dinnerOnlyChecked);
  return (
    <div style={{ width: '100%' }}>
      <DataGrid

        className={classes.gridcell}
        hideFooter={true}
        hideFooterPagination={true}
        // hideFooterRowCount={true}
        // hideFooterSelectedRowCount={true}
        density="compact"
        rows={globalOrderList}
        columns={printcolumns}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
        disableColumnSelector={true}
        disableColumnFilter={true}
        // onRowClick={handleFruitsSelect}
        // headerHeight={0}
        autoHeight={true}
        components={{
          NoRowsOverlay: () => (
            <GridOverlay className={classes.gridoverlay}>
              <div className={classes.label}>
              </div>
            </GridOverlay>
          ),
          Toolbar:CustomToolbar
        }}
      />
    </div>
  );
};



// gen dialog

const handleGenDialog = () => {
  setXPost(null);
  setXGet(null);
}

  function genDialog() {
    // console.log('XPXPXPXPXPXPXPXP', xpost)
    return (
      <div>
        <Dialog open={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
        {/* <Dialog> */}
          <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {xpost}{xget}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenDialog} color="primary">
              {translate(props.language, 'Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    );
  };


  const handleRobotHumanChoice = (event) => {
    // console.log('KAKAKAKAKAK', event);
    // ['All', 'Tested', 'Robot Tested', 'Human Tested']
    if (event === 'All') {
      setTagString('');
    } else if (event === "Tested") {
      setTagString("&tags=robot_tested,human_tested");
    } else if (event === "Robot Tested") {
      setTagString("&tags=robot_tested");
    } else if (event === "Human Tested") {
      setTagString("&tags=human_tested");
    } else {
      setTagString("");
    }

    setAllRobotHumanChoice(event);
    setMenuLoaded(false);
  };


  async function handleCancelMenu () {

    handleClearAll();

    let subDate = selectedDate.toString().split(" ")
    let dateString = subDate[1] + subDate[2] + subDate[0] + subDate[3];

    let sendData = {
      datestring: dateString,
    };


    // console.log('WXWXWXWXWX', combinedLists);

    await YPCPutAxios2Alt(setXPost, 'deleteMenuURL', sendData, '');


    let testit = {
      datestring: dateString,
      reason: 'timelists',
      misc: JSON.stringify({
        'lunchList': lunchTimeList,
        'dinnerList' : dinnerTimeList
      })
    }
    await YPCPutAxios2Alt(setXPost, 'deleteMiscTimeListsURL', testit, '');


    testit = {
      datestring: dateString,
      reason: 'costprice',
      misc: JSON.stringify({
        'whatever' : 'whatever'
      })
    }
    await YPCPutAxios2Alt(setXPost, 'deleteMiscCostPriceListsURL', testit, '');


  };

  
  // following is transfer list stuff

  const handleEditTimeList = (event) => {
    setEditTimeList(event.target.checked);
  };

  // transfer list lunch stuff

  const [lunchChecked, setLunchChecked] = React.useState([]);

  const leftLunchChecked = intersection(lunchChecked, lunchDefaultTimeList);
  const rightLunchChecked = intersection(lunchChecked, lunchTimeList);

  const handleLunchToggle = (value) => () => {

    const currentIndex = lunchChecked.indexOf(value);
    const newChecked = [...lunchChecked];


    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setLunchChecked(newChecked);
  };

  const handleCheckedLunchRight = () => {
    let tempLunchTimeList = lunchTimeList.concat(leftLunchChecked);
    let tempLunchLeftTimeList = not(lunchDefaultTimeList, leftLunchChecked);
    tempLunchTimeList.sort();
    tempLunchLeftTimeList.sort();
    setLunchTimeList(tempLunchTimeList);
    setLunchDefaultTimeList(tempLunchLeftTimeList);

    // setLunchTimeList(lunchTimeList.concat(leftLunchChecked));
    // setLunchDefaultTimeList(not(lunchDefaultTimeList, leftLunchChecked));
    setLunchChecked(not(lunchChecked, leftLunchChecked));
  };

  const handleCheckedLunchLeft = () => {
    let tempLunchLeftTimeList = lunchDefaultTimeList.concat(rightLunchChecked);
    let tempLunchTimeList = not(lunchTimeList, rightLunchChecked)
    tempLunchTimeList.sort();
    tempLunchLeftTimeList.sort();
    setLunchDefaultTimeList(tempLunchLeftTimeList);
    setLunchTimeList(tempLunchTimeList);

    // setLunchDefaultTimeList(lunchDefaultTimeList.concat(rightLunchChecked));
    // setLunchTimeList(not(lunchTimeList, rightLunchChecked));
    setLunchChecked(not(lunchChecked, rightLunchChecked));
  };


  const customLunchList = (title, items) => (
    <Card
      raised={true} 
      square={false} 
    >

      <CardHeader
        sx={{ px: 2, py: 1 }}
        subheader={title}
      />


      <Divider />
      <List dense component="div" role="list"

              style={{                
                width: 200,
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto'}}
      >
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label-lunch`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleLunchToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={lunchChecked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>

  );


  // transfer list dinner stuff

  const [dinnerChecked, setDinnerChecked] = React.useState([]);

  const leftDinnerChecked = intersection(dinnerChecked, dinnerDefaultTimeList);
  const rightDinnerChecked = intersection(dinnerChecked, dinnerTimeList);

  const handleDinnerToggle = (value) => () => {
    const currentIndex = dinnerChecked.indexOf(value);
    const newChecked = [...dinnerChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setDinnerChecked(newChecked);
  };

  const handleCheckedDinnerRight = () => {
    let tempDinnerTimeList = dinnerTimeList.concat(leftDinnerChecked);
    let tempDinnerLeftTimeList = not(dinnerDefaultTimeList, leftDinnerChecked);
    tempDinnerTimeList.sort();
    tempDinnerLeftTimeList.sort();
    setDinnerTimeList(tempDinnerTimeList);
    setDinnerDefaultTimeList(tempDinnerLeftTimeList);

    // setDinnerTimeList(dinnerTimeList.concat(leftDinnerChecked));
    // setDinnerDefaultTimeList(not(dinnerDefaultTimeList, leftDinnerChecked));
    setDinnerChecked(not(dinnerChecked, leftDinnerChecked));
  };

  const handleCheckedDinnerLeft = () => {
    let tempDinnerLeftTimeList = dinnerDefaultTimeList.concat(rightDinnerChecked);
    let tempDinnerTimeList = not(dinnerTimeList, rightDinnerChecked)
    tempDinnerTimeList.sort();
    tempDinnerLeftTimeList.sort();
    setDinnerDefaultTimeList(tempDinnerLeftTimeList);
    setDinnerTimeList(tempDinnerTimeList);

    // setDinnerDefaultTimeList(dinnerDefaultTimeList.concat(rightDinnerChecked));
    // setDinnerTimeList(not(dinnerTimeList, rightDinnerChecked));
    setDinnerChecked(not(dinnerChecked, rightDinnerChecked));
  };


  const customDinnerList = (title, items) => (
    <Card
      raised={true} 
      square={false} 
    >

      <CardHeader
        sx={{ px: 2, py: 1 }}
        subheader={title}
      />


      <Divider />
      <List dense component="div" role="list"

              style={{                
                width: 200,
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto'}}
      >
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label-dinner`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleDinnerToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={dinnerChecked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>

  );

 // end of transfer stuff 

    return (

          <React.Fragment>

          <Container className={classes.container}  style={{ marginTop: "2em" }}>

          <MuiThemeProvider theme={theme}>
            <Grid container direction="row" alignItems="stretch" justify="center" spacing={2}>
              <Grid item xs={12}>



                  <div className={classes.sendleft}>

                      {compositionList !== null && comboOpen ? ComboSelect() : null}

                      {/* {xget !== null ? genDialog() : null} */}
                      {xpost !== null || xget !== null ? genDialog() : null}
                      <Typography component="h4" variant="h4">
                          {translate(props.language, 'Create Menu')}
                      </Typography>
                  </div>


                  <div style={{clear: "both"}}></div>
              </Grid>

              <Grid item xs={12}>

              <MuiPickersUtilsProvider utils={MomentUtils} locale={translate(props.language, 'en-ca')}>
                                <MuiThemeProvider theme={theme}>
                                  <KeyboardDatePicker
                                      orientation="landscape"
                                      variant="static"
                                      margin="normal"
                                      id="date-picker-dialog"
                                      label="Date picker dialog"
                                      // format="MM/dd/yyyy"
                                      format={"dddd, " + "MMMM" + ", yyyy"}
                                      value={selectedDate}
                                      onChange={handleDateChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                  />
                                </MuiThemeProvider>
                              </MuiPickersUtilsProvider>


              </Grid>
              <Grid item xs={3}>
                         <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                  onChange={(event, value) => handleRobotHumanChoice(value)}
                                  id="combo-box-choice-1"
                                  options={['All', 'Tested', 'Robot Tested', 'Human Tested']}
                                  value={allRobotHumanChoice}
                                  // getOptionLabel={(option) => option.name}
                                  // style={{ width: 300 }}
                                  renderInput={(params) => <TextField {...params} label={translate(props.language,'Recipes by')} InputLabelProps={{
                                      shrink: true,
                                      style: {color: '#78cbff'},
                                  }}
                                  // variant="outlined"
                                  />} 
                              />
                        </MuiThemeProvider>
              </Grid>
              <Grid item xs={9}></Grid>

              {/*   Starters Section */}
              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Starters')} &#8198;</div>
              </Grid>
              <Grid item xs={12}>
                  {recipedb ?     
                    <Grid container spacing={3}>
                        <Grid item xs={3}>

                            <MuiThemeProvider theme={theme}>
                                <Autocomplete
                                    onChange={(event, value) => setSelectedStarterValue(value)}
                                    id="combo-box1"
                                    options={starterRecipes}
                                    // getOptionLabel={(option) => option.name}
                                    // style={{ width: 300 }}
                                    renderInput={(params) => 
                                            <TextField {...params} label={translate(props.language,'Select')}
                                                InputLabelProps={{
                                                  shrink: true,
                                                  style: {color: '#78cbff'},
                                                }}
                                                
                                                // variant="outlined"
                                            />
                                    } 
                                />

                              </MuiThemeProvider>

                              <p></p>
                              { surveyResultDict.hasOwnProperty(selectedStarterValue) ?
                                  <Rating 
                                      readOnly
                                      name="star-rating" 
                                      value={surveyResultDict[selectedStarterValue]} 
                                      // max={10}
                                      precision={0.5}
                                      // onChange={(event, value) => setReviewStars(value * 2)}
                                      // disabled={robotTested && humanTested}
                                  />
                                :
                                null
                              }

                        </Grid>
                        <Grid item xs={1}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleStarterMoveRight}
                                disabled={false}
                                aria-label="move selected right"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                &gt;
                            </Button>
                        </Grid>
                        <Grid item xs={8} >
                            {StarterTable()}
                            {starterOrderList.length > 0 ?
                              <p><small>(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s&lt;None/Low/Med/High&gt;) spice level, (bf) baby friendly</small></p>
                              : <p></p>}
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                onClick={handleStarterDelete}
                                disabled={false}
                                aria-label="whatever 1"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                {translate(props.language,'Delete')}
                            </Button>
                        </Grid>
                      </Grid>
                  : null }
              </Grid>
              {/* End of Starters Section */}


              {/* Start of Mains Section */}
              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Mains')} &#8198;</div>
              </Grid>
              <Grid item xs={12}>

                  {recipedb ?     
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Autocomplete
                                onChange={(event, value) => setSelectedMainsValue(value)}
                                id="combo-box2"
                                options={mainRecipes}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"
                                />} 
                            />

                              <p></p>
                              { surveyResultDict.hasOwnProperty(selectedMainsValue) ?
                                  <Rating 
                                      readOnly
                                      name="star-rating" 
                                      value={surveyResultDict[selectedMainsValue]} 
                                      // max={10}
                                      precision={0.5}
                                      // onChange={(event, value) => setReviewStars(value * 2)}
                                      // disabled={robotTested && humanTested}
                                  />
                                :
                                null
                              }


                        </Grid>
                        <Grid item xs={1}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleMainsMoveRight}
                                disabled={false}
                                aria-label="move selected right"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                &gt;
                            </Button>
                        </Grid>
                        <Grid item xs={8} >
                            {/* {startOrderForm(starterOrderList)} */}
                            {MainsTable()}
                            {mainsOrderList.length > 0 ?
                              <p><small>(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s&lt;None/Low/Med/High&gt;) spice level, (bf) baby friendly</small></p>
                              : <p></p>}
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                onClick={handleMainsDelete}
                                disabled={false}
                                aria-label="whatever 1"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                {translate(props.language,'Delete')}
                            </Button>
                        </Grid>
                      </Grid>
                  : null }
              </Grid>



              {/* End of Mains Section */}

              {/* Beginning of Desserts */}

              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Desserts')} &#8198;</div>
              </Grid>
              <Grid item xs={12}>
                  {recipedb ?     
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Autocomplete
                                onChange={(event, value) => setSelectedDessertsValue(value)}
                                id="combo-box3"
                                options={dessertRecipes}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"
                                />} 
                            />

                              <p></p>
                              { surveyResultDict.hasOwnProperty(selectedDessertsValue) ?
                                  <Rating 
                                      readOnly
                                      name="star-rating" 
                                      value={surveyResultDict[selectedDessertsValue]} 
                                      // max={10}
                                      precision={0.5}
                                      // onChange={(event, value) => setReviewStars(value * 2)}
                                      // disabled={robotTested && humanTested}
                                  />
                                :
                                null
                              }

                        </Grid>
                        <Grid item xs={1}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleDessertsMoveRight}
                                disabled={false}
                                aria-label="move selected right"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                &gt;
                            </Button>
                        </Grid>
                        <Grid item xs={8} >
                            {/* {startOrderForm(starterOrderList)} */}
                            {DessertsTable()}
                            {dessertsOrderList.length > 0 ?
                              <p><small>(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s&lt;None/Low/Med/High&gt;) spice level, (bf) baby friendly</small></p>
                              : <p></p>}
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                onClick={handleDessertsDelete}
                                disabled={false}
                                aria-label="whatever 1"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                {translate(props.language,'Delete')}
                            </Button>
                        </Grid>
                      </Grid>
                  : null }
              </Grid>



              {/* End of Desserts */}


              {/* Start of Drinks */}


              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Drinks')} &#8198;</div>
              </Grid>
              <Grid item xs={12}>
                  {recipedb ?     
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Autocomplete
                                onChange={(event, value) => setSelectedDrinksValue(value)}
                                id="combo-box4"
                                options={drinkRecipes}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"
                                />} 
                            />


                              <p></p>
                              { surveyResultDict.hasOwnProperty(selectedDrinksValue) ?
                                  <Rating 
                                      readOnly
                                      name="star-rating" 
                                      value={surveyResultDict[selectedDrinksValue]} 
                                      // max={10}
                                      precision={0.5}
                                      // onChange={(event, value) => setReviewStars(value * 2)}
                                      // disabled={robotTested && humanTested}
                                  />
                                :
                                null
                              }

                        </Grid>
                        <Grid item xs={1}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleDrinksMoveRight}
                                disabled={false}
                                aria-label="move selected right"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                &gt;
                            </Button>
                        </Grid>
                        <Grid item xs={8} >
                            {/* {startOrderForm(starterOrderList)} */}
                            {DrinksTable()}
                            {drinksOrderList.length > 0 ?
                              <p><small>(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s&lt;None/Low/Med/High&gt;) spice level, (bf) baby friendly</small></p>
                              : <p></p>}
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                onClick={handleDrinksDelete}
                                disabled={false}
                                aria-label="whatever 1"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                {translate(props.language,'Delete')}
                            </Button>
                        </Grid>
                      </Grid>
                  : null }
              </Grid>


              {/* End of Drinks */}


              {/* Start of Fruits */}

              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>Fruits &#8198;</div>
              </Grid>
              <Grid item xs={12}>
                  {recipedb ?     
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Autocomplete
                                onChange={(event, value) => setSelectedFruitsValue(value)}
                                id="combo-box5"
                                options={fruitRecipes}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"
                                />} 
                            />

                            <p></p>
                              { surveyResultDict.hasOwnProperty(selectedFruitsValue) ?
                                  <Rating 
                                      readOnly
                                      name="star-rating" 
                                      value={surveyResultDict[selectedFruitsValue]} 
                                      // max={10}
                                      precision={0.5}
                                      // onChange={(event, value) => setReviewStars(value * 2)}
                                      // disabled={robotTested && humanTested}
                                  />
                                :
                                null
                              }

                        </Grid>
                        <Grid item xs={1}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleFruitsMoveRight}
                                disabled={false}
                                aria-label="move selected right"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                &gt;
                            </Button>
                        </Grid>
                        <Grid item xs={8} >
                            {/* {startOrderForm(starterOrderList)} */}
                            {FruitsTable()}
                            {fruitsOrderList.length > 0 ?
                              <p><small>(p) may contain peanuts, (gf) gluten free, (v) vegetarian, (sf) sugar free, (ve) vegan, (s&lt;None/Low/Med/High&gt;) spice level, (bf) baby friendly</small></p>
                              : <p></p>}
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                onClick={handleFruitsDelete}
                                disabled={false}
                                aria-label="whatever 1"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                {translate(props.language,'Delete')}
                            </Button>
                        </Grid>
                      </Grid>
                  : null }
              </Grid>

              {/* End of Fruits */}

              {/* Start of Check Balances */}

              <Grid item xs={12}>
                  <div className={classes.sendleft}>

                      <Typography component="h5" variant="h5">
                          {translate(props.language,'Sense Check Balanced Pricing')}
                      </Typography>
                  </div>
                  <div style={{clear: "both"}}></div>
              </Grid>

              <Grid item xs={12}>

                  {recipedb ?     
                        <Grid item xs={8} >
                            {SenseTable()}

                        </Grid>
                  : null }
                  <p></p>
              </Grid>



              <Grid item xs={12}>
                  <div className={classes.sendleft}>

                      <Typography component="h5" variant="h5">
                          {'Set Order Entry Cutoff Time'}
                      </Typography>
                  </div>
                  <div style={{clear: "both"}}></div>
              </Grid>

              <Grid item xs={12}>
                  <div className={classes.sendleft}>

                      <Typography >
                          {'Lunch'}
                      </Typography>
                  </div>
                  <div style={{clear: "both"}}></div>
              </Grid>

              {/* lunch cut off */}
              <Grid container direction='row'>
                  <Grid item xs={1} >
                        <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                  onChange={(event, value) => setDaysBack(value)}
                                  id="combo-box-choice-days"
                                  options={['0','-1','-2','-3','-4','-5','-6','-7','-8','-9','-10','-11']}
                                  value={daysBack}
                                  // getOptionLabel={(option) => option.name}
                                  // style={{ width: 300 }}
                                  renderInput={(params) => <TextField {...params} label={'Days Back'} InputLabelProps={{
                                      shrink: true,
                                      style: {color: '#78cbff'},
                                  }}
                                  // variant="outlined"
                                  />} 
                              />
                        </MuiThemeProvider>
                  </Grid>
                  <Grid  item xs={1}></Grid>

                  <Grid item xs={1} >
                        <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                  onChange={(event, value) => setHourTime(value)}
                                  id="combo-box-choice-hour"
                                  options={['0', '1', '2', '3', '4' , '5', '6', '7', '8', '9', '10', '11', '12', '13', '14','15','16','17','18','19','20', '21', '22', '23', '24']}
                                  value={hourTime}
                                  // getOptionLabel={(option) => option.name}
                                  // style={{ width: 300 }}
                                  renderInput={(params) => <TextField {...params} label={'Hour (24)'} InputLabelProps={{
                                      shrink: true,
                                      style: {color: '#78cbff'},
                                  }}
                                  // variant="outlined"
                                  />} 
                              />
                        </MuiThemeProvider>
                  </Grid>
                  <Grid  item xs={1}></Grid>
                  <Grid item xs={1} >
                        <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                  onChange={(event, value) => setMinuteTime(value)}
                                  id="combo-box-choice-minute"
                                  options={['0','15', '30', '45']}
                                  value={minuteTime}
                                  // getOptionLabel={(option) => option.name}
                                  // style={{ width: 300 }}
                                  renderInput={(params) => <TextField {...params} label={'Minute'} InputLabelProps={{
                                      shrink: true,
                                      style: {color: '#78cbff'},
                                  }}
                                  // variant="outlined"
                                  />} 
                              />
                        </MuiThemeProvider>
                  </Grid>

              </Grid>

              <Grid item xs={12}>
                  <div className={classes.sendleft}>

                      <Typography >
                          {'Dinner'}
                      </Typography>
                  </div>
                  <div style={{clear: "both"}}></div>
              </Grid>

              {/* Dinner cut off */}
              <Grid container direction='row'>
                  <Grid item xs={1} >
                        <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                  onChange={(event, value) => setDaysBackDinner(value)}
                                  id="combo-box-choice-days"
                                  options={['0','-1','-2','-3','-4','-5','-6','-7','-8','-9','-10','-11']}
                                  value={daysBackDinner}
                                  // getOptionLabel={(option) => option.name}
                                  // style={{ width: 300 }}
                                  renderInput={(params) => <TextField {...params} label={'Days Back'} InputLabelProps={{
                                      shrink: true,
                                      style: {color: '#78cbff'},
                                  }}
                                  // variant="outlined"
                                  />} 
                              />
                        </MuiThemeProvider>
                  </Grid>
                  <Grid  item xs={1}></Grid>

                  <Grid item xs={1} >
                        <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                  onChange={(event, value) => setHourTimeDinner(value)}
                                  id="combo-box-choice-hour"
                                  options={['0', '1', '2', '3', '4' , '5', '6', '7', '8', '9', '10', '11', '12', '13', '14','15','16','17','18','19','20', '21', '22', '23', '24']}
                                  value={hourTimeDinner}
                                  // getOptionLabel={(option) => option.name}
                                  // style={{ width: 300 }}
                                  renderInput={(params) => <TextField {...params} label={'Hour (24)'} InputLabelProps={{
                                      shrink: true,
                                      style: {color: '#78cbff'},
                                  }}
                                  // variant="outlined"
                                  />} 
                              />
                        </MuiThemeProvider>
                  </Grid>
                  <Grid  item xs={1}></Grid>
                  <Grid item xs={1} >
                        <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                  onChange={(event, value) => setMinuteTimeDinner(value)}
                                  id="combo-box-choice-minute"
                                  options={['0','15', '30', '45']}
                                  value={minuteTimeDinner}
                                  // getOptionLabel={(option) => option.name}
                                  // style={{ width: 300 }}
                                  renderInput={(params) => <TextField {...params} label={'Minute'} InputLabelProps={{
                                      shrink: true,
                                      style: {color: '#78cbff'},
                                  }}
                                  // variant="outlined"
                                  />} 
                              />
                        </MuiThemeProvider>
                  </Grid>

              </Grid>              


              <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                    <FormControlLabel control={
                      <Checkbox
                          value="checkedA"
                          checked={editTimeList}
                          onChange={handleEditTimeList}
                          // inputProps={{
                          //   'aria-label': 'Checkbox A',
                          // }}
                        />}
                      label="Edit Time Slot List" />
                  </MuiThemeProvider>
                </Grid>                      



                { editTimeList ?
                    <Grid item xs={12}>

                        {/* <Grid item xs={12}>
                          <div className={classes.sendleft}>

                              <Typography component="h5" variant="h5">
                                  {'Edit Time Slot List'}
                              </Typography>
                          </div>
                          <div style={{clear: "both"}}></div>
                      </Grid> */}

                      {/* lunch Time */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}  alignItems="center">
                              <Grid item>{customLunchList('Lunch Time Source', lunchDefaultTimeList)}</Grid>
                              <Grid item>
                                <Grid container direction="column" alignItems="center">
                                  <Button
                                    sx={{ my: 0.5 }}
                                    // variant="outlined"
                                    variant={leftLunchChecked.length === 0 ? "outline" : "contained"}
                                    size="small"
                                    onClick={handleCheckedLunchRight}
                                    disabled={leftLunchChecked.length === 0}
                                    aria-label="move selected right lunch"
                                    style={leftLunchChecked.length === 0 ? {} : {backgroundColor: '#78cbff', color: 'white'}}
                                  >
                                    &gt;
                                  </Button>
                                  <p></p>
                                  <Button
                                    sx={{ my: 0.5 }}
                                    // variant="outlined"
                                    variant={rightLunchChecked.length === 0 ? "outline" : "contained"}
                                    size="small"
                                    onClick={handleCheckedLunchLeft}
                                    disabled={rightLunchChecked.length === 0}
                                    aria-label="move selected left lunch"
                                    // style={{backgroundColor: '#78cbff', color: 'white'}}
                                    style={rightLunchChecked.length === 0 ? {} : {backgroundColor: '#78cbff', color: 'white'}}
                                  >
                                    &lt;
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid item>{customLunchList(' Final Lunch Time List', lunchTimeList)}</Grid>
                            </Grid>
                        </Grid>

                        <p></p>

                        {/* <Typography>
                                  {JSON.stringify(lunchDefaultTimeList)}
                          </Typography>
                          <Typography>
                                  {JSON.stringify(lunchTimeList)}
                          </Typography> */}


                      {/* dinner Time */}

                      <Grid item xs={12}>
                            <Grid container spacing={2}  alignItems="center">
                              <Grid item>{customDinnerList('Dinner Time Source', dinnerDefaultTimeList)}</Grid>
                              <Grid item>
                                <Grid container direction="column" alignItems="center">
                                  <Button
                                    sx={{ my: 0.5 }}
                                    // variant="outlined"
                                    variant={leftDinnerChecked.length === 0 ? "outline" : "contained"}
                                    size="small"
                                    onClick={handleCheckedDinnerRight}
                                    disabled={leftDinnerChecked.length === 0}
                                    aria-label="move selected right dinner"
                                    // style={{backgroundColor: '#78cbff', color: 'white'}}
                                    style={leftDinnerChecked.length === 0 ? {} : {backgroundColor: '#78cbff', color: 'white'}}
                                  >
                                    &gt;
                                  </Button>
                                  <p></p>
                                  <Button
                                    sx={{ my: 0.5 }}
                                    // variant="outlined"
                                    variant={rightDinnerChecked.length === 0 ? "outline" : "contained"}
                                    size="small"
                                    onClick={handleCheckedDinnerLeft}
                                    disabled={rightDinnerChecked.length === 0}
                                    aria-label="move selected left dinner"
                                    // style={{backgroundColor: '#78cbff', color: 'white'}}
                                    style={rightDinnerChecked.length === 0 ? {} : {backgroundColor: '#78cbff', color: 'white'}}
                                  >
                                    &lt;
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid item>{customDinnerList(' Final Dinner Time List', dinnerTimeList)}</Grid>
                            </Grid>
                        </Grid>


                        {/* <Typography>
                                  {JSON.stringify(dinnerDefaultTimeList)}
                          </Typography>
                          <Typography>
                                  {JSON.stringify(dinnerTimeList)}
                          </Typography> */}

                    </Grid>

                : null }



              {/* Upload and Place Order Section */}
              <Grid item xs={12} >          



              <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        // color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleCancelMenu}
                        disabled={false}
                        aria-label="whatever 116"
                        style={{backgroundColor: '#78cbff', color:"white"}}
                    >
                        {translate(props.language, 'Cancel Menu')}
                    </Button>        

              </Grid>


              <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        size="small"
                        // color="primary"
                        className={classes.button}
                        onClick={handleClearAll}
                        disabled={false}
                        aria-label="whatever 1"
                        style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                        {translate(props.language,'Clear All')}
                    </Button>        

                    <p></p>
                  </Grid>



                  <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        // color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleSaveMenu}
                        disabled={false}
                        aria-label="whatever 1"
                        style={{backgroundColor: '#78cbff', color: 'white'}}
                    >
                        {translate(props.language,'Save')}
                    </Button>        

                  </Grid>

                  {/* <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleTestButton}
                        disabled={false}
                        aria-label="whatever 10"
                        style={{backgroundColor: '#78cbff'}}
                    >
                        {'Test Button'}
                    </Button>        

                  </Grid> */}

                  <Grid item xs={6} ></Grid>
              </Grid>

                <Grid item xs={12} >
                  <p></p>
                  <Divider />
                  <p></p>
                  <Typography component="h5" variant="h5">
                              Print CSV -
                      </Typography>
                </Grid>
                <Grid item xs={12} >
                  {everythingTable()}
                </Grid>


              {/* {xget !== null ? 
                xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
              : null}


              {xpost !== null ? 
                  xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
                : null}

              {xpost !== null ? 
                  xpost[0] === 'O' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
                : null} */}

            </Grid>
          </MuiThemeProvider>
          </Container>
          </ React.Fragment>



    );



}



